import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { reduxForm, SubmissionError, formValueSelector, blur } from 'redux-form';
import { FieldGroup, DoubleFieldGroup, PasswordField } from "Components/Forms";

import updateFormValues from "modules/updateFormValues"
import { requestSignup } from "modules/user/actions";
import { openModal } from 'modules/modals/actions';
import requestFilteredProducts from "modules/product/requestFilteredProducts"

import { createPasswordValidation, createValidateEmail, createRequired } from 'Components/Forms/validation';

import { createUser } from "utils/createUser"

import { googleSignin, facebookSignin } from "utils/oauth"

const passwordValidation = createPasswordValidation();
const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter your email');
const passwordRequired = createRequired('Please enter a password');
const confirmationRequired = createRequired('You must confirm your password');


class SignupForm extends Component {

  state = {
    password: '',
  }

  submit = (results) => {
    const { requestSignup, openModal } = this.props;

    results['user_meta-entryType'] = 'sign-up';

    return createUser(results).catch(() => { throw new Error("Error creating user"); });
  }

  passwordsMatch = value => 
    value === this.state.password ? null : 'Passwords do not match';

  onPasswordChanged = e => {
    this.setState({ password: e.target.value }, () => {
      this.props.blur('sign-up', 'confirmPassword', this.props.confirmPassword);
    });
  }


  render() {
      const { error, handleSubmit } = this.props;
      //console.log("error?", error);
      return(
        <div className="form" style={{position: 'relative'}}>
          <form onSubmit={handleSubmit(this.submit)}>

            <FieldGroup id='email' width='100%' title='Email Address' type='text' hideOptional placeholder='Enter Email' validate={[emailRequired, emailValidation]} />
            <FieldGroup id='user-firstName' width='50%' title='First Name' required='Yes' type='text' placeholder='E.g. John' />
            <FieldGroup id='user-lastName' width='50%' title='Last Name' required='Yes' type='text' placeholder='E.g. Appleseed' />
            <PasswordField policy onChange={this.onPasswordChanged} id='password' width='100%' title='Password' hideOptional placeholder='Enter Password' validate={[passwordRequired, passwordValidation]}/>
            <PasswordField id='confirmPassword' width='100%' title='Confirm Password' hideOptional placeholder='Enter Password' validate={[confirmationRequired, this.passwordsMatch]}/>
            
            <button className='submit-button' type='submit' style={{marginTop: '10px'}}>Create an Account</button>
            {error && <div className='validation' style={{position: 'absolute'}}>{error}</div>}
          </form>
        </div>     
      )
    // }
    // else {
    //   return (<div>test</div>);
    // }
  }
}

SignupForm = reduxForm({
  form: 'sign-up',
  destroyOnUnmount: false
})(SignupForm);


const selector = formValueSelector('sign-up')
const mapStateToProps = state => ({
  password: selector(state, 'password'),
  confirmPassword: selector(state, 'confirmPassword'),
});




function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSignup,
    openModal,
    blur,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import getQueryParams from "utils/getQueryParams";
import {requestPatchUser} from 'modules/user/actions';
import Debouncer from "utils/debouncer";
import LinkButton from "Components/LinkButton";

const debouncer = new Debouncer();


function mapStateToProps(state) {
  return {
    isVerified: state.user.isVerified,
    email: state.user.email,
    userId: state.user.id,
    newEmail: state.user.newEmail,
    verifyFinished: state.user.verifyFinished
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
    requestPatchUser
  }, dispatch);
}

class EmailChangeResult extends Component {

  constructor() {
    super();
    let queryParams = getQueryParams();
    this.state = {
      continue: queryParams.continue || "",
      email: queryParams.email,
      sent: queryParams.sent || false
    }
  }

  componentDidMount() {
    document.title = 'Secure Finance';
    this.props.setTitle("Secure Finance");
    this.props.setPageID("verify-success");
  }

  sendValidationEmail = function() {
    debouncer.run(() => {
      this.props.requestPatchUser({userId: this.props.userId, data: {email: this.props.newEmail},
        callback: ()=>{
          this.setState((prevState) => {return {...prevState, sent: true}});
        }
      });
    }, 5000)
   
  }

  render() {
    return (
      <main className={"verify " + this.props.type}>      
      {
        (!this.state.sent) ? (
          this.props.verifyFinished && ((this.props.email !== this.state.email) ? (
          <div className="main-container">
            <h1>Email Change Successful!</h1>
            <p>Your email address has been successfully updated to <span className="underline">{this.props.email}</span>. Click below to continue.</p>
            <LinkButton to={"/" + this.state.continue} className="button">Continue</LinkButton>
            <img src={require('img/page_illustrations/success_2.png')} alt='floating man'/>
          </div>
          ) : (
          <div className="main-container">
            <h1>Email Change Validation Expired</h1>
            <p>Too much time has elapsed since you requested your change email link and clicked on it.</p>
            {
              this.props.newEmail && [
              <p>Please click the button below if you would like us to e-mail a new change email link.</p>,
              <button className="button" onClick={()=>this.sendValidationEmail()}>Resend validation</button>
            ]}
          </div>
          ))
        ) : (
          <div className="main-container">
            <h1>Validation Email Sent</h1>
            <p>A validation email will shortly arrive in your inbox. Follow the link inside.</p>
            {
              this.props.newEmail && [
              <p>Click below to send a new validation link to <span className="underline">{this.props.newEmail}</span>:</p>,
              <button className="button" onClick={()=>this.sendValidationEmail()}>Resend validation</button>
            ]}
          </div>
        )
      }
      </main>
      );
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(EmailChangeResult);

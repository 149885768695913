import React from "react";
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import { EditableParagraph as EditableParagraphBase, Button } from './Shared';

import _ from 'lodash';

const FORM_NAME = "overview-intro";

class IntroLetter extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  resetSection = () => {
    const formValues = { body: '', heading: `Dear ${this.props.user.firstName},`};
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  }

  save = () => {
    let values = {...this.props.formValues};
    delete values.enabled;
    if (!values.heading) values.heading = `Dear ${this.props.user.firstName},`
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;
    
    toSave = {
      overview: {
        introLetter: data
      }
    }
    
    this.props.requestPatchUser({ 
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  } 

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }
  
  render() {
    const { active, editMode, letterEmpty } = this.props;

    return (
      
      <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        {editMode &&
          <SectionButton
            color='white'
            resetSection={() => this.resetSection()}
            enableEdit={() => this.enableEdit()}
            isEditable={active}
            cancelEdit={() => this.cancel()}
            type="overview-intro-letter" 
            style={{ float: 'right', position: 'absolute', top: '20px', right: '20px' }}
            cancelSaveContainer="20px"
          />
        }
        

        <LetterContainer>
          <h1><Span name='heading' as={EditableContent} disabled={!active} onEnter={this.blurOnEnter}/></h1>
          <p><EditableParagraph richText={!!active} innerRef={this.inputRef} allowLineBreaks onKeyDown={e => this.onKeyDown(e)} className={active ? 'active' : ''} name='body' disabled={!active}/></p>
          {editMode && letterEmpty &&
            <Button color='#2AD22B' onClick={() => this.props.openModal('introLetterTemplates')}>Set Letter</Button>
          }
        </LetterContainer>
       
      </Form>
    )
  }
}

const EditableParagraph = styled(EditableParagraphBase)`
  ul, ol {
    margin-top: 0 !important;

    li {
      margin-bottom: 0 !important;
    }
  }
`;

const LetterContainer = styled.div`
  width: 60%;

  @media (max-width: 645px) {
    width: 100%;
  }
`;

const Span = styled.span`
  display: inline-block;
  outline: none;
  width: 100%;
  font-weight: 700;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  `}
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = state => {
  const { introLetter } = state.user.meta.miniproposal.overview ?? {}
  const { globalSettings } = state.user;
  const heading = introLetter?.heading ?? `Dear ${state.user.firstName},`;
  const body = introLetter?.body ?? '';

  return {
    globalSettings,
    body: state.form[FORM_NAME]?.values?.text,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { heading, body },
    formValues: state.form[FORM_NAME]?.values ?? {},
    browser: state.browser,
    letterEmpty: !body,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }) (IntroLetter));
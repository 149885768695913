import React from "react"; import { Component } from "react";
import Form from "./Form";
import { connect } from 'react-redux';

class LendingOverviewOptions extends Component {

  render() {
    const { objectives_json, objectiveSwitches } = this.props;

    if (!objectives_json) return null;

    const objectives = JSON.parse(objectives_json)
      .sort((a, b) => a.order - b.order);

    if (!objectives || !objectives.length) return null;

    let initList = objectives.map((_, index) => objectiveSwitches[index] || false)

    if (this.props.currentModal === "objectivesList") {
    return (
      <div className="modal-content">
        <h1>Set Objectives</h1>
        <Form initialValues={{ objectives: initList }} objectives={objectives}/>
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(LendingOverviewOptions);

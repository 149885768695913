import React from "react"; import { Component } from "react";

class CheckEligibilityDelete extends Component {
  deleteCheck() {
    for (const product of this.props.products) {
      this.props.removeProductFromApplication({product_id: product.id, applicationId: this.props.application.id});
    }
    this.props.closeModal()
    this.props.requestPatchUser(
      { userId: this.props.userId, 
        data: {
          meta: {
            eligibilityStart: 0,
            eligibility_last_page: 0,
            eligibility_complete_page1: 0,
            eligibility_complete_page2: 0,
            eligibility_complete_page3: 0,
            eligibility_complete_page4: 0,
            eligibility_complete_page5: 0,
            eligibility_complete_page6: 0,
            eligibility_complete_page7: 0,
            eligibility_complete_page8: 0,
          }
        }, 
        callback: ()=>{}
    });
  }

  render() {
    if (this.props.currentModal === "checkEligibilityDelete") {
    return (
      <div className="modal-content">
        <img src={require('../../../img/modal/checkeligibilitydelete.png')} alt="Notifications"/>
        <h1>Cancel Qualification Check</h1>
        <p>Are you sure you wish to cancel?</p>
        <p>Any details you have entered into the qualifier will be lost <br/>and you will need to start over.</p>
        <div className="modal-navigation">
          <button onClick={()=>this.props.openModal("checkEligibilityAdd")} className="left double resume">No, I'll Resume it Later</button>
          <button onClick={()=>this.deleteCheck()} className="right double delete">Yes, Cancel Qualification Check</button>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default CheckEligibilityDelete;

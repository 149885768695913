import React from "react"; import { Component, Fragment } from "react";
import { formValueSelector, reduxForm } from 'redux-form';

import QualifyMeSmallProductBox from "./Components/QualifyMeSmallProductBox";
import calculateLoanValues from "utils/calculateLoanValues";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';
import FormNavigation from "../Components/FormNavigation";
import AddMoreProducts from "./Components/AddMoreProducts";
import { FormContainer, TextArea, UnsavedChangesPrompt } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import Disclaimer from 'Components/Global/ProductDetailsDisclaimer';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import moment from 'moment';

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products || [];
  }
  return {
    shortlist: state.user.shortlist,
    user: state.user.meta,
    products,
    application
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

const formatNumber = n => 
  Math.round(n).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

class SimpleForm extends Component {

  state = {
    place: '',
  }
  componentWillMount() {
    const { application, user } = this.props;

    const placeId = (application.meta.propertyAddress || {}).placeId;
    let place;
    if (placeId) geocodeByPlaceId(placeId).then(results => {
      const components = results[0].address_components;
      let localityComponent = components.filter(item => item.types.some(type => type === 'locality'));
      if (localityComponent.length) place = localityComponent[0].short_name;
      this.setState({ place });
    });
  }

  render() {
    const products = this.props.products.map(calculateLoanValues);
    let repaymentType = '';
    //console.log('products:', products);
    
    if (products.length) {
      if (products.every(product => product.repaymentType === 'Principal & Interest')) {
        repaymentType = 'Principal & Interest';
      }
      if (products.every(product => product.repaymentType === 'Interest Only')) {
        repaymentType = 'Interest Only';
      }
    }
    

    let isActive = false;
    if (this.props.applicationProducts) {
        isActive = (this.props.applicationProducts.find((e)=>e.id===this.props.product.id)) !== undefined;
    }
    
    var count;

    const { application, user } = this.props;

    return (
    <div className="form loan-details">

      <h1>Your Lending Objectives</h1>
      {application.meta.loanPurpose === 'purposeRefinance' ?
        <ul className="summary-results">
          <li>You are looking to refinance a loan of <strong>${formatNumber(application.loanAmount)}</strong>{this.state.place ? `, secured by a property in ${this.state.place}` : ''}</li>
          {!!application.meta.propertyAdditionalFundsAmount && <li>You wish to borrow an additional <strong>${formatNumber(application.meta.propertyAdditionalFundsAmount)}</strong>, for a total of <strong>${formatNumber(application.loanAmount + (application.meta.propertyAdditionalFundsAmount || 0))}</strong></li>}
          {repaymentType === 'Principal & Interest' && <li>You want to repay the loan on a <strong>Principle & Interest</strong> basis</li>}
          {repaymentType === 'Interest Only' && <li>You want to initially repay the loan on an <strong>Interest Only</strong> basis</li>}
        </ul>
        :
        <ul className="summary-results">
          <li>You are looking to buy a property {this.state.place ? `in ${this.state.place}, ` : ''}valued at approximately <strong>${formatNumber(application.meta.propertyValue)}</strong></li>
          <li>You wish to borrow <strong>${formatNumber(application.loanAmount)}</strong>{repaymentType ? <Fragment> repaying <strong>{repaymentType}</strong></Fragment> : null}</li>
          <li>We understand that you have a deposit of approximately <strong>${formatNumber(application.meta.propertyValue - application.loanAmount)}</strong></li>
        </ul>
      }
      {!!products.length &&
        <div className="selection">
          <h1>Your Loan Product Selection</h1>
          <p className='under-heading'>Our broking team will examine your scenario and seek to qualify you for the following loan products. We will also explore if there are any better options for your situation.</p>

          {this.props.products.map((product)=>
              <div className="product-container">
                <QualifyMeSmallProductBox 
                  application={this.props.application}
                  removeProductFromApplication={this.props.removeProductFromApplication}
                  productLength={this.props.products.length}
                  closeModal={this.props.closeModal}
                  nsrValues={this.props.nsrValues} product={product} />            
              </div>
                )}
            {empty(this.props.products.length)}
        </div>
      }
      {!products.length &&
        <div className="selection">
          <h1>Next Steps</h1>
          <p className='under-heading'>Our broking team will examine your scenario and seek to identify the best solution for your situation. We'll be in touch to discuss this further.</p>
        </div>
      }

      
      <FormContainer onSubmit={this.props.handleSubmit}>
        <h1>Additional Comments</h1>
        <p className='under-heading'>Please let us know below if you have any additional comments or questions.</p>
        <TextArea id='user_meta-additionalComments' width='wide' position='left' hideOptional type='text' placeholder='' />
        <p>📌 Remember that by submitting this form you are not committing to anything. We offer a completely free service and expert advice to help you with your mortgage needs. 🙂</p>

      
        <FormNavigation currentPage="7" submitting={this.props.submitting}/>
        <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting) }/>
      </FormContainer>
      {!!products.length && <Disclaimer/>}
    </div>
    )
  }
}

function empty(length){
  switch (3 - length) {
    case 0: return "";
    case 1: return <AddMoreProducts value={(3 - length)} />
    case 2: return <AddMoreProducts value={(3 - length)} />
    case 3: return <AddMoreProducts value={(3 - length)} />
    default: return "";
  }
}

SimpleForm = reduxForm({
  form: 'eligibility-loanDetails',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-loanDetails') // <-- same as form name
SimpleForm = connect(
  state => {
    return {
      initialValues: { 
        "user_meta-eligibility_complete_page1": 0, 
        "user_meta-eligibility_complete_page2": 0, 
        "user_meta-eligibility_complete_page3": 0, 
        "user_meta-eligibility_complete_page4": 0, 
        "user_meta-eligibility_complete_page5": 0, 
        "user_meta-eligibility_complete_page6": 0, 
        "user_meta-eligibility_complete_page7": 0, 
        "user_meta-eligibility_last_page": 7,
        "application-submitEligibility": 1,
        "application_meta-applicationSubmitted": 1,
        "application-list": 1,      
        "user-clientStage": 'eligibility',
        "user_meta-eligibilitySubmitDate": moment(),
      }
    }
  }
)(SimpleForm);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleForm);

import React from "react"; import { Component } from "react";
import Form from "./Form";
import { connect } from 'react-redux';

class IntroLetter extends Component {

  render() {
    
    if (this.props.currentModal === "introLetterTemplates") {
    return (
      <div className="modal-content">
        <h1>Set Letter Template</h1>
        <Form />
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(IntroLetter);

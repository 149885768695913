import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Dashboard from "./Dashboard"
import Calculators from "./Calculators"
import SearchLoans from "./SearchLoans"
import LoanShortlist from "./LoanShortlist"
import CheckEligibility from "./CheckEligibility"
import LoanTracker from "./LoanTracker"
import Help from "./Help/underConstruction"
import Contact from "./Contact"
import Upload from "./Upload"
import ValidationResult from "./Accounts/ValidationResult"
import ValidationSent from "./Accounts/ValidationSent"
import EmailChangeResult from "./Accounts/EmailChangeResult"
import PreventEmails from "./Accounts/PreventEmails"
import Proposal from "./Proposal/proposal"
import Referrals from "./Referrals/Views/Referrals";

import ProductDetails from "./ProductDetails"

import InitialQuestionSet from "./InitialQuestionSet"

import AccountSettings from "./Accounts/AccountSettings"
import ConfirmAccountSubmission from './Accounts/ConfirmAccountSubmission';
import ShowModal from "Components/Modals/showModal";
import AddReferral from './Referrals/Views/AddReferral';
import CompanyDetails from './Referrals/Views/CompanyDetails';
import Pipeline from './Referrals/Views/Pipeline';
import ReferralDetails from './Referrals/Views/ReferralDetails';
import PrivacyPolicy from 'Components/PrivacyPolicy/PrivacyPolicy';

function getQueryVariable(query, variable) {
    if (query) {
      var vars = query.split('?')[1].split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) === variable) {
            //console.log("Next: " + pair[1]);
            return decodeURIComponent(pair[1]);
          }
      }
    }
    return '';
}

function mapStateToProps(state) {
  return {
    user: state.user,
    godmode: state.user.godmode,
    miniProposal: state.user.meta.miniproposal || {},
  };
}

class Routes extends Component {

  componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
          window.Intercom('update');
      }
  }

  render() {
    const { miniProposal, godmode, user } = this.props;

    let videoProposalActive = false;
    if ((user.meta.proposal || {}).enabled === 'Y') {
      const { expiry } = user.meta.proposal;
      if (expiry) {
        const day = parseInt(expiry.slice(0,2));
        const month = parseInt(expiry.slice(2,4));
        const year = parseInt(expiry.slice(4,8));
        if (day && month && year) {
          const date = new Date(year, month - 1, day);
          if (!isNaN(date)) {
            const currentDate = new Date();
            const dayDifference = (date - currentDate) / (1000 * 60 * 60 * 24);
            if ((dayDifference > 0 && date.getDay() !== currentDate.getDay())) videoProposalActive = true;
          }
        }
      } 
    }

    const allowProposal = 
      (miniProposal.draftMode && (miniProposal.enabled || godmode )) // mini proposal
      || videoProposalActive // video proposal

    let defaultRedirect = '/search-loans';
    if (user.isProfessionalReferrer) {
      defaultRedirect = '/referrals/dashboard'
    } else if (miniProposal.enabled && miniProposal.draftMode) {
      defaultRedirect = '/proposal'
    } else if (this.props.clientStage !== 'unregistered') {
      defaultRedirect = '/dashboard';
    }
    console.log(this.props.user, defaultRedirect);
    return (
      <Switch>
        {!allowProposal && 
          <Redirect exact from="/proposal" to="/" />
        }

        {/* {((this.props.user.miniproposal || {}).enabled && (this.props.user.miniproposal || {}).draftMode) ? (
            <Redirect exact from="/" to="/proposal" />
          ): (this.props.clientStage === "unregistered")?(
            <Redirect exact from="/" to="/search-loans" />
            ): (
            <Redirect exact from="/" to="/dashboard" />
          )
        
        } */}
        <Redirect exact from="/" to={defaultRedirect} />

        <Redirect exact from="/refinance" to="/search-loans" />
        <Redirect exact from="/signup" to="/search-loans" />
        {allowProposal &&
          <Route exact path="/proposal" render={(props) => <Proposal type={this.props.state} {...props} /> } />
        }
        <Route exact path="/dashboard" render={(props) => <Dashboard type={this.props.state} {...props} /> } />
        <Route exact path="/calculators" render={(props) => <Calculators type={this.props.state} {...props} /> } />
        <Route exact path="/calculators/:id/" render={(props) => <Calculators type={this.props.state} {...props} /> } />
        <Route exact path="/search-loans" render={(props) => <SearchLoans type={this.props.state} {...props} /> } />
        <Route exact path="/products/:id/:tab" render={(props) => <ProductDetails type={this.props.state} {...props} /> } />
        <Route exact path="/products/:id" render={(props) => <ProductDetails type={this.props.state} {...props} /> } />
        <Route exact path="/loan-shortlist" render={(props) => <LoanShortlist type={this.props.state} {...props} /> } />
        {/* Cannot Implement this.props.user.eligibilityStart} */}
        <Route exact path="/check-eligibility/" render={(props) => <CheckEligibility type={this.props.state} {...props} /> } />
        <Route exact path="/check-eligibility/:id/" render={(props) => <CheckEligibility type={this.props.state} {...props} /> } />
        <Route exact path="/loan-tracker" crender={(props) => <LoanTracker type={this.props.state} {...props} /> } />
        <Route exact path="/help" render={(props) => <Help type={this.props.state} {...props} /> } />
        <Route exact path="/help/:id/" render={(props) => <Help type={this.props.state} {...props} /> } />
        <Route exact path="/upload/" render={(props) => <Upload type={this.props.state} {...props} /> } />
        <Route path="/initial-question-set" render={(props) => <InitialQuestionSet type={this.props.state} {...props} /> } />
        <Route exact path="/accounts" render={(props) => <AccountSettings type={this.props.state} {...props} /> } />
        <Route exact path="/verify" render={(props) => <ValidationResult type={this.props.state} {...props} /> } />
        <Route exact path="/validation-email-sent" render={(props) => <ValidationSent type={this.props.state} {...props} /> } />
        <Route exact path="/replace-email" render={(props) => <EmailChangeResult type={this.props.state} {...props} /> } />        
        <Route exact path="/prevent-emails" render={(props) => <PreventEmails type={this.props.state} {...props} /> } />
        <Route exact path="/confirm-account-submission" render={(props) => <ConfirmAccountSubmission type={this.props.state} {...props} /> } />
        <Route exact path="/referrals/dashboard" render={(props) => <Referrals type={this.props.state} {...props} /> } />
        <Route exact path="/referrals/new" render={(props) => <AddReferral type={this.props.state} {...props} /> } />
        <Route exact path="/referrals/details" render={(props) => <CompanyDetails type={this.props.state} {...props} /> } />
        <Route exact path="/referrals/pipeline" render={(props) => <Pipeline type={this.props.state} {...props} /> } />
        <Route path="/referrals/view/:id/" render={(props) => <ReferralDetails type={this.props.state} {...props} /> } />
        <Route
          path="/contact"
          render={props => (
            <ShowModal modal='checkEligibilityHelp' redirect={''} />
          )}
        />

        <Route
          exact
          path="/login"
          render={props => (
            <Redirect to={`/` + getQueryVariable(props.location.search, 'next')} />
          )}
        />
        <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy/> } />

        {/* For testing purposes only - will throw an error to test if the error boundry is working as expected */}
        <Route exact path="/throw-error" render={(props) => <CreateErrorPage/> } />

      <Route component={()=>
      (
      <main className="page-not-found">
        <div className="main-container">
          <div className="center">
            <div class="illustration"></div>
            <h4>404 Page not Found</h4>
            <p>Why not search to identify a suitable home loan?</p>
          </div>
        </div>
      </main>
      )} />

      </Switch>
    );
  }
};

Routes = connect(mapStateToProps, null)(Routes);

export default withRouter(props => <Routes {...props}/>);

// export default connect(mapStateToProps, null)(Routes);

const CreateErrorPage = () => {

  throw new Error("This error has been created for testing purposes");
  return (
    <main>
      <h1>Error!</h1>
    </main>
  );
}
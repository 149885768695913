import styled, { css } from 'styled-components';

import { EditableParagraph as EP } from 'Components/Forms';
import { lighten } from "polished";

export const Text = styled.div`
  ${p => p.color && css`color: ${p.color};`}
  text-align: ${p => p.align || 'none'};
  display: ${p => p.type || 'block'};
  font-size: ${p => p.size || '16px'};
  font-weight:  ${p => p.weight || '400'};
  margin-bottom: ${p => p.marginBottom || '0'};
  line-height: ${p => p.lineHight || '22px'};
`;

export const Container = styled.div`
    ${p => p.active ? css`z-index: 110;` : ``}
    position: relative;
    margin-bottom: 60px;
`;

export const Heading = styled.h1`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const SmallHeading = styled.h2`
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 16px !important;
`;

export const FlexRow = styled.div`
  ${p => p.width && css`width: ${p.width};`}
  display: flex;
  margin: ${p => p.margin || '40px 0 40px 0'};
`;

export const FlexColumn = styled.div`
  display: inline-block;
  width: ${p => p.width || '33%'};
`;

export const BlueSeperator = styled.span`
  display: inline-block;
  width: 3px;
  height: 22px;
  margin: 0 20px 0 20px;
  background-color: #2291FF;
  vertical-align: top; 
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Footnote = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  a {
    color: #575E6C;
    text-decoration: underline;
  }
`;

export const EditableParagraph = EP;

export const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 645px) {
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  width: calc(50% - 20px);

  @media (max-width: 780px) {
    width: calc(100% - 270px - 20px);
  }

  @media (max-width: 645px) {
    width: 100%;
    order: 1;
  }
`;

export const Button = styled.button`
  margin-top: 20px; 
  width: 183px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  padding: 12px 0 12px 0;
  color: white;
  border: none; 
  background-color: ${p => p.color};
  cursor: pointer;

  transition: 0.5s background-color;

  &:focus, &:hover {
    background-color: ${p => lighten(0.05, p.color)};
  }

  &:active {
    background-color: ${p => lighten(0.05, p.color)};
  }
`;
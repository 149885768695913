import React from "react"; import { Component } from "react";
import Help from "../Components/Help";
import { Line } from 'react-chartjs-2';

import RefinancingForm from "../Forms/RefinancingForm";
import Disclaimer from 'Components/Global/GlobalDisclaimer';
import formatNumber from 'utils/formatNumber';

class Refinancing extends Component {
  state = {}

  onChange = values => 
    this.setState({...values});

  render() {
    const {
      loanSchedule = [],
      newLoanSchedule = [],
      showResults,
      yearOneSavings = 0,
      repaymentSavings = 0,
      termExtension = 0,
      monthlyRepayments = 0,
      newMonthlyRepayments = 0,

      annualFee = 0,
      newAnnualFee = 0,
      switchingFee = 0,
      loanPeriod = 30,
      totalSavings = 0,
      chartLength = 30,
    } = this.state;

    //console.log('schedule', loanSchedule);
    const chartLabels = (chartLength < 4) ? [...Array(chartLength  * 12 + 1).keys()] : [...Array(chartLength  * 12 + 1).keys()].map(n => '' + Math.floor(n / 12));
    const labelPeriod = (chartLength < 4) ? 'Month' : 'Year';
    var options = {
        lineHeightAnnotation: {
          always: false,
          hover: true,
          color: '#aaa',
          lineWeight: 1,
          noDash: true,
        },
        scales: {
          xAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0
              },
              scaleLabel: {
                display: true,
                labelString: labelPeriod + 's',
              }
          }],
          yAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  callback: function(label, index, labels) {
                      if (label > 999999) {
                        return '$' + label/1000000 + 'M';
                      } else {
                        return '$' + label/1000 + 'k';
                      }
                  },
                  scaleLabel: {
                      display: true,
                      labelString: '1k = 1000'
                  },
                  autoSkip: true,
                  maxTicksLimit: 6
                }
            }]
        },
        legend: {
            display: false,
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return labelPeriod + ' ' + data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              var label = Math.round(tooltipItem.yLabel * 100) / 100
              var comma = label.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              return '$' + comma + ' savings';
            },
          },
          backgroundColor: '#fff0',
          //borderColor: '#E9EBF1',
          //borderWidth: 1,
          intersect: false,
          titleFontFamily: "'Lato', sans-serif",
          titleFontSize: 11,
          titleFontStyle: 400,
          titleFontColor: '#727C8F',
          bodyFontColor: '#101922',
          bodyFontFamily: "'Lato', sans-serif",
          bodyFontSize: 12,
          bodyFontStyle: 600,
          displayColors: false,
          caretPadding: 30,
          caretSize: 15,
          cornerRadius: 4,
          xPadding: 10,
          yPadding: 10
        },
        elements: { point: { radius: 50 } },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
    }
    let data;
    if (loanSchedule.length) {
      data = [...Array(chartLength * 12 + 1).keys()]
      .map(index => ({
        x: index,
        y: ((loanSchedule[index].totalInterestRepayment - newLoanSchedule[index].totalInterestRepayment) - switchingFee + (annualFee - newAnnualFee) * index / 12) || 0
      }));
    } else {
      data = [...Array(chartLength * 12 + 1).keys()]
      .map(index => ({
        x: index,
        y: 0,
      }));
    }
    this.data = (canvas) => {
      const ctx = canvas.getContext("2d")
      const gradient = ctx.createLinearGradient(150.000, 0.000, 150.000, 300.000);
      gradient.addColorStop(0.2, 'rgba(234,245,255,1)');
      gradient.addColorStop(1, 'rgba(255,255,255,0)');
      return {
        labels: chartLabels,
        datasets: [
          {
            label: 'My First dataset',
            lineTension: 0.1,
            backgroundColor: gradient,
            borderColor: 'rgba(34,145,255,1)',
            borderWidth: 2,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'bevel',
            //pointBorderColor: 'rgba(34,145,255,1)',
            //pointBackgroundColor: 'rgba(34,145,255,1)',
            // pointBorderWidth: 1,
            pointBorderWidth: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(34,145,255,1)',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            fill: 'origin',
            data,
          }

        ]
      }
    };
    
    

    return(
      <div className="refinancing">
        <h1>How much can I save by refinancing?</h1>
        <h2>Please enter your loan details below.</h2>
        <div className="content">

          <div className="left-content">
            <div className={"module your-results " + (showResults ? '' : 'collapsed')}>
              <div className="results">
                <h2>Your Results</h2>
                <div className="total-savings">
                  <h3>${formatNumber(yearOneSavings, 0)}</h3>
                  <p>Year 1 Interest & Fee Savings</p>
                </div>
                <div className="monthly-savings">
                  <h3>${formatNumber(repaymentSavings , 0)}</h3>
                  <p>Monthly Savings</p>
                </div>
                {!!termExtension &&
                  <div className="loan-term">
                    <h3>{Math.abs(termExtension)} {Math.abs(termExtension) !== 1 ? 'years' : 'year'}</h3>
                    <p>Loan term {termExtension > 0 ? 'increased' : 'decreased'} by</p>
                  </div>
                }
              </div>
              <div className="chart">
                <div className={(totalSavings > 0)?"potential-savings positive":"potential-savings negative"}>
                    <h2>${formatNumber(totalSavings, 0)}</h2>
                    <p>Total Interest Savings<br />Over {chartLength} Years</p>
                </div>
                <Line data={this.data} options={options}/>
              </div>
              {/* <LinkButton to="/search-loans" className="search-loans">Search Loans</LinkButton>
              <p className="search-loan-description">Run a search based on your new loan criteria</p> */}
            </div>

            <div className="module">
              <RefinancingForm onValueChange={this.onChange}/>
            </div>

            <div className="references">Please note that this calculator is only designed to provide you with a rough indication of savings and does not
            constitute personal advice. Your actual repayments and savings may vary. For the sake of simplicity, we have
            assumed that any fixed and/or interest only periods are rolled over for the life of the loan.</div>
          </div>

          <div className="right-content">
            <div className="image"></div>
            <Help />
          </div>

        </div>
        <Disclaimer/>
      </div>
    );
  }
}

export default Refinancing;

import React, { Component } from 'react';

class Select extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      search: '',
    };
  }

  componentWillMount() {
    window.addEventListener('click', this.clickOffComponent);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickOffComponent);
  }

  render() {
    const { isOpen, search } = this.state;
    const { options, isSearchable, width, value, max, dontFilter=[], ...props } = this.props;
    //console.log('search select props:', this.props, options.find(option => option.value === value));
    return (
      <div
        className={`multi-select ${isOpen && 'open'}`}
        style={{ width }}
        ref={node => this.node = node}
        >
        <div className='select-box' onClick={this.toggleOpen}>
          {value != null && (options.find(option => option.value === value) || {}).name || 'Please select an option'}
        </div>

        {
          isOpen && (


            <div className='options-container' style={{ width }}>
              {isSearchable && (
                <div className='options-search'>
                  <input ref={node => this.search = node} type='text' placeholder='Search' onChange={e => this.setState({ search: e.target.value })} />
                </div>
              )}
              <div className='scroll-container'>
                {
                  options
                    .filter(o => !isSearchable || caseInsensitiveIncludes(o.name, search) || dontFilter.some(v => v === o.value))
                    .map((option, index) => (
                      (!max || index < max) &&
                      <SelectOption
                        key={index}
                        {...option}
                        onClick={this.select(option.value)} />
                    ))
                }
              </div>
            </div>
          )
        }
      </div>
    )
  }


  toggleOpen = e => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen, search: '' }, () => {
      if (this.state.isOpen && this.search) this.search.focus();  
    });
  }

  select = value => e => {
    e.preventDefault();
    this.setState({ isOpen: false });
    this.props.onChange(value);
  }

  clickOffComponent = e => {
    if (this.state.isOpen && !this.node.contains(e.target))
      this.setState({ isOpen: false });
  }
}

const caseInsensitiveIncludes = (string, filter) =>
  string && string.toLowerCase().includes(filter.toLowerCase());

const SelectOption = ({ name, onClick }) => (
  <div className='select-option' onClick={onClick}>
    {name}
  </div>
);

export default Select;
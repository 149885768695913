import React from 'react';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux'

import { DoubleFieldGroup, timePeriod } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";
import CurrencyField from '../Components/CurrencyField';

import { createNumberBetween } from 'Components/Forms/validation';

const numberBetween = createNumberBetween(1, 9999999, (low, high) => `Repayment must be between $${low} and $${high}`);

let CombinedIncome = function(props) {

  const skip = () => {
    props.change('user_meta-refinanceRepayments', '');
    props.skip();
  }

  return (
    <QuestionWrapper  isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
        <h1>What is your current repayment?</h1>
        <h2>This is an optional question. If you’re not sure, an approximation is fine.</h2>
        <div className='onboarding-currency'>
          <DoubleFieldGroup id='user_meta-refinanceRepayments' width='large' position='left' hideOptional type='monetary-time' mask='int' />
        </div>
        <Navigation optional skip={skip} hidden={props.pristine } name="Next Step →" />
    </QuestionWrapper>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-repayments',
  initialValues: {
    ['user_meta-refinanceRepaymentsType']: timePeriod.MONTH,
    ['user_meta-refinanceMonthlyRepayment']: 0,
  },
  onChange: (values, dispatch, props) => {
    //console.log('things are changing!');
    if (values['user_meta-refinanceRepayments'])
      dispatch(change('onboarding-repayments', 'user_meta-refinanceMonthlyRepayment', values['user_meta-refinanceRepayments'] / values['user_meta-refinanceRepaymentsType'] / 12));
  }
})(CombinedIncome);

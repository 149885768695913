import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, FieldArray } from 'redux-form';
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';

import { FormContainer, FieldGroup, SelectGroup, DoubleFieldGroup, Heading3, Heading4, Button, UnsavedChangesPrompt, timePeriod, Empty } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import FormNavigation from "../Components/FormNavigation";
import { FadeIn } from './Components/MultiStageForm';

import { createValidateDate, createRequired } from "Components/Forms/validation";

const required = createRequired();
const selectRequired = createRequired('Please select an option');

function mapStateToProps(state) {
  return {
    user: state.user.meta,
    userId: state.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class SimpleForm extends Component {
  
  render() {
    const applicantIncomeFinished = 
      this.props.hasOvertimeCommission === 'No' 
      || !!this.props.bonusOvertimeCommission
      || this.props.employmentStatus === 'Retired' 
      || this.props.employmentStatus === 'Unemployed' 
      || this.props.employmentStatus === 'Maternity Leave'
      || this.props.employmentStatus === 'Homemaker'
      || this.props.selfEmployedIncomeYearBefore != null;

    const partnerIncomeFinished =
      this.props.applicantType !== "youAndYourPartner"
      || this.props.partnerHasOvertimeCommission === 'No' 
      || !!this.props.partnerBonusOvertimeCommission
      || this.props.partnerEmploymentStatus === 'Retired' 
      || this.props.partnerEmploymentStatus === 'Unemployed' 
      || this.props.partnerEmploymentStatus === 'Maternity Leave'
      || this.props.partnerEmploymentStatus === 'Homemaker'
      || this.props.partnerSelfEmployedIncomeYearBefore != null;

    //console.log(applicantIncomeFinished, partnerIncomeFinished);

    return (
      <FormContainer name='check-eligibility-income' extended onSubmit={this.props.handleSubmit}>

        <h1>Income</h1>
        {/* {"#: " + this.props.user.partner_otherIncome_Count}
        {"#: " + this.props.user.individual_otherIncome_Count} */}
        <div style={{width: '580px'}}>
          <p className='under-heading'>Please provide your income details below. This information is necessary for us to confirm serviceability for your selected loan products.</p>
        </div>

        <Heading3 width='large' position='left' title='Your Income' />
        <div className='extra-margin'/>
        <SelectGroup required id='user_meta-employmentStatus' width='small' position='left' type='select' title='What is your employment status?' values={['Salaried Employee','Self-Employed','Retired','Unemployed','Maternity Leave','Homemaker']} />
        {this.props.employmentStatus === 'Salaried Employee' &&
          <FadeIn>
            <div className='extra-margin'/>
            <SelectGroup required id='user_meta-qualifyMeEmploymentType' width='small' position='left' type='select' title='What is your employment type?' placeholder='Full-time' values={['Full-time','Part-time','Casual','Contract']} />
            
            {
              !!this.props.employmentType &&
              <FadeIn>
                <div className='extra-margin'/>
                <DoubleFieldGroup required id='user_meta-salaryBeforeTax' width='small' position='left' type='monetary-time' title='What is your salary before tax, excluding super?' mask='monetary' placeholder='0' mask="monetary" />
                
                {!!this.props.salaryBeforeTax &&
                  <FadeIn>
                    <div className='extra-margin'/>
                    <SelectGroup required id='user_meta-hasOvertimeCommission' width='small' position='left' type='select' title='Do you receive bonus, overtime or commission?' values={['Yes', 'No']} />
                    
                    {this.props.hasOvertimeCommission === 'Yes' &&
                      <FadeIn>
                        <div className='extra-margin'/>
                        <DoubleFieldGroup required id='user_meta-bonusOvertimeCommission' width='small' position='left' type='monetary-time' title='What bonus, overtime or commission do you receive?' mask='monetary' placeholder='0' mask="monetary" />
                        
                      </FadeIn>
                    }
                    {(this.props.hasOvertimeCommission === 'No' || !!this.props.bonusOvertimeCommission) &&
                      <FadeIn>
                        <div className='extra-margin'/>
                        <FieldGroup id='user_meta-jobTitle' width='small' position='left' type='text' title='What is your job title?' placeholder='E.g. Manager' fill='fill' />
                      
                        <div className='extra-margin'/>
                        <SelectGroup empty hideOptional id='user_meta-hasOtherIncome' width='small' position='left' type='select' title='Do you have any other forms of income?' values={['No', 'Yes']} />
                        {toggleOtherIncome(this.props.hasOtherIncome, this, this.props.user.individual_otherIncome_Count)}
                      </FadeIn>
                    }
                  </FadeIn>
                } 
              </FadeIn>
            }
          </FadeIn>
        }

        {(this.props.employmentStatus === 'Retired' 
          || this.props.employmentStatus === 'Unemployed' 
          || this.props.employmentStatus === 'Maternity Leave'
          || this.props.employmentStatus === 'Homemaker') &&
          <FadeIn>            
            <div className='extra-margin'/>
            <SelectGroup empty hideOptional id='user_meta-hasOtherIncome' width='small' position='left' type='select' title='Do you have any other forms of income?' values={['No', 'Yes']} />
            {toggleOtherIncome(this.props.hasOtherIncome, this, this.props.user.individual_otherIncome_Count)}
          </FadeIn>
        }
        
        {this.props.employmentStatus === 'Self-Employed' &&
          <FadeIn>
            <div className='extra-margin'/>
            <DoubleFieldGroup required id='user_meta-selfEmployedIncomeLastYear' width='small' position='left' type='monetary-time' title='What was your self-employed income last financial year?' mask='monetary' placeholder='0' mask="monetary" tooltip='selfEmployedIncome' />
            
            {!!this.props.selfEmployedIncomeLastYear &&
              <FadeIn>
                <div className='extra-margin'/>
                <DoubleFieldGroup required id='user_meta-selfEmployedIncomeYearBefore' width='small' position='left' type='monetary-time' title='What was your self-employed income the year before last?' mask='monetary' placeholder='0' mask="negativeMonetary" />

                {this.props.selfEmployedIncomeYearBefore != null &&
                  <FadeIn>
                    <div className='extra-margin'/>
                    <SelectGroup empty hideOptional id='user_meta-hasOtherIncome' width='small' position='left' title='Do you have any other forms of income?' type='select' values={['No', 'Yes']} />
                    {toggleOtherIncome(this.props.hasOtherIncome, this, this.props.user.individual_otherIncome_Count)}
                  </FadeIn>
                }
              </FadeIn>
            } 
            </FadeIn>
        }

        
        {applicantIncomeFinished && togglePartner(this.props.applicantType, this.props)}
        {applicantIncomeFinished && partnerIncomeFinished && 
          <FadeIn>
            <FormNavigation currentPage="4" disabled={this.props.invalid} submitting={this.props.submitting}/>
          </FadeIn>
        }
        {!(applicantIncomeFinished && partnerIncomeFinished) && 
          <div style={{ width: '100%', height: '35vh' }} />
        }
        <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting) }/>
      </FormContainer>
  );
}
}

function togglePartner(applicantType, props){
  if (applicantType === "youAndYourPartner") {
    return (
      <div>
        <Heading3 width='large' position='left' title='Your Partners Income' />
        <div className='extra-margin'/>
        
        <SelectGroup required id='user_partner_meta-employmentStatus' width='small' position='left' type='select' title="What is your partner's employment status?" values={['Salaried Employee','Self-Employed','Retired','Unemployed','Maternity Leave','Homemaker']} />
        {props.partnerEmploymentStatus === 'Salaried Employee' &&
          <FadeIn>
            <div className='extra-margin'/>
            <SelectGroup required id='user_partner_meta-qualifyMeEmploymentType' width='small' position='left' type='select' placeholder='Full-time' title="What is your partner's employment type?" values={['Full-time','Part-time','Casual','Contract']} />
            
            {
              !!props.partnerEmploymentType &&
              <FadeIn>
                <div className='extra-margin'/>
                <DoubleFieldGroup required id='user_partner_meta-salaryBeforeTax' width='small' position='left' type='monetary-time' title="What is your partner's salary before tax, excluding super?" mask='monetary' placeholder='0' mask="monetary" />

                {!!props.partnerSalaryBeforeTax &&
                  <FadeIn>
                    <div className='extra-margin'/>
                    <SelectGroup required id='user_partner_meta-hasOvertimeCommission' width='small' position='left' type='select' title='Does your partner receive bonus, overtime or commission?' values={['Yes', 'No']} />

                    {props.partnerHasOvertimeCommission === 'Yes' &&
                      <FadeIn>
                        <div className='extra-margin'/>
                        <DoubleFieldGroup required id='user_partner_meta-bonusOvertimeCommission' width='small' position='left' type='monetary-time' mask='monetary' placeholder='0' title='What bonus, overtime or commission does your partner receive?' mask="monetary" />
                      
                      </FadeIn>
                    }
                    {(props.partnerHasOvertimeCommission === 'No' || !!props.partnerBonusOvertimeCommission) &&
                      <FadeIn>
                        <div className='extra-margin'/>
                        <FieldGroup id='user_partner_meta-jobTitle' width='small' position='left' type='text' title="What is your partner's job title?" placeholder='E.g. Manager' fill='fill' />
                        
                        <div className='extra-margin'/>
                        <SelectGroup empty hideOptional id='user_partner_meta-hasOtherIncome' width='small' position='left' type='select' title='Does your partner have any other forms of income?' values={['No', 'Yes']} />
                        {toggleOtherIncomePartner(props.partnerHasOtherIncome)}
                      </FadeIn>
                    }
                  </FadeIn>
                } 
              </FadeIn>
            }
          </FadeIn>
        }

{(props.partnerEmploymentStatus === 'Retired' 
  || props.partnerEmploymentStatus === 'Unemployed' 
  || props.partnerEmploymentStatus === 'Maternity Leave'
  || props.partnerEmploymentStatus === 'Homemaker') &&
  <FadeIn>
    <div className='extra-margin'/>
    <SelectGroup empty hideOptional id='user_partner_meta-hasOtherIncome' width='small' position='left' type='select' title='Does your partner have any other forms of income?' values={['No', 'Yes']} />
    {toggleOtherIncomePartner(props.partnerHasOtherIncome)}
  </FadeIn>
}

{props.partnerEmploymentStatus === 'Self-Employed' &&
  <FadeIn>
    <div className='extra-margin'/>
    <DoubleFieldGroup required id='user_partner_meta-selfEmployedIncomeLastYear' width='small' position='left' type='monetary-time' title="What was your partner's self-employed income last financial year?" mask='monetary' placeholder='0' mask="monetary"  tooltip='selfEmployedIncome' />

    {!!props.partnerSelfEmployedIncomeLastYear &&
      <FadeIn>
        <div className='extra-margin'/>
        <DoubleFieldGroup required id='user_partner_meta-selfEmployedIncomeYearBefore' width='small' position='left' type='monetary-time' title="What was your partner's self-employed income the year before last?" placeholder='0' mask="negativeMonetary" />

        {props.partnerSelfEmployedIncomeYearBefore != null &&
          <FadeIn>
            <div className='extra-margin'/>
            <SelectGroup empty hideOptional id='user_partner_meta-hasOtherIncome' width='small' position='left' type='select' title='Does your partner have any other forms of income?' values={['No', 'Yes']} />
            {toggleOtherIncomePartner(props.partnerHasOtherIncome)}
          </FadeIn>
        }
      </FadeIn>
    } 
    </FadeIn>
}
        
      </div>
    );
  } else {
    return "";
  }
}

function toggleOtherIncome(otherIncome){
  if (otherIncome === "Yes") {
    return (
      <FadeIn>
        <Heading4 width='large' position='left' content='Please provide your other income details below.'/>
        <FieldArray component={OtherIncome} name='user_meta-otherIncomeItems'/>
      </FadeIn>
    )
  }
  else {
    return (
      ""
    )
  }
}

const initialOtherIncome = {
  incomeAmountType: timePeriod.MONTH,
}

class OtherIncome extends Component {
  
  componentWillMount() {
    const { fields } = this.props;
    if (!fields.length) {
      fields.push();
    }
  }

  render() {
    const { fields } = this.props;
    return (
      <FadeIn>
        {(fields).map((item, index) => (
          <FadeIn>
            <div key={index} className='field-array other-income'>
              <SelectGroup id={`${item}.incomeType`} hideOptional width='small' position='left' title='Other Income Type' type='select' placeholder='Share Dividends' values={['Annuity Income','Child Support / Maintenance','Foreign Pension Income','Income Protection Payments','Managed Fund Distributions','Share Dividends','Superannuation / Pension Income','Trust Distributions','Welfare Payments (Centerlink DVA etc)', 'Other']} />      
              {!!fields.get(index) && fields.get(index).incomeType === 'Other' ?
                <Fragment>
                  <Empty width='small' position='right'/>
                  {fields.length > 1 && <button type='button' className="delete" onClick={() => fields.remove(index)}></button>}
                  <FieldGroup required id={`${item}.description`} width='small' position='left' type='text' title='Other Income Description'/>
                  <DoubleFieldGroup hideOptional id={`${item}.incomeAmount`} width='small' position='right' title='Other Income Amount' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
                </Fragment>
                :
                <Fragment>
                  <DoubleFieldGroup hideOptional id={`${item}.incomeAmount`} width='small' position='right' title='Other Income Amount' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
                  {fields.length > 1 && <button type='button' className="delete" onClick={() => fields.remove(index)}></button>}
                </Fragment>
              }
            </div>
          </FadeIn>
        ))
        }
        <Button onClick={() => fields.push(initialOtherIncome)} type='button' width='small' position='left' title='Add Other Income' />
      </FadeIn>  
    )
  }
}

function toggleOtherIncomePartner(otherIncome){
  if (otherIncome === "Yes") {
    return (
      <FadeIn>
        <Heading4 width='large' position='left' content='Please provide your other income details below.'/>
        <FieldArray component={OtherIncome} name='user_partner_meta-otherIncomeItems'/>
      </FadeIn>
    )
  }
  else {
    return (
      ""
    )
  }
}


// function addOtherIncome(value, a){
//   // var i;
//   var numberOfProperties = parseInt(value, 10);
//   return (
//     <div>

//     {
//       (numberOfProperties)?(
//         [...Array(numberOfProperties).keys()].map((i)=>(
//         <div>
//           <SelectGroup id={'user_income-'+ (i+1) + '-incomeType'} width='small' position='left' title='Other Income Type' required='Yes' type='select' placeholder='Share Dividends' values={['Annuity Income','Child Support / Maintenance','Foreign Pension Income','Income Protection Payments','Managed Fund Distributions','Share Dividends','Superannuation / Pension Income','Trust Distributions','Welfare Payments (Centerlink DVA etc)']} />
//           <DoubleFieldGroup id={'user_income-'+ (i+1) + '-incomeAmount'} width='small' position='right' title='Other Income Amount' required='Yes' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
//           {/* <div onClick={()=>a.UserOtherIncome("remove")} className="remove">AS</div> */}
//         </div>
//         ))
//       ):""}
//     </div>
//   );
// }



SimpleForm = reduxForm({
  form: 'eligibility-income',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-income') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // external values
    const applicantType = state.user.applications[0].meta.applicantType;
    
    // internal values
    const hasOtherIncome = selector(state, 'user_meta-hasOtherIncome');
    const partnerHasOtherIncome = selector(state, 'user_partner_meta-hasOtherIncome');
    const employmentStatus = selector(state, 'user_meta-employmentStatus');
    const partnerEmploymentStatus = selector(state, 'user_partner_meta-employmentStatus');
    const employmentType = selector(state, 'user_meta-qualifyMeEmploymentType');
    const partnerEmploymentType = selector(state, 'user_partner_meta-qualifyMeEmploymentType');
    const salaryBeforeTax = selector(state, 'user_meta-salaryBeforeTax');
    const partnerSalaryBeforeTax = selector(state, 'user_partner_meta-salaryBeforeTax');
    const hasOvertimeCommission = selector(state, 'user_meta-hasOvertimeCommission');
    const partnerHasOvertimeCommission = selector(state, 'user_partner_meta-hasOvertimeCommission');
    const bonusOvertimeCommission = selector(state, 'user_meta-bonusOvertimeCommission');
    const partnerBonusOvertimeCommission = selector(state, 'user_partner_meta-bonusOvertimeCommission');
    const selfEmployedIncomeLastYear = selector(state, 'user_meta-selfEmployedIncomeLastYear');
    const partnerSelfEmployedIncomeLastYear = selector(state, 'user_partner_meta-selfEmployedIncomeLastYear');
    const selfEmployedIncomeYearBefore = selector(state, 'user_meta-selfEmployedIncomeYearBefore');
    const partnerSelfEmployedIncomeYearBefore = selector(state, 'user_partner_meta-selfEmployedIncomeYearBefore');
    return {
      selfEmployedIncomeLastYear,
      partnerSelfEmployedIncomeLastYear,
      selfEmployedIncomeYearBefore,
      partnerSelfEmployedIncomeYearBefore,
      employmentStatus,
      partnerEmploymentStatus,
      employmentType,
      partnerEmploymentType,
      salaryBeforeTax,
      partnerSalaryBeforeTax,
      hasOvertimeCommission,
      partnerHasOvertimeCommission,
      bonusOvertimeCommission,
      partnerBonusOvertimeCommission,
      applicantType,
      hasOtherIncome,
      partnerHasOtherIncome,
      initialValues: {
        'user_meta-salaryBeforeTaxType': timePeriod.YEAR,
        'user_meta-bonusOvertimeCommissionType': timePeriod.YEAR,
        'user_meta-selfEmployedIncomeLastYearType': timePeriod.YEAR,
        'user_meta-selfEmployedIncomeYearBeforeType': timePeriod.YEAR,
        'user_meta-hasOtherIncome': 'No',
        'user_partner_meta-salaryBeforeTaxType': timePeriod.YEAR, 
        'user_partner_meta-bonusOvertimeCommissionType': timePeriod.YEAR,
        'user_partner_meta-selfEmployedIncomeLastYearType': timePeriod.YEAR,
        'user_partner_meta-selfEmployedIncomeYearBeforeType': timePeriod.YEAR,
        'user_partner_meta-hasOtherIncome': 'No',
        ...getInitialValuesObject(state), 
        "user_meta-eligibility_complete_page4": "1", 
        "user_meta-eligibility_last_page": 4}
    }
  }
)(SimpleForm);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleForm);

import React from "react"; import { Component } from "react";

class CheckEligibilityOpen extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityOpen") {
    return (
      <div className="modal-content">
       <img src={require('../../../img/modal/checkeligibilityopen.png')} alt="checkeligibilityopen"/>
        <h1>You already have<br />an open eligibility assessment.</h1>
        <p>We are already looking into your situation.</p>
        <div className="box-text">If you would like us to look at another loan product for you or would like to discuss something else, please get in touch by clicking the button below.</div>
        <div className="modal-navigation">
          <button onClick={()=>this.props.closeModal()} className="double left button-5">I'm fine for now</button>
          <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="double right button-1">Get in Touch</button>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default CheckEligibilityOpen;

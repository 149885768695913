import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { createRequired, createValidateEmail } from 'Components/Forms/validation';
import { reduxForm, Field, SubmissionError } from 'redux-form';

import Navigation from "Components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import { requestSecureLink } from "modules/user/actions";
import LinkButton from "Components/LinkButton";
import Debouncer from "utils/debouncer";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

const debouncer = new Debouncer();

const emailRequired = createRequired('Please enter your email');
const emailValidation = createValidateEmail();

function mapDispatchToProps(dispatch) {
  return bindActionCreators({    
    requestSecureLink
  }, dispatch);
}

class SecureLinkComponent extends Component {

  state = { submitted: false, email: '' }

  submit = ({ email }) => {
    return new Promise((resolve, reject) => 
      debouncer.run(() => {
        this.props.requestSecureLink({
          email, callback: response => {
            if (response.err) {
              return reject( new SubmissionError({
                _error: 'An account with this email does not exist',
              }));
            }
            this.setState({ submitted: true, email });
            return resolve();
          }
        })
      }, 5000)
    );
    
  }

  resetMagicLink() {
    this.setState((prevState) => {return {...prevState, submitted: false, email: ''}});
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    const { submitted } = this.state;
    return (
      <div>
        {submitted ? (
            <div className="magic">
              <h2>Please check your e-mail</h2>
              <h4>We have sent a magic link to your e-mail address.<br/>Check your spam folder if you do not see it. </h4>
              <form>
                <button type='submit' onClick={()=>this.resetMagicLink()} to="/secure-link/"><span>Resend Magic Link</span></button>
              </form>
              {/* <h4>An email with a link to login to your account has been sent to: {this.state.email}<br/><br/></h4> */}
            </div>
              ) : (
            <div className="magic" style={{ position: 'relative' }}>
              <h2>Magic Link</h2>
              <h4>Please enter your e-mail address below and we will send you a magic link to automatically login.</h4>
              <form onSubmit={handleSubmit(this.submit)}>
                <label>Email Address</label>
                <Field component={Input} name="email" placeholder="Enter e-mail address" validate={[emailRequired, emailValidation]} />
                {error && <div className="validation" style={{position: 'absolute'}}>{error}</div>}
                <button type="submit"><ButtonSpinner loading={submitting}/><span>Send Magic Link</span></button>
                  
              </form>
            </div>
        )}
      </div>
    );
  }
}

const Input = ({meta: {touched, error}, input, formError, ...props}) => {
  return ( 
  <Fragment>
    <input
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <div className='field-error-message'>{error}</div>} 
  </Fragment>
  )
};

SecureLinkComponent = reduxForm({
  form: 'login-secure_link',
})(SecureLinkComponent)

const stateToProps = state => {
  return {
    initialValues: {
      email: ((state.form.login || {}).values || {}).email,
    }
  };
}

export default connect(stateToProps, mapDispatchToProps)(SecureLinkComponent);

import styled from 'styled-components'

export const Icons = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  margin-right: 10px;
  width: 16px;
  height: 18px;
  background-color: ${props => props.color};
  -webkit-mask-image: url(${require('../../../img/proposal/tab.svg')});
  mask-image: url(${require('../../../img/proposal/tab.svg')});
  mask-repeat: no-repeat;
  mask-size: 16px 18px;
  mask-position: 0% 50%;
`;
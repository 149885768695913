import React from "react"; import { Component } from "react";
import { reduxForm, getFormValues, change } from "redux-form";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import updateFormValues from "modules/updateFormValues";
import { requestPatchUser } from 'modules/user/actions';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import initializeFormValues from "utils/initializeFormValues";
import { openRefinanceModal, closeModal } from 'modules/modals/actions';
import styled, { css } from 'styled-components'

import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, Subtitle, SearchableSelectGroup, RateFieldGroup } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

const $ = window.$;

function mapStateToProps(state) {
  // console.log(state);
  return {
    user: state.user.meta,
    lenders: state.user.lenders,
    userId: state.user.id,
    formValues: state.form['current-loan'] && state.form['current-loan'].values || undefined,
    product: state.modals.args,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    change,
    requestPatchUser,
    openRefinanceModal,
    closeModal,
  }, dispatch);
}

const LOAN_AMOUNT = 'user_meta-refinanceLoanAmount';
const INTEREST_RATE = 'user_meta-refinanceInterestRate';
const REPAYMENT_TYPE = 'user_meta-refinanceRepaymentType';
const LOAN_PERIOD = 'user_meta-refinanceYears';
const MONTHLY_REPAYMENT = 'user_meta-refinanceMonthlyRepayment';
const LENDER = 'user_meta-refinanceLender';
const OTHER_LENDER_NAME = 'user_meta-refinanceOtherLenderName';

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(interestRate / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = interestRate * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}


class CurrentLoanDetails extends Component {
  state = {
    monthlyRepayments: 0,
  }

  componentDidMount() {

    initializeFormValues("current-loan", (vals, state) => {
      const user = state.user.meta; 
      const { calculators } = state.user.meta;
      
      const values = {
        [LOAN_AMOUNT]: calculators.refinanceLoanAmount || user.loanAmount,
        [INTEREST_RATE]: calculators.refinanceInterestRate,
        [REPAYMENT_TYPE]: calculators.refinanceRepaymentType,
        [LOAN_PERIOD]: calculators.refinanceYears,
        [MONTHLY_REPAYMENT]: calculators.refinanceMonthlyRepayment,
        ...vals,
        "user_meta-initialLoanDetails": "1",
      }

      //console.log('Form values', values);

      const monthlyRepayments = calculateRepayments({
        loanAmount: values[LOAN_AMOUNT] || 0,
        interestRate: values[INTEREST_RATE] / 100 || 0,
        repaymentType: values[REPAYMENT_TYPE],
        loanPeriod: values[LOAN_PERIOD] || 30,
      });

      this.setState({monthlyRepayments});

      return values;
    }, [OTHER_LENDER_NAME]);

    
  }

  componentWillReceiveProps(nextProps) {
    const newValues = nextProps.formValues;
    const values  = this.props.formValues;
    //console.log('values', this.props.formValues);
    if (!values) return;
    if (
      values[LOAN_AMOUNT] !== newValues[LOAN_AMOUNT] ||
      values[INTEREST_RATE] !== newValues[INTEREST_RATE] ||
      values[REPAYMENT_TYPE] !== newValues[REPAYMENT_TYPE] ||
      values[LOAN_PERIOD] !== newValues[LOAN_PERIOD]
    ) {
      const monthlyRepayments = calculateRepayments({
        loanAmount: newValues[LOAN_AMOUNT] || 0,
        interestRate: newValues[INTEREST_RATE] / 100 || 0,
        repaymentType: newValues[REPAYMENT_TYPE],
        loanPeriod: newValues[LOAN_PERIOD] || 30,
      });
      if (!values[MONTHLY_REPAYMENT] || values[MONTHLY_REPAYMENT] === this.state.monthlyRepayments) {;
        this.props.change(MONTHLY_REPAYMENT, monthlyRepayments);
      }

      this.setState({monthlyRepayments});
    }

  }
  
  onRepaymentBlur = e => {
    if (!this.props.formValues[MONTHLY_REPAYMENT]) {
      this.props.change(MONTHLY_REPAYMENT, this.state.monthlyRepayments);
      e.preventDefault();
    }
  }

  onSubmit = results => {
    return new Promise(resolve => {
      updateFormValues(results, ()=>{
        if (this.props.location.pathname === '/loan-shortlist') {
          this.props.openRefinanceModal(this.props.product)
        } else {
          this.props.closeModal();
        }
        resolve();
        setTimeout(() => {
          if ($('.refinance-content').length) {
            $('main').animate({
                scrollTop: $('.refinance-content').offset().top - $('main').offset().top + $('main').scrollTop() - 90
            }, 400);
          }
        }, 10)

      }, false, false);
    })
    
  }

  

  render() {
    const lenderOptions = [
      {name: "Other", value: 'other'},
      ...this.props.lenders
        .filter(({ id }) => id !== 'MSL') // filter out Mystery Lender
        .sort((a, b) => a.name.localeCompare(b.name))
        .map( ({ id, name }) => ({ name, value: id }))
    ];
    
    
    return (

      <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)} className="form your-details" >
        <FieldGroup id={LOAN_AMOUNT} width='large' position='left' title='What is your current loan amount?' required='No' type='text' placeholder='0' mask="monetary" />
        <RateFieldGroup id={INTEREST_RATE} width='large' position='left' title='What is your current interest rate?' required='No' />
        <SelectGroup id={REPAYMENT_TYPE} bottomLabel="If you're not sure, just pick Principal &amp; Interest." width='large' position='left' title='What is your repayment type?' required='No' type='select' placeholder='Single' values={[{text: 'Principal & Interest', value: 'PandI'}, {text: 'Interest Only', value: 'Interest'}]}/>
        <FieldGroup id={LOAN_PERIOD} width='large' position='left' title='What is the remaining loan term?' type='text' placeholder='30' maxNumber={99} mask='int'/>
        <FieldGroup id={MONTHLY_REPAYMENT} width='large' position='left' title='What is your approximate monthly repayment?' required='No' type='text' placeholder='0' mask="monetary" autoCalculated={this.props.formValues && this.state.monthlyRepayments === this.props.formValues[MONTHLY_REPAYMENT]} onBlur={this.onRepaymentBlur}/>
        <SearchableSelectGroup size='large' id={LENDER} width={380} position='left' title='Who is your current lender?' type='select' values={lenderOptions} dontFilter={['other']}/>
        {(this.props.formValues || {})[LENDER] === 'other' &&
          <FieldGroup id={OTHER_LENDER_NAME} width='large' position='left' title='What is your current lenders name?' required type='text'/>
        }
        
        <button className="analyse"><ButtonSpinner loading={this.props.submitting}/>{this.props.button}</button>
      </FormContainer>

    );
  }
}

CurrentLoanDetails = reduxForm({
  form: "current-loan", // a unique identifier for this form
})(CurrentLoanDetails);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentLoanDetails)
);
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import ProposalRebateForm from "./ProposalRebateForm"
import { connect } from 'react-redux';

export default class ProposalRebate extends Component {
  render() {
    if (this.props.currentModal === "ProposalRebate") {
    return (
      <div className="modal-content">
        <h1>Rebate Details</h1>
        <ProposalRebateForm tabId={this.props.tabId} productId={this.props.productId}/>
      </div>
    ) } else {
      return "";
    }
  }
}

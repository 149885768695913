import React from 'react';
import store from 'store';
import { emailService } from 'modules';
import styled from 'styled-components';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      
      emailService.create({
        type: 'error-report',
        store: JSON.stringify(store.getState()),
        error: error.message,
        stack: info.componentStack || '',
      });
    }
  
    render() {

      function refreshPage() {
        window.location.reload(false);
      }

      if (this.state.hasError) {
        return (
          <Container>
            <div>
              <H1><span class="orange-excl">!</span>Something went wrong</H1>
              <P>There has been an error with your request. Please reload the page and try again.</P>
              <Button onClick={refreshPage}>Reload</Button>
            </div>
          </Container>
        )
      }
      return this.props.children;
    }
  }

  const H1 = styled.h1`
  display: inline-block;
    font-weight: 600;
    font-family: Lato,sans-serif;
    font-size: 24px;
    margin-bottom: 25px;
    line-height: 1.4;

    span {
      display: inline-block;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      cursor: pointer;
      background-color: #f5a623;
      margin-left: 10px;
      font-weight: 900;
      z-index: 99;
      margin-right: 10px;
      pointer-events: none;
      height: 22px;
      width: 22px;
      line-height: 22px;
      font-size: 12px;
      vertical-align: middle;
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  `;

  const P = styled.p`
  display: inline-block;
    font-weight: 400;
    font-family: Lato,sans-serif;
    font-size: 16px;
    color: #575e6c;
    line-height: 1.4;
    margin-bottom: 20px;
  `;

  const Button = styled.a`
  display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: inherit;
    font-size: 16px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 13px 50px;
    color: #fff;
    background-color: #2291ff;
    transition: background .5s;
    font-weight: 600;
    font-family: Lato,sans-serif;
    font-size: 14px;

    &:hover {
      background-color: #46a3ff;
    }

    &:active {
      background-color: #1c83ea;
    }
  `;

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div {
      text-align: center;
      max-width: 460px;
    }
  `;

  const Text = styled.div`
    font-size: 25px;
  `;


  export default ErrorBoundary;
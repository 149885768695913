import React from "react"; import { Component } from "react";
import { NavLink } from "react-router-dom";

import requestFilteredProducts from "modules/product/requestFilteredProducts";
import { PasswordField } from "Components/Forms";
const $ = window.$;
class LoginModal extends Component {

  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  componentDidMount() {
    $('.username').find('input').focus();
  }

  handleSubmit = (e) => {
    this.props.requestLogin({
      callback: (result) => {
        if (!result.success) {
          alert(result.message);
        } else {
          requestFilteredProducts();
        }
      },
      data: {
        email: this.state.username,
        password: this.state.password,
      },
    });
    e.preventDefault();
  }

  render() {
    if (this.props.currentModal === "login") {
    return (
      <form onSubmit={this.handleSubmit} >
        <label>Email Address</label>
        <input name="username" onChange={e => this.setState({ username: e.target.value })} placeholder="Enter Email" />
        <br />
        <label className="password">Password</label>
        <NavLink className="forgot-password" exact to="/forgot-password">Forgot Password?</NavLink>
        <PasswordField name="password" onChange={e => this.setState({ password: e.target.value })} placeholder="Enter Password" />
        <button type="submit">Sign In to My Account</button>
      </form>
    ) } else {
      return "";
    }
  }
}

export default LoginModal;

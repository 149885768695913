export const mapToInitialValues = function(user) {
  let initialValues = {};
  for (let key in user) {
    initialValues["user-" + key] = user[key];
  }
  if (user.meta) {
    for (let key in user.meta) {
      initialValues["user_meta-" + key] = user.meta[key];
    }
  }
  if (user.applications) {
    for (let key in user.applications[0]) {
      initialValues["application-" + key] = user.applications[0][key];
    }
  }
  return initialValues;
}

import React from "react"; import { Component } from "react";

class Divider extends Component {
  render() {
    return (
      <div className="or-divider">
        <span>OR</span>
        <hr />
      </div>
    );
  }
}

export default Divider;

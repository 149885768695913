import React from "react"; import { Component } from "react";
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { TextArea } from "Components/Forms";
import { SmallProductBox } from "Components/Product"
import FormNavigation from "../Components/FormNavigation";
import { FormContainer, UnsavedChangesPrompt } from "Components/Forms";
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';
import getInitialValuesObject from "utils/getInitialValuesObject";

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  return {
    shortlist: state.user.shortlist,
    user: state.user.meta,
    products,
    application
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class SimpleForm extends Component {
  render() {
  return (
    <div className="form finish" >
      <h1>Final Steps</h1>

      <h3>Based on a $400,000 loan amount</h3>
      <ul className="summary-results">
        <li>You are looking to buy a property in St Ives, valued at approximately <strong>$600,000</strong></li>
        <li>You wish to borrow <strong>$400,000</strong> repaying <strong>Principal & Interest</strong> over <strong>30 years</strong></li>
        <li>That you have approximately a <strong>$200,000 deposit</strong></li>
      </ul>

      <h3>Loan Products</h3>
      <div className="selection">
      {this.props.products.map((product)=>
        <SmallProductBox 
          application={this.props.application}
          removeProductFromApplication={this.props.removeProductFromApplication}
          productLength={this.props.products.length}
          closeModal={this.props.closeModal}
          nsrValues={this.props.nsrValues} product={product} />)}

      </div>

      <h3>Additional Products</h3>
      <p>If you have any additional comments or queries about your application, please let us know below.</p>
      <TextArea id='user_meta-additionalComments' width='wide' position='left' required='Yes' type='text' placeholder='' />
      
      <FormContainer onSubmit={this.props.handleSubmit}>
        <FormNavigation currentPage="8" submitting={this.props.submitting} />
        <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting) }/>
      </FormContainer>
    </div>
  );
}
}

SimpleForm = reduxForm({
  form: 'eligibility-finish',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-finish') // <-- same as form name
SimpleForm = connect(
  state => {
    return {
      initialValues: {...getInitialValuesObject(state), "user_meta-eligibility_complete_page8": "1", "user_meta-eligibility_last_page": 8}
    }
  }
)(SimpleForm);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleForm);
import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, Form, change as changeForm } from 'redux-form';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { Header, Subtitle } from "../../theme/General";
import SectionButton from "../../component/SectionButton";
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import calculateTrueRate from "utils/calculateTrueRate";
import formatNumber from 'utils/formatNumber';
import parseNumber from 'utils/parseNumber';

import _ from 'lodash';

class Property extends React.Component {

  toggleEdit(type) {
    const { property } = this.props;
    const loanAmountEnabled = Number.isInteger(property.loanAmountEnabled) ? property.loanAmountEnabled : property.loanAmountEnabled ? 1 : 0;
    switch (type) {
      case "address": return this.patchProposal({ propertyDetail: { addressEnabled: !property.addressEnabled } });
      case "loanAmount": return this.patchProposal({ propertyDetail: { loanAmountEnabled: (loanAmountEnabled + 1) % 3 } });
      case "image": return this.patchProposal({ propertyDetail: { image: !property.image } });
    }

  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  save = values => {
    let freshValues = {...this.props.formValues};
    const currentLoan = this.props.loan;
    delete freshValues.addressEnabled;
    delete freshValues.loanAmountEnabled;
    delete freshValues.image;
    delete freshValues.enabled;

    freshValues.value = formatNumber(parseInt(freshValues.value.replace(/\D/g,''))) || '';
    this.props.change('value', freshValues.value);
    const newLoanAmount = parseInt(freshValues.loanAmount.replace(/\D/g,''));
    this.props.change('loanAmount', formatNumber(newLoanAmount) || '');
    console.log('House Values:', values, freshValues);

    let monthlyRepayment = currentLoan.monthlyRepayment;

    if (currentLoan.loanAmount !== newLoanAmount) {
      const currentMonthlyRepayments = calculateRepayments({
        loanAmount: currentLoan.loanAmount || 0,
        interestRate: currentLoan.interestRate / 100 || 0,
        repaymentType: currentLoan.repaymentType,
        loanPeriod: currentLoan.loanPeriod || 30,
      });
  
      const difference = Math.abs(currentMonthlyRepayments - currentLoan.monthlyRepayment);
      if (difference < 1) {
        monthlyRepayment = calculateRepayments({
          loanAmount: newLoanAmount || 0,
          interestRate: currentLoan.interestRate / 100 || 0,
          repaymentType: currentLoan.repaymentType,
          loanPeriod: currentLoan.loanPeriod || 30,
        });
      }

      const loans = this.props.miniProposal.properties[this.props.tabId].loanComparison.loans;
        const loanChanges = {};
        for (const key in loans) {
          const loan = loans[key];
          const originalMonthlyFee = (parseNumber(loan.original_ongoing_fees) + parseNumber(loan.originalApproxUpfrontFee) / 30) / 12;
          let originalTrueRate = calculateTrueRate({ 
            rate: parseFloat(loan.originalIIR) / 100, 
            monthlyFee: originalMonthlyFee, 
            loanAmount: newLoanAmount,
            loanPeriod: currentLoan.loanPeriod || 30,
            fixedTerm: loan.fixed_term / 12,
            rr: (loan.originalRR || loan.rr) / 100,
          });
          if (!originalMonthlyFee) originalTrueRate = loan.originalIIR;
          
          let monthlyFee = (parseNumber(loan.ongoing_fees) + parseNumber(loan.approxUpfrontFee) / 30) / 12;
          if (!monthlyFee) monthlyFee = 0;
          let trueRate = calculateTrueRate({ 
            rate: parseFloat(loan.iir) / 100, 
            monthlyFee, 
            loanAmount: newLoanAmount,
            loanPeriod: currentLoan.loanPeriod || 30,
            fixedTerm: loan.fixed_term / 12,
            rr: loan.rr / 100,
          });
          
          const loanChange = loanChanges[key] = {}
          loanChange.trueRate = trueRate;
          loanChange.originalTrueRate = originalTrueRate;
          
          this.props.changeForm('loan-' + this.props.tabId + '-' + currentLoan.productId, 'trueRate', trueRate);

          console.log("Proposed loan changes:", loanChanges);
        }
        this.patchProposal({ loanComparison: { loans: loanChanges } });
    }
    

    this.patchProposal({ propertyDetail: freshValues, currentLoan: { monthlyRepayment, loanAmount: parseInt(freshValues.loanAmount.replace(/\D/g,'')) } });
    this.props.initialize(freshValues);
    
    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => this.props.requestPatchUser({ 
    userId: this.props.user.id,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [this.props.tabId]: data
          }
        }
      } 
    },
    callback, 
  });

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { user, property, miniProposal, loan, formValues, EditMode } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (!this.props.isView) return null;
    return (
      <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        <ProposedOptionsContainer isPDF={this.props.isPDF} active={this.props.active} isView={this.props.isView}>
          
            {!noGodmode && !!user.godmode && !miniProposal.previewMode && !this.props.isPDF &&
              <SectionButton
              tabId={this.props.tabId}
              toggleAddress={() => this.toggleEdit("address")}
              toggleLoanAmount={() => this.toggleEdit("loanAmount")}
              toggleImage={() => this.toggleEdit("image")}
              removeSection={this.props.removeSection}
              enableEdit={this.props.toggleEdit}
              isEditable={this.props.active}
              cancelEdit={() => this.cancel()}
              type="property" style={{ float: 'right', position: 'absolute', top: '0', right: '0' }} />
            }
            <CenterContent>
              <Header isPDF={this.props.isPDF} style={{ marginTop: '0'}}>
                <Span as={EditableContent} name='title' disabled={!this.props.active} onEnter={this.blurOnEnter}/>
              </Header>
            </CenterContent>
            {property.addressEnabled ?
              <CenterContent>
                <Subtitle><Span as={EditableContent} name='address' disabled={!this.props.active} onEnter={this.blurOnEnter}/></Subtitle>
              </CenterContent>
              : ""}
            <PropertyDetails className="property-details">
              <div className="left">
                {property.image ?
                  <img src={require('../../../../img/proposal/house.png')} alt="house" />
                  :
                  <img src={require('../../../../img/proposal/apartment.png')} alt="apartment" />
                }
              </div>
              <div className="right">
              <div className="container-right">
                <RateContainer icon={require('../../../../img/elements/repayment.png')} >
                <Value><span>$</span><Span as={EditableContent} name='value' disabled={!this.props.active} onEnter={this.blurOnEnter}/></Value>
                  <div class="value-description">Approximate Value</div>
                </RateContainer>
                {!!property.loanAmountEnabled && (property.loanAmountEnabled === 1 ?
                  <RateContainer icon={require('../../../../img/elements/comparison-rate.png')} >
                    <Value>
                      <span>$</span><Span as={EditableContent} name='loanAmount' disabled={!this.props.active} onEnter={this.blurOnEnter}/>
                      {(loan.loanAmount !== parseInt((formValues.loanAmount || '0').replace(/\,/g, '')) && EditMode && !noGodmode) && <WarningDot/>}
                    </Value>
                    <div class="value-description">Current Loan Amount</div>
                  </RateContainer>
                  : 
                  <RateContainer icon={require('../../../../img/elements/comparison-rate.png')} >
                    <Value>
                      <span>$</span><Span as={EditableContent} name='loanAmount' disabled={!this.props.active} onEnter={this.blurOnEnter}/>
                      {(loan.loanAmount !== parseInt((formValues.loanAmount || '0').replace(/\,/g, '')) && EditMode && !noGodmode) && <WarningDot/>}
                    </Value>
                    <div class="value-description">New Loan Amount</div>
                  </RateContainer>
                ) }
              </div>
              </div>
            </PropertyDetails>
          
        </ProposedOptionsContainer>
      </Form>
    )
  }

}

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50px;
  font-weight: 700;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const WarningDot = () => <OrangeDot>!</OrangeDot>

const OrangeDot = styled.span`
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background-color: #F5A623;
    margin-left: 10px;
    font-weight: 900;
    z-index: 99;
    margin-right: 10px;
    pointer-events: none;
    height: 14px;
    width: 14px;
    line-height: 14px;
    font-size: 8px;
    vertical-align: middle;
`;

const Value = styled.div`
  outline: none;
  font-size: 24px;
  font-weight: 700;
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PropertyDetails = styled.div`
  //margin-bottom: 35px;
`;

const ProposedOptionsContainer = styled.div`
  position: relative;
  max-width: 500px;
  
  margin: 0 auto 0 auto;
  
  ${p => !p.isPDF && css`
    margin: 25px auto 0 auto;
  `}

  
  transition: max-height 0.2s;
  max-height: 1000px;
  

  ${props => props.active && css`
    z-index:  500;
  `}

  .property-details {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .left, .right {
      margin-bottom: 35px;
      display: block;
      width: 100%;
      min-width: 200px;
    }

    .left {
      margin-right: 20px;

      img {
        display: block;
        object-fit: contain;
        width: 100%;
        min-width: 150px;
        max-width: 165px;
        margin: 0 auto;
      }
    }

    .right {

      .container-right {
        width: 200px;
        margin: 0 auto;
      }
    }
  }

  ${props => !props.isView && css`
    max-height: 0;
    overflow: hidden;
  `}
`;

const RateContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  padding-left: 50px;
  background-image: url(${props => props.icon});

  &:last-of-type {
    margin-bottom: 0;
  }

  .value {
    font-size: 24px;
    font-weight: 700;
  }

  .value-description {
    font-size: 12px;
    font-weight: 400;
    color: #727C8F;
    margin-top: 8px;
  }
`;


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = (state, props) => {
  let property = state.user.meta.miniproposal.properties[props.tabId].propertyDetail;
  property = {...property, loanAmount: formatNumber(props.loan.loanAmount)};
  return {
    user: state.user,
    EditMode: state.user.godmode && !state.user.meta.miniproposal.previewMode,
    miniProposal: state.user.meta.miniproposal,
    property,
    initialValues: property,
    formValues: (state.form[props.form] || {}).values || {},
    browser: state.browser,
  }
};

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(interestRate / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = interestRate * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  //form: 'property',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Property));


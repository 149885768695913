import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux';

import IntroLetter from './sections/OverviewSections/IntroLetter';
import WhyLoanBase from './sections/OverviewSections/WhyLoanbase';
import TheTeam from './sections/OverviewSections/TheTeam';
import Objectives from './sections/OverviewSections/Objectives';
import LendingOverview from './sections/OverviewSections/LendingOverview';
import DetailedBreakdown from './sections/OverviewSections/DetailedBreakdown';
import NextSteps from './sections/OverviewSections/NextSteps';
import AdditionalDetails from './sections/OverviewSections/AdditionalDetails';
import BeyondSettlement from './sections/OverviewSections/BeyondSettlement';
import { Rebates } from './sections/OverviewSections/LendingOverviewSections/Rebates';
import AboutLender from './sections/OverviewSections/AboutLender';
import { Aggregates } from './sections/OverviewSections/Aggregates';
import { calculateProductValues } from 'utils/CalculateProductValues';

import { Img } from 'Components/Global/Image';
import imgButtonArrow from 'img/button/right-arrow.svg';

const $ = window.$;

export const SECTION = {
    NONE: -1,
    INTRO: 1,
    WHY_LOANBASE: 2,
    THE_TEAM: 3,
    OBJECTIVES: 4,
    LENDING_OVERVIEW: 5,
    NEXT_STEPS: 6,
    AGGREGATES: 7,
    ADDITIONAL_DETAILS: 8,
    BEYOND_SETTLEMENT: 9,
    REBATES: 10,
}

const Image = styled(Img)`
  width: 100px;
`;

const Summary = ({ tabs, showBreakdown, editMode }) => {

    let { loans, banks, miniProposal, lenders } = useSelector(state => {
        const miniProposal = state.user.meta.miniproposal;
        const lenders = state.user.lenders;
        return {...getLoanData(miniProposal), miniProposal, lenders };
    });
    
    const [ activeSection, setActiveSection ] = useState(SECTION.NONE);

    const sectionProps = useCallback(section => ({
        active: activeSection === section,
        toggleActive: () => setActiveSection(section),
        cancelEdit: () => setActiveSection(SECTION.NONE),
        editMode,
        loans,
        banks,
    }), [activeSection, loans, banks]);
    
    let proposalLenders = Array.isArray(miniProposal?.tabs)
    ? miniProposal.tabs.map(id => {
        const tab = miniProposal?.properties?.[id]; // Safely access properties
        if (!tab || tab.excludedFromOverview || !tab?.loanComparison?.recommendationActive) return null; // Ensure loanComparison exists
  
        const loanId = tab?.loanComparison?.loanOrder?.[0]; // Safely access loanOrder
        if (!loanId) return null;
  
        const loan = tab?.loanComparison?.loans?.[loanId]; // Safely access loans
        return loan || null; // Return loan or null if it doesn't exist
      })
      .filter(loan => loan !== null && lenders.some(x => x.id === loan.bank)) // Filter valid loans and banks
      .reduce((acc, loan) => {
        const bank = lenders.find(x => x.id === loan.bank);
        if (bank?.description) { // Ensure bank and description exist
          acc[loan.bank] = loan.bank_name; // Accumulate bank details
        }
        return acc;
      }, {})
    : {};
  
  
    proposalLenders = Object.keys(proposalLenders)
      .map(id => ({ id, name: proposalLenders[id] }));

    const aboutLender = miniProposal.overview?.aboutLender ?? {};
    const aboutLenderIds = (proposalLenders ?? [])
      .map(lender => lender.id)
      .filter(id => aboutLender[id] ?? true);

    
    return (
        <>
            <Overlay disabled={activeSection === SECTION.NONE}/>
            {showBreakdown &&
              <GotoButtonContainer>
                <GotoButton onClick={() => scrollTo('next-steps')}>Jump To Next Steps <ButtonArrow/></GotoButton>
                <GotoButton onClick={() => scrollTo('lending-overview')}>Jump To Solution <ButtonArrow/></GotoButton>
              </GotoButtonContainer>
            }
            <IntroLetterContainer active={activeSection === SECTION.INTRO}>
                <IntroLetter {...sectionProps(SECTION.INTRO)} />
                {/* <IntroLetterImg/> */}
            </IntroLetterContainer>
            <DetailsContainer>
                <WhyLoanBase {...sectionProps(SECTION.WHY_LOANBASE)}/>
                <TheTeam />
                <Seperator/>
                <Objectives {...sectionProps(SECTION.OBJECTIVES)}/>
                <Seperator/>
                <LendingOverview {...sectionProps(SECTION.LENDING_OVERVIEW)} />
                <Rebates {...sectionProps(SECTION.REBATES)} />
                {miniProposal.overview?.additionalDetails?.isActive &&
                  <AdditionalDetails {...sectionProps(SECTION.ADDITIONAL_DETAILS)}/>
                }
                <Aggregates {...sectionProps(SECTION.AGGREGATES)}/>
                <Seperator/>

                {showBreakdown && <>
                  <DetailedBreakdown tabs={tabs}/>
                  <Seperator/>
                </>}

                {!!aboutLenderIds?.length && <>
                  <AboutLender ids={aboutLenderIds} banks={banks} />
                  <Seperator/>
                </>}
                
                {(miniProposal.overview?.beyondSettlement?.isActive ?? true) && <>
                  <BeyondSettlement {...sectionProps(SECTION.AGGREGATES)}/>
                  <Seperator/>
                </>}
                
                
                <NextSteps {...sectionProps(SECTION.NEXT_STEPS)}/>
            </DetailsContainer>
        </>
    );
};

export default Summary;

const scrollTo = dest => {
  const offset = $('#' + dest).offset().top;
  $('main').animate({
      scrollTop: offset - 125
  }, offset / 10);
}
/*
  const getLoanData = miniProposal => {
      let loans = [];
      for (const id of (miniProposal.tabs ?? [])) {
        const tab = miniProposal.properties[id];
        if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) continue;
        const loanComparison = tab.loanComparison;
        const currentLoan = tab.currentLoan;
        const loanId = loanComparison.loanOrder[0];
        if (!loanId) continue;
        const newLoan = calculateProductValues(loanComparison.loans[loanId], currentLoan);
    
        if (currentLoan) {
          loans.push({
            newLoan,
            currentLoan,
            tabId: id,
            loanId,
            name: tab.name,
            cashback: tab.overviewCashback,
          });
        }
      }
  */

      const getLoanData = (miniProposal) => {
        let loans = [];
        
        // Ensure miniProposal.tabs is an array before iterating
        if (Array.isArray(miniProposal.tabs)) {
          for (const id of miniProposal.tabs) {
            const tab = miniProposal.properties[id];
            if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) continue;
      
            const loanComparison = tab.loanComparison;
            const currentLoan = tab.currentLoan;
            const loanId = loanComparison.loanOrder[0];
            if (!loanId) continue;
            const newLoan = calculateProductValues(loanComparison.loans[loanId], currentLoan);
      
            if (currentLoan) {
              loans.push({
                newLoan,
                currentLoan,
                tabId: id,
                loanId,
                name: tab.name,
                cashback: tab.overviewCashback,
              });
            }
          }
        }
      
    const banks = loans.reduce((obj, loan) => {
      const bank = loan.newLoan.bank;
      return ({
        ...obj,
        [bank]: [...(obj[bank] || []), loan.newLoan],
      });
    }, {});
  
    return { loans, banks };
  }


const GotoButtonContainer = styled.div`
  margin-bottom: 15px;

  display: flex;
  flex-direction: row-reverse;
`;

const GotoButton = styled.button`
  margin-left: 20px;
  font-family: Lato, sans-serif;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  width: 175px;
  height: 44px;
  line-height: 44px;
  border-radius: 4px;
  border: solid 1px #d8dce7;
  background: #ffffff;
  box-shadow: 0px 1px #e9ebf1;

  font-size: 14px;
  font-weight: 400;
  color: #171728;

  cursor: pointer;
`;

const ButtonArrow = styled.img.attrs({
  src: imgButtonArrow,
})`
  position: relative;
  top: 1px;
  margin-left: 8px;
  height: 11px;
`;

const IntroLetterImg = styled.img.attrs({
  src: require('img/page_illustrations/intro_letter.png'),
})`
  z-index: 5;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 666px;
  min-height: 393px;
  max-width: 536px;
  
  @media (max-width: 780px) {
    height: 70%;
  }

  @media (max-width: 645px) {
    display: none;
  }
`;

const IntroLetterContainer = styled.div`
      
    ${p => p.active ? css`z-index: 110;` : ``}
    position: relative;
    overflow: hidden;
    min-height: 300px;
    background: #2A82AF;
    padding: 50px 60px 50px 60px;
    background-position: 100% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    color: #F6F7FB !important;
    font-size: 16px;

    @media (max-width: 780px) {
      padding: 50px 30px 50px 30px;
      background-size: auto 70%;
    }

    @media (max-width: 645px) {
      background-image: url(${require('img/brand/logo_white.png')});
      background-size: 151px 24px;
      background-position: calc(100% - 50px) calc(100% - 50px);
    }

    @media (max-width: 480px) {
      padding: 30px 20px 30px 20px;
      background-image: none;
    }

    h1 {
        position: relative;
        z-index: 10;
        font-size: 26px;
        margin-bottom: 16px;
    }

    p {
        position: relative;
        z-index: 10;
        color: #F6F7FB !important;
    }
    
    ol {
      color: #F6F7FB !important;
    }
`;

const DetailsContainer = styled.div`
    color: #575E6C;
    background: #FFFFFF;
    border: 1px solid #D8DCE7;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 60px 60px 0 60px;

  @media (max-width: 780px) {
    padding: 60px 30px 0 30px;
  }

  @media (max-width: 645px) {
    padding: 40px 30px 0 30px;
  }

  @media (max-width: 480px) {
    padding: 30px 20px 0 20px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  ${props => !props.disabled && css`
    opacity: 1;
    display: block;
    pointer-events: auto;
  `}
`;

const Seperator = styled.div`
    border-top: solid 1px #B3BECC;
    height: 1px;
    width: 100%; 
    margin-bottom: 60px;

    @media (max-width: 645px) {
      display: none;
    }

    ${props => props.last && css`

      @media (max-width: 780px) {
        margin-top: -50px;
      }

      @media (max-width: 645px) {
        display: block;
      }
    `}
`;



import React from 'react';
import { reduxForm } from 'redux-form';

import { SelectGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

const selectValues = ['None', '1', '2', '3', '4', '5', '6+'];

let HowManyProperties = function(props) {
  return (
    <QuestionWrapper alwaysRender={props.alwaysRender} isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
      <h1>How many other properties do you own?</h1>
      <h2>Some lenders offer better rates or cashbacks if you refinance additional properties.</h2>
      <SelectGroup id='user-additionalProperties' style={{ width: '140px' }} default='None' empty width='small' position='left' hideOptional values={selectValues}/>
      <Navigation name="Next Step →" />
    </QuestionWrapper>
  )
}

HowManyProperties = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-additional-properties'  // a unique identifier for this form
})(HowManyProperties);

export default HowManyProperties;

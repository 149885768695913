import React from "react";
import { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SMSCode from "Components/Global/SMSCode"
import { openModal, closeModal } from 'modules/modals/actions';
import { verificationService } from "modules";
import { requestPatchUser } from 'modules/user/actions';


const stateToProps = state => ({
  products: state.products,
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    closeModal,
    openModal,
  }, dispatch);
}


const getDigitCounts = number => {
  // will return a Map of digits and their count in a string
  // e.g. 0400000022 = Map({ '0': 7, '2': 2, '4': 1 })
  const digitCounts = new Map();

  for (const d of number) {
    const count = digitCounts.get(d) || 0;
    digitCounts.set(d, count + 1);
  }

  return digitCounts;
}

const numberIsFake = number => {

  // mobile numbers with only one repeated number after the prefix are fake
  // e.g. 04222222222 = fake, 04221221222 = real
  if (number.startsWith('04')) {
    const stripped = number.replace('04', '');
    return getDigitCounts(stripped).size === 1;
  }
  if (number.startsWith('+614')) {
    const stripped = number.replace('+614', '');
    return getDigitCounts(stripped).size === 1;
  }
  const digitCounts = getDigitCounts(number);
  
  // if number only has 1 repeated digit it's probably fake, if it has more than 2 it's OK
  // 4444444444444 = fake, 123123123123 = real
  if (digitCounts.size !== 2) return digitCounts.size < 2;

  // if it has 2 unique digits check if one digit is used only once, if so it's probably fake
  // 030000000000 = fake, 0303030303030 = real,
  return [...digitCounts.values()].some(count => count === 1);
}


class SMSVerification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstAttempt: props.user.smsAttempts === 0,
    }
  } 

  async componentDidMount() {
    const { user, requestPatchUser } = this.props;
    if (numberIsFake(user.phoneNumber)) return;
    
    
    if (user.smsAttempts === 0) {

      const result = await verificationService.create({
        type: 'send-sms-verification',
        phoneNumber: user.phoneNumber,
        userId: this.props.user.id,
      });
      if (!result.error) {
        requestPatchUser({
          data: {
            smsAttempts: user.smsAttempts + 1,
          }
        });
      }
    }
  }

  render() {
    const { user, products } = this.props;
      const isFake = numberIsFake(user.phoneNumber);
      const canSend = this.state.firstAttempt ? !(user.smsAttempts > 4) : !(user.smsAttempts > 3)
 

      return (
        <div className="modal-content">
          <div className="left">
            <img src={require('../../../img/page_illustrations/sms.png')} alt="Refinance" />
          </div>
          {/* Implement Logic here */}
          {(canSend) ?
            <div className="right">
              <h1>We’ve identified {products.total} loans that may be suitable for you.</h1>
              {isFake ?
                <p>Oops! It looks like something isn't right with the phone number you entered. We use two-factor authentication to secure your account. Please re-enter your number and you should receive a code via SMS momentarily.</p>
                :
                <p>We use two-factor authentication to secure your account. You should receive a code via SMS momentarily.</p>
              }
              <SMSCode />
            </div>
            :
            <div className="right trouble-validate">
              <h1>Sorry we couldn’t validate you.</h1>
              <p>It looks like we’re having trouble validating your phone number. If you’d like to request a call back from one of our expert brokers, please click the button below.</p>
              {/* Make sure clicking close goes back to SMS Verification Modal */}
              <button className="request-callback" onClick={()=>this.props.openModal("checkEligibilityHelp")} >Request Callback</button>
            </div>
          }
        </div>
      )
  }
}

export default connect(stateToProps, mapDispatchToProps)(SMSVerification);
// Application hooks that run for every service
import checkHost from "utils/checkHost"

const ping = context => {
  if (context.path !== "ping") {
    checkHost();
  }
  return context;
};

const hooks = {
  before: {
    all: [ping],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};

export default hooks;
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import Account from '../Navigation/Components/Account';
import styled, { css } from 'styled-components'
import { bindActionCreators } from 'redux';
import { openModal } from 'modules/modals/actions';
import { closeModal } from 'modules/modals/actions';

const $ = window.$;

function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist || [],
    user: state.user.meta,
    isClientReferrer: state.user.isClientReferrer,
    application: state.user.applications[0],
    godmode: state.user.godmode,
    browser: state.browser,
    isProfessionalReferrer: state.user.isProfessionalReferrer,
    settings: state.user.globalSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    closeModal,
  }, dispatch);
}

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggleAccount: false,
      greaterThanW1440: this.props.browser.greaterThan.w1440,
    };
  }

  toggleAccount() {
    console.log("CLI");
    this.setState({ toggleAccount: !this.state.toggleAccount });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.browser.greaterThan.w1440 !== prevState.greaterThanW1440) {
      $('.sidebar').addClass('small');
    }
    else {
      $('.sidebar').removeClass('small');
    }
  }

  

  render() {
    const { user, godmode, isProfessionalReferrer, settings, isClientReferrer } = this.props;

    let referralNavActive = false;

    if (window.location.pathname.includes('referrals') && isProfessionalReferrer) {
      referralNavActive = true;
    }

    let proposalActive = false;
    if (user.proposal && user.proposal.enabled) {
      const value = user.proposal.expiry;
      if (value) {
        const day = parseInt(value.slice(0, 2));
        const month = parseInt(value.slice(2, 4));
        const year = parseInt(value.slice(4, 8));
        const expiry = new Date(year, month - 1, day);
        if (expiry > new Date()) proposalActive = true;
      }
    }
    if ((user.miniproposal || {}).draftMode && ((user.miniproposal || {}).enabled || godmode)) {
      proposalActive = true;
    }
    //debugger;
    const clientReferralsActive = settings.client_referrals_active || isClientReferrer;

    return (
      <div className={'sidebar' + (this.props.isInProposal ? ' proposal' : '')}>
        {(this.props.state === "mobile") &&
          <>
            <div style={{ padding: '0 40px', margin: '40px 0 0 0' }}>
              <AccountStyled style="mobile" onClick={() => this.toggleAccount()} active={!this.state.toggleAccount} />
              <Line style={{ margin: '20px 0 0 0' }} />
            </div>
          </>
        }
        <ClientNavigation active={!referralNavActive}>
          <ul>
            {proposalActive && 
              <li className="menu-item-0"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/proposal"><span>Proposal</span><span className="condensed">Proposal</span><span className='proposal-pill'>!</span></NavLink></li>
            }
            {(this.props.browser.lessThan.w780) ||
              <li className="menu-item-1"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/dashboard"><span>Dashboard</span><span className="condensed">Dash</span></NavLink></li>
            }
            <li className="menu-item-3"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/search-loans"><span>Search Loans</span><span className="condensed">Search</span></NavLink></li>
            {(this.props.browser.lessThan.w780) ||
              <li className="menu-item-4"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/loan-shortlist">
                <span>Loan Shortlist</span><span className="condensed">Shortlist</span>
                {this.props.shortlist.length > 0 &&
                  <span className='shortlist-pill'>{(this.props.shortlist.length < 9) ? this.props.shortlist.length : "9+"}</span>
                }
              </NavLink></li>
            }
            {(this.props.browser.lessThan.w780) ||
              <li className="menu-item-5">
                <NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/check-eligibility"><span>Qualify Me</span><span className="condensed">Qualify</span>
                  <span className='check-eligibility-pill'>{((this.props.user.eligibilityStart == "1") && (!(this.props.application ?? {}).list)) ? "!" : ""}</span>
                </NavLink>
              </li>
            }
            {(this.props.browser.lessThan.w780) ||
              <li className="menu-item-2"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/calculators"><span>Calculators</span><span className="condensed">Calcs</span></NavLink></li>
            }
            {/* {(this.props.browser.lessThan.w780) ||
              <li className="menu-item-6"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/help"><span>Help</span><span className="condensed">Help</span></NavLink></li>
            } */}
            {(this.props.browser.lessThan.w780) || <>
              {isProfessionalReferrer ?
                <li className="menu-item-12"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/referrals/dashboard"><span>Referral Platform</span><span className="condensed">Referrals</span></NavLink></li>
                :
                clientReferralsActive ? <li className="menu-item-8"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" to="/referrals/dashboard"><span>Refer a Friend</span><span className="condensed">Referrals</span></NavLink></li> : ''
              }
            </>}
          </ul>
          
          {(this.props.state === "mobile") &&
            <>
              <div style={{ padding: '0 40px', margin: '35px 0 60px 0' }}>
                <Line style={{ margin: '0 0 20px 0' }} />
                <Button onClick={()=>this.props.openModal("checkEligibilityHelp")} className='chat-button'>Contact Us</Button>
              </div>
            </>
          }
        </ClientNavigation>
        <ReferrerNavigation active={referralNavActive}>
          <ul>
            <li className="menu-item-1"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/referrals/dashboard"><span>Referral Dashboard</span><span className="condensed">Dash</span></NavLink></li>
            <li className="menu-item-9"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/referrals/pipeline"><span>Deal Pipeline</span><span className="condensed">Pipeline</span></NavLink></li>
            <li className="menu-item-10"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/referrals/new"><span>Refer New Client</span><span className="condensed">Refer</span></NavLink></li>
            <li className="menu-item-11"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/referrals/details"><span>Your Details</span><span className="condensed">Details</span></NavLink></li>
            <li className="menu-item-13"><NavLink onClick={this.props.closeMobileMenu} activeClassName="current" exact to="/search-loans"><span>Client Platform</span><span className="condensed">Client</span></NavLink></li>
          </ul>
        </ReferrerNavigation>
      </div>
    );
  };
}

const ClientNavigation = styled.nav`
  position: absolute;
  width: 260px;
  transition: all 1s ease-in-out;
  ${p => p.active ? css`
    animation: active1 0.4s ease-in-out forwards;
  ` : css `
    animation: inactive1 0.4s ease-in-out forwards;
  `}

  @keyframes active1 {
    from {left: 260px}
    to {left: 0px}
  }

  @keyframes inactive1 {
    from {left: 0px}
    to {left: 260px}
  }
`;

const ReferrerNavigation = styled.nav`
  position: absolute;
  width: 260px;
  transition: all 1s ease-in-out;
  ${p => p.active ? css`
    animation: active2 0.4s ease-in-out forwards;
  ` : css `
    animation: inactive2 0.4s ease-in-out forwards;
  `}

  @keyframes active2 {
    from {left: -260px}
    to {left: 0px}
  }

  @keyframes inactive2 {
    from {left: 0px}
    to {left: -260px}
  }
`;

const AccountStyled = styled(Account)`
  cursor: pointer;

  .account-details {
    background-image: url(${require('../../img/elements/small-triangle-down.png')});
    background-repeat: no-repeat;
    background-size: 10px 6px;
    background-position: 100% 50%;

    .profile-picture {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;

      .avatar-pic {
        background-color: grey;
        background-position: 50% 50%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        position: relative;
        border-radius: 50%;
        height: 38px;
        width: 38px;

        &.empty {
          line-height: 38px;
          color: #fff;
          text-align: center;
          background-color: #2291FF;
        }
      }
    }

    .name {
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 48px);
      padding-right: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 18px;
    }
  }

  ul.navigation {

    ${props => props.active && css`
      display: none;
    `}

     li {
      text-align: left;
      padding: 20px 0;
      font-size: 16px;
      font-weight: 400;
      color: #D3DEE9 !important;

      &:hover {
        color: #fff !important;
      }

      a { 
        padding: 0 !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        width: inherit !important;
        color: #D3DEE9 !important;

        &:hover {
          background-color: transparent !important;
          color: #fff !important;
        }
      }
    }
  }
`;

const Line = styled.hr`
  border: none;
  height: 1px;
  background: #385673;
`;

const Button = styled.button`
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 13px 25px 13px 25px;
  color: #fff;
  background-color: #213D58;
  border: solid 1px #213D58;
  width: 100%;
`;

// export default connect(mapStateToProps) (Sidebar);
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Sidebar);
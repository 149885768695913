import React from "react";
import { Carousel } from "react-responsive-carousel";

export default () => (
  <Carousel width="380px" showThumbs={false} showIndicators={false}  showStatus={false} infiniteLoop autoPlay>
    <div>
      <img src={require('img/content/signup-slider-n1.jpg')} alt="signup-img" />
    </div>
    <div>
      <img src={require('img/content/signup-slider-n2.jpg')} alt="signup-img" />
    </div>
    <div>
      <img src={require('img/content/signup-slider-n3.jpg')} alt="signup-img" />
    </div>
    <div>
      <img src={require('img/content/signup-slider-n4.jpg')} alt="signup-img" />
    </div>
    <div>
      <img src={require('img/content/signup-slider-n5.jpg')} alt="signup-img" />
    </div>
  </Carousel>
);

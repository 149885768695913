import React from "react"; import { Component } from "react";
import RefinanceAnalysis from "Views/RefinanceAnalysis"
import calculateLoanValues from "utils/calculateLoanValues"

class RefinanceAnalysisModal extends Component {
  render() {
    let product = calculateLoanValues(this.props.result);

    if (this.props.currentModal === "refinanceAnalysis") {
    return (
      <div className="modal-content">
        <div className="refinance-wrapper">
          <RefinanceAnalysis product={product} result={this.props.result} />
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default RefinanceAnalysisModal;

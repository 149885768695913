let query = {};

export default function getQueryPrams(dontRemove) {
    let values = {};
    let queryString = window.location.search;
    if (window.location.search) {
      var vars = queryString.split('?')[1].split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        values[pair[0]] = pair[1];
      }
    }
    
    if(!dontRemove) window.history.replaceState(null, null, window.location.pathname);

    query = {...query, ...values};
    return query;
}
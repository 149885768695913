import React from "react";
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from 'modules/user/actions';
import { Container, Heading, EditableParagraph } from './Shared';
import _ from 'lodash';

const FORM_NAME = "overview-beyond-loanbase";
const BODY_NAME = "overview_beyond_body";

class BeyondSettlement extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  resetSection = () => {
    const body = this.props.globalSettings[BODY_NAME];
    const formValues = { body };
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  }

  save = () => {
    let values = { ...this.props.formValues };
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;

    toSave = {
      overview: {
        beyondSettlement: data
      }
    }

    this.props.requestPatchUser({
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: toSave,
        }
      },
      callback,
    });
  }

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { active, editMode } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    return (
      <Container active={active} style={{margin: '60px 0 60px 0'}}>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>

          {editMode &&
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              type="overview-section"
              style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
              cancelSaveContainer="20px"
            />
          }

        <Column>
            <Heading>Beyond Settlement</Heading>
            <p><EditableParagraph richText={!!active} innerRef={this.inputRef} allowLineBreaks onKeyDown={e => this.onKeyDown(e)} className={active ? 'active' : ''} name='body' disabled={!active} /></p>
        </Column>
        </Form>
      </Container>
    )
  }
}

const Column = styled.div`
    width: 100%;
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = state => {
  const { beyondSettlement } = state.user.meta.miniproposal.overview || {};
  const { globalSettings } = state.user;
  const body = (beyondSettlement || {}).body || globalSettings[BODY_NAME];

  return {
    globalSettings,
    body: ((state.form[FORM_NAME] || {}).values || {}).body,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { body },
    formValues: (state.form[FORM_NAME] || {}).values || {},
    browser: state.browser,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(BeyondSettlement));
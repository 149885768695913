import React from "react"; import { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { requestLogout } from 'modules/user/actions';

function mapStateToProps(state) {
  return {
    user: state.user,
    userMeta: state.user.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogout,
  }, dispatch);
}

class Account extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedOut: false,
    }
  }


  logout() {
    this.props.requestLogout();
  }

  render() {
    let userMeta = this.props.userMeta;
    let user = this.props.user;

    let firstName = user.firstName || "";
    let lastName = user.lastName || "";

    if (this.state.isLoggedOut) {
      return <Redirect to='/' />
    }

    let pictureStyle = {};
    if (user && userMeta && user.profilePictureUrl && !userMeta.profile_picture) {
      pictureStyle = {
        backgroundImage: 'url(' + user.profilePictureUrl + ')',
      };
    }    

    return (
      <div className={"account " + this.props.className}>

        <div className="account-details" onClick={this.props.onClick}>
          <div className="profile-picture">
            {
              user && userMeta && (<div 
                className={"avatar-pic" + (!(userMeta.profile_picture)?" empty":" exists") + " n" + userMeta.profile_picture}
                style={pictureStyle}
                >
                {(userMeta.profile_picture || user.profilePictureUrl)?""
                :
                (firstName ?
                  (firstName.toString().charAt(0).toUpperCase() + (lastName ? lastName.toString().charAt(0).toUpperCase() : ''))
                  :
                  "LB"
                )
                }</div>)
            }
          </div>
          <span className="name">{user && name(firstName, lastName)}</span>
        </div>

        <ul className="navigation">
          {this.props.style === "mobile" || 
            <li><NavLink exact to="/accounts/">Account Settings</NavLink></li>
          }
          <li onClick={()=>{this.logout()}}>Log out</li>
        </ul>
      </div>
    );
  }
}

function name(firstName, lastName) {
  let name = firstName || '';
  if (firstName && lastName) name += ' ';
  if (lastName) name += lastName || '';
  return name || "Secure Finance";
}


export default connect(mapStateToProps, mapDispatchToProps)(Account);

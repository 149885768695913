import React from "react";
import { FieldGroup, FormContainer, Subtitle, Input } from "Components/Forms";

let FundsRequiredForm = (props) => {
  return(
    <div className="form" >
      <div className="your-details">

        <FormContainer>
          <Subtitle width='wide' position='left' title='Government & Lender Fees / Concessions' />

          <FieldGroup id='calculators-totalLenderFees' width='small' position='left' title='Total Lender Fees' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup disabled id='calculators-result_TransferFee' value='10000' width='small' position='right' title='Transfer Fees (Government)' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup disabled id='calculators-result_StampDuty' width='small' position='left' title='Stamp Duty (Government)' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup disabled id='calculators-result_MorgageFee' width='small' position='right' title='Mortgage Fee (Government)' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup disabled id='calculators-result_GovernmentConcessions' width='small' position='left' title='Concessions (Government)' hideOptional type='text' placeholder='0' mask="monetary" />
          
          <Subtitle width='wide' position='left' title='Other Fees' />
          <FieldGroup id='calculators-conveyancingFee' width='small' position='left' title='Conveyancing Fees' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup id='calculators-propertyReport' width='small' position='right' title='Property Report' hideOptional type='text' placeholder='0' mask="monetary" />
          <FieldGroup id='calculators-additionalCost' width='small' position='left' title='Additional Costs' hideOptional type='text' placeholder='0' mask="monetary" />
        </FormContainer>

      </div>
    </div>
  );
}


export default FundsRequiredForm
import 'react-app-polyfill/ie11';
// import 'babel-polyfill';
// import 'core-js/es7/object';
// import 'core-js/es7/array';
// import 'core-js/fn/object/set-prototype-of';
// import 'core-js';


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundry from 'Components/Global/ErrorBoundry';

import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import store from './store';

import { ConnectedRouter } from 'connected-react-router'
import { history } from './modules';

// Turn on google analytics (will not turn on in production)
let debugGA = false;

if (window.location.hostname.indexOf('hosting') === -1 && window.location.hostname.indexOf('stag') === -1) {
  debugGA = false;
}

store.subscribe(() => {
   //console.log("store changes", store.getState())
})

const app = (
  <ErrorBoundry>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundry>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);
//registerServiceWorker();

import React, { Component } from 'react';
import FundsRequiredFormTab1 from "./FundsRequiredFormTab1";
import FundsRequiredFormTab2 from "./FundsRequiredFormTab2";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { change, reduxForm } from 'redux-form';

import Debouncer from "utils/debouncer";
const debouncer = new Debouncer();

class FundsRequiredForm extends Component {
  render() {
    return (
      <div className="module tabs">
        <form onSubmit={this.props.handleSubmit}>
          <Tabs>

            <div className="navigation">
              <TabLink to="tab1" className="tab-navigation">Property Details</TabLink>
              <TabLink to="tab2" className="tab-navigation">Other Expenses</TabLink>
            </div>

            <div className="tab-content">
              <TabContent for="tab1"><div className="tab"><FundsRequiredFormTab1 onSubmit={this.submitTab1} /></div></TabContent>
              <TabContent for="tab2"><div className="tab"><FundsRequiredFormTab2 onSubmit={this.submitTab2}/></div></TabContent>
            </div>

          </Tabs>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: "calculator", // a unique identifier for this form
  onChange: (values, dispatch, props, previousValues) => {
      debouncer.run(props.submit, 300);
  }
})(FundsRequiredForm);
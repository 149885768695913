import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import Tooltips from "Components/Tooltips";

class CalculateBox extends Component {
  render() {
    return(
      <div className="calculate-box">
        <h3>{this.props.title}<Tooltips type="definition" term={this.props.term}/></h3>
        <h6>{this.props.subtitle}</h6>
        <div className="result">
          <h7>Your Result</h7>
          {resultValue(this.props.result)}
        </div>
        {statusType(this.props.status, this.props.link)}
      </div>
  );
}
}

function statusType(value, value2) {
  if (!value) {
    return <LinkButton to={"/calculators/" + value2} className="calculate">Calculate</LinkButton>
  }
  else {
    return <LinkButton to={"/calculators/" + value2}>Go to Calculator</LinkButton>
  }
}

function resultValue(value) {
  if (value !== "") {
    return <h2>${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>;
  }
  else {
    return <h2> — </h2>;
  }
}


export default CalculateBox;

import React from "react"; import { Component, Fragment } from "react";
import { reduxForm, change as changeForm } from "redux-form";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';
import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, SearchableSelectGroup, RateFieldGroup } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import calculateTrueRate from "utils/calculateTrueRate";
import parseNumber from 'utils/parseNumber';
import _ from 'lodash';

const $ = window.$;

function mapStateToProps(state, props) {
  return {
    user: state.user.meta,
    lenders: state.user.lenders,
    userId: state.user.id,
    initialValues: state.user.meta.miniproposal.properties[props.tabId].loanComparison.loans[props.productId],
    currentLoan: state.user.meta.miniproposal.properties[props.tabId].currentLoan,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    requestPatchUser,
    closeModal,
  }, dispatch);
}

const upfrontValidation = value => value > 10000 ? 'The entered rebate must be no more than $10,000' : null;

class CurrentLoanDetails extends Component {
  
  patchProposal = (data, callback) => this.props.requestPatchUser({ 
    userId: this.props.userId,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [this.props.tabId]: {
              loanComparison: {
                loans: {
                  [this.props.productId]: data
                }
              }
            }
          }
        }
      }
    },
    callback, 
  });

  onSubmit = results => {
    return new Promise(resolve => {
      
      const loan = this.props.initialValues;
      const currentLoan = this.props.currentLoan;
      let monthlyFee = (parseNumber(loan.ongoing_fees) + (Math.max(parseNumber(loan.approxUpfrontFee) - (results.upfrontRebate || 0), 0)) / 30) / 12;
      if (!monthlyFee) monthlyFee = 0;
      
      results.trueRate = calculateTrueRate({ 
        rate: (parseFloat(loan.iir) - (results.trailRebate || 0)) / 100, 
        monthlyFee , 
        loanAmount: parseNumber(currentLoan.loanAmount), 
        loanPeriod: parseInt(currentLoan.newLoanPeriod) || 30,
        fixedTerm: loan.fixed_term / 12,
        rr: loan.rr / 100,
      });

      this.patchProposal(results);
      this.props.closeModal();
      resolve();
    });
  }

  render() {
    return (
      <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)} className="form your-details" >
        <RateFieldGroup hideOptional id="trailRebate" width='large' position='left' title='Trail Rebate' forRebate />
        <FieldGroup hideOptional validate={upfrontValidation} id="upfrontRebate" width='large' position='left' title='Upfront Rebate' type='text' placeholder='0' mask="monetary"/>
        <button className="analyse"><ButtonSpinner loading={this.props.submitting}/>Done</button>
      </FormContainer>
    );

  }
}

CurrentLoanDetails = reduxForm({
  form: "rebate-details", // a unique identifier for this form
})(CurrentLoanDetails);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentLoanDetails);

import { createAction } from 'redux-actions';

export const REQUEST_NEW_APPLICATION = 'REQUEST_NEW_APPLICATION';
export const RECEIVE_NEW_APPLICATION = 'RECEIVE_NEW_APPLICATION';
export const REQUEST_PATCH_APPLICATION = 'REQUEST_PATCH_APPLICATION';
export const RECEIVE_PATCH_APPLICATION = 'RECEIVE_PATCH_APPLICATION';

export const requestNewApplication = createAction(REQUEST_NEW_APPLICATION);
export const receiveNewApplication = createAction(RECEIVE_NEW_APPLICATION);
export const requestPatchApplication = createAction(REQUEST_PATCH_APPLICATION);
export const receivePatchApplication = createAction(RECEIVE_PATCH_APPLICATION);

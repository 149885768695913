import React from "react"; import { Component } from "react";
import Form from "./Form";
import { connect } from 'react-redux';

class LendingOverviewOptions extends Component {
  render() {
    const { miniProposal, banks } = this.props;

    if (this.props.currentModal !== "lenderOverviewOptions") return null;

    let lenders = miniProposal.tabs
    .map(id => {
      const tab = miniProposal.properties[id];
      if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) return null;
      const loanId = tab.loanComparison.loanOrder[0];
      if (!loanId) return null;
      let loan = tab.loanComparison.loans[loanId];
      return loan;
    })
    .filter(loan => {
      if (!loan) return false;
      const bank = banks.find(x => x.id === loan.bank)
      if (!bank || !bank.description) return false;
      return true;
    })
    .reduce((acc, loan) => ({
      ...acc,
      [loan.bank]: loan.bank_name,
    }), {});
  
    lenders = Object.keys(lenders)
      .map(id => ({ id, name: lenders[id] }));

    let initialValues = (miniProposal || {}).overview || {};
    let lendingOverview = initialValues.lendingOverview || {};

    if (!initialValues.aboutLender) initialValues.aboutLender = {};
    for (const lender of lenders) {
      if (initialValues.aboutLender[lender.id] == null) {
        initialValues.aboutLender[lender.id] = true;
      }
    }
    
    if (lendingOverview.aggregateFees == null) {
      lendingOverview.aggregateFees = true;
    }

    if (lendingOverview.refinanceComparison == null) {
      lendingOverview.refinanceComparison = true;
    }

    if (initialValues.beyondSettlement) {
      if (initialValues.beyondSettlement.isActive == null) {
        initialValues.beyondSettlement.isActive = true;
      }
    } else {
      initialValues.beyondSettlement = { isActive: true };
    }

    initialValues.lendingOverview = lendingOverview;

    if (this.props.currentModal === "lenderOverviewOptions") {
    return (
      <div className="modal-content">
        <h1>Proposed Lending Overview</h1>
        <Form initialValues={initialValues} lenders={lenders}/>
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(LendingOverviewOptions);

import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import { reduxForm } from "redux-form";

import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup } from "Components/Forms";
import initializeFormValues from "utils/initializeFormValues";

import { propertyTypeOptions, stateOptions } from 'utils/calculateStampDuty';

import Debouncer from 'utils/debouncer';
const debouncer = new Debouncer();

class StampDutyForm extends Component {

  componentDidMount = () => {
    initializeFormValues('calculator', 
    (values, state) => ({
      'calculators-propertyValue': state.user.meta.purchasePrice || null,
      'calculators-propertyState': 'NSW',
      'calculators-propertyType': 'ESTABLISHED_HOME',
      'calculators-firstHomeBuyer': '',
      'calculators-foreignPurchaser': '',
      'calculators-propertyPurpose': (state.user.isOOLoan === '' && 'Y') || '',
      'calculators-stampDutyActive': 'Y',
      ...values,
    }));
    
  }

  render() {
    return(
      <div className="form" >
        <form onSubmit={this.props.handleSubmit} className="your-details">

          <FormContainer>
            <FieldGroup hideOptional id='calculators-propertyValue' width='small' position='left' title='Property Value' type='text' placeholder='0' mask="monetary" />
            <SelectGroup hideOptional empty id='calculators-propertyState' width='small' position='right' title='State / Territory of the Property' type='select' placeholder='New South Wales' values={stateOptions} />
            <SelectGroup hideOptional empty id='calculators-propertyType' width='small' position='left' title='Property Type' type='select' placeholder='Established Home' values={propertyTypeOptions} />
            <RadioToggleGroup hideOptional id='calculators-propertyPurpose' width='small' position='right' title='Property Purpose' placeholder='To Live In' unchecked='To Live In' checked='Investment' />
            <RadioToggleGroup hideOptional id='calculators-firstHomeBuyer' width='small' position='left' title='First Home Buyer' checked='Yes' unchecked='No' />
            <RadioToggleGroup hideOptional id='calculators-foreignPurchaser' width='small' position='right' title='Foreign Purchaser' placeholder='Yes' checked='Yes' unchecked='No' />
          </FormContainer>

        </form>
      </div>
    );
  }
}

StampDutyForm = reduxForm({
  form: "calculator", // a unique identifier for this form
  onChange: (values, dispatch, props, previousValues) => {
    debouncer.run(props.submit.bind(this), 300);
  },
})(StampDutyForm);



export default connect(null, null) (StampDutyForm);
import React from "react"; import { Component } from "react";

import { createUser } from "utils/createUser";
import LinkButton from "Components/LinkButton";
import { connect } from "react-redux";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";


class LoginStartedProcess extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      submitted: false,
      startFreshSubmitting: false
    };
  }

  startFresh = ()=>{
    const { type, userData, closeModal, openModal, userId, requestPatchUser } = this.props;
    
    if (type === 'create') {
      closeModal();
      createUser(null, true).catch(() => { throw new Error("Error creating user"); });
    } else if (type === 'patch') {
      this.setState({ startFreshSubmitting: true });
      requestPatchUser({ userId, data: { ...userData, force: true } , callback: () => {
        this.setState({ startFreshSubmitting: false });
        closeModal();
        openModal("signup-2");
      }});
      
    }
  }

  sendLoginEmail = ()=>{
    this.props.requestSecureLink({
      email: this.props.userData.email,
      type: 'create-retrieve-link',
      callback: (result)=>{
        this.setState(()=>{
          return {submitted: true}
        });
    }});
  }

  render() {
    const { userData, type } = this.props;
    const { startFreshSubmitting } = this.state;
    if (this.props.currentModal === "loginStartedProcess") {
    if (this.state.submitted) {
      return (
        <div className="modal-content">
          <h1>Secure link sent!</h1>
          <p>A secure link has been sent to <strong>{userData.email}</strong>. Follow the link to continue.</p>
        </div>
      )
    }

    return (
      <div className="modal-content">
        <h1>An Account Already Exists</h1>
        <p>
          There is an existing Secure Finance account with the e-mail address
          <br />
          <strong>{userData.email}</strong>
        </p>

        <br />
        {!userData.result.hasPassword ? (
          <p>
            This account has not yet been verified. We can send you an email
            with a link to sign in to your existing account, or alternatively
            you can wipe the slate clean and start fresh with a new account.
          </p>
        ) : (
          <p>
            This account has not yet been verified. If you know your password
            you can sign in, or alternatively you can wipe the slate clean and
            start fresh with a new account.
          </p>
        )}
        <div className="modal-navigation">
          {!userData.result.hasPassword ? (
            <button
              onClick={() => this.sendLoginEmail()}
              className="double left button-5"
            >
              Send Me an Email
            </button>
          ) : (
            <LinkButton
              to="/login"
              onClick={() => this.props.closeModal()}
              className="double left button-5"
            >
              Sign In
            </LinkButton>
          )}
          <button
            onClick={() => this.startFresh()}
            className="double right button-1"
          >
            Start Fresh!
          </button>
        </div>
      </div>
    ); } else {
      return "";
    }
  }
}

export default LoginStartedProcess;

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';
import { connect } from 'react-redux';
import getQueryParams from "utils/getQueryParams";
import {requestValidationEmail} from 'modules/user/actions';
import Debouncer from "utils/debouncer";
import { push } from 'connected-react-router';

const debouncer = new Debouncer();

function mapStateToProps(state) {
  return {
    email: state.user.email
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
    requestValidationEmail,
    push
  }, dispatch);
}

class ValidationSent extends Component {

  constructor() {
    super();
    let queryParams = getQueryParams();
    this.state = {
      continue: queryParams.continue || "",
      email: queryParams.email
    }
  }

  componentDidMount() {
    document.title = "Secure Finance";
    this.props.setTitle("Secure Finance");
    this.props.setPageID("validation-sent");
  }

  sendValidationEmail = function() {
    let link = this.state.continue;
    debouncer.run(() => {
      this.props.requestValidationEmail({
        email: this.props.email || this.state.email,
        continue: link,
        callback: ()=>{
          this.props.history.push('/validation-email-sent?continue=' + link);
        }
      });
    }, 5000)
  }

  render() {
    return (
      <main className={"verify " + this.props.type}>      
      {       
        <div className="main-container">
          <h1>Validation Email Sent</h1>
          <p>A validation email will shortly arrive in your inbox. Follow the link inside.</p>
          {
            (this.props.email || this.state.email) &&
            [<p>Click below to send a new validation link.</p>,
            <button class="button" onClick={()=>this.sendValidationEmail()}>Resend validation</button>]
          }
        </div>
      }
      </main>
      );
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ValidationSent);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from "redux-form";
import View from './View';
import { requestProducts } from 'modules/product/actions';
import { openModal } from 'modules/modals/actions';
import { setTitle, setPageID } from 'modules/page/actions';
import { requestPatchUser } from 'modules/user/actions';

function mapStateToProps(state) {
  return {
    products: state.products.list,
    loansFinished: state.products.isFinished,
    isLoading: state.products.loading,
    loanPurpose: state.user.meta.loanPurpose,
    user: state.user,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestProducts,
    openModal,
    setTitle,
    setPageID,
    requestPatchUser,
    change,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal } from 'modules/modals/actions';
import { requestAddProductToApplication, requestRemoveProductFromApplication } from 'modules/user/actions';
import { requestPatchUser } from 'modules/user/actions';
import { NavLink } from "react-router-dom";

function mapStateToProps(state) {
    let applicationProducts = [];
    if (state.user.applications && state.user.applications.length > 0) {
      applicationProducts = state.user.applications[0].products;
    }
    return {
      application: state.user.applications[0],
      applicationProducts,
      userMeta: state.user.meta,
      userId: state.user.id,
    };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      closeModal,
      openModal,
      requestAddProductToApplication,
      removeProductFromApplication: requestRemoveProductFromApplication,
      requestPatchUser,
    }, dispatch);
  }

class CheckEligibilityButton extends Component {

    constructor(props) {
      super(props);
      this.state = {
        isHovered: false,
      }
    }
    
    timeout = undefined;

    proceedToEligibility(state){
        let newState = state;
        // alert(state);
        this.props.requestPatchUser({userId: this.props.userId, data: {meta: {proceedToEligibility: newState}}, callback: ()=>{}});    
    }

    handleClick(isActive, pageType){
      this.setState({inRemoveState: false});
      if (this.props.application?.meta?.applicationSubmitted) {
        this.props.openModal('checkEligibilityOpen')
        return;
      }
      if (!isActive && (pageType !== "loan-details")) {
          if (this.props.applicationProducts.length < 3) {
              this.props.requestAddProductToApplication({applicationId: this.props.application.id, product: this.props.product});
              this.proceedToEligibility(0);
          } else {
              if (!this.props.location) {
                this.props.openModal("checkEligibilityFull");
              }
          }
      } else {
        this.props.removeProductFromApplication({applicationId: this.props.application.id, product_id: this.props.product.id})
      }
    }

    onMouseEnter = isActive => {
      if(!isActive) return;
      this.setState({isHovered: true})
    }

    onMouseLeave = () => {
      this.setState({isHovered: false});
    }

    

    render() {
        let isActive = false;
        if (this.props.applicationProducts) {
            isActive = (this.props.applicationProducts.find((e)=>e.id===this.props.product.id)) !== undefined;
            if (this.props.application?.meta?.applicationSubmitted) isActive = false;
        }
        if (this.props.type == "loan-details"){
            return (
                <button onClick={()=>this.handleClick(isActive, this.props.type)} className="select-button"></button>
            )
        } else {
            return (
                <div className="check-eligibility-container">
                    <button 
                    onClick={()=>this.handleClick(isActive)}
                    className={("check-eligiblity-button " + (isActive?"active":"") + (this.state.isHovered ? ' remove-loan' : ''))}
                    onMouseEnter={() => this.onMouseEnter(isActive)}
                    onMouseLeave={() => this.onMouseLeave(isActive)}>
                    {!this.state.isHovered || !isActive ? 
                        <span className='add-text'>{(isActive?"Added":"Qualify Me")}</span>
                        :
                        <span className='remove-text'>Remove</span>
                    }
                    </button>
                    {(this.props.how)?((isActive?"":<span to="/help/" onClick={()=>this.props.openModal("checkEligibilityHowDoesThisWork")} className="how-does-this-work">How does this work?</span>)):""}
                </div>
            )
        }

}
}


export default connect(mapStateToProps, mapDispatchToProps)(CheckEligibilityButton);

import React, { Fragment, Component, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { createRequired, createValidateEmail } from 'Components/Forms/validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeModal, openModal } from 'modules/modals/actions';

import { requestLogin } from 'modules/user/actions';
import getQueryParams from "utils/getQueryParams";
import { PasswordField } from 'Components/Forms';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';

const emailRequired = createRequired('Please enter your email');
const passwordRequired = createRequired('Please enter your password')
const validateEmail = createValidateEmail();
const $ = window.$;

let Form = ({ error, handleSubmit, pristine, reset, submitting, requestLogin, openModal, ...props }) => {
  
  useEffect(() => {
    $('.email').find('input').focus();
  }, []);

  const submit = ({ email, password }) => {    
    return new Promise((resolve, reject) => 
      requestLogin({
        callback: result => {
          if (!result.success) {
            return reject( new SubmissionError({
              _error: result.errorMessage || 'Email or password are incorrect',
            }));
          } else {
            resolve();
            let value = getQueryParams();
            window.location = window.location.origin + "/" + (value.redirect || "");
          }
        },
        data: {
          email,
          password
        }
      })
    );
  };

  return (
    <div className="form user-authentication" style={{position: 'relative'}}>
      <form onSubmit={handleSubmit(submit)}>
        <div className='w100p ls text field'>
          <label>Email Address</label>
          <Field name='email' type='text' component={Input} validate={[emailRequired, validateEmail]} placeholder="Enter Email" formError={error}/>
        </div>
        <br />
        <div className='w100p ls text field'>
          <label className="password">Password</label>
          <span tabIndex="999" className="forgot-password" onClick={() => openModal('forgetPassword')}>Forgot Password?</span>
          <PasswordField id='password' hideOptional component={Input} validate={passwordRequired} placeholder="Enter Password" formError={error}/>
        </div>
        <button  type="submit" style={{marginTop: '10px'}}><ButtonSpinner loading={submitting}/>Sign In to My Account</button>
        {error && <div className="validation" style={{position: 'relative'}}>{error}</div>}
      </form>
    </div>
  )
};

Form = reduxForm({
  form: 'login',
  destroyOnUnmount: false,
})(Form)

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogin,
    openModal,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Form);


const Input = ({meta: {touched, error}, input, formError, ...props}) => {
  return ( 
  <Fragment>
    <input
      type='text'
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <div className='field-error-message'>{error}</div>} 
  </Fragment>
  )
};

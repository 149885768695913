import React from 'react';
import { reduxForm, change, blur } from 'redux-form';
import QuestionWrapper from "../Components/QuestionWrapper";
import Navigation from "../Components/Navigation";
import CurrencyField from '../Components/CurrencyField';
import { connect } from 'react-redux';

import { createNumberBetween } from 'Components/Forms/validation';

const numberBetween = createNumberBetween(20000, 20000000, (low, high) => `Purchase price must be between $${low} and $${high}`);

const moreThanDepositAmount =
(value, values, props) => value <= props.depositAmount ? 'Purchase price must be larger than deposit' : null;

let PurchasePrice = function(props) {
  return (
    <QuestionWrapper  isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>
      <h1>What is the purchase price?</h1>
      <h2>If you’re not sure, tell us the maximum price you’re looking at.</h2>
      <CurrencyField maxNumber={99999999} required validate={[numberBetween, moreThanDepositAmount]} id='user_meta-purchasePrice' type='text' />
      <Navigation hidden={ props.pristine } name="Next Step →" />
      <img className='img-price' src={require('../../../img/page_illustrations/onboarding_documents.png')}/>
    </QuestionWrapper>
  )
}

PurchasePrice = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-purchase-price',
  onChange: (values, dispatch, props) => {
    dispatch(change('onboarding-deposit-amount', 'user_meta-purchasePrice', values['user_meta-purchasePrice']))
    dispatch(blur('onboarding-deposit-amount', 'user_meta-depositAmount', props.depositAmount))
  }  // a unique identifier for this form
})(PurchasePrice);

const stateToProps = state => {
  let depositAmount = null;
  if (state.form['onboarding-deposit-amount']
  && state.form['onboarding-deposit-amount'].values) {
    depositAmount = state.form['onboarding-deposit-amount'].values['user_meta-depositAmount'];
  }
  return {
    depositAmount,
  }
  
}; 

export default connect(stateToProps) (PurchasePrice);



import React from "react"; import { Component } from "react";
import { NavLink } from "react-router-dom";
import LoginFormModal from "Components/Accounts/LoginFormModal";
import Divider from "Views/Accounts/Components/Divider";

import requestFilteredProducts from "modules/product/requestFilteredProducts";
import {googleSignin, facebookSignin} from "utils/oauth"

class Signin extends Component {

  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  handleSubmit = (e) => {
    this.props.requestLogin({
      callback: (result) => {
        if (!result.success) {
          alert(result.message);
        } else {
          requestFilteredProducts();
        }
      },
      data: {
        email: this.state.username,
        password: this.state.password,
      },
    });
    e.preventDefault();
  }

  // Where does this exist?

  render() {
    if (this.props.currentModal === "signin") {
    return (
      <div className="modal-content signin">
        <h1>Sign in to Your Account</h1>
        <p>Please enter your login details below.</p>
        <LoginFormModal />

        <Divider />

        <div className="skip-password">
          <div className="skip-password-text">
            <span>Skip password!</span>
            Login with a magic link!
          </div>
          <button onClick={()=>this.props.openModal("secureLink")} className="email-secure-link"><span>Email Me a Magic Link</span></button>
        </div>

        <Divider />

        <div className="alternative-signin">
          <button className="google" onClick={()=>googleSignin()}>Sign In with Google</button>
          <button className="facebook" onClick={()=>facebookSignin()}>Sign In with Facebook</button>
        </div>

        <div className="navigation">
          Don't have an account yet? <a onClick={()=>this.props.openModal("signup-1")}>Click here to Sign Up.</a>
        </div>

      </div>
    ) } else {
      return "";
    }
  }
}

export default Signin;

import GlobalDisclaimer from "Components/Global/GlobalDisclaimer";
import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <main className={"main dashboard "}>
      <h1>Privacy Policy</h1>
      <p>
        ‘We’, ‘us’ and ‘our’ refer to Secure Finance Services Pty Ltd trading as
        Loankit Australian Credit Licence number 465059 .
      </p>
      <p>
        At Secure Finance, we understand that the details you provide us are
        private and personal. It’s important that you feel secure whenever you
        deal with us. It’s also important that you understand how we protect
        your privacy and the manner in which we may use your details. As such,
        our policies relating to personal information are designed to ensure
        that you are fully protected under Australian privacy laws.
      </p>
      <p>
        Our commitment in respect of personal information is to abide by the
        Australian Privacy Principles for the protection of personal
        information, as set out in the Privacy Act and any other relevant law.
      </p>
      <h2>Personal Information</h2>
      <p>
        When we refer to personal information we mean information from which
        your identity is reasonably apparent. This information may include
        information or an opinion about you. The personal information we hold
        about you may also include credit information.
      </p>
      <p>
        Credit information is information which is used to assess your
        eligibility to be provided with finance and may include any finance that
        you have outstanding, your repayment history in respect of those loans,
        and any defaults. Usually, credit information is exchanged between
        credit and finance providers and credit reporting bodies.
      </p>
      <h3>Why we collect your personal information</h3>
      <p>
        We collect personal information when you apply to join our network or
        for the purposes of assessing your application for finance and managing
        that finance. We may also collect your personal information for the
        purposes of direct marketing and managing our relationship with you.
        From time to time we may offer you other products and services.
      </p>
      <p>
        To enable us to maintain a successful business relationship with you, we
        may disclose your personal information to other organisations that
        provide products or services used by us. We may also disclose your
        personal information to any other organisation that may have or is
        considering having an interest in your finance, or in our business.
      </p>
      <p>
        Where reasonable and practical we will collect your personal information
        directly from you. We may also collect your personal information from
        finance brokers and other people such as accountants and lawyers.
      </p>
      <p>We may disclose your personal information:</p>
      <ul class="arrow-list">
        <li>
          To prospective funders or other intermediaries in relation to your
          finance requirements;
        </li>
        <li>
          To other organisations that are involved in managing or administering
          your finance such as third party suppliers, printing and postal
          services, call centres;
        </li>
        <li>
          To companies that provide information and infrastructure systems to
          us;
        </li>
        <li>
          To anybody who represents you, such as finance brokers, lawyers and
          accountants;
        </li>
        <li>To anyone, where you have provided us consent;</li>
        <li>
          Where we are required to do so by law, such as under the Anti-Money
          Laundering and Counter Terrorism Financing Act 2006 (Cth);
        </li>
        <li>
          To investors, agents or advisers, or any entity that has an interest
          in our business;
        </li>
        <li>To your employer, referees or identity verification services.</li>
      </ul>

      <h3>Direct Marketing</h3>
      <p>
        From time to time we may use your personal information to provide you
        with current information about finance, offers you may find of interest,
        changes to our organisation, or new products or services being offered
        by us or any company with whom we are associated.
      </p>
      <h3>Complaints</h3>
      <p>
        If you are dissatisfied with how we have dealt with your personal
        information, or you have a complaint about our compliance with the
        Privacy Act, you may contact our complaints officer on 1800 466 085 or
        by writing to info@securefinance.com.au.
      </p>
      <p>
        We will acknowledge your complaint within seven days. We will provide
        you with a decision on your complaint within 30 days.
      </p>
      <GlobalDisclaimer />
    </main>
  );
};

export default PrivacyPolicy;

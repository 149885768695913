import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';

import LinkButton from 'Components/LinkButton';
import styled, { css } from 'styled-components';

const $ = window.$;

function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist || [],
    repaymentFrequency: state.user.meta.searchFilter_repaymentFrequency,
    user: state.user.meta,
  };
}

class LoanSearchHeader extends Component {

  // constructor() {
  //   super();

  //   this.state = {
  //     sticky: false,
  //   };

  //   this.handleScroll = this.handleScroll.bind(this);
  // }

  // componentDidMount() {
  //   $('main').scroll(this.handleScroll);
  // }

  // handleScroll(e) {
  //   var scrollPosition = $('main').scrollTop();
  

  //   var childPos = $('#search-header').offset();
  //   var parentPos = $('main').parent().offset();
  //   var childOffset = {
  //       top: childPos.top - parentPos.top,
  //       left: childPos.left - parentPos.left
  //   }

  //   console.log(scrollPosition);

  //   if (childOffset.top <= 60) {
  //     this.setState({ sticky: true });
  //     console.log( childOffset.top);
  //   } else {
  //     this.setState({ sticky: false });
  //     console.log(childOffset.top);
  //   }
  // }

  onClick = field => {
    if (this.props.onChange) this.props.onChange(field);
  }

  sortClass = field => {
    const { sortField, sortDirection } = this.props.user;
    if (sortField === field) 
      return sortDirection === 1 ? 'sort-down' : 'sort-up';
    return '';
  }

  render() {
    const { state, repaymentFrequency, shortlist, onChange } = this.props; 
    return (
      <SearchHeader sticky={this.props.sticky} id="search-header" className={"search-header col-m1" + MonthlySavingsStyle(this.props.refinance, this.props.user.initialLoanDetails)}>
        <ul>
          <li>
            <button className={this.sortClass('bank_name')} onClick={() => this.onClick('bank_name')}>Lender</button>
          </li>
          <li>
            <button className={this.sortClass('iir')} onClick={() => this.onClick('iir')}>Interest Rate</button>
          </li>
          <li>
            <button className={this.sortClass('comparisonRate')} onClick={() => this.onClick('comparisonRate')}>Comparison Rate</button>
          </li>
          <li >
            <button className={this.sortClass('repayments')} onClick={() => this.onClick('repayments')}>Repayments</button>
          </li>
          {MonthlySavingsToggle(this.props.refinance, this, this.props.user.initialLoanDetails, repaymentFrequency)}
          <li className='borrowing-power'>
            <button className="disable" onClick={() => this.onClick('borrowingPower')}>Borrowing Power</button>
          </li>
          <li>
            <div className="compare-starred-loan-label">
              <LinkButton to="/loan-shortlist">
                Compare shortlist
              </LinkButton>
              <span className="count">{shortlist.length}</span>
            </div>
          </li>
        </ul>
      </SearchHeader>
    );
  }
}

const SearchHeader = styled.div`
  border-radius: 4px !important;

  @media (max-width: 645px) {
    border-radius: 0px !important;
  }

  ${props => props.sticky && css`
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 500;
    border-top: none !important;
    border-bottom: solid 1px #D8DCE7 !important;
  `}

  ul {

    li {
      width: calc((100% - 200px) / 5);
      display: inline-block;
      vertical-align: top;
      border-left: solid 1px #E9EBF1;
      text-align: left;

      &:first-of-type {
        border-left: none;
      }

      &:last-of-type {
        width: 200px;
      }

      @media(max-width: 1000px) {
        width: calc((100% - 200px) / 4);

        &.borrowing-power {
          display: none;
        }
      }

      @media (max-width: 950px) {
        width: calc(100% / 4);

        &:nth-of-type(6) {
          display: none;
        }
      }

      @media (max-width: 645px) {
        width: calc(100% / 3);

        &:nth-of-type(1) {
          display: none;
          border-left: none;
        }

        &:nth-of-type(2) {
          border-left: none;
        }

        &:nth-of-type(6) {
          display: none;
        }

      }

      >button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-left: 20px;
        height: 48px;
        width: calc(100% - 40px);
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        background-image: url(${require('../../../img/button/expand-grey.png')});
        background-position: 100% 50%;
        background-size: 5px 10px;
        background-repeat: no-repeat;
        color: #575E6C;
        margin-right: 20px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media(max-width: 1440px) {
          font-size: 10px;
          font-weight: 600;
        }

        @media(max-width: 950px) {
          width: calc(100% - 20px);
          margin-right: 10px;
          padding-right: 10px;
          margin-left: 10px;
          font-size: 9px;
        }

        &:focus {
          outline: 0;
        }

        &.sort-up {
          background-image: url(${require('../../../img/button/up-blue.png')});
          background-size: 5px 4px;
        }

        &.sort-down {
          background-image: url(${require('../../../img/button/down-blue.png')});
          background-size: 5px 4px;
        }

        &.disable {
          background-image: none;
          pointer-events: none;
        }

        @media(max-width: 1440px) {
          text-align: left;
        }
      }

      .compare-starred-loan-label {
        line-height: 48px;
        width: 100%;
        display: inline-block;
        min-width: 180px;

        >button {
          display: inline-block;
          vertical-align: middle;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          cursor: pointer;
          outline: inherit;
          font-size: 14px;
          border-radius: 4px;
          -moz-border-radius: 4px;
          -webkit-border-radius: 4px;
          padding: 13px 25px 13px 25px;
          color: #2291FF;
          background-color: Transparent;
          border: solid 1px #2291FF;
          padding: 7px 10px 7px 10px;
          font-weight: 600;
          font-family: 'Lato', sans-serif;
          font-size: 11px;
          margin-left: 20px;
        }

        .count {
          margin-left: 15px;
          text-align: center;
          line-height: 24px;
          display: inline-block;
          vertical-align: middle;
          height: 24px;
          width: 24px;
          display: inline-block;
          vertical-align: middle;
          background-color: #2291FF;
          color: #fff;
          border-radius: 12px;
          -moz-border-radius: 12px;
          -webkit-border-radius: 12px;
          font-weight: 600;
          font-size: 11px;
        }

      }

    }
  }

  &.monthly-savings ul li {
    width: calc((100% - 200px) / 6);

    @media (max-width: 1000px) {
      width: calc((100% - 200px) / 5);
    }

    @media (max-width: 950px) {
      width: calc(100% / 5);

      &:nth-of-type(7) {
        display: none;
      }
    }

    @media (max-width: 645px) {
      width: calc(100% / 4);

      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        border-left: none;
      }

      &:nth-of-type(7) {
        display: none;
      }

    }

    &:last-of-type {
      min-width: 160px;
    }
  }

    
`;

function repaymentTitle(freq) {
  if (freq === 'weekly') return 'Weekly Repayments';
  if (freq === 'fortnightly') return 'Fortnightly Repayments';
  return 'Monthly Repayments';
}

function savingsTitle(freq) {
  if (freq === 'weekly') return 'Weekly Savings';
  if (freq === 'fortnightly') return 'Fortnightly Savings';
  return 'Monthly Savings';
}

function MonthlySavingsToggle(value1, that, initialLoanDetails, frequency){
  if (value1 === "refinance" && initialLoanDetails == "1") {
    return (
      <li>
        <button className={that.sortClass('monthlySavings')} onClick={() => that.onClick('monthlySavings')}>Repayment Savings</button>
      </li>
    );
  }
}



function MonthlySavingsStyle(value1, initialLoanDetails){
  if (value1 === "refinance" && initialLoanDetails == "1") {
    return " monthly-savings";
  }
  else {
    return "";
  }
}

export default connect(mapStateToProps, null)(LoanSearchHeader)

import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, Form } from 'redux-form';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { FieldGroup } from "Components/Forms";
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { Header, Subtitle } from "../../theme/General";
import SectionButton from "../../component/SectionButton";
import { TextArea } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import { createRequired } from 'Components/Forms/validation';
import { emailService } from "modules";
import AdditionalDetails from "./AdditionalDetails";
import { uploadUrl } from 'modules';

const required = createRequired("Please type a message");

function mapStateToProps(state) {
  return {
    proposal: state.user.meta.proposal,
    user: state.user.meta
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}


class BrokerSection extends React.Component {

  sendQuestion = ({ question }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await emailService.create({
          type: "proposal-question",
          question
        });
        this.props.dispatch(this.props.change("question", ""));
        this.props.dispatch(this.props.untouch("question"));
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  render() {
    const { proposal, user, submitSucceeded } = this.props;
    console.log(this.props);
    // let proposalActive = false;
    // if (user.proposal && user.proposal.enabled) {
    //   const value = user.proposal.expiry;
    //   if (value) {
    //     const day = parseInt(value.slice(0, 2));
    //     const month = parseInt(value.slice(2, 4));
    //     const year = parseInt(value.slice(4, 8));
    //     const expiry = new Date(year, month - 1, day);
    //     if (expiry > new Date()) proposalActive = true;
    //   }
    // }

    const proposalActive = true;

    if (!proposalActive) return null;
    const { name, firstName, title, email, mobile, blurb, calendlyUrl, id } = this.props.broker;
    return (
      <BrokerSectionContainer className="broker-section">
        <div className="broker">
          <img src={`${uploadUrl}/brokers/big${id}.png`} alt={name} />
          <div className='broker-detail'>
            <div className="name">{name}</div>
            <div className="position">{title}</div>
            <ul>
              <li className="email">{email}</li>
              <li className="phone">{mobile}</li>
              <li className="hotline">0404 262 056</li>
            </ul>
            <button onClick={() => window.open(calendlyUrl, "_blank")}>
              <span>Book time in my calendar</span>
            </button>
          </div>
          
        </div>
        <div className="broker-info">
            <AdditionalDetails isForSummary={this.props.isForSummary} form={'additionalDetails-' + this.props.tabId + '-' + this.props.index} initialValues={this.props.details} isView={this.props.isView} active={this.props.active} tabId={this.props.tabId} cancelEdit={this.props.cancelEdit} toggleEdit={this.props.toggleEdit} removeSection={this.props.removeSection}/>
          <h2>About {firstName}</h2>
          <p>{blurb}</p>
          <div className="form">
            <Form onSubmit={this.props.handleSubmit(this.sendQuestion)}>
              <h2>Ask {firstName} a Question</h2>
              <TextArea id="question" width="large" position="left" type="text" placeholder="" validate={required} />
              {submitSucceeded && (<span className="message-sent">Your question has been sent! 🙂</span>)}
              <button type="submit"><ButtonSpinner loading={this.props.submitting} />Submit Question</button>
            </Form>
          </div>
        </div>
      </BrokerSectionContainer>
    )
  }
}

const BrokerSectionContainer = styled.div`
  padding-bottom: 20px;
`;

BrokerSection = reduxForm({
  form: "broker-section"
})(BrokerSection);


export default connect(mapStateToProps, mapDispatchToProps)(BrokerSection);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { openModal } from 'modules/modals/actions';
import { setTitle, setPageID } from "modules/page/actions";
import { bindActionCreators } from "redux";

export default connect(null, mapDispatchToProps) (({ dispatch, ...props }) => {

  useEffect(() => {
    document.title = "Secure Finance - Help";
    props.setTitle("Help");
    props.setPageID("help");
  }, []);

  return (
    <main className={"help under-construction"}>
      <div className="main-container">
        <img src={require('img/page_illustrations/construction.png')} className='construction'/>
        <p className="title">
          Sorry, our help section is still being built! 👷
        </p>
        <p className="text">
          Why not <span onClick={() => dispatch(openModal('checkEligibilityHelp'))}className="get-in-touch">get in touch</span> and we'll help find the right loan for you?
        </p>
      </div>
    </main>
  )
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTitle,
      setPageID,
    },
    dispatch
  );
}
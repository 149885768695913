import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import CheckEligibilityButton from "Components/CheckEligibilityButton"
import { openModal, closeModal } from 'modules/modals/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeFromShortlist } from 'modules/user/actions';
import BankLogo from 'Components/Global/BankLogo2';
import calculateLoanValues from "utils/calculateLoanValues";
import { logoFolder } from 'modules';
import Tooltips from "Components/Tooltips";

const toMoneyString = n => 
  '$' + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export class SmallProductBox extends Component {

  render() {
    let product = calculateLoanValues(this.props.product);

    return (
      <div className="product-select">
          <BankLogo product={product} folder={logoFolder} /> 
        <h6>{product.product_name}</h6>

        <div className="interest-rate">
          <div className="variable-rate">
            <div className="rate">{product.iir}<span>% p.a.</span></div>
            <div className="rate-description">{product.rateDescriptionYears}</div>
          </div>
          <div className="comparison-rate">
            <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
            <div className="rate-description">Comparison Rate</div>
          </div>
        </div>

        {/* <div className="principal-interest">
          <h5>Principal &amp; Interest</h5>
          <div className="description">Repayment Type</div>
        </div> */}

        <div className="fees">
          <div className="upfront-fee">${product.approxUpfrontFee}<span>Approx Upfront</span></div>
          <div className="annual-fee">${product.approxUpfrontFee}<span>Annual Fee</span></div>
        </div>
      
        <ul className="statistics" style={{paddingBottom: '5px'}}>
          <li className={product.redraw_allowed}><span>Redraw</span></li>
          <li className={product.full_offset}>
            {product.full_offset === 'additional' ? 
              <Tooltips type="wrapper" definition={product.offsetTooltip}><span className='content'>Offset</span></Tooltips>
              :
              <span>Offset</span>
            }
          </li>
          <li className={product.extra_repayments_allowed}><span>Early Repayments</span></li>
        </ul>
      </div>
    )
  }
}

class MediumProductBoxClass extends Component {
  constructor(props) {
    super(props);

    this.state= {
      removeToggle: false,
    };
  }

  removeLoan(){
    this.setState((prevState) => {return {...prevState, removeToggle: !prevState.removeToggle}});
  }

  toggleUnstarPopup(){
    this.setState((prevState) => {return {...prevState, isUnstarShown: !prevState.isUnstarShown}});
  }

  render() {
    let product = calculateLoanValues(this.props.product);
    
    return(
      <div className="product">
        <div className="product-item">
          {(!this.state.removeToggle?
            <div className="active">
              {(this.props.disableDelete)?"":
                <button onClick={()=>this.removeLoan()} className="close"></button>
              }

              {/* <div onClick={()=>this.toggleUnstarPopup()} className="favourites">
                {this.state.isUnstarShown ?
                  <a className="confirmFavourites" onClick={()=>this.props.removeFromShortlist(product.id)}>Click here to unstar</a>:
                  ""
                }
              </div> */}
              <BankLogo product={product} folder={logoFolder} /> 
              <div className="product-name-container">
                <h6>{product.product_name}</h6>
              </div>
              

              <div className="interest-rate">
                <div className="variable-rate">
                  <div className="rate">{product.iir}<span> % p.a.</span></div>
                  <div className="description">{product.initial_ir_type}</div>
                </div>
                <div className="comparison-rate">
                  <div className="rate">{product.comparisonRate}<span> % p.a.</span></div>
                  <div className="description">Comparison</div>
                </div>
              </div>

              <div className="principal-interest">
                <h5>Principal & Interest</h5>
                <div className="description">Repayment Type</div>
              </div>

              <ul className="statistics">
                <li className={product.redraw_allowed}><span>Redraw</span></li>
                <li className={product.full_offset}>
                  {product.full_offset === 'additional' ? 
                    <Tooltips type="wrapper" definition={product.offsetTooltip}><span className='content'>Offset</span></Tooltips>
                    :
                    <span>Offset</span>
                  }
                </li>
                <li className={product.extra_repayments_allowed}><span>Early Repayments</span></li>
              </ul>

              {(this.props.dashboard)?
              ""
              :
              <div className="fees">
                <div className="upfront-fee">{toMoneyString(product.approxUpfrontFee)}<span>Approx Upfront</span></div>
                <div className="annual-fee">{toMoneyString(product.annual_fees)}<span>Annual Fee</span></div>
              </div>
              }

              <div className="more-info">
              <LinkButton onClick={() => this.props.closeModal()} to={"/products/" + product.id} className="details">Details</LinkButton>
                <CheckEligibilityButton how product={product} location="shortlist" />
              </div>

            </div>
            :
            <div className="remove">
              <h2>Are you sure<br />you want to remove this loan?</h2>
              <div className="remove-navigation">
                <button className="cancel-button" onClick={()=>this.removeLoan()} >Cancel</button>
                <button className="remove-button" onClick={()=>this.props.removeFromShortlist({ data: product.id })} >Remove</button>
              </div>
            </div>
          )}



        </div>
      </div>
    );
  }
}

export let MediumProductBox = connect(null, (dispatch) => {
  return bindActionCreators({
    closeModal,
    openModal,
    removeFromShortlist,
  }, dispatch);
})(MediumProductBoxClass);


export class ExtraSmallProductBox extends Component {
  render() {
    return (
      <div className="product-select">
        <img className="company-logo" src={require(`img/temp/lender1.png`)} alt="company-logo" />
        <h6>Offset Home Loan Package</h6>

        <div className="interest-rate">
          <div className="variable-rate">
            <div className="rate">3.79<span>% p.a.</span></div>
            <div className="rate-description">Variable Rate</div>
          </div>
          <div className="comparison-rate">
            <div className="rate">3.79<span>% p.a.</span></div>
            <div className="rate-description">Comparison Rate</div>
          </div>
        </div>
      </div>
    )
  }
}
export default function shortListFilterFunction(p, user) {
    let lvr = 100 * user.loanAmount / user.purchasePrice;

    let ioLoan = (p.io_allowed_for_own_occ === "Y" && p.maximum_lvr_own_occ > 0) || (p.io_repayments === "Y" && p.maximum_lvr_investment > 0);

    return p.maximum_loan_amount < user.loanAmount ||
        p.minimum_loan_amount > user.loanAmount ||
        p.maximum_term < user.loanPeriod ||
        (user.isOOLoan && p.maximum_lvr_own_occ < lvr) ||
        (!user.isOOLoan && p.maximum_lvr_investment < lvr) ||
        (user.loanPurpose === "refinance" && p.maximum_lvr_refinance < lvr) ||
        (ioLoan !== !(user.isPandILoan === "Y"));
}
import React from "react"; import { Component } from "react";

import { MediumProductBox } from "Components/Product"
import shortListFilterFunction from "utils/shortListFilterFunction"

export default class ShortListFilterView extends Component {
  
  render() {
    
    if (this.props.currentModal === "shortListFilterView") {
      const { shortlist, user } = this.props;
      const filteredProducts = shortlist.filter(p => shortListFilterFunction(p, user));
      if (!filteredProducts.length) this.props.closeModal();
      return (
        <div className="modal-content">
          <div className="heading">
            <h1>Your Additional Shortlist Loans</h1>
          </div>

          <div className={"product-list" + ((filteredProducts.length <= 3)?" no-scroll":"")}>
            <div className="scroll-container" style={{width: (filteredProducts.length * 290)}}>
              {filteredProducts.map(item => <MediumProductBox key={item.id} product={item} />)}
            </div>
          </div>

        </div>
      ) } else {
        return "";
    }
  }
}

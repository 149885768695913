import React from 'react';
import { reduxForm } from 'redux-form';
import { FieldGroup } from "Components/Forms";
import QuestionWrapper from "../Components/QuestionWrapper";
import Navigation from "../Components/Navigation";

const notBlank = value => 
  (!!value && !!value.trim()) ? null : 'This is required';


let FullNameFinal = function({ pristine, valid, ...props }) {
  console.log(props);
  return (
    <QuestionWrapper finalQuestion={props.finalQuestion} isTransitioning={props.isTransitioning} invalid={props.invalid}  debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.finishOnboarding} maxQuestion={props.maxQuestion}>
      <h1>Finally, what's your name?</h1>
      <h2>Our search results are personalised for you!</h2>
      <FieldGroup id='name' width='large' position='left' required type='text' validate={notBlank}/>
      <Navigation hidden={ pristine || !valid } name="Finish" greenButton/>
      <img className='img-success' src={require('../../../img/page_illustrations/success.png')}/>
      <p className='p-success'>Hooray, your results await! 🚀</p>
    </QuestionWrapper>
  )
}

FullNameFinal = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-full-name'  // a unique identifier for this form
})(FullNameFinal);

export default FullNameFinal;

import React from "react"; import { Component } from "react";
import SecureLinkComponent from 'Views/Accounts/Components/SecureLinkComponent';


class SecureLink extends Component {
  render() {
    if (this.props.currentModal === "secureLink") {
    return (
      <div className="modal-content">
        <SecureLinkComponent />

        <div className="navigation back">
          <a onClick={()=>this.props.openModal("signin")}>
          ← Back to Sign In
          </a>
        </div>
        
      </div>
    ) } else {
      return "";
    }
  }
}

export default SecureLink;

import React from "react"; import { Component } from "react";
import { reduxForm, SubmissionError, formValueSelector, blur, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';
import { setTitle, setPageID } from 'modules/page/actions';
import getInitialValuesObject from "utils/getInitialValuesObject";

import { FieldGroup, FormContainer, DoubleFieldGroup, PasswordField, Input } from "Components/Forms";
import PickAvatar from "./Components/PickAvatar";
import updateFormValues from "modules/updateFormValues";
import { runInThisContext } from "vm";
import ShowModal from "Components/Modals/showModal"

import { createPasswordValidation, createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';
import Debouncer from "utils/debouncer";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import Disclaimer from 'Components/Global/GlobalDisclaimer';
import { push } from 'connected-react-router';

const debouncer = new Debouncer();

const passwordValidation = createPasswordValidation();
const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter your email');
const passwordRequired = createRequired('Please enter a password');
const confirmationRequired = createRequired('You must confirm your password');

const selector = formValueSelector('account');
function mapStateToProps(state) {
  return {
    user: state.user.meta,
    email: state.user.email,
    userId: state.user.id,
    userName: state.user,
    clientStage: state.user.clientStage,
    hasPassword: state.user.hasPassword,
    newEmail: state.user.newEmail,
    profile_picture: state.user.meta.profile_picture,
    profilePictureUrl: state.user.profilePictureUrl,
    password: selector(state, 'user-password'),
    confirmPassword: selector(state, 'confirmPassword'),
    initialValues: {
      ...getInitialValuesObject(state),
    },    
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
      setTitle,
      setPageID,
      blur,
      push
  }, dispatch);
}

class AccountSettings extends Component {

  state = {
    password: '',
    showPassword: false,
    resendingValidation: false
  }

  redirect = null;

  componentDidMount() {
    document.title = "Secure Finance - Settings";
    this.props.setTitle("Settings");
    this.props.setPageID("settings");
  }

  cancelEmailUpdate() {
    this.props.requestPatchUser({userId: this.props.userId, data: {newEmail: ''}});
  }

  resendValidationUpdate() {
    if (!this.setState.resendingValidation) {
      this.setState(state=>({...state, resendingValidation: true}));
      let isWaiting = true;
      setTimeout(() => {
        if (!isWaiting) {
          this.setState(state=>({...state, resendingValidation: false}))
        }
        isWaiting = false;
      }, 1000);
      this.props.requestPatchUser({userId: this.props.userId, data: {email: this.props.newEmail}, callback: ()=>{
          if (!isWaiting) {
            this.setState(state=>({...state, resendingValidation: false}))
          }
          isWaiting = false;
        }
      });
    }
  }

  submit(results){
    return new Promise((resolve, reject) => {
      delete results['user-newEmail'];
      if (results['user-password']) {
        if (this.props.hasPassword && !results['user-currentPassword']) {
          reject(new SubmissionError({
            'user-currentPassword': 'Please enter your current password'
          }));
          return;
        }
        if (results['user-password'] !== results['confirmPassword']) {
          reject(new SubmissionError({
            'confirmPassword': 'Passwords do not match'
          }));
          return;
        }
      }

      updateFormValues(results, (result)=>{
        if (result && result.error) {
          reject(new SubmissionError({
            ...result.validation
          }));
        } else {
          this.confirmAccountSubmission();
          resolve();
        }
      });
    })
  }

  passwordsMatch = (value, values) => {
    return value === values['user-password'] ? null : 'Passwords do not match';
  }


  showPassword(e) {
    this.setState(state=>({...state, showPassword: true}));
    e.preventDefault();
    return 0;
  }

  confirmAccountSubmission() {
    // why do this? Bacause a direct history.push(...) wasn't working within the submit function for some reason
    if (this.redirect) {
      this.redirect.click();
    }
  }
  
  render() {

    let firstName = this.props.userName.firstName || "";
    let lastName = this.props.userName.lastName || "";

    let pictureStyle = {};
    if (this.props.profilePictureUrl && !this.props.profile_picture) {
      pictureStyle = {
        backgroundImage: 'url(' + this.props.profilePictureUrl + ')',
      };
    }

    if (this.props.clientStage === 'unregistered' && !this.props.hasPassword) {
      return <ShowModal modal='signup-3' redirect='accounts' />;
    }

    return (
    <main className={"my-profile " + this.props.type}>
      <div className="main-container">
        <h1>My Profile</h1>
        {
          this.props.newEmail && this.props.newEmail !== this.props.email  &&
          <div className="new-email-box">
            <h2>Email not yet verified!</h2>
            <p>You recently requested to change you email to <span className="underline">{this.props.newEmail}</span></p>
            <p>Please check your inbox for a validation link to confirm your new address.</p>
            <div className="new-email-navigation">
              <button onClick={()=>this.resendValidationUpdate()} className="resend"><ButtonSpinner loading={this.state.resendingValidation}/>Resend confirmation</button>
              <button onClick={()=>this.cancelEmailUpdate()} className="cancel-update">Revert & Cancel</button>         
            </div>
          </div>
        }
        
        <div className="setting">

          <div className="avatar">

            <div className={"avatar-pic" + (!(this.props.profile_picture)?" empty":" exists") + " n" + this.props.profile_picture} style={pictureStyle} >
              {(this.props.profile_picture || this.props.profilePictureUrl)?"":(firstName.toString().charAt(0).toUpperCase() + (lastName || ' ').toString().charAt(0).toUpperCase())}
            </div>

            <div className="avatar-description">
              <h3>{name(firstName, lastName)}</h3>
              <PickAvatar />
            </div>

            <div className="social-media">
              {(this.props.userName.googleId)?
                <div className="google">Connected via Google</div>
              :""}
              {(this.props.userName.facebookId)?
              <div className="facebook">Connected via Facebook</div>
              :""}
            </div>

          </div>

          <FormContainer name="myProfileDetails" onSubmit={this.props.handleSubmit(results=>this.submit(results))}>
            <div className="details-section">
              
                <FieldGroup id='user-firstName' position='left' width='small' required title='First Name' type='text' placeholder='E.g. John' />
                <FieldGroup id='user-lastName' position='right' width='small' required title='Last Name' type='text' placeholder='E.g. Appleseed' />

                <FieldGroup id='user-email' validate={[emailRequired, emailValidation]} width='small' position='left' title='E-mail Address' required type='text' placeholder='E.g. securefinance@mail.com.au' />
                <DoubleFieldGroup {...phoneNumberProps} hideOptional id='user-phoneNumber' width='small' position='right' title='Phone Number' type='number-type' placeholder='(99) 9999 9999' />
                <DoubleFieldGroup {...phoneNumberProps} hideOptional id='user_meta-secondaryNumber' width='small' position='left' title='Alternative Number' type='number-type' placeholder='(99) 9999 9999' />
                {!this.state.showPassword && <button onClick={(e)=>this.showPassword(e)} className="show-password button-5 rs">{this.props.hasPassword?'Change Password':'Set Password'}</button>}
            </div>
            
            
            {this.state.showPassword &&
              (<div className="password-section">
                {!!this.props.hasPassword && [
                  <PasswordField hideOptional id='user-currentPassword' width='small' position='left' title='Current Password' placeholder='Enter current password' />,
                  <div className="info"><span className="bank">Your information is protected by<br/>bank-level security and covered by an industry best practice insurance policy.</span></div>
                ]}
                <PasswordField hideOptional id='user-password' validate={[passwordValidation]} width='small' position='left' title='New Password' placeholder='Enter new password' />
                <PasswordField hideOptional id='confirmPassword' validate={[this.passwordsMatch]} width='small' position='right' title='Verify Password' placeholder='Enter new password' />
              </div>)}

            <div className="tooltips">
              <div className="content">
                <h3>Show tooltips & illustrations in Search Loans</h3>
                <p>Turning this option off will remove illustrations and introductory tips from the loan search section.</p>
              </div>
              <div className="switch">
                  <Field name="user_meta-user_meta_tooltips" type="checkbox" class="switch_1" component={Input}/>
              </div>

            </div>
            <button type="submit" className="update"><ButtonSpinner loading={this.props.submitting}/> Update Settings</button>

          </FormContainer>
          
          
        </div>
        <div style={{width: '665px'}}>
          <Disclaimer/>
        </div>

      </div>
      {/* hidden button for the purpose of correctly firing history.push(...). I honestly don't know why it doesn't work normally in this component... */}
      <button type="button" style={{display: 'none'}} ref={ref => this.redirect = ref} onClick={() => this.props.history.push("/confirm-account-submission")}></button>
    </main>
  );
}
}

function name(firstName, lastName) {
  let name = firstName || '';
  if (firstName && lastName) name += ' ';
  if (lastName) name += lastName || '';
  return name || "Secure Finance";
}

let AccountSettingsComponent = reduxForm({
  form: "account"
})(AccountSettings);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsComponent);

import React from "react";
import Payslips from "../Components/Payslips";
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import request from "superagent";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestFileUpload, requestPatchFile } from 'modules/user/actions';
import { randomBytes } from "crypto";
import _ from 'lodash'
import LinkButton from "Components/LinkButton";
import { emailService } from 'modules';
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

function mapStateToProps(state) {

  let form = state.form["eligibility-payslips"];

  return {
    uploads: state.user.uploads,
    formValues: form && form.values
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestFileUpload,
    requestPatchFile
  }, dispatch);
}

class SuccessPage extends React.Component {

  originalUploads = null;

  state = {
    submitting: false,
  }



  componentDidMount = () => {
    this.originalUploads = this.props.uploads;
    this.onClose = window.addEventListener("beforeunload", this.submit.bind(this));
  }

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.submit.bind(this))
    this.submit();
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    // Do something with files
    //console.log(acceptedFiles);
    
    acceptedFiles.forEach(file => {
      if (this.props.uploads.filter(item=>item.name===file.name).length > 0) {
        alert(file.name + " already uploaded.");
        return;
      }
      if (file.size >= 50000000) {
        alert("'" + file.name + "' is too large. Please ensure all files are below 50MB.");
        return;
      }
      this.props.requestFileUpload({file, type: 'payslip'});
    });
  }

  handleSubmit = (result, file)=>{
    this.props.requestPatchFile({id: file.id, data: {
      name: file.name,
      applicant: result["applicant_" + file.id]
    }});
  };

  submit = () => {
    //console.log('submit running')
    this.setState({ submitting: true });

    setTimeout(() => {
      this.setState({ submitting: false });
    }, 1500);
    
    if (_.isEqual(this.originalUploads, this.props.uploads)) return;

    this.originalUploads = this.props.uploads;
    //console.log('sending upload email');
    emailService.create({
      type: 'user-payslips',
    });
  }

  render() {
    const uploads = this.props.uploads
      .filter(file => file.type === 'payslip');

    return (
      <div className="form success" >
        <img src={require('img/page_illustrations/success.png')} alt='floating man'/>

        <h1>Success!</h1>
        <p>Awesome! Our skilled broking team will now look into your situation and figure out if you're eligible for the selected loan. We will also explore if there are any even better options for you out there. </p>
        <p>It would also help if you can <strong>provide your payslips</strong> so we can confirm certain aspects of your income. The reason we ask for this is that certain lenders treat income differently when it comes to consistency of earnings, bonuses, overtime and so on. </p>

        <h4>You can send all your files using the email: <a href="mailto:docs@securefinance.com.au">docs@securefinance.com.au</a></h4>

        {uploads.length < 20 ?
          <Dropzone onDrop={this.onDrop}>
            {({getRootProps, getInputProps, isDragActive}) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                >
                  <input {...getInputProps()} />
                  <div className="upload-container">
                    <p>Upload multiple files</p>

                    <div className="upload-directions">
                      <h2>Drop files here</h2>
                      <p>or <span>Browse Files</span> from your computer.</p>
                    </div>

                  </div>
                </div>
              )
            }}
          </Dropzone>
          :
          <div
            className='dropzone'
          >
            <div className="upload-container max-files">

              <div className="upload-directions">
                <h2>You have reached your 20 payslip limit</h2>
              </div>

            </div>
          </div>
        }
        

        <div className="payslips">
          <div className="payslips-header">
            <h2>Payslips</h2>
            {/* <button>Upload</button> */}
          </div>

          <ul className="payslips-titles">
            <li>File Name</li>
            <li>Applicant</li>
          </ul>

          <div className="uploaded-payslips">
            {
              (this.props.uploads.map) ? 
                this.props.uploads.map((file)=>{
                if (file.type === 'payslip') {
                  return <Payslips file={file} key={file.name} onSubmit={(result)=>this.handleSubmit(result, file)}/>
                } else {
                  return "";
                }             
              }):""            
            }
          </div>

        </div>
        <button type='button' onClick={this.submit} className="button-1"  style={{marginTop: '40px', float: 'right'}}>
          <ButtonSpinner loading={this.state.submitting}/>
          Submit
        </button>
      </div>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { createRequired } from 'Components/Forms/validation';
import { reduxForm, Field } from 'redux-form';

import Navigation from "Components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import { requestSecureLink } from "modules/user/actions";
import LinkButton from "Components/LinkButton";

import SecureLinkComponent from "./Components/SecureLinkComponent";


const emailRequired = createRequired('Please enter your email');

function mapDispatchToProps(dispatch) {
  return bindActionCreators({    
    requestSecureLink
  }, dispatch);
}

class SecureLink extends Component {

  state = { submitted: false, email: '' }

  submit = ({ email }) => {
    this.props.requestSecureLink({
      email, callback: response => {
        //console.log('response:', response)
        this.setState({ submitted: true, email })
      }
    });
    //console.log('did the thing')
  }

  resetMagicLink() {
    this.setState((prevState) => {return {...prevState, submitted: false, email: ''}});
  }

  render() {
    const { handleSubmit } = this.props;
    const { submitted } = this.state;
    return (
      <div>
        <Navigation state="unactive" />
        <main className="user-authentication securelink">
          <div className="modal single">

            <SecureLinkComponent />
            
            <div className="navigation back">
              <NavLink exact to="/login">
                ← Back to Sign In
              </NavLink>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const Input = ({meta: {touched, error}, input, formError, ...props}) => {
  return ( 
  <Fragment>
    <input
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <div className='field-error-message'>{error}</div>} 
  </Fragment>
  )
};

SecureLink = reduxForm({
  form: 'login',
  destroyOnUnmount: false,
})(SecureLink)

export default connect(() => {}, mapDispatchToProps)(SecureLink);

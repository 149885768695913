import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { Heading, Body, Container as ContainerBase, Column } from './Shared';


export default ({ user, settings }) => {
  
  const body = user.meta.miniproposal.overview?.whyLoanbase?.body 
    ?? settings.overview_why_body;
    
  return (
    <Container>
      <img
        src={require("../img/illustrations/why_loanbase.png")}
        alt="objectives"
      />

      <Column width="492px">
        <Heading>Why Secure Finance</Heading>
        <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(body) }} />
      </Column>
    </Container>
  );
};

const Container = styled(ContainerBase)`
  display: flex;
  padding-right: 0;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 15px;

  img {
    align-self: flex-end;
    width: 300px;
    margin-right: 33px;
    margin-bottom: 20px;
  }
`;
import { createAction } from 'redux-actions';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'




export const requestProducts = createAction(REQUEST_PRODUCTS);
export const receiveProducts = createAction(RECEIVE_PRODUCTS);

export const requestProduct = createAction(REQUEST_PRODUCT);
export const receiveProduct = createAction(RECEIVE_PRODUCT);
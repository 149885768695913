import React from "react";
import { Component } from "react";
// import Chat from 'Components/Global/Chat/Chat';
import ModuleBox from "Components/Global/ModuleBox/ModuleBox";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openModal, closeModal } from "modules/modals/actions";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeModal,
      openModal,
    },
    dispatch
  );
}

class LetsStart extends Component {
  render() {
    return (
      <ModuleBox
        id="lets-start"
        link="help"
        linkText="Learn more"
        class="lets-start"
        title="Let’s get started!"
        toggle="N"
      >
        <div className="half n1">
          <h4>Welcome</h4>
          <p>
            With thousands of finance options out there, we know it can be
            pretty confusing. It’s not only about finding a competitive
            offering, but also about understanding whether or not you qualify
            for that particular loan.{" "}
          </p>
          <h4>The Secure Finance Platform</h4>
          <p>
            We’ve designed this to make the mortgage process as easy as
            possible, whether that be for prospective property buyers,
            refinancers, or seasoned property investors. Our clever technology
            platform is backed by skilled mortgage specialists who are available
            via online chat, e-mail, phone, and face-to-face.
          </p>
          <h4>The Process</h4>
          <p>
            The best place to start is in Search Loans. Here you can compare and
            explore loan products of interest. Once you have found a product
            that you’re interested in, just click Qualify Me to start the
            process!
          </p>
          <div className="chat" onClick={() => window.Intercom("show")}>
            <div className="chat-container">
              <div className="chat-content">
                <h5>Let's chat!</h5>
                <p>&#183; We are Online</p>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="half n2">
          <div className="video">
            {/* <ReactPlayer className="video" url='https://securefinance.wistia.com/medias/rmsvmuub32' width="100%" height="262px" /> */}
            <img
              src={require("img/page_illustrations/dashboard-top.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="quick-links">
            <h4>A few things you can do on the Secure Finance platform:</h4>
            <ul>
              <li>
                <NavLink
                  exact
                  to={
                    this.props.user.shortlist.length === 0
                      ? "/search-loans"
                      : "/loan-shortlist"
                  }
                >
                  Compare your home loan options
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/calculators/borrowing-power/">
                  Calculate your borrowing power
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/calculators/refinancing/">
                  Find our how much refinancing can save you
                </NavLink>
              </li>
              <li>
                <a onClick={() => this.props.openModal("checkEligibilityHelp")}>
                  Get advice from our mortgage specialists
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ModuleBox>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LetsStart);
// <button className="btn-1 fw">Get Started</button>

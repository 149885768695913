import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";
import CurrencyField from '../Components/CurrencyField';

import { createNumberBetween } from 'Components/Forms/validation';
const numberBetween = createNumberBetween(10000, 20000000, (low, high) => `Your own contribution must be between $${low} and $${high}`); 

const lessThanPurchasePrice =
  (value, values) => value >= values['user_meta-purchasePrice'] ? 'Your contribution must be below the total cost' : null;

let YourContribution = function(props) {

  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
      <h1>How much of your own funds will you be contributing?</h1>
      <h2>If you're not sure, an approximate amount is fine.</h2>
      <CurrencyField maxNumber={99999999} hideOptional validate={[numberBetween, lessThanPurchasePrice]} id='user_meta-depositAmount' type='text'  mask="int" />
      <Navigation hidden={props.pristine} name="Next Step →" />
    </QuestionWrapper>
  )
}

YourContribution = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-deposit-amount'  // a unique identifier for this form
})(YourContribution);

const stateToProps = state => {
  let purchasePrice = null;
  if (state.form['onboarding-purchase-price']
  && state.form['onboarding-purchase-price'].values) {
    purchasePrice = state.form['onboarding-purchase-price'].values['user_meta-purchasePrice'];
  }
  return {
    purchasePrice,
  }
  
}; 

export default connect(stateToProps) (YourContribution);

import React from 'react';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux'

import { DoubleFieldGroup, FieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

import { createNumberBetween } from 'Components/Forms/validation';

const numberBetween = createNumberBetween(1, 30, (low, high) => `Loan term must be between ${low} and ${high} years`);

let CombinedIncome = function(props) {

  const skip = () => {
    props.change('user_meta-refinanceYears', 30);
    props.change('user_meta-loanPeriod', 30);

    props.skip();
  }

  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
        <h1>How many years are left on your loan term?</h1>
        <h2>This is an optional question. If you’re not sure, an approximation is fine.</h2>
        <div className='year-input-wrapper'>
          <FieldGroup maxNumber={99} validate={numberBetween} id='user_meta-refinanceYears' width='large' position='left' required type='text' mask='int' />
          <span>Years</span>
        </div>
        
        <Navigation optional skip={skip} name="Next Step →" />
    </QuestionWrapper>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-loan-term',  // a unique identifier for this form
  initialValues: {
    ['user_meta-refinanceYears']: 30,
    ['user_meta-loanPeriod']: 30,
  },
  onChange: (values, dispatch, props) => {
    dispatch(change('onboarding-loan-term', 'user_meta-loanPeriod', values['user_meta-refinanceYears']));
  }
})(CombinedIncome);

import React from "react"; import { Component, Fragment } from "react";
import Help from "../Components/Help";

import { connect } from 'react-redux';
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, RadioToggleGroup, Subtitle, timePeriod } from "Components/Forms";
import { reduxForm, formValueSelector, change, Form} from "redux-form";
import updateFormValues from "modules/updateFormValues"
import { createNumberBetween } from 'Components/Forms/validation';
import initializeFormValues from "utils/initializeFormValues";
import Debouncer from "utils/debouncer";
import { Loader } from "Components/Global/Loader";
import Disclaimer from 'Components/Global/GlobalDisclaimer';
import formatNumber from 'utils/formatNumber';
const debouncer = new Debouncer();

const loanTermValidation = createNumberBetween(1, 30, (low, high) => `Loan term must be between ${low} and ${high} years`);
const employmentTypes = [
  {text:'PAYG', value:'payg'},
  {text:'Self Employed', value:'selfEmployed'},
  {text:'Unemployed', value:'unemployed'}
];

class BorrowingPower extends Component {

  state = {
    loading: false,
    formChanged: false,
  }

  componentDidMount() {
    initializeFormValues('calculator', (values, state) => ({
      'user_meta-loanPeriod': 30,
      'user_meta-ioPeriod': 5,
      'user_meta-partnerStatus': 'couple',
      'user_meta-numDependents': 0,
      'user_meta-incomeType': state.user.meta.incomeType || timePeriod.YEAR,
      'user_meta-partnerIncomeType': state.user.meta.partnerIncomeType || timePeriod.YEAR,
      'user_meta-otherIncomeType': timePeriod.YEAR,
      'user_meta-partnerOtherIncomeType': timePeriod.YEAR,
      'user_meta-taxFreeIncomeType': timePeriod.YEAR,
      'user_meta-partnerTaxFreeIncomeType': timePeriod.YEAR,
      'user_meta-bonusOvertimeType': state.user.meta.bonusOvertimeType || timePeriod.YEAR,
      'user_meta-partnerBonusOvertimeType': state.user.meta.partnerBonusOvertimeType || timePeriod.YEAR,
      'user_meta-otherRentalIncomeType': timePeriod.WEEK,
      'user_meta-livingExpensesType': timePeriod.MONTH,
      'user_meta-carAndPersonalLoanRepaymentsType': timePeriod.MONTH,
      'user_meta-otherMorgageRepaymentsActualType': timePeriod.MONTH,


      'user_meta-income': state.user.meta.income,
      'user_meta-bonusOvertime': state.user.meta.bonusOvertime,
      'user_meta-partnerIncome': state.user.meta.partnerIncome,
      'user_meta-partnerBonusOvertime': state.user.meta.partnerBonusOvertime,
      ...values
    }));
  }

  onSubmit = values => {
    //console.log('submiting values:', values);
    if (this.state.loading) {
      //console.log('submission in queue')
      this.setState(state => ({...state, formChanged: true}));
      return;
    }

    this.setState({loading: true}, () => {
      //console.log('Updating form values')
      if (values['user_meta-ioPeriod']) values['user_meta-ioPeriod'] = Math.min(values['user_meta-ioPeriod'], values['user_meta-loanPeriod'])
      updateFormValues({...values, ['calculators-borrowingPowerActive'] : 'Y'}, 
      () => {}, true, true, 
      () => {
        if (this.state.formChanged) {
          //console.log('submitting deferred changes')
          this.setState(
            {formChanged: false, loading: false}, 
            this.props.submit
          );
        } else this.setState({formChanged: false, loading: false});
      });
    });
    
  }

  render() {
    const { applicants, isPandILoan, employmentType, partnerEmploymentType, borrowHigh, borrowLow } = this.props;
    return (
      <div className="borrowing-power">
        <h1>Calculate Your Borrowing Power</h1>
        <h2>Please enter your details below to calculate your borrowing capacity.</h2>
        <div className="content">

          <div className="left-content">

            <div className="module borrowing-power-result">
              {this.state.loading ? 
                <div className="result"><div className="loading-inline-empty"><Loader /></div></div>
                :
                <div className="result">${formatNumber(borrowLow, 0)}<span> to </span>${formatNumber(borrowHigh, 0)}</div>
              }
              <div className="result-subtitle">Your Borrowing power<sup>1</sup></div>
            </div>

            <div className="module tabs">
              <Tabs>

                <div className="navigation">
                  <TabLink to="tab1" className="tab-navigation">Applicants & Loan Details</TabLink>
                  <TabLink to="tab2" className="tab-navigation">Income & Expenses</TabLink>
                </div>
                <div className="tab-content">
                    <TabContent for="tab1">
                      <div className="tab">
                        <div className='form'>
                          <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Subtitle width='small' position='left' title='Applicants' />
                            <Subtitle width='small' position='right' title='Loan' />
                            <RadioToggleGroup id='user_meta-loanApplicants' width='small' position='left' title='Who will be on the loan application?' hideOptional placeholder='Just Me' checked='Just Me' valueChecked='justMe' unchecked='Me & My Partner' valueUnchecked='meAndPartner' />
                            <RadioToggleGroup id='user_meta-isPandILoan' width='small' position='right' title='Repayment Type' hideOptional placeholder='Principal & Interest' checked='Principal & Interest' unchecked='Interest Only' />
                            <SelectGroup empty id='user_meta-partnerStatus' width='small' position='left' title='Marital Status' hideOptional type='select' placeholder='Single' values={[{text: 'Married / De facto', value: 'couple'}, {text: 'Single', value: 'single'}]} />
                            <FieldGroup id='user_meta-loanPeriod' width='small' position='right' title='Total Term' hideOptional type='text' mask='years'  validate={loanTermValidation} maxlength={8}/>
                            <SelectGroup empty id='user_meta-numDependents' width='small' position='left' title='Number of Dependents' hideOptional type='select' placeholder='0' values={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']} />
                            <Optional show={!isPandILoan}><FieldGroup visible={false} id='user_meta-ioPeriod' width='small' position='right' title='Interest Only Term' hideOptional type='text' mask='years' /></Optional>
                          </FormContainer>
                        </div>
                        
                      </div>
                    </TabContent>
                    <TabContent for="tab2">
                      <div className="tab">
                        <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)}>
                          <div class='row'> 
                            <div className='half-column left'>
                              <Subtitle width='small' position='left' title='My Income' />
                              <SelectGroup id='user_meta-employmentType' width='small' position='left' title='Employment Type' hideOptional type='select' placeholder='PAYG' values={employmentTypes} />
                              {employmentType === 'payg' &&
                                <Fragment>
                                  <DoubleFieldGroup id='user_meta-income' width='small' position='left' title='Salary Before Tax (excluding Super)' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  <DoubleFieldGroup id='user_meta-bonusOvertime' width='small' position='left' title='Bonus / Overtime / Commission' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  <DoubleFieldGroup id='user_meta-otherIncome' width='small' position='left' title='Other Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                </Fragment>
                              }
                              {employmentType === 'selfEmployed' &&
                                <Fragment>
                                  <DoubleFieldGroup id='user_meta-income' width='small' position='left' title='Self-Employed Income Last Financial Year' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  <DoubleFieldGroup id='user_meta-otherIncome' width='small' position='left' title='Other Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                </Fragment>
                              }
                              {employmentType === 'unemployed' &&
                                <Fragment>
                                  <DoubleFieldGroup id='user_meta-income' width='small' position='left' title='Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                </Fragment>
                              }
                              {employmentType &&
                                <Fragment>
                                  <DoubleFieldGroup id='user_meta-taxFreeIncome' width='small' position='left' title='Tax-Free Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  <RadioToggleGroup id='user_meta-hecsDept' width='small' position='left' title='HECS or FEE-HELP debt?' hideOptional placeholder='Yes' checked='Yes' unchecked='No' valueChecked='Y' valueUnchecked='N' />
                                </Fragment> 
                              }
                              

                            </div>

                            <div className='half-column right'>
                              <Optional show={applicants === "meAndPartner"}>
                                <Subtitle width='small' position='left' title='Partner Income' />
                                <SelectGroup id='user_meta-partnerEmploymentType' width='small' position='left' title='Employment Type' hideOptional type='select' placeholder='PAYG' values={employmentTypes} />
                                {partnerEmploymentType === 'payg' &&
                                  <Fragment>
                                    <DoubleFieldGroup id='user_meta-partnerIncome' width='small' position='left' title='Salary Before Tax (excluding Super)' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                    <DoubleFieldGroup id='user_meta-partnerBonusOvertime' width='small' position='left' title='Bonus / Overtime / Commission' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                    <DoubleFieldGroup id='user_meta-partnerOtherIncome' width='small' position='left' title='Other Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  </Fragment>
                                }
                                {partnerEmploymentType === 'selfEmployed' &&
                                  <Fragment>
                                    <DoubleFieldGroup id='user_meta-partnerIncome' width='small' position='left' title='Self-Employed Income Last Financial Year' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                    <DoubleFieldGroup id='user_meta-partnerOtherIncome' width='small' position='left' title='Other Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  </Fragment>
                                }
                                {partnerEmploymentType === 'unemployed' &&
                                  <Fragment>
                                    <DoubleFieldGroup id='user_meta-partnerIncome' width='small' position='left' title='Taxable Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                  </Fragment>
                                }
                                {partnerEmploymentType &&
                                  <Fragment>
                                    <DoubleFieldGroup id='user_meta-partnerTaxFreeIncome' width='small' position='left' title='Tax-Free Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                                    <RadioToggleGroup id='user_meta-partnerHecsDept' width='small' position='left' title='HECS or FEE-HELP debt?' hideOptional placeholder='Yes' checked='Yes' unchecked='No' valueChecked='Y' valueUnchecked='N' />
                                  </Fragment> 
                                }
                              </Optional>
                            </div>
                          </div>
                          
                          <Subtitle width='large' position='left' title={applicants ? 'Other Financials' : 'Combined Financials'} />
                          <DoubleFieldGroup id='user_meta-otherRentalIncome' width='small' position='left' title='Total Rental Income' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                          <DoubleFieldGroup id='user_meta-livingExpenses' width='small' position='right' title='Living Expenses' hideOptional type='monetary-time' mask='monetary' placeholder='0' mask='monetary'/>
                          <FieldGroup id='user_meta-combinedCreditCardLimit' width='small' position='left' title='Combined Credit Card Limit' hideOptional type='text' placeholder='0' mask='monetary'/>
                          <DoubleFieldGroup id='user_meta-carAndPersonalLoanRepayments' width='small' position='right' title='Total Car and Personal Loan Repayments' hideOptional type='monetary-time' mask='monetary' placeholder='0' />
                          <FieldGroup id='user_meta-otherMorgageValue' width='small' position='left' title='Other Mortgage Debt' hideOptional type='text' placeholder='0' mask='monetary'/>
                          <DoubleFieldGroup id='user_meta-otherMorgageRepaymentsActual' width='small' position='right' title='Other Mortgage Debt Total Repayments' hideOptional type='monetary-time' mask='monetary' placeholder='0' />

                        </FormContainer>
                      </div>
                    </TabContent>
                  </div>
              </Tabs>
            </div>

            <div className="references"><sup>1</sup>Results should only be used as a rough guide as we make a number of assumptions for this calculation. Lender credit policy may also change at any time, affecting these results. Please get in touch for a personalised assessment.</div>

          </div>

          <div className="right-content">
            <div className="image"></div>
            <Help />
          </div>

        </div>
        <Disclaimer/>
      </div>
    );
  }
}

const Optional = ({ show, ...props }) => <span className={show ? '' : 'invisible'} {...props}/>

BorrowingPower = reduxForm({
  form: "calculator",
  onChange: (values, dispatch, props, previousValues) => {
      if (values['user_meta-loanApplicants'] === 'justMe' && previousValues['user_meta-loanApplicants'] && previousValues['user_meta-loanApplicants'] !== 'justMe') {
        dispatch(change('calculator','user_meta-partnerStatus', 'single'));
      }
      debouncer.run(props.submit, 300);
  },
})(BorrowingPower);


const selector = formValueSelector('calculator')
const stateToProps = state => ({
  applicants: selector(state, 'user_meta-loanApplicants'),
  isPandILoan: selector(state, 'user_meta-isPandILoan'),
  employmentType: selector(state, 'user_meta-employmentType'),
  partnerEmploymentType: selector(state, 'user_meta-partnerEmploymentType'),
  borrowHigh: state.user.borrowingPower && state.user.borrowingPower.high || 0,
  borrowLow: state.user.borrowingPower && state.user.borrowingPower.low || 0,
  loading: state.user.userUpdating,
});

export default connect(stateToProps)(BorrowingPower);

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { removeFromShortlist, requestPatchUser } from 'modules/user/actions';
import { openModal, closeModal } from 'modules/modals/actions';

function mapStateToProps(state) {
  return {
    nsrValues: state.user.nsrValues,
    user: state.user.meta,
    userId: state.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    removeFromShortlist,
    requestPatchUser
  }, dispatch);
}

class LoanShortlistFiltered extends Component {

  render() {
    const { count } = this.props;
    return (
      <div className="shortlist-result filtered" onClick={()=>this.props.openModal("shortListFilterView")}>
        <div className="content">
          <button className="shortlist-button">
            <div className="number">{count}</div>
          </button>
          <h2>There are <span>{count} more</span> loan products<br />in your shortlist</h2>
          <p>They have been excluded as they don’t match your search criteria.</p>
          <p>Click here to show hidden shortlisted loan products</p>
        </div>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanShortlistFiltered);

import React from 'react';
import { reduxForm } from 'redux-form';
import { FieldGroup } from "Components/Forms";
import QuestionWrapper from "../Components/QuestionWrapper";
import Navigation from "../Components/Navigation";
import { DoubleFieldGroup } from "Components/Forms";
import { phoneNumberProps } from 'Components/Forms/validation';


let MobileNumber = function({ pristine, globalSettings, ...props }) {
  const heading = (globalSettings || {}).intro_phonenumber_heading || 'Finally, what’s your phone number?';
  const subtext = (globalSettings || {}).intro_phonenumber_subtext || 'So we can qualify you for the most competitive & unadvertised deals.';
  return (
    <QuestionWrapper finalQuestion={props.finalQuestion} isTransitioning={props.isTransitioning} invalid={props.invalid}  debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.finishOnboarding} maxQuestion={props.maxQuestion}>
      <h1>{heading}</h1>
      <h2>{subtext}</h2>
      <DoubleFieldGroup  {...phoneNumberProps} width='large' id='phoneNumber' required title="Phone Number" type='number-type' />
      <Navigation hidden={ pristine } name="Finish" greenButton/>
      <img className='img-success' src={require('../../../img/page_illustrations/success.png')}/>
      <p className='p-success'>Hooray, your results await! 🚀</p>
    </QuestionWrapper>
  )
}

MobileNumber = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-mobile-number'  // a unique identifier for this form
})(MobileNumber);

export default MobileNumber;

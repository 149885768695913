import styled, { css } from 'styled-components'

export const Header = styled.h2`
  /* border: solid 2px;
  border-radius: 6px; */
  width: 100%;
  outline: none;
  min-width: 100px;
  /* padding: 5px; */
  display: inline-block;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: ${p => p.marginBottom ?? '10px'} !important;
  ${p => !p.isPDF && css`
    margin-top: 25px;
  `}
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

  &.active {
    /* &:focus, &:hover, &.empty {
      border: solid 1px #D8DCE7;
      border-radius: 3px;
      transition: border-color 0.4s ease-in-out;
    }  */

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

export const Subtitle = styled.h3`

  outline: none;
  /* border-radius: 4px; */
  min-width: 80px;
  padding: 3px;
  display: inline-block;
  text-align: center;
  color: #727C8F;
  font-size: 10px !important;
  border: solid 1px;
  border-color: #FFF;
  transition: border-color 0.4s ease-in-out;

  &.active {
    /* &:focus, &:hover, &.empty {
      border-color: #D8DCE7;

      transition: border-color 0.4s ease-in-out;
    } */

    &.empty {
      border: solid 1px;
      border-radius: 6px;
      border-color: #EB5846;
    }
  }

`;

export const SectionContainer = styled.div`
  max-width: 920px;
  ${p => !p.isPDF && css`
    padding: 20px 20px 20px 20px;
    margin: 0 auto;
  `}
`;
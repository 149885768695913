import React from 'react';
import { reduxForm } from 'redux-form';

import { FieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

const notBlank = value => (value && value.trim()) ? null : 'This is required';

let FullName = function(props) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
      <h1>So, what's your name?</h1>
      <h2>Our search results are personalised for you!</h2>
      <FieldGroup id='name' width='large' position='left' required='yes' type='text' validate={notBlank} />
      <Navigation hidden={props.pristine || props.invalid } name="Next Step →" />
    </QuestionWrapper>
  )
}

FullName = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-full-name'  // a unique identifier for this form
})(FullName);

export default FullName;

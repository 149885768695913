import React from "react"; import { Component } from "react";
import ContactForm from "./ContactForm"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';

import Navigation from "Components/Navigation/Navigation";

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
  }, dispatch);
}

class Contact extends Component {
  componentDidMount() {
    document.title = "Secure Finance - Contact Us";
    this.props.setTitle("Contact Us");
    this.props.setPageID("contact");
  }

  render() {
    return (
      <div>
        {this.props.type === "initial" ? <Navigation state="unactive" /> : ""}
        <main
          className={
            "contact " +
            (this.props.type === "initial" ? "contact-initial" : "") +
            " " +
            this.props.type
          }
        >
          <div className="main-container">
            <div className="contact-box-container">
              <div className="contact-box call-us">
                <h4>Call Us</h4>
                <p>
                  We love talking to our customers,
                  <br />
                  give us a call!
                </p>
                <a className="contact-button" href="tel:0404 262 056 ">
                  0404 262 056{" "}
                </a>
              </div>
              <div className="contact-box email-us">
                <h4>Email Us</h4>
                <p>
                  If email is your thing, send us a note at
                  <br />
                  info@securefinance.com.au
                </p>
                <a
                  className="contact-button"
                  href="mailto:info@securefinance.com.au"
                >
                  Email Us Now
                </a>
              </div>
              <div className="contact-box chat">
                <h4>Chat with an expert</h4>
                <p>
                  No questions is too big or small.
                  <br />
                  Start conversation now!
                </p>
                <button
                  onClick={() => window.Intercom("show")}
                  className="contact-button"
                >
                  Online Chat Now
                </button>
              </div>
            </div>
            <div className="request-callback">
              <h4>Request a call back</h4>
              <p>Any time, Business Hours, After Hours, Weekend</p>

              <ContactForm button="Send call back request" />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

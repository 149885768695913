import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import ProceedButton from 'Components/ProceedButton';
import calculateLoanValues from "utils/calculateLoanValues";

import ProductExists from "./Components/ProductExists";

function mapStateToProps(state) {
  return {
    user: state.user.meta,
    userId: state.user.id,
    page: state.page,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}


class CheckEligibilityAdd extends Component {
  proceedToEligibility(){
    let newState = this.props.user.proceedToEligibility?0:1;
    this.props.requestPatchUser({userId: this.props.userId, data: {meta: {proceedToEligibility: newState}}, callback: ()=>{}});
  }

  cancelClick = () => {
    const {user, products, application, removeProductFromApplication, openModal } = this.props
    if (user.eligibilityStart == "1") {
      openModal("checkEligibilityDelete")
    } else {
      for (const product of products) {
        removeProductFromApplication({product_id: product.id, applicationId: application.id});
      }
    }
  }

  render() {
    let productsCount = (this.props.applicationProducts || []).length;
  
    return (
      <div className="bottom-modal" style= {{
        height: (this.props.active?"186px":"0px"),
      }}>
        <div className="modal-bottom checkeligibilityadd">
          <div className="content">
            <div className="products-list">

              {(this.props.products ?? []).map((product)=><ProductExists product={product} removeProductFromApplication={this.props.removeProductFromApplication} application={this.props.application} />)}
              {[...Array(Math.max(3 - this.props.products?.length, 0)).keys()].map((i)=>(<ProductEmpty />))}

            </div>
            <div className="navigation">
              <div className="navigation-container">
                <ProceedButton name="Qualify Me!" />
                <button onClick={this.cancelClick} className="cancel">Cancel</button>
              </div>
            </div>
            <div className="tips">
              <p>⚡️ Select up to 3 products and click <strong>Qualify Me.</strong></p>
              <p>Our expert broking team will then review your scenario to ensure you qualify. We can then help you apply.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckEligibilityAdd);


class ProductEmpty extends Component {
render() {
    return(
      <div className="product empty">
        <div className="product-content">
          <h3>Select another product above</h3>
          <p>Optional</p>
        </div>
      </div>
   )};
}

// <button onClick={()=>this.props.openModal("checkEligibilityView")} className="product-list"><div className="number">{productsCount}</div></button>
// <div className="product-text">
// <h3>You have selected {productsCount} product{(productsCount > 1)?"s":""}.</h3> 
//   <p>You can select up to {3 - productsCount} more for an eligiblity check.</p>
//   {/* {this.props.user.proceedToEligibility + " "} */}
// </div>
// <div className="navigation">
//   <button onClick={()=>this.props.openModal("checkEligibilityDelete")} className="cancel">Cancel</button>
//   <ProceedButton name="Proceed" />
//   {/* <button onClick={()=>this.proceedToEligibility()} >Please close</button> */}
//   {/* <LinkButton to="/check-eligibility/" onClick={()=>this.proceedToEligibility()} className="proceed">Proceed</LinkButton> */}
// </div>

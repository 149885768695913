import React from "react"; import { Component } from "react";
import Navigation from "Components/Navigation/Navigation";
import { PasswordField } from "Components/Forms";

class SetupPassword extends Component {
  render() {
    return (
    <div>
      <Navigation state="unactive" />
      <main className="user-authentication">
        <div className="modal single">
          <h2>Setup your password</h2>
          <h4>We just sent an email to: <span>jeff@wax.com.au</span></h4>
          <h4>Please enter the code below to verify this is your email address and then set up a new password.</h4>
          <form>
            <label>Access Code</label>
            <input name="accesscode" placeholder="Enter access code" />
            <br />
            <label>Password</label>
            <PasswordField name="password" placeholder="Enter Password" />
            <span className="password-requirements">Min, 8 characters. At least 1 letter &amp; 1 number.</span>
            <label>Confirm Password</label>
            <PasswordField name="password" placeholder="Enter Password" />
            <button type="submit">Setup my New Password &amp; Continue</button>
          </form>

          <hr />

          <div className="access-code">
            <div className="access-code-text">
              <span>No access code?</span>
              Check your spam or request another access code
            </div>
            <button className="access-link">Get access code!</button>
          </div>

        </div>


      </main>
  </div>
  );
}
}


export default SetupPassword;

import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { openModal, openModalWithArgs } from "modules/modals/actions";
import { Header } from "../../theme/General";
import SectionButton from "../../component/SectionButton";
import BankLogoBase from 'Components/Global/BankLogo';
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import formatNumber from 'utils/formatNumber';
import _ from 'lodash';

class CurrentLoan extends React.Component {

  titleRef = React.createRef()

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  save = values => {
    this.patchProposal(values)

    this.props.cancelEdit();
  }

  enableEdit = () => {
    setTimeout(() => {
      this.titleRef.current.focus();
    });
    setTimeout(() => {
      document.execCommand('selectAll');
    }, 5);
    this.props.toggleEdit();
  }

  patchProposal = (data, callback) => this.props.requestPatchUser({ 
    userId: this.props.user.id,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [this.props.tabId]: {
              currentLoan: data
            }
          }
        }
      } 
    },
    callback, 
  });

  render() {
    const { user, data, miniProposal } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (!this.props.isView) return null;
    return (
      <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        <CurrentLoanContainer active={this.props.active} isView={this.props.isView}>
        
          <Header isPDF={this.props.isPDF && this.props.isTop}><Span innerRef={this.titleRef} name='title' as={EditableContent} disabled={!this.props.active}/></Header>
          <LoanBox hasLender={!!data.lender}>
            {!noGodmode && !!user.godmode && !miniProposal.previewMode && !this.props.isPDF &&
              <SectionButton
              removeSection={this.props.removeSection}
              enableEdit={() => this.props.openModalWithArgs({ modal:"currentLoanDetails", args: { tabId: this.props.tabId }})}
              isEditable={this.props.active}
              cancelEdit={() => this.cancel()}
              editTitle={() => this.enableEdit()}
              type="loan" style={{ float: 'right', position: 'absolute', top: '0', right: '0' }} />
            }
            
            {!!data.lender &&
            <Column className="logo">
              <BankLogo product={{ bank: data.lender !== 'other' ? data.lender : null, bank_name: data.otherLenderName }}/>
            </Column>
            }
           

            <Column>
              <LoanDetailsValue style={{ fontSize: '26px' }}>${formatNumber(data.monthlyRepayment)}</LoanDetailsValue>
              <LoanDetailsSubtitle>Monthly Repayment</LoanDetailsSubtitle>
            </Column>
            <Column>
              <LoanDetailsValue style={{ fontSize: '26px' }}>
                {data.interestRate || '???'}
                <span style={{ fontWeight: '400', fontSize: '12px', color: '#727C8F' }}> % p.a.</span>
              </LoanDetailsValue>
              <LoanDetailsSubtitle>{data.rateType}</LoanDetailsSubtitle>
            </Column>
            {/* <Column>
              <LoanDetailsValue>${formatNumber(data.loanAmount) || '???'}</LoanDetailsValue>
              <LoanDetailsSubtitle>Loan Amount</LoanDetailsSubtitle>
            </Column> */}
            <Column>
              <LoanDetailsValue style={{fontSize: '14px', fontWeight: '400' }}>{data.repaymentType === 'PandI' ? 'Principal & Interest' : 'Interest Only'}</LoanDetailsValue>
              <LoanDetailsSubtitle>Repayment Type</LoanDetailsSubtitle>
            </Column>
          </LoanBox>
        </CurrentLoanContainer>
      </Form>
      
    )
  }

}

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50px;
  font-weight: 700;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const BankLogo = styled(BankLogoBase)`
  align-self: center;
  margin-bottom: -18px;
  max-height: 50px;
  max-width: 90px;
  object-fit: contain;
`;

const CurrentLoanContainer = styled.div`
  position: relative;
  max-width: 100%;
  transition: max-height 0.2s;
  max-height: 1000px;

  ${props => props.active && css`
    z-index:  500;
  `}

  ${props => !props.isView && css`
    max-height: 0;
    overflow: hidden;
  `}
`;

const LoanBox = styled.div`

  display: block;
  max-width: 220px;
  margin: 0 auto;

  border: 1px solid #D8DCE7;
  border-radius: 4px;
  position: relative;

  div:first-of-type {
    border-top: none;
  }

  margin-bottom: 35px;
`;

const Column = styled.div`
  border-top: 1px solid #D8DCE7;
  display: block;
  padding: 20px !important;

  &:last-of-type {
    margin-right: 0;
  }

  &.logo {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 0;
    border-top: none;

    img {
      display: block;
      margin-bottom: 0 !important;
    }
  }
  
  @media (max-width: 900px) {
    &.logo {
      flex: 0 0 100%;
    }
  }
`;

const LoanDetailsValue = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: #101922;
  text-align: center;
  margin-bottom: 8px;
`;

const LoanDetailsSubtitle = styled.div`
  font-size: 13px;
  color: #727C8F;
  text-align: center;
`;


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    openModalWithArgs,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  miniProposal: state.user.meta.miniproposal,
  browser: state.browser,
});

export default reduxForm({ enableReinitialize: true, destroyOnUnmount: false, })
  (connect(mapStateToProps, mapDispatchToProps)(CurrentLoan));

import React from 'react';

import ModuleBox from 'Components/Global/ModuleBox/ModuleBox';
import LoanApplicationsBox from 'Views/Dashboard/Components/LoanApplicationsBox';

let LoanApplicationsModules = [
  {
    id: '1',
    loanProduct: {
      lender: 'lender1.png',
      package: 'Offset Home Loan Package',
      interest: {
        variableRate: 3.79,
        comparisonRate: 3.79
      },
      additionalProductCount: 2,
      information: 'Lorem ipsum'
    },
    keyDetails: {
      borrowing: 400000,
      purpose: 'Refinance',
      property: '35 Buckingham Street, Surry Hills 2010, NSW',
      propertyType: 'Investment'
    },
    stage : {
      stage: 1,
      eligiblityStatus: 'lodged'
    },
    commentsSection: {
      comment: {
        author: 'Chris Gillis',
        date: '24th June',
        body: 'Please click the button to the left to resume and finish your eligibilityn check so that we can cwhether or not this loan product is suitable for'
      }
    }
  },
  {
    id: '2',
    loanProduct: {
      lender: 'lender2.png',
      package: 'Package Loan',
      interest: {
        variableRate: 4.21,
        comparisonRate: 3.82
      },
      additionalProductCount: 5,
      information: 'Lorem ipsum'
    },
    keyDetails: {
      borrowing: 520600,
      purpose: 'Refiance',
      property: '35 Buckingham Street, Surry Hills 2010, NSW',
      propertyType: 'Investment'
    },
    stage : {
      stage: 1,
      eligiblityStatus: 'unfinished'
    },
    commentsSection: {
      comment: {
        author: 'Jeffrey Lau',
        date: '30th June',
        body: 'Please click the button to the left to resume and finish your eligibilityn check so that we can cwhether or not this loan product is suitable for'
      }
    }
  }
];

const LoanApplications = () => {
  return (
    <ModuleBox id="loan-application" class="loan-applications nb" title="Loan Applications" toggle="Y">
      <table>
        <tbody>
          <tr className="app-label">
            <th>Loan Details</th>
            <th>Application Stage</th>
            <th>Updates & Comments</th>
          </tr>

          {LoanApplicationsModules.map((box)=><LoanApplicationsBox calculateBox={box} key={box.id} />)}
        </tbody>
      </table>

    </ModuleBox>
  );
};

export default LoanApplications;

import {
  SET_HOST_IS_DEAD
} from './actions';

export const localReducer = (state = {}, action) => {
  switch(action.type) {
    case SET_HOST_IS_DEAD: {
      return {...state, hostIsDead: action.payload};
    }
    default : {
      return state;
    }
  }
};

import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import { reduxForm } from "redux-form";
import { bindActionCreators } from 'redux';

import { FormContainer, SelectGroup } from "Components/Forms";


function mapStateToProps(state) {
  return {
    user: state.user.meta,
    initialValues: {payment_frequency: "monthly"}
  };
}



class LoanShortlistLabel extends Component {

  

  submitForm() {
    setTimeout(() => this.props.handleSubmit(), 100);
  }

  render() {
    return (

        <div className="shortlist-labels">

        <ul>
          <li>Lender</li>
          <li className="label-payments">
            <span>Payments</span>
            <FormContainer onSubmit={this.handleSubmit}>
              <SelectGroup onChange={() => this.submitForm()} empty="false" id='user_meta-searchFilter_repaymentFrequency' width='small' position='left' hideOptional type='select' placeholder='0' 
                values={[
                {value: 'monthly', text: 'Monthly'},
                {value: 'fortnightly', text: 'Fortnightly'},
                {value: 'weekly', text: 'Weekly'},
                ]} />
            </FormContainer>
          </li>

          <li>Interest rate</li>

          {
            (this.props.type === "dashboard" && !this.props.user.pageState_dashboard_loanshortlist_showmore)
            ?
            <li>More Information</li>
            :
          <div className="shortlist-additional-labels">
            <li>Total cost</li>

            {(this.props.type === "check-eligibility")?
              ""
              :
              <li>Borrowing Power</li>
            }

            <li>Repayment Type</li>
            <li>Fees</li>
            <li>Cost Reducers</li>
            <li>Redraw from Home Loan</li>

            {(this.props.type === "check-eligibility")?<li>More Information</li>:""}

            {(!(this.props.user.pageState_checkeligibility_loanshortlist_showmore) && (this.props.type === "check-eligibility"))?
              <div className="more-information">
                <li>Additional Features</li>
                <li>Loan Types</li>
              </div>
              :
              ""
            }

          </div>
          }



        </ul>
        </div>


      
    );
  }
}

let LoanShortlistLabelComponent = reduxForm({
  form: "searchFilter",
  destroyOnUnmount: false
})(LoanShortlistLabel);

export default connect(mapStateToProps, null)(LoanShortlistLabelComponent);

import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import ProceedButton from 'Components/ProceedButton';
import calculateLoanValues from "utils/calculateLoanValues";
import BorrowingPowerValue from "Components/BorrowingPowerValue"
import BankLogo from 'Components/Global/BankLogo';
import Tooltips from "Components/Tooltips";

export default class CheckEligibilityView extends Component {

  constructor(props) {
    super(props);

    this.state= {removeToggleMap: {}};
  }

  toggleRemove(id) {
    this.setState((prevState) => {return {...prevState, removeToggleMap: {...prevState.removeToggleMap, [id]: !prevState.removeToggleMap[id]}}});
  }

  render() {

    if ((this.props.currentModal === "checkEligibilityView")) {
    return (
      <div className="modal-content">
        <div className="heading">
          <h1>Your Eligibility List</h1>
        </div>

        <div className="product-list">
          <div className="scroll-container" style={{width: (3 * 290)}}>
            {this.props.products.map((product)=>
              <Product removeToggle={this.state.removeToggleMap[product.id]}
                toggleRemove={(id)=>this.toggleRemove(id)}
                application={this.props.application}
                removeProductFromApplication={this.props.removeProductFromApplication}
                productLength={this.props.products.length}
                closeModal={this.props.closeModal}
                nsrValues={this.props.nsrValues} product={product} />)}

            {(this.props.products.length < 3)?
            <div className="select-more">
              <p>You can still select up to <strong>{(3 - this.props.products.length)} more loan</strong> for an eligibility check</p>
            </div>
            :""
            }

          </div>
          
        </div>

        <div className="navigation">

          {(this.props.products.length < 3)?<ProceedButton className="skip" name="Continue to Qualifer" />:""}

          {(this.props.products.length < 3)
            ?
            <LinkButton to="/search-loans/" onClick={()=>this.props.closeModal()} className="add">{"Add Up to " + (3 - this.props.products.length)+ " More"}</LinkButton>
            :
            <ProceedButton className="add" name="Continue to Qualify Me" />
          }

          {/* <LinkButton to={(this.props.products.length >= 3)?"/check-eligibility/":"/search-loans/"} onClick={()=>this.props.closeModal()} className="add">{(this.props.products.length >= 3)?"Continue to Check Eligibility":"Add Up to " + (3 - this.props.products.length)+ " More"}</LinkButton> */}
        </div>

      </div>
    ) } else {
      return "";
    }
  }
}

class Product extends Component {
  removeLoan(){
    //this.setState((prevState) => {return {...prevState, removeToggle: !prevState.removeToggle}});
    this.props.toggleRemove(this.props.product.id);
  }

  render() {
       
    let product = calculateLoanValues(this.props.product);

    return(
      <div className="product">
        <div className="product-item">

          {(this.props.removeToggle?
            <div className="remove">
              <button onClick={()=>this.removeLoan()} className="close"></button>
              <h2>Are you sure<br />you want to remove this loan?</h2>
              {/* {this.props.application.id}
              {"  "}
              {this.props.product.id} */}
              <div className="remove-navigation">
                <button className="cancel-button" onClick={()=>this.removeLoan()} >Cancel</button>
                <button className="remove-button" onClick={()=>{
                    this.props.removeProductFromApplication({applicationId: this.props.application.id, product_id: this.props.product.id})
                    if (this.props.productLength === 1) {
                      this.props.closeModal();
                    }
                  }} >Remove</button>
              </div>
            </div>
          :"")}

          <div className="active">
              <button onClick={()=>this.removeLoan()} className="close"></button>
                <BankLogo product={product} />
              <h6>{product.product_name}</h6>
              <LinkButton to={"/products/" + product.id} onClick={()=>this.props.closeModal()} className="more-detail">More Details</LinkButton>

              <div className="interest-rate">
                <div className="variable-rate">
                  <div className="rate">{product.iir}<span>% p.a.</span></div>
                  <div className="description">{product.rateDescriptionYears}</div>
                </div>
                <div className="comparison-rate">
                  <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
                  <div className="description">Comparison</div>
                </div>
              </div>

              <div className="repayment-power">
                <div className="monthly-repayment">
                  <div className="rate">${(Math.round(product.monthlyRepayments)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  <div className="description">Monthly Payment</div>
                </div>
                <div className="borrowing-power">
                  <BorrowingPowerValue value={(this.props.nsrValues[product.bank] || this.props.nsrValues['default'])} />
                  <div className="description">Borrowing Power</div>
                </div>
              </div>

              <ul className="statistics">
                <li className={product.redraw_allowed}>Redraw</li>
                <li className={product.full_offset}>
                  {product.full_offset === 'additional' ? 
                    <Tooltips type="wrapper" definition={product.offsetTooltip}>Offset</Tooltips>
                    :
                    <span>Offset</span>
                  }
                </li>
                <li className={product.extra_repayments_allowed}>Early Repayments</li>
              </ul>

              <div className="fees">
                <div className="upfront-fee">${product.approxUpfrontFee}<span>Approx Upfront</span></div>
                <div className="annual-fee">${product.annual_fees}<span>Annual Fee</span></div>
              </div>

              <div className="principal-interest">{/*TODO: Update this*/}
                <h5>Principal &amp; Interest</h5>
                <div className="description">Repayment Type</div>
              </div>

            </div>

        </div>
      </div>
    );
  }
}
import parseNumber from './parseNumber';

export default function formatNumber(n, d = 0) {
    //console.log('formatting number', n);
    if (!n) return '0';
    if (typeof n === 'string') n = parseNumber(n);
    return n
    .toFixed(d)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

class LoginAccountAbused extends Component {
  render() {
    if (this.props.currentModal === "loginAccountAbused") {
    return (
      <div className="modal-content">
        <h1>Please verify your e-mail address</h1>
        <p>An account already exists with this e-mail address.</p>
        <br />
        <p>A verification e-mail has been sent to your address. Please<br/>check your e-mail and click on the link in this e-mail in order to<br/>verify your account and sign in.</p>
        <div className="modal-navigation">
          <button onClick={()=>this.props.closeModal()} className="single button-1">Ok</button>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default LoginAccountAbused;

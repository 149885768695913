import React, { Component } from 'react';

class DragToScroll extends Component {
  constructor() {
    super();
    this.state = {
      xPos: 0,
      yPos: 0,
      isDragging: false,
    };
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', this.mouseDown);
    document.body.addEventListener('mouseup', this.mouseUp);
    this.div.addEventListener('mousemove', this.mouseMove);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.mouseDown);
    document.body.removeEventListener('mouseup', this.mouseUp);
    this.div.removeEventListener('mousemove', this.mouseMove);
  }

  mouseDown = e => {
    if (!e.target) return;
    
    if (this.props.exclude && e.target.matches(this.props.exclude)) return;

    if (e.target.matches(['.drag-scroll', '.drag-scroll *'])) {
      e.preventDefault();
      this.xPos = e.pageX;
      this.yPos = e.pageY;
      this.isDragging = true;
      document.body.style.cursor = 'move';
    }
    
  }

  mouseUp = e => {
    this.isDragging = false;
    document.body.style.cursor = 'default';
  }

  mouseMove = e => {
    if (!this.isDragging) return;
    const { horizontal, vertical } = this.props;
    const oldX = this.div.scrollLeft;
    const newX = oldX + (this.xPos - e.pageX);
    const oldY = this.div.scrollTop;
    const newY = oldY + (this.yPos - e.pageY);

    this.div.scrollTo(horizontal ? newX : oldX, vertical ? newY : oldY);
    this.xPos = e.pageX;
    this.yPos = e.pageY;
  }



  render() {
    const {children, exclude, ...props} = this.props;
    return (
      <div 
        ref={element => this.div = element} 
        className='drag-scroll'
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default DragToScroll;
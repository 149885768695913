import React from "react"; import { Component } from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


class checkEligibilityHelpTimer extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityHelpTimer") {
    return (
      <div className="modal-content">
        <img src={require('../../../img/modal/checkeligibilityhelptimer.png')} alt="checkeligibilityhelptimer"/>
        <h1>Need some help?</h1>
        <p>Just checking in. We're always here if you need any help with completing your qualifier, or with anything else.</p>
        <div className="modal-navigation">
          <button onClick={()=>this.props.closeModal()} className="double left button-5">I'm fine!</button>
          <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="double right button-1">Help!</button>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default checkEligibilityHelpTimer;
import store from 'store';
import {requestSignup} from "modules/user/actions";
import updateFormValues from "modules/updateFormValues"
import requestFilteredProducts from "modules/product/requestFilteredProducts"
import { openModal, openModalWithArgs, closeModal } from 'modules/modals/actions';
import { SubmissionError } from 'redux-form';
import { emailService } from 'modules';
import { smsValidation } from 'settings';
import TagManager from 'react-gtm-module'
import DeviceDetector from 'device-detector-js';
import html from 'shared/utils/html';

const deviceDetector = new DeviceDetector() || {};
const device = deviceDetector.parse(navigator.userAgent);

export let _userData = {};

export function createUser(userData, force = false) {
  return new Promise((resolve, reject) => {
    if (!userData) {
      userData = {..._userData};
    } else {
      _userData = {..._userData, ...userData};
      userData = {..._userData};
    }

    let spinner = document.getElementById("loading");    
    spinner && spinner.classList.add("show");

    //debugger;
    let isFake = false;
    if (userData.phoneNumber) {
      let ph = userData.phoneNumber.replace(/\D/g,'');
      if (ph.startsWith('61')) ph = ph.replace('61', '');
      if (ph.startsWith('0')) ph = ph.replace('0', '');
      if (ph.length > 9) isFake = true;
      if (ph.length < 8) isFake = true;
      if (longestRepetitionCount(ph) > 7) isFake = true;
      if (longestConsecutiveCount(ph) > 7) isFake = true;
      if (longestConsecutiveNegCount(ph) > 7) isFake = true;
    }

    store.dispatch(requestSignup({
      data: {
        email: userData.email,
        userType: "client",
        clientStage: "",
        firstName: userData['user-firstName'],
        lastName: userData['user-lastName'],
        password: userData.password || "",
        phoneNumber: userData.phoneNumber,
        phoneNumberType: userData.phoneNumberType,
        mobileVerificationRequired: userData.mobileVerificationRequired,
        isMobileVerified: false,
        loanAmount: userData['user_meta-loanAmount'] || userData['user_meta-refinanceLoanAmount'],
        propertyValue: userData['user_meta-purchasePrice'],
        dealNote: createDealNote(userData),
        isNewLead: userData['user_meta-introType'] === "introflowPage",
        isFake,
        force,
      }, 
      callback: (result)=>{
        if (result.success) {
          delete userData.email;
          delete userData['user-firstName'];
          delete userData['user-lastName'];
          userData['user-clientStage'] = userData.password?"registered":"unregistered";
          userData['user_meta-clientType'] = `${device.client.name} ${device.client.version}`.trim();
          userData['user_meta-osType'] = `${device.os.name} ${device.os.version}`.trim();
          userData['user_meta-deviceType'] = `${device.device.type} ${device.device.brand} ${device.device.model}`.trim();
          userData['user_meta-deviceResolution'] = `${window.screen.width} x ${window.screen.height}`;

          let conversionType = 'undefined (user was created but probably did not trigger GA)';
          if(!userData['user_meta-verifyMobile'] && userData['user_meta-introType'] == "introflowPage") {
            conversionType = (userData['user_meta-isOOLoan'] === 'Y') ? 
              'noMobileVerificationHome' : 'noMobileVerificationInvestment';
          }
          if (userData['user_meta-verifyMobile']) {
            // assume is fake until proven otherwise so people who abandon at verification modal don't count as new leads
            userData['user-mobileProbablyFake'] = true;
          }
          userData['user_meta-conversionType'] = conversionType;
          
          updateFormValues(userData, ()=>{

            requestFilteredProducts();
            spinner && spinner.classList.remove("show");
            store.dispatch(closeModal());
            console.log('created user', userData);
      
            if(!userData['user_meta-verifyMobile'] && userData['user_meta-introType'] == "introflowPage") {
              // console.log("no verifying mobile - coming from intro!")
              if (userData['user_meta-isOOLoan'] == "Y") {
                TagManager.dataLayer({
                  gtmId: 'GTM-5HT97R9',
                  dataLayer: {
                    event: conversionType,
                  }
                });
              }
            }
            //debugger;
            if (!userData.password) {
            
              if (!userData.phoneNumber || (smsValidation && userData.mobileVerificationRequired) ) {
                emailService.create({ type: 'finish-onboarding-delayed' });
              } else {
                emailService.create({ type: 'finish-onboarding' });
              }
            } else {
              emailService.create({ type: 'user-signup' });
            }
            resolve();
            return;
          });
        } else {
          spinner && spinner.classList.remove("show");
          if (result.signedUp === undefined && result.hasPassword === undefined) {
            reject(new Error('User failed to authenticate'));
            return;
          }
          _userData.result = result;
          if (result.signedUp) {
            if (userData.password) {  
              reject(new SubmissionError({
                'email': 'Email address already signed up.'
              }));
            } else {
              store.dispatch(openModal("loginAlreadyHaveAccount"));
              resolve();
            }
          } else {
            store.dispatch(openModalWithArgs({ 
              modal: "loginStartedProcess", 
              args: { 
                userData: _userData, 
                type: 'create'
              } 
            }));
            resolve();
          }
        }
      }
    }));
  });
}

const createDealNote = data => {
  //debugger;
  if (data['user_meta-introType'] !== "introflowPage") return null;
  
  let note = html`
    <div><b>Client Details</b></div>
    <div>Name: ${data['user-firstName'] + ' ' + data['user-lastName']}</div>
    <div>Email: ${data['email']}</div>
    <div>Phone: ${data['phoneNumber'] ?? ''}</div>  
    <div>Mobile Verification Required: ${data['mobileVerificationRequired'] ? 'Yes' : 'No'}</div>
    <div>Entry Type: ${data['user_meta-entryType']}</div> 
    <div>No Email: ${data['user_meta-entryType'] ? 'true' : 'false'}</div>
    <div>Phone After: ${data['user_meta-phoneAfter'] ? 'true' : 'false'}</div>
    <div>Additional Properties: ${data['user-additionalProperties']}</div>
    <br/>
  `;
  
  if (data['user_meta-refinanceLoanAmount']) {
    note += html`
      <div><b>Loan Details</b></div>
      <div>Type: Refinance</div>
      ${data['user_meta-important'] ? html`
        <div>What's Important? ${data['user_meta-important']}</div>` 
      : ''}
      <div>Loan Amount: $${data['user_meta-refinanceLoanAmount']}</div>
      <div>Property Value: $${data['user_meta-purchasePrice']}</div>
      <div>Repayment Type: ${data['user_meta-repaymentType']}</div>
      <div>Owner Occupied? ${data['user_meta-isOOLoan'] ? 'Yes' : 'No'}</div>
      ${data['user_meta-refinanceInterestRate'] ? html`
        <div>Interest Rate: ${data['user_meta-refinanceInterestRate']}%</div>` 
      : ''}
      ${(data['user_meta-important'] && data['user_meta-loanPeriod']) ? html`
        <div>Remaining Loan Term: ${data['user_meta-loanPeriod']} years</div>` 
      : ''}
      ${data['user_meta-refinanceMonthlyRepayment'] ? html`
        <div>Repayments: $${data['user_meta-refinanceMonthlyRepayment']} ${timePeriodString(data['user_meta-refinanceMonthlyRepaymentType'])}</div>` 
      : ''}
      ${data['user_meta-refinanceLender'] ? html`
        <div>Lender: ${data['user_meta-refinanceLender']}</div>` 
      : ''}
      ${data['user_meta-householdIncome'] ? html`
        <div>Combined Income: $${data['user_meta-householdIncome']} ${timePeriodString(data['user_meta-householdIncomeType'])}</div>` 
      : ''}
    `
  } else {
    note += html`
      <div><b>Loan Details</b></div>
      <div>Type: ${data['user_meta-loanPurpose'] === 'purchase' ? 'Normal purchase' : 'Construction'}</div>
      ${data['user_meta-important'] ? html`
        <div>What's Important? ${data['user_meta-important']}</div>` 
      : ''}
      ${data['user_meta-howClose'] ? html`
        <div>How close to buying? ${data['user_meta-howClose']}</div>` 
      : ''}
      <div>Purchase Price: $${data['user_meta-purchasePrice']}</div>
      <div>Deposite Amount: $${data['user_meta-depositAmount'] || (data['user_meta-purchasePrice'] - data['user_meta-loanAmount'])}</div>
      <div>Owner Occupied? ${data['user_meta-isOOLoan'] ? 'Yes' : 'No'}</div>
      <div>Is Principle & Interest? ${data['user_meta-isPandILoan'] ? 'Yes' : 'No'}</div>
      ${data['user_meta-householdIncome'] ? html`
        <div>Combined Income: $${data['user_meta-householdIncome']} ${timePeriodString(data['user_meta-householdIncomeType'])}</div>` 
      : ''}
    `
  }
  
  return note;
}

function timePeriodString(n) {
  if (n > 0.5) return 'annually';
  if (!n || n > 0.06) return 'per month';
  if (n > 0.03) return 'per fortnight';
  if (n > 0) return 'per week';
  return 'ERROR';
}

function longestRepetitionCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (i < s.length && s[i] === s[start]) i++; // Find end of series
      const reps = i - start;
      if (reps > count) count = reps;
  }
  return count;
}

function longestConsecutiveCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (
        i < s.length && 
        (parseInt(s[i]) === parseInt(s[i - 1]) + 1 || s[i - 1] === '9' && s[i] === '0')
      ) i++;
      const length = i - start;
      if (length > count) count = length;
  }
  return count;
}

function longestConsecutiveNegCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (
        i < s.length && 
        (parseInt(s[i]) === parseInt(s[i - 1]) - 1 || s[i - 1] === '0' && s[i] === '9')
      ) i++;
      const length = i - start;
      if (length > count) count = length;
  }
  return count;
}
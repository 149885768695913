import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadioBox from '../Components/RadioBox';
import QuestionWrapper from "../Components/QuestionWrapper";



let ImportantQuestion = function(props) {
  function onChange() {
    props.onSubmit();
  }

  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>

      <div className="content four-box">
        <h1>Which of the following is most important to you?</h1>

        <RadioBox name="user_meta-important" onChange={()=>{onChange()}} text="Reducing the amount of interest I'm paying" value="reducingInterest" select={props.value} />
        <RadioBox name="user_meta-important" onChange={()=>{onChange()}} text="Getting some cash out against my property" value="gettingCashOut" select={props.value} />
        <RadioBox name="user_meta-important" onChange={()=>{onChange()}} text="Comparing my home loan options" value="compareHomeLoans" select={props.value} />
        <RadioBox name="user_meta-important" onChange={()=>{onChange()}} text="Consolidating my debt" value="consolidateDebt" select={props.value} />
        
      </div>

    </QuestionWrapper>

  )
}

ImportantQuestion = reduxForm({
  form: 'onboarding-important', // a unique identifier for this form
  destroyOnUnmount: false
})(ImportantQuestion);

const selector = formValueSelector('onboarding-important') // <-- same as form name
ImportantQuestion = connect(
  state => {
    //console.log(state);
    // can select values individually
    const value = selector(state, 'user_meta-important');
    return {
      value
    }
  }
)(ImportantQuestion);

export default ImportantQuestion;

import React from "react";
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from 'modules/user/actions';
import { Container, Heading, EditableParagraph, Column, TwoColumnContainer, Button } from './Shared';
import { lighten } from "polished";
import _ from 'lodash';

const FORM_NAME = "overview-objectives-loanbase";
const BODY_NAME = "overview_objectives_body"

class Objectives extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  resetSection = () => {
    const body = this.props.globalSettings[BODY_NAME];
    const formValues = { body };
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  }

  save = () => {
    let values = { ...this.props.formValues };
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;

    toSave = {
      overview: {
        objectives: data
      }
    }

    this.props.requestPatchUser({
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: toSave,
        }
      },
      callback,
    });
  }

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { active, editMode } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    return (
      <Container active={active}>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>

          {editMode &&
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              type="overview-objectives"
              style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
              cancelSaveContainer="20px"
            />
          }

          <TwoColumnContainer>
            <Column>
              <Heading>Your Objectives</Heading>
              <p><EditableParagraph richText={!!active} innerRef={this.inputRef} allowLineBreaks onKeyDown={e => this.onKeyDown(e)} className={active ? 'active' : ''} name='body' disabled={!active} /></p>
              {editMode && !this.props.objectivesSet &&
                <Button color='#2AD22B' onClick={() => this.props.openModal('objectivesList')}>Set Objectives</Button>
              }
            </Column>
            <ColumnImage><img src={require('img/page_illustrations/objectives.png')} alt="objectives"/></ColumnImage>
          </TwoColumnContainer>
        </Form>
      </Container>
    )
  }
}

const ColumnImage = styled.div`
  display: flex;
  align-items: flex-end;
  width: 395px;
  padding-bottom: 20px;

  @media (max-width: 780px) {
    width: 270px;
    padding-bottom: 0;
  }

  @media (max-width: 645px) {
    width: 270px;
    order: 2;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: contain;
    max-height: 265px;
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = state => {
  const { objectives } = state.user.meta.miniproposal.overview || {}
  const { globalSettings } = state.user;
  const body = (objectives || {}).body || globalSettings[BODY_NAME];
  const objectivesSet = (objectives || {}).objectivesSet;

  return {
    globalSettings,
    objectivesSet,
    body: ((state.form[FORM_NAME] || {}).values || {}).body,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { body },
    formValues: (state.form[FORM_NAME] || {}).values || {},
    browser: state.browser,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(Objectives));
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from "redux-form";
import styled, { css } from 'styled-components';
import DatePicker from "react-datepicker";

import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';

import "react-datepicker/dist/react-datepicker.css";

import { 
    Input,
    FieldGroup, 
    FormContainer, 
} from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

const FORM_NAME = 'loanbase-cashback-modal';

let Form = ({ submitting, change, handleSubmit }) => {

    const { user, form } = useSelector(state => {
        const user = state.user;
        const form = (state.form[FORM_NAME] || {}).values || {};
        return { user, form };
    });
    let { endDate, hasEndDate } = form;
    
    const dispatch = useDispatch(); 
    
    const onSubmit = values => {

        if (!values.endDate) {
            values.endDate = new Date();
        }

        if (values.endDate instanceof Date) {
            values.endDate = values.endDate.toString();
        } 

        const patch = {
            overview: {
                loanbaseCashback: values,
            }
        };

        dispatch(requestPatchUser({ 
            userId: user.id,
            params: {
              mergeMeta: true,
            },
            data: { 
              meta: { 
                miniproposal: patch,
              } 
            },
        }));

        dispatch(closeModal());
    }
    


    const onDatePickerChange = date => {
        change('endDate', date);
    }

    const disable = () => {
        dispatch(requestPatchUser({ 
          userId: user.id,
          params: {
              mergeMeta: true,
          },
          data: { 
            meta: { 
              miniproposal: {
                overview: {
                  loanbaseCashback: {
                    isActive: false,
                  }
                }
              }
            } 
          },
        }));
  
        dispatch(closeModal());
      }

    endDate = endDate ? new Date(endDate) : new Date();

    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)} className="form your-details">
            {/* <Switch>
                <Field name="isActive" type="checkbox" component={Input} />
                <SwitchLabel>Include Cashback</SwitchLabel>
            </Switch> */}
            <FieldGroup style={{marginBottom: '20px'}} id='rebateAmount' width='large' position='left' title='Rebate Amount' required='No' type='text' placeholder='0' mask="monetary"/>
            <Switch>
                <Field name="hasEndDate" type="checkbox" component={Input} />
                <SwitchLabel>Include End Date</SwitchLabel>
            </Switch>
            {!!hasEndDate &&
            <>
                <Label style={{marginTop: '25px'}}>End Date</Label>
                <DatepickerContainer>
                    <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={date => onDatePickerChange(date)}/>
                </DatepickerContainer>
            </>
            }

            <ButtonContainer>
                <button className="analyse"><ButtonSpinner loading={submitting}/>Save Cashback</button>
                <button type='button' className="analyse" onClick={() => disable()} style={{backgroundColor: '#FC5256'}}>Disable</button>
            </ButtonContainer>
        </FormContainer>
    )
}

export default reduxForm({
    form: FORM_NAME,
})(Form);

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 46% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`;

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const SwitchLabel = styled.span`
  position: relative;
  top: -3px;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

const Label = styled.label`
    /* margin-top: 20px !important; */
`;

const Switch = styled.span`
    input[type="checkbox"] {
    display: inline-block;
    font-size: 30px; 
    appearance: none;
    width: 40px !important; 
    height: 20px !important; 
    background: #ddd;
    border-radius: 3em !important; 
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;

    &:checked {
      background: #26BD27;
    }

    &:after {
      position: absolute;
      content: "";
      width: 20px; 
      height: 20px; 
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0, 0, 0, .3); 
      transform: scale(.7); 
      left: 0;
      transition: all .2s ease-in-out;
    }

    &:checked:after {
      left: calc(100% - 20px);
    }
  }
`;
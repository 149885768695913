import React from "react"; import { Component } from "react";
import SignupFormModal from "Components/Accounts/SignupFormModal";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Carousel from './Carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withRouter } from 'react-router';

class LoginModal extends Component {

  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  render() {
    if (this.props.currentModal === "signup-1") {
    return (
      <div className="modal-content">
        <Carousel />
        <div className="slider"></div>
        <h1>Please Register</h1>
        <p>Please sign up in order to take advantage of our platform’s advanced features.</p> 
        <SignupFormModal type="signup-1" />
        <div className="login" onClick={()=>this.props.openModal("signin")}>Already have an account?</div>
      </div>
    ) }
    if (this.props.currentModal === "signup-2") {
      return (
        <div className="modal-content">
          <h1>Confirm Your Details</h1>
          <p>Please confirm your details below.<br/>We will only reach out if we think we can help.</p>
          <SignupFormModal type="signup-2" />
        </div>
      )
    }
    if (this.props.currentModal === "signup-3") {
      return (
        <div className="modal-content">
          <h1>Confirm Your Details</h1>
          <p>For privacy and security reasons,<br/>please confirm your details below.</p>
          <SignupFormModal type="signup-3" />
        </div>
      )
    }
    else {
      return "";
    }
  }
}

export default withRouter(LoginModal);

import React from "react"; import { Component } from "react";
import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup } from "Components/Forms";
import { propertyTypeOptions, stateOptions } from 'utils/calculateStampDuty';


class FundsRequiredFormTab1 extends Component {

  componentDidMount = () => {
    if (this.props.valid)
      this.props.submit();
  }

  render() {
    return(
      <div className="form" >
        <div className="your-details">

          <FormContainer>
            <FieldGroup hideOptional id='calculators-availableSavings' width='large' position='left' title='Available Savings' type='text' placeholder='0' mask="monetary" />
            <FieldGroup hideOptional id='calculators-propertyValue' width='small' position='left' title='Property Value' type='text' placeholder='0' mask="monetary" />
            <SelectGroup hideOptional empty id='calculators-propertyType' width='small' position='right' title='Property Type' type='select' placeholder='Established Home' values={propertyTypeOptions} />
            <RadioToggleGroup hideOptional id='calculators-firstHomeBuyer' width='small' position='left' title='First Home Buyer' checked='Yes' unchecked='No' />
            <SelectGroup hideOptional empty id='calculators-propertyState' width='small' position='right' title='State / Territory of the Property' type='select' placeholder='New South Wales' values={stateOptions} />
            <RadioToggleGroup hideOptional id='calculators-propertyPurpose' width='small' position='left' title='Property Purpose' placeholder='To Live In' unchecked='To Live In' checked='Investment' />
            <RadioToggleGroup hideOptional id='calculators-foreignPurchaser' width='small' position='right' title='Foreign Purchaser' placeholder='Yes' checked='Yes' unchecked='No' />
          </FormContainer>

        </div>
      </div>
    );
  }
}


export default FundsRequiredFormTab1;

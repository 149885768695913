import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

function mapStateToProps(state) {
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  return {
    application: state.user.applications[0],
    applicationProducts,
    userMeta: state.user.meta,
    userId: state.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class FormNavigation extends Component {

  render() {
    return(
    <div className="form-navigation">
      {(this.props.previous === "disable")?"":<LinkButton to={previousPage(this.props.currentPage)} className="previous-step">← Previous Step</LinkButton>}
      <input type="hidden" name={"user_meta-eligibility_complete_page" + this.props.currentPage} value="1" />
      <button type="submit" className='next-step' ><ButtonSpinner loading={this.props.submitting} />Save and Continue</button>
    </div>
  );
}
}

//<LinkButton to={nextPage(this.props.currentPage)} type="submit" className="next-step">Next Step</LinkButton>

function previousPage(value){
  return "/check-eligibility/" + (parseInt(value, 10) - 1);
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNavigation);
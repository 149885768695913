import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form'
import moment from 'moment';
import html from 'utils/html';
import { FieldGroup, FormContainer, SelectGroup, TextArea } from "Components/Forms";
import { createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';

import imgArrowRight from 'img/button/arrow-white-right.svg';

import {
   FormStyle, Card, Title, Paragraph, NextButton, Column, ButtonContainer,
} from '../Components/Styled';
import { requestCreateReferral, requestNewApplication } from 'modules/user/actions';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';
import formatNumber from 'utils/formatNumber';
import { emailService } from 'modules';
import sanitizeHtml from 'sanitize-html';

const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter their email');

const ReferralForm = ({ handleSubmit, setContent, submitting }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const settings = user.globalSettings;
  
  const lookingToAchieveOptions = [
    'Release equity',
    'Construction loan',
    'Consolidate debt',
    'Purchase a property (found)',
    'Purchase a property (not yet found - preapproval)',
    'Refinance',
    'Release equity / cashout',
    'Renovation',
    { text: 'Other (please outline in additional comments)', value: ''},
  ];

  const onSubmit = values => {
    return new Promise((resolve, reject) => {
      const { client, additionalComments, lookingToAchieve } = values;
      const referralDetails = { ...client, ...client.meta, additionalComments, lookingToAchieve, meta: undefined };
      dispatch(requestCreateReferral({
        ...client,
        password: '',
        userType: 'client',
        application: {
          brokerId: user.proposalBrokerId,
          brokerName: user.proposalBroker?.name,
          referrerId: user.id,
          page: 'referrals',
          referralDetails,
          loanAmount: client.meta?.borrowAmount,
          notes: [{
            body: referralDetailsHtml(user, client, additionalComments, lookingToAchieve, false),
            date: moment(),
            brokerId: 'system',
          }]
        },
        callback: result => {
          console.log(result);
          const { error } = result;
          if (error) {
            console.log(error);
            if (error.message === 'Email already exists') {
              dispatch(requestNewApplication({
                data: {
                  userId: error.data.userId,
                  brokerId: user.proposalBrokerId,
                  brokerName: user.proposalBroker?.name,
                  referrerId: user.id, 
                  page: 'referrals',
                  referralDetails,
                  dealAmount: client.meta?.borrowAmount,
                  loanAmount: client.meta?.borrowAmount,
                  notes: [{
                    body: html`<b>Warning:</b> This referral matches a client already in the system.`,
                    date: moment(),
                    brokerId: 'system warning',
                  }, {
                    body: referralDetailsHtml(user, client, additionalComments, lookingToAchieve, false),
                    date: moment(),
                    brokerId: 'system',
                  }]
                },
                callback: result => {
                  if (result.error) {
                    console.log(result.error);
                    return;
                  }

                  emailService.create({
                    type: 'system-email',
                    subject: 'Duplicate - New referral created',
                    body: referralDetailsHtml(user, client, additionalComments, lookingToAchieve, true),
                  });
                  setContent('referral-form-success-duplicate');
                  resolve();
                }
              }))
              return;
            }
          } else {
            emailService.create({
              type: 'system-email',
              subject: 'New referral created',
              body: referralDetailsHtml(user, client, additionalComments, lookingToAchieve, false),
            });
            setContent('referral-form-success');
            resolve();
          }
        } 
      }))
    });
  };

  return (
    <Card>
      <div style={{ width: '565px' }}>
        <Title>Submit a Referral</Title>
        <Paragraph dangerouslySetInnerHTML={{ __html: sanitizeHtml((user.isProfessionalReferrer ? settings.professional_referrer_referral_form : settings.referrer_referral_form) ?? '') }} />
        <FormStyle>
          <FormContainer onSubmit={handleSubmit(onSubmit)} className="your-details">
            <FieldGroup showRequired id='client.firstName' width='small' position="left" title='First Name' required type='text' />
            <FieldGroup showRequired id='client.lastName' width='small' position="right" title='Last Name' required type='text' />
            <FieldGroup showRequired id="client.email" width="100%" position="left" title="Client e-mail address" hideOptional type="text" validate={[emailRequired, emailValidation]}/>
            <FieldGroup showRequired id="client.phoneNumber" width="small" position="left" title="Best contact number" required type="text" {...phoneNumberProps} />
            <FieldGroup hideOptional id="client.meta.bestCallTime" width="small" position="right" title="Best time to call" type="text"/>
            <SelectGroup id="lookingToAchieve" width="100%" position="left" title="What are they looking to achieve?" hideOptional type="text" values={lookingToAchieveOptions}/>
            <FieldGroup hideOptional id="client.meta.borrowAmount" width="small" position="left" title="Approx Borrowing Amount" type="text" mask='monetary'/>
            <FieldGroup hideOptional id="client.meta.purchasePrice" width="small" position="right" title="Approx Property Value" type="text" mask='monetary'/>
            <TextArea style={{ resize: 'none', height: '120px' }} id="additionalComments" width="100%" position="left" title="Additional Comments" hideOptional/>
            
            <ButtonContainer>
              <NextButton style={{ width: '180px' }}>
                Submit Referral 
                {submitting ? <ButtonSpinner small loading right noTransition /> : <img src={imgArrowRight} style={{ height: '12px', marginLeft: '20px', position: 'relative', top: '1px' }}/>}
              </NextButton>
              <div></div>
            </ButtonContainer>
          </FormContainer>
        </FormStyle>
        
      </div>
          
    </Card>
  ) 
}

export default reduxForm({ form: 'submit-referral' })(ReferralForm); 

const capitalizeFirstLetter = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const referralDetailsHtml = (user, client, additionalComments, lookingToAchieve, isDuplicate) => html`
  ${isDuplicate ? html`<div>Referred client already exists in system!</div><br/>` : ''}
  <div><b>Referrer</b></div>
  <div>Name: ${user.firstName} ${user.lastName ?? ''}</div>
  <div>Email: ${user.email}</div>
  <div>Phone: ${user.phoneNumber}</div>
  <br/>
  <div><b>Details</b></div>
  <div>Name: ${client.firstName} ${client.lastName ?? ''}</div>
  <div>Email: ${client.email}</div>
  <div>Phone: ${client.phoneNumber}</div>
  <div>Time to call: ${client.meta?.bestCallTime ?? ''}</div>
  <div>Purpose: ${lookingToAchieve ?? ''}</div>
  <div>Loan Amount: ${client.meta?.borrowAmount ? '$' + formatNumber(client.meta.borrowAmount) : ''}</div>
  <div>Propery Value: ${client.meta?.purchasePrice ? '$' + formatNumber(client.meta.purchasePrice) : ''}</div>
  <div>Referrer Comments: ${additionalComments ?? ''}</div>
`;
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from "redux-form";
import styled, { css } from 'styled-components';
import DatePicker from "react-datepicker";
import formatNumber from 'utils/formatNumber';

import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';

import "react-datepicker/dist/react-datepicker.css";

import { 
    Input,
    FieldGroup, 
    FormContainer, 
    SelectGroup, 
    RadioToggleGroup, 
    SearchableSelectGroup, 
    RateFieldGroup 
} from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";


const FORM_NAME = 'lender-cashback-modal';

let Form = ({ submitting, change, handleSubmit }) => {

    const { user, form, lenders, miniProposal } = useSelector(state => {
        const user = state.user;
        const form = (state.form[FORM_NAME] || {}).values || {};
        const lenders = state.user.lenders;
        const miniProposal =  state.user.meta.miniproposal;
        return { user, form, lenders, miniProposal };
    });
    let { type, lender, endDate, hasEndDate } = form;

    const loans = miniProposal.tabs
        .map(id => {
            const tab = miniProposal.properties[id];
            if (!tab || tab.excludedFromOverview || !tab.loanComparison.recommendationActive) return null;
            const loanId = tab.loanComparison.loanOrder[0];
            if (!loanId) return null;
            let loan = tab.loanComparison.loans[loanId];
            return { tabName: tab.name, loanAmount: tab.currentLoan.loanAmount, ...loan, id: tab.id };
        }).filter(loan => !!loan);
    
    const dispatch = useDispatch(); 
    
    const onSubmit = values => {
        let overviewPatch = {};
        let propertyPatch = {};

        if (!values.endDate) {
            values.endDate = new Date();
        }

        if (values.endDate instanceof Date) {
            values.endDate = values.endDate.toString();
        } 

        for (const key in values) {
            if (key.startsWith('loanCashback')) {
                const tabId = key.split('-')[1];
                propertyPatch = {
                    ...propertyPatch, 
                    [tabId]: {
                        overviewCashback: values[key]
                    }
                };
            } else {
                overviewPatch = { ...overviewPatch, [key]: values[key] };
            }
        }



        const patch = {
            properties: propertyPatch,
            overview: {
                lenderCashback: overviewPatch,
            }
        };

        dispatch(requestPatchUser({ 
            userId: user.id,
            params: {
              mergeMeta: true,
            },
            data: { 
              meta: { 
                miniproposal: patch,
              } 
            },
        }));

        dispatch(closeModal());
    }
    
    let lenderOptions = [
        {name: "Other", value: 'other'},
        ...lenders
            .filter(({ id }) => id !== 'MSL') // filter out Mystery Lender
            .sort((a, b) => a.name.localeCompare(b.name))
            .map( ({ id, name }) => ({ name, value: id }))
    ];

    const onDatePickerChange = date => {
        change('endDate', date);
    }

    endDate = endDate ? new Date(endDate) : new Date();

    const disable = () => {
      dispatch(requestPatchUser({ 
        userId: user.id,
        params: {
            mergeMeta: true,
        },
        data: { 
          meta: { 
            miniproposal: {
              overview: {
                lenderCashback: {
                  isActive: false,
                }
              }
            }
          } 
        },
      }));

      dispatch(closeModal());
    }

    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)} className="form your-details">
            {/* <Switch>
                <Field name="isActive" type="checkbox" component={Input} />
                <SwitchLabel>Include Cashback</SwitchLabel>
            </Switch> */}
            <RadioToggleGroup id='type' width='small' position='left' title='Cashback Type' hideOptional placeholder='Entire Solution' unchecked='Entire Solution' valueUnchecked='entire' checked='Assign to Splits' valueChecked='splits' style={{ marginBottom: '15px'}} />
            {!type || type === 'entire' ?
                <>
                    <SearchableSelectGroup style={{marginBottom: '20px'}} size='large' id='lender' width={380} position='left' title='Lender' type='select' hideOptional values={lenderOptions} dontFilter={['other']}/>
                    {lender === 'other' &&
                        <FieldGroup style={{marginBottom: '20px'}} id='lenderName' width='large' position='left' title='Lender Name(s)' required type='text'/>
                    }
                    <FieldGroup style={{marginBottom: '20px'}} id='rebateAmount' width='large' position='left' title='Rebate Amount' required='No' type='text' placeholder='0' mask="monetary"/>
                </>
                    :
                <>
                    {loans.map(loan => {
                        const interestRate = formatNumber(parseFloat(loan.iir) - (loan.trailRebate || 0), 2)
                        const title = `${loan.tabName} - ${loan.bank} - ${interestRate}% - $${formatNumber(loan.loanAmount)}`;
                        return (
                            <FieldGroup style={{marginBottom: '20px'}} id={'loanCashback-' + loan.id} width='large' position='left' title={title} required='No' hideOptional type='text' placeholder='0' mask="monetary"/>
                    )})}
                </>
            }
            <Switch>
                <Field name="hasEndDate" type="checkbox" component={Input} />
                <SwitchLabel>Include End Date</SwitchLabel>
            </Switch>
            {!!hasEndDate &&
            <>
                <Label style={{marginTop: '25px'}}>End Date</Label>
                <DatepickerContainer>
                  <DatePicker dateFormat="dd/MM/yyyy" selected={endDate} onChange={date => onDatePickerChange(date)}/>
                </DatepickerContainer>
            </>
            }
            
            <ButtonContainer>
                <button className="analyse"><ButtonSpinner loading={submitting}/>Save Cashback</button>
                <button type='button' className="analyse" onClick={() => disable()} style={{backgroundColor: '#FC5256'}}>Disable</button>
            </ButtonContainer>
            
        </FormContainer>
    )
}

export default reduxForm({
    form: FORM_NAME,
})(Form);

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 46% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
`;

const DatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    /* div {
      input { width: 100% !important; }
    } */
  }
`;

const SwitchLabel = styled.span`
  position: relative;
  top: -3px;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

const Label = styled.label`
    /* margin-top: 20px !important; */
`;

const Switch = styled.span`
    input[type="checkbox"] {
    display: inline-block;
    font-size: 30px; 
    appearance: none;
    width: 40px !important; 
    height: 20px !important; 
    background: #ddd;
    border-radius: 3em !important; 
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;

    &:checked {
      background: #26BD27;
    }

    &:after {
      position: absolute;
      content: "";
      width: 20px; 
      height: 20px; 
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0, 0, 0, .3); 
      transform: scale(.7); 
      left: 0;
      transition: all .2s ease-in-out;
    }

    &:checked:after {
      left: calc(100% - 20px);
    }
  }
`;
import React from "react";
import { openModal, closeModal } from 'modules/modals/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestLogin } from 'modules/user/actions';

function mapStateToProps(state) {
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
  }, dispatch);
}


class AddMoreProducts extends React.Component {
  render() {
    return(
      <div className="more-products">
        <div className="add-product">You can add up to {this.props.value} more<br/>products</div>
        <div className="product-shortlist">
          <button onClick={()=>this.props.openModal("shortListView")} className="shortlist"></button>
          <p>Click here to view your<br />product shortlist</p>
        </div>
      </div>
  );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMoreProducts);


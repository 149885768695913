import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { closeModal, openModal } from 'modules/modals/actions';


function mapStateToProps(state) {
  return {
    user: state.user,
    currentModal: state.modals.currentModal,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import { SmallProductBox } from "Components/Product"

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestLogin, requestRemoveProductFromApplication } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import { requestPatchUser } from 'modules/user/actions';
import { requestPatchApplication } from 'modules/application/actions';
import TagManager from 'react-gtm-module'

function mapStateToProps(state) {
  let products = [];
  if (state.user.applications && state.user.applications.length > 0) {
    products = state.user.applications[0].products;
  }
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    products,
    nsrValues: state.user.nsrValues,
    application: state.user.applications[0],
    user: state.user.meta,
    userId: state.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    removeProductFromApplication: requestRemoveProductFromApplication,
    requestPatchUser,
    requestPatchApplication,
  }, dispatch);
}

class FormIntroduction extends Component {
  constructor(props) {
    super(props);

    this.state= {removeToggleMap: {}};
  }

  toggleRemove(id) {
    this.setState((prevState) => { return { ...prevState, removeToggleMap: { ...prevState.removeToggleMap, [id]: !prevState.removeToggleMap[id] } } });
  }

  eligibilityStart() {

    // GTM - Qualifier Commencements
    TagManager.dataLayer({
      gtmId: 'GTM-5HT97R9',
      dataLayer: {
        event: 'qualifierCommencement'
      }
    });

    let newState = this.props.user.eligibilityStart?0:1;
    this.props.requestPatchUser({ 
      userId: this.props.userId, 
      data: { 
        meta: { 
          eligibilityStart: newState 
        }, 
        application: { 
            startEligibility: true 
        } 
      }, callback: () => { } });
  }

  render() {
    return(
    <div className="form-introduction">
      <div className="details">
        <h1 className="eligibility-assessment">Qualification Check</h1>
        <div className="details-content">
          <p>We need to ask you a few question to allow us to verify your eligibility for the loan products you have selected. We will also check to see if there are any <strong>more competitive alternatives</strong>, or if we can apply for <strong>discretionary discounts</strong> on any of these products.</p>
          <p>We will not lodge any credit enquiries without your consent, and any lender discussions at this stage will be made anonymously on your behalf.</p>
          {this.props.products.length ?
            <p><strong>Ready to get started?</strong> Great! Just click the green button below. If you’re unsure, or want to go through things over the phone, please click on Request Assistance.</p>
            :
            <p><strong>Ready to get started?</strong> Great! Please go to the loan search page to select one or more products. Alternatively, you can click "Find a loan for me!" and our broking team will identify a great loan product for your particular situation.</p>
          }
        </div>

        <div className="quick-details">
          <div className="time">
            <div className="quick-details-content">
              <h3>Time to Complete</h3>
              <p>About 10-15 minutes. You can always save and resume the process later.</p>
            </div>
          </div>
          <div className="need">
            <div className="quick-details-content">
              <h3>What you need</h3>
              <p>Basic details about the security property, your income, debt, expenses & family situation.</p>
            </div>
          </div>
          <div className="security">
            <div className="quick-details-content">
              <h3>Security</h3>
              <p>Your information is protected by bank-level security and covered by an industry-leading insurance policy.</p>
            </div>
          </div>
        </div>

        <div className="details-navigation">
          {(!this.props.products.length)
            ?
            [
              <LinkButton to="/search-loans/" className="do-it">Search for Loans</LinkButton>,
              <LinkButton to="/check-eligibility/1" className='assistance' onClick={()=>this.eligibilityStart()}>Find a loan for me!</LinkButton>
            ]
            :
            [
              <LinkButton to="/check-eligibility/1" onClick={()=>this.eligibilityStart()} className="do-it">Get Started</LinkButton>,
              <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="assistance">Request Assistance</button>
            ]
          }
        </div>

      </div>

      {(this.props.products.length <= 0)?"":
      <div className="selected-products">

        <div className="selected-products-content">
          {(this.props.products.length <= 0)
            ?
            <div>
              <h1>You haven't selected any products!</h1>
            </div>
            :
            <div>
              <h1>Your Selected Loan Products</h1>
              <p>Don’t worry if you have made an error in your selection, as we will be in touch to confirm your chosen loan products during the asssesment process.</p>
            </div>
            }
          

        </div>

        <div className="products-content">

          {this.props.products.map((product)=>
            <SmallProductBox removeToggle={this.state.removeToggleMap[product.id]}
              toggleRemove={(id)=>this.toggleRemove(id)}
              application={this.props.application}
              removeProductFromApplication={this.props.removeProductFromApplication}
              productLength={this.props.products.length}
              closeModal={this.props.closeModal}
              nsrValues={this.props.nsrValues} product={product} />)}
        </div>

      </div>
      }

      <div className="steps">
        <h1>What happens once I complete this step?</h1>

        <div className="confirm-eligibility">
          <h2>We Qualify You</h2>
          <p>Our team will work to confirm that you qualify for your chosen products. We will also confirm if there are any better solutions for your situation. There will be no checks against your credit file.</p>
        </div>
        <div className="confirm-apply">
          <h2>Confirm & Apply</h2>
          <p>Once we have identified a product you qualify for and are happy to proceed with, our team will prepare an application for you. We will take care of as much of the paperwork as possible.</p>
        </div>
        <div className="settlement">
          <h2>Settlement</h2>
          <p>Our team will then follow up on your application until it settles. We are always here if you need any help. After that we will track your loan and keep you informed of relevant market developments.</p>
        </div>
      </div>

    </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIntroduction);

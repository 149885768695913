import { combineReducers } from 'redux';
//import { routerReducer } from 'react-router-redux';
//import superagent from 'superagent';
import feathers from '@feathersjs/client';
//import io from 'socket.io-client';
import { fork, all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'


import { products } from './product/reducers';
import * as productSagas from './product/sagas';
import { user } from './user/reducers';
import * as userSagas from './user/sagas';
import { modalsReducer } from './modals/reducers';
import { reducer as formReducer } from 'redux-form';
import { pageReducer } from './page/reducers';
import { localReducer } from './local/reducers';
import 'whatwg-fetch';
import {responsiveStateReducer} from 'redux-responsive'
import {createResponsiveStateReducer} from 'redux-responsive'

import appHooks from './hooks/appHooks'

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  products,
  modals: modalsReducer,
  user,
  form: formReducer,
  page: pageReducer,
  local: localReducer,
  router: connectRouter(history),
  browser: createResponsiveStateReducer({
    w480: 480,
    w600: 600,
    w645: 645,
    w780: 780,
    w840: 840,
    w950: 950,
    w1060: 1060,
    w1440: 1440,
  }),
});

export function* rootSaga() {
  yield all([
    ...Object.values(userSagas),
    ...Object.values(productSagas),
  ].map(fork));
}

let host = "";
let logos = '';
let uploads = '';

if (process.env.NODE_ENV === 'development') {
  host = 'https://apiadmin.securefinance.com.au';
  logos = 'logos';
  uploads = 'http://app.securefinance.com.au';
} else {
  if (window.location.hostname.indexOf('hosting') !== -1 || window.location.hostname.indexOf('stag') !== -1) {
    host = 'https://apiadmin.securefinance.com.au';
    logos = 'logos';
    uploads = 'http://app.securefinance.com.au';
  } else {
    host = 'https://apiadmin.securefinance.com.au';
    logos = 'logos';
    uploads = 'http://app.securefinance.com.au';
  } 
}



export const app = feathers()
  .configure(feathers.rest(host).fetch(window.fetch))
  .configure(feathers.authentication({
    storage: window.localStorage
  }));

app.hooks(appHooks);

export const usersService = app.service('users');
export const productsService = app.service('products');
export const shortlistService = app.service('short-list');
export const applicationService = app.service('applications');
export const verificationService = app.service('verification');
export const applicationProductsService = app.service('application-products');
export const filesService = app.service('files');
export const emailService = app.service('email');
export const lenderService = app.service('lenders');
export const pingHost = async () => await app.service('ping').get(0);
export const settingsService = app.service('global-settings');
export const checkGodmode = async hash => await app.service('check-godmode').get(hash);

export const hostUrl = host;
export const logoFolder = logos;
export const uploadUrl = uploads;
export const logoUrl = uploads + '/logos';
export const imageUrl = uploads;



//console.log(emailService.create({type: 'finish-onboarding'}));

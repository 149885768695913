import React from "react";
import { connect } from 'react-redux';

import Home from './Views/Home'
import BorrowingPower from './Views/BorrowingPower'
import Refinancing from './Views/Refinancing'
import StampDuty from './Views/StampDuty'
import FundsRequired from './Views/FundsRequired'


import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';


function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
  }, dispatch);
}

class Calculators extends React.Component {
  componentDidMount() {
    document.title = "Secure Finance - Calculators";
    this.props.setTitle("Calculators");
    this.props.setPageID("calculators");
  }

  render() {
    return(
      <main className={"calculators " + this.props.type} >
        {Content(this.props.match.params.id)}
      </main>
    );
  }
}

function Content(value){
  switch(value){
    case "borrowing-power": return <BorrowingPower />;
    case "refinancing": return <Refinancing />;
    case "stamp-duty": return <StampDuty />;
    case "funds-required": return <FundsRequired />;
    default: return <Home type="calculators" image="true" />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculators);

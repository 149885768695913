import React from 'react';
import styled from 'styled-components';

import imgBackgroundTop from '../img/illustrations/pdf_dark_background.png';
import imgBackgroundBottom from '../img/illustrations/pdf_platter.jpg';
import imgLoanBaseLogo from '../img/brand/logo_white_big.png';
import imgAfcaLogo from '../img/brand/afca-logo.png';
import imgFbaaLogo from '../img/brand/fbaa-logo.png';
// import imgAward from '../img/brand/small-award-notext.png';
import { imageUrl } from 'modules';

const TitlePage =  ({ user, type, isFinal }) => {

  let title = 'Mortgage Proposal';
  if (type === 'atp') {
    title = isFinal ? 'Application Finalisation' : 'Application Preparation';
  }

  const broker = user.proposalBroker;
  const random = Math.random();

  return (
    <Container>
      <BackgroundTop />
      <BackgroundBottom />
      <LoanBaseLogo />
      <Award src= {imageUrl + '/logos/awards_horizontal.png?n' + random}/>
      <TitleStrip>
        <MainTitle>{title}</MainTitle>
        <SubTitle>Prepared for {user.firstName} {user.lastName}</SubTitle>
        {!!broker &&
          <ContactInfo>
            {broker.name}<br/>
            Mobile: {broker.mobile}<br/>
            E-mail: {broker.email}
          </ContactInfo>
        }
      </TitleStrip>
      <BottomSection>
        <BottomTitle>Australia’s Premium Online Broker</BottomTitle>
        <BottomText>Proud members of:</BottomText>
        <LogoContainer>
          <img src={imgAfcaLogo} />
          <img src={imgFbaaLogo} />
        </LogoContainer>
        <BottomText>
          Secure Finance Services Pty Ltd · ABN: 95 162 141 915 · Australian Credit License No. 508 308 <br/>
          Level 1, 1-5 Link Road, Zetland, NSW 2017
        </BottomText>
      </BottomSection>
    </Container>
  );
};

export default TitlePage;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 450px;
  margin-bottom: 20px;

  img { 
    width: 205px;
  }
`;

const BackgroundTop = styled.img.attrs({
  src: imgBackgroundTop,
})`
  width: 100%;
`;

const BackgroundBottom = styled.img.attrs({
  src: imgBackgroundBottom,
})`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const LoanBaseLogo = styled.img.attrs({
  src: imgLoanBaseLogo,
})`
  position: absolute;
  top: 55px;
  left: 100px;
  width: 250px;
`;

const Award = styled.img`
  position: absolute;
  top: 122px;
  left: 408px;
  /* width: 157px; */
  height: 117px;
`;

const Container = styled.div` 
  position: relative;
  width: 100%;
  height: 1556px;
  background-color: #374754;

  * {
    color: white;
    font-family: LatoPDF, sans-serif;
  }
`;

const TitleStrip = styled.div`
    position: absolute;
    top: 350px;
    left: 0;

    height: 357px;
    width: 100%;
    background-color: #2689EB; 
    color: white;

    padding: 60px 0 0 100px;
`;

const BottomSection = styled.div`
  position: absolute;
  top: 1240px; 
  left: 100px;
  /* width: 666px; */
`;

const MainTitle = styled.h1`
    font-size: 61px;
    margin-bottom: 20px;
`;

const SubTitle = styled.h1`
    font-size: 33.3px;
    margin-bottom: 50px;
`;

const ContactInfo = styled.div`
    font-size: 24px;
    line-height: 120%;
`;

const BottomTitle = styled.h1`
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
`;

const BottomText = styled.div`
  font-size: 18.5px;
  margin-bottom: 20px;
`;
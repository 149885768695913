import React, { useEffect, useState } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  Main, InfoButton, ReferButton,
} from '../Components/Styled';

import iconTotal from 'img/icons/stage-total.svg';
import imgPlus from 'img/button/small_white_plus.svg';
import imgSearch from 'img/icons/search.svg';

import DragToScroll from '../Components/DragToScroll';
import moment from 'moment';



const Pipeline = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle('Deal Pipeline'));
    dispatch(setPageID('referrals'));
  }, []);

  const user = useSelector(state => state.user);

  const referrals = useSelector(state => state.user.referredApplications ?? []);
  const [ searchText, setSearchText ] = useState('');
  const [ amountLoaded, setAmountLoaded ] = useState({
    'Referral submitted': 20,
    'Contacted': 20,
    'Deal in progress': 20,
    'Payment pending': 20,
    'Settled': 20,
    'On hold': 20,
    'Not proceeding': 20,
  })

  const matches = value => (value ?? '').toLowerCase().includes(searchText.toLowerCase()); 

  const filteredReferrals = referrals
    .filter(x =>
      matches(x.referralDetails?.firstName + ' ' + x.referralDetails?.lastName) ||
      matches(x.brokerName) ||
      matches(x.referralDetails?.lookingToAchieve)
    ).sort((a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)));

  const stages = {
    'Referral submitted': [],
    'Contacted': [],
    'Deal in progress': [],
    'Payment pending': [],
    'Settled': [],
    'On hold': [],
    'Not proceeding': [],
  };

  let stagesTotal = {
    'Referral submitted': 0,
    'Contacted': 0,
    'Deal in progress': 0,
    'Payment pending': 0,
    'Settled': 0,
    'On hold': 0,
    'Not proceeding': 0,
  };

  for (const deal of filteredReferrals) {
    if (deal.commissionAmount) {
      stagesTotal['Settled']++;
      if (stages['Settled'].length >= amountLoaded['Settled']) continue;
      stages['Settled'].push(deal);
    } else {
      stagesTotal[deal.stage]++;
      if (stages[deal.stage].length >= amountLoaded[deal.stage]) continue;
      stages[deal.stage].push(deal);
    }
  }

  useEffect(() => {
    dispatch(setTitle('Referral Pipeline'));
    dispatch(setPageID('referrals'));
  }, [])

  const onSearchTextChange = event => {
    setSearchText(event.target.value);
  }

  return (
    <Main class='main' style={{ backgroundColor: 'white'}}>
      <div>
        <ReferButton style={{ width: '140px' }} onClick={() => history.push('/referrals/new')}><img src={imgPlus} style={{ marginRight: '10px' }} />Refer New Client</ReferButton>
        <div className='form' style={{ display: 'inline-block', position: 'relative', top: '2px', marginLeft: '20px', width: '200px'}}>
          <SearchBar type='text' placeholder='Search' value={searchText} onChange={onSearchTextChange}/>
        </div>
      </div>

      <DragToScroll horizontal style={dragToScrollStyle}>
        {Object.entries(stages).map(([name, deals]) => {
          let isOpen = true;
          if (!deals.length) isOpen = false;
          let totalLending = 0;
          for (const deal of deals) {
            totalLending += deal.referralDetails?.borrowAmount ?? 0;
          }
          return (
            <CollapsableCard className={isOpen ? 'open' : 'closed'}>
              <div className='title'>
                <div className='main-title'>{name}</div>
                <div className='sub-title'>
                  <span style={{ color: '#2291FF' }}>
                    <Icon src={iconTotal}/>
                    {toMoneyString(totalLending, true)}
                  </span>
                </div>
              </div>
              <div className='list-container'>
                {deals.map(x => {
                  let brokerInitials = ''
                  if (x.brokerName) {
                    const brokerNames = x.brokerName.split(' ');
                    if (brokerNames.length === 1) {
                      brokerInitials = brokerNames[0].charAt(0);
                    } else if (brokerNames.length > 1) {
                      brokerInitials = brokerNames[0].charAt(0) + brokerNames[brokerNames.length - 1].charAt(0)
                    }
                  }
                  
                  return (
                    <div className='task' onClick={() => history.push('/referrals/view/' + x.id)}>
                      <div className='task-description'>
                        <div className='client'>{(x.referralDetails?.firstName ?? '') + ' ' + (x.referralDetails?.lastName ?? '')}</div>
                        <div className='broker'>{x.referralDetails?.lookingToAchieve || ''}</div>
                      </div>
                      <div className='task-money' style={{ fontSize: '14px'}}>
                        {toMoneyString(x.dealAmount)}
                      </div>
                      {x.brokerName &&
                        <BrokerCircle>
                        {brokerInitials}
                          <TooltipContent>
                            <div><i>Assigned Broker</i></div>
                            <div><b>{x.brokerName}</b></div>
                          </TooltipContent>
                        </BrokerCircle>
                      }
                    </div>
                  );
                })}
              </div>
              {stagesTotal[name] > deals.length &&
                <InfoButton 
                  style={{ width: '100%', color: '#2689EB', marginBottom: '10px' }}
                  onClick={() => setAmountLoaded({ ...amountLoaded, [name]: amountLoaded[name] + 20 })}
                >
                  Load More...
                </InfoButton>
              }
            </CollapsableCard>
          ) 
        })}
      </DragToScroll>

      
    </Main>
  ) 
}

const SearchBar = styled.input`
  background-image: url(${imgSearch});
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 35px !important;
`;

const dragToScrollStyle = {
  overflowX: 'auto', 
  whiteSpace: 'nowrap',
  paddingLeft: '40px',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  top: '120px',
  paddingBottom: '40px',
};

const toMoneyString = (amount, caps) => {
  if (!amount) {
    return '$-';
  }

  if (amount < 1000) {
    const rounded = Math.round(amount*10)/10;
    return `$${rounded}`;
  }
    
  if (amount < 1000000) {
    const rounded = Math.round(amount/100)/10;
    return `$${rounded}${caps ? 'K' : 'k'}`;
  }

  const rounded = Math.round(amount/10000)/100;
  return `$${rounded}${caps ? 'M' : 'm'}`;
};

const Icon = styled.img`
  position: relative;
  top: 1px;
  margin-right: 3px;
`;

const TooltipContent = styled.span`
  white-space: nowrap;
  visibility: hidden;
  background-color: #28384A;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
 
  position: absolute;
  bottom: 140%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  line-height: 150%;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #28384A transparent transparent transparent;
  }
`;  

const BrokerCircle = styled.div`

  display: inline-block;
  overflow: visible;

  position: absolute;
  right: 10px;
  top: 10px;

  text-align: center;
  line-height: 26px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #2D91F9;

  &:hover {
    ${TooltipContent} {
      visibility: visible;
    }
  }
`;

const CollapsableCard = styled.div`
  user-select: none;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  background-color: #F6F7FB;
  border: solid 1px #E9EBF1;
  border-radius: 5px;
  margin-right: 20px;
  padding: 10px 10px 0px 10px;
  transition: 300ms all;
  overflow: hidden;


  &.open {
    min-height: 450px;
    width: 260px;
    overflow: visible;
    .btn-collapse {
      background-image: url(img/button/small-left-grey.png);
    }

    .title {
      margin-bottom: 28px;
    }
    
  }

  &.closed {
    width: 56px;
    height: 450px;


    .btn-collapse {
      background-image: url(img/button/small-right-grey.png);
    }

    .title {
      width: 200px;
      position: relative;
      left: -86px;
      bottom: -280px;
      transform: rotate(-90deg);

      .main-title, .sub-title {
        display: inline;
        margin-right: 10px;
      }
    }
    .task {
      height: 0px;
      padding: 0px;
      margin: 0px;
      transition: all;
      transition-delay: 300ms;
    }

    .list-container {
      margin-top: 38px;
      opacity: 0;
      height: -10px !important;
      overflow: hidden;
      transition: 300ms height, 300ms opacity;
    }
  }
  
  .title {
    
    .main-title {
      font-size: 14px;
      font-weight: 700;
      color: #101922;
    }
  
    .sub-title {
      margin-top: 10px;
      font-size: 12px;
      color: #223241;
    }
  }

  .placeholder {
    height: 0px;
    overflow: hidden;
    transition: 500ms height;
    &:hover {
      height: 100px;
    }
  }

  .task {
    position: relative;
    box-sizing: border-box;
    // overflow: hidden;
    width: 240px;
    height: 115px;
    border: 1px solid #d8dce7;
    box-shadow: 0px 1px 0px 0px #e9ebf1;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;

    .task-description {
      position: absolute;
      top: 10px;
      left: 10px;

      .client {
        font-size: 16px;
        font-weight: 700;
        color: #101922;
        margin-bottom: 5px;
      }

      .broker {
        font-size: 12px;
        color: #223241;
        max-width: 190px;
        overflow: hidden;
        white-space: normal;
      }
    }

    .task-logo {
      position: absolute;
      bottom: 5px;
      left: 10px;
      
      img {
        max-height: 35px;
        max-width: 120px;
      }
    }

    .task-due {
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: 700;
      font-size: 12px;

      div {
        display: inline-block;
        padding-top: auto;
        padding-bottom: auto;
        align-content: center; 
        height: 100%; 
      }

      .icon-warning {
        display: inline-block;
        min-width: 17px;
        height: 17px;
        color: white;
        margin-right: 10px;
        margin-bottom: 14px;
        background-color: red;
        border-radius: 17px;
        vertical-align: middle;
        text-align: center;
        line-height: 17px; 
        font-weight: 700;
        font-size: 13px;
      }

      .txt-urgent {
        box-sizing: border-box;
        display: block;
        min-width: 20px;
        height: 20px;
        color: white;
        background-color: red;
        border-radius: 20px;
        vertical-align: middle;
        text-align: center;
        line-height: 20px; 
        font-size: 11px;
        font-weight: 700;
      }
    }

    .task-money {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export default Pipeline; 



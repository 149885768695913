import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import { formValueSelector } from "redux-form";

import ModuleBox from 'Components/Global/ModuleBox/ModuleBox';
import LoanApplicationsBox from 'Views/Dashboard/Components/LoanApplicationsBox';

const selector = formValueSelector('searchFilter');
function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    applicationProducts,
    products,
    application,
    userMeta: state.user.meta,
    userId: state.user.id,
    isOOLoan: selector(state, 'user_meta-isOOLoan'),
    isPandILoan: selector(state, 'user_meta-isPandILoan'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class EligibilityAssessment extends Component {
  render() {
    return (
      <ModuleBox id="eligibility-assessment" class="eligibility-assessment nb" title="Loan Qualifer" toggle="Y">
        <table>
          <tbody>
            <tr className="app-label">
              <th>Loan Details</th>
              <th>Application Stage</th>
              <th>Updates &amp; Comments</th>
            </tr>

            
            <LoanApplicationsBox product={this.props.products[0]} user={this.props.userMeta} key={this.props.products[0].id} purpose={this.props.isOOLoan} principle={this.props.isPandILoan} assessment={this.props.application.list} />

            {/* {this.props.products.map((product)=>
            <LoanApplicationsBox product={product} user={this.props.userMeta} key={product.id} purpose={this.props.isOOLoan} principle={this.props.isPandILoan} assessment={this.props.application.list} />
            )} */}
          </tbody>
        </table>

      </ModuleBox>
    )
  }
}

function assessmentPosition(complete_page7){
  if (complete_page7 === "1") {
    return "2";
  }
  else {
    return "1";
  } 
}


export default connect(mapStateToProps, mapDispatchToProps)(EligibilityAssessment);
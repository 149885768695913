import React from "react"; import { Component } from "react";
import Form from "./Form";
import { connect } from 'react-redux';

class LenderCashback extends Component {
  render() {
    const { miniProposal } = this.props;
    let initialValues = ((miniProposal || {}).overview || {}).lenderCashback;

    const tabs = (miniProposal|| {}).properties || [];
    for (const id in tabs) {
      initialValues = { 
        ...initialValues, 
        ['loanCashback-' + id]: tabs[id].overviewCashback 
      };
    }

    if (this.props.currentModal === "lenderCashback") {
    return (
      <div className="modal-content">
        <h1>Lender Cashback</h1>
        <Form initialValues={initialValues}/>
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(LenderCashback);

import React from "react"; import { Component } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import LinkButton from "Components/LinkButton";
import styled, { css } from 'styled-components'
import { parseFullName } from 'parse-full-name';
import Img from 'react-image';

import { logoFolder, uploadUrl } from 'modules';
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { TextArea, FormContainer, FieldGroup, DoubleFieldGroup, RadioToggleGroup } from "Components/Forms";
import updateFormValues from "modules/updateFormValues";
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';
import { emailService } from 'modules';
import isVerificationRequired from 'utils/isVerificationRequired';
import TagManager from 'react-gtm-module'

import { createPasswordValidation, createValidateEmail, createRequired, phoneNumberProps, createNumberBetween } from 'Components/Forms/validation';
import { user } from "modules/user/reducers";
const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter your email');
const nameRequired = createRequired('Please enter your name');
const valueRequired = createRequired('Please enter the property value');
const loanAmountRequired = createRequired('Please enter the loan amount');
const numberBetween = createNumberBetween(100000, 20000000, (low, high) => `Value must be between $${low} and $${high}`);
const lessThan95 = (value, values) => (value > values['user_meta-purchasePrice'] * 0.95) ? 'Loan amount must be less than 95% of the property value' : null;
const moreThan1 = (value, values) => (value < values['user_meta-purchasePrice'] * 0.01) ? 'Loan amount must be more than 1% of the property value' : null;

class Onboarding extends Component {

  state = {
    hasSubmitted: false
  }

  onSubmit = _results => {
    let results = { ..._results };

    results['user_meta-isOOLoan'] = (results['user_meta-isOOLoan'] === 'N') ? '' : results['user_meta-isOOLoan'];
    results['user_meta-isPandILoan'] = (results['user_meta-isPandILoan'] === 'N') ? '' : results['user_meta-isPandILoan'];
    
    if (results['user-fullName'] && results['user-fullName'] !== this.props.user.fullName) {
      let { first, last } = parseFullName(results['user-fullName']);
      if (last && !first) {
        first = last;
        last = '';
      }
      results['user-firstName'] = first;
      results['user-lastName'] = last;
    }

    if (results['user_meta-loanPurpose'] == 'refinance') {
      results['user_meta-refinanceLoanAmount'] = results['user_meta-loanAmount'];
      results['user_meta-repaymentType'] = results['isPandILoan'] ? 'PandI' : 'Interest';
    }

    if (results['user_meta-purchasePrice'] && results['user_meta-loanAmount']) {
      results['user_meta-depositAmount'] = results['user_meta-purchasePrice'] - results['user_meta-loanAmount'];
    }

    if (results['user-phoneNumber'] && this.props.user.meta.verifyMobile) {
      results['user-mobileVerificationRequired'] = isVerificationRequired(results['user-phoneNumber']);
    }

    let isFake = false;
    //debugger;
    if (results['user-phoneNumber']) {
      let ph = results['user-phoneNumber'].replace(/^(\+|0{1,2})/, '');
      if (ph.startsWith('61')) ph = ph.replace('61', '');
      if (ph.startsWith('0')) ph = ph.replace('0', '');
      if (ph.length > 9) isFake = true;
      if (ph.length < 8) isFake = true;
      if (ph.length == 12) isFake = false;
      if (longestRepetitionCount(ph) > 7) isFake = true;
      if (longestConsecutiveCount(ph) > 7) isFake = true;
      if (longestConsecutiveNegCount(ph) > 7) isFake = true;
      
    }
    if (isFake) {
      results['user-mobileProbablyFake'] = true;
    } else {
      results['user-notFake'] = true;
    }

    
    

    results['user_meta-introType'] = "introflowModal";

    // if (!results['user-phoneNumber'] && !this.props.user.meta.verifyMobile && !results['user-mobileVerificationRequired']) {
    //   TagManager.dataLayer({
    //     gtmId: 'GTM-5HT97R9',
    //     dataLayer: {
    //       event: 'newIntroUnverified'
    //     }
    //   });
    //   console.log("newIntroUnverified");
    // } 

    return new Promise((resolve, reject) => {

      const conversionType = !results['user-mobileVerificationRequired'] ? 
        'newIntroUnverified' : 'undefined (probably user did not register before timeout)'
      results['user_meta-conversionType'] = conversionType;
      updateFormValues(results, (result) => {
        if (!results['user-phoneNumber']) {
          this.props.push('/help');
          this.props.push('/search-loans');
          this.setState(state => ({ ...state, hasSubmitted: true }));
          resolve();
          return;
        } else {
          if (result && result.error) {
            reject(new SubmissionError(result.validation));
            return;
          } else {
            if (!results['user-mobileVerificationRequired']) {

              emailService.create({ type: 'finish-onboarding' });

              TagManager.dataLayer({
                gtmId: 'GTM-5HT97R9',
                dataLayer: {
                  event: 'newIntroUnverified'
                }
              });
              window.Intercom("boot", {
                app_id: "t2b7w54a"
              });
              console.log("newIntroUnverified");
            } else {
              emailService.create({ type: 'finish-onboarding-delayed' });
            }
            resolve();
            return;
          }
        }
      }, false, false);
    })
  }

  render() {
    if (!this.props.user.meta.finished_onboarding1 && !this.props.user.meta.skip_email) {
      return (
        <div className='modal-box additional-info onboarding-1'>
          <div className="modal-content">
            <TopHeader>
              <LeftHeader style={{ textAlign: 'left' }}>
                <LoanBaseLogo src={require('../../../img/brand/logo_2.png')} alt="logo" />
              </LeftHeader>
              <RightHeader>
                <Award style={{ width: '65px' }} src={require('../../../img/brand/small-award-notext.png')} alt="award" />
                <HotlineContainer>
                  <HotLine href="tel:1300512377">0404 262 056</HotLine>
                  <LoanBaseSubtitle>Premium Online Broker</LoanBaseSubtitle>
                </HotlineContainer>
              </RightHeader>
            </TopHeader>
            <>
              <LeftSide>
                <h1 style={{ fontSize: '24px', margin: '0 0 10px 0' }}>Instant Online Comparison</h1>

                <Above640>
                  <h3 style={{ fontSize: '16px', color: '#575E6C' }}>Compare loans from over 50 lenders!</h3>
                </Above640>

                <Below640>
                  <h3 style={{ fontSize: '14px', color: '#575E6C', margin: '0 0 20px 0', lineHeight: '1.3' }}>Over 50 lenders on our platform, with comparison rates from...</h3>
                  <Rates>
                    <Rate>{this.props.globalSettings.lowest_home_loan.toFixed(2)}<Percentage>%</Percentage><PerAnnum> p.a.</PerAnnum><Description>Home loans</Description></Rate>
                    <Rate>{this.props.globalSettings.lowest_investment_loan.toFixed(2)}<Percentage>%</Percentage><PerAnnum> p.a.</PerAnnum><Description>Investment Loans</Description></Rate>
                  </Rates>
                </Below640>

                <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <RadioToggleGroup id="user_meta-loanPurpose" width='100%' title='Is this a purchase or refinance?' hideOptional unchecked='Purchase' valueUnchecked='purchase' checked='Refinance' valueChecked='refinance' />
                  <RadioToggleGroup id="user_meta-isOOLoan" width='100%' title='What is the loan purpose?' hideOptional unchecked='Owner-occupied' valueUnchecked='Y' checked='Investment' valueChecked='N' />
                  <RadioToggleGroup id="user_meta-isPandILoan" width='100%' title='What repayment type are you after?' hideOptional unchecked='Principle &amp; Interest' valueUnchecked='Y' checked='Interest Only' valueChecked='N' />
                  <FieldGroup id='user_meta-purchasePrice' validate={[valueRequired, numberBetween]} width='100%' title='What is the property value?' hideOptional type='text' placeholder='0' mask="monetary" />
                  <FieldGroup id='user_meta-loanAmount' validate={[loanAmountRequired, lessThan95, moreThan1]} width='100%' title='What is the loan amount?' hideOptional type='text' placeholder='0' mask="monetary" />
                  <SubmitButton style={{ margin: '10px 0 0 0' }} type="submit"><ButtonSpinner loading={this.props.submitting} />Search!</SubmitButton>
                </FormContainer>
              </LeftSide>
              <Above640>
                <RightSide>
                  <LoanBaseLogo src={require('../../../img/brand/logo_2.png')} alt="logo" />
                  <LoanBaseSubtitle>Australia's Premium Online Broker</LoanBaseSubtitle>
                  <HotLine href="tel:1300512377">0404 262 056</HotLine>
                  <Award src={require('../../../img/brand/small-award-notext.png')} alt="award" />
                  <ComparisonRate>Comparison rates from:</ComparisonRate>
                  <Rates>
                    <Rate>{this.props.globalSettings.lowest_home_loan.toFixed(2)}<Percentage>%</Percentage><PerAnnum> p.a.</PerAnnum><Description>Home loans</Description></Rate>
                    <Rate>{this.props.globalSettings.lowest_investment_loan.toFixed(2)}<Percentage>%</Percentage><PerAnnum> p.a.</PerAnnum><Description>Investment Loans</Description></Rate>
                  </Rates>
                  <Rocket src={require('../../../img/page_illustrations/onboarding.png')} alt="onboarding" />
                </RightSide>
              </Above640>
            </>
          </div>
          <MobileFooter globalSettings={this.props.globalSettings} />
          <Above640>
            <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>
          </Above640>
          {/* Preload promo image */}
          <Promo style={{ "display": "none" }} src={uploadUrl + '/' + logoFolder + '/promo_image.png'} alt="onboarding" alt="promo-image" />
          <img style={{ "display": "none" }} src={uploadUrl + '/' + logoFolder + '/promo_image_mobile.png'} alt="onboarding" />
        </div>
      )
    }
    else if (!this.props.user.phoneNumber) {
      return (
        <div className='modal-box additional-info onboarding-2'>
          <div className="modal-content">
            <TopHeader>
              <LeftHeader style={{ textAlign: 'left' }}>
                <LoanBaseLogo src={require('../../../img/brand/logo_2.png')} alt="logo" />
              </LeftHeader>
              <RightHeader>
                <Award style={{ width: '65px' }} src={require('../../../img/brand/small-award-notext.png')} alt="award" />
                <HotlineContainer>
                  <HotLine href="tel:1300512377">0404 262 056</HotLine>
                  <LoanBaseSubtitle>Premium Online Broker</LoanBaseSubtitle>
                </HotlineContainer>
              </RightHeader>
            </TopHeader>
            <>
              <LeftSide state='2'>
                <h1 style={{ fontSize: '24px', margin: '0 0 10px 0' }}>We’ve identified {this.props.productsCount} loans that may be suitable for you.</h1>

                <Above640>
                  <h3 style={{ fontSize: '16px', color: '#575E6C', lineHeight: '1.4' }}>Please confirm your contact details below so we can qualify you for the best unadvertised deals.</h3>
                </Above640>

                <Below640>
                  <h3 style={{ fontSize: '14px', color: '#575E6C', margin: '0 0 20px 0', lineHeight: '1.3' }}>Please enter your contact details so we can qualify you for the sharpest unadvertised deals and view your results instantly online!</h3>
                </Below640>

                <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)}>
                  <Wrapper>
                    {!this.props.user.meta.skip_email &&
                    <>
                      <FieldGroup id='user-fullName' validate={[nameRequired]} width='100%' title='What is your name?' hideOptional type='text' />
                      <FieldGroup id='user-email' validate={[emailRequired, emailValidation]} width='100%' hideOptional title='What is your e-mail address?' type='text' />
                    </>
                    }
                    {/* <FieldGroup required id='user-phoneNumber' width='100%' title='What is your phone number?' required='Yes' type='text' /> */}
                    <DoubleFieldGroup  {...phoneNumberProps} width='100%' id='user-phoneNumber' required title="What is your phone number?" type='number-type' />
                    <SubmitButton style={{ margin: '10px 0 0 0' }} type="submit"><ButtonSpinner loading={this.props.submitting} />See My Results!</SubmitButton>
                    {this.props.user.meta.skip_email &&
                      <Above640>
                        <Details>{this.props.globalSettings.cashback_text}</Details>
                      </Above640>
                    }
                  </Wrapper>
                </FormContainer>
                
                <Below640>
                  <img style={{ display: 'block', width: '100%', margin: '40px auto 15px auto' }} src={uploadUrl + '/' + logoFolder + '/promo_image_mobile.png'} alt="onboarding" />
                </Below640>
              </LeftSide>
              <RightSide state='2'>
                <Above640>
                  <Promo src={uploadUrl + '/' + logoFolder + '/promo_image.png'} alt="onboarding" alt="promo-image" />
                </Above640>
                <Ticks>
                  <li>We negotiate better rates</li>
                  <li>Our service is completely free</li>
                  <li>Utilise our team of specialists</li>
                  <li>Easy online application process</li>
                </Ticks>
                {!this.props.user.meta.skip_email &&
                  <Above640>
                    <Details>{this.props.globalSettings.cashback_text}</Details>
                  </Above640>
                }
              </RightSide>
            </>
          </div>
          <MobileFooter globalSettings={this.props.globalSettings} />
          <Above640>
            <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>
          </Above640>
        </div>
      )
    } else {
      return "";
    }
  }
}

const MobileFooter = ({ globalSettings }) => {
  return (
    <Below640>
      <InlineBankProtection className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></InlineBankProtection>
      <RocketMobile src={uploadUrl + '/' + logoFolder + '/promo_image_footer.png'} alt="onboarding" />
      <Mandatories>
        <p>{globalSettings.comparison_text}</p>
        <p>{globalSettings.cashback_text}</p>
      </Mandatories>
    </Below640>
  )
}

const Mandatories = styled.div`
  background-color: #fff;
  width: calc(100% + 80px);
  margin: -2px -40px 0 -40px;
  padding: 40px 40px 140px;

  @media(max-width: 480px) {
    width: calc(100% + 40px);
    margin: -2px -20px 0 -20px;
    padding: 20px 20px 120px;
  }

  p { 
    color: #727C8F;
    font-size: 10px;
    margin-bottom: 8px;
  }
`;

const RocketMobile = styled(Img)`
  width: calc(100% + 80px);
  margin: 30px -40px 0 -40px;

  @media(max-width: 480px) {
  width: calc(100% + 40px);
  margin: 30px -20px 0 -20px;
  }
`;

const InlineBankProtection = styled.div`
  /* background-color: red !important; */
  width: 100% !important;
  padding: 0 !important;
  margin: 20px 0 0 0 !important;
  border-top: none !important;

  .bank {
    color: #727C8F !important;
    background-size: 20px 30px !important;
    padding: 0px 0px 10px 40px !important;
  }
`;

const Below640 = styled.span`
  display: none;

  @media(max-width: 645px) {
    display: inline;
  }
`;


const Above640 = styled.span`
  display: inline;
  
  @media(max-width: 645px) {
    display: none;
  }
`;


const Wrapper = styled.div`
  max-width: 290px;

  @media(max-width: 645px) {
    max-width: none;
  }
`;

const Ticks = styled.ul`
  margin: 20px 0 0 0;

  li {
    font-size: 14px;
    color: #575E6C;
    text-align:left;
    background-image: url(${require('../../../img/elements/thick_green_tick.png')});
    background-size: 18px 16px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 30px;
    margin-bottom: 15px;
  }
`;

const Details = styled.div`
  text-align: left;
  font-size: 10px;
  color: #575E6C;
  margin: 30px 0 0 0;
`;

const Promo = styled(Img)`
  /* width: 243px;
  height: 279px; */
  width: 100%;
  object-fit: contain;
`;

const Rates = styled.div`
  margin: 0 0 20px 0;
`;

const Rate = styled.div`
  font-size: 30px;
  color: #DF30A3;
  font-weight: 600;
  text-align: left;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;

  &:last-of-type {
    margin-right: 0;
  }
`;

const Percentage = styled.span`
  font-size: 18px;
`;

const PerAnnum = styled.span`
  font-size: 13px;
`;

const SubmitButton = styled.button`
  height: 50px !important;
  font-size: 18px !important;
`;

const Description = styled.div`
  display: block;
  font-size: 12px;
  color: #575E6C;
  margin: 5px 0 0 0;
`;

const LeftSide = styled.div`
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 350px;
  padding-right: 60px;
  border-right: 1px solid #E9EBF1;

  @media(max-width: 780px) {
    width: 300px;
  }

  @media(max-width: 645px) {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }

  .w280 {
    margin: 10px 0 15px 0;
  }

  ${props => props.state === "2" && css`
    width: 400px;
    border: none;

    @media(max-width: 780px) {
      width: 350px;
      padding-right: 30px;
    }

    @media(max-width: 645px) {
      width: 100%;
      padding-right: 0;
    }
  `}

`;

const RightSide = styled.div`
  width: calc(100% - 350px);
  display: inline-block;
  vertical-align: top;
  text-align: center;

  @media(max-width: 780px) {
    width: calc(100% - 300px);
  }

  @media(max-width: 645px) {
    width: 100%;
  }

  ${props => props.state === "2" && css`
    width: calc(100% - 400px);

    @media(max-width: 780px) {
      width: calc(100% - 350px);
    }

    @media(max-width: 645px) {
      width: 100%;
    }
  `}
`;

const LoanBaseLogo = styled.img`
  width: 135px;
  height: 93px;
  object-fit: contain;
`;

const Award = styled.img`
  margin: 20px auto;
  display: block;
  width: 135px;
  height: 95px;
  object-fit: contain;
`;


const LoanBaseSubtitle = styled.p`
  font-size: 13px !important;
  color: #575E6C !important;
  margin: 5px 0 0 0;
`;

const HotLine = styled.a`
  font-size: 19px !important;
  color: #575E6C !important;
  font-weight: 600 !important;
  background-image: url(${require('../../../img/button/call-blue.png')});
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  display: inline-block;
  padding-left: 25px;
  cursor: pointer;

  &:hover {
    color: #2291FF !important;
  }
`;

const ComparisonRate = styled.p`
  text-align: center;
  margin: 0 0 10px 0 !important;
`;

const Rocket = styled.img`
  width: 100%;
  object-fit: contain;
`;

const TopHeader = styled.div`
  border-bottom: 1px solid #D8DCE7;
  height: 80px;
  display: none;
  margin-bottom: 20px;
  /* background-color: red; */
  padding: 11px 20px;

  @media (max-width: 645px) {
    display: block;
    margin-right: -40px;
    margin-left: -40px;
    margin-top: -40px;
  }

  @media (max-width: 480px) {
    margin-right: -20px;
    margin-left: -20px;
    margin-top: -20px;
  }
`;

const RightHeader = styled.div`
  /* display: inline-block; */
  float: right;
  /* width: 50%; */
  vertical-align: top;
  /* height: 20px; */
  /* background-color: green; */

  ${Award} {
    width: 65px;
    height: 48px;
    margin: 0;
    display: inline-block;
    vertical-align: top;
  }
`;

const LeftHeader = styled.div`
  /* display: inline-block; */
  float: left;
  /* width: 50%; */
  vertical-align: top;
  /* height: 20px; */
  /* background-color: purple; */

  ${LoanBaseLogo} {
    width: 75px;
    height: 52px;
  }
`;

const HotlineContainer = styled.div`
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 8px;

  p {
    margin-bottom: 0 !important;
  }
`;

export default reduxForm({
  form: "onboarding", // a unique identifier for this form
})(Onboarding);

function longestRepetitionCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (i < s.length && s[i] === s[start]) i++; // Find end of series
      const reps = i - start;
      if (reps > count) count = reps;
  }
  return count;
}

function longestConsecutiveCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (
        i < s.length && 
        (parseInt(s[i]) === parseInt(s[i - 1]) + 1 || s[i - 1] === '9' && s[i] === '0')
      ) i++;
      const length = i - start;
      if (length > count) count = length;
  }
  return count;
}

function longestConsecutiveNegCount(s) {
  let count = 0;
  for (var i = 0; i < s.length; null) {
      let start = i++;
      while (
        i < s.length && 
        (parseInt(s[i]) === parseInt(s[i - 1]) - 1 || s[i - 1] === '0' && s[i] === '9')
      ) i++;
      const length = i - start;
      if (length > count) count = length;
  }
  return count;
}
import React from "react"; import { Component, Fragment } from "react";
import { reduxForm, change as changeForm } from "redux-form";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';
import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, SearchableSelectGroup, RateFieldGroup } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import calculateTrueRate from "utils/calculateTrueRate";
import parseNumber from 'utils/parseNumber';
import _ from 'lodash';

const $ = window.$;

function mapStateToProps(state, props) {
  return {
    user: state.user.meta,
    lenders: state.user.lenders,
    userId: state.user.id,
    initialValues: state.user.meta.miniproposal.properties[props.tabId].loanComparison.loans[props.productId],
    //currentLoan: state.user.meta.miniproposal.properties[props.tabId].currentLoan,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    requestPatchUser,
    closeModal,
  }, dispatch);
}

const ioTermValidation = value => (value < 1 || value > 10) ? 'Must be from 1 to 10 years' : null;

class CurrentLoanDetails extends Component {

  patchProposal = (data, callback) => this.props.requestPatchUser({ 
    userId: this.props.userId,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [this.props.tabId]: {
              loanComparison: {
                loans: {
                  [this.props.productId]: data
                }
              }
            }
          }
        }
      }
    },
    callback, 
  });

  onSubmit = results => {
    return new Promise(resolve => {
      this.patchProposal(results);
      this.props.closeModal();
      resolve();
    });
  }

  render() {
    return (
      <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)} className="form your-details" >
        <FieldGroup  id='ioTerm' required width='large' position='left' type='text' maxNumber={99} mask='int' validate={ioTermValidation}/>
        <button className="analyse"><ButtonSpinner loading={this.props.submitting}/>Done</button>
      </FormContainer>
    );

  }
}

CurrentLoanDetails = reduxForm({
  form: "io-term", // a unique identifier for this form
})(CurrentLoanDetails);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentLoanDetails);

import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components';

import { requestPatchUser } from 'modules/user/actions';
import Tooltips from "Components/Tooltips";
import BankLogoBase from 'Components/Global/BankLogo3';
import { SmallHeading, BlueSeperator, Bold, Italic, Footnote } from '../Shared';
import formatNumber from 'utils/formatNumber';
import { openModalWithArgs } from 'modules/modals/actions';

import imgTick from 'img/elements/tick.svg';


export const LendingStructure = ({ loans = [], editMode }) => {

  const dropdownButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const handleClickOffDropdown = event => {
    if ((dropdownRef && !dropdownRef.current.contains(event.target)) &&
      (dropdownButtonRef && !dropdownButtonRef.current.contains(event.target))) {
      setDropdownIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOffDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOffDropdown);
    }
  }, []);

  const dispatch = useDispatch();

  const { overview, user, lenderCashback, browser } = useSelector(state => ({
    overview: ((state.user.meta.miniproposal || {}).overview || {}).lendingOverview || {},
    user: state.user,
    lenderCashback: ((state.user.meta.miniproposal || {}).overview || {}).lenderCashback || {},
    browser: state.browser,
  }));

  const setRepaymentPeriod = (repaymentPeriod) => {
    setDropdownIsOpen(false);
    patchProposal({ repaymentPeriod });
  };

  const repaymentPeriod = overview.repaymentPeriod || 'month';

  const patchUser = payload => dispatch(requestPatchUser(payload));

  const patchProposal = (data, callback) => {

    const toSave = {
      overview: {
        lendingOverview: data
      }
    };

    patchUser({
      userId: user.id,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: toSave,
        }
      },
      callback,
    });
  };

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount || 0)
    .reduce((a, b) => a + b, 0);

  const totalRepayments = loans
    .map(l => l.newLoan[(repaymentPeriod || 'month') + 'lyRepayments'] || 0)
    .reduce((a, b) => a + b, 0);

  const trailRebateFootnote = createRebateFootnote(loans);

  const showCashbacks = !!lenderCashback.isActive && lenderCashback.type === 'splits';

  console.log((browser.greaterThan.w780 && browser.lessThan.w1440))
  const logoProps = (browser.greaterThan.w780 && browser.lessThan.w1440) ? {
      width: 100,
      height: 50,
      maxFill: 0.6
    } : {
      width: 135,
      height: 60,
      maxFill: 0.6
    }

  const openIOTermModal = ({ tabId, loanId }) => {
    
    dispatch(openModalWithArgs({
      modal: "io-term",
      args: {
        tabId,
        productId: loanId,
      }
    }));
  }



  return (
    <Container>
      <InlineHeading>Proposed Lending Structure</InlineHeading>
      {/* <SectionButton
        // width={180}
        type="lending-structure"
        lenderCashbackModal={() => lenderCashbackModal()}
        loanbaseCashbackModal={() => loanbaseCashbackModal()}
        loanbaseTrailRebateModal={() => loanbaseTrailRebateModal()}
        style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
        cancelSaveContainer="20px"
      /> */}
      <Adjustments>
        <BlueSeperator />

        <AdjustmentText>
          <span>
            <Bold>Total Lending</Bold>
            <BlueValue>${formatNumber(loansTotal)}</BlueValue>
          </span>
          <span>
            <Bold>Total Repayments</Bold>
            <BlueValue>
              ${formatNumber(totalRepayments)}
              <PeriodDropdown ref={dropdownButtonRef} onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>per {repaymentPeriod}</PeriodDropdown>

              <Menu ref={dropdownRef} display={dropdownIsOpen}>
                <MenuList>
                  <MenuItem onClick={() => setRepaymentPeriod("week")}>per week</MenuItem>
                  <MenuItem onClick={() => setRepaymentPeriod("fortnight")}>per fortnight</MenuItem>
                  <MenuItem onClick={() => setRepaymentPeriod("month")}>per month</MenuItem>
                </MenuList>
              </Menu>

            </BlueValue>
          </span>
        </AdjustmentText>
      </Adjustments>

      <LoanTable>
        <HeaderRow>
          <Cell width='20%'>Description</Cell>
          <Cell width='18%'>Lender</Cell>
          <Cell width='15%'>Interest Rate</Cell>
          <Cell width='18%'>Rate Type</Cell>
          <Cell width='13%'>Loan Amount</Cell>
          <Cell width='10%' style={{ textAlign: 'right' }}>Repayment</Cell>
        </HeaderRow>
        <Lenders>
          {loans.map(loan => {
            const { currentLoan, newLoan, name, cashback } = loan;

            let typeText = '';

            const repaymentType = (newLoan.repaymentType === 'Principal & Interest') ? 'P&I' : 'IO';
            const isIntroVariable = newLoan.initial_ir_type === 'Intro Variable';
            const isFixedInterest = newLoan.initial_ir_type === 'Fixed';
            const isVariableIO = newLoan.initial_ir_type === 'Variable' && repaymentType == 'IO';

            if (newLoan.fixedTermMonths > 0 && (isIntroVariable || isFixedInterest)) {
              const years = newLoan.fixedTermMonths / 12;
              typeText += `${years} Years `;
            }
            typeText += newLoan.initial_ir_type;
            typeText += (newLoan.repaymentType === 'Principal & Interest') ? ' P&I' : ' IO';

            if (isVariableIO) {
              if (editMode) {
                if (newLoan.ioTerm) {
                  typeText = <NormalIO onClick={() => openIOTermModal(loan)}>{newLoan.ioTerm} Years Variable IO</NormalIO>;
                } else {
                  typeText = <WarningIO onClick={() => openIOTermModal(loan)}>Variable IO</WarningIO>
                }
              } else if (newLoan.ioTerm) {
                typeText = `${newLoan.ioTerm} Years Variable IO`;
              }
            }

            return (
              <Row>
                <Cell width='20%'>
                  {name}
                  {!!cashback && showCashbacks && browser.greaterThan.w780 &&
                    <CashbackText>${formatNumber(cashback)} Lender Cashback</CashbackText>
                  }
                </Cell>
                <LogoCell width='18%' padding='0' >
                  <BankLogoSmall
                    {...logoProps}
                    product={newLoan}
                    alt={newLoan.bank_name} />
                </LogoCell>
                <Cell width='15%' padding='24px'>
                  <LenderRate>
                    <Rate>{formatNumber(newLoan.iir - (newLoan.trailRebate || 0), 2)}<Postfix>% p.a.</Postfix></Rate>
                    {!!newLoan.trailRebate &&
                      <EffectiveRateTooltips type='wrapper' definition={`Rate factors in a ${newLoan.trailRebate}% annual trail rebate from us`}>
                        <EffectiveRateTag>Effective Rate*</EffectiveRateTag>
                      </EffectiveRateTooltips>
                    }
                  </LenderRate>
                </Cell>
                <Cell width='18%'>
                  {typeText}
                  
                  <FeatureContainer>
                    {newLoan.full_offset === 'Y' &&
                      <Feature><img src={imgTick}/>Offset</Feature>
                    }
                    {newLoan.redraw_allowed === 'Y' &&
                      <Feature><img src={imgTick}/>Redraw</Feature>
                    }
                  </FeatureContainer>
                </Cell>
                
                <Cell width='13%'>${formatNumber(currentLoan.loanAmount)}<MobileSubtitle>Loan Size</MobileSubtitle></Cell>
                <Cell width='10%' style={{ textAlign: 'right' }}>
                  ${formatNumber(newLoan[repaymentPeriod + 'lyRepayments'])}
                  <SmallText> / {repaymentPeriod}</SmallText>
                  <MobileSubtitle>Repayment</MobileSubtitle>
                </Cell>
                {!!cashback && showCashbacks && (browser.lessThan.w780 || browser.is.w780) &&
                  <Cell>
                    <CashbackText>${formatNumber(cashback)} Lender Cashback</CashbackText>
                  </Cell>
                }
              </Row>
            )
          })}
        </Lenders>
      </LoanTable>
      {loans.some(l => l.newLoan.trailRebate) &&
        <>
        {trailRebateFootnote &&
          <Footnote>
            * {trailRebateFootnote}
          </Footnote>
        }
        </>

      }
      {!!overview.aggregateFees &&
        <Footnote>
          † Upfront lender fees are indicative only, do not include government or variable legal charges, and may vary depending on your particular circumstances.
        </Footnote>
      }
      
    </Container>
  );

};

const createRebateFootnote = loans => {
  let trailRebatesBySize = loans
    .reduce((acc, loan) => {

      const rebate = loan.newLoan.trailRebate;
      if (!rebate) return acc;
      return {
        ...acc,
        [rebate]: [...(acc[rebate] || []), loan.name]
      }
    }, {});

  trailRebatesBySize = Object.keys(trailRebatesBySize)
    .sort((a, b) => parseFloat(b) - parseFloat(a)) // largest to smallest
    .map(x => ({ size: parseFloat(x), names: trailRebatesBySize[x] }));

  if (!trailRebatesBySize.length) return '';

  let trailRebateFootnote = trailRebatesBySize
    .map(({size, names}) => {
      const s = names.length === 1; //is singular
      return `The effective rate${s ? "" : "s"} for ${listToString(
        names
      )} factor${s ? "s" : ""} in an annual Secure Finance rebate of ${size}%.`;
    }).join(' ');

  trailRebateFootnote = trailRebateFootnote + ' Please see further details below.';

  return trailRebateFootnote;
}

const listToString = _list => {
  const list = [..._list];
  if (!(list || {}).length) return '';
  if (list.length === 1) return list[0];
  const last = list.pop();
  return `${list.join(', ')} and ${last}`;
};

const FeatureContainer = styled.div`
  margin-top: 5px;

  @media (max-width: 800px) {
    margin-top: 3px;
  }
`;

const WarningIO = styled.div`
  color: #FF4E4C;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`

const NormalIO = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

const Feature = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  color: #26BD27;
  font-size: 12px;
  /* padding-left: 18px; */
  font-weight: 400;

  img {
    position: relative;
    margin-right: 4px;
    top: 2px;
  }

  @media (max-width: 1100px) {
    margin-right: 10px;

    img { margin-right: 3px; }
  }

  @media (max-width: 1025px) {

    margin-right: 5px;
    font-size: 11px;

    img { width: 12px; }  
  }

  @media (max-width: 820px) {
    margin-right: 4px;
    font-size: 10px;
  }

  @media (max-width: 780px) {
    margin-left: 7px;
    margin-right: 7px;
    font-size: 12px;
    img { 
      margin-right: 4px;
      width: 14px; 
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

const EffectiveRateTooltips = styled(Tooltips)`
  @media (max-width: 780px) {
    display: block;
    margin: 0 auto;
  }
`;

const CashbackText = styled.div`
  margin-top: 6px;
  font-size: 12px;
  font-weight: 700;
  color: #26BD27;

  @media (max-width: 850px) {
    font-size: 10px;
  }

  @media (max-width: 780px) {
    font-size: 12px;
  }
`;

const AdjustmentText = styled.span`
  @media (max-width: 580px) {
    > span {
      display: block;
      width: 100%;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
`;

const Adjustments = styled.span`
display: inline;
white-space: nowrap;

span {
  white-space: nowrap;
}

@media (max-width: 1080px) {
  display: flex;
  align-items: center;
  margin-top: 20px;

  ${BlueSeperator} {
    margin-left: 0;
  }

  ${AdjustmentText} {
    width: calc(100% - 22px);
  }
}

@media (max-width: 580px) {
  ${BlueSeperator} {
    height: 52px;
  }
}
`;

const MobileSubtitle = styled.div`
display: none;
font-size: 12px;
color: #727C8F;
margin-top: 5px;

@media (max-width: 780px) {
  display: block;
}
`;

const InlineHeading = styled(SmallHeading)`
  display: inline;
`;

const BlueValue = styled(Bold)`
  position: relative;
  color: #2291FF;
  margin: 0 20px 0 5px;
`;

const PeriodDropdown = styled.span`
  margin-left: 5px;
  padding-bottom: 3px;
  position: relative;
  cursor: pointer;
  border-bottom: solid 2px #308AE9;
`;

const Menu = styled.div`
  ${p => p.display ? `` : css`display: none;`}
  top: 35px;
  left: 35px;
  z-index: 100;
  padding: 5px 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216,220,231,0.65);
  border-radius: 4px;
  cursor: default !important;

  &::before {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent #D8DCE7 transparent;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

const MenuList = styled.ul`
`;

const MenuItem = styled.li`
  width: 110px;
  vertical-align: baseline;
  font-size: 14px;
  padding: 12px 15px;
  font-weight: 400;
  color: #101922;
  cursor: pointer;
  &:hover {
    background-color: #F6F7FB;
  }
`;

const SmallText = styled.div`
  display: inline;
  font-size: 10px;
  font-weight: 400;
`;

const LoanTable = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
`;

const Cell = styled.div`
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding-top: ${p => p.padding || '31px'};
  width: ${p => p.width};

  @media (max-width: 850px) {
    font-size: 12px;
  }

  @media (max-width: 780px) {
    font-size: 14px;
  }
`;

const LogoCell = styled(Cell)`
  display: flex;
  align-items: center;

  @media (max-width: 780px) {
    justify-content: center;
  }
`;

const Lenders = styled.div`
  @media (max-width: 780px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const Row = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  @media (max-width: 780px) {
    border: 1px solid #D8DCE7;
    border-radius: 8px;
    width: calc(50% - 7.5px);
    overflow: hidden;
    flex-wrap: wrap;
    text-align: center;
    padding: 25px 20px;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 15px;
  }

  ${Cell} {
    height: 75px;
    font-weight: 700;

    &:nth-of-type(1), &:nth-of-type(4) {
      font-family: 'Lato', sans-serif;
      font-weight: 600;
    }

    @media (max-width: 780px) {
      width: 100%;
      height: inherit;
      padding-top: 0;

      &:nth-of-type(1) {
        order: 2;
        font-weight: 800;
        margin-bottom: 20px;
      }

      &:nth-of-type(2) {
        order: 1;
        margin-bottom: 15px;

        img {
          margin: 0 auto;
        }
      }

      &:nth-of-type(3) {
        order: 4;
        margin-bottom: 10px;
      }

      &:nth-of-type(4) {
        order: 5;
        margin-bottom: 20px;

        span {
          font-weight: 700;
          font-style: normal;
        }
      }

      &:nth-of-type(5) {
        order: 3;
        margin-bottom: 20px;
      }

      &:nth-of-type(6) {
        order: 7;
        text-align: center !important;
      }

      :nth-of-type(7) {
        order: 6;
        margin-bottom: 25px;
      }
    }
  }
`;
const HeaderRow = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE7;

  @media (max-width: 780px){
    display: none;
  }

  ${Cell} {
    color: #707E97;
    height: 20px;
    padding-top: 0px !important;
  }
`;

const BankLogoSmall = styled(BankLogoBase)`
  vertical-align: middle;
  /* max-width: 130px;
  max-height: 30px;
  object-fit: contain; */
  display: inline-block;
`;

const Rate = styled.span`
  @media (max-width: 780px) {
    display: block;
    margin-bottom: 5px;
  }
`;
const Postfix = styled.span``;
const Title = styled.div``;

const LenderRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    font-size: 22px;
    font-weight: 400;

    ${Postfix} {
      font-size: 12px;
      margin-left: 5px;
      font-weight: 400;
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'Lato', sans-serif;
  }

`;

const EffectiveRateTag = styled.div`
  display: inline-block;
  font-size: 11px;
  line-height: 100%;
  color: white !important;
  font-weight: bold !important;
  background-color: #2291FF;
  padding: 3px 5px;
  border-radius: 6px;
  margin-top: 2px !important;
  white-space: nowrap;
  overflow: hidden;
  width: 81px;
`;



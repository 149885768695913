import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { FieldGroup } from "Components/Forms";
import styled, { css } from 'styled-components'
import { openModal, openModalWithArgs } from "modules/modals/actions";
import { SubMenu, MenuOptions } from "Views/Proposal/theme/Dropdown";

const $ = window.$;

class SectionButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      subMenuOpen: false,
      subMenuOpen2: false
    };
  }

  checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.proposal-button').length) {
      this.leaveMenu();
    }
  }

  eventHandler = this.checkIfLeaveMenu.bind(this);

  componentDidMount() {
    $(window).on('click', this.eventHandler);  
  }

  componentWillUnmount() {
    $(window).off('click', this.eventHandler);
  }

  toggleMenu() {
    this.setState(state => ({ ...state, menuOpen: !this.state.menuOpen }))
  }

  toggleSubMenu() {
    this.setState(state => ({ ...state, subMenuOpen: !this.state.subMenuOpen }))
  }

  toggleSubMenu2() {
    this.setState(state => ({ ...state, subMenuOpen2: !this.state.subMenuOpen2 }))
  }

  leaveMenu() {
    this.setState(state => ({ ...state, subMenuOpen: false }));
    this.setState(state => ({ ...state, subMenuOpen2: false }));
    this.setState(state => ({ ...state, menuOpen: false }));
  }

  enableEdit() {
    this.props.enableEdit();
    this.leaveMenu();
  }

  resetSection() {
    this.props.resetSection();
    this.leaveMenu();
  }

  andClose = fn => 
    () => {
      fn();
      this.leaveMenu();
    }

  menuType(type, that) {
    switch (type) {
      case "tab": return (
        <>
          <MenuOptions onClick={() => this.props.openModalWithArgs({ modal:"currentLoanDetails", args: { tabId: this.props.tabId }})} color="default">Loan Details</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu2()} confirm color="default">{this.props.excludedFromOverview ? "Include in Overview" : "Exclude from Overview"} <SubMenuOptions status={this.state.subMenuOpen2} color="red" onClick={() => this.props.toggleOverview(this.props.excludedFromOverview)}>Are you sure?</SubMenuOptions></MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Delete Tab<SubMenuOptions status={this.state.subMenuOpen} onClick={this.props.removeTab}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "section": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Remove Section<SubMenuOptions onClick={this.props.removeSection} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "overview-section": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Reset<SubMenuOptions onClick={this.resetSection.bind(this)} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "overview-objectives": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions color="default" onClick={() => this.props.openModal('objectivesList')}>Set Objectives</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Reset<SubMenuOptions onClick={this.resetSection.bind(this)} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "overview-intro-letter": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions color="default" onClick={() => this.props.openModal('introLetterTemplates')}>Set Letter</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Reset<SubMenuOptions onClick={this.resetSection.bind(this)} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "lending-overview": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions color="default" onClick={() => this.props.openOptions()}>Options</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Reset<SubMenuOptions onClick={this.resetSection.bind(this)} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "lending-structure": return (
        <>
          <MenuOptions color="default" onClick={() => this.props.lenderCashbackModal()}>Lender Cashback</MenuOptions>
          <MenuOptions color="default" onClick={() => this.props.loanbaseCashbackModal()}>Secure Finance Cashback</MenuOptions>
          <MenuOptions color="default" onClick={() => this.props.loanbaseTrailRebateModal()}>Secure Finance Trail Rebate</MenuOptions>
       </>
      );
      case "loan": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit Loan</MenuOptions>
          <MenuOptions color="default" onClick={this.andClose(this.props.editTitle)}>Edit Title</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Remove Section<SubMenuOptions onClick={this.props.removeSection} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "product": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions color="default" onClick={this.andClose(this.props.revert)}>Revert to Original</MenuOptions>
          {!this.props.recommended ? 
            <MenuOptions onClick={this.andClose(this.props.isRecommended)} color={"default"}>Set Recommended</MenuOptions>
            :
            <MenuOptions onClick={this.andClose(this.props.removeRecommended)} color={"default"}>Remove Recommended</MenuOptions>
          }
          <MenuOptions onClick={this.andClose(this.props.openRebateModal)} color={"default"}>Rebate</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Remove Product<SubMenuOptions onClick={this.props.removeProduct} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "property": return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions onClick={this.props.toggleAddress} color="default">Toggle Address</MenuOptions>
          <MenuOptions onClick={this.props.toggleLoanAmount} color="default">Toggle Loan Amount</MenuOptions>
          <MenuOptions onClick={this.props.toggleImage} color="default">Toggle Image</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Remove Section<SubMenuOptions onClick={this.props.removeSection} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      case "overview": 
      const { toggleOption, isOptionActive }  = this.props;
      const actionWord = key => isOptionActive(key) ? 'Remove' : 'Add';
      return (
        <>
          <MenuOptions color="default" onClick={() => this.enableEdit()}>Edit</MenuOptions>
          <MenuOptions onClick={() => toggleOption('termAndType')} color="default">{actionWord('termAndType')} Term & Type</MenuOptions>
          <MenuOptions onClick={() => toggleOption('upfrontFees')} color="default">{actionWord('upfrontFees')} Upfront Fees</MenuOptions>
          <MenuOptions onClick={() => toggleOption('ongoingFees')} color="default">{actionWord('ongoingFees')} Ongoing Fees</MenuOptions>
          <MenuOptions onClick={() => toggleOption('lenderRebate')} color="default">{actionWord('lenderRebate')} Lender Rebates</MenuOptions>
          <MenuOptions onClick={() => toggleOption('brokerRebate')} color="default">{actionWord('brokerRebate')} Broker Rebate</MenuOptions>
          <MenuOptions onClick={() => toggleOption('benefits')} color="default">{actionWord('benefits')} Benefits</MenuOptions>
          <MenuOptions onClick={() => this.toggleSubMenu()} confirm color="red">Remove Section<SubMenuOptions onClick={this.props.removeSection} status={this.state.subMenuOpen}>Are you sure?</SubMenuOptions></MenuOptions>
       </>
      );
      default: return (
        <MenuOptions color="disabled">Error</MenuOptions>
      )
    }
  }

  render() {
    return (
      <SectionButtonContainer {...this.props} menuOpen={this.state.menuOpen}>
        <span className='proposal-button'>
          {this.props.isEditable
            ?
            <CancelSaveContainer cancelSaveContainer={this.props.cancelSaveContainer}>
              <Button cancel onClick={this.props.cancelEdit}>Cancel</Button>
              <Button save onClick={this.props.saveEdit}>Save</Button>
            </CancelSaveContainer>
            :
            <ButtonDots color={this.props.color} onClick={() => this.toggleMenu()} active={this.state.menuOpen}></ButtonDots>
          }

          <SubMenu color={this.props.color} status={this.state.menuOpen} location={this.props.right} width={this.props.width}>
            {this.menuType(this.props.type, this)}
          </SubMenu>
        </span>
      </SectionButtonContainer>
    )
  }
}

const CancelSaveContainer = styled.div`
  margin-top: -30px;
  margin-top: ${props => props.cancelSaveContainer};  
`;

const Button = styled.button`
  vertical-align: top;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #fff;
  background-color: #2291FF;
  font-weight: 600;
  font-size: 11px;
  transition: background color 0.3s;
  box-sizing: border-box;
  padding: 4px 2px;
  width: 50px;
  margin-left: 5px;

  ${props => props.cancel && css`
    background-color: #FF4E4C;
  `};

  ${props => props.save && css`
    background-color: #26BD27;
  `};
`;


const SectionButtonContainer = styled.span`
  z-index: ${p => p.menuOpen ? 60 : 50};
`;

const SubMenuOptions = styled.div`
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(216,220,231,0.65);
  border-radius: 4px 0 4px 4px;
  right: -11px;
  top: 33px;
  padding: 10px;
  display: none;
  text-align: left;
  z-index: 60;

  ${props => props.status && css`
    display: block;
  `};

  ${props => props.color === "red" && css`
    color: #FF4E4C;

    &:hover {
      color: #EC2422;
    }
  `};

`;

const ButtonDots = styled.div`
  float: right;
  background-image: ${p => p.color === 'white' ?
    css`url(${require('../../../img/button/tripledot_white.png')})`
    :
    css`url(${require('../../../img/button/tripledot.png')})`};
  background-size: 16px 5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(0,0,0,0);
  height: 25px;
  width: 40px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  ${props => props.active && css`
    border: 1px solid #D8DCE7;

    ${p => p.color === 'white' && css`
      background-image: url(${require('../../../img/button/tripledot.png')});
      background-color: white;
    `}
  `};

  &:hover {
    border: 1px solid #D8DCE7;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
    border-radius: 4px 4px 4px 4px;

    /* ${p => p.color === 'white' && css`
      background-image: url(${require('../../../img/button/tripledot.png')});
      background-color: white;
    `} */
    
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    openModalWithArgs,
  }, dispatch);
}

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SectionButton);

import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Form } from "redux-form";
import { setTitle, setPageID } from "modules/page/actions";
import ModuleBox from "Components/Global/ModuleBox/ModuleBox";
import ReactPlayer from "react-player";
import { TextArea } from "Components/Forms";
import { SectionContainer } from "../theme/General";
import { emailService } from "modules";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import { createRequired } from 'Components/Forms/validation';
import ProductDetailsDisclaimer from "Components/Global/ProductDetailsDisclaimer";
import { Tabs, TabList, Tab, PanelList, Panel } from 'react-tabtab';
import styled, { css } from 'styled-components'

const required = createRequired("Please type a message");

function mapStateToProps(state) {
  return {
    proposal: state.user.meta.proposal,
    user: state.user.meta,
    broker: state.user.proposalBroker,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTitle,
      setPageID
    },
    dispatch
  );
}

class VideoProposal extends Component {

  sendQuestion = ({ question }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await emailService.create({
          type: "proposal-question",
          question
        });
        this.props.dispatch(this.props.change("question", ""));
        this.props.dispatch(this.props.untouch("question"));
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  render() {
    let { proposal, user, submitSucceeded, broker } = this.props;
    
    if (!broker) broker = proposal.broker;
    
    console.log(this.props);
    let proposalActive = false;
    if (user.proposal && user.proposal.enabled) {
      const value = user.proposal.expiry;
      if (value) {
        const day = parseInt(value.slice(0, 2));
        const month = parseInt(value.slice(2, 4));
        const year = parseInt(value.slice(4, 8));
        const expiry = new Date(year, month - 1, day);
        if (expiry > new Date()) proposalActive = true;
      }
    }

    if (!proposalActive) return null;

    return (
      <SectionContainer>
        <h2>Overview</h2>
        <p>{proposal.overview}</p>
        <PlayerWrapper>
          <StyledReactPlayer className="video" url={proposal.proposalUrl} width="100%" height="100%" />
        </PlayerWrapper>

        {broker &&
          <div className="broker-section">
            <div className="broker">
              <img src={require(`img/brokers/${broker.id}.png`)} alt={broker.firstName} />
              <div className="name">{broker.name}</div>
              <div className="position">{broker.title}</div>
              <ul>
                <li className="email">{broker.email}</li>
                <li className="phone">{broker.mobile}</li>
                <li className="hotline">0404 262 056</li>
              </ul>
              <button onClick={() => window.open(broker.calendlyUrl, "_blank")}>
                <span>Book time in my calendar</span>
              </button>
          
            </div>
            <div className="broker-info">
              <h2>About {broker.firstName}</h2>
              <p>{broker.blurb}</p>
              <div className="form">
                <Form onSubmit={this.props.handleSubmit(this.sendQuestion)}>
                  <h2>Ask {broker.firstName} a Question</h2>
                  <TextArea id="question" width="large" position="left" type="text" placeholder="" validate={required} />
                  {submitSucceeded && (<span className="message-sent">Your question has been sent! 🙂</span>)}
                  <button type="submit"><ButtonSpinner loading={this.props.submitting} />Submit Question</button>
                </Form>
              </div>
            </div>
          </div>
        }
        
        
      </SectionContainer>

    );
  }
}

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin: 20px 0 30px 0;
`;

VideoProposal = reduxForm({
  form: "proposal"
})(VideoProposal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoProposal);

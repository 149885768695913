import React from 'react';
import { reduxForm } from 'redux-form';

import { SearchableSelectGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

let CombinedIncome = function(props) {

  const skip = () => {
    props.change('user_meta-refinanceLender', '');
    props.skip();
  }

  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
        <h1>Who is your current lender?</h1>
        <h2>This is an optional question.</h2>
        <div className='big-select'>
          <SearchableSelectGroup id='user_meta-refinanceLender' width="large" hideOptional position='left' values={props.lenders} dontFilter={['other']}/>
        </div>
        <Navigation optional skip={skip} hidden={props.pristine } name="Next Step →" />
    </QuestionWrapper>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-current-lender'  // a unique identifier for this form
})(CombinedIncome);





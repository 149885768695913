import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { reduxForm } from "redux-form";

import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, TextArea } from "Components/Forms";
import { phoneNumberProps } from 'Components/Forms/validation';

class ContactForm extends Component {
  render() {
    return (
    <FormContainer>
      <FieldGroup id='contact-fullName' width='small' position='left' title='Full name' required='Yes' type='text' placeholder='E.g. John Doe' />
      <DoubleFieldGroup {...phoneNumberProps} id='contact-phoneNumber' width='small' position='left' title='Phone Number' required='Yes' type='number-type' placeholder='e.g. 555-555-555' />
      <SelectGroup id='contact-about' width='small' position='left' title='What would you like help with?' required='Yes' type='select' placeholder='Select One' values={['Something', 'Other']} />
      {(this.props.contactAbout === "Other")?
        <TextArea id='contact-about-other' width='small' position='left' title='Please specify other?' required='Yes' type='text' placeholder='' />
        :
        ""
      }
      <SelectGroup id='contact-when' width='small' position='left' title='When would you like a call back?' required='Yes' type='select' placeholder='ASAP' values={['Something', 'ASAP']} />
      <button className="submit">{this.props.button}</button>
    </FormContainer>
  )
}
}

ContactForm = reduxForm({
  form: 'simple',  // a unique identifier for this form
  destroyOnUnmount: false
})(ContactForm);

const selector = formValueSelector('simple') // <-- same as form name
ContactForm = connect(
  state => {
    //console.log(state);
    // can select values individually
    const contactAbout = selector(state, 'contact-about');

    return {
      contactAbout,
    }
  }
)(ContactForm);

export default ContactForm;

import {
  SET_TITLE,
  SET_PAGEID
} from './actions';

export const pageReducer = (state = {}, action) => {
  switch(action.type) {
    case SET_TITLE: {
      return {...state, title: action.payload};
    }
    case SET_PAGEID: {
      return {...state, pageID: action.payload};
    }
    default : {
      return state;
    }
  }
};

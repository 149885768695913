import React, { useEffect, useState, useRef } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import formatNumber from 'utils/formatNumber';
import getQueryPrams from 'utils/getQueryParams';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import { uploadUrl } from 'modules';
import { emailService } from 'modules';

import {
  Main, MainContainer, PageIllustration, Card, Stage, Title, Paragraph, ButtonContainer, InfoButton, ReferButton, StageLabel
} from '../Components/Styled';
import { Img } from '../Components/Img';
import { Input } from 'Components/Forms';
import { addNoteToReferral } from 'modules/user/actions';
import html from 'shared/utils/html';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';

import imgBack from 'img/button/left-arrow-short-blue.png'; 
const $ = window.$;




const ReferralDetails = ({ history, match }) => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle('Deal Details'));
    dispatch(setPageID('referrals'));
  }, []);
  const messageRef = useRef(null);

  const referrals = useSelector(state => state.user.referredApplications ?? []);
  const [ message, setMessage ] = useState('');
  const [ sendingEmail, setSendingEmail ] = useState(false);
  const [ emailSent, setEmailSent ] = useState(false);
  
  const id = parseInt(match.params.id);

  const referral = referrals.find(x => x.id === id);

  const askForUpdate = async () => {
    if (emailSent) return;
    setSendingEmail(true);
    await emailService.create({
      type: 'request-update',
      applicationId: referral.id,
    });
    setSendingEmail(false);
    setEmailSent(true);
  }

  const submitMessage = () => {
    if (!message) return;
    dispatch(addNoteToReferral({
      id: referral.id,
      note: {
        visibleToReferrer: true,
        body: message,
        brokerId: 'referrer'
      },
      callback: () => {
        const messages = messageRef.current;
        messages.scrollTop = messages.scrollHeight;

        emailService.create({
          type: 'referrer-note',
          applicationId: referral.id,
          note: message,
        });
      }
    }));
    setMessage('');
  }

  const onKeypress = e => {
    if(e.which === 13) {
      submitMessage(message);
    }
  }
  
  useEffect(() => {
    $(window).on('keypress', onKeypress);
    return () => {
      $(window).off('keypress', onKeypress);
    };
  });
  
  // scroll to the most recent notes
  useEffect(() => {
    const messages = messageRef.current;
    messages.scrollTop = messages.scrollHeight;
  });

  if (!id) return null;
  
  return (
    <Main>
      <InfoButton 
        onClick={() => history.replace('/referrals/pipeline') } 
        style={{ height: '25px', lineHeight: '25px', marginBottom: '30px', fontSize: '11px'}}>
          <img src={imgBack} style={{ height: '10px', marginRight: '10px', position: 'relative', top: '1px'}}/>    
          Return to pipeline
      </InfoButton>
      <Card style={{ width: '610px', overflow: 'hidden', padding: '0 0 0 0' }}>
        <HeadingBar>
          <ButtonContainer width='100%'>
            <div>{referral.referralDetails?.firstName} {referral.referralDetails?.lastName}</div>
            <div>
              <Stage color='#575E6C' style={{ marginRight: '10px' }}>${formatNumber(referral.dealAmount) || '—'} Deal Value</Stage>
              <StageLabel stage={referral.stage} noTooltip/>
            </div>
          </ButtonContainer>
        </HeadingBar>
        <Details>
          <DetailsItem>
            <DetailsTitle>E-mail</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.email || '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>Best contact number</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.phoneNumber || '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>Best time to call</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.bestCallTime || '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>What are they looking to achieve?</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.lookingToAchieve || '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>Approx borrowing amount</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.borrowAmount ? '$' + formatNumber(referral.referralDetails?.borrowAmount) : '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>Approx property value</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.purchasePrice ? '$' + formatNumber(referral.referralDetails?.purchasePrice) : '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem>
            <DetailsTitle>Additional comments</DetailsTitle>
            <DetailsValue>{referral.referralDetails?.additionalComments || '—'}</DetailsValue>
          </DetailsItem>
          <DetailsItem style={{ marginTop: '10px' }}>
            <DetailsTitle></DetailsTitle>
            <DetailsValue>
              <ReferButton 
                color={emailSent ? '#00CD98' : '#2291ff'} 
                style={{ width: '230px', cursor: emailSent ? 'default' : 'pointer' }} 
                onClick={askForUpdate}>
                  <ButtonSpinner style={{ marginRight: '10px'}} loading={sendingEmail}/>
                  {emailSent ? 'Request Sent' :'Click to request deal update'}
              </ReferButton>
            </DetailsValue>
          </DetailsItem>
        </Details>
        <Heading>Comments</Heading>
        <Messages ref={messageRef}>
          {(referral.notes ?? [])
            .sort((a, b) => moment(a.date) - moment(b.date))
            .map((note, index) => <MessageComponent key={index} index={index} note={note} editNote={() => {}}/>)
          }
        </Messages>

        <Row>
          <Column className='form' style={{ width: '82%', marginRight: '3%'}} >
            <input type='text' placeholder='Type a message...' value={message} onChange={e => setMessage(e.target.value)} />      
          </Column>
          <Column style={{ width: '15%'}}>
            <ReferButton style={{ width: '100%' }} onClick={() => submitMessage(message)}>Send</ReferButton>
          </Column>
        </Row>

        
      </Card>

    </Main>
  );
}

const MessageComponent = ({ note, index, editNote }) => {
  const brokers = useSelector( state => state.user.team );
  const user = useSelector( state => state.user );

  let broker, avatarUrl, isSelf;  
  let isWarning = false;
  if (note.brokerId === 'system') {
    broker = { name: 'System', rawName: 'System' }; 
    avatarUrl = require('img/avatars/system.png');
    isSelf = false;
  } else if (note.brokerId === 'system warning') {
    broker = { name: 'System Warning', rawName: 'System Warning'  }; 
    avatarUrl = require('img/avatars/system.png');
    isSelf = false;
    isWarning = true;
  } else if (note.brokerId === 'referrer') {
    broker = { name: 'You', rawName: user.firstName + ' ' + user.lastName }; 
    isSelf = true;
  } else {
    const rawBroker = brokers.find(x => x.id === note.brokerId);
    broker = {
      ...rawBroker,
      name: "(Secure Finance) " + rawBroker.name,
      rawName: rawBroker.name,
    };
    avatarUrl = `${uploadUrl}/brokers/small${broker.id ?? 'noimage'}.png`;
    isSelf = false;
  }

  return <>
    <Message isSelf={isSelf} isWarning={isWarning}>
      <HeaderRow>
        <Header>
          <Avatar src={avatarUrl} objectFit={'cover'} loader={<Fallback broker={broker} />} fallback={<Fallback broker={broker} />}/>
          <Sender>{broker.name}</Sender>
          <Time>{moment(note.date).format('h:mma, Do MMM, YYYY')}</Time>
        </Header>
      </HeaderRow>
      
      <Body dangerouslySetInnerHTML= {{ __html: sanitizeHtml(note.body) }} />
    </Message>
  </>;
};

const Details = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #D8DCE7;
`;

const DetailsTitle = styled.div``;
const DetailsValue = styled.div``;
const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 28px;
  /* margin-bottom: 5px; */
  ${DetailsTitle} {
    width: 45%;
    font-weight: bold;
    text-align: right;
  }

  ${DetailsValue} {
    width: 51%;
  }
`

const HeadingBar = styled.div`
  background-color: #D8DCE7;
  font-size: 17px;
  font-weight: bold;

  height: 46px;
  line-height: 46px;

  padding-left: 20px;
  padding-right: 20px;
`;

const Messages = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  min-height: 100px;
  max-height: 650px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  overflow-y: auto;
`;


const Heading = styled.h1`
  margin-top: 20px;
  margin-left: 30px;
  font-size: 17px;
  font-weight: bold;
`;

const Row = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 40px;  
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const Body = styled.div`
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    /* margin-top: 20px; */

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      /* margin-bottom: 15px; */
    }
  }

  ol {
    /* margin-top: 20px; */

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      /* margin-bottom: 15px; */
    }
  }
`;

const Header = styled.div``;
const HeaderRow = styled.div``;
const Avatar = styled(Img)``;
const Sender = styled.span``;
const Time = styled.span``;

const Fallback = ({broker}) => {

  if (!broker) return <FallbackAvatar />;
  let brokerInitials = ''
  const brokerNames = broker.rawName.split(' ');
  if (brokerNames.length === 1) {
    brokerInitials = brokerNames[0].charAt(0);
  } else if (brokerNames.length > 1) {
    brokerInitials = brokerNames[0].charAt(0) + brokerNames[brokerNames.length - 1].charAt(0)
  }

  return <FallbackAvatar>
    <div>{brokerInitials}</div>
  </FallbackAvatar>;
};

const FallbackAvatar = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #2D91F9;
  
  text-align: center;
  line-height: 26px;
  color: white;
  font-size: 12px;
  font-weight: 700;
`;

const Message = styled.div`
  margin-bottom: 20px;

  ${HeaderRow} {
    display: flex;
    margin-bottom: 10px;
    ${p => p.isSelf && css`
      flex-direction: row-reverse;
    `}
  }

  ${Avatar} {
    display: inline-block;
    object-fit: cover;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #919191;
    position: relative;
    top: 7px;
  }

  ${Sender} {
    position: relative;
    //top: -7px;
    font-size: 12px;
    font-weight: bold;
    margin: 0 10px 0 6px;
  }

  ${Time} {
    position: relative;
    //top: -7px;
    font-size: 12px;
    color: #858997;
  }

  ${Body} {
    background-color: #F1F0F0;
    border-radius: 12px;
    width: 100%;
    font-size: 15px;
    padding: 12px;
    line-height: 150%;

    ${p => p.isSelf && css`
      background-color: #2291FF;
      color: white;
    `}

    ${p => p.isWarning && css`
      background-color: #FF4E4C;
      color: white;
    `}
  }
`;

export default ReferralDetails;
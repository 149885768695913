import React from "react";
import { Component } from 'react';
import { reduxForm, change } from "redux-form";
import { connect } from 'react-redux';
import { dispatch } from 'redux';

import initializeFormValues from "utils/initializeFormValues";
import updateFormValues from "modules/updateFormValues"
import calculateLoanValues from "utils/calculateLoanValues";
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments"

import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, KeyLine, Subtitle, RateFieldGroup } from "Components/Forms";

import Debouncer from 'utils/debouncer';
import { createNumberBetween } from 'Components/Forms/validation';
const debouncer = new Debouncer();
const loanTermValidation = createNumberBetween(1, 30, (low, high) => `Loan term must be between ${low} and ${high} years`);


const LOAN_AMOUNT = 'calculators-refinanceLoanAmount';
const INTEREST_RATE = 'calculators-refinanceInterestRate';
const REPAYMENT_TYPE = 'calculators-refinanceRepaymentType';
const LOAN_PERIOD = 'calculators-refinanceYears';
const MONTHLY_REPAYMENT = 'calculators-refinanceMonthlyRepayment';
const REPAYMENT_FREQUENCY = 'calculators-refinanceRepaymentFrequency';
const ANNUAL_FEE = 'calculators-refinanceAnnualFee';

const SWITCHING_FEE = 'calculators-refinanceSwitchingFee'

const NEW_INTEREST_RATE = 'calculators-refinanceNewInterestRate';
const NEW_REPAYMENT_TYPE = 'calculators-refinanceNewRepaymentType';
const NEW_LOAN_PERIOD = 'calculators-refinanceNewYears';
const NEW_MONTHLY_REPAYMENT = 'calculators-refinanceNewMonthlyRepayment';
const NEW_REPAYMENT_FREQUENCY = 'calculators-refinanceNewRepaymentFrequency';
const NEW_ANNUAL_FEE = 'calculators-refinanceNewAnnualFee';


const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(Number.parseFloat(interestRate) / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = Number.parseFloat(interestRate) * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}

const calculateLoanSchedulePandI = ({ period, ir, principle }) => {
  const repayment = calculateMonthlyRepayments(ir, period, principle);
  let totalInterestRepayment, totalPrincipleRepayment;
  totalInterestRepayment = totalPrincipleRepayment = 0;
  let schedule = [{ 
    time: 0,
    remainingPrinciple: principle, 
    totalInterestRepayment,
    totalPrincipleRepayment, 
    interestRepayment: 0, 
    principleRepayment: 0
  }];
  [...Array(period)].forEach( (_, i) => {
    const remainingPrinciple = principle - totalPrincipleRepayment;
    const interestRepayment = remainingPrinciple * ir;
    totalInterestRepayment += interestRepayment;
    const principleRepayment = repayment - interestRepayment;
    totalPrincipleRepayment += principleRepayment;
    schedule.push({ 
      time: i + 1, 
      interestRepayment, 
      totalInterestRepayment, 
      principleRepayment, 
      remainingPrinciple 
    });
  });
  return schedule;
}

const calculateLoanScheduleInterestOnly = ({ period, ir, principle }) => {
  //console.log('IO schedule stuff:', period, ir, principle)
  let schedule = [{
    time: 0,
    remainingPrinciple: principle,
    totalInterestRepayment: 0,
    totalPrincipleRepayment: 0, 
    interestRepayment: 0, 
    principleRepayment: 0
  }];
  const interestRepayment = ir * principle;
  let totalInterestRepayment = 0;
  [...Array(period)].forEach( (_, i) => {
    totalInterestRepayment += interestRepayment;
    schedule.push({ time: i + 1, 
      interestRepayment, 
      totalInterestRepayment, 
      principleRepayment: 0, 
      remainingPrinciple: principle 
    });
  });
  return schedule;
}

class RefinancingForm extends Component {

  state = { 
    monthlyRepayments: 0,
    newMonthlyRepayments: 0,
    yearOneSavings: 0,
    repaymentSavings: 0,
    termExtension: 0,
  }

  componentDidMount() {

    const { user, products } = this.props;
    let product; 
    if (user.refinanceFocus) {
      product = products.find(p => p.id === user.refinanceFocus);
    }

    if (product) product = calculateLoanValues(product);
    else product = {}; 

    let monthlyRepayments, newMonthlyRepayments;
    initializeFormValues('calculator', (values, state) => {
      
      
      const initialValues = {
        [NEW_INTEREST_RATE]: product.iir,
        [NEW_ANNUAL_FEE]: product.monthly_fees * 12 || (product.annual_fees || null),
        [NEW_REPAYMENT_FREQUENCY]: 'monthly',
        [NEW_LOAN_PERIOD]: 30,
        [NEW_REPAYMENT_TYPE]: product.repaymentType === 'Interest Only' ? 'Interest' : 'PandI',
        [LOAN_AMOUNT]: user.refinanceLoanAmount || user.loanAmount,
        [INTEREST_RATE]: user.refinanceInterestRate,
        [REPAYMENT_TYPE]: user.refinanceRepaymentType || 'PandI',
        [LOAN_PERIOD]: user.refinanceYears || user.loanPeriod,
        [MONTHLY_REPAYMENT]: user.refinanceMonthlyRepayment,
        [REPAYMENT_FREQUENCY]: 'monthly',

        ...values,
      }

      monthlyRepayments = calculateRepayments({
        loanAmount: initialValues[LOAN_AMOUNT],
        interestRate: initialValues[INTEREST_RATE] / 100,
        repaymentType: initialValues[REPAYMENT_TYPE],
        loanPeriod: initialValues[LOAN_PERIOD],
      }) || 0;

      newMonthlyRepayments = calculateRepayments({
        loanAmount: initialValues[LOAN_AMOUNT],
        interestRate: initialValues[NEW_INTEREST_RATE] / 100,
        repaymentType: initialValues[NEW_REPAYMENT_TYPE],
        loanPeriod: initialValues[LOAN_PERIOD],
      }) || 0;
      //console.log('calculating...', monthlyRepayments, newMonthlyRepayments)
      this.setState({monthlyRepayments, newMonthlyRepayments});
      
      return initialValues;
    });

    if (newMonthlyRepayments)
      this.props.change(NEW_MONTHLY_REPAYMENT, newMonthlyRepayments);

  }

  componentWillReceiveProps(nextProps) {
    const newValues = nextProps.formValues;
    const values  = this.props.formValues;
    //console.log('values', this.props.formValues);
    if (!values) return;

    let newMonthlyRepayments = values[NEW_MONTHLY_REPAYMENT];
    let monthlyRepayments = values[MONTHLY_REPAYMENT];

    if (
      values[LOAN_AMOUNT] !== newValues[LOAN_AMOUNT] ||
      values[INTEREST_RATE] !== newValues[INTEREST_RATE] ||
      values[REPAYMENT_TYPE] !== newValues[REPAYMENT_TYPE] ||
      values[LOAN_PERIOD] !== newValues[LOAN_PERIOD]
    ) {
      monthlyRepayments = calculateRepayments({
        loanAmount: newValues[LOAN_AMOUNT],
        interestRate: newValues[INTEREST_RATE] / 100,
        repaymentType: newValues[REPAYMENT_TYPE],
        loanPeriod: newValues[LOAN_PERIOD],
      }) || 0;

      if (!values[MONTHLY_REPAYMENT] || values[MONTHLY_REPAYMENT] == this.state.monthlyRepayments) {;
        this.props.change(MONTHLY_REPAYMENT, monthlyRepayments);
      }

      this.setState({monthlyRepayments});
    }

    if (
      values[LOAN_AMOUNT] !== newValues[LOAN_AMOUNT] ||
      values[NEW_INTEREST_RATE] !== newValues[NEW_INTEREST_RATE] ||
      values[NEW_REPAYMENT_TYPE] !== newValues[NEW_REPAYMENT_TYPE] ||
      values[LOAN_PERIOD] !== newValues[LOAN_PERIOD]
    ) {
      newMonthlyRepayments = calculateRepayments({
        loanAmount: newValues[LOAN_AMOUNT],
        interestRate: newValues[NEW_INTEREST_RATE] / 100,
        repaymentType: newValues[NEW_REPAYMENT_TYPE],
        loanPeriod: newValues[LOAN_PERIOD],
      }) || 0;

      if (!values[NEW_MONTHLY_REPAYMENT] || values[NEW_MONTHLY_REPAYMENT] == this.state.newMonthlyRepayments) {;
        this.props.change(NEW_MONTHLY_REPAYMENT, newMonthlyRepayments);
      }

      this.setState({newMonthlyRepayments});
    }
  }

  onRepaymentBlur = e => {
    if (!this.props.formValues[MONTHLY_REPAYMENT]) {
      this.props.change(MONTHLY_REPAYMENT, this.state.monthlyRepayments);
      e.preventDefault();
    }
  }

  onNewRepaymentBlur = e => {
    if (!this.props.formValues[NEW_MONTHLY_REPAYMENT]) {
      this.props.change(NEW_MONTHLY_REPAYMENT, this.state.newMonthlyRepayments);
      e.preventDefault();
    }
  }
  

  render() {
    return(
      <div className="form" >
        <form onSubmit={this.props.handleSubmit} className="your-details">

          <FormContainer>
            
            <FieldGroup id={LOAN_AMOUNT} width='small' position='left' title='Loan Amount' hideOptional type='text' placeholder='0' mask='monetary'/>
            <FieldGroup id={LOAN_PERIOD} width='small' position='right' title='Remaining Loan Term' hideOptional type='text' mask='years' placeholder='0' validate={loanTermValidation} maxNumber={99}/>
            <FieldGroup id={SWITCHING_FEE} width='small' position='left' title='Approximate Switching Costs' required='No' type='text' placeholder='0' mask='monetary' tooltip='switchingCost'/>

            <KeyLine />
            <Subtitle width='small' position='left' title='Your current loan' />
            <Subtitle width='small' position='right' title='Your new loan' />
            <RadioToggleGroup id={REPAYMENT_TYPE} width='small' position='left' title='Repayment Type' hideOptional unchecked='Principle & Interest' valueUnchecked='PandI' checked='Interest Only' valueChecked='Interest' />
            <RadioToggleGroup id={NEW_REPAYMENT_TYPE} width='small' position='right' title='Repayment Type' hideOptional unchecked='Principle & Interest' valueUnchecked='PandI' checked='Interest Only' valueChecked='Interest' />
            <RateFieldGroup id={INTEREST_RATE} width='small' position='left' title='Interest Rate' hideOptional />
            <RateFieldGroup id={NEW_INTEREST_RATE} width='small' position='right' title='Interest Rate' hideOptional />
            <FieldGroup id={MONTHLY_REPAYMENT} width='small' position='left' title='Approximate Monthly Repayments' hideOptional type='text' placeholder='0' mask='monetary' autoCalculated={this.props.formValues && this.state.monthlyRepayments == this.props.formValues[MONTHLY_REPAYMENT]} onBlur={this.onRepaymentBlur}/>
            <FieldGroup id={NEW_MONTHLY_REPAYMENT} width='small' position='right' title='Approximate Monthly Repayments' hideOptional type='text' placeholder='0' mask='monetary' autoCalculated={this.props.formValues && this.state.newMonthlyRepayments == this.props.formValues[NEW_MONTHLY_REPAYMENT]} onBlur={this.onNewRepaymentBlur}/>
            <FieldGroup id={ANNUAL_FEE} width='small' position='left' title='Annual Fee' type='text' placeholder='0' mask="monetary" />
            <FieldGroup id={NEW_ANNUAL_FEE} width='small' position='right' title='Annual Fee' type='text' placeholder='0' mask="monetary" />
          </FormContainer>

        </form>
      </div>
    );
  }
}

RefinancingForm = reduxForm({
  form: "calculator", 
  onChange: (v, dispatch, props, prevValues) => {
    debouncer.run(() => {
      const chartLength = (v[REPAYMENT_TYPE] === 'Interest' || v[NEW_REPAYMENT_TYPE] === 'Interest') ? 5 : v[LOAN_PERIOD]; 

      const showResults = v[NEW_MONTHLY_REPAYMENT] && v[MONTHLY_REPAYMENT]
        && !(v[REPAYMENT_TYPE] === 'PandI' && !v[LOAN_AMOUNT])
        && !(v[NEW_REPAYMENT_TYPE] === 'PandI' && !v[LOAN_AMOUNT]);

      const loanSchedule = v[REPAYMENT_TYPE] === 'Interest' ?
        calculateLoanScheduleInterestOnly({ period: v[LOAN_PERIOD] * 12, ir: v[INTEREST_RATE] / 1200, principle: v[LOAN_AMOUNT]})
        :
        calculateLoanSchedulePandI({ period: v[LOAN_PERIOD] * 12, ir: v[INTEREST_RATE] / 1200, principle: v[LOAN_AMOUNT] });

      const newLoanSchedule = v[NEW_REPAYMENT_TYPE] === 'Interest' ?
        calculateLoanScheduleInterestOnly({ period: v[LOAN_PERIOD] * 12, ir: v[NEW_INTEREST_RATE] / 1200, principle: v[LOAN_AMOUNT] })
        :
        calculateLoanSchedulePandI({ period: v[LOAN_PERIOD] * 12, ir: v[NEW_INTEREST_RATE] / 1200, principle: v[LOAN_AMOUNT] });

      const yearOneSavings = (loanSchedule[12].totalInterestRepayment - newLoanSchedule[12].totalInterestRepayment) - (v[SWITCHING_FEE] || 0) + (v[ANNUAL_FEE] || 0) - (v[NEW_ANNUAL_FEE] || 0);
      const repaymentSavings = v[MONTHLY_REPAYMENT] - v[NEW_MONTHLY_REPAYMENT] ;
      const termExtension = 0;
      const totalSavings = (loanSchedule[chartLength * 12].totalInterestRepayment - newLoanSchedule[chartLength * 12].totalInterestRepayment) - (v[SWITCHING_FEE] || 0) + ((v[ANNUAL_FEE] || 0) - (v[NEW_ANNUAL_FEE] || 0)) * chartLength;

      dispatch(change('calculator', 'calculator-result_RefinanceSavings', totalSavings));
      updateFormValues({
        'calculators-result_RefinanceSavings': totalSavings,
        'calculators-refinanceActive': showResults ? 'Y' : '',
        ...v,
      });

      props.onValueChange({
        loanSchedule,
        newLoanSchedule,
        showResults,
        yearOneSavings,
        repaymentSavings,
        termExtension,
        annualFee: v[ANNUAL_FEE] || 0,
        newAnnualFee: v[NEW_ANNUAL_FEE] || 0,
        switchingFee: v[SWITCHING_FEE] || 0,
        loanPeriod : (!v[LOAN_PERIOD] || v[LOAN_PERIOD] > 30) ? 30 : v[LOAN_PERIOD],
        totalSavings,
        chartLength,
      });
    }, 300);
    
  }
})(RefinancingForm);

const stateToProps = state => ({
  products: state.products.list,
  user: state.user.meta,
  formValues: state.form['calculator'] && state.form['calculator'].values || undefined,
});

export default connect(stateToProps) (RefinancingForm);

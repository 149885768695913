import {hostUrl} from "modules"
import store from 'store';
import {requestPatchUser} from "modules/user/actions";

const oauthSignin = (provider, returnPage)=>{
    let user = store.getState().user;
    
    if (user && user.meta) {
        let data = {
            [provider + "Id"]: "T_" + (new Date().getTime() / 1000)
        };
        if (returnPage) {
            data.meta = {
                returnPage
            }
        }

        store.dispatch(requestPatchUser({
            userId: user.id,
            data,
            callback: ()=>{
                window.location = hostUrl + "/auth/" + provider;
            }
        }));
    } else {
        window.location = hostUrl + "/auth/" + provider;
    }
};

export const googleSignin = (returnPage)=>{    
    oauthSignin('google', returnPage);
}

export const facebookSignin = (returnPage)=>{
    oauthSignin('facebook', returnPage);
}
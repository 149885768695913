import store from 'store';

import {requestProducts} from "modules/product/actions";

export default function requestFilteredProducts(callback, skip=0, sortField='iir', sortDirection=1, godmode ) {
    //console.log('requesting products!')
    //console.log('sorting:', sortField, sortDirection);

    let user = store.getState().user;

    if (sortField === 'monthlySavings' || sortField === 'repayments') {
      sortField = 'iir';
    }

    if (sortField === 'comparisonRate' && user.meta.loanPurpose === "construct") {
        sortField = 'comparisonConstruction';
    }

    let filters = Object.keys(user.meta).reduce((a, key)=>{
        if (key.indexOf("searchFilter_") === 0 && user.meta[key]) {
            return {...a, [key.replace("searchFilter_", "")] : user.meta[key] }
        } else {
            return a;
        }
    }, {});

    //console.log('filters', filters);
    if(filters.full_offset) {
        filters.full_offset = { $ne: 'N'};
    }

    const { loanAmount, loanPeriod } = user.meta;
    filters.maximum_loan_amount = { $gte: loanAmount };
    filters.minimum_loan_amount = { $lte: loanAmount };
    filters.maximum_term = { $gte: loanPeriod };

    let lvr = 100 * user.meta.loanAmount / user.meta.purchasePrice;
       
    if (user.meta.isOOLoan) {
        filters.maximum_lvr_own_occ = { $gte: lvr }
        if (user.meta.isPandILoan) {
            filters.io_allowed_for_own_occ = { $ne: "Y" };
        } else {
            filters.io_allowed_for_own_occ = "Y";
        }
    } else {
        filters.maximum_lvr_investment = { $gte: lvr }
        if (user.meta.isPandILoan) {
            filters.io_repayments = { $ne: "Y" };
        } else {
            filters.io_repayments = "Y";
        }
    }

    
    if (user.meta.loanPurpose === "refinance") {
        filters.maximum_lvr_refinance = { $gte: lvr }
    } else if (user.meta.loanPurpose === "construct") {
        filters.maximum_lvr_spec_builder = { $gte: lvr }
    }
    if (filters.ownerBuilder) {
        delete filters.ownerBuilder;
        filters.maximum_lvr_owner_builder = { $gte: lvr }
    }
    if (filters.vacant) {
        delete filters.vacant;
        filters.maximum_lvr_spec_builder = { $gte: lvr }
    }
    if (filters.loDoc) {
        delete filters.loDoc;
        if (lvr <= 80) {
          filters.maximum_lvr_lodoc = { $or: [{$gte: lvr}, {$eq: 0}] };
        }
        else {
          filters.maximum_lvr_lodoc = { $gte: lvr };
        }
        filters.is_lodoc = "Y";
        filters.minimum_lodoc_loan_amount = { $lte: loanAmount };
        filters.maximum_lodoc_loan_amount = { $or: [{$gte: loanAmount}, {$eq: 0}] };
    }
    if (filters.lockedRate) {
        delete filters.lockedRate;
        filters.rate_lock_fee = { $gte: 0 }
    }
    if (filters.package) {
        delete filters.package;
        filters.pkg_seq = { $gte: 0 }
    }

    if (filters.rebate) {
        delete filters.rebate;
        let lenderList = user.lenders.filter(lender => lender.rebateType && lender.rebateValue !== 'Preferred Partner');
        let lenderFilter = lenderList
          .map(lender => lender.id);
        //console.log('lender filter', lenderList);
        filters.$and = [
            { $or: [{ rebate_type: { $ne: null } }, { bank: lenderFilter }] }
        ];
    }

    if (godmode) {
        filters.godmode = godmode;
    }

    if (filters.bank && filters.bank.length)  {
      filters.bank = filters.bank
        .filter(b => b !== 'ALL_LENDERS' && b !== 'MAJOR_ONLY');

        if (godmode && filters.bank.length) {
            if (!filters.$and) filters.$and = [];
            filters.$and.push({
                $or: [
                    { bank: filters.bank },
                    { hiddenLenderId: filters.bank }
                ]
            });
            delete filters.bank;
        }
    } 

    // const freq = filters.repaymentFrequency;
    // if (freq === 'monthly') filters.monthly_repayment = 'Y';
    // if (freq === 'weekly') filters.weekly_repayment = 'Y';
    // if (freq === 'fortnightly') filters.fortnightly_repayment = 'Y';
    delete filters.repaymentFrequency;
    // debugger;
    const irType = filters.initial_ir_type
    const fixedTermValues = {
        'any': { $gt: 0 },
        '1': { $eq: 12 },
        '2': { $eq: 24 },
        '3': { $eq: 36 },
        '4': { $eq: 48 },
        '5': { $eq: 60 },
        '5+': { $gt: 60 },
    }
    if (!filters.fixed_term) filters.fixed_term = 'any';
    delete filters.initial_ir_type;
    if (irType !== 'any') {
        if (irType === 'F') {
            filters.fixed_term = fixedTermValues[filters.fixed_term];
            filters.initial_ir_type = 'F'
        } else if (irType === 'IV') {
            filters.fixed_term = { $gt: 0 };
            filters.initial_ir_type = 'IV';
        } else if (irType === 'V') {
            if (!filters.$and) filters.$and = [];
            filters.$and.push({
                $or: [
                    { initial_ir_type: 'V' },
                    { initial_ir_type: 'IV' },
                    { initial_ir_type: 'B' }
                ]
            });
            delete filters.initial_ir_type;
            delete filters.fixed_term;
        }
    } else {
        if (!filters.$and) filters.$and = [];
        filters.$and.push({
            $or: [
                { fixed_term: fixedTermValues[filters.fixed_term] },
                { initial_ir_type: { $ne: 'F' } },
            ]
        });
        delete filters.fixed_term;
    }
    console.log('Filter:', filters);
    //debugger;
    if (user.globalSettings.min_allowed_interest_rate) {
        filters.iir = { $gte: user.globalSettings.min_allowed_interest_rate }
    }
    //filters.interest_rate_type = { $ne: 'LD' };
    //console.log('filters:', filters)
    store.dispatch(requestProducts({
        query: { ...filters, 
            $sort: { 
                is_priority_product: -1,
                [sortField]: sortDirection, 
            },
            $limit: 6,
            $skip: skip,
            product_active: 'Y',
          },
        }));
}
import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import Navigation from "Components/Navigation/Navigation";
import {requestSetAccountAbused} from "modules/user/actions";

import getQueryParams from "utils/getQueryParams";

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSetAccountAbused
  }, dispatch);
}

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    let params = getQueryParams(this.props.location.search);
    this.state = { email: params.email, token: params.token };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.requestSetAccountAbused({
      data: {
        email: this.state.email,
        token: this.state.token
      },
      callback:(result)=>{
        if (result.err) {
          alert(result.err.message);
        } else {
          this.setState((state)=>{
            return {...state, submitted: true}
          });
        }
    }});
    return false;
  }

  render() {
    return (
      <div>
        <Navigation state="unactive" />
        <main className="user-authentication">
          {this.state.submitted ? (
            <div className="modal single">
              <h2>You will no longer receive emails at {this.state.email}.</h2>
            </div>
          ) : (
            <div className="modal single">
              <h2>Prevent further emails</h2>
              <h4>
                If you have not signed up with Secure Finance you can prevent
                further emails to:{this.state.email} by clicking the link below:
              </h4>
              <form onSubmit={this.handleSubmit}>
                <button type="submit">Prevent Further Emails</button>
              </form>
            </div>
          )}
          ;
        </main>
      </div>
    );
}
}


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

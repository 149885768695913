import React from 'react';
import { reduxForm } from 'redux-form';
import QuestionWrapper from "../Components/QuestionWrapper";
import Navigation from "../Components/Navigation";
import CurrencyField from '../Components/CurrencyField';
import { connect } from 'react-redux';


import { createNumberBetween } from 'Components/Forms/validation';

const numberBetween = createNumberBetween(20000, 20000000, (low, high) => `Purchase price must be between $${low} and $${high}`);

const lessThenHalfLoanAmount =
(value, values) =>(value < values['user_meta-loanAmount'] / 0.95) ? 'Loan amount must be less than 95% of the property value' : null;

let PurchasePrice = function({ id, handleSubmit, pristine, maxQuestion, setQuestion, debounce, ...props }) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={debounce} id={id} currentQuestion={props.currentQuestion} setQuestion={setQuestion} onSubmit={handleSubmit} maxQuestion={maxQuestion}>
      <h1>What is the property value?</h1>
      <h2>If you’re not sure, an approximation is fine.</h2>
      <CurrencyField required validate={[numberBetween, lessThenHalfLoanAmount]} id='user_meta-purchasePrice' type='text' />
      <Navigation hidden={ pristine } name="Next Step →" />
    </QuestionWrapper>
  )
}

const stateToProps = state => {
  let loanAmount = null;
  if (state.form['onboarding-loan-amount']
  && state.form['onboarding-loan-amount'].values) {
    loanAmount = state.form['onboarding-loan-amount'].values['user_meta-loanAmount'];
  }
  return {
    loanAmount,
  }
  
}; 

PurchasePrice = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-purchase-price'  // a unique identifier for this form
})(PurchasePrice);

export default connect(stateToProps)(PurchasePrice);

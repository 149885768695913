import React, { Fragment } from 'react';
import GlobalDisclamer from './GlobalDisclaimer';

export default () => {
  return (
    <Fragment>
      <div className="references">
        <ol>
          <li>
            Calculators: All calculators make various assumptions and we make no guarantee as to the
            accuracy of these figures. Furthermore, lender policy may change at any time. Please get in
            touch for a personalised assessment.
          </li>
          <li>
            Comparison Rates: Any quoted comparison rate is only true for the example given and may
            not include all fees and charges. Different terms, loan amounts or fees may result in a different
            comparison rate. Comparison rates are based on a loan amount of $150,000 over a loan term of
            25 years.
          </li>
          <li>
            Borrowing Power: Results should only be used as a rough guide as we make a number of
            assumptions for these calculations. Lender credit policy may also change at any time. Please
            contact us for a personalised assessment.
          </li>
        </ol>
        
      </div>
      <GlobalDisclamer/>
    </Fragment>
  );
}
import React, { useState, useEffect } from 'react';
import styled, {css} from 'styled-components'
import { Field } from 'redux-form';
import ContentEditable from 'react-contenteditable';



const EditableContent = ({input, allowLineBreaks, onEnter, onKeyDown, richText, ...props}) => {

  const pasteAsPlainText = event => {
    event.preventDefault()
  
    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  };
  
  const onChange = event => {
    input.onChange(event.target.value);
  };

  const keyDown = event => {
    if (event.key === 'Enter') {
      if (!allowLineBreaks) event.preventDefault()
      onEnter && onEnter(event);
    }
    
    if (richText) richTextKeyDown(event);

    if (onKeyDown) onKeyDown(event);
  }

  const richTextKeyDown = e => {
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  const insertUnorderedList = () => {
    document.execCommand('insertUnorderedList');
  }

  const insertOrderedList = () => {
    document.execCommand('insertOrderedList');;
  }

  if (richText) return (
    <Container>
      <ContentEditable
        html={input.value}
        {...props}
        {...input}
        onChange={onChange}
        onPaste={pasteAsPlainText}
        onKeyDown={keyDown}
      />
      <OrderedButton onClick={() => insertOrderedList()}/>
      <UnorderedButton onClick={() => insertUnorderedList()}/>
    </Container>
  );

  return (
    <ContentEditable
      html={input.value}
      {...props}
      {...input}
      onChange={onChange}
      onPaste={pasteAsPlainText}
      onKeyDown={keyDown}
    />
  );
}

export default props => (
  <Field component={EditableContent} {...props}/>
)

const Container = styled.div`
  position: relative;
`;

const ButtonBase = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: solid 1px #d8dce7;
  background-color: white;
  cursor: pointer;
`;

const OrderedButton = styled(ButtonBase)`
  background-image: url(${require('img/button/ordered_list.png')});
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: -35px;
  left: 0px;
`;

const UnorderedButton = styled(ButtonBase)`
  background-image: url(${require('img/button/unordered_list.png')});
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: -35px;
  left: 36px;
`;
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { requestLogin } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import { push } from 'connected-react-router';

function mapStateToProps(state) {
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    push
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import React from "react"; import { Component } from "react";
import styled from 'styled-components';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';

import pngSuccess from 'img/page_illustrations/success.png'
import { TextArea as TextAreaBase, FormContainer, Input } from "Components/Forms";
import { emailService } from 'modules'; 

import { lighten } from "polished";

class ProceedThankYou extends Component {
  
  

  render() {

    if (this.props.currentModal === "proceedThankYou") {
    
    const brokerName = (this.props.user.proposalBroker || {}).name || 'We';
    return (
      <div className="modal-content">
        <Container>
          <ImgSuccess />
          <Heading>Thank You!</Heading>
          <Paragraph>
            {brokerName} will be notified of your agreement to move forward and 
            will be in touch shortly with next steps from here. We look forward to 
            providing you with the premium service we are known for.
          </Paragraph>

          <BigButton onClick={() => this.props.closeModal()}>Okay</BigButton>
        </Container>    
        
        
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default reduxForm({ form: 'next-step' })(connect(stateToProps)(ProceedThankYou));

const Container = styled.div`
  padding: 0 80px;

  @media (max-width: 800px) {
    padding: 0 20px;
  }

`;

const ImgSuccess = styled.img.attrs({
  src: pngSuccess,
})`
  width: 100%;
  object-fit: contain;
  margin-bottom: 46px;
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Paragraph = styled.p`
  color: #575e6c !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-bottom: 50px !important;
`;

const BigButton = styled.button`
  border: none !important;
  width: 100% !important;
  max-width: 380px !important;
  height: 46px !important;
  border-radius: 4px !important;
  background: #2291ff !important;
  color: #ffffff !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out; 
  margin-bottom: 20px;

  /* @media (max-width: 400px) {
    width: 100% !important;
  } */

  &:hover {
    background-color: ${lighten(0.05, '#2291FF')} !important;
  }
`; 
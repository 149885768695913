import { takeEvery, call, put } from 'redux-saga/effects';

import {
  logout,
  signup,
  login,
  auth,
  userPatch,
  userPatchDebounce,
  shortlistRemove,
  shortlistCreate,
  applicationCreate,
  applicationPatch,
  addProductToApplication,
  removeProductFromApplication,
  forgotPassword,
  resetPassword,
  setAccountAbused,
  tokenLogin,
  uploadFile,
  patchFile,
  removeFile,
  oauthMerge,
  secureLink,
  emailVerification,
  sendValidationEmail,
  getSelf,
  createReferral,
  addNoteToReferral,
 } from './api';

import {
  patchEnded,
  receiveAuth,
  receiveLogout,
  requestAuth,
  receiveAddToShortlist,
  receiveRemoveFromShortlist,
  receiveNewApplication,
  receivePatchApplication,
  receivePatchUser,
  receiveAddProductToApplication,
  receiveRemoveProductFromApplication,
  receiveCreateFile,
  receivePatchFile,
  receiveRemoveFile,
  receiveSelf,
  receiveCreateReferral,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_SIGNUP,
  REQUEST_AUTH,
  REQUEST_PATCH_USER,
  REMOVE_FROM_SHORTLIST,
  ADD_TO_SHORTLIST,
  REQUEST_NEW_APPLICATION,
  REQUEST_PATCH_APPLICATION,
  REQUEST_ADD_PRODUCT_TO_APPLICATION,
  REQUEST_FORGOT_PASSWORD,
  REQUEST_PASSWORD_RESET,
  REQUEST_SET_ACCOUNT_ABUSED,
  REQUEST_TOKEN_LOGIN,
  REQUEST_REMOVE_PRODUCT_FROM_APPLICATION,
  REQUEST_FILE_UPLOAD,
  REQUEST_PATCH_FILE,
  REQUEST_REMOVE_FILE,
  REQUEST_OAUTH_MERGE,
  REQUEST_SECURE_LINK,
  REQUEST_EMAIL_VERIFICATION,
  REQUEST_VALIDATION_EMAIL,
  REQUEST_SELF,
  REQUEST_CREATE_REFERRAL,
  ADD_NOTE_TO_REFERRAL
} from './actions';


function* callLogin({ payload: { data, callback } }) {
  let result = yield call(login, data);
  if (!result.accessToken) {
    if (document.getElementById("loading")) {
      document.getElementById("loading").classList.remove("show");
    }
    
    callback ({
      success: false,
      message: "Invalid username or password"
    });
    return;
  }
  yield put(requestAuth((user)=>{
    callback ({
      success: !user.error,
      errorMessage: user.error && user.error.message
    });
  }));
}

export function* loginSaga() {
  yield takeEvery(REQUEST_LOGIN, callLogin);
}

function* callSignup({ payload: { callback, data } }) {
  console.log('STARTED');
  let result = yield call(signup, data);
  console.log('result');
  console.log(result);
  if (!result.accessToken) {
    callback({success: false, signedUp: result.data.isVerified, hasPassword: result.data.hasPassword});
  } else {
    yield put(requestAuth(()=>callback({success: true})));
  }
}

export function* signupSaga() {
  yield takeEvery(REQUEST_SIGNUP, callSignup);
}

function* callLogout({payload}) {
  yield call(logout);
  window.location = (typeof payload) == 'string' ? payload : window.location.origin + "/login";
}

export function* logoutSaga() {
  yield takeEvery(REQUEST_LOGOUT, callLogout);
}

function* callAuth({ payload }) {
  const user = yield call(auth);
  if (!user.error) {
    yield put(receiveAuth(user));
  }
  if (typeof(payload) === 'object') {
    if (user.error) {
      payload.onFail && payload.onFail(user.error);
    } else {
      payload.onSuccess && payload.onSuccess(user);
    }
  } else {
    payload(user);
  }
}

export function* authSaga() {
  yield takeEvery(REQUEST_AUTH, callAuth);
}

function* callGetSelf({ payload }) {
  const user = yield call(getSelf);
  if (!user.error) {
    yield put(receiveSelf(user));
  }
  if (payload) payload(user);
}

export function* getSelfSage() {
  yield takeEvery(REQUEST_SELF, callGetSelf);
}

function* callPatchUser({ payload }) {
  let latePatchData = {};
  try {
    if (payload.data.email || payload.data.password || payload.data.googleId || payload.data.facebookId || payload.data.phoneNumber) {
      latePatchData = payload.data;
      latePatchData.newEmail = payload.data.email;
      if (payload.data.password) {
        latePatchData.hasPassword = true;
      }
      delete latePatchData.googleId;
      delete latePatchData.facebookId;
    } else {
      
      if (payload.data.meta && payload.data.meta.finished_onboarding1) {
        if (!latePatchData.meta) {
          latePatchData.meta = {};
        }
        latePatchData.meta.finished_onboarding1 = payload.data.meta.finished_onboarding1;
        delete payload.data.meta.finished_onboarding1;
      }
      yield put(receivePatchUser({...payload.data}));
    }

    payload.callbackEarly && payload.callbackEarly();
    if (payload.debounce) {
      delete payload.debounce;
      yield call(userPatchDebounce, payload);
      yield put(patchEnded());
      return;
    }
    
    const value = yield call(userPatch, payload);
    if (value.error) {
      payload.callback && payload.callback(value);
      yield put(patchEnded());
      return;
    }
  
    yield put(receivePatchUser({...latePatchData, 
      email: value.email, 
      clientStage: value.clientStage || undefined, 
      nsrValues: value.nsrValues, 
      borrowingPower: value.borrowingPower}));
    payload.callback && payload.callback(value);
    yield put(patchEnded());
  } catch (err) {
    yield put(patchEnded());
  }
  
}

export function* userPatchSaga() {
  console.log("userPatchSaga");
  yield takeEvery(REQUEST_PATCH_USER, callPatchUser);
}

function* callShortlistAdd({ payload }) {
  const { id, callback } = payload
  yield call(shortlistCreate, {product_id : id});
  yield put(receiveAddToShortlist(payload));
  if (callback) callback();
}

export function* shortlistAddSaga() {
  yield takeEvery(ADD_TO_SHORTLIST, callShortlistAdd);
}

function* callShortlistRemove({ payload }) {
  const { data, callback } = payload;
  const item = yield call(shortlistRemove, data);
  yield put(receiveRemoveFromShortlist(item));
  if (callback) callback();
}

export function* shortlistRemoveSaga() {
  yield takeEvery(REMOVE_FROM_SHORTLIST, callShortlistRemove);
}

function* callNewApplication({ payload: { data, callback }}) {
  const application = yield call(applicationCreate, data);
  yield put(receiveNewApplication({ ...data, ...application }));
  if (callback) callback({ ...data, ...application });
}

export function* applicationAddSaga() {
  yield takeEvery(REQUEST_NEW_APPLICATION, callNewApplication);
}

function* callPatchApplication({ payload: { data, callback } }) {
  yield call(applicationPatch, {});
  yield put(receivePatchApplication(data));
  callback();
}

export function* applicationPatchSaga() {
  yield takeEvery(REQUEST_PATCH_APPLICATION, callPatchApplication);
}

function* callAddProductToApplication({ payload }) {
  console.log("test");
  yield put(receiveAddProductToApplication(payload));
  yield call(addProductToApplication, {applicationId: payload.applicationId, product_id : payload.product.id});  
}

export function* addProductToApplicationSaga() {
  yield takeEvery(REQUEST_ADD_PRODUCT_TO_APPLICATION, callAddProductToApplication);
}

function* callRemoveProductFromApplication({ payload }) {
  console.log("test");
  yield put(receiveRemoveProductFromApplication(payload));
  console.log("test2");
  yield call(removeProductFromApplication, payload);
  console.log("test3");
}

export function* removeProductFromApplicationSaga() {
  yield takeEvery(REQUEST_REMOVE_PRODUCT_FROM_APPLICATION, callRemoveProductFromApplication);
}

function* callForgotPassword({ payload }) {
  console.log("callForgotPassword");
  let message = yield call(forgotPassword, { email: payload.email });
  console.log(message);
  payload.callback(message);
}

export function* forgotPasswordSaga() {
  yield takeEvery(REQUEST_FORGOT_PASSWORD, callForgotPassword);
}

function* callResetPassword({ payload }) {
  let result = yield call(resetPassword, payload.data);
  payload.callback(result);
}

export function* passwordResetSaga() {
  yield takeEvery(REQUEST_PASSWORD_RESET, callResetPassword);
}

function* callSetAccountAbused({ payload }) {
  let result = yield call(setAccountAbused, payload.data);
  payload.callback(result);
}

export function* setAccountAbusedSaga() {
  yield takeEvery(REQUEST_SET_ACCOUNT_ABUSED, callSetAccountAbused);
}

function* callTokenLogin({ payload }) {
  let result = yield call(tokenLogin, payload);
  if (!result.err) {    
    yield put(requestAuth(()=>{}));
    yield put(receivePatchUser({...result, isVerified: 1, verifyFinished: true}));
  } else {
    yield put(receivePatchUser({verifyFinished: true}));
  }
  yield put(receivePatchUser({verifyFinished: true}));
  payload.callback(result);
}

export function* tokenLoginSaga() {
  yield takeEvery(REQUEST_TOKEN_LOGIN, callTokenLogin);
}

function* callFileUpload({ payload }) {
  console.log("Uploading: " + payload.file);
  yield put(receiveCreateFile({
    name: payload.file.name,
    type: payload.type,
    status: 0,
    percentUploaded: 0
  }));

  yield call(uploadFile, {...payload});    
}

export function* fileUploadSaga() {
  yield takeEvery(REQUEST_FILE_UPLOAD, callFileUpload);
}

function* callPatchFile({ payload }) {
  yield put(receivePatchFile(payload.data));
  const value = yield call(patchFile, payload);
  if (value.error) {
    console.log("FILE PATCH ERROR:" + value.error);
    return;
  }
}

export function* filePatchSaga() {
  yield takeEvery(REQUEST_PATCH_FILE, callPatchFile);
}

function* callRemoveFile({ payload }) {
  yield put(receiveRemoveFile(payload));
  const value = yield call(removeFile, payload);
  if (value.error) {
    console.log("FILE REMOVE ERROR:" + value.error);
    return;
  }
}

export function* fileRemoveSaga() {
  yield takeEvery(REQUEST_REMOVE_FILE, callRemoveFile);
}


function* callOauthMerge({ payload }) {
  const result = yield call(oauthMerge, payload);
  yield put(receivePatchUser(result));
  payload.callback && payload.callback(result);
}

export function* oauthMergeSaga() {
  yield takeEvery(REQUEST_OAUTH_MERGE, callOauthMerge);
}

function* callSecureLink({ payload }) {
  const result = yield call(secureLink, payload);
  payload.callback(result);
}

export function* secureLinkSaga() {
  yield takeEvery(REQUEST_SECURE_LINK, callSecureLink);
}

function* callEmailVerification({ payload }) {
  const result = yield call(emailVerification, payload);
  if (!result.err) {    
    yield put(receivePatchUser({...result, isVerified: 1, verifyFinished: true}));
  } else {
    yield put(receivePatchUser({verifyFinished: true}));
  }
  payload.callback(result);
}

export function* emailVerificationSaga() {
  yield takeEvery(REQUEST_EMAIL_VERIFICATION, callEmailVerification);
}

function* callSendValidationEmail({ payload }) {
  const result = yield call(sendValidationEmail, payload);
  payload.callback(result);
}

export function* validationEmailSaga() {
  yield takeEvery(REQUEST_VALIDATION_EMAIL, callSendValidationEmail);
}

function* callCreateReferral({ payload }) {
  const result = yield call(createReferral, payload);
  yield put(receiveCreateReferral({ 
    ...payload, 
    application: { 
      ...payload.application, 
      id: result.id, 
      notes: [] 
    } 
  }));
  payload.callback(result);
}

export function* createReferralSaga() {
  yield takeEvery(REQUEST_CREATE_REFERRAL, callCreateReferral);
}


function* callAddNote({ payload }) {
  const result = yield call(addNoteToReferral, payload);
  payload.callback(result);
}

export function* createAddNoteSaga() {
  yield takeEvery(ADD_NOTE_TO_REFERRAL, callAddNote);
}
import React from "react"; import { Component } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux';

import { FieldGroup, FormContainer } from "Components/Forms";
import { createUser, _userData } from "utils/createUser"

class NewEmail extends Component {

  signUp = ()=>{
    createUser({'email': this.props.email}, false).catch(() => { throw new Error("Error creating user"); });
  }

  render() {
    if (this.props.currentModal === "newEmail") {
    return (
      <div className="modal-content">
        <h1>Enter a New E-Mail Address</h1>
        <p>
          Please enter an alternate e-mail address below
          <br />
          for your new Secure Finance account.
        </p>
        <FormContainer onSubmit={this.props.handleSubmit}>
          <FieldGroup
            required
            id="email"
            width="large"
            position="right"
            type="text"
            placeholder="Enter email address"
          />
        </FormContainer>
        <div className="modal-navigation">
          <button
            onClick={() => this.props.openModal("loginAlreadyHaveAccount")}
            className="double left button-5"
          >
            Cancel
          </button>
          <button
            onClick={() => this.signUp()}
            className="double right button-1"
          >
            Submit
          </button>
        </div>
      </div>
    ); } else {
      return "";
    }
  }
}

NewEmail = reduxForm({
  form: 'newEmail', 
})(NewEmail);

const selector = formValueSelector('newEmail') // <-- same as form name
NewEmail = connect(
  state => {
    return {
      email:selector(state, 'email')
    }
  }
)(NewEmail);


export default NewEmail;
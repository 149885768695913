import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import LoginModal from "./Login";
import CheckEligibilityHelpTimer from "./CheckEligibilityHelpTimer";
import CheckEligibilityDelete from "./CheckEligibilityDelete";
import Signup from "./Signup";
import Signin from "./Signin";
import CheckEligibilityOpen from "./CheckEligibilityOpen";
import CheckEligibilityHelp from "./CheckEligibilityHelp";
import CheckEligibilityHelpCallback from "./CheckEligibilityHelpCallback";
import CheckEligibilityView from "./CheckEligibilityView";
// import CheckEligibilityUnfinished from "./CheckEligibilityUnfinished";
import CheckEligibilityInitial from "./CheckEligibilityInitial";
// import ContactGetAdvice from "./ContactGetAdvice";
import CheckEligibilityHowDoesThisWork from "./CheckEligibilityHowDoesThisWork";
import LoginAlreadyHaveAccount from "./LoginAlreadyHaveAccount";
import LoginStartedProcess from "./LoginStartedProcess";
import CheckEligibilityFull from "./CheckEligibilityFull";
import RefinanceAnalysisModal from "./RefinanceAnalysisModal";
import SecureLink from "./SecureLink";
import RefinanceAnalysisStart from "./RefinanceAnalysisStart";
import RefinanceAnalysisDetails from "./RefinanceAnalysisDetails";
import ShortListView from "./ShortListView";
import RequestUpdate from "./RequestUpdate";
import CheckEligibilityFormResetNotification from "./CheckEligibilityFormResetNotification";

import ProposalRebate from "./ProposalRebate";
import LenderCashback from './LenderCashback';
import LoanbaseCashback from './LoanbaseCashback';
// import GetAdvice from "./GetAdvice";
import NewEmail from "./NewEmail";
import LoginAccountAbused from "./LoginAccountAbused";
import SignUpFinish from "./SignUpFinish";
import ForgetPassword from "./ForgetPassword";
import AdditionalLoanProducts from "./AdditionalLoanProducts";
// import ReactGA from 'react-ga'

import ShortListFilterView from "./ShortListFilterView";
import ConnectionError from "./ConnectionError";
import SMSVerification from "./SMSVerification";
import RoleRateOverride from "./RoleRateOverride"

import Onboarding from "./Onboarding";

import OffsetTooltip from "./OffsetTooltip";
import CurrentLoanDetails from "./CurrentLoanDetails";
import NextStep from './NextStep';
import ProceedThankYou from './ProceedThankYou';
import LoanbaseTrailRebate from './LoanbaseTrailRebate';
import LendingOverviewOptions from './LendingOverviewOptions';
import ObjectivesList from './ObjectivesList';
import IntroModal from './IntroLetterSelection';
import SelectTermIO from './SelectTermIO';
import ReferralMoreInfo from './ReferralMoreInfo';

const $ = window.$;

class Modal extends Component {

  isModalShown(user, currentModal) {
    // Onboarding Modal
    if ( user.phoneNumber
      && !currentModal 
      && user.mobileVerificationRequired 
      && !user.isMobileVerified) {
      return true;
    }

    // SMS Verification Modal
    else if ( user.id 
      && user.meta 
      && (user.meta.entryType === 'main' || user.meta.entryType === 'modal') 
      && !user.phoneNumber && user.clientStage) {
      return true;
    }

    else {
      return false;
    }
  }

  componentWillMount() {
    $(window)
      .keydown(e => e.keyCode !== 27 || this.props.closeModal())
    $(window).mousedown(e => $(e.target).attr('class') !== 'modal-vertical-center' || this.props.closeModal());
    
    if (!this.props.browser.greaterThan.w645) {
      if (!this.isModalShown(this.props.user, this.props.currentModal) ) {
        // console.log("Active - 1");
        window.Intercom("boot", {
          app_id: "t2b7w54a"
        });
      } else {
        // console.log("Not active - 2");
        window.Intercom('shutdown');
      }
    } else {
      window.Intercom("boot", {
        app_id: "t2b7w54a"
      });
      // console.log("Active - 3");
    }

  }

  componentDidUpdate() {
    //console.log("Modal updated")
    if (!this.props.browser.greaterThan.w645) {
      if (!this.isModalShown(this.props.user, this.props.currentModal) ) {
        // console.log("Active - 1");
        window.Intercom("boot", {
          app_id: "t2b7w54a"
        });
      } else {
        // console.log("Not active - 2");
        window.Intercom('shutdown');
      }
    } else {
      window.Intercom("boot", {
        app_id: "t2b7w54a"
      });
      // console.log("Active - 3");
    }
  }

  render() {
    const { user } = this.props;
    var page = this.props.currentModal || "";

    // ReactGA.modalview(page.replace(/[A-Z]/g, m => "-" + m.toLowerCase()), ['loanbaseentire']);

    if (user.id && user.meta && (user.meta.entryType === 'main' || user.meta.entryType === 'modal') && !user.phoneNumber && user.clientStage) {
      return (
        <div ref={ref => this.ref = ref} className='overlay-modal smsverification'>
          <div className='modal-container'>
            <div className='modal-scroll'>
              <div className='modal-vertical-center'>
                <Onboarding />
              </div>
            </div>
          </div>
        </div>
      )
    }

    //console.log('Modal Stats:', this.props.currentModal, this.props);
    if (user.phoneNumber
      && !this.props.currentModal 
      && this.props.user.mobileVerificationRequired 
      && !this.props.user.isMobileVerified) {
      return (
        <div ref={ref => this.ref = ref} className='overlay-modal smsverification border'>
          <div className='modal-container'>
            <div className='modal-scroll'>
              <div className='modal-vertical-center'>
                <div className='modal-box additional-info smsverification'>
                  <SMSVerification />
                  {AdditionalInformation('smsVerification')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }


    if (this.props.currentModal) {
      if (this.props.type === "initial"){
        return(
          <div ref={ref => this.ref = ref} className={modalType(this.props.currentModal)}>
            <div className='modal-container'>
              <div className='modal-scroll'>
                <div className='modal-vertical-center'>
                  <div className={modalBoxClass(this.props.currentModal) + " " + this.props.currentModal.toLowerCase()}>
                    <button className="closeButton" onClick={() => this.props.closeModal()}></button>
                    <CheckEligibilityHelp />
                    <LoginAlreadyHaveAccount />
                    <LoginStartedProcess />
                    <SecureLink />
                    <NewEmail />
                    {AdditionalInformation(this.props.currentModal)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      else {
        return (
          <div ref={ref => this.ref = ref} className={modalType(this.props.currentModal)}>
            <div className='modal-container'>
              <div className='modal-scroll'>
                <div className='modal-vertical-center'>
                  <div className={modalBoxClass(this.props.currentModal) + " " +this.props.currentModal.toLowerCase()}>
                    <button className="closeButton" onClick={()=>this.props.closeModal()}></button>
                    <LoginModal />
                    <CheckEligibilityHelpTimer />
                    <CheckEligibilityDelete />
                    <Signup />
                    <Signin />
                    <CheckEligibilityOpen />
                    <CheckEligibilityHelp />
                    <CheckEligibilityHelpCallback/>
                    <CheckEligibilityView />

                    {/* <CheckEligibilityUnfinished /> */}
                    <ShortListFilterView />
                    <CheckEligibilityInitial />
                    {/* <ContactGetAdvice /> */}
                    <CheckEligibilityHowDoesThisWork />
                    <CheckEligibilityFormResetNotification/>
                    <LoginAlreadyHaveAccount />
                    <LoginStartedProcess />
                    <CheckEligibilityFull />
                    <RefinanceAnalysisModal />
                    <RefinanceAnalysisDetails />
                    <RefinanceAnalysisStart />
                    <RequestUpdate />
                    {/* <GetAdvice /> */}
                    <NewEmail />
                    <LoginAccountAbused />
                    <ShortListView />
                    <SignUpFinish />
                    <ForgetPassword />
                    <SecureLink />
                    {/* <SMSVerification /> */}

                    <AdditionalLoanProducts />
                    <OffsetTooltip />
                    <CurrentLoanDetails />
                    <RoleRateOverride />

                    <ProposalRebate />
                    <LenderCashback />
                    <LoanbaseCashback />
                    <LoanbaseTrailRebate />
                    <NextStep />
                    <ProceedThankYou />
                    <LendingOverviewOptions />
                    <ObjectivesList />
                    <IntroModal />
                    <SelectTermIO />

                    <ReferralMoreInfo />
                    {AdditionalInformation(this.props.currentModal, this)}

                  </div>
                </div>
              </div>
              
            </div>
          </div>
        );
      }
    }
    if (this.props.connectionError) {
      return (
        <div className="overlay-modal">
          <div className='modal-container'>
            <div className='modal-scroll'>
              <div className='modal-vertical-center'>
                <div className="modal-box backendDead">
                  <ConnectionError />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return "";
  }

  
}

export default connect(({user}) => ({user})) (Modal);

function AdditionalInformation(value, that){
  switch (value) {
    case "checkEligibilityDelete": return <div className="info"><span className="warning"><span className="link" onClick={()=>that.props.openModal("checkEligibilityHelp")}>Get in touch</span> with us if you need assistance. We’re here to help!</span></div>;
    case "signup-2": return <div className="info"><span className="bank">Our platform adheres to stringent security standards, and you are covered by a best practice insurance policy.</span></div>;
    case "signup-3": return <div className="info"><span className="bank">Our platform adheres to stringent security standards, and you are covered by a best practice insurance policy.</span></div>;
    case "smsVerification": return <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>;
    case "onboarding": return <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>;
    case "nextStep": return <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>;
    case "proceedThankYou": return <div className="info"><span className="bank">Your information is protected by 256-bit AES Encryption and covered by an industry best practice insurance policy.</span></div>;
    default: return "";
  }
}

function modalBoxClass(value){
  switch (value) {
    case "checkEligibilityAdd": return "modal-bottom"
    case "checkEligibilityDelete": return "modal-box additional-info";
    case "signup-2": return "modal-box additional-info";
    case "signup-3": return "modal-box additional-info";
    case "smsVerification": return "modal-box additional-info";
    case "nextStep": return "modal-box additional-info next-step";
    case "proceedThankYou": return "modal-box additional-info next-step";
    default: return "modal-box";
  }
}

function modalType(value){
  if (value === "checkEligibilityHelp") {
    return "overlay-modal border";
  } else {
    switch (value) {
      case "checkEligibilityAdd": return "bottom-modal";
      default: return "overlay-modal";
    }
  }
}

export default function getInitialValuesObject(state) {
    let user = state.user;
    let initialUserValues = Object.keys(user).reduce((a, key)=>({...a, ["user-" + key]: user[key]}), {});
    let meta = user.meta;
    let initialMetaValues = {};
    if (meta && Object.keys(meta).length) {
        initialMetaValues = Object.keys(meta).reduce((a, key)=>({...a, ["user_meta-" + key]: meta[key]}), {});
    }
    delete initialMetaValues["user_meta-calculators"];
    let calculators = user.meta?user.meta.calculators:{};
    let initialCalculatorsValues = {}
    if (Object.keys(calculators || {}).length) {
      initialCalculatorsValues = Object.keys(calculators).reduce((a, key)=>({...a, ["calculators-" + key]: calculators[key]}), {});
    }
    let applications = user.applications;
    let initialApplicationValues = {};
    let initialApplicationMetaValues = {};
    if (applications && applications.length && Object.keys(applications[0]).length) {
        initialApplicationValues = Object.keys(applications[0]).reduce((a, key)=>({...a, ["application-" + key]: applications[0][key]}), {});
    
        let applicationMeta = applications[0].meta;        
        if (applicationMeta && Object.keys(applicationMeta).length) {
            initialApplicationMetaValues = Object.keys(applicationMeta).reduce((a, key)=>({...a, ["application_meta-" + key]: applicationMeta[key]}), {});
        }
    }
    let partners = user.partners;
    let initialPartnerValues = {};
    let initialPartnerMetaValues = {};
    if (partners && partners.length && Object.keys(partners[0]).length) {
        initialPartnerValues = Object.keys(partners[0]).reduce((a, key)=>({...a, ["user_partner-" + key]: partners[0][key]}), {});
    
        let partnerMeta = partners[0].meta;        
        if (partnerMeta && Object.keys(partnerMeta).length) {
            initialPartnerMetaValues = Object.keys(partnerMeta).reduce((a, key)=>({...a, ["user_partner_meta-" + key]: partnerMeta[key]}), {});
        }
    }

    delete initialMetaValues["user_meta-sortField"];
    delete initialMetaValues["user_meta-sortDirection"];

    return {
        ...initialUserValues, 
        ...initialMetaValues, 
        ...initialApplicationValues, 
        ...initialApplicationMetaValues,
        ...initialPartnerValues,
        ...initialPartnerMetaValues,
        ...initialCalculatorsValues,
    };
}




const calculateStampDuty = values => {
  nullCheck(values.state, 'state');
  return {
    registration: calculateRegistrationFee(values) || 0,
    transfer: calculateTransferFee(values) || 0,
    stampDuty: calculateStampDutyFee(values) || 0,
    firstHomeGrant: calculateFirstHomeGrant(values) || 0,
  }  
};

export default calculateStampDuty;

//// PropertyUse
export const OWNER_OCCUPIED = 'OWNER_OCCUPIED';
export const INVESTMENT = 'INVESTMENT';


//// PropertyType 
export const VACANT_BLOCK = 'VACANT_BLOCK';
export const NEW_HOME = 'NEW_HOME';
export const ESTABLISHED_HOME = 'ESTABLISHED_HOME';

//// State 
export const ACT ='ACT';
export const NSW ='NSW';
export const NT ='NT';
export const QLD ='QLD';
export const SA ='SA';
export const TAS ='TAS';
export const VIC ='VIC';
export const WA ='WA';

export const propertyTypeOptions = [
  {text: 'Established Home', value: ESTABLISHED_HOME},
  {text: 'New Home', value: NEW_HOME},
  {text: 'Vacant Lot', value: VACANT_BLOCK}
];

export const stateOptions = [
  {text: 'New South Wales', value: NSW},
  {text: 'Victoria', value: VIC},
  {text: 'Queensland', value: QLD},
  {text: 'Western Australia', value: WA},
  {text: 'Tasmania', value: TAS},
  {text: 'South Australia', value: SA},
  {text: 'Northern Territory', value: NT},
  {text: 'Australian Capital Territory', value: ACT},
];


const nullCheck = (value, name) => {
  if (value == null) throw new Error(`Stamp Duty Calc Error: required value '${name}' is not provided.`);
}


//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
///////// Registration Fee
const calculateRegistrationFee = ({ state }) => ({
  [ACT]: 145.00,
  [NSW]: 142.00,
  [NT]: 145.00,
  [QLD]: 142.00,
  [SA]: 163.00,
  [TAS]: 135.09,
  [VIC]: 116.80,
  [WA]: 171.20,
}[state]);

//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
///////// Transfer Fee
const calculateTransferFee = ({ state, value }) => ({
  [ACT]: () => 386.00,
  [NSW]: () => 142.00,
  [NT]: () => 145.00,
  [QLD]: () => 142.00,
  [SA]: transferFeeSA,
  [TAS]: () => 206.98,
  [VIC]: transferFeeVIC,
  [WA]: transferFeeWA,
}[state](value));

const transferFeeSA = value => {
  nullCheck(value, 'value');
  if (value <= 5000)  return 136.00;
  if (value <= 20000) return 182.00;
  if (value <= 40000) return 199.00;
  if (value <= 50000) return 280.00;
  return 280.00 + Math.ceil((value - 50000) / 10000) * 82.50;
}

const transferFeeVIC = value => {
  nullCheck(value, 'value'); 
  return 96.10 + Math.ceil(value / 1000) * 2.34;
}

const transferFeeWA = value => {
  nullCheck(value, 'value');
  if (value <= 85000)  return 171.20;
  if (value <= 120000) return 181.20;
  if (value <= 200000) return 201.20;
  return 201.20 + Math.ceil((value - 200000) / 100000) * 20.00;
}

//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
///////// Stamp Duty
const calculateStampDutyFee = values => ({
  [ACT]: stampDutyACT, 
  [NSW]: stampDutyNSW,
  [NT]: stampDutyNT,
  [QLD]: stampDutyQLD,
  [SA]: stampDutySA,
  [TAS]: stampDutyTAS,
  [VIC]: stampDutyVIC,
  [WA]: stampDutyWA,
}[values.state](values));

/////////
///////// ACT
const stampDutyACT = values => {
  const { use, value, isPensioner, isFirstHomeBuyer } = values;
  nullCheck(use, 'use'); nullCheck(value, 'value');
  if (use === OWNER_OCCUPIED) {
    if (isPensioner) {
      return pensionerRateACT(values);
    }
    if (isFirstHomeBuyer && passesIncomeTestACT(values)) {
      return firstHomeRateACT(values);
    }
  }
  return generalRateACT(value)
};

const generalRateACT = value => {
  nullCheck(value, 'value');
  if (value <= 200000)  return Math.max(20, (value / 100)                 * 1.30);
  if (value <= 300000)  return 2600  + Math.ceil((value - 200000) / 100)  * 2.30;
  if (value <= 500000)  return 4900  + Math.ceil((value - 300000) / 100)  * 3.60;
  if (value <= 750000)  return 12100 + Math.ceil((value - 500000) / 100)  * 4.56;
  if (value <= 1000000) return 23500 + Math.ceil((value - 750000) / 100)  * 6.10;
  if (value <= 1455000) return 38750 + Math.ceil((value - 1000000) / 100) * 6.60;
  return (value / 100) * 4.73;
};

const pensionerRateACT = ({ type, value }) => {
  nullCheck(type, 'type'); nullCheck(value, 'value');
  if (type === VACANT_BLOCK) {
    if (value <= 361700) return 0;
    if (value <= 434400) return Math.ceil((value - 361700) / 100) * 13.35; 
  } else { //ie NEW_HOME or ESTABLISHED_HOME
    if (value <= 680500) return 0;
    if (value <= 894900) return Math.ceil((value - 680500) / 100) * 15.05; 
  }
  return generalRateACT(value);
};

const firstHomeRateACT = ({ type, value }) => {
  nullCheck(type, 'type'); nullCheck(value, 'value');
  if (type === VACANT_BLOCK) {
    if (value <= 281200) return 0;
    if (value <= 329400) return Math.ceil((value - 281200) / 100) * 12.30; 
  }
  if (type === NEW_HOME) {
    if (value <= 470000) return 0;
    if (value <= 606900) return Math.ceil((value - 470000) / 100) * 12.35; 
  }
  return generalRateACT(value);
};

const passesIncomeTestACT = ({ income, dependents }) => {
  nullCheck(income, 'income'); nullCheck(dependents, 'dependents');
  if (dependents < 5) return income <= (160000 + 3300 * dependents);
  else return income < 176650;
};

/////////
///////// NSW
const stampDutyNSW = values => {
  const { use, value, isForeign, isFirstHomeBuyer } = values;
  nullCheck(use, 'use'); nullCheck(value, 'value');
  if (isFirstHomeBuyer && !isForeign && use !== INVESTMENT)
    return firstHomeRateNSW(values);
  return generalRateNSW(value) + (isForeign ? (value * 0.08) : 0);
};

const generalRateNSW = value => {
  nullCheck(value, 'value');
  if (value <= 14000)   return Math.ceil(value / 100)                       * 1.25;
  if (value <= 30000)   return 174 + Math.ceil((value -    14000) / 100)    * 1.50;
  if (value <= 80000)   return 415 + Math.ceil((value -    30000) / 100)    * 1.75;
  if (value <= 300000)  return 1290 + Math.ceil((value -   80000) / 100)    * 3.50;
  if (value <= 1000000) return 8990 + Math.ceil((value -   300000) / 100)   * 4.50;
  if (value <= 3000000) return 40490 + Math.ceil((value -  1000000) / 100)  * 5.50;
  return                       150490 + Math.ceil((value - 3000000) / 100)  * 7.00;
};


const firstHomeRateNSW = ({ type, value }) => {
  nullCheck(type, 'type'); nullCheck(value, 'value');
  if (type === VACANT_BLOCK) {
    if (value <= 350000) return 0;
    if (value < 450000) return (value * 0.1574) - 55090; 
  } else { //ie NEW_HOME or ESTABLISHED_HOME
    if (value <= 650000) return 0;
    if (value < 800000) return (value * 0.21) - 136510; 
  }
  return generalRateNSW(value);
};

/////////
///////// NT
const stampDutyNT = ({ value }) => {
  nullCheck(value, 'value');
  if (value <= 525000) {
    const v = value / 1000;
    return (0.06571441 * v * v) + 15 * v 
  }
  if (value < 3000000) return value * 0.0495;
  if (value < 5000000) return value * 0.0575;
  return                      value * 0.0595;
};

/////////
///////// QLD
const stampDutyQLD = values => {
  const { use, type, value, isForeign, isFirstHomeBuyer } = values;
  nullCheck(use, 'use'); nullCheck(value, 'value');
  if (use === OWNER_OCCUPIED) {
    if (isFirstHomeBuyer && !isForeign) {
      nullCheck(type, 'type');
      if (type === VACANT_BLOCK) {
        return Math.max(0, invRateQLD(value) - vacantLotConcessionQLD(value));
      }
      //console.log(ooRateQLD(value), homeConcessionQLD(value));
      return Math.max(0, ooRateQLD(value) - homeConcessionQLD(value));
    } 
    if (type !== VACANT_BLOCK) 
      return ooRateQLD(value) + (isForeign ? value * 0.07 : 0);
  } 
  return invRateQLD(value) + (isForeign ? value * 0.07 : 0);
};

const invRateQLD = value => {
  nullCheck(value, 'value');
  if (value <= 5000)    return 0;
  if (value <= 75000)   return Math.ceil((value -           5000) / 100)    * 1.50;
  if (value <= 540000)  return 1050 +  Math.ceil((value -   75000) / 100)   * 3.50;
  if (value <= 1000000) return 17325 + Math.ceil((value -   540000) / 100)  * 4.50;
  return                       38025 + Math.ceil((value - 1000000) / 100)   * 5.75;
};

const ooRateQLD = value => {
  nullCheck(value, 'value');
  if (value <= 350000)  return Math.ceil(value / 100)                     * 1.00
  if (value <= 540000)  return 3500 +  Math.ceil((value - 350000) / 100)  * 3.50;
  if (value <= 1000000) return 10150 + Math.ceil((value - 540000) / 100)  * 4.50;
  return                       30850 + Math.ceil((value - 1000000) / 100) * 5.75;
};

const homeConcessionQLD = value => {
  nullCheck(value, 'value');
  if (value < 505000) return 8750;
  return Math.max(0, 8750 - Math.ceil((value + 0.01 - 505000) / 5000) * 875);
}

const vacantLotConcessionQLD = value => {
  nullCheck(value, 'value');
  if (value <= 250000) return Infinity; 
  if (value < 400000) return 7650 - Math.ceil((value + 0.01 - 250000) / 10000) * 475;
  return 0;
}


/////////
///////// NT
const stampDutySA = ({ value }) => {
  nullCheck(value, 'value');
  if (value <= 12000)  return Math.ceil(value / 100)                     * 1.00;
  if (value <= 30000)  return 120 + Math.ceil((value -    12000) / 100)  * 2.00;
  if (value <= 50000)  return 480 + Math.ceil((value -    30000) / 100)  * 3.00;
  if (value <= 100000) return 1080 + Math.ceil((value -   50000) / 100)  * 3.50;
  if (value <= 200000) return 2830 + Math.ceil((value -   100000) / 100) * 4.00;
  if (value <= 250000) return 6830 + Math.ceil((value -   200000) / 100) * 4.25;
  if (value <= 300000) return 8955 + Math.ceil((value -   250000) / 100) * 4.75;
  if (value <= 500000) return 11330 + Math.ceil((value -  300000) / 100) * 5.00;
  return                      21330 + Math.ceil((value -  500000) / 100) * 5.50;
};

/////////
///////// TAS
const stampDutyTAS = ({ use, type, value, isPensioner, isFirstHomeBuyer, isForeign })=> { 
  nullCheck(use, 'use'); nullCheck(type, 'type'); nullCheck(value, 'value');
  if (use === OWNER_OCCUPIED && !isForeign) {
    let multiplier = 1.0;
    if (isPensioner && type !== VACANT_BLOCK && value <= 400000) 
      multiplier -= 0.5;
    if (isFirstHomeBuyer && type === ESTABLISHED_HOME && value <= 400000) 
      multiplier -= 0.5
    return generalRateTAS(value) * multiplier;
  }
  return generalRateTAS(value) + (isForeign ? (value * 0.03) : 0);
};

const generalRateTAS = value => {
  nullCheck(value, 'value');
  if (value <= 3000)   return 50;
  if (value <= 25000)  return 50 + Math.ceil((value -    3000) / 100)   * 1.75;
  if (value <= 75000)  return 435 + Math.ceil((value -   25000) / 100)  * 2.25;
  if (value <= 200000) return 1560 + Math.ceil((value -  75000) / 100)  * 3.50;
  if (value <= 375000) return 5935 + Math.ceil((value -  200000) / 100) * 4.00;
  if (value <= 725000) return 12935 + Math.ceil((value - 375000) / 100) * 4.25;
  return                      27810 + Math.ceil((value - 725000) / 100) * 4.50;
};

/////////
///////// TAS
const stampDutyVIC = ({ use, value, isForeign, isFirstHomeBuyer }) => {
  nullCheck(use, 'use'); nullCheck(value, 'value');
  if (isFirstHomeBuyer && !isForeign && use !== INVESTMENT)
    return firstHomeRateVIC(value);
  if (use === INVESTMENT)
    return invRateVIC(value) + (isForeign ? (value * 0.07) : 0);
  return ooRateVIC(value) + (isForeign ? (value * 0.07) : 0);
};

const invRateVIC = value => {
  nullCheck(value, 'value');
  if (value <= 25000)  return value                     * 0.014;                      
  if (value <= 130000) return 350 + (value -  25000)    * 0.024;
  if (value <= 960000) return 2870 + (value - 130000)   * 0.06;
  return                      value                     * 0.055;
};

const ooRateVIC = value => {
  nullCheck(value, 'value');
  if (value <= 130000) return invRateVIC(value);      
  if (value <= 440000) return 2870 + (value - 130000)   * 0.05;
  if (value <= 550000) return 18370 + (value - 440000)  * 0.06;
  return                      invRateVIC(value);
};

const firstHomeRateVIC = value => {
  nullCheck(value, 'value');
  if (value <= 600000) return 0;
  if (value <= 750000) return (0.0000004 * value * value) - ((4093/15000) * value) + 19720;
  return invRateVIC(value);
};

/////////
///////// WA
const stampDutyWA = values => {
  const { use, value, isForeign, isFirstHomeBuyer } = values;
  nullCheck(use, 'use'); nullCheck(value, 'value');
  if (isFirstHomeBuyer && !isForeign && use !== INVESTMENT)
    return firstHomeRateWA(values);
  if (use === INVESTMENT)
    return invRateWA(value) + (isForeign ? (value * 0.07) : 0);
  return ooRateWA(value) + (isForeign ? (value * 0.07) : 0);
};

const invRateWA = value => {
  nullCheck(value, 'value');
  if (value <= 80000)  return value * 0.019;
  if (value <= 100000) return 1520 + Math.ceil((value -  80000) / 100)  * 2.85;
  if (value <= 250000) return 2090 + Math.ceil((value -  100000) / 100) * 3.80;
  if (value <= 500000) return 7790 + Math.ceil((value -  250000) / 100) * 4.75;
  return                      19665 + Math.ceil((value - 500000) / 100) * 5.15;
};

const ooRateWA = value => {
  nullCheck(value, 'value');
  if (value <= 120000) return value * 0.019;
  if (value <= 150000) return 2280 + Math.ceil((value -  120000) / 100)  * 2.85;
  if (value <= 360000) return 3135 + Math.ceil((value -  150000) / 100) * 3.80;
  if (value <= 725000) return 11115 + Math.ceil((value - 360000) / 100) * 4.75;
  return                      28453 + Math.ceil((value - 725000) / 100) * 5.15;
};

const firstHomeRateWA = ({ type, value }) => {
  nullCheck(value, 'value'); nullCheck(type, 'type');
  if (type === VACANT_BLOCK) {
    if (value <= 300000) return 0;
    if (value < 400000)  return Math.ceil((value - 300000) / 100) * 13.10;
  } else { //ie NEW_HOME or ESTABLISHED_HOME
    if (value <= 430000) return 0;
    if (value < 530000)  return Math.ceil((value - 430000) / 100) * 19.19;
  }
  return ooRateWA(value);
}


//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
///////// First Home Grant
const calculateFirstHomeGrant = values => {
  const { isFirstHomeBuyer, isForeign, type, state, use, value } = values;
  nullCheck(type, 'type'); nullCheck(use, 'use'); nullCheck(state, 'state'); nullCheck(value, 'value');
  if (!isFirstHomeBuyer || use !== OWNER_OCCUPIED || isForeign) return 0;
  return {
    [ACT]: 7000,
    [NSW]: ((type === VACANT_BLOCK && value <= 750000) || (type === NEW_HOME && value <= 600000)) ? 10000 : 0,
    [NT]: type !== ESTABLISHED_HOME ? 26000 : 0,
    [QLD]: (type !== ESTABLISHED_HOME && value <= 750000) ? 15000 : 0,
    [SA]: (type !== ESTABLISHED_HOME) ? 15000 : 0,
    [TAS]: (type !== ESTABLISHED_HOME) ? 20000 : 0,
    [VIC]: (type !== ESTABLISHED_HOME && value <= 750000) ? 10000 : 0,
    [WA]: (value <= 750000) ? 10000 : 0,
  }[state];
}

export const unitTests = () => {
  console.log('###### UNIT TESTS #######')
  
  console.log('---Transfer Fee Tests---');
  
  console.log('-SA-');
  console.log('45,000: 280 ==', calculateTransferFee({state: SA, value: 45000}));
  console.log('55,000: 362.5 ==', calculateTransferFee({state: SA, value: 55000}));
  console.log('60,000: 362.5 ==', calculateTransferFee({state: SA, value: 60000}));
  console.log('60,001: 445 ==', calculateTransferFee({state: SA, value: 60001}));

  console.log('-VIC-')
  console.log('0: 96.10 ==', calculateTransferFee({state: VIC, value: 0}));
  console.log('999: 98.60 ==', calculateTransferFee({state: VIC, value: 999}));
  console.log('1000: 98.60 ==', calculateTransferFee({state: VIC, value: 1000}));
  console.log('1001: 101.10 ==', calculateTransferFee({state: VIC, value: 1001}));
  console.log('2001: 103.12 ==', calculateTransferFee({state: VIC, value: 2001}));

  console.log('-WA-')
  console.log('50,000: 171.20 ==', calculateTransferFee({state: WA, value: 50000}));
  console.log('100,000: 181.20 ==', calculateTransferFee({state: WA, value: 100000}));
  console.log('200,000: 201.20 ==', calculateTransferFee({state: WA, value: 200000}));
  console.log('200,001: 221.20 ==', calculateTransferFee({state: WA, value: 200001}));
  console.log('300,000: 221.20 ==', calculateTransferFee({state: WA, value: 300000}));
  console.log('300,001: 241.20 ==', calculateTransferFee({state: WA, value: 300001}));

  console.log('');
  console.log('---Stamp Duty Tests---');
  console.log('');

  console.log('-ACT-');
  console.log('100,000: ?????? ==', calculateStampDutyFee({state: ACT, value: 100000, use: INVESTMENT}));
  console.log('200,000: ?????? ==', calculateStampDutyFee({state: ACT, value: 200000, use: INVESTMENT}));
  console.log('300,000: ?????? ==', calculateStampDutyFee({state: ACT, value: 300000, use: INVESTMENT}));
  console.log('500,000: ?????? ==', calculateStampDutyFee({state: ACT, value: 500000, use: INVESTMENT}));
  console.log('1,000,000: ?????? ==', calculateStampDutyFee({state: ACT, value: 1000000, use: INVESTMENT}));
  console.log('100,000: 0 ==', calculateStampDutyFee({state: ACT, value: 100000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('400,000: 8,500 ==', calculateStampDutyFee({state: ACT, value: 400000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('400,000: 0 ==', calculateStampDutyFee({state: ACT, value: 400000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('400,000: 8,500 ==', calculateStampDutyFee({state: ACT, value: 400000, use: INVESTMENT, type: NEW_HOME, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('700,000: 21,220 ==', calculateStampDutyFee({state: ACT, value: 700000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('680500: 0 ==', calculateStampDutyFee({state: ACT, value: 680500, use: OWNER_OCCUPIED, type: NEW_HOME, isPensioner: true, income: 0, dependents: 5}));
  console.log('700000: 2,934.75 ==', calculateStampDutyFee({state: ACT, value: 700000, use: OWNER_OCCUPIED, type: NEW_HOME, isPensioner: true, income: 0, dependents: 5}));
  console.log('880000: ???? ==', calculateStampDutyFee({state: ACT, value: 880000, use: OWNER_OCCUPIED, type: NEW_HOME, isPensioner: true, income: 0, dependents: 5}));  
  console.log('895000: 32,345 ==', calculateStampDutyFee({state: ACT, value: 895000, use: OWNER_OCCUPIED, type: NEW_HOME, isPensioner: true, income: 0, dependents: 5}));  
  console.log('434500: 9,742 ==', calculateStampDutyFee({state: ACT, value: 434500, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isPensioner: true, income: 0, dependents: 5})); 
  console.log('400000: 5113.05 ==', calculateStampDutyFee({state: ACT, value: 400000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isPensioner: true, income: 0, dependents: 5})); 
  console.log('434401: 9,742 ==', calculateStampDutyFee({state: ACT, value: 434401, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isPensioner: true, income: 0, dependents: 5}));  
  console.log('434400: 9,705.45 ==', calculateStampDutyFee({state: ACT, value: 434400, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isPensioner: true, income: 0, dependents: 5}));    
  console.log('0: 20 ==', calculateStampDutyFee({state: ACT, value: 0, use: INVESTMENT}));
  console.log('500000: 3705 ==', calculateStampDutyFee({state: ACT, value: 500000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('500000: 12100 ==', calculateStampDutyFee({state: ACT, value: 500000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true, income: 300000, dependents: 5}));
  console.log('500000: 12100 ==', calculateStampDutyFee({state: ACT, value: 500000, use: OWNER_OCCUPIED, type: ESTABLISHED_HOME, isFirstHomeBuyer: true, income: 0, dependents: 5}));
  console.log('');

  console.log('-NSW-');
  console.log('INVESTMENT')
  console.log('100,000: 1990.00 ==', calculateStampDutyFee({state: NSW, value: 100000, use: INVESTMENT}));
  console.log('200,000: 5490.00 ==', calculateStampDutyFee({state: NSW, value: 200000, use: INVESTMENT}));
  console.log('300,000: 8990.00 ==', calculateStampDutyFee({state: NSW, value: 300000, use: INVESTMENT}));
  console.log('500,000: 17990.00 ==', calculateStampDutyFee({state: NSW, value: 500000, use: INVESTMENT}));
  console.log('1,000,000: 40490.00 ==', calculateStampDutyFee({state: NSW, value: 1000000, use: INVESTMENT}));
  console.log('5,000,000: 290490.00 ==', calculateStampDutyFee({state: NSW, value: 5000000, use: INVESTMENT}));
  console.log('OWNER_OCCUPIED')
  console.log('firstHomeBuyer, VACANT_BLOCK')
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: NSW, value: 100000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('350,000: 0.00 ==', calculateStampDutyFee({state: NSW, value: 350000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('400,000: 7,870.00 ==', calculateStampDutyFee({state: NSW, value: 400000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('500,000: 17990.00 ==', calculateStampDutyFee({state: NSW, value: 500000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('firstHomeBuyer, NEW_HOME')
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: NSW, value: 100000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('650,000: 0.00 ==', calculateStampDutyFee({state: NSW, value: 650000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('800,000: 31490.00 ==', calculateStampDutyFee({state: NSW, value: 800000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('1,000,000: 40490.00 ==', calculateStampDutyFee({state: NSW, value: 1000000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('NEW_HOME, isForeign')
  console.log('600,000: 70,773.20 ==', calculateStampDutyFee({state: NSW, value: 600000, use: OWNER_OCCUPIED, type: NEW_HOME, isForeign: true}));
  console.log('');

  console.log('-NT-');
  console.log('100,000: 2,157.10 ==', calculateStampDutyFee({state: NT, value: 100000}));
  console.log('200,000: 5,628.55 ==', calculateStampDutyFee({state: NT, value: 200000}));
  console.log('2,000,000: 99,000.00 ==', calculateStampDutyFee({state: NT, value: 2000000}));
  console.log('4,000,000: 230,000.00 ==', calculateStampDutyFee({state: NT, value: 4000000}));
  console.log('10,000,000: 595,000.00 ==', calculateStampDutyFee({state: NT, value: 10000000}));
  console.log('');

  console.log('-QLD-');
  console.log('INVESTMENT')
  console.log('100,000: 1925.00 ==', calculateStampDutyFee({state: QLD, value: 100000, use: INVESTMENT}));
  console.log('200,000: 5425.00 ==', calculateStampDutyFee({state: QLD, value: 200000, use: INVESTMENT}));
  console.log('300,000: 8925.00 ==', calculateStampDutyFee({state: QLD, value: 300000, use: INVESTMENT}));
  console.log('500,000: 15925.00 ==', calculateStampDutyFee({state: QLD, value: 500000, use: INVESTMENT}));
  console.log('1,000,000: 38025.00 ==', calculateStampDutyFee({state: QLD, value: 1000000, use: INVESTMENT}));
  console.log('5,000,000: 268025.00 ==', calculateStampDutyFee({state: QLD, value: 5000000, use: INVESTMENT}));
  console.log('OWNER_OCCUPIED')
  console.log('firstHomeBuyer = false, NEW_HOME')
  console.log('100,000: 1000.00 ==', calculateStampDutyFee({state: QLD, value: 100000, use: OWNER_OCCUPIED, type: NEW_HOME}));
  console.log('400,000: 5250.00 ==', calculateStampDutyFee({state: QLD, value: 400000, use: OWNER_OCCUPIED, type: NEW_HOME}));
  console.log('700,000: 17350.00 ==', calculateStampDutyFee({state: QLD, value: 700000, use: OWNER_OCCUPIED, type: NEW_HOME}));
  console.log('2,000,000: 88,350.00 ==', calculateStampDutyFee({state: QLD, value: 2000000, use: OWNER_OCCUPIED, type: NEW_HOME}));
  console.log('firstHomeBuyer, VACANT_BLOCK')
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: QLD, value: 100000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('350,000: 8250.00 ==', calculateStampDutyFee({state: QLD, value: 350000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('399,000: 12425.00 ==', calculateStampDutyFee({state: QLD, value: 399000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('400,000: 12425.00 ==', calculateStampDutyFee({state: QLD, value: 400000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('500,000: 15925.00 ==', calculateStampDutyFee({state: QLD, value: 500000, use: OWNER_OCCUPIED, type: VACANT_BLOCK, isFirstHomeBuyer: true}));
  console.log('firstHomeBuyer, NEW_HOME')
  console.log('400,000: 0.00 ==', calculateStampDutyFee({state: QLD, value: 400000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('505,000: 1050.00 ==', calculateStampDutyFee({state: QLD, value: 505000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('540,000: 8400.00 ==', calculateStampDutyFee({state: QLD, value: 540000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('550,000: 10600.00 ==', calculateStampDutyFee({state: QLD, value: 550000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('600,000: 12850.00 ==', calculateStampDutyFee({state: QLD, value: 600000, use: OWNER_OCCUPIED, type: NEW_HOME, isFirstHomeBuyer: true}));
  console.log('NEW_HOME, isForeign')
  console.log('600,000: 54850.00 ==', calculateStampDutyFee({state: QLD, value: 600000, use: OWNER_OCCUPIED, type: NEW_HOME, isForeign: true}));
  console.log('');

  console.log('-SA-');
  console.log('100,000: 2830.00 ==', calculateStampDutyFee({state: SA, value: 100000, use: INVESTMENT}));
  console.log('430,000: 17830.00 ==', calculateStampDutyFee({state: SA, value: 430000, use: INVESTMENT}));
  console.log('570,000: 25180.00 ==', calculateStampDutyFee({state: SA, value: 570000, use: INVESTMENT}));
  console.log('');

  console.log('-TAS-');
  console.log('INVESTMENT')
  console.log('100,000: 2435.00 ==', calculateStampDutyFee({state: TAS, value: 100000, use: INVESTMENT, type: ESTABLISHED_HOME}));
  console.log('200,000: 5935.00 ==', calculateStampDutyFee({state: TAS, value: 200000, use: INVESTMENT, type: ESTABLISHED_HOME}));
  console.log('300,000: 9935.00 ==', calculateStampDutyFee({state: TAS, value: 300000, use: INVESTMENT, type: ESTABLISHED_HOME}));
  console.log('500,000: 18247.50 ==', calculateStampDutyFee({state: TAS, value: 500000, use: INVESTMENT, type: ESTABLISHED_HOME}));
  console.log('1,000,000: 40185.00 ==', calculateStampDutyFee({state: TAS, value: 1000000, use: INVESTMENT, type: ESTABLISHED_HOME}));
  console.log('OWNER_OCCUPIED')
  console.log('is Pensioner, ESTABLISHED_HOME')
  console.log('300,000: 9935.00/2 ==', calculateStampDutyFee({state: TAS, value: 300000, use: OWNER_OCCUPIED, type: ESTABLISHED_HOME, isPensioner: true}));
  console.log('500,000: 18247.50 ==', calculateStampDutyFee({state: TAS, value: 500000, use: OWNER_OCCUPIED, type: ESTABLISHED_HOME, isPensioner: true}));
  console.log('is firstHomeOwner, ESTABLISHED HOME')
  console.log('300,000: 9935.00/2 ==', calculateStampDutyFee({state: TAS, value: 300000, use: OWNER_OCCUPIED, type: ESTABLISHED_HOME, isFirstHomeBuyer: true}));
  console.log('is firstHomeOwner, is pensioner, ESTABLISHED HOME')
  console.log('300,000: 0.00 ==', calculateStampDutyFee({state: TAS, value: 300000, use: OWNER_OCCUPIED, type: ESTABLISHED_HOME, isPensioner: true, isFirstHomeBuyer: true}));
  console.log('');

  console.log('-VIC-');
  console.log('INVESTMENT')
  console.log('100,000: 2150.00 ==', calculateStampDutyFee({state: VIC, value: 100000, use: INVESTMENT}));
  console.log('500,000: 25070.00 ==', calculateStampDutyFee({state: VIC, value: 500000, use: INVESTMENT}));
  console.log('750,000: ? ==', calculateStampDutyFee({state: VIC, value: 750000, use: INVESTMENT}));
  console.log('1,000,000: 55000.00 ==', calculateStampDutyFee({state: VIC, value: 1000000, use: INVESTMENT}));
  console.log('OWNER_OCCUPIED')
  console.log('isFirstHomeOwner = false');
  console.log('100,000: 2150.00 ==', calculateStampDutyFee({state: VIC, value: 100000, use: OWNER_OCCUPIED}));
  console.log('500,000: 21970.00 ==', calculateStampDutyFee({state: VIC, value: 500000, use: OWNER_OCCUPIED}));
  console.log('700,000: 37070.00 ==', calculateStampDutyFee({state: VIC, value: 700000, use: OWNER_OCCUPIED}));
  console.log('1,000,000: 55000.00 ==', calculateStampDutyFee({state: VIC, value: 1000000, use: OWNER_OCCUPIED}));
  console.log('isFirstHomeOwner = true');
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: VIC, value: 100000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true}));
  console.log('500,000: 0.00 ==', calculateStampDutyFee({state: VIC, value: 500000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true}));
  console.log('700,000: 24,713 ==', calculateStampDutyFee({state: VIC, value: 700000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true}));
  console.log('710,000: 27,624 ==', calculateStampDutyFee({state: VIC, value: 710000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true}));
  console.log('1,000,000: 55000.00 ==', calculateStampDutyFee({state: VIC, value: 1000000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true}));
  console.log('');

  console.log('-WA-');
  console.log('INVESTMENT')
  console.log('50,000: 950.00 ==', calculateStampDutyFee({state: WA, value: 50000, use: INVESTMENT}));
  console.log('120,000: 2850.00 ==', calculateStampDutyFee({state: WA, value: 120000, use: INVESTMENT}));
  console.log('250,000: 7790.00  ==', calculateStampDutyFee({state: WA, value: 250000, use: INVESTMENT}));
  console.log('400,000: 14915.00 ==', calculateStampDutyFee({state: WA, value: 400000, use: INVESTMENT}));
  console.log('1,000,000: 45415.00 ==', calculateStampDutyFee({state: WA, value: 1000000, use: INVESTMENT}));
  console.log('OWNER_OCCUPIED')
  console.log('isFirstHomeOwner = false');
  console.log('100,000: 1900.00 ==', calculateStampDutyFee({state: WA, value: 100000, use: OWNER_OCCUPIED}));
  console.log('200,000: 5035.00 ==', calculateStampDutyFee({state: WA, value: 200000, use: OWNER_OCCUPIED}));
  console.log('500,000: 17765.00 ==', calculateStampDutyFee({state: WA, value: 500000, use: OWNER_OCCUPIED}));
  console.log('1,000,000: 42615.50 ==', calculateStampDutyFee({state: WA, value: 1000000, use: OWNER_OCCUPIED}));
  console.log('isFirstHomeOwner = true, VACANT_BLOCK');
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: WA, value: 100000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: VACANT_BLOCK}));
  console.log('350,000: 6505.00 ==', calculateStampDutyFee({state: WA, value: 350000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: VACANT_BLOCK}));
  console.log('1,000,000: 42615.50 ==', calculateStampDutyFee({state: WA, value: 1000000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: VACANT_BLOCK}));
  console.log('isFirstHomeOwner = true, NEW_HOME');
  console.log('100,000: 0.00 ==', calculateStampDutyFee({state: WA, value: 100000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: NEW_HOME}));
  console.log('500,000: 13,433.00 ==', calculateStampDutyFee({state: WA, value: 500000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: NEW_HOME}));
  console.log('1,000,000: 42615.50 ==', calculateStampDutyFee({state: WA, value: 1000000, use: OWNER_OCCUPIED, isFirstHomeBuyer: true, type: NEW_HOME}));
}
import React from "react"; import { Component } from "react";
import styled, { css } from 'styled-components'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Form, formValueSelector, change as changeForm } from 'redux-form';
import { closeModal } from 'modules/modals/actions';
import EditableContent from "Components/Forms/EditableContent";

const $ = window.$;
class OffsetTooltip extends Component {
  
  constructor(props) {
    super(props);
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    if (!this.inputRef.current) return;
    setTimeout(() => {
      this.inputRef.current.focus();
    });
    setTimeout(() => {
      if (this.props.tooltip === 'Enter Text Here') {
        document.execCommand('selectAll');
      }
    }, 5);

    $(document).on('keydown.offset', e => {
      if (e.key == 'Enter') {
        this.submit({ tooltip: this.props.tooltip });
      }
    });
  }

  

  componentWillUnmount() {
    $(document).off('.offset');
  }


  
  submit = ({ tooltip }) => {
    this.props.dispatch(this.props.changeForm(this.props.formName, 'offsetTooltip', tooltip));
    this.props.closeModal()
  }
  
  render() {
    if (this.props.currentModal === "offsetTooltip") {
    return (
      <div className="modal-content">
        <Form onSubmit={this.props.handleSubmit(this.submit.bind(this))}>
          <h1>Please Enter Offset Tooltip</h1>
          <p>This text will show when the client hovers over the offset feature</p>
          <CostReducers>
            <ToolTip innerRef={this.inputRef} as={EditableContent} name='tooltip'/>
            <Offset>$ Offset</Offset>
          </CostReducers>
          <div className="modal-navigation">
            <button onClick={()=>this.props.closeModal()} className="double left button-5">Cancel</button>
            <button className="double right button-1" style={{ fontSize: "13px" }}>Save Tooltip</button>
          </div>
        </Form>
        
      </div>
    ) } else {
      return "";
    }
  }
}


const CostReducers = styled.div`
  margin: 20px 0;
`;

const ToolTip = styled.div`
  background: #223241;
  border-radius: 4px;
  width: 185px;
  margin: 0 auto;
  padding: 8px;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 15px;
`;

const Offset = styled.div`
  font-weight: 600;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 10px 10px;
  color: #2291FF;
`;

const stateToProps = state => {
  const tooltip = (state.modals.args && state.modals.args.tooltip) || 'Enter Text Here';

  return {
    formName: (state.modals.args && state.modals.args.form) || "",
    initialValues: {
      tooltip,
    },
    tooltip: ((state.form['offsetTooltip'] || {}).values || {}).tooltip,
  };
};

function dispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    closeModal,
  }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(reduxForm({ 
  form: 'offsetTooltip',
})(OffsetTooltip))

 


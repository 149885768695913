import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from "redux-form";
import styled, { css } from 'styled-components';

import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';
import imgDanger from 'img/elements/exc-circle-red.png';

import { 
    FormContainer, 
    SelectGroup, 
} from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";


const FORM_NAME = 'intro-letter-modal';

let Form = ({ submitting, dirty, handleSubmit }) => {

  const userId = useSelector(state => state.user.id);
  const letters_json = useSelector(state => state.user.globalSettings.intro_letter_json)
  const currentText = useSelector(state => ((state.user.meta.miniproposal.overview || {}).introLetter || {}).body);
  const broker = useSelector(state => state.user.proposalBroker);

  const letterEmpty = !currentText;

  const [ needsConfirmation, setNeedsConfirmation ] = useState(false);

  const dispatch = useDispatch(); 

  if (!letters_json) return null;
  const letters = JSON.parse(letters_json);
  if (!letters || !letters.length) return null;
    
  const onSubmit = ({ letterIndex }) => {
      const template = letters[parseInt(letterIndex)];
      let text = template?.text ?? '';

      text = text.replace(/{brokername}/g, broker.name ?? '{brokername}');
      text = text.replace(/{brokeremail}/g, broker.email ?? '{brokeremail}');
      text = text.replace(/{brokermobile}/g, broker.mobile ?? '{brokermobile}');
      text = text.replace(/{brokerlandline}/g, broker.landline ?? '{brokerlandline}');
      text = text.replace(/{brokerfirstname}/g, broker.firstName ?? '{brokerfirstname}');
      text = text.replace(/{brokerlastname}/g, broker.firstName ?? '{brokerfirstname}');
      text = text.replace(/{brokertitle}/g, broker.title ?? '{brokertitle}');

      const patch = {
          overview: {
            introLetter: {
              body: text,
            }
          }
      };

      dispatch(requestPatchUser({ 
          userId: userId,
          params: {
            mergeMeta: true,
          },
          data: { 
            meta: { 
              miniproposal: patch,
            } 
          },
      }));

      dispatch(closeModal());
    };

    const requireConfirmation = e => {
      setNeedsConfirmation(true);
      e.preventDefault();
    }
    return (
      <FormContainer onSubmit={handleSubmit(onSubmit)} className="form your-details">
        <Section>
          <SelectGroup id='letterIndex' hideOptional values={letters.map((x, index) => ({ value: index, text: (x || {}).name }))}/>
        </Section>
        <ButtonContainer>
          {needsConfirmation && 
            <ConfirmationQuestion>This will wipe all edits. Are you sure?</ConfirmationQuestion>
          }
          {letterEmpty ? <button type='submit' disabled={!dirty} className="analyse"><ButtonSpinner loading={submitting}/>Ok</button>
            : needsConfirmation ? <button type='submit' className="analyse"><ButtonSpinner loading={submitting}/>Yes</button>
              : <button type='button' disabled={!dirty} className="analyse" onClick={requireConfirmation}><ButtonSpinner loading={submitting}/>Ok</button>
          }
          <button type='button' className="analyse" onClick={() => dispatch(closeModal())} style={{backgroundColor: '#FC5256'}}>Cancel</button>
        </ButtonContainer>
      </FormContainer>
    )
}


export default reduxForm({
    form: FORM_NAME,
})(Form);

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 53px;

  button, button {
    position: relative;
    width: 46% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const ConfirmationQuestion = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #FC535A !important;
  font-weight: 600;
  border: solid 1px #FC535A;
  padding-top: 13px;
  padding-left: 35px;
  border-radius: 4px;
  background-color: #FFEAEB;
  position: absolute;
  top: -35px;
  left: 0px;
  color: black;
  background-image: url(${imgDanger});
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 14px 14px;
`;

const Section = styled.div`
  margin-bottom: 40px;  
`;


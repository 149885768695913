import React from 'react';

export const Loader = () => (
  <div class="loader2">
    <div class="duo duo1">
      <div class="dot dot-a"></div>
      <div class="dot dot-b"></div>
    </div>
    <div class="duo duo2">
      <div class="dot dot-a"></div>
      <div class="dot dot-b"></div>
    </div>
  </div>
);

export const PageLoader = ({show}) => (
  <div className={'sidebar-page-loader' + (show ? ' show' : '')}>
    <Loader/>
  </div>
)
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal } from 'modules/modals/actions';
import Tooltips from "Components/Tooltips";
import CheckEligibilityButton from "Components/CheckEligibilityButton";
import StarLoansButton from "Components/StarLoansButton"
import { requestPatchUser } from 'modules/user/actions';
import BorrowingPowerValue from "Components/BorrowingPowerValue"
import Disclaimer from 'Components/Global/ProductDetailsDisclaimer';
import getQueryParams from "utils/getQueryParams";
import calculateLoanValues from "utils/calculateLoanValues"
import { addToShortlist, removeFromShortlist } from 'modules/user/actions';
import BankLogo from 'Components/Global/BankLogo';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import { setTitle, setPageID } from 'modules/page/actions';

function mapStateToProps(state) {

  let products;

  const lastRemovedProduct = state.user.meta.lastRemovedProduct ? [state.user.meta.lastRemovedProduct] : [];
  if (state.products.list) {
    products = [
      ...state.products.list,
      ...state.user.shortlist,
      ...state.user.applications[0].products,
      ...lastRemovedProduct,
    ];
  } else {
    products = [];
  }

  return {
    shortlist: state.user.shortlist,
    products,
    loanAmount: state.user.meta.loanAmount,
    nsrValues: state.user.nsrValues,
    user: state.user.meta,
    userId: state.user.id,
    miniProposal: state.user.meta.miniproposal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    addToShortlist,
    removeFromShortlist,
    requestPatchUser,
    setTitle,
    setPageID,
  }, dispatch);
}

function formatDollars(amount, decimals = 0) {
  return "$" + Number.parseFloat(amount).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class ProductDetails extends Component {

  componentDidMount() {
    // console.log("AH");
    this.props.setTitle("Product Details");
    this.props.setPageID("product-details");
    window.$("main").scroll(function (event) {
      var scroll = window.$("main").scrollTop();
      //console.log(scroll);
      if (scroll > 350) {
        window.$(".top-container").addClass("sticky");
        window.$(".sticky-padding").addClass("enabled");
      } else {
        window.$(".top-container").removeClass("sticky");
        window.$(".sticky-padding").removeClass("enabled");
      }
    });
  }

  toggleView(item) {
    let toggleState = this.props.user["pageState_product_toggle_" + item] ? 0 : 1;

    this.props.requestPatchUser({ userId: this.props.userId, data: { meta: { ["pageState_product_toggle_" + item]: toggleState } }, callback: () => { } });
  }

  render() {
    //console.log(this.props.products);
    
    let product, isProposal, currentLoan;
    {
      const { id, tab } = this.props.match.params;
      isProposal = !!tab;
      if (isProposal) {
        const property = this.props.miniProposal.properties[tab];
        currentLoan  = property.currentLoan || {};
        product = property.loanComparison.loans[id];   
      } else {
        product = this.props.products.find((e) => e.id == this.props.match.params.id);
      }
    }
    
    //console.log('products:', this.props.products);

    if (!product) {
      return "";
    }

    product = calculateLoanValues(product);

    if(isProposal && currentLoan.loanAmount) {
      if (product.repaymentType === 'Interest Only') {
        product.monthlyRepayments = (parseFloat(product.iir) / 100 / 12 * (currentLoan.loanAmount)).toFixed(2);
      } else {
        product.monthlyRepayments = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 12, (currentLoan.newLoanPeriod || 30) * 12, currentLoan.loanAmount).toFixed(2);
      }
    }

    if (isProposal) {
      let totalUpfront = 0;
      totalUpfront += parseInt(product.application_fees);
      totalUpfront += parseInt(product.lender_legals);
      totalUpfront += parseInt(product.other_establishment_fees);
      totalUpfront += parseInt(product.settlement_fees);
      if (this.props.user.loanPurpose === 'construct') {
        totalUpfront += parseInt(product.construction_fees);
      }
      console.log('total', totalUpfront, product.approxUpfrontFee);
      product.valuationFee = Math.max(0, parseInt((product.approxUpfrontFee.toString()).replace(/,/g, '')) - totalUpfront);
    }
    
    let otherFees = 0;
    const upfrontFee = parseInt(parseInt((product.approxUpfrontFee.toString()).replace(/,/g, '')));
    if (upfrontFee) {
      const totalFees = parseInt(product.application_fees) + 
        + parseInt(product.lender_legals) + parseInt(product.settlement_fees) 
        + (product.additional_valuation_fee ? parseInt(product.additional_valuation_fee) : 0)
        + (this.props.user.loanPurpose === 'construct' ? parseInt(product.construction_fees) : 0)
      otherFees = Math.max(0, upfrontFee - totalFees);
      debugger;
    }


    return (
      <main className={"product-details " + this.props.type}>

        <div className="sticky-padding"></div>

        <div className="top-container">
          <div className="featured-details">

            {/* <LinkButton to="/search-loans" className="go-back top">Go back to search results</LinkButton> */}

            <div className="shortlist">
              <p>Star this loan to add it <br />to your shortlist</p>
              <StarLoansButton product={product} />
            </div>

            <div className="heading">

              <BankLogo product={product} className='logo'/>
              <h1>{product.product_name}</h1>

            </div>

            <div className="statistics">
              <div className="variable-rate">

                <div className="rate-container">
                  <div className="value">{parseFloat(product.iir).toFixed(2)}<span>% p.a.</span></div>
                  <div className="value-description">{product.rateDescriptionYears}</div>
                </div>

              </div>
              <div className="comparison-rate">
                {isProposal ? 
                  <div className="rate-container">
                    <div className="value">{product.trueRate}<span>% p.a.</span></div>
                    <div className="value-description">True Rate</div>
                  </div>
                  :
                  <div className="rate-container">
                    <div className="value">{product.comparisonRate.toFixed(2)}<span>% p.a.</span></div>
                    <div className="value-description">Comparison Rate</div>
                  </div>
                }
              </div>
              <div className="repayment">
                <div className="rate-container">
                  <div className="value">${(Math.round(product.monthlyRepayments)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  <div className="value-description">Monthly Repayment</div>
                </div>
              </div>
              <div className="loan-amount">
                <div className="rate-container">
                  {isProposal ?
                    <div className="value">${(currentLoan.loanAmount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    :
                    <div className="value">${(this.props.loanAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  }
                  <div className="value-description">Loan Amount</div>
                </div>
              </div>
            </div>

            <div className="navigation">

              <div className="left">
                <button onClick={() => this.props.openModal("checkEligibilityHelp")} className="get-advice"><span>Get Advice</span></button>
              </div>

              <div className="middle">
                <div className="additional-information">
                  <div className="borrowing-power"><BorrowingPowerValue value={(this.props.nsrValues[product.bank] || this.props.nsrValues['default'])} /></div>
                  <div className="upfront-fee"><span>Approx Upfront: <span className="bolder">${product.approxUpfrontFee}</span></span></div>
                  <div className="annual-fee"><span>Annual fee: <span className="bolder">${product.ongoing_fees}</span></span></div>
                </div>
              </div>

              <div className="right">
                <div className="check-eligiblity">
                  <CheckEligibilityButton product={product} />
                  <div className="modal-a-link" onClick={() => this.props.openModal("checkEligibilityHowDoesThisWork")} >How does this work?</div>
                  <StarLoansButton product={product} />
                </div>

              </div>

            </div>

          </div>

        </div>
        
        <div className='content-wrapper'>
          <div className="back-container" style={{ marginBottom: '20px'}}>
            {isProposal ?
              <LinkButton to="/proposal" className="go-back bottom">Return to proposal</LinkButton>
              :
              <LinkButton to="/search-loans" className="go-back bottom">Go back to search results</LinkButton>
            } 
          </div>

          <div className="content">

            <div className="key-details">
              <div className="bank">
                <BankLogo product={product} className='logo'/>

                <ul className={"list-details " + ((product.initial_ir_type === "Fixed") ? "five" : "four")}>
                  <li>
                    <span className="title">Interest Rate</span>
                    <span className="value">{product.iir}% p.a.</span>
                    <span className="subtitle">{product.rateDescriptionYears}</span>
                  </li>
                  {isProposal ? 
                    <li>
                      <span className="title">True Rate</span>
                      <span className="value">{product.trueRate}% p.a.</span>
                    </li>
                  :
                    <li>
                      <span className="title">Comparison Rate</span>
                      <span className="value">{product.comparisonRate}% p.a.</span>
                    </li>
                  }

                  {(product.initial_ir_type === "Fixed") ? (
                    <li>
                      <span className="title">Revert Rate</span>
                      <span className="value">{product.rr}%</span>
                      <span className="subtitle">After {product.fixed_term} months</span>
                    </li>
                  ) : ""}
                  <li>
                    <span className="title">Purpose</span>
                    <span className="value">{InvOrOcc(product.maximum_lvr_investment, product.maximum_lvr_own_occ)}</span>
                  </li>
                  <li>
                    <span className="title">Repayment type</span>
                    <span className="value">{product.repaymentType}</span>
                  </li>

                </ul>
                
              </div>

              <p className="lender-description">
                {(product.lenderDescription || '')
                  .split('\n')
                  .map(x => (<><br/>{x}</>))}
              </p>

              {(product.key_details)?
                [<h3>Additional Notes</h3>,
                <p>{product.key_details}</p>]
                : ""
              }

            </div>

            <div className="features">
              
              <div className="left-side">
                <h3>Loan Features</h3>
                <ul>
                  <div className="column-1">
                    <li className={(product.extra_repayments_allowed === "Y") ? "available" : ""}>Extra Repayment<Tooltips type="definition" term="extraRepayment" /></li>
                    <li className={(product.interest_in_advance === "Y") ? "available" : ""}>Interest in Advance<Tooltips type="definition" term="interestInAdvance" /></li>
                    {product.full_offset === 'additional' ? 
                      <li className='additional'><Tooltips type="wrapper" definition={product.offsetTooltip}>Offset</Tooltips><Tooltips type="definition" term="offset" /></li>
                      :
                      <li className={(product.full_offset === "Y") ? "available" : ""}>Offset<Tooltips type="definition" term="offset" /></li>
                    }
                    <li className={(product.redraw_allowed === "Y") ? "available" : ""}>Redraw<Tooltips type="definition" term="redraw" /></li>
                  </div>
                  <div className="column-2">
                    <li className={(product.pkg_seq) ? "available" : ""}>Package<Tooltips type="definition" term="package" /></li>
                    <li className={(product.rate_lock_fee) ? "available" : ""}>Locked Rate<Tooltips type="definition" term="lockedRate" /></li>
                    <li className={(product.partial_offset === "Y") ? "available" : ""}>Partial Offset<Tooltips type="definition" term="partialOffset" /></li>
                    <li className={(product.portable === "Y") ? "available" : ""}>Portability<Tooltips type="definition" term="portability" /></li>
                  </div>
                </ul>
              </div>

              <div className="right-side">
                <h3>Permissible Loan Types</h3>
                <ul> 
                  <div className="column-1">
                    <li className={(product.bridging_loan === "Y") ? "available" : ""}>Bridging<Tooltips type="definition" term="bridging" /></li>
                    <li className={(product.company === "Y" || product.trust === "Y") ? "available" : ""}>Company/Trust<Tooltips type="definition" term="companyTrust" /></li>
                    <li className={(product.maximum_lvr_spec_builder > 0) ? "available" : ""}>Construction<Tooltips type="definition" term="construction" /></li>
                    <li className={(product.first_home_buyer === "Y") ? "available" : ""}>First Home Buyer<Tooltips type="definition" term="firstHomeBuyer" /></li>
                    <li className={(product.first_property_buyer === "Y") ? "available" : ""}>First Property Buyer<Tooltips type="definition" term="firstPropertyBuyer" /></li>
                    <li className={(!product.product_name.toLowerCase().includes('refinance')) ? "available" : ""}>Purchase<Tooltips type="definition" term="purchase" /></li>
                  </div>
                  <div className="column-2">
                    <li className={(product.maximum_lodoc_loan_amount > 0) ? "available" : ""}>Lo Doc/Alt-Doc<Tooltips type="definition" term="loDocAltDoc" /></li>
                    <li className={(product.maximum_lvr_owner_builder > 0) ? "available" : ""}>Owner Builder<Tooltips type="definition" term="ownerBuilder" /></li>
                    <li className={(product.maximum_lvr_refinance > 0) ? "available" : ""}>Refinance<Tooltips type="definition" term="refinance" /></li>
                    <li className={(product.is_smsf === "Y") ? "available" : ""}>SMSF<Tooltips type="definition" term="smsf" /></li>
                    <li className={(product.maximum_lvr_spec_builder > 0) ? "available" : ""}>Vacant Land<Tooltips type="definition" term="vacantLand" /></li>
                  </div>
                </ul>
              </div>
            </div>

            <div className="repayment-frequency">
              <div className="left-side">
                <h3>Repayment Frequency</h3>
                <ul>
                  <div className="column-1">
                    <li className={(product.weekly_repayment === "Y") ? "available" : ""}>Weekly</li>
                    <li className={(product.fortnightly_repayment === "Y") ? "available" : ""}>Fortnightly</li>
                  </div>
                  <div className="column-2">
                    <li className={(product.monthly_repayment === "Y") ? "available" : ""}>Monthly</li>
                  </div>
                </ul>
              </div>
              <div className="right-side">
                <h3>Account Access Methods</h3>
                <ul>
                  <div className="column-1">
                    <li className={(product.atm_access === "Y") ? "available" : ""}>ATM Access</li>
                    <li className={(product.aust_post_access === "Y") ? "available" : ""}>Australia Post Access</li>
                    <li className={(product.bpay_access === "Y") ? "available" : ""}>BPAY Access</li>
                  </div>
                  <div className="column-2">
                    <li className={(product.branch_access === "Y") ? "available" : ""}>Branch Access</li>
                    <li className={(product.net_access === "Y") ? "available" : ""}>Net Access</li>
                    <li className={(product.phone_access === "Y") ? "available" : ""}>Phone Access</li>
                  </div>
                </ul>
              </div>
            </div>

            <div className="fee-detail">
                <div className="left-side">
                  <div className="upfront-fees">
                    <h4>Upfront Fees</h4>
                    <div className="fee-detail-content">
                      <p>Upfront fees may include application fees, standard valuation costs (generally valuation on 1 standard residential property with estimated value below $1million), and standard legals (excluding PEXA). Note that these fees are indicative only, do not include government charges, and may vary depending on your particular circumstances.</p>
                    </div>
                    <div className="fees-calculations">
                      {!!upfrontFee ?
                        <ul>
                          <li><span>${product.application_fees}</span>Application Fees</li>
                          { this.props.user.loanPurpose === 'construct' &&
                            <li><span>${product.construction_fees}</span>Construction Fees</li>
                          }
                          <li><span>${product.lender_legals}</span>Lender Legals</li>
                          <li><span>${product.other_establishment_fees}</span>Other Establishment Fee</li>
                          <li><span>${product.settlement_fees}</span>Settlement Fee</li>
                          { !!product.valuationFee &&
                            <li><span>${product.additional_valuation_fee}</span>Valuation Fee</li>
                          }
                          { !!otherFees &&
                            <li><span>${otherFees}</span>Other</li>
                          }
                        </ul>

                      :
                        <ul>
                          <li><span>$0</span>Application Fees</li>
                          { this.props.user.loanPurpose === 'construct' &&
                            <li><span>${product.construction_fees}</span>Construction Fees</li>
                          }
                          <li><span>$0</span>Lender Legals</li>
                          <li><span>$0</span>Other Establishment Fee</li>
                          <li><span>$0</span>Settlement Fee</li>
                        </ul>
                      }
                    </div>
                    <h4 className="total">Total: ${product.approxUpfrontFee}</h4>
                  </div>
                </div>
                
                <div className="right-side">
                  <div className="ongoing-fees">
                    
                    <h4>Ongoing Fees</h4>
                    <div className="fee-detail-content">
                      <p>Some loans include ongoing fees, usually charged either monthly or annually. </p>
                    </div>

                    <div className="fees-calculations">
                      <ul>
                        <li><span>${product.ongoing_fees} / year</span>Ongoing Fees</li>
                      </ul>
                    </div>
                    <h4 className="total">Total: ${product.ongoing_fees} / year</h4>

                  </div>

                  <div className="exit-fees">
                    
                    <h4>Exit Fees</h4>
                    <div className="fee-detail-content">
                      <p>Exit fees are generally charged by lenders only when you fully discharge a loan. This could be as a result of refinancing or paying out the loan. Note that additional charges may apply if you break a fixed term early.</p>
                    </div>
                    <div className="fees-calculations">
                      <ul>
                        <li><span>${product.discharge_fee}</span>Discharge Fee</li>
                      </ul>
                    </div>
                    <h4 className="total">Total: ${product.discharge_fee}</h4>

                  </div>
                </div>

            </div>

            <div className="loan-amounts-and-lending-ratios">
                <div className="left-side">
                  <div className="loan-amounts">
                    <h4>Minimum & Maximum Loan Amounts</h4>
                    <dl>
                      <dt>Minimum Loan Size:</dt>
                      <dd>{formatDollars(product.minimum_loan_amount)}</dd>
                    </dl>
                    <dl>
                      <dt>Maximum Loan Size:</dt>
                      <dd>{formatDollars(Math.min(product.maximum_loan_amount, 20000000))}</dd>
                    </dl>
                    <dl>
                      <dt>Maximum Loan Term:</dt>
                      <dd>{product.maximum_term} Years</dd>
                    </dl>
                  </div>
                </div>
                <div className="right-side">
                  <div className="loan-to-value-ratio">
                    <h4>Maximum Loan-to-value Ratio (LVR)*</h4>

                    <div className="column-1">
                      <dl>
                        <dt>Owner-Occupier:</dt>
                        <dd>{product.maximum_lvr_own_occ}%</dd>
                      </dl>

                      {(product.max_lvr_invest) ?
                        <dl>
                          <dt>Investment:</dt>
                          <dd>{product.max_lvr_invest}%</dd>
                        </dl> : ""}

                      <dl>
                        <dt>Refinance:</dt>
                        <dd>{product.maximum_lvr_refinance}%</dd>
                      </dl>
                    </div>

                    <div className="column-2">
                      <dl>
                        <dt>Capitalise LMI</dt>
                        <dd>{(product.capitalise_lmi === "Y") ? "Yes" : "No"}</dd>
                      </dl>
                      <dl>
                        <dt>Min. Genuine Savings:</dt>
                        <dd>{product.minimum_genuine_savings}%</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>

              
              {product.key_notes &&
                <div className="key-notes">
                  <h3 className={!(this.props.user.pageState_product_toggle_keyNotes)?" ":"close"} onClick={()=>this.toggleView('keyNotes')}>Key Notes</h3>
                  {!(this.props.user.pageState_product_toggle_keyNotes)
                    ?
                    <div className="content-toggle-box">
                      <p>{product.key_notes}</p>
                    </div>
                    :
                    ""
                  }
                </div>
              }
              
              {/* {product.lenderDescription && 
                <div className="about">
                  <h3 className={!(this.props.user.pageState_product_toggle_about)?" ":"close"} onClick={()=>this.toggleView('about')}>{"About " + product.bank_name}</h3>
                  {!(this.props.user.pageState_product_toggle_about)
                    ?
                    <div className="content-toggle-box">
                      <p>{product.lenderDescription}</p>
                    </div>
                    :
                    ""
                  }
                </div>
              } */}
              

            <div className="navigation">
              <button onClick={() => this.props.openModal("checkEligibilityHelp")} className="get-advice"><span>Get Advice</span></button>
              <div className="check-eligiblity">
                <CheckEligibilityButton product={product} />
                <div className="modal-a-link" onClick={() => this.props.openModal("checkEligibilityHowDoesThisWork")} >How does this work?</div>
              </div>
            </div>

          </div>

          <div className="back-container" style={{ marginTop: '40px'}}>
            {isProposal ?
              <LinkButton to="/proposal" className="go-back bottom">Return to proposal</LinkButton>
              :
              <LinkButton to="/search-loans" className="go-back bottom">Go back to search results</LinkButton>
            } 
          </div>
        </div>
        

        <Disclaimer />
      
      </main>
    );
  }
}

function InvOrOcc(inv, occ) {
  if ((inv > 0) && (occ > 0)) {
    return "Investment or Owner Occupied";
  }
  else {
    if (occ > 0) {
      return "🏡 Owner Occupied"
    }
    else {
      return "📈 Investment"
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);

import React from "react"; import { Component, Fragment } from "react";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal, openRefinanceModal, openModalWithArgs } from 'modules/modals/actions';
import CheckEligibilityButton from "Components/CheckEligibilityButton";
import Tooltips from "Components/Tooltips";
import BorrowingPowerValue from "Components/BorrowingPowerValue"
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import calculateLoanValues from "utils/calculateLoanValues"

import { reduxForm, formValueSelector, Form, submit, change as changeForm } from "redux-form";
import { FormContainer, SelectGroup } from "Components/Forms";
import BankLogo from 'Components/Global/BankLogo';
import SectionButton from "./SectionButton";
import styled, { css } from 'styled-components';

import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import calculateTrueRate from "utils/calculateTrueRate";
import formatNumber from 'utils/formatNumber';
import parseNumber from 'utils/parseNumber';

import alterArray from 'utils/alterArray';

import _ from 'lodash';

const $ = window.$;



function mapStateToProps(state, props) {
  const formSelector = formValueSelector(props.form);
  return {
    nsrValues: state.user.nsrValues,
    user: state.user.meta,
    userId: state.user.id,
    products: state.products.list,
    //repaymentFrequency: state.user.meta.searchFilter_repaymentFrequency,
    loanPurpose: state.user.meta.loanPurpose,
    nsrValues: state.user.nsrValues,
    //initialValues: { payment_frequency: "monthly" },
    miniProposal: state.user.meta.miniproposal,
    trueRate: formSelector(state, 'trueRate'),
    iir: formSelector(state, 'iir'),
    rr: formSelector(state, 'rr'),
    originalRR: formSelector(state, 'originalRR'),
    approxUpfrontFee: formSelector(state, 'approxUpfrontFee'),
    ongoing_fees: formSelector(state, 'ongoing_fees'),
    extra_repayments_allowed: formSelector(state, 'extra_repayments_allowed'),
    redraw_allowed: formSelector(state, 'redraw_allowed'),
    full_offset: formSelector(state, 'full_offset'),
    offsetTooltip: formSelector(state, 'offsetTooltip'),
    paymentPeriod: formSelector(state, 'paymentPeriod'),
    formValues: state.form,
    godmode: state.user.godmode,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    openRefinanceModal,
    requestPatchUser,
    openModalWithArgs,
    changeForm,
  }, dispatch);
}

class ProposalProductBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isUnstarShown: false,
      isShownMore: true,
      reducerOffset: (this.props.calculateBox.full_offset === "Y").toString(),
      reducerRedraw: (this.props.calculateBox.redraw_allowed === "Y").toString(),
      reducerRepayment: (this.props.calculateBox.extra_repayments_allowed === "Y").toString(),
      defaultReducerOffset: (this.props.calculateBox.full_offset === "Y").toString(),
      defaultReducerRedraw: (this.props.calculateBox.redraw_allowed === "Y").toString(),
      defaultReducerRepayment: (this.props.calculateBox.extra_repayments_allowed === "Y").toString(),
    };
  }

  toggleUnstarPopup() {
    const { isUnstarShown } = this.state;
    if (!isUnstarShown) {
      $(window).click(e => {
        if (!$(e.target).closest('.favourites').length
          && $(e.target).attr('class') !== 'pick-avatar') {

          this.setState({ isUnstarShown: false });
        }
      });
    } else {
      $(window).off();
    }
    this.setState({ isUnstarShown: !isUnstarShown });
  }

  openRefinanceModal = product => {
    const isInitialised = !!this.props.user.initialLoanDetails;
    if (isInitialised) {
      this.props.openRefinanceModal(product);
    }
    else {
      this.props.openModalWithArgs({
        modal: 'refinanceAnalysisStart',
        args: product
      });
    }
  }

  removeFromProposal = (loanId, proposalId) => {
    console.log({ proposalId, loanId });
    const loanComparisonOld = this.props.miniProposal.properties[proposalId].loanComparison;
    let recommended = loanComparisonOld.recommendationActive;
    if (loanComparisonOld.recommendationActive && loanComparisonOld.loanOrder[0] === loanId) {
      recommended = false;
    }
    const loanComparison = {
      recommendationActive: recommended,
      loanOrder: alterArray.remove(loanId),
      loans: {
        [loanId]: 'DELETE_ITEM',
      } 
    }

    this.props.onDelete();
    this.patchProposal(proposalId, loanComparison);
  }

  patchProposal = (id, data, callback) => this.props.requestPatchUser({ 
    userId: this.props.userId,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [id]: {
              loanComparison: data
            }
          }
        }
      } 
    },
    callback, 
  });

  patchLoan = (tabId, data, callback) => {
    const id = this.props.calculateBox.id;
    const loans = {
      [id]: data,
    };
    this.patchProposal(tabId, { loans }, callback);
  }

  switchCostReducers(current, type, e) {

    let changed = current === 'Y' ? 'N' : 'Y'; 


    switch (type) {
      case "full_offset":
        if (e.shiftKey) {
          return (
            [
              this.props.change('full_offset', 'additional'),
              ,
              this.props.openModalWithArgs({ 
                modal: "offsetTooltip", 
                args: { 
                  form: this.props.form,
                  tooltip: this.props.offsetTooltip,
                }
              })
            ]
          );
        }
        else {
          this.props.change('full_offset', changed);
        }
      default: {
        this.props.change(type, changed);
      }
    }
  }

  compareReducers(original, current) {
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (original !== current && !noGodmode && this.props.godmode && !this.props.miniProposal.previewMode && !this.props.isPDF) {
      return true;
    } else {
      return false;
    }
  }

  isRecommended(index, recommended) {
    if (index === recommended) {
      return true;
    } else {
      return false;
    }
  }

  // submitForm() {
  //   setTimeout(() => this.props.submit(), 100);
  // }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  submitForm = values => {
    const loan = this.props.calculateBox;

    delete values.paymentPeriod;
    if (values.approxUpfrontFee)  {
      values.approxUpfrontFee = formatNumber(parseInt(values.approxUpfrontFee.replace(/\D/g,''))) || '0';
    }
    if (values.ongoing_fees) {
      values.ongoing_fees = formatNumber(parseInt(values.ongoing_fees.replace(/\D/g,''))) || '0';
    }

    // will fill in the original comparision rate for old products for compatability (this value was not originally saved)
    if (!loan.originalComparisonRate) {
      values.originalComparisonRate = loan.comparisonRate;
    }

    this.patchLoan(this.props.tabId, values);
    this.props.cancelEdit();
  }

  revert = () => {
    const { change } = this.props;
    const loan = this.props.calculateBox;
    const changes = {};
    changes.iir = loan.originalIIR.toString();
    changes.trueRate = loan.originalTrueRate.toString();
    changes.approxUpfrontFee = formatNumber(loan.originalApproxUpfrontFee);
    changes.ongoing_fees = formatNumber(loan.original_ongoing_fees);
    changes.full_offset = loan.original_full_offset;
    changes.offsetTooltip = '';
    changes.redraw_allowed = loan.original_redraw_allowed;
    changes.extra_repayments_allowed = loan.original_extra_repayments_allowed;
    changes.rr = loan.originalRR || loan.rr;
    changes.trailRebate = 0;
    changes.upfrontRebate = 0;

    change('iir', loan.originalIIR.toString());
    change('trueRate', loan.originalTrueRate.toString());
    change('approxUpfrontFee', formatNumber(loan.originalApproxUpfrontFee));
    change('ongoing_fees', formatNumber(loan.original_ongoing_fees));
    change('full_offset', loan.original_full_offset);
    change('offsetTooltip', '');
    change('redraw_allowed', loan.original_redraw_allowed);
    change('extra_repayments_allowed', loan.original_extra_repayments_allowed);
    change('rr', loan.rr);

    this.submitForm(changes);
  } 

  paymentPeriodChanged = e => {
    const { formValues, dispatch, changeForm } = this.props;
    const value = e.target.value;
    const forms = Object.keys(formValues).filter(name => name.startsWith('loan-'));
    for (const name of forms) {
      dispatch(changeForm(name, 'paymentPeriod', value));
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    const { currentLoan, open } = this.props;
    let product = calculateLoanValues({ ...this.props.calculateBox, iir: this.props.iir });
    
    {
      //const rate = parseFloat(product.iir) - (product.trailRebate || 0);
      const rate = parseFloat(product.iir);
      const { loanAmount, loanPeriod } = currentLoan;
      //console.log("calculating repayments:", rate, loanAmount, loanPeriod);

      if (product.repaymentType === 'Interest Only') {
        product.monthlyRepayments = (rate / 100 / 12 * loanAmount).toFixed(2);
        product.fortnightlyRepayments = (rate / 100 / 26.0714 * loanAmount).toFixed(2); 
        product.weeklyRepayments = (rate / 100 / 52.1428 * loanAmount).toFixed(2);
      } else {
        product.monthlyRepayments = calculateMonthlyRepayments(rate / 100 / 12, (loanPeriod || 30) * 12, loanAmount).toFixed(2);
        product.fortnightlyRepayments = calculateMonthlyRepayments(rate / 100 / 26.0714, (loanPeriod || 30) * 26.0714, loanAmount).toFixed(2);
        product.weeklyRepayments = calculateMonthlyRepayments(rate / 100 / 52.1428, (loanPeriod || 30) * 52.1428, loanAmount).toFixed(2);
      }
    }

    //console.log("length: ", product.rateDescriptionYears.length)

    const { repaymentFrequency, loanPurpose, active } = this.props;
    return (
      <ProposalProduct isPDF={this.props.isPDF} numberOfLoans={this.props.numberOfLoans} active={active} purchase={currentLoan.newLoanPurpose !== 'refinance'} recommended={this.props.recommendedIndex === this.props.index} refinance={this.isRecommended(this.props.index, this.props.refinance) && currentLoan.newLoanPurpose === 'refinance'} index={this.props.index} className={toggleTypeClass(this.props.type) + " proposal-product-box"}>
        <Form onSubmit={this.props.handleSubmit(this.submitForm.bind(this))}>
          <div className="lender">
            {!noGodmode && this.props.godmode && !this.props.miniProposal.previewMode && !this.props.isPDF &&
              <SectionButton
                recommended={this.props.recommendedIndex === this.props.index}
                enableEdit={() => { this.props.toggleEdit(); console.log('toggling edit')}}
                isEditable={active}
                cancelEdit={() => this.cancel()}
                revert={() => this.revert()}
                removeRecommended={this.props.removeRecommended}
                isRecommended={this.props.setRecommended}
                removeProduct={() => this.removeFromProposal(product.id, this.props.proposalId)}
                openRebateModal={() => this.props.openModalWithArgs({
                  modal: "ProposalRebate",
                  args: {
                    tabId: this.props.tabId,
                    productId: this.props.calculateBox.id,
                  }
                })}
                type="product" style={{ position: 'absolute', right: '0', top: '0' }} />
            }
            <div className='logo-wrapper'>
              <BankLogo isPDF={this.props.isPDF} product={product} />
            </div>  
            <h6>{product.product_name}</h6>
          </div>

          <div className="payments">
            <Rate>${Math.round(repaymentValue(product, this.props.paymentPeriod)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Rate>
            <span className="field-description">Payment
              <FormContainer>
                <SelectGroup onChange={e => this.paymentPeriodChanged(e)} id='paymentPeriod' empty="false" width='small' position='left' hideOptional type='select' placeholder='0'
                  values={[
                    { value: 'monthly', text: 'Monthly' },
                    { value: 'fortnightly', text: 'Fortnightly' },
                    { value: 'weekly', text: 'Weekly' },
                  ]} />
              </FormContainer>
            </span>
          </div>

          <div className="interest-rate" style={{ padding: '20px 0 17px 0'}}>

              <OptionalTooltip className="variable-rate" active={!!product.trailRebate && !(!noGodmode && this.props.godmode && !this.props.miniProposal.previewMode)} definition={`Rate factors in a ${product.trailRebate}% annual trail rebate from us, full details outlined above`}>
                <Rate hidden={active}>{formatNumber(Number.parseFloat(product.iir) - (product.trailRebate || 0), 2)}<AfterRate> % p.a.</AfterRate></Rate>
                <Rate hidden={!active}><Span disabled={!active} name='iir' as={EditableContent} onEnter={this.blurOnEnter}/><AfterRate> % p.a.</AfterRate></Rate>
              
              <>
                {product.initial_ir_type === 'Fixed' && !noGodmode && this.props.godmode && !this.props.miniProposal.previewMode? 
                  <>
                    <FixedTerm 
                      overriden={this.props.originalRR && this.props.rr !== this.props.originalRR} 
                      editMode={active}
                    >
                      <RateBox style={{ marginTop: product.trailRebate ? '0px' : '5px'}} className="field-description" blueBox={!!product.trailRebate}>
                        {(product.trailRebate ? "Effective " : "") + product.rateDescriptionYears}
                      </RateBox>
                    </FixedTerm>
                  </>
                  :
                  <RateBox style={{ marginTop: product.trailRebate ? '0px' : '5px'}} className="field-description" blueBox={!!product.trailRebate}>
                    {(product.trailRebate ? "Effective " : "") + product.rateDescriptionYears}
                  </RateBox>
                }
                {(parseFloat(this.props.iir) !== product.originalIIR || !!product.trailRebate) && !noGodmode && this.props.godmode && !this.props.miniProposal.previewMode && !this.props.isPDF &&
                  <InlineBlueDot type="custom" definition={
                    'Original Value: ' + product.originalIIR + '%' + 
                    (product.trailRebate ? 
                      (parseFloat(this.props.iir) !== product.originalIIR ?  
                        '\nModified Value: ' + this.props.iir + '%' : "") 
                      + '\nRebate: ' + formatNumber(product.trailRebate, 2) + '%' : '')} 
                  />
                }
              </>
            </OptionalTooltip>
   
            <div className="comparison-rate">
              
              {product.initial_ir_type === "Fixed" && 
                <>
                  <Rate hidden={active}>
                    {formatNumber(Number.parseFloat(product.rr), 2)}
                    <AfterRate> % p.a.</AfterRate>
                  </Rate>
                  <Rate hidden={!active}>
                    <Span disabled={!active} name='rr' as={EditableContent} onEnter={this.blurOnEnter}/>
                    <AfterRate> % p.a.</AfterRate>
                  </Rate>
                </>
              }

              {product.initial_ir_type !== "Fixed" && ((this.props.trueRate !== product.originalTrueRate && !noGodmode && this.props.godmode && this.props.currentLoan.loanAmount && !this.props.miniProposal.previewMode) ?
                  <Tooltips type='wrapper' definition={'Original Value: ' + product.originalTrueRate + '%'} >
                    <Rate>{this.props.currentLoan.loanAmount ? this.props.trueRate : '???'}<AfterRate> % p.a.</AfterRate></Rate>
                  </Tooltips>
                  :
                  <>
                    <Rate>{this.props.currentLoan.loanAmount ? this.props.trueRate : '???'}<AfterRate> % p.a.</AfterRate></Rate>
                  </>
              )}
              
              <div className="field-description true-rate">
                <span style={{verticalAlign: "top"}}>{product.initial_ir_type === "Fixed" ? "Revert Rate" : "True Rate"}</span>
                {!this.props.isPDF && product.initial_ir_type === "Fixed" &&
                  <Tooltips style={{marginTop: "-10px"}} type="definition" term="proposedOptionsRR" />
                }
                {product.initial_ir_type === "Fixed" 
                && (!this.props.isPDF && !noGodmode && this.props.godmode && this.props.currentLoan.loanAmount && !this.props.miniProposal.previewMode)
                && product.originalRR !== product.rr &&
                  <InlineBlueDot type="custom" definition={
                    'Original Value: ' + product.originalRR + '%'}
                  />  
                }
                
              </div>
              
            </div>
          </div>

          <div className="repayment-type">
            {product.repaymentType}
            {!this.props.isPDF &&
              ((product.repaymentType == "Interest Only") ? <Tooltips type="definition" term="interestOnlyShortlist" /> : (<Tooltips type="definition" term="piOrInterestShortlist" />))
            }
            <span className="field-description">Repayment Type</span>
          </div>

          <div className="fees">
            <div className="upfront-fee" style={{ width: "37%"}}>
              <OptionalTooltip className="value" active={!!product.upfrontRebate && !(!noGodmode && this.props.godmode && !this.props.miniProposal.previewMode)} definition={`Upfront fees factor in a $${product.upfrontRebate} post-settlement rebate from us, full details outlined above`}>
             
                $<Span  hidden={!active} disabled={!active} name='approxUpfrontFee' as={EditableContent} onEnter={this.blurOnEnter}/>
                <InitialFeeSpan  hidden={active}>{formatNumber(Math.max(parseNumber(this.props.approxUpfrontFee) - (product.upfrontRebate || 0), 0))}</InitialFeeSpan>
                {(parseNumber(this.props.approxUpfrontFee) !== product.originalApproxUpfrontFee || !!product.upfrontRebate) && !noGodmode && this.props.godmode && !this.props.miniProposal.previewMode && !this.props.isPDF &&
                  <InlineBlueDot type="custom" definition={'Original Value: $' + product.originalApproxUpfrontFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 
                  (product.upfrontRebate ? 
                    (parseNumber(this.props.approxUpfrontFee) !== product.originalApproxUpfrontFee ?  
                      '\nModified Value: $' + this.props.approxUpfrontFee : "") 
                    + '\nRebate: $' + formatNumber(product.upfrontRebate) : '')} 
                  />
                }
                <RateBox blueBox={!!product.upfrontRebate}>{product.upfrontRebate ? "Effective" : "Approx"} Upfront</RateBox>
              </OptionalTooltip>
            </div>
            
            
            <div className="ongoing-fee" style={{ width: "37%"}}>
              $<Span disabled={!active} name='ongoing_fees' as={EditableContent} onEnter={this.blurOnEnter}/>
              {parseNumber(this.props.ongoing_fees) !== product.original_ongoing_fees && !noGodmode && this.props.godmode && !this.props.miniProposal.previewMode && !this.props.isPDF &&
                <InlineBlueDot type="custom" definition={'Original Value: $' + product.original_ongoing_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
              }
              <span className="field-description" style={{ marginBottom: '6px'}}>Annual Fees</span>
            </div>
            

          </div>

          <CostReducers className="cost-reducers" isPDF={this.props.isPDF} isEditable={active}>
            <ul>
              <li onClick={(e) => active && this.switchCostReducers(this.props.redraw_allowed, "redraw_allowed", e)} className={(this.props.redraw_allowed === 'Y').toString() + (this.compareReducers(product.original_redraw_allowed, this.props.redraw_allowed) ? " changed" : "")}>
                Redraw
              {(this.compareReducers(product.original_redraw_allowed, this.props.redraw_allowed) ? <BlueDots className="blue-dot" type="custom" definition={(product.original_redraw_allowed === 'Y' ? "Original value: ✓ Active" : "Original value: X Inactive")} /> : "")}
              </li>
              <li onClick={(e) => active && this.switchCostReducers(this.props.full_offset, "full_offset", e)} className={(this.props.full_offset === 'Y' ? 'true' : this.props.full_offset === 'N' ? 'false' : 'additional') + (this.compareReducers(this.props.full_offset, product.original_full_offset) ? " changed" : "")}>
                
                {this.props.full_offset === 'additional' ? 
                  <Tooltips type="wrapper" definition={this.props.offsetTooltip}>Offset</Tooltips>
                  :
                  <span>Offset</span>
                }
                {(this.compareReducers(this.props.full_offset, product.original_full_offset) ? 
                  <BlueDots 
                    className="blue-dot" 
                    type="custom" 
                    definition={(product.original_full_offset === 'Y' ? 
                    "Original value: ✓ Active" : 
                    product.original_full_offset === 'additional' ? 
                    "Original value: $ Custom" : 
                    "Original value: X Inactive")} 
                  /> 
                  : "")}
              </li>
              <li onClick={(e) => active && this.switchCostReducers(this.props.extra_repayments_allowed, "extra_repayments_allowed", e)} className={(this.props.extra_repayments_allowed === 'Y') + (this.compareReducers(product.original_extra_repayments_allowed, this.props.extra_repayments_allowed) ? " changed" : "")}>
                Extra Repayment
              {(this.compareReducers(product.original_extra_repayments_allowed, this.props.extra_repayments_allowed) ? <BlueDots className="blue-dot" type="custom" definition={(product.original_extra_repayments_allowed === 'Y' ? "Original value: ✓ Active" : "Original value: X Inactive")} /> : "")}
              </li>
            </ul>
          </CostReducers>
          {!this.props.isPDF &&
          <div>
            <div className="more-info">
              <DetailsButton isSingle={currentLoan.newLoanPurpose !== 'refinance'} to={"/products/" + this.props.calculateBox.id + '/' +  this.props.tabId} className="details">Details</DetailsButton>
              {currentLoan.newLoanPurpose === 'refinance' &&
                <AnalysisContainer>
                 <button onClick={this.props.setRefinance}><span>Analysis</span></button>
                 <span className="sub-text" onClick={this.props.setRefinance}>See your savings!</span>
               </AnalysisContainer>
              }
            </div>
          </div>
          }
          <div className='arrow'/>
        </Form>
      </ProposalProduct>
      
      
    );
  }
}

const OptionalTooltip = ({active, children, ...props}) => {
  if (active) return (
    <Tooltips type='wrapper' {...props}>
      {children}
    </Tooltips>
  );
  else return (
    <span {...props}>
      {children}
    </span>  
  );
}

const roleRateDefinition = (rr, orr) => {
  if (orr) {
    if (orr === rr) {
      return 'Original revert: ' + orr + '%'
    } else {
      return 'Overidden revert: ' + rr + '%\n' + 'Original revert: ' + orr + '%'; 
    }
  }
  return 'Original revert: ' + rr + '%'
}

const FixedTerm = styled.span`
  ${p => p.editMode && css`
    border-bottom: dotted 1px #727C8F;
  `}
  
  ${p => p.overriden && css`
    color: #2291FF;
    border-color: #2291FF;
  `}
`;

const Span = styled.span`
  display: ${p => p.hidden ? 'none' : 'inline-block'};
  outline: none;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const InitialFeeSpan = styled.div`
  display: ${p => p.hidden ? 'none' : 'inline-block'};
`;

const RateBox = styled.span`
  ${p => p.blueBox && css`
    display: inline-block !important;
    color: white !important;
    font-weight: bold !important;
    background-color: #2291FF;
    padding: 4px 5px;
    border-radius: 6px;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

const Rate = styled.div`
    ${p => p.hidden && css`
      display: hidden;
    `}
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    text-align: center;
`;

const AfterRate = styled.span`
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: #727C8F;
`;

const ProposalProduct = styled.div`
  //width: calc(33% - 11px) !important;

  width: 280px;
  margin-bottom: 28px;
  box-sizing: border-box;
  white-space: normal;
  
  ${props => props.purchase && css`
    padding-bottom: 10px !important;
    min-height: 565px !important;
  `}

  ${props => props.active && css`
    z-index:  500;
  `}

  @media (max-width: 645px) {
    margin-left: 20px !important;
    margin-right: 0 !important;
  }

  ${props => props.refinance && css`
    .arrow {
      position: absolute;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      left: 0;
      right: 0;
      bottom: -70px;

      ${props => props.numberOfLoans === 3 && css`
        ${props => props.index === 0 && css`
          background-image: url(${require('../../../img/proposal/arrow-right.png')});
          width: 29px;
          height: 46px;
          left: 139px;
        `}

        ${props => props.index === 1 && css`
          background-image: url(${require('../../../img/proposal/arrow-down.png')});
          bottom: -44px;
          width: 16px;
          height: 36px;
          left: 131px;
        `}

        ${props => props.index === 2 && css`
          background-image: url(${require('../../../img/proposal/arrow-left.png')});
          width: 29px;
          height: 46px;
          left: 110px;
        `}
      `}

      ${props => props.numberOfLoans === 2 && css`
        ${props => props.index === 0 && css`
          background-image: url(${require('../../../img/proposal/arrow-left.png')});
          width: 29px;
          height: 46px;
          left: 110px;
        `}

        ${props => props.index === 1 && css`
          background-image: url(${require('../../../img/proposal/arrow-right.png')});
          width: 29px;
          height: 46px;
          left: 135px;
        `}
      `}

    }
  `};

  .logo-wrapper {
    height: 34px;
  }

  ${props => props.recommended && css`
    border: 2px solid #2689EB !important;

    &:after {
      position: absolute;
      content: 'Recommended Solution';
      color: #2689EB;
      font-weight: 700;
      bottom: -28px;
      left: 0;
      right: 0;
      width: 100%; 
      text-align: center;
    }

      &:before {
      position: absolute;
      content: 'Recommended Solution';
      color: #2689EB;
      font-weight: 700;
      top: -28px;
      left: 0;
      right: 0;
      width: 100%; 
      text-align: center;
    }

    .arrow {
      bottom: -80px;
    }

    .logo-wrapper {
      height: 32px;
    }

  `};

  ${p => p.isPDF && css`
    min-height: 0 !important;
  `}
`;

const BlueDots = styled(Tooltips)`
  position: absolute !important;
  height: 7px !important;
  width: 7px !important;
  left: 0 !important;
  bottom: -10px !important;
  background-color: #2291FF !important;
  border-radius: 50% !important;
  margin-left: 0 !important;
`;

const InlineBlueDot = styled(Tooltips)`
  display: inline-block !important;
  height: 7px !important;
  width: 7px !important;
  background-color: #2291FF !important;
  border-radius: 50% !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
`;

const AutoCalculated = styled.div`
  position: relative;
  background: none !important;
  width: 100% !important;
  display: block !important;
  color: #2689eb !important;
  height: 12px;
  font-size: 11px !important;
  font-weight: 700 !important;
`;

const CostReducers = styled.div`
  text-align: center;
  ul {
    li {
      ${p => p.isEditable && css`
        cursor: pointer;
      `}
      position: relative;
      margin-bottom: 5px;
    }
  }

  ${p => p.isPDF && css`
    border-bottom: none !important;
    padding-bottom: 2px !important;
  `}
`;

const DetailsButton = styled(LinkButton)`
  width: Calc(50% - 12px) !important;
  ${p => p.isSingle && css`
    margin-right: 0 !important;
  `}
  /* @media(max-width: 1060px) {
    width: 100% !important;
    margin: 0 0 10px 0 !important;
  } */
`;

const AnalysisContainer = styled.div`
  vertical-align: top;
  display: inline-block;
  width: Calc(50% - 12px);

  @media(max-width: 645px) {
    width: 100%;
  }

  button {
    vertical-align: top;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: inherit;
    font-size: 16px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 13px 50px 13px 50px;
    color: #fff;
    background-color: #2291FF;
    transition: background 500ms;
    padding: 9px 0;
    font-weight: 700;
    font-size: 13px;
    margin-right: 5px;
    width: 110px;
    transition: background color 0.3s;
    box-sizing: border-box;
    border: 1px solid #1C83EA;
    padding: 10px 2px 10px 2px;
    margin-right: 0;
    width: 100%;

    &:hover {
      background-color: #46A3FF;
    }

    span {
      background-image: url(${require('../../../img/button/stats-white-large.png')});
      background-size: 14px 14px;
      background-position: 0% 50%;
      padding-left: 22px;
      background-repeat: no-repeat;
    }
  }

  .sub-text {
    font-weight: 400;
    font-size: 11px;
    color: #2291FF;
    text-align: center;
    margin-top: 7px;
    margin-right: 5px;
    display: block;
    cursor: pointer;
  }

`;



function repaymentValue(product, freq) {
  let value;
  if (freq === 'weekly') value = product.weeklyRepayments;
  else if (freq === 'fortnightly') value = product.fortnightlyRepayments;
  else value = product.monthlyRepayments;

  if (value === 'NaN') return 0;
  return value;
}

function totalCost(product, freq) {
  if (freq === 'weekly') return product.totalCostWeekly;
  if (freq === 'fortnightly') return product.totalCostFortnightly;
  return product.totalCost;
}

function toggleTypeClass(type) {
  if (type === "dashboard") {
    return "col-c3 shortlist-result";
  }
  if (type === "check-eligibility") {
    return "check-eligibility-selection shortlist-result";
  }
  else {
    return "shortlist-result";
  }
}

function Award(value) {
  if (value !== '') {
    return <div className="best">{value}</div>
  }
  else {
    return ''
  }
}

ProposalProductBox = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  onChange: (values, dispatch, props) => {
    
    let loan = values;

    let monthlyFee = (parseNumber(loan.ongoing_fees) + (Math.max(parseNumber(loan.approxUpfrontFee) - (loan.upfrontRebate || 0), 0)) / 30) / 12;
    if (!monthlyFee) monthlyFee = 0;
    let trueRate = calculateTrueRate({ 
      rate: (parseFloat(loan.iir) - (loan.trailRebate || 0)) / 100, 
      monthlyFee, 
      loanAmount: parseNumber(props.currentLoan.loanAmount), 
      loanPeriod: parseInt(props.currentLoan.newLoanPeriod) || 30,
      fixedTerm: loan.fixed_term / 12,
      rr: loan.rr / 100,
    });
    
    dispatch(props.change('trueRate', trueRate));
  }
})(ProposalProductBox);

export default connect(mapStateToProps, mapDispatchToProps)(ProposalProductBox);
import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';
import calculateLoanValues from "utils/calculateLoanValues";
import { closeModal, openModal } from 'modules/modals/actions';
import { requestLogin, requestRemoveProductFromApplication } from 'modules/user/actions';
import BankLogo from 'Components/Global/BankLogo';
import Tooltips from "Components/Tooltips";

const { $ } = window;

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    applicationProducts,
    products,
    application
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    removeProductFromApplication: requestRemoveProductFromApplication,
  }, dispatch);
}

class QualifyMeSmallProductBox extends Component {

  constructor(props) {
    super(props);
    this.state= {isUnstarShown: false};
    this.state= {isShownMore: true};
  }

  componentDidMount() {
    $('body').click(e => {
      if (e.target.className === "confirm-removal") return;
      if (this.state.isUnstarShown) {
        this.setState({isUnstarShown: false});
      }
    });
  }

  toggleUnstarPopup(){
    this.setState((prevState) => {return {...prevState, isUnstarShown: !prevState.isUnstarShown}});
  }

  render() {
    let product = calculateLoanValues(this.props.product);

    return (
      <div>
        <div className="product-select">
          <BankLogo product={product} />
          <h6>{product.product_name}</h6>

          <div className="interest-rate">
            <div className="variable-rate">
              <div className="rate">{product.iir}<span>% p.a.</span></div>
              <div className="rate-description">{product.rateDescriptionYears}</div>
            </div>
            <div className="comparison-rate">
              <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
              <div className="rate-description">Comparison Rate</div>
            </div>
          </div>

          <div className="principal-interest">
            <h5>{product.repaymentType}</h5>
            <div className="description">Repayment Type</div>
          </div>

          <ul className="statistics">
            <li className={product.redraw_allowed}><span>Redraw</span></li>
            <li className={product.full_offset}>
              {product.full_offset === 'additional' ? 
                <Tooltips type="wrapper" definition={product.offsetTooltip}><span className='content'>Offset</span></Tooltips>
                :
                <span>Offset</span>
              }
            </li>
            <li className={product.extra_repayments_allowed}><span>Early Repayments</span></li>
          </ul>


          <div className="fees">
            <div className="upfront-fee">${product.approxUpfrontFee}<span>Approx Upfront</span></div>
            <div className="annual-fee">${product.ongoing_fees}<span>Annual Fee</span></div>
          </div>
        </div>
        <div onClick={()=>this.toggleUnstarPopup()} className={"edit " + (this.state.isUnstarShown?"on":"off")}></div>
        {(this.state.isUnstarShown)?<div onClick={()=>{confirmRemoval(this.props, ()=>this.toggleUnstarPopup())}} className="confirm-removal">Confirm Removal?</div>:""}
      </div>
    )
  }
}

function confirmRemoval(props, toggleFunc) {
  toggleFunc();
  props.removeProductFromApplication({applicationId: props.application.id, product_id: props.product.id})
}

export default connect(mapStateToProps, mapDispatchToProps)(QualifyMeSmallProductBox);
import React, {Component} from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux'

import { DoubleFieldGroup, FieldGroup, RateFieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

const $ = window.$;

class InterestRate extends Component {

  ref = null;

  componentDidMount() {
    const inputs = $('#ir-question').find('input');
    inputs.keydown(e => {
      if (e.keyCode === 13 && !this.props.interestRate) {
        e.preventDefault();
        this.skip();
      } 
    })
  }

  skip = () => {
    this.props.change('user_meta-refinanceInterestRate', '');
    this.props.skipOptionals();
  }

  render() {
    return (
      <QuestionWrapper alwaysRender={this.props.alwaysRender} isTransitioning={this.props.isTransitioning} noHooks invalid={this.props.invalid} gotoQuestion={this.props.gotoQuestion} invalid={this.props.invalid} debounce={this.props.debounce} id={this.props.id} currentQuestion={this.props.currentQuestion} setQuestion={this.props.setQuestion} onSubmit={this.props.handleSubmit} maxQuestion={this.props.maxQuestion} >
        <div id='ir-question'>
          <h1>What is your current interest rate?</h1>
          <h2>This is an optional question. If you’re not sure, an approximation is fine.</h2>
          <RateFieldGroup id='user_meta-refinanceInterestRate' width='large' position='left' hideOptional />
          <Navigation optional skip={this.skip} hidden={this.props.pristine } name="Next Step →" />
        </div>
      </QuestionWrapper>
    )
  }
}

InterestRate = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-interestRate'  // a unique identifier for this form
})(InterestRate);

const stateToProps = state => {
  let interestRate = null;
  if (state.form['onboarding-interestRate']
  && state.form['onboarding-interestRate'].values) {
    interestRate = state.form['onboarding-interestRate'].values['user_meta-refinanceInterestRate'];
  }
  return {interestRate}
}; 

export default connect(stateToProps)(InterestRate)
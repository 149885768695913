import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

class CheckEligibilityFormResetNotification extends Component {
  render() {
    if (this.props.currentModal === "CheckEligibilityFormResetNotification") {
    //console.log('modal opened!!!! :O')
    return (
      <div className="modal-content">
        <h1>This will reset some of your progress?</h1>
        <p>The change you have made will affect some of the questions you have already answered.</p>
        <p>Please check over your the questions to continue</p>
        <div className="modal-navigation">
          <button onClick={()=>this.props.closeModal()} className="single button-1">Okay</button>  
          {/* <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="right double">Get in Touch</button> */}
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default CheckEligibilityFormResetNotification;

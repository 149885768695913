import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import CurrentLoanDetailsForm from "./Forms/CurrentLoanDetails"
import { connect } from 'react-redux';

class CurrentLoanDetails extends Component {
  render() {
    if (this.props.currentModal === "currentLoanDetails") {
    return (
      <div className="modal-content">
        <h1>{this.props.tabId ? 'Edit Loan Details' : 'Your Current Loan'}</h1>
        <CurrentLoanDetailsForm button={this.props.tabId ? "Save" : "Add loan"} />
      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(CurrentLoanDetails);

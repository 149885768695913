import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, SubmissionError, formValueSelector, blur, Field } from 'redux-form';
import { FieldGroup, FormContainer, DoubleFieldGroup, PasswordField, Input } from "Components/Forms";
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { requestPatchUser } from 'modules/user/actions';
// import _ from 'lodash';

class Switch extends React.Component {
  render() {
    return (
      <SwitchButton className="switch" {...this.props}>
        <Field name={this.props.name} type="checkbox" class="switch_1" component={Input} />
        <SwitchButtonLabel>Breakdown</SwitchButtonLabel>
      </SwitchButton>
    )
  }
}
const SwitchButton = styled.div`
  display: inline-block;
  vertical-align: middle;

  transition: opacity 0.2s;
  ${props => props.disabled && css`
    display: none;
  `}

  input, span {
    display: inline-block !important;
    vertical-align: middle;
  }

  input {
    width: 30px !important;
    height: 15px !important;

    &:after {
      width: 14px !important;
      height: 14px !important;
    }

    &:checked:after {
      left: calc(100% - 15px) !important;
    }
  }
`;

const SwitchButtonLabel = styled.span`
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}


const mapStateToProps = (state, ownProps) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Switch);

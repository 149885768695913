import React from "react"; import { Component, Fragment } from "react";
import { reduxForm, getFormValues, change as changeForm } from "redux-form";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import updateFormValues from "modules/updateFormValues";
import { requestPatchUser } from 'modules/user/actions';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import initializeFormValues from "utils/initializeFormValues";
import { openRefinanceModal, closeModal } from 'modules/modals/actions';
import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, SearchableSelectGroup, RateFieldGroup } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import calculateTrueRate from "utils/calculateTrueRate";
import parseNumber from 'utils/parseNumber';
import _ from 'lodash';

const $ = window.$;

function mapStateToProps(state) {
  console.log(state);
  return {
    user: state.user.meta,
    lenders: state.user.lenders,
    userId: state.user.id,
    formValues: state.form['current-loan'] && state.form['current-loan'].values || undefined,
    product: state.modals.args,
    tabId: (state.modals.args || {}).tabId,
    miniProposal: state.user.meta.miniproposal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    requestPatchUser,
    openRefinanceModal,
    closeModal,
  }, dispatch);
}

const LOAN_AMOUNT = 'user_meta-refinanceLoanAmount';
const INTEREST_RATE = 'user_meta-refinanceInterestRate';
const REPAYMENT_TYPE = 'user_meta-refinanceRepaymentType';
const LOAN_PERIOD = 'user_meta-refinanceYears';
const MONTHLY_REPAYMENT = 'user_meta-refinanceMonthlyRepayment';
const LENDER = 'user_meta-refinanceLender';
const OTHER_LENDER_NAME = 'user_meta-refinanceOtherLenderName';
const RATE_TYPE = 'rateType';
const NEW_LOAN_PERIOD = 'newLoanPeriod';
const NEW_LOAN_PURPOSE = 'newLoanPurpose';

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(interestRate / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = interestRate * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}


class CurrentLoanDetails extends Component {
  state = {
    monthlyRepayments: 0,
  }

  componentDidMount() {

    initializeFormValues("current-loan", (vals, state) => {
      const user = state.user.meta; 
      const { calculators, miniproposal } = state.user.meta;
      
      let values = {};
      if (!this.props.tabId) {
        values = {
          [LOAN_AMOUNT]: calculators.refinanceLoanAmount || user.loanAmount,
          [INTEREST_RATE]: calculators.refinanceInterestRate,
          [REPAYMENT_TYPE]: calculators.refinanceRepaymentType,
          [LOAN_PERIOD]: calculators.refinanceYears,
          [MONTHLY_REPAYMENT]: calculators.refinanceMonthlyRepayment,
          ...vals,
          "user_meta-initialLoanDetails": "1",
        }
      } else {
        const loan = miniproposal.properties[this.props.tabId].currentLoan;
        values = {
          [LOAN_AMOUNT]: loan.loanAmount,
          [INTEREST_RATE]: loan.interestRate,
          [REPAYMENT_TYPE]: loan.repaymentType,
          [LOAN_PERIOD]: loan.loanPeriod,
          [MONTHLY_REPAYMENT]: loan.monthlyRepayment,
          [LENDER]: loan.lender,
          [OTHER_LENDER_NAME]: loan.otherLenderName,
          [NEW_LOAN_PURPOSE]: loan.newLoanPurpose,
          [NEW_LOAN_PERIOD]: loan.newLoanPeriod,
          [RATE_TYPE]: loan.rateType,
        }
      }
      

      console.log('Form values', values);

      const monthlyRepayments = calculateRepayments({
        loanAmount: values[LOAN_AMOUNT] || 0,
        interestRate: values[INTEREST_RATE] / 100 || 0,
        repaymentType: values[REPAYMENT_TYPE],
        loanPeriod: values[LOAN_PERIOD] || 30,
      });

      this.setState({ monthlyRepayments });

      return values;
    }, [OTHER_LENDER_NAME]);

    
  }

  componentWillReceiveProps(nextProps) {
    const newValues = nextProps.formValues;
    const values  = this.props.formValues;
    console.log('values', this.props.formValues);
    if (!values) return;
    if (
      values[LOAN_AMOUNT] !== newValues[LOAN_AMOUNT] ||
      values[INTEREST_RATE] !== newValues[INTEREST_RATE] ||
      values[REPAYMENT_TYPE] !== newValues[REPAYMENT_TYPE] ||
      values[LOAN_PERIOD] !== newValues[LOAN_PERIOD]
    ) {
      const monthlyRepayments = calculateRepayments({
        loanAmount: newValues[LOAN_AMOUNT] || 0,
        interestRate: newValues[INTEREST_RATE] / 100 || 0,
        repaymentType: newValues[REPAYMENT_TYPE],
        loanPeriod: newValues[LOAN_PERIOD] || 30,
      });
      if (!values[MONTHLY_REPAYMENT] || values[MONTHLY_REPAYMENT] === this.state.monthlyRepayments) {;
        this.props.change(MONTHLY_REPAYMENT, monthlyRepayments);
      }

      this.setState({monthlyRepayments});
    }

  }
  
  onRepaymentBlur = e => {
    if (!this.props.formValues[MONTHLY_REPAYMENT]) {
      this.props.change(MONTHLY_REPAYMENT, this.state.monthlyRepayments);
      e.preventDefault();
    }
  }

  onSubmit = results => {
    return new Promise(resolve => {
      if (!this.props.tabId) {
        updateFormValues(results, ()=>{
          if (this.props.location.pathname === '/loan-shortlist') {
            this.props.openRefinanceModal(this.props.product)
          } else {
            this.props.closeModal();
          }
          resolve();
          setTimeout(() => {
            if ($('.refinance-content').length) {
              $('main').animate({
                  scrollTop: $('.refinance-content').offset().top - $('main').offset().top + $('main').scrollTop() - 90
              }, 400);
            }
          }, 10)
  
        }, false, false);
      } else {
        const currentLoan = {
          loanAmount: results[LOAN_AMOUNT],
          interestRate: results[INTEREST_RATE],
          repaymentType: results[REPAYMENT_TYPE],
          loanPeriod: results[LOAN_PERIOD],
          monthlyRepayment: results[MONTHLY_REPAYMENT],
          lender: results[LENDER],
          otherLenderName: results[OTHER_LENDER_NAME],
          rateType: results[RATE_TYPE],
          newLoanPeriod: results[NEW_LOAN_PERIOD],
          newLoanPurpose: results[NEW_LOAN_PURPOSE],
        }

        const loans = this.props.miniProposal.properties[this.props.tabId].loanComparison.loans;
        const loanChanges = {};
        for (const key in loans) {
          const loan = loans[key];
          const originalMonthlyFee = (parseNumber(loan.original_ongoing_fees) + parseNumber(loan.originalApproxUpfrontFee) / 30) / 12;
          let originalTrueRate = calculateTrueRate({ 
            rate: parseFloat(loan.originalIIR) / 100, 
            monthlyFee: originalMonthlyFee, 
            loanAmount: parseNumber(currentLoan.loanAmount),
            loanPeriod: parseInt(results[NEW_LOAN_PERIOD] || 30),
            fixedTerm: loan.fixed_term / 12,
            rr: (loan.originalRR || loan.rr) / 100,
          });
          if (!originalMonthlyFee) originalTrueRate = loan.originalIIR;
          
          let monthlyFee = (parseNumber(loan.ongoing_fees) + parseNumber(loan.approxUpfrontFee) / 30) / 12;
          if (!monthlyFee) monthlyFee = 0;
          let trueRate = calculateTrueRate({ 
            rate: parseFloat(loan.iir) / 100, 
            monthlyFee, 
            loanAmount: parseNumber(currentLoan.loanAmount),
            loanPeriod: parseInt(results[NEW_LOAN_PERIOD] || 30),
            fixedTerm: loan.fixed_term / 12,
            rr: loan.rr / 100,
          });
          
          const loanChange = loanChanges[key] = {}
          loanChange.trueRate = trueRate;
          loanChange.originalTrueRate = originalTrueRate;
          
          this.props.changeForm('loan-' + this.props.tabId + '-' + currentLoan.productId, 'trueRate', trueRate);
        }
        this.patchProposal({ currentLoan, loanComparison: { loans: loanChanges } });
        this.props.closeModal();
        resolve();
      }
    })
  }

  patchProposal = (data, callback) => this.props.requestPatchUser({ 
    userId: this.props.userId,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [this.props.tabId]: data,
          }
        }
      } 
    },
    callback, 
  });

  // patchLoans = (loans, callback) => {
    

  //   const data = { loans }; 
  //   this.props.requestPatchUser({ 
  //     userId: this.props.userId,
  //     params: {
  //       mergeMeta: true,
  //     },
  //     data: { 
  //       meta: { 
  //         miniproposal:{
  //           properties: {
  //             [this.props.tabId]: {
  //               loanComparison: data
  //             }
  //           }
  //         }
  //       } 
  //     },
  //     callback, 
  //   });
  // }

  render() {
    const { formValues } = this.props;

    

    let lenderOptions = [
      {name: "Other", value: 'other'},
      ...this.props.lenders
        .filter(({ id }) => id !== 'MSL') // filter out Mystery Lender
        .sort((a, b) => a.name.localeCompare(b.name))
        .map( ({ id, name }) => ({ name, value: id }))
    ];
    
    const isProposal = !!this.props.tabId;

    if (isProposal) lenderOptions = [{ name: 'None', value: ''}, ...lenderOptions];

    if (!isProposal) return (
      <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)} className="form your-details" >
        <FieldGroup id={LOAN_AMOUNT} width='large' position='left' title='What is the current loan amount?' required='No' type='text' placeholder='0' mask="monetary" />
        <RateFieldGroup id={INTEREST_RATE} width='large' position='left' title='What is the current interest rate?' required='No' />
        <SelectGroup id={REPAYMENT_TYPE} width='large' position='left' title='What is the repayment type?' required='No' type='select' placeholder='Single' values={[{text: 'Principal & Interest', value: 'PandI'}, {text: 'Interest Only', value: 'Interest'}]}/>
        <FieldGroup id={LOAN_PERIOD} width='large' position='left' title='What is the remaining loan term?' type='text' placeholder='30' maxNumber={99} mask='int'/>
        <FieldGroup id={MONTHLY_REPAYMENT} width='large' position='left' title='What is the approximate monthly repayment?' required='No' type='text' placeholder='0' mask="monetary" autoCalculated={this.props.formValues && this.state.monthlyRepayments === this.props.formValues[MONTHLY_REPAYMENT]} onBlur={this.onRepaymentBlur}/>
        <SearchableSelectGroup size='large' id={LENDER} width={380} position='left' title='Who is the current lender?' type='select' values={lenderOptions} dontFilter={['other']}/>
        {(this.props.formValues || {})[LENDER] === 'other' &&
          <FieldGroup id={OTHER_LENDER_NAME} width='large' position='left' title='What is the current lenders name?' required type='text'/>
        }
        <button className="analyse"><ButtonSpinner loading={this.props.submitting}/>{this.props.button}</button>
      </FormContainer>
    );

    return (
      <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit)} className="form your-details" >
        <RadioToggleGroup id={NEW_LOAN_PURPOSE} width='small' position='left' title='Loan Purpose' hideOptional placeholder='Purchase' unchecked='Purchase' valueUnchecked='purchase' checked='Refinance' valueChecked='refinance' />
        <FieldGroup id={LOAN_AMOUNT} width='large' position='left' title='Loan Amount' required='No' type='text' placeholder='0' mask="monetary" />
        {(this.props.formValues || {})[NEW_LOAN_PURPOSE] === 'refinance' ?
          <Fragment>
            <RateFieldGroup id={INTEREST_RATE} width='large' position='left' title='Interest Rate' required='No' />
            <SelectGroup id={RATE_TYPE} width='large' position='left' title='Rate Type' required='No' type='select' placeholder='Single' values={[{text: 'Variable', value: 'Variable'}, {text: 'Fixed', value: 'Fixed'}]}/>
            <SelectGroup id={REPAYMENT_TYPE} width='large' position='left' title='Repayment Type' required='No' type='select' placeholder='Single' values={[{text: 'Principal & Interest', value: 'PandI'}, {text: 'Interest Only', value: 'Interest'}]}/>
            <FieldGroup id={LOAN_PERIOD} width='large' position='left' title='Remaining Loan Term' type='text' placeholder='30' maxNumber={99} mask='int'/>
            <FieldGroup id={NEW_LOAN_PERIOD} width='large' position='left' title='New Loan Term' type='text' placeholder='30' maxNumber={99} mask='int'/>
            <FieldGroup id={MONTHLY_REPAYMENT} width='large' position='left' title='Approximate Monthly Repayment' required='No' type='text' placeholder='0' mask="monetary" autoCalculated={this.props.formValues && this.state.monthlyRepayments === this.props.formValues[MONTHLY_REPAYMENT]} onBlur={this.onRepaymentBlur}/>
            <SearchableSelectGroup size='large' id={LENDER} width={380} position='left' title='Current Lender?' type='select' values={lenderOptions} dontFilter={['other']}/>
            {(this.props.formValues || {})[LENDER] === 'other' &&
              <FieldGroup id={OTHER_LENDER_NAME} width='large' position='left' title='Lender Name' required type='text'/>
            }
          </Fragment>
          :
          <Fragment>
            <FieldGroup id={NEW_LOAN_PERIOD} width='large' position='left' title='Loan Term' type='text' placeholder='30' maxNumber={99} mask='int'/>
          </Fragment>
        }
        <button className="analyse"><ButtonSpinner loading={this.props.submitting}/>{this.props.button}</button>
      </FormContainer>
    );
  }
}

CurrentLoanDetails = reduxForm({
  form: "current-loan", // a unique identifier for this form
})(CurrentLoanDetails);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentLoanDetails)
);
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form';

import { DoubleFieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

import { createNumberBetween } from 'Components/Forms/validation';
const numberBetween = createNumberBetween(1, 999999999, (low, high) => `Combined income must be between $${low} and $${high}`); 

const selector = formValueSelector('onboarding-combined-income')
function mapStateToProps(state) {
  return {
    value: selector(state, 'user_meta-householdIncome'),
    initialValues: {
      "user_meta-householdIncomeType" : "1",
    }
  };
}


let CombinedIncome = function(props) {

  const skip = () => {
    props.change('user_meta-householdIncome', '');
    props.skip();
  }

  return (
    <QuestionWrapper alwaysRender={props.alwaysRender} isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
        <h1>What's your combined income?</h1>
        <h2>Your (plus your partner’s) income, including any rental income, before tax.</h2>
        <div className='onboarding-currency'>
          <DoubleFieldGroup maxNumber={999999999} id='user_meta-householdIncome' width='large' position='left' hideOptional type='monetary-time' mask='int' />
        </div>
        
        <Navigation optional skip={skip} hidden={props.pristine || !props.value || props.value === 0} name="Next Step →" />
    </QuestionWrapper>
  )
}

CombinedIncome = reduxForm({
  form: 'onboarding-combined-income',  // a unique identifier for this form
  destroyOnUnmount: false
})(CombinedIncome);

export default connect(mapStateToProps, null)(CombinedIncome);

import React from "react";
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { reduxForm, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { FieldGroup } from "Components/Forms";
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { Header, Subtitle } from "../../theme/General";
import SectionButton from "../../component/SectionButton";
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import { EditableParagraph } from 'Components/Forms';

import _ from 'lodash';
const $ = window.$;


class ExecutiveSummary extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  save = () => {
    let values = {...this.props.formValues};
    delete values.enabled;

    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;
    if (!this.props.isForSummary) {
      toSave = {
        properties: {
          [this.props.tabId]: {
            execSummary: data
          }
        }
      }
    } else {
      toSave = {
        summary: {
          execSummary: data
        }
      }
    }

    this.props.requestPatchUser({ 
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  } 

  enableEdit = () => {
    const text = this.props.text;
    setTimeout(() => {
      this.inputRef.current.focus();
    });
    setTimeout(() => {
      if (!text || text === 'Add Text Here') {
        document.execCommand('selectAll');
      }
    }, 5);
    this.props.toggleEdit();
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { user, miniProposal } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    const showText = this.props.active || this.props.text !== "Add Text Here";
    return (
      <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        <ExecutiveSummaryContainer active={this.props.active} isView={this.props.isView}>
          
            {!noGodmode && !!user.godmode && !miniProposal.previewMode && !this.props.isPDF &&
              <SectionButton
                removeSection={this.props.removeSection}
                enableEdit={() => this.enableEdit()}
                isEditable={this.props.active}
                cancelEdit={() => this.cancel()}
                type="section" 
                style={{ float: 'right', position: 'absolute', top: '0', right: '0' }}
                cancelSaveContainer="20px"
              />
            }
            <Header isPDF={this.props.isPDF && (this.props.isForSummary || this.props.isTop)}><Span name='title' as={EditableContent} disabled={!this.props.active} onEnter={this.blurOnEnter}/></Header>
            <TextBox>
              {showText &&
                <EditableParagraph style={{color: '#575E6C'}} richText={!!this.props.active} innerRef={this.inputRef} allowLineBreaks onKeyDown={e => this.onKeyDown(e)} className={this.props.active ? 'active' : ''} name='text' disabled={!this.props.active}/>
              }
            </TextBox>
          
        </ExecutiveSummaryContainer>
      </Form>
    )
  }

}

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50px;
  font-weight: 700;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const ExecutiveSummaryContainer = styled.div`
  position: relative;
  max-width: 920px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* margin: 40px 0 30px 0; */
  transition: max-height 0.2s;
  /* max-height: 1000px; */

  ${props => props.active && css`
    z-index:  500;
  `}

  ${props => !props.isView && css`
    max-height: 0;
    overflow: hidden;
  `}
`;

const TextBox = styled.div`
  /* margin-bottom: 30px
  
  p {
    color: #575E6C;
    font-size: 16px !important;
    line-height: 1.4;
  } */
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const selector = formValueSelector('executive_summary');
const mapStateToProps = (state, props) => {
  if (props.isForSummary) {
    return {
      text: ((state.form[props.form] || {}).values || {}).text,
      user: state.user,
      miniProposal: state.user.meta.miniproposal,
      initialValues: (state.user.meta.miniproposal.summary || {}).execSummary,
      formValues: (state.form[props.form] || {}).values || {},
      browser: state.browser,
    };
  }
  
  return {
    text: ((state.form[props.form] || {}).values || {}).text,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: state.user.meta.miniproposal.properties[props.tabId].execSummary,
    formValues: (state.form[props.form] || {}).values || {},
    browser: state.browser,
  };
}

export default reduxForm({
  //form: 'executive_summary',
  enableReinitialize: true,
  destroyOnUnmount: false,
})

(connect(mapStateToProps, mapDispatchToProps)(ExecutiveSummary));

import React from "react"; import { Component } from "react";
import styled from 'styled-components';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';

import pngChecklist from 'img/page_illustrations/sms.png'
import { TextArea as TextAreaBase, FormContainer, Input } from "Components/Forms";
import { emailService } from 'modules'; 

import { lighten } from "polished";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

import moment from 'moment';

class NextStep extends Component {
  
  state = {
    addAmendment: false,
    submitting: false,
    signature: '',
  }

  onSubmit = values => {
    if (!this.state.signature || !this.state.signature.trim()) {
      throw new SubmissionError({
        _error: "Please fill in your name before proceeding",
      })
    };

    this.setState({ submitting: true});
    setTimeout(() => {
      this.setState({ submitting: false});
      this.continue({...values, signHere: this.state.signature });
    }, 1500)

  };

  continue = values => {

    this.props.closeModal();
    this.props.openModal('proceedThankYou');

    if (!values.amendment) {
      values.amendment = '';
    }

    const patch = {
        agreedToProceed: moment().format(),
        overview: {
            proceed: values,
        }
    };

    this.props.requestPatchUser({ 
        userId: this.props.user.id,
        params: {
          mergeMeta: true,
        },
        data: { 
          meta: { 
            miniproposal: patch,
          } 
        },
        callback: () => {
          emailService.create({ type: 'next-steps' });
        }
    });
    
  }

  addAmendment = () => {
    this.setState({ addAmendment: true });
  }
  
  render() {

    if (this.props.currentModal === "nextStep") {

    return (
      <div className="modal-content">
        <FormContainer onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
          <Row>
            <ImgColumn>
              <ImgChecklist />
            </ImgColumn>
            <ContentColumn>
              <Heading>Ready To Proceed?</Heading>
              <Paragraph>
                With your acceptance of our proposal, we’ll start 
                getting everything ready to lodge your application(s). 
                As part of the process, we will need to collect some 
                additional information as well as supporting 
                documentation from yourself.
              </Paragraph>
              {!!this.state.addAmendment ? 
                <>
                  <Bold>
                    Please add your comments regarding the proposal below
                  </Bold>
                  <TextArea id='amendment'/>
                  <Bold style={{marginBottom: '30px'}}>
                    Please enter your name below to confirm you wish to 
                    proceed with applying for the recommended solution, 
                    factoring in the above comments / amendments.
                  </Bold>
                </>
                :
                <Bold>
                  Please enter your name below to confirm you wish to 
                  proceed with applying for the recommended solution.
                </Bold>
              }
              
              
              <SignHere onChange={e=>this.setState({ signature: e.target.value })} value={this.state.signature}/>
              <SignHereLine />
              {this.props.error && 
                <ErrorText>{this.props.error}</ErrorText>
              }
              {!this.state.addAmendment ?
                <SmallText>
                  Want to make an amendent to the solution or add an advisory note?&nbsp; 
                  <Blue onClick={() => this.addAmendment()}>Click here</Blue> to expand the comment box.
                </SmallText>
                :
                <EmptySpace />
              }
              

              <BigButton><ButtonSpinner loading={this.state.submitting} />Agree to Proceed</BigButton>
            </ContentColumn>
          </Row> 
        </FormContainer>
        

      </div>
    ) } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default reduxForm({ form: 'next-step' })(connect(stateToProps)(NextStep));

const Row = styled.div`
  display: flex;
  width: 770px;
  text-align: left !important;

  @media (max-width: 645px) {
    width: 100%;
  }
`;

const Column = styled.div`
  display: inline-block;
  width: ${p => p.width};
`;

const ImgColumn = styled(Column)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const ContentColumn = styled(Column)`
  width: 385px; 
  
  @media (max-width: 645px) {
    width: 100%;
  }
`;

const EmptySpace = styled.div`
  height: 30px;
`;

const ImgChecklist = styled.img.attrs({
  src: pngChecklist,
})`
  width: 240px;
  margin-right: 33px;
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Paragraph = styled.p`
  color: #575e6c !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
`;

const Bold = styled.p`
  color: #101922 !important;
  font-family: Lato !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
`;

const SmallText = styled.p`
  color: #727c8f !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  margin-bottom: 30px !important;
`;

const ErrorText = styled.p`
  color: red !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
`;

const Blue = styled.span`
  display: inline-block;
  cursor: pointer;
  color: #2D91F9 !important;
`;

const TextArea = styled(TextAreaBase)`
    appearance: none;
    resize: vertical;
    border: 1px solid #D8DCE7;
    padding: 15px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    width: 100%;
    height: 110px;
    border-radius: 4px;
    margin-bottom: 20px;
  `;

  const SignHere = styled.input`
    padding-left: 20px;
    border: none !important;
    height: 60px !important;
    font-family: 'adobe-handwriting-frank' !important;
    font-size: 42px !important;
    font-weight: 400 !important;
    line-height: 56px !important;
    background: white;
    position: relative;
    width: 100%;
    text-rendering: auto !important;
  `;

  const SignHereLine = styled.div`
    height: 2px;
    width: 100%;
    background-color: #2291FF;
    position: relative;
    top: -15px;
  `


  const BigButton = styled.button`
    border: none !important;
    width: 250px !important;
    height: 52px !important;
    border-radius: 4px !important;
    background: #2291ff !important;
    color: #ffffff !important;
    font-family: Lato !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    float: right;
    cursor: pointer;
    transition: all 0.3s ease-in-out; 

    @media (max-width: 400px) {
      width: 100% !important;
    }

    &:hover {
      background-color: ${lighten(0.05, '#2291FF')} !important;
    }
  `; 
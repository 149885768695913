import React, { Component } from "react";
import styled, { css } from 'styled-components'

class Hamburger extends Component {
  render() {
    return (
      <HamburgerMenu active={this.props.mobileMenu}  className={this.props.className} >
        <HamburgerBox active={this.props.mobileMenu}>
          <HamburgerInner active={this.props.mobileMenu}></HamburgerInner>
        </HamburgerBox>
      </HamburgerMenu>
    )
  }
}


const HamburgerMenu = styled.button`
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }
`;

const HamburgerBox = styled.span`
  width: 23px;
  height: 23px;
  display: inline-block;
  position: relative;

  @media(max-width: 680px) {
    width: 16px;
    height: 16px;
  }
`;

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${props => props.active && css`
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    @media(max-width: 680px) {
      bottom: -4px;
    }

  `}

  &, &::before, &::after {
    width: 24px;
    height: 4px;
    background-color: #000;
    /* border-radius: 4px; */
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    background-color: #575E6C;

    @media(max-width: 680px) {
      width: 16px;
      height: 3px;
    }

    ${props => props.active && css`
      background-color: #575E6C;
    `}
  }

  &::before, &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -9px;
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    @media(max-width: 680px) {
      top: -7px;
    }

    ${props => props.active && css`
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

      @media(max-width: 680px) {
        top: 0;
      }
  `}
  }

  &::after {
    bottom: -10px;
    top: -18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;

    @media(max-width: 680px) {
      top: -14px;
    }

    ${props => props.active && css`
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 

      @media(max-width: 680px) {
        top: 0;
      }
    `}
  }
`;
export default Hamburger;
import React from "react"; import { Component } from "react";

import Navigation from "Components/Navigation/Navigation";
import Divider from "./Components/Divider";
import { NavLink } from "react-router-dom";

import {googleSignin, facebookSignin} from "utils/oauth"
import SignupForm from "Components/Accounts/SignupForm";
import updateFormValues from "modules/updateFormValues"

function handleSubmit(result) {
  //console.log("Result:");
  //console.log(result);
  if (result["user-password"] === result["confirmPassword"]) {
    updateFormValues(result, () => {});
  } else {
    alert("Passwords do not match");
  }
  return false;
}

class SignUp extends Component {
  render() {
    return (
      <div>
        <Navigation state="unactive" />
        <main className="user-authentication sign-up">
          <div className="modal">
            <div className="left-image">
              <div className="quote">
                <div className="quote-symbol">"</div>
                <div className="quote-content">
                  <strong>SecureFinance</strong> recently helped me with a loan for a new home purchase. The entire process was efficient and stress-free. I have no hesitation recommending their services to others and will never be going direct to the banks again! <span>- Jason, Raceview (QLD)</span>
                </div>
              </div>
            </div>

            <div className="right-content">
              <h2>Create an Account</h2>
              <h4>If you don't have an account, let's create one!</h4>

              <SignupForm type="signup-3" onSubmit={handleSubmit} />

              <Divider />
              <div className="alternative-signin">
              <button className="google" onClick={()=>googleSignin()}>Sign In with Google</button>
                <button className="facebook" onClick={()=>facebookSignin()}>Sign In with Facebook</button>
              </div>

              <div className="navigation">
                Already have an account? <NavLink exact to="/login">Click here to Sign In.</NavLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default SignUp;

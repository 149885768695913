import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { setTitle, setPageID } from 'modules/page/actions';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import getQueryParams from "utils/getQueryParams";
import {requestValidationEmail} from 'modules/user/actions';
import Debouncer from "utils/debouncer";
import LinkButton from "Components/LinkButton";
import { push } from 'connected-react-router';

const debouncer = new Debouncer();

function mapStateToProps(state) {
  return {
    isVerified: state.user.isVerified,
    email: state.user.email,
    verifyFinished: state.user.verifyFinished
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTitle,
    setPageID,
    requestValidationEmail,
    push
  }, dispatch);
}

class ValidationResult extends Component {

  constructor() {
    super();
    let queryParams = getQueryParams();
    this.state = {
      continue: queryParams.continue || "",
      email: queryParams.email
    }
  }

  componentDidMount() {
    document.title = 'Secure Finance';
    this.props.setTitle("Secure Finance");
    this.props.setPageID("verify-success");
  }

  sendValidationEmail = function() {
    let link = this.state.continue;
    debouncer.run(() => {
      this.props.requestValidationEmail({
        email: this.props.email || this.state.email,
        continue: link,
        callback: ()=>{
          this.props.history.push('/validation-email-sent?continue=' + link);
        }
      });
    }, 5000)
    
  }

  render() {
    return (
      <main className={"verify " + this.props.type}>      
      {
        this.props.verifyFinished && ((this.props.isVerified) ? (
        <div className="main-container">
          <h1>E-mail Address Validated</h1>
          <p>Thanks for validating your e-mail address.<br/>Please click the button below to continue.</p>
          <LinkButton to={"/" + this.state.continue} class="button">Continue</LinkButton>
          <img src={require('img/page_illustrations/success_2.png')} alt='floating man'/>
        </div>
        ) : (
          <div className="main-container">
          <h1>Email Validation Expired</h1>
          {
            (this.props.email || this.state.email) &&
            [<p>Too much time has elapsed since you requested your email validation link and clicked on it.</p>,<p>Please click the button below if you would like us to e-mail a new email validation link.</p>,
            <button class="button" onClick={()=>this.sendValidationEmail()}>Resend validation</button>]
          }
        </div>
        ))
      }
      </main>
      );
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ValidationResult);

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import calculateLoanValues from "utils/calculateLoanValues";
import { requestLogin } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import BankLogo from 'Components/Global/BankLogo';


import LinkButton from "Components/LinkButton";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
  }, dispatch);
}

class ProductExists extends Component {
render() {
  let product = calculateLoanValues(this.props.product);
  
    return(
        <div className="product exist">
          <div className="product-content">
            <div className="product-hover">
              <div className="product-hover-container">
                <LinkButton to={"/products/" + product.id} onClick={()=>this.props.closeModal()} className="product-details">Product Details</LinkButton>
                <button onClick={()=>this.props.openModal("checkEligibilityView")} className="compare">Compare</button>
              </div>
            </div>
            <div className="logo">
              <BankLogo product={product} />
            </div>
            <div className="approximate-repayment">
              <div className="repayment">
                <div className="rate">${Math.round(product.monthlyRepayments).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span>/month</span></div>
                <div className="rate-description">Approximate Repayment</div>
              </div>

            </div>
            <div className="variable-comparison">

              <div className="variable-rate">
                <div className="rate">{product.iir}<span>% p.a.</span></div>
                <div className="rate-description">{product.rateDescriptionYears}</div>
              </div>
              <div className="comparison-rate">
                <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
                <div className="rate-description">Comparison Rate</div>
              </div>
            
            </div>


            <div className="product-name"><span>{product.product_name}</span></div>

          </div>
        
          <div className="close" onClick={()=>{this.props.removeProductFromApplication({applicationId: this.props.application.id, product_id: this.props.product.id})}} ></div>
        </div>
    )};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductExists);
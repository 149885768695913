import React, { Fragment, Component } from 'react';
import { NavLink } from "react-router-dom";
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { createRequired, createValidateEmail } from 'Components/Forms/validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { requestLogin } from 'modules/user/actions';
import requestFilteredProducts from "modules/product/requestFilteredProducts";
import LoginForm from 'Components/Accounts/LoginForm';
import { PasswordField, FieldGroup } from 'Components/Forms';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';

const emailRequired = createRequired('Please enter your email');
const passwordRequired = createRequired('Please enter your password')
const validateEmail = createValidateEmail();

const $ = window.$;

class Form extends Component { 
  
  componentDidMount() {
    $('.email').find('input').focus();
  }

  submit = ({ email, password }) => {    
    return new Promise((resolve, reject) => 
      this.props.requestLogin({
        callback: result => {
          if (!result.success) {
            return reject( new SubmissionError({
              _error: result.errorMessage || 'Email or password are incorrect',
            }));
          } else {
            requestFilteredProducts();
            return resolve();
          }
        },
        data: {
          email,
          password
        }
      })
    );
  };

  render() {
    let { error, handleSubmit, submitting } = this.props;
    return (
      <div className="form" style={{position: 'relative'}}>
        <form onSubmit={handleSubmit((result)=>this.submit(result))}>
          <FieldGroup  hideOptional id='email' width="100%" title="Email Address" validate={[emailRequired, validateEmail]} placeholder="Enter Email" type='text' formError={error}/>
          <div className='w100p ls text field'>
            <label className="password">Password</label>
            <NavLink tabIndex={10} className="forgot-password" exact to="/forgot-password">Forgot Password?</NavLink>
            <PasswordField id='password' hideOptional component={Input} validate={passwordRequired} placeholder="Enter Password" formError={error}/>
          </div>
          
          <button type="submit" style={{marginTop: '10px'}}><ButtonSpinner loading={submitting}/>Sign In to My Account</button>
          {error && <div className="validation" style={{position: 'relative'}}>{error}</div>}

        </form>
      </div>
    )
  }
}

Form = reduxForm({
  form: 'login',
  destroyOnUnmount: false,
})(Form)

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestLogin,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Form);


const Input = ({meta: {touched, error}, input, formError, ...props}) => {
  return ( 
  <Fragment>
    <input
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <div className='field-error-message'>{error}</div>} 
  </Fragment>
  )
};

import React, { useCallback } from 'react';
import Img from 'react-image';
import { logoFolder, uploadUrl } from 'modules';


const getAltLogo = (id) => {
  try {
    return require(`img/banks/${id}.png`);
  } catch(e) {
    return '';
  }
}

export default ({ product, ...props }) => {
  if (!product.bank) {
    return (
      props.unloader || <h6 {...props}>{product.bank_name}</h6>
    );
  }

  const logoSrc = uploadUrl + '/' + logoFolder + '/' + product.bank + '.png';

  if (props.isPDF) {
    return (
      <img 
        className="company-logo" 
        src={logoSrc} 
        alt="logo" 
        crossorigin="anonymous"
      />
    )
  }

  return (
    <Img 
      className="company-logo" 
      src={[logoSrc, getAltLogo(product.bank)]} 
      alt="logo" 
      loader={<div {...props}><div className='image-spinner'/></div>}
      unloader={<h6>{product.bank_name}</h6>}
      crossOrigin="anonymous"
      {...props}
    />
    
  )
    
}




  
import React from "react"; import { Component } from "react";
import Form from "./Form";
import { connect } from 'react-redux';

class LoanbaseTrailRebate extends Component {
  render() {
    const { miniProposal } = this.props;
    let initialValues = ((miniProposal || {}).overview || {}).loanbaseTrailRebate || {};

    if (this.props.currentModal === "loanbaseTrailRebate") {
    return (
      <div className="modal-content">
        <h1>Secure Finance Trail Rebate</h1>
        <Form initialValues={initialValues} />
      </div>
    ); } else {
      return "";
    }
  }
}

const stateToProps = state => ({
  tabId: (state.modals.args || {}).tabId,
});

export default connect(stateToProps)(LoanbaseTrailRebate);

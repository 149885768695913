import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { Header } from "../../theme/General";
import ProposalProductBox from "../../component/ProposalProductBox";
import { NavLink } from "react-router-dom";
import RefinanceSavings from "../sections/RefinanceSavings";
import { requestPatchUser } from 'modules/user/actions';
import formatNumber from 'utils/formatNumber';
import parseNumber from 'utils/parseNumber';
import _ from 'lodash';

const $ = window.$;



function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    openModal
  }, dispatch);
}

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  return {
    shortlist: state.user.shortlist,
    user: state.user.meta,
    miniProposal: state.user.meta.miniproposal,
    products,
    application,
    userId: state.user.id,
    browser: state.browser,
  };
}

class ProposedOptions extends React.Component {

  constructor(props) {
    super(props);

    const loanCount = (props.data.loanOrder || []).length;
    this.state = {
      isRecommended: null,
      refinance: (loanCount === 3) ? 1 : 0,
      repaymentFrequency: 'monthly',
    };
  }

  componentDidMount() {

    const setOpacity = elm => {

      const totalScrollWidth = elm.scrollWidth - elm.clientWidth;
      if (totalScrollWidth < 10) {
        this.setState({ leftOpacity: 0, rightOpacity: 0 });
        return;
      }
      const leftScroll = elm.scrollLeft / totalScrollWidth;
      const rightScroll = (elm.scrollWidth - elm.clientWidth - elm.scrollLeft) / totalScrollWidth;
      let leftOpacity = Math.min(leftScroll * 10, 1);
      let rightOpacity = Math.max(Math.min(rightScroll * 10, 1), 0);
      console.log('opacity', leftOpacity, rightOpacity);
      this.setState({ leftOpacity, rightOpacity });
    }

    setOpacity($('#scroll-container')[0]);

    $('#scroll-container').scroll(e => {
      setOpacity(e.target);
    });
  }

  setRecommended(id) {
    //this.setState(state => ({ ...state, isRecommended: index }))
    let loanOrder = this.props.miniProposal.properties[this.props.tabId].loanComparison.loanOrder;
    loanOrder = loanOrder.filter(loanId => loanId !== id);
    loanOrder = [id, ...loanOrder];
    this.patchProposal(this.props.tabId, { 
      loanOrder,
      recommendationActive: true
    });
  }

  removeRecommended() {
    this.patchProposal(this.props.tabId, { recommendationActive: false });
  }

  setRefinance(index) {
    console.log(index);
    this.setState(state => ({ ...state, refinance: index }))
  }

  onSubmit = values => {
    if (values)
    this.setState({ repaymentFrequency: values.repaymentFrequency });
  }

  patchProposal = (id, data, callback) => this.props.requestPatchUser({ 
    userId: this.props.userId,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [id]: {
              loanComparison: data
            }
          }
        }
      } 
    },
    callback, 
  });

  onDelete = (index, selectedIndex, loanCount) => {
    if (loanCount < 3 || index === selectedIndex) return this.setRefinance(0);
    if (index === 0) return this.setRefinance(selectedIndex - 1)
    if (index === 2) return this.setRefinance(selectedIndex === 0 ? 1 : 0);
    return this.setRefinance(0);
  }

  render() {
    const showScrollHelp = this.props.browser.lessThan.w1060 || this.props.browser.is.w1060;
    let shortlistEdited = [...this.props.shortlist];
    let loans = this.props.data.loanOrder.map(id => {
      return this.props.data.loans[id];
    });

    let recommendedIndex = 0;
    if (loans.length === 3 && !showScrollHelp) {
      loans = [loans[1], loans[0], loans[2]];
      recommendedIndex = 1;
    }

    console.log("first loan:", loans[0]);
    return (
      [<ProposedOptionsContainer>
        <Heading isPDF={this.props.isPDF && this.props.isTop} style={{ marginTop: '0', fontWeight: '700'}}>Proposed Loan Products</Heading>
        
        {((!!(loans[0] || {}).trailRebate || !!(loans[0] || {}).upfrontRebate) || this.props.isPDF && loans.some(loan => loan.initial_ir_type === "Fixed")) &&
          <RebateInfoContainer isOverview={this.props.isOverview}>
            {!!(loans[0] || {}).trailRebate && 
              (loans[0].initial_ir_type === 'Fixed' ? 
                <p>
                  Please note that the <b>Effective Fixed</b> rate takes into account our annual trail rebate to you.
                  For the recommended solution, the actual fixed rate charged by the lender is {loans[0].iir}% pa, 
                  your trail rebate is {loans[0].trailRebate}% per annum (for the fixed term), 
                  so your effective / net rate is {formatNumber(Number.parseFloat(loans[0].iir) - loans[0].trailRebate, 2)}% pa.
                  {!this.props.isPDF &&
                    <> Please see further details <a href={require(`documents/trailrebatedisclaimer.pdf`)}>here</a>.</>
                  }
                  
                </p>
                :
                <p>
                  Please note that the <b>Effective Variable</b> rate takes into account our annual trail rebate to you.
                  For the recommended solution, the actual variable rate charged by the lender is {loans[0].iir}% pa, 
                  your trail rebate is {loans[0].trailRebate}% per annum (for the life of the loan), 
                  so your effective / net rate is {formatNumber(Number.parseFloat(loans[0].iir) - loans[0].trailRebate, 2)}% pa.
                  
                  {!this.props.isPDF &&
                    <> Please see further details <a href={require(`documents/trailrebatedisclaimer.pdf`)}>here</a>.</>
                  }
                </p>
              )
            }
            {!!(loans[0] || {}).upfrontRebate &&
              <p>
                Please note that the <b>Effective Upfront</b> fee takes into account our rebate to you.
                For the recommended solution, the actual estimated upfront fees charged by the lender are ${loans[0].approxUpfrontFee}, 
                your rebate is ${formatNumber(loans[0].upfrontRebate)} (payable after settlement), 
                so your effective / net upfront fees are ${formatNumber(Math.max(parseNumber(loans[0].approxUpfrontFee) - loans[0].upfrontRebate, 0))}.
              </p>
            }

            {this.props.isPDF && loans.some(loan => loan.initial_ir_type === "Fixed") &&
              <p>
                Please note that the <b>Revert Rate</b> is the variable rate applied after the fixed rate term has expired. 
                Before this occurs, we would generally work with to you to roll you into another competitive fixed rate, 
                negotiate a discount to the variable rate, or help you refinance to a more competitive solution elsewhere. 
                This means that you should not usually need to pay the revert rate.
              </p>
            }
          </RebateInfoContainer>
        }

        <div style={{position: 'relative'}}>
          {showScrollHelp &&
            <InformationHelp show>Scroll to see more</InformationHelp>
          }
          <GradientMask>
            <GradientOverlayRight opacity={this.state.rightOpacity}/>
            <GradientOverlayLeft opacity={this.state.leftOpacity}/>
          </GradientMask>
          <Loans isPDF={this.props.isPDF && this.props.isTop} id='scroll-container'>
            
            <div className="loan-container">
              {(loans.length > 0) ?
                loans.map((box, index) => {
                  const initialValues = {
                    ...box,
                    repaymentFrequency: this.state.repaymentFrequency,
                    iir: box.iir.toString(),
                    rr: box.rr.toString(),
                    approxUpfrontFee: (box.approxUpfrontFee || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    ongoing_fees: (box.ongoing_fees || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  }
                  return (
                    <ProposalProductBox
                      isPDF={this.props.isPDF}
                      recommendedIndex={this.props.data.recommendationActive ? recommendedIndex : -1}
                      removeRecommended={this.removeRecommended.bind(this)}
                      toggleEdit={() => this.props.toggleEdit(10 + index)}
                      active={this.props.editMode === (10 + index)}
                      cancelEdit={this.props.cancelEdit}
                      form={'loan-' + this.props.tabId + '-' + box.productId + '-' + this.props.index}
                      tabId={this.props.tabId}
                      repaymentFrequency={this.state.repaymentFrequency}
                      proposalId={this.props.tabId}
                      currentLoan={this.props.currentLoan}
                      index={index}
                      calculateBox={box}
                      initialValues={initialValues}
                      key={box.id}
                      onSubmit={this.onSubmit.bind(this)}
                      refinance={this.state.refinance}
                      setRecommended={() => this.setRecommended(box.id)}
                      setRefinance={() => this.setRefinance(index)}
                      numberOfLoans={loans.length}
                      onDelete={() => this.onDelete(index, this.state.refinance, loans.length)}
                    />
                )})
                :
                <EmptyLoans>
                  <img src={require('img/page_illustrations/search_empty_50.png')} alt="checkeligibilityhelp" />
                  <h4>Nothing added as yet.</h4>
                  <p>Go to <NavLink to="/search-loans/">Search Loans</NavLink> to populate this section.</p>
                </EmptyLoans>
              }
            </div>
          </Loans>
        </div>
      </ProposedOptionsContainer>
        ,
      (loans.length > 0 && this.props.currentLoan.newLoanPurpose === 'refinance') ? <RefinanceSavings isPDF={this.props.isPDF} currentLoan={this.props.currentLoan} product={loans[this.state.refinance || 0]}/> : ""
      ]
    )
  }

}

const RebateInfoContainer = styled.div`
  margin: 9px 0 52px 0;
  width: 100%;
  

  @media (max-width: 645px) {
    p {
      ${p => !p.isOverview &&
        css`
          padding-left: 20px;
          padding-right: 20px; 
        `
      }
      
    }
  }
`;

const Heading = styled(Header)`
  margin-bottom: 40px !important;
  @media(max-width: 480px) {
    margin-bottom: 25px !important;
  }
`;

const EmptyLoans = styled.div`
  margin: 10px 0 30px 0;
  text-align: center;
  

  img {
    width: 80%;
    max-width: 450px;
    max-height: 190px;
    object-fit: contain;
  }

  h4 {
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;

    a {
      color: #2291FF;
      cursor: pointer;

      &:hover {
        color: #46A3FF;
      }
    }
  }
`;

const GradientMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media(min-width: 1080px) {
    display: none;
  }

  @media(max-width: 645px) {
    display: none;
  }
  pointer-events: none;
  mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 88%, transparent 100%);
  z-index: 10000;
`;


const GradientOverlayLeft = styled.div`
  opacity: ${p => p.opacity};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: transparent;
  mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 88%, transparent 100%);
  background: linear-gradient(to right, rgba(0,0,0,5%) 0%, transparent 3%);
  z-index: 10000;
`;

const GradientOverlayRight = styled.div`
  opacity: ${p => p.opacity};
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: transparent;
  background: linear-gradient(to left, rgba(0,0,0,5%) 0%, transparent 3%);
  z-index: 10000;
`;


const Loans = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
        height: 14px;
  }

  ::-webkit-scrollbar-track {
    background: #E9EBF1;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border: solid 3px #E9EBF1;
    background: #888;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #626B7B;
  }
  

  @media (max-width: 1200px) {
    height: 675px;
    overflow-x: auto;
    margin-bottom: 15px;
  
    .arrow {
      display: none;
    }
  }

  @media (max-width: 645px) {
    .details {
      display: none !important;
    }
  }


  .loan-container {
    white-space: nowrap;
    display: block;
    margin-top: 25px;
    /* margin-bottom: 40px; */

    @media (min-width: 480px) {
      text-align: center;
    }    
  }

  ${p => p.isPDF && css`
    .proposal-product-box {
      margin-right: 40px;
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  `}
  
  
`;

const InformationHelp = styled.div`
  ${p => !p.show && css`display: none;`}
  position: absolute;
  right: 0;
  top: -40px;
  font-size: 13px;
  color: #727C8F;
  padding: 0 35px 3px 40px;

  &:after {
    content: '';
    display: inline-block;
    position: relative;
    height: 19px;
    width: 19px;
    top: 14px;
    left: 15px;
    transform: scaleX(-1);
    background-size: 19px 19px;
    background-repeat: no-repeat;
    background-image: url(${require('img/tooltips/arrow_left.png')});
  }
`;


const ProposedOptionsContainer = styled.div`

  p {
    margin-bottom: 22px;

    b {
      font-weight: bold;
    }
  }

  @media (max-width: 645px) {
    margin: 0 -20px 10px -20px !important;
    width: 90vw !important;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProposedOptions);

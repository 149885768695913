import { takeEvery, call, put } from 'redux-saga/effects';
import { findProducts, getProduct } from './api';

import {
  receiveProducts,
  REQUEST_PRODUCTS,

  receiveProduct,
  REQUEST_PRODUCT
} from './actions';

function* callProducts({ payload }) {
  const products = yield call(findProducts, payload);
  yield put(receiveProducts({products: products.list, isFinished: products.isFinished, isAddition: !!payload.query.$skip, total: products.total }));
}

export function* productsSaga() {
  yield takeEvery(REQUEST_PRODUCTS, callProducts);
}


function* callGetProduct({ payload }) {
  const product = yield call(getProduct, payload);
  yield put(receiveProduct(product));
}

export function* getProductSaga() {
  yield takeEvery(REQUEST_PRODUCT, callGetProduct);
}

import React from "react"; import { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';

function mapStateToProps(state) {
  return {
    user: state.user.meta,
    userId: state.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class ModuleBox extends Component {
  constructor(props) {
    super(props);

    this.state= {isShown: true};
  }

  toggleView(link){
    let newStateCalculators = this.props.user["pageState_dashboard_toggle_" + link]?0:1;

    this.props.requestPatchUser({userId: this.props.userId, data: {meta: {["pageState_dashboard_toggle_" + link]: newStateCalculators}}, callback: ()=>{}});
  }

  render() {

    return (
      <div className= {((!this.props.user["pageState_dashboard_toggle_" + this.props.id])?"active ":"") + typeOfClass(this.props.link, this.props.title, this.props.toggle, this.props.class)}>
        <div className="mod-title">
          {toggleTitle(this.props.toggle, this.props.title, this, this.props.link, this.props.id)}
          <NavLink exact to={"/" + this.props.link}>{this.props.linkText}</NavLink>
        </div>

        {/* {(!this.props.user["pageState_dashboard_toggle_" + this.props.id] || this.props.id === "lets-start" || this.props.alwaysShow === "true")? */}
          <div className={className(this.props.class) + " mod-content" } style={{
            maxHeight: ((!this.props.user["pageState_dashboard_toggle_" + this.props.id] || this.props.id === "lets-start" || this.props.alwaysShow === "true")?"2500px":"0"),
            overflow: ((!this.props.user["pageState_dashboard_toggle_" + this.props.id] || this.props.id === "lets-start" || this.props.alwaysShow === "true")?"none":"hidden"),
          }}
          >
            <div className={"animation-container" + ((this.props.shortlist < 4)?" remove-scroll-bar":"")}>
              {this.props.children}
            </div>
          </div>
          {/* :
          ""
        } */}
      </div>
    );
  }
}

function typeOfClass(link, title, toggle, className){
  let name = "col-m1 ";
  if (link) {
    name += " m-lnk ";
  }
  if (title) {
    name += " m-tlt "
  }
  if (toggle === "Y") {
    name += " m-const "
  }
  return name;
}

function toggleTitle(value, title, props, link, id){
  if (value === "Y") {
    return <button onClick={()=>props.toggleView(id)} className="title button">{title}</button>;
  }
  else {
    return <h3 className="title">{title}</h3>;
  }
}

function className(value){
  if (value) {
    return value;
  }
  else {
    return "";
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleBox);

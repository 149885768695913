import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTitle, setPageID } from "modules/page/actions";
import ProductDetailsDisclaimer from "Components/Global/ProductDetailsDisclaimer";

import styled, { css } from 'styled-components'
import VideoProposal from "./views/VideoProposal";
import Properties from "./views/Properties";
import RightSettings from "./component/RightSettings";
import _ from 'lodash';
import { requestPatchUser, requestSelf } from 'modules/user/actions';
import Summary from "./views/Summary";

import { PDFExport } from '@progress/kendo-react-pdf';
import { withSize } from 'react-sizeme';

import DetailedBreakdown from "./views/DetailedBreakdown";
import Overview from "./views/Overview";

import ProposalPDF from 'shared/ProposalPDF';
import download from 'downloadjs';
import moment from 'moment';

import { ButtonSpinner } from 'Components/Global/ButtonSpinner';

const $ = window.$;

function mapStateToProps(state) {
  const previewMode = (state.user.meta.miniproposal || {}).previewMode;
  const godmode = state.user.godmode;
  return {
    proposal: state.user.meta.proposal,
    user: state.user.meta,
    client: state.user,
    godmode,
    previewMode,
    userId: state.user.id,
    miniProposal: state.user.meta.miniproposal,
    activeTab: state.user.meta.proposalActiveTab || 0,
    browser: state.browser,
    isSaving: state.user.isSaving
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTitle,
      setPageID,
      requestPatchUser,
      requestSelf,
    },
    dispatch
  );
}

class Proposal extends Component {
  tabs = [];

  state = {
    tabFocused: false,
  }

  pdf = null;

  componentDidMount() {
    document.title = "Secure Finance- Proposal";
    this.props.setTitle("Proposal");
    this.props.setPageID("proposal");
    document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this), false );
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange.bind(this));
  }

  onVisibilityChange() {
    console.log(document.hidden);
    if (!document.hidden && this.props.godmode) {
      this.setState({ loadingSelf: true });
      this.props.requestSelf(
        () => {
          this.setState({ loadingSelf: false })
        }
      );
    }
  }
  // componentWillUnmount() {
  //   document.removeEventListener("visibilitychange");
  // }

  testPageHeight(height) {
    const { miniproposal } = this.props.user;
    let { maxPageHeightAccumulator, pdfPageCount, pagesTested } = this.state;
    if (!pdfPageCount) {
      const tabs = miniproposal.tabs || [];
      pdfPageCount = tabs.length;
      if (miniproposal.showSummary) pdfPageCount++;
      pagesTested = 0;
    }
    
    const maxHeight = (!maxPageHeightAccumulator || height > maxPageHeightAccumulator) 
      ? height : maxPageHeightAccumulator;
      pagesTested++;
    if (pagesTested === pdfPageCount) {
      this.setState({ 
        maxPageHeight: maxHeight, 
        pagesTested: 0, 
        pdfPageCount: 0, 
        maxPageHeightAccumulator: 0 
      }, () => {
        setTimeout(() => { // wait for logos to load correctly
          this.pdf.save();
          setTimeout(() => {
            this.setState({ savePDF: false });
          }, 1000);
        }, 500)
      });
    }
    else this.setState({ 
      maxPageHeightAccumulator: maxHeight, 
      pagesTested, 
      pdfPageCount 
    }); 
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      tabCount: 1,
    };
  }

  switchContentForPDF(content, that, index, data, isPDF) {
    switch (content) {
      case "video": return <VideoProposal />;
      case "proposal": return <Properties index={index} data={data} isPDF={isPDF}/>;
      case "summary": return <Summary isPDF={isPDF}/>;
    }
  }

  title(val) {
    switch (val) {
      case 'video': return 'Video';
      case 'summary': return 'Summary';
      default: return '';
    }
  }

  savePDF = () => {
    this.setState({ savePDF: true });
  }

  stopSavePDF = (blob) => {
    const now = moment();
    this.setState({ savePDF: false });
    download(blob, `Mortgage Proposal - ${this.props.client.lastName || this.props.client.firstName} - ${now.format('DDMMYY')}.pdf`, 'application/pdf');
  }

  render() {
    let { user, godmode, previewMode, miniProposal, client } = this.props;

    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (noGodmode) godmode = false;

    //let propertyTabs = (miniProposal.tabs)?.map(index => miniProposal.properties[index]);
    let propertyTabs = Array.isArray(miniProposal.tabs)
    ? miniProposal.tabs.map(index => miniProposal.properties[index])
    : [];


    let excludedText;
    {
      const excludedTabs = propertyTabs
        ?.filter(tab => tab.excludedFromOverview)
        .map(tab => `'${tab.name}'`) || [];

      if (excludedTabs.length) {
        const s = excludedTabs.length === 1; // is singular
        excludedText = `Note: The ${s ? 'tab' : 'tabs'} ${listToString(excludedTabs)} ${s ? 'has' : 'have'} been excluded from the overview.`;
      }
    }
    
    let incompleteText;
    {
      const incompleteTabs = propertyTabs
        ?.filter(tab => !tab.loanComparison.recommendationActive)
        .map(tab => `'${tab.name}'`) || [];

      if (incompleteTabs.length) {
        const s = incompleteTabs.length === 1; // is singular
        incompleteText = `Note: The ${s ? 'tab' : 'tabs'} ${listToString(incompleteTabs)} ${s ? 'has' : 'have'} no loan selected.`;
      }
    }

    let noIOTermText;
    {
      const noTermTabs = propertyTabs
        ?.filter(property => {
          const tab = property.loanComparison;
          const loanId = tab.loanOrder?.[0];
          if (!tab.recommendationActive || !loanId) return false;
          const loan = tab.loans[loanId];
          const isIO = (loan.io_allowed_for_own_occ === "Y" && loan.maximum_lvr_own_occ > 0) || (loan.io_repayments === "Y" && loan.maximum_lvr_investment > 0);
          return isIO && loan.initial_ir_type === 'Variable' && !loan.ioTerm;
        })
        .map(tab => `'${tab.name}'`) || [];

      if (noTermTabs.length) {
        const s = noTermTabs.length === 1; // is singular
        noIOTermText = `Note: The ${s ? 'tab' : 'tabs'} ${listToString(noTermTabs)} ${s ? 'has' : 'have'} no IO term selected`;
      }
    }

    const missingBroker = !client.proposalBrokerId;

    let hasVideoProposal = userHasVideoProposal(user);

    const { overviewActivated } = miniProposal;
    const showOverview = 
      overviewActivated 
      && godmode && 
      !previewMode && 
      (user.miniproposal || {}).draftMode;
    
    const hasMiniProposal =
      user.miniproposal &&
      user.miniproposal.draftMode &&
      (user.miniproposal.enabled || this.props.godmode);

    const tabs = getTabs(user, hasMiniProposal, hasVideoProposal, showOverview);

    const noTabs = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (noTabs) godmode = false;

    return (
      <main className="proposal">
        <div className="main-container">
        
        {!noTabs && !!this.props.godmode && (user.miniproposal || {}).draftMode &&
          <>
          {!!noIOTermText &&
            <IncompleteTabInfo>{noIOTermText}</IncompleteTabInfo>
          }
          {!!incompleteText &&
            <IncompleteTabInfo>{incompleteText}</IncompleteTabInfo>
          }
          {!!missingBroker &&
            <IncompleteTabInfo>Note: The proposal has no broker selected</IncompleteTabInfo>
          }
          {!!excludedText &&
            <ExcludedTabInfo>{excludedText}</ExcludedTabInfo>
          }   
          </>
        }
          
        {!noTabs && !!this.props.godmode && (user.miniproposal || {}).draftMode &&
          <TopOption>
            <RightNavigation savePDF={this.savePDF.bind(this)} savingPdf={this.state.savePDF} loading={this.state.loadingSelf}/>        
          </TopOption>
        }

        {(!overviewActivated || (!!godmode && !previewMode && (user.miniproposal || {}).draftMode)) ?
            <DetailedBreakdown tabs={tabs} overviewActivated={overviewActivated} showInfo={tabs?.length > 1} removeShowInfo={godmode && !previewMode}/>
            :
            <Overview tabs={tabs} showBreakdown={true} editMode={false}/>

        }
        
        </div>
        <Disclaimer />
        {!!this.props.godmode &&
          <PDF>
            <PDFExport
              paperSize='A4'
              fileName="proposal.pdf"
              forcePageBreak=".page-break"
              keepTogether=".content"
              title=""
              subject=""
              keywords=""
              ref={r => this.pdf = r}>
                {this.state.savePDF && !overviewActivated &&
                  tabs.map((value, index) => {
                    if (value.content === 'video') return null;
                    return (
                      <PagePDF value={value} that={this} index={index} user={user} tabs={tabs} maxPageHeight={this.state.maxPageHeight} testHeight={this.testPageHeight.bind(this)}/>
                    );
                  })
                }
              </PDFExport>
          </PDF>
        }

        {this.state.savePDF && overviewActivated &&
          <PDF>
            {/* <NewPDF stopSave={() => this.stopSavePDF()}/> */}
            <ProposalPDF user={client} settings={client.globalSettings} finished={(blob) => this.stopSavePDF(blob)}/>
          </PDF>
        }
         {/* <NewPDF stopSave={() => this.stopSavePDF()}/> */}
        
      </main>
    );
  }
}

// array to form a, b, c & d
const listToString = _list => {
  const list = [..._list];
  if (!(list || {}).length) return '';
  if (list.length === 1) return list[0];
  const last = list.pop();
  return `${list.join(', ')} & ${last}`;
};

const userHasVideoProposal = user => {
  if (user.proposal && user.proposal.enabled) {
      const value = user.proposal.expiry;
      if (value) {
          const day = parseInt(value.slice(0, 2));
          const month = parseInt(value.slice(2, 4));
          const year = parseInt(value.slice(4, 8));
          const expiry = new Date(year, month - 1, day);
          if (expiry > new Date()) return true;
      }
  }
  return false;
};

const getTabs = (user, hasMiniProposal, hasVideoProposal, showOverview) => {
      
      const propertyTabs = Array.isArray((user.miniproposal || {}).tabs)
      ? user.miniproposal.tabs.map((id, index) => {
          const property = ((user.miniproposal || {}).properties || {})[id];
          if (!property) {
            return null;
          }
          return {
            id,
            title: property.name,
            content: 'proposal',
            icon: 'file',
            color: newTabStyle[index + 1],
          };
        })
      : [];


  let tabs = [];
  if (hasMiniProposal) {
    tabs = propertyTabs?.filter(tab=>!!tab);
  }

  if (hasVideoProposal) {
    tabs = [
      {
        title: 'Video',
        content: 'video',
        icon: 'video',
        color: '#2291FF',
      },
      ...tabs,
    ]
  }

  if (hasMiniProposal && (user.miniproposal && user.miniproposal.showSummary && !showOverview)) {
    tabs = [
      {
        title: 'Summary',
        content: 'summary',
        icon: 'summary',
        color: '#2291FF',
      },
      ...tabs
    ]
  }

  if (showOverview) {
    tabs = [
      {
        title: 'Overview',
        content: 'overview',
        icon: 'summary',
        color: '#2291FF',
      },
      ...tabs
    ];
  }

  return tabs;
}

class PagePDF extends React.Component {
  
  state = {};

  shouldComponentUpdate(nextProps, nextState) { 
    if (nextProps.maxPageHeight !== this.props.maxPageHeight) return true;
    if (nextState.height !== this.state.height) return true;
    return false;
  }

  onSize = ({ height }) => {
    // height === 1555 is a hack, for some reason
    // the first size measurement for the page is always the (incorrect) value 1555
    // so I just ignore it...
    if (this.state.height || height === 1555) return;
    this.setState({ height }, () => {
      //console.log("Testing page height", this.props.index, height);
      this.props.testHeight(height);
    });
  }

  render() {
    let { maxPageHeight, index, tabs } = this.props;
    let scaling = 1;
    const height = this.state.height || 0;
    if (height && maxPageHeight) {
      if (maxPageHeight < 1600) {
        if (maxPageHeight < 1300) {
          scaling = 0.55;
        } else {
          scaling = ((1300 / maxPageHeight) * 0.55);
        }
      } else if (height > 1600) {
        scaling = ((1600 / height) * 0.45);
      } else {
        scaling = 0.45;
      }
    }
    
    //console.log('rendering pdf page:', index, height, scaling, maxPageHeight);
    
    
    return (
      <>
        <PDFPage>
          <PDFContent className='content' scaling={scaling || 1}>
            <ScalablePDFContent {...this.props} onSize={this.onSize.bind(this)}/>
          </PDFContent>
        </PDFPage>
        {index < tabs.length - 1 &&
          <div className='page-break'/>
        }
      </>
    )
  }
}

const ScalablePDFContentBase = ({ value, that, index, user, tabs }) => (
  <div>
    {that.switchContentForPDF(value.content, that, index, ((user.miniproposal || {}).properties || [])[value.id], true)}
  </div>
);

const ScalablePDFContent = withSize({ monitorHeight: true, monitorWidth: false })(ScalablePDFContentBase);


const PDF = styled.div`
  position: absolute;
  left: 10000px;
  top: -100000px;

  * {
    font-family: LatoPDF, sans-serif !important;
  }

  li {
    font-family: inherit;
  }
`;

const PDFPage = styled.div`
  position: relative;
  width: 595px;
  height: 842px;
  overflow: hidden;
  /* border: 1px solid purple; */
`;

const PDFContent = styled.div`
  min-width: 1100px;
  height: 1555px;
  position: relative;
  ${p => css`
    transform: scale(${p.scaling}, ${p.scaling});
    top: ${(p.scaling - 1) * 772.5 + 40}px;
  `}
  
  left: ${p => 90*p.scaling - 252.5}px;
`;

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50%;
  cursor: pointer;

  ${p => p.disabled ? '' : css`
    &:focus {
      border: solid 1px #A3A8B2;
      border-radius: 3px;
    }
  `}
`;
  
let newTabStyle = [
  '#2291FF',
  '#26BD27',
  '#BD10E0',
  '#FF4E4C',
  '#F5A623',
  '#00CD98',
  '#FF00B2',
  '#00E5FF',
  '#FF0000',
  '#2291FF',
  '#26BD27',
  '#BD10E0',
  '#FF4E4C',
  '#F5A623',
  '#00CD98',
  '#FF00B2',
  '#00E5FF',
  '#FF0000',
];

const TopOption = styled.div`
  width: 100%;
  height: 36px;
  margin-bottom: 15px;
`;

const RightNavigation = styled(RightSettings)`
  float: right;
`;

const Disclaimer = styled(ProductDetailsDisclaimer)`
  @media(min-width: 480px), @media(max-width: 950px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const ExcludedTabInfo = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #3F70F2;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #d8dce7;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const IncompleteTabInfo = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: red;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #d8dce7;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Proposal);

import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, Heading3, RadioBox, UnsavedChangesPrompt, RadioGroup, AddressFieldGroup } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import FormNavigation from "../Components/FormNavigation";

import { MultiStageForm, FormStage, FadeIn } from './Components/MultiStageForm';

let SimpleForm = props => {
  let stage = 0;
  if (props.loanPurpose) stage += 1
  if (props.loanPurpose === 'purposeRefinance') stage += 1
  if (props.currentStatus) stage += 1;
  return (
    <FormContainer onSubmit={props.handleSubmit}>
      <MultiStageForm main name='form' stage={stage}>

        <FormStage>
        <h1>Security Property</h1>
          <p className='under-heading'>The following questions will allow us to ensure we are qualifying you for an appropriate loan product for your situation.</p>

          <Heading3 width='large' position='left' title='What is the purpose of this loan?' />

          <div className="content four-box">
            <RadioGroup required name="application_meta-loanPurpose">
              <RadioBox text="Purchase" value="purposePurchase" select={props.loanPurpose} />
              <RadioBox text="Refinance" value="purposeRefinance" select={props.loanPurpose} />
              <RadioBox text="Construction" value="purposeConstruction" select={props.loanPurpose} />
            </RadioGroup>
          </div>
        </FormStage>

        <FormStage>
          {togglePurpose(props.loanPurpose, props.propertyPurpose, props.currentStatus)}
        </FormStage>

        <FormStage>
          {typePropertyDetailsBudget(props.currentStatus, props.loanPurpose)}
          <div className='extra-margin'/>
          {propertySizing(props.loanPurpose, props.currentStatus)}

          {toggleEstimatedRent(props.propertyPurpose, props.loanPurpose)}
          {toggleBorrowAdditionalFunds(props.loanPurpose, props.propertyBorrowFunds)}
        
          {/* {ownPropertyText(props.loanPurpose)} */}
          <Heading3 width='large' position='left' title='Who will own this property?' />
          {ownPropertyValues(props.firstName, props.lastName, props.partnerFirstName, props.partnerLastName, props.applicantType, props.loanPurpose)}
          {toggleOtherSituation(props.propertyOwner)}
          <FormNavigation currentPage="2" disabled={props.invalid} submitting={props.submitting}/>
        </FormStage> 
        
      </MultiStageForm>
      <UnsavedChangesPrompt when={!(props.pristine || props.submitting) }/>
    </FormContainer>
  );
};

function togglePurpose(purchase, propertyPurpose, currentStatus) {
  let stage = 0;
  if (propertyPurpose) stage += 1

  if (purchase === "purposePurchase") {
    return (
      <MultiStageForm name='purchase' stage={stage}>
        <FormStage>
          <Heading3 width='large' position='left' title='What is the purpose of this property?' />
          <div className="content four-box">
            <RadioGroup required name="application_meta-propertyPurpose">
              <RadioBox text="To Live In" value="purposeToLiveIn" select={propertyPurpose} />
              <RadioBox text="Investment" value="purposeInvestment" select={propertyPurpose} />
            </RadioGroup>
          </div>
        </FormStage>
        <FormStage>
          <Heading3 width='large' position='left' title='What stage are you at in your property hunt?' />
          <div className="content four-box">
            <RadioGroup required name="application_meta-currentStatus">
              <RadioBox text="Still Looking" value="statusStillLooking" select={currentStatus} />
              <RadioBox text="Found a Property" value="statusFoundAProperty" select={currentStatus} />
            </RadioGroup>
          </div>
        </FormStage>     
      </MultiStageForm>

    );
  }
  if (purchase === "purposeConstruction") {
    return (
      <MultiStageForm name='construction' stage={stage}>
        <FormStage>
          <Heading3 width='large' position='left' title='What is the purpose of this property?' />
          <div className="content four-box">
            <RadioGroup required name="application_meta-propertyPurpose">
              <RadioBox text="To Live In" value="purposeToLiveIn" select={propertyPurpose} />
              <RadioBox text="Investment" value="purposeInvestment" select={propertyPurpose} />
            </RadioGroup>
          </div>
        </FormStage>
        <FormStage>
          <Heading3 width='large' position='left' title='What stage are you at in your property hunt?' />
          <div className="content four-box">
          <RadioGroup required name="application_meta-currentStatus">
            <RadioBox text="Still Looking" value="statusStillLooking" select={currentStatus} />
            <RadioBox text="Found a Property" value="statusFoundAProperty" select={currentStatus} />
            <RadioBox text="Already Own the Land" value="statusAlreadyOwnLand" select={currentStatus} />
          </RadioGroup>   
          </div>
        </FormStage>     
      </MultiStageForm>
    );
  }
  else {
    return "";
  }
}

function typePropertyDetailsBudget(currentStatus, loanPurpose) {
  if (currentStatus === "statusStillLooking" && (loanPurpose === "purposePurchase" || loanPurpose === "purposeConstruction")) {
    return (<Heading3 width='large' position='left' title='Property Details & Budget' />);
  }
  else {
    return (<Heading3 width='large' position='left' title='Property Details & Budget' />);
  }
}

function toggleEstimatedRent(propertyPurpose, loanPurpose) {
  if (propertyPurpose === "purposeInvestment" && loanPurpose === "purposePurchase") {
    return (<DoubleFieldGroup required id='application_meta-propertyEstimatedRent' width='small' position='left' title='Estimated Rent of the Property' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />);
  }
  else {
    return "";
  }
}

function toggleBorrowAdditionalFunds(loanPurpose, propertyBorrowFunds) {
  if (loanPurpose === "purposeRefinance") {
    return (
      <Fragment>
        <SelectGroup required id='application_meta-propertyBorrowFunds' width='small' position='left' title='Do you wish to borrow any additional funds?' required='Yes' type='select' placeholder='No' values={['Yes','No']} />
        {(propertyBorrowFunds === "Yes")
          ?
          <FadeIn>
            <FieldGroup required id='application_meta-propertyAdditionalFunds' width='small' position='left' title='What do you want the additional funds for?' type='text' placeholder='E.g. Investment Purposes' />
            <FieldGroup required id='application_meta-propertyAdditionalFundsAmount' width='small' position='right' title='How much extra do you want to borrow?' type='text' placeholder='0' mask="monetary"/>
          </FadeIn>
          :
          ""
        }

      </Fragment>
    );
  }
  else {
    return "";
  }
}

function propertySizing(loanPurpose, currentStatus) {
  if (loanPurpose === "purposeConstruction" ) {
    switch (currentStatus) {
      case "statusStillLooking": return (
        <Fragment>
          <AddressFieldGroup id='application_meta-propertyAddress' width='large' position='left' title='Where are you looking?' type='text' placeholder='E.g. Surry Hills, Australia' />
          <FieldGroup required id='application_meta-propertyValue' width='small' position='left' title='What is the approximate purchase price?' required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application_meta-propertyConstructionCost' width='small' position='right' title='What is the approximate construction cost?' required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application-loanAmount' width='small' position='left' title='What is your desired loan amount?' required='Yes' type='text' placeholder='0' mask="monetary" />
        </Fragment>
      );
      case "statusFoundAProperty": return (
        <Fragment>
          <AddressFieldGroup id='application_meta-propertyAddress' width='large' position='left' title='What is the property address?' type='text' placeholder='E.g. Suite 510/46 Kippax St, Surry Hills NSW 2010' />
          <FieldGroup required id='application_meta-propertyValue' width='small' position='left' title='What is the purchase price?' required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application_meta-propertyConstructionCost' width='small' position='right' title='What is the approximate construction cost?' required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application-loanAmount' width='small' position='left' title='What is your desired loan amount?' required='Yes' type='text' placeholder='0' mask="monetary" />
        </Fragment>
      );
      case "statusAlreadyOwnLand": return (
        <Fragment>
          <AddressFieldGroup id='application_meta-propertyAddress' width='large' position='left' title='What is the property address?' type='text' placeholder='E.g. Suite 510/46 Kippax St, Surry Hills NSW 2010' />
          <FieldGroup required id='application_meta-propertyValue' width='small' position='left' title='What is the approximate land value?' required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application_meta-propertyCurrentMortgage' width='small' position='right' title="What is its current mortgage? (if any)" required='Yes' type='text' placeholder='0' mask="monetary" />
          <FieldGroup required id='application-loanAmount' width='small' position='left' title='What is your desired loan amount?' required='Yes' type='text' placeholder='0' mask="monetary" />
        </Fragment>
      );
      default: return "ERROR";
    }
  }
  else if (loanPurpose === "purposeRefinance" ) {
    return (
      <Fragment>
        <AddressFieldGroup id='application_meta-propertyAddress' width='large' position='left' title='What is the property address?' type='text' placeholder='E.g. Suite 510/46 Kippax St, Surry Hills NSW 2010' />
        <FieldGroup required id='application_meta-propertyValue' width='small' position='left' title='What is the estimated property value?' type='text' placeholder='0' mask="monetary" />
        <FieldGroup required id='application-loanAmount' width='small' position='right' title='How much are you refinancing?' type='text' placeholder='0' mask="monetary" />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <AddressFieldGroup id='application_meta-propertyAddress' width='large' position='left' title='What is the property address?' type='text' placeholder='E.g. Suite 510/46 Kippax St, Surry Hills NSW 2010' />
        <FieldGroup required id='application_meta-propertyValue' width='small' position='left' title='What is the approximate purchase price?' type='text' placeholder='0' mask="monetary" />
        <FieldGroup required id='application-loanAmount' width='small' position='right' title='What is your desired loan amount?' type='text' placeholder='0' mask="monetary" />
      </Fragment>
    );
  }

}

// function ownPropertyText(loanPurpose){
//   if (loanPurpose === "purposePurchase") {
//     return (<Heading3 width='large' position='left' title='Ownership' content='Who will own this property?' />);
//   }
//   else {
//     return (<Heading3 width='large' position='left' title='Ownership' content='Who owns this property?' />);
//   }
// }

function ownPropertyValues(firstName, lastName, partnerFirstName, partnerLastName, applicantType, loanPurpose) {
  const applicantName = firstName + (lastName ? (" " + lastName) : '');
  const partnerName = partnerFirstName + " " + partnerLastName;
  const together = applicantName + " & " + partnerName + " Jointly (50/50)";
  const other = 'Other (e.g. Tenants in Common)';

  if (applicantType === "justYou" || applicantType === "youAndOneOrMoreOthers" || applicantType === "otherSituation"){
    return (
      <SelectGroup hideOptional id='application_meta-propertyOwner' width='small' position='left' type='select' placeholder='' values={[{text: applicantName, value: 'user'}, {text: other, value:'other'}]} />
    );
  }
  if (applicantType === "youAndYourPartner"){
    return (
      <SelectGroup hideOptional id='application_meta-propertyOwner' width='small' position='left' type='select' placeholder='' values={[{text: applicantName, value: 'user'}, {text: partnerName, value: 'partner'},  {text: together, value: 'user&partner'}, {text: other, value:'other'}]} />
    );
  }
  else {
    return "ERROR: APPLICANT TYPE NOT SELECTED";
  }
}

function toggleOtherSituation(propertyOwner) {
  if (propertyOwner === "other") {
    return <FieldGroup id='application_meta-propertyOtherSituation' width='large' position='left' title='Other?' required='Yes' type='text' placeholder='Please explain.' />;
  }
  else {
    return "";
  }
}

SimpleForm = reduxForm({
  form: 'eligibility-securityProperties',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-securityProperties') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // external values
    const user = state.user;
    const firstName = state.user.firstName;
    const lastName = state.user.lastName;
    let partnerFirstName;
    let partnerLastName;
    if (state.user.partners && state.user.partners.length) { 
      partnerFirstName = state.user.partners[0].firstName;
      partnerLastName = state.user.partners[0].lastName;
    }
    const applicantType = state.user.applications[0].meta.applicantType;

    // internal values
    const loanPurpose = selector(state, 'application_meta-loanPurpose');
    const propertyPurpose = selector(state, 'application_meta-propertyPurpose');
    const currentStatus = selector(state, 'application_meta-currentStatus');
    const propertyBorrowFunds = selector(state, 'application_meta-propertyBorrowFunds');
    const propertyOwner = selector(state, 'application_meta-propertyOwner');

    return {
      loanPurpose,
      propertyPurpose,
      currentStatus,
      propertyBorrowFunds,
      firstName,
      lastName,
      partnerFirstName,
      partnerLastName,
      applicantType,
      propertyOwner,
      initialValues: {...getInitialValuesObject(state), "user_meta-eligibility_complete_page2": "1", "user_meta-eligibility_last_page": 2}
    }
  }
)(SimpleForm);

export default SimpleForm;

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { reduxForm, SubmissionError, formValueSelector, Field, blur } from 'redux-form';
import { FieldGroup, DoubleFieldGroup, FormContainer, PasswordField } from "Components/Forms";

import updateFormValues from "modules/updateFormValues"
import { requestSignup } from "modules/user/actions";
import { openModal, closeModal, openModalWithArgs } from 'modules/modals/actions';
import requestFilteredProducts from "modules/product/requestFilteredProducts"
import getInitialValuesObject from "utils/getInitialValuesObject";

import { createPasswordValidation, createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';

import { googleSignin, facebookSignin } from "utils/oauth"
import initializeFormValues from "utils/initializeFormValues";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

const passwordValidation = createPasswordValidation();
const passwordRequired = createRequired('Please enter a password');
const confirmationRequired = createRequired('You must confirm your password');


class SignupFormModal extends Component {

  state = {
    password: '',
  }

  redirect = null;

  redirectToAccounts() {
    // why do this? Bacause a direct history.push(...) wasn't working within the submit function for some reason
    if (this.redirect) {
      this.redirect.click();
    }
  }

  componentDidMount() {
    initializeFormValues('login');
  }

  submit = (results) => {

    return new Promise((resolve, reject) => {
      //console.log('this is happening!')
      if (results['user-password']) {
        if (results['user-password'] !== results['verifyPassword']) {
          return reject(new SubmissionError({
            'verifyPassword': 'Passwords do not match'
          }));
        }
      }

      updateFormValues(results, (result)=>{
        if (result && result.error) {
          if (result.isVerified) {
            reject(new SubmissionError({
              ...result.validation
            }));
          } else {
            this.props.closeModal();
            //console.log('modal args:', results)
            results.email = results['user-email'];
            this.props.openModalWithArgs({
              modal: 'loginStartedProcess', 
              args: {
                type: 'patch',
                userData: { ...results, result },
              }
            });
          }
        } else {
          if (this.props.type === "signup-1") {
            this.props.openModal("signup-2");
          } else if (this.props.type === "signup-2") {
            this.props.openModal("signUpFinish");
          } else if (this.props.type === "signup-3") {
            this.props.closeModal();
            this.redirectToAccounts();
          }
          resolve();
        }
      }, false, false);
    })
  }

  passwordsMatch = value => 
    value === this.props.password ? null : 'Passwords do not match';

  onPasswordChanged = e => {
    this.setState({ password: e.target.value }, () => {
      this.props.blur('login', 'confirmPassword', this.props.confirmPassword);
    });
  }

  render() {
    const { submitting } = this.props;
    if ( this.props.type === "signup-1") {
      return (
        <div className="form">
          <FormContainer onSubmit={this.props.handleSubmit(results=>this.submit(results))}>
            <FieldGroup id='user-email' width='100%' title='Email Address' required='Yes' type='text' placeholder='Enter Email' />
            <button className="submit-button sign-me-up" type="submit"><ButtonSpinner loading={submitting}/>Sign Me Up</button>
          </FormContainer>
          <div className="alternative-signin">
            <button className="google" onClick={()=>googleSignin()}>Sign In with Google</button>
            <button className="facebook" onClick={()=>facebookSignin()}>Sign In with Facebook</button>
          </div>
          <button type="button" style={{display: 'none'}} ref={ref => this.redirect = ref} onClick={() => this.props.history.push("/accounts")}></button>
        </div>
      )
    }
    if ( this.props.type === "signup-2") {
      return(
      <div className="form">
        <FormContainer onSubmit={this.props.handleSubmit(results=>this.submit(results))}>

          <FieldGroup id='user-email' width='100%' title='Email Address' required='Yes' type='text' placeholder='Enter Email' />
          <FieldGroup id='user-firstName' width='100%' title='First Name' required='Yes' type='text' placeholder='E.g. John' />
          <FieldGroup id='user-lastName' width='100%' title='Last Name' required='Yes' type='text' placeholder='E.g. Appleseed' />
          {/* <DoubleFieldGroup {...phoneNumberProps} width='100%' id='user-phoneNumber' title='Phone Number' required='Yes' type='number-type' /> */}

          {(!this.props.hasPassword) && ([
            <PasswordField onChange={this.onPasswordChanged} id='user-password' width='100%' title='Password' hideOptional placeholder='Enter Password' validate={[passwordRequired, passwordValidation]}/>,
            <PasswordField id='verifyPassword' width='100%' title='Confirm Password' hideOptional placeholder='Enter Password' validate={[confirmationRequired, this.passwordsMatch]}/>
          ])}

          <button className="submit-button create-account" type="submit"><ButtonSpinner loading={submitting}/>Create an Account</button>
        </FormContainer>
      </div>
      )
    }
    if ( this.props.type === "signup-3") {
      return(
        <div className="form">
        <FormContainer onSubmit={this.props.handleSubmit(results=>this.submit(results))}>
            <FieldGroup id='user-email' width='100%' title='Email Address' required='Yes' type='text' placeholder='Enter Email' />
            <PasswordField onChange={this.onPasswordChanged} id='user-password' width='100%' title='Password' hideOptional placeholder='Enter Password' validate={[passwordRequired, passwordValidation]}/>
            <PasswordField id='verifyPassword' width='100%' title='Confirm Password' hideOptional placeholder='Enter Password' validate={[confirmationRequired, this.passwordsMatch]}/>

            <button className="submit-button create-account" type="submit"><ButtonSpinner loading={submitting}/>Sign Me Up</button>

          </FormContainer>
        </div>
      )
    }
    else {
      return (<div>test</div>);
    }
  }
}

SignupFormModal = reduxForm({
  form: 'login',
  destroyOnUnmount: false
})(SignupFormModal);


const selector = formValueSelector('login')
const mapStateToProps = state => ({
  hasPassword: state.user.hasPassword,
  confirmPassword: selector(state, 'confirmPassword'),
  password: selector(state, 'user-password'),  
});




function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSignup,
    openModal,
    closeModal,
    blur,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupFormModal));

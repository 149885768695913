import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Input } from "Components/Forms";
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { requestPatchUser } from 'modules/user/actions';
import _ from 'lodash';
import { ButtonSpinner } from "Components/Global/ButtonSpinner";

class RightSettings extends React.Component {

  render() {
    return (
      <>
        <div className={this.props.className}>
          {/* <FinalPreview onClick={this.togglePreview()}>{previewMode ? 'Preview Mode'}</FinalPreview> */}
          <SpinnerContainer>
            <ButtonSpinner loading={this.props.loading} blue/>
          </SpinnerContainer>
          <Switch className="switch" style={{ marginRight: '40px'}}>
            <Field name="previewMode" type="checkbox" class="switch_1" component={Input} />
            <SwitchLabel>Preview Mode</SwitchLabel>
          </Switch>
          <Switch className="switch" style={{ marginRight: '40px'}}>
            <Field name="overviewActivated" type="checkbox" class="switch_1" component={Input} />
            <SwitchLabel>Include Overview</SwitchLabel>
          </Switch>
          <Switch className="switch">
            <Field name="enabled" type="checkbox" class="switch_1" component={Input} />
            <SwitchLabel>Live</SwitchLabel>
          </Switch>
          <Button onClick={() => this.props.savePDF()}><ButtonSpinner loading={this.props.savingPdf}/> Save PDF</Button>
          
        </div>
        {/* <div>
        {
          this.props.incompleteTabNames.map(name => <IncompleteTabInfo>The tab "{name}" has no loan selected</IncompleteTabInfo>)
        }
        {
          this.props.excludedTabNames.map(name => <ExcludedTabInfo>The tab "{name}" has been excluded from the overview</ExcludedTabInfo>)
        }
        </div> */}
      </>
    )
  }
}

const SpinnerContainer = styled.div`
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: 2px;
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  appearance: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  font-size: 14px;

  margin-left: 40px;

  padding: 8px 15px 8px 15px;
  color: white;

  background-color: #2291FF;

  .button-spinner.show {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`;

const Switch = styled.div`
  display: inline-block;
  vertical-align: middle;

  input, span {
    display: inline-block !important;
    vertical-align: middle;
  }
`;

const SwitchLabel = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const patchProposal = (data, props) => props.requestPatchUser({ 
  userId: props.user.id,
  params: {
    mergeMeta: true,
  },
  data: { 
    meta: { 
      miniproposal: _.merge(props.miniProposal, data)
    } 
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}



const mapStateToProps = (state, ownProps) => {
  
  return {
    initialValues: {
      enabled: state.user.meta.miniproposal && state.user.meta.miniproposal.enabled,
      previewMode: state.user.meta.miniproposal && state.user.meta.miniproposal.previewMode,
      overviewActivated: state.user.meta.miniproposal && state.user.meta.miniproposal.overviewActivated,
    },
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
  }
  
};


RightSettings = reduxForm({
  form: 'enableProposal',
  onChange: (values, dispatch, props) => {
    patchProposal(values, props);
  }
})(RightSettings);

export default connect(mapStateToProps, mapDispatchToProps)(RightSettings);

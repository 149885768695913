import React, {Component, Fragment} from 'react';

const { $ } = window;

export class MultiStageForm extends Component {

  state = {
    stage: this.props.stage,
  }

  // componentDidMount = () => {
  //   if (!this.props.main) return;
  //   const elementId = '#' + this.props.name + '-stage-' + this.state.stage;
  //   $('.main-container')
  //     .scrollTop(($(elementId).offset().top + $('.main-container').scrollTop()) - 100)
  // }

  static getDerivedStateFromProps = (nextProps, prevProps) => {
    return prevProps.stage === nextProps.stage ? {} : {stage: nextProps.stage};
  }

  componentDidMount() {
    $('.main-container').animate({ scrollTop: -1000 }, "fast");
  }
  
  componentDidUpdate = (_, prevState) => {
    const { name } = this.props;
    if (prevState.stage === this.state.stage) return;
    const elementId = '#' + name + '-stage-' + this.state.stage;
    // $('.main-container').animate({
    //     scrollTop: 
    //       ($(elementId).offset().top + $('.main-container').scrollTop()) - 100
    // }, 1000);
  }

  renderChildren = () => {
    const { children, name } = this.props;
    return children.map( (child, stage) => {
      return React.cloneElement(child, {
        id: name + '-stage-' + stage,
        currentStage: this.state.stage,
        stage,
      });
    });
  }

  render() {
    return (
      <Fragment>
        {this.renderChildren()}
      </Fragment>
    )
  }
}

export const FormStage = ({currentStage, stage, ...props}) => (
  <div {...props} className={`form-stage ${stage <= currentStage ? 'active' : ''}`} />
  //<div {...props} className='active' />
);

export const FadeIn = props => (
  <div {...props} className={'fade-in'} />
);
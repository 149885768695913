import React, { Component } from "react";
import { reduxForm, formValueSelector, blur } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FieldGroup, FormContainer, DoubleFieldGroup, PasswordField } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import {googleSignin, facebookSignin} from "utils/oauth"


import { createPasswordValidation, createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import Disclaimer from 'Components/Global/GlobalDisclaimer';
const passwordValidation = createPasswordValidation();
const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter your email');
const passwordRequired = createRequired('Please enter a password');
const confirmationRequired = createRequired('You must confirm your password');

const passwordFieldValidation = [passwordRequired, passwordValidation];
const confirmationFieldValidation = [confirmationRequired, ]

class FormIntroduction extends Component {

  state = {
    password: '',
  }

  passwordsMatch = (value, values) => 
    value === values['user-password'] ? null : 'Passwords do not match';

  onPasswordChanged = e => {
    this.setState({ password: e.target.value }, () => {
      this.props.blur('checkEligibilityYourDetails', 'confirmPassword', this.props.confirmPassword);
    });
  }
    

  // onPasswordChanged = e => {
  //   this.setState({ password: e.target.value }, () => {
  //     console.log('password:', this.state.password);
  //     this.props.blur('login', 'confirmPassword', this.props.confirmPassword);
  //   });
  // }

  passwordFieldValidation = [passwordRequired, passwordValidation];
  confirmationFieldValidation = [confirmationRequired, this.passwordsMatch];
      
  render() {
    const { props } = this;
    return(
      <div className="your-details">
        
          <div className="intro-text">
            <h1>Your Details</h1>
            <p>Before we go any further, let's confirm we've got your correct contact details in case we need to have a chat about your lending scenario.</p>
          </div>
      
        <div className="left">
          <div className="content">
          <div className="form" >
            <FormContainer onSubmit={props.handleSubmit} className="your-details">
              <FieldGroup id='user-firstName' width='small' position="left" title='First Name' required type='text' />
              <FieldGroup id='user-lastName' width='small' position="right" title='Last Name' required type='text' />
              <FieldGroup id="user-email" width="small" position="left" title="Your Email" hideOptional type="text" validate={[emailRequired, emailValidation]}/>
              <DoubleFieldGroup {...phoneNumberProps} required id='user-phoneNumber' width="small" position="right" title='Phone Number' type='number-type'/>

              <hr />

              {
                !(props.user.hasPassword || props.user.googleId || props.user.facebookId) && [
                  <PasswordField onChange={this.onPasswordChanged} id="user-password" width="small" position="left" title="Password" hideOptional placeholder="Enter your Password" validate={this.passwordFieldValidation}/>,
                  <PasswordField id="confirmPassword" width="small" position="right" title="Confirm Password" hideOptional placeholder="Confirm your Password" validate={this.confirmationFieldValidation}/>
                ]
              }
              <button type="submit" className="update-start"><ButtonSpinner loading={props.submitting}/>Update &amp; Start Eligibility Check</button>
              {
                !(props.user.hasPassword || props.user.googleId || props.user.facebookId) && 
                  <div className="alternative-signin">

                    <div class="or-divider">
                      <span>OR</span>
                      <hr />
                    </div>
                    <div className="social-media">
                      <div className="button google" onClick={()=>googleSignin("check-eligibility")}>Sign In with Google</div>
                      <div className="button facebook" onClick={()=>facebookSignin("check-eligibility")}>Sign In with Facebook</div>
                    </div>

                  </div>

              }
            </FormContainer>
          </div>
        </div>
        </div>

        <div className="right">
          <div className="security-box">
            <p>Our platform adheres to stringent security standards, and you are covered by a best practice insurance policy.</p>
          </div>

          <img src={require('img/page_illustrations/your_details.png')} alt='floating man'/>
        </div>
        <Disclaimer/>
      </div>
    );
  }
  
}

FormIntroduction = reduxForm({
  form: 'checkEligibilityYourDetails', // a unique identifier for this form
})(FormIntroduction);

const selector = formValueSelector('checkEligibilityYourDetails')
function mapStateToProps(state) {
  return {
    user: state.user,
    initialValues: {...getInitialValuesObject(state), "user_meta-confirmed_details": 1},
    password: selector(state, 'user-password'),
    confirmPassword: selector(state, 'confirmPassword'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    blur,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIntroduction);
import React, { useEffect } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';

import imgPlus from 'img/button/small_white_plus.svg';
import imgMoreInfo from 'img/button/more_info.svg';

import {
  Main, MainContainer, PageIllustration, Card, Title, Paragraph, ButtonContainer, InfoButton, ReferButton,
} from '../Components/Styled';

import ReferralsInfo from '../Components/ReferralsInfo';
import sanitizeHtml from 'sanitize-html';
import CompanyDetailsForm from '../Components/CompanyDetailsForm';

const Referrals = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const globalSettings = user.globalSettings;
  const referrals = user.referredApplications;
  const isProfessional = user.isProfessionalReferrer;

  useEffect(() => {
    dispatch(setTitle('Your Details'));
    dispatch(setPageID('referrals'));
  }, [])

  const clickReferButton = () => {
    history.push('/referrals/new')
  }

  let initialValues = user.referralProfile ?? {};
  if (!initialValues.email) initialValues.email = user.email;
  return (
    <Main class='main'>
      <MainContainer>
        <CompanyDetailsForm initialValues={initialValues}/>
      </MainContainer>
    </Main>
  ) 
}

export default Referrals; 



import React from "react";
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from 'modules/user/actions';
import { Container, Heading, EditableParagraph } from './Shared';
import _ from 'lodash';
const $ = window.$;

const FORM_NAME = "overview-additional-details-loanbase";
const BODY_NAME = "overview_additional_details_body"

class AdditionalDetails extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
    this.state = {
      caretPosition: 0,
      contentLength: 0,
      menuOpen: false,
    }
  }

  checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.details-menu').length) {
      this.setState({ menuOpen: false });
    }
  }

  eventHandler = this.checkIfLeaveMenu.bind(this);

  componentDidMount() {
    $(window).on('click', this.eventHandler);  
  }

  componentWillUnmount() {
    $(window).off('click', this.eventHandler);
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  resetSection = () => {
    const body = this.props.globalSettings[BODY_NAME];
    const formValues = { body };
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  }

  save = () => {
    let values = { ...this.props.formValues };
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;

    toSave = {
      overview: {
        additionalDetails: data
      }
    }

    this.props.requestPatchUser({
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: toSave,
        }
      },
      callback,
    });
  }

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  }

  updateCaretPosition = e => {
    setTimeout(() => {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      const tmp = document.createElement('div');

      preCaretRange.selectNodeContents(this.inputRef.current);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      tmp.appendChild(preCaretRange.cloneContents());
      const caretPosition = tmp.innerHTML.length;
      const contentLength = this.inputRef.current.innerHTML.length;
      if (caretPosition !== this.state.caretPosition || contentLength !== this.state.contentLength) {
        this.setState({ caretPosition, contentLength });
      }
      console.log(caretPosition, this.inputRef.current.innerHTML.length);
    }, 50);
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    this.updateCaretPosition(e);
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  sendCaretToEnd(el) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  addTemplate(index) {
    const { caretPosition, contentLength } = this.state;
    const { body, textTemplates, change } = this.props;
    const newHTML = (textTemplates[index] || {}).text;
    if (!newHTML) return;

    // const atStart = caretPosition < contentLength / 2;
    // const atEnd = caretPosition >= contentLength / 2;
    const atStart = false;
    const atEnd = true;
    let newBody = body;

    if (!newBody || newBody === '<br>') {
      newBody = newHTML;
    } else {
      if (!newBody.endsWith('</div>')) {
        newBody = `<div>${newBody}</div>`
      }
      if (atStart) {
        newBody = `<div>${newHTML}</div><br>${newBody}`;
      } else if (atEnd) {
        newBody = `${newBody}<br><div>${newHTML}</div>`;
      }
    }
    
    if (newBody) change('body', newBody);
    this.setState({ menuOpen: false });
    
    setTimeout(() => {
      const el = this.inputRef.current
      document.activeElement.blur();
      el.focus();

      if (atEnd) {
        this.sendCaretToEnd(el);
      }
    }, 100);
    
  }
  

  render() {
    const { active, editMode, textTemplates } = this.props;
    const { caretPosition, contentLength, menuOpen } = this.state;
    // const atStart = caretPosition < contentLength / 2;
    // const atEnd = caretPosition >= contentLength / 2;
    const atStart = false;
    const atEnd = true;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    return (
      <Container active={active} style={{margin: '60px 0 60px 0'}}>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>

          {editMode &&
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              type="overview-section"
              style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
              cancelSaveContainer="20px"
            />
          }

        <ContentContainer showButton={(atStart || atEnd) && active}>
            <Heading>Additional Details</Heading>
            <p>
              <Paragraph 
                richText={!!active} 
                innerRef={this.inputRef} 
                allowLineBreaks 
                onKeyDown={e => this.onKeyDown(e)}
                onClick={e => this.updateCaretPosition(e)}
                onFocus={e => this.updateCaretPosition(e)}
                className={active ? 'active' : ''} 
                name='body' 
                disabled={!active} 
              />
            </p>
            
            <InsertTextContainer className='details-menu'>
              <InsertTextButton onClick={() => this.toggleMenu()}>Insert Text Block</InsertTextButton>
              {menuOpen &&
                <Menu>
                  {(textTemplates || []).map((template, index) => {
                    return <MenuOption key={index} onClick={() => this.addTemplate(index)}>{(template || {}).name}</MenuOption> 
                  })}
                </Menu>
              }
            </InsertTextContainer>
            
            
        </ContentContainer>
        </Form>
      </Container>
    )
  }
}

const Paragraph = styled(EditableParagraph)`
  ul, ol {
    margin: 0;
    li {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
`;

export const Menu = styled.ul`
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
  border-radius: 4px 0 4px 4px;
  /* right: 0px;
  top: 23px; */
  padding: 2px 10px;
  width: ${p => p.width || 150}px;
  /* display: none; */
  /* text-align: left; */
  /* z-index: 51; */
`;

export const MenuOption = styled.li`
  font-size: 12px;
  border-bottom: 1px solid #D8DCE7;
  padding: 10px 0;
  cursor: pointer;
  text-align: left;
  color: #818CA2;

  &:hover {
    color: #101922;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

const InsertTextContainer = styled.div`
  position: relative;
  left: 73px;
  top: 5px;
`;

const InsertTextButton = styled.button.attrs({
  type: 'button',
})`
  font-size: 10px;
  position: absolute;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #d8dce7;
  background-color: white;
  cursor: pointer;
  padding: 0 5px;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
`;

const Column = styled.div`
    width: 100%;
`;

const ContentContainer = styled(Column)`
  ${InsertTextButton} {
    ${props => props.showButton && css`
      opacity: 1;
      pointer-events: all;
    `}
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = state => {
  const { additionalDetails } = state.user.meta.miniproposal.overview || {};
  const { globalSettings } = state.user;
  const body = (additionalDetails || {}).body || globalSettings[BODY_NAME];
  const textBlockJson = globalSettings.additional_details_json || '[]';

  const textTemplates = JSON.parse(textBlockJson);

  return {
    globalSettings,
    body: ((state.form[FORM_NAME] || {}).values || {}).body,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { body },
    formValues: (state.form[FORM_NAME] || {}).values || {},
    browser: state.browser,
    textTemplates,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(AdditionalDetails));
import calculateMonthlyRepayments from './calculateMonthlyRepayments';

export default function ({rate, monthlyFee, loanAmount, monthlyRepayment, loanPeriod = 30, fixedTerm, rr}) {  
    console.log({rate, monthlyFee, loanAmount, monthlyRepayment})
    var min = rate;
    var max = (fixedTerm ? rr : rate) + 0.02;
    var monthly;
    if (monthlyRepayment) {
      monthly = monthlyRepayment;
    } else {
      if (fixedTerm) {
        const fixedMonthlyRepayments = calculateMonthlyRepayments(rate / 12, loanPeriod * 12, loanAmount);
        const principleRemaining = calculateRemainingPrinciple({
          period: loanPeriod * 12,
          ir: rate / 12,
          principle: loanAmount,
          stopAt: fixedTerm * 12,
        });
        const rrMonthlyRepayments = calculateMonthlyRepayments(rr / 12, (loanPeriod - fixedTerm) * 12, principleRemaining);
        monthly = ((fixedMonthlyRepayments * fixedTerm) + (rrMonthlyRepayments * (loanPeriod - fixedTerm))) / loanPeriod;
      } else {
        monthly = calculateMonthlyRepayments(rate / 12, loanPeriod * 12, loanAmount);
      }
    }
  
    var n = 100;
    
    for (var i = 0; i < n; i++) {
      if (max - min < 0.00001) {
        break;
      }
      var testRate = min + (max - min) / 2;
      var testFee = calculateMonthlyRepayments(testRate / 12, loanPeriod * 12, loanAmount) - monthly;
      if (testFee > monthlyFee) {
        max = testRate;
      } else if (testFee < monthlyFee) {
        min = testRate;
      } else {
        break;
      }
    }
    return ((min + (max - min) / 2) * 100).toFixed(2);
  }


  const calculateRemainingPrinciple = ({ period, ir, principle, stopAt }) => {
    const repayment = calculateMonthlyRepayments(ir, period, principle);
    let totalPrincipleRepayment = 0;

    [...Array(stopAt)].forEach((_, i) => {
      const interestRepayment = (principle - totalPrincipleRepayment) * ir;
      const principleRepayment = repayment - interestRepayment;
      totalPrincipleRepayment += principleRepayment;
    });

    return principle - totalPrincipleRepayment;
  }
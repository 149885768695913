import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { closeModal, openModal } from 'modules/modals/actions';

function mapStateToProps(state) {
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    miniProposal: state.user.meta.miniproposal,
    banks: state.user.lenders,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

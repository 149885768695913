import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'

import View from './View';
import { requestLogin } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import getInitialValuesObject from 'utils/getInitialValuesObject';

function mapStateToProps(state) {
  let initialValues = getInitialValuesObject(state);
  if (!state.user.phoneNumber) {
    initialValues = {...initialValues, 'user_meta-finished_onboarding1': 1};
  }
  initialValues['user_meta-isOOLoan'] = (initialValues['user_meta-isOOLoan'] === '') ? 'N' : initialValues['user_meta-isOOLoan'];
  initialValues['user_meta-isPandILoan'] = (initialValues['user_meta-isPandILoan'] === '') ? 'N' : initialValues['user_meta-isPandILoan'];
  delete initialValues['user_meta-purchasePrice'];
  delete initialValues['user_meta-loanAmount'];
  
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    user: state.user, 
    initialValues,
    productsCount: state.products.total,
    globalSettings: state.user.globalSettings
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    push
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import store from 'store';
import { initialize } from 'redux-form';
import getInitialValuesObject from './getInitialValuesObject';

export default (formName, fn, extraFields = []) => {
  const state = store.getState();
  const fields = getInitialValuesObject(state);

  let fieldNames = Object.keys( state.form[formName].registeredFields )
    .map(key => key.split('.')[0]);

  fieldNames = [...fieldNames, ...extraFields];

  let initialValues = fieldNames.reduce(
    (obj, key) => ({
      ...obj,
      [key]: fields[key]
    }), {});
  
  for (const key in initialValues) {
    if (initialValues[key] == null) delete initialValues[key];
  }

  if (fn) 
    store.dispatch( initialize(formName, fn(initialValues, state)) );
  else 
    store.dispatch( initialize(formName, initialValues) );
};
import React from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch, connect } from "react-redux";
import { reduxForm, Form } from "redux-form";

import { openModal } from "modules/modals/actions";

import {
  FlexColumn,
  FlexRow,
  SmallHeading,
  Footnote as FootnoteBase,
  Button,
} from "../Shared";
import formatNumber from "utils/formatNumber";
import intToOrdinal from "utils/intToOrdinal";
import SectionButton from "../../../../component/SectionButton";
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from "modules/user/actions";

const FORM_NAME = "overview-rebates";

const RebatesBase = ({
  loans,
  editMode,
  toggleActive,
  cancelEdit,
  active,
  reset,
  initialize,
  handleSubmit,
}) => {
  const dispatch = useDispatch();

  const {
    loanbaseCashback,
    lenderCashback,
    loanbaseTrailRebate,
    lenders,
    defaults,
    userId,
  } = useSelector((state) => ({
    loanbaseCashback:
      state.user.meta.miniproposal?.overview?.loanbaseCashback ?? {},
    lenderCashback:
      state.user.meta.miniproposal?.overview?.lenderCashback ?? {},
    loanbaseTrailRebate:
      state.user.meta.miniproposal?.overview?.loanbaseTrailRebate ?? {},
    lenders: state.user.lenders,
    defaults: state.user.globalSettings,
    userId: state.user.id,
  }));

  const trailRebates = loans
    .map((x) => x.newLoan.trailRebate)
    .filter((x) => !!x);

  const splitsCashback = loans
    .map((x) => x.cashback || 0)
    .reduce((a, b) => a + b, 0);

  const lenderCashbackTotal =
    lenderCashback.type === "splits"
      ? splitsCashback
      : lenderCashback.rebateAmount || 0;

  let lenderName = "";
  if (lenderCashback.type === "splits") {
    let lenderNames = loans
      .filter((x) => !!x.cashback)
      .map((x) => x.newLoan.bank_name);

    // remove repetitions
    lenderNames = Array.from(new Set(lenderNames));

    if (lenderNames.length > 1) {
      const last = lenderNames.pop();
      lenderName = `${lenderNames.join(", ")} & ${last}`;
    } else {
      lenderName = lenderNames[0];
    }
  } else {
    lenderName =
      lenderCashback.lender !== "other"
        ? (lenders.find((x) => x.id === lenderCashback.lender) || {}).name
        : lenderCashback.lenderName;
  }

  const patchProposal = (data) => {
    data = {
      overview: {
        lenderCashback: { text: data.lenderCashbackText },
        loanbaseCashback: { text: data.loanbaseCashbackText },
        loanbaseTrailRebate: { text: data.loanbaseRebateText },
      },
    };

    dispatch(
      requestPatchUser({
        userId,
        params: {
          mergeMeta: true,
        },
        data: {
          meta: {
            miniproposal: data,
          },
        },
      })
    );
  };

  const cancel = () => {
    reset();
    cancelEdit();
  };

  const resetSection = () => {
    const values = {
      lenderCashbackText: defaults.overview_lender_cashback,
      loanbaseCashbackText: defaults.overview_loanbase_cashback,
      loanbaseRebateText: defaults.overview_loanbase_rebate,
    };
    patchProposal(values);
    initialize(values);
    reset();
  };

  const save = (values) => {
    patchProposal(values);
    initialize(values);
    cancelEdit();
  };

  const lenderCashbackEndDate = formatDate(lenderCashback.endDate);

  const loanbaseCashbackEndDate = formatDate(loanbaseCashback.endDate);

  const loanbaseTrailRebateEndDate = formatDate(loanbaseTrailRebate.endDate);

  const hasTrailRebate = !!trailRebates.length;

  let sectionCount = 0;
  if (hasTrailRebate) sectionCount++;
  if (lenderCashback.isActive) sectionCount++;
  if (loanbaseCashback.isActive) sectionCount++;

  let trailRebateText = "";
  if (hasTrailRebate && trailRebates.every((x) => x === trailRebates[0])) {
    trailRebateText = trailRebates[0] + "%";
  }

  if (sectionCount === 0) return null;
  return (
    <Container active={active}>
      <Form onSubmit={handleSubmit(save)}>
        <FlexRowRebates>
          {editMode && (
            <SectionButton
              resetSection={() => resetSection()}
              enableEdit={() => toggleActive()}
              isEditable={active}
              cancelEdit={() => cancel()}
              type="overview-section"
              style={{
                float: "right",
                position: "absolute",
                top: "-30px",
                right: "0",
              }}
              cancelSaveContainer="20px"
            />
          )}
          {hasTrailRebate && (
            <FlexColumnRebates>
              <SmallHeading>Secure Finance Rebate</SmallHeading>
              <BigBlueText>{trailRebateText} Trail Rebate*</BigBlueText>
              <SmallBlueText>Provided by Secure Finance</SmallBlueText>

              <Footnote
                name="loanbaseRebateText"
                as={EditableContent}
                disabled={!active}
              />

              {!!loanbaseTrailRebate.hasEndDate && (
                <GreenText>
                  Rebate Offer Expires {loanbaseTrailRebateEndDate}
                </GreenText>
              )}
              {editMode && (
                <Button
                  color="#2AD22B"
                  onClick={() => dispatch(openModal("loanbaseTrailRebate"))}
                >
                  Set End Date
                </Button>
              )}
            </FlexColumnRebates>
          )}
          {!!loanbaseCashback.isActive && (
            <FlexColumnRebates>
              <SmallHeading>Secure Finance Cashback</SmallHeading>

              {!!loanbaseCashback.rebateAmount && (
                <>
                  <BigBlueText>
                    ${formatNumber(loanbaseCashback.rebateAmount)} Cashback
                  </BigBlueText>
                  <SmallBlueText>Provided by Secure Finance</SmallBlueText>
                  <Footnote
                    name="loanbaseCashbackText"
                    as={EditableContent}
                    disabled={!active}
                  />
                  {!!loanbaseCashback.hasEndDate && (
                    <GreenText>
                      Cashback Expires {loanbaseCashbackEndDate}
                    </GreenText>
                  )}
                </>
              )}
              {editMode && (
                <Button
                  color="#2AD22B"
                  onClick={() => dispatch(openModal("loanbaseCashback"))}
                >
                  Set Cashback
                </Button>
              )}
            </FlexColumnRebates>
          )}

          {!!lenderCashback.isActive && (
            <FlexColumnRebates>
              <SmallHeading>Lender Cashback</SmallHeading>
              {!!lenderCashbackTotal && (
                <>
                  <BigBlueText>
                    ${formatNumber(lenderCashbackTotal)} Cashback
                  </BigBlueText>
                  <SmallBlueText>Provided by {lenderName}</SmallBlueText>
                  <Footnote
                    name="lenderCashbackText"
                    as={EditableContent}
                    disabled={!active}
                  />
                  {!!lenderCashback.hasEndDate && (
                    <GreenText>
                      Cashback Expires {lenderCashbackEndDate}
                    </GreenText>
                  )}
                </>
              )}
              {editMode && (
                <Button
                  color="#2AD22B"
                  onClick={() => dispatch(openModal("lenderCashback"))}
                >
                  Set Cashback
                </Button>
              )}
            </FlexColumnRebates>
          )}

          {sectionCount < 3 && (
            <FlexColumnRebatesImg>
              <img
                src={require("img/page_illustrations/piggybank.png")}
                alt="checkeligibilityhelptimer"
              />
            </FlexColumnRebatesImg>
          )}
        </FlexRowRebates>
      </Form>
    </Container>
  );
};

const stateToProps = (state) => {
  const overview = state.user.meta.miniproposal.overview || {};
  const defaults = state.user.globalSettings;

  return {
    initialValues: {
      lenderCashbackText:
        (overview.lenderCashback || {}).text ||
        defaults.overview_lender_cashback,
      loanbaseCashbackText:
        (overview.loanbaseCashback || {}).text ||
        defaults.overview_loanbase_cashback,
      loanbaseRebateText:
        (overview.loanbaseTrailRebate || {}).text ||
        defaults.overview_loanbase_rebate,
    },
  };
};

export const Rebates = connect(stateToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(RebatesBase)
);

const formatDate = (date, makeDayOrdinal = false) => {
  if (!date) return "";
  date = new Date(date);
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  let day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(date);

  if (makeDayOrdinal) {
    day = intToOrdinal(parseInt(day));
  }
  return `${day} ${month} ${year}`;
};

export const Container = styled.div`
  ${(p) =>
    p.active
      ? css`
          z-index: 110;
        `
      : ``}
  position: relative;
  margin-bottom: 60px;
`;

const Footnote = styled(FootnoteBase)`
  ${(p) =>
    p.disabled
      ? ""
      : css`
          border: solid 1px #a3a8b2;
          border-radius: 3px;
        `}
`;

const FlexRowRebates = styled(FlexRow)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 60px 0 60px 0;

  @media (max-width: 780px) {
    flex-wrap: wrap;
    margin: 40px 0 40px 0;
  }

  @media (max-width: 480px) {
    margin: 40px 0 30px 0;
  }
`;

const FlexColumnRebates = styled(FlexColumn)`
  width: calc(33.33% - 40px);

  @media (max-width: 780px) {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: contain;
    max-height: 190px;
  }
`;

const FlexColumnRebatesImg = styled(FlexColumnRebates)`
  @media (max-width: 780px) {
    display: none;
  }
`;

const BigBlueText = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #308ae9;
  margin-bottom: 15px;
`;

const SmallBlueText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #308ae9;
  margin-bottom: 15px;
`;

const GreenText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2bb839;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 1.2;
`;

import React from "react"; import { Component } from "react";
import styled, { css } from 'styled-components'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Form, formValueSelector, change as changeForm } from 'redux-form';
import { closeModal } from 'modules/modals/actions';
import EditableContent from "Components/Forms/EditableContent";

const $ = window.$;
class OffsetTooltip extends Component {
  
  constructor(props) {
    super(props);
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    if (!this.inputRef.current) return;
    setTimeout(() => {
      this.inputRef.current.focus();
    });
    setTimeout(() => {
      document.execCommand('selectAll');
    }, 5);
    
    $(document).on('keydown.offset', e => {
      if (e.key == 'Enter') {
        this.submit({ rr: this.props.rr });
      }
    });
  }

  

  componentWillUnmount() {
    $(document).off('.offset');
  }
  
  submit = ({ rr }) => {

    this.props.dispatch(this.props.changeForm(this.props.formName, 'rr', rr));
    if (!this.props.orr) {
      this.props.dispatch(this.props.changeForm(this.props.formName, 'originalRR', this.props.initialValues.rr));
    }
    this.props.closeModal()
  }
  
  render() {
    if (this.props.currentModal === "roleRateOverride") {
    return (
      <div className="modal-content">
        <Form onSubmit={this.props.handleSubmit(this.submit.bind(this))}>
          <h1>Enter a new revert rate</h1>
          <Rate><Span innerRef={this.inputRef} name='rr' as={EditableContent}/><AfterRate> % p.a.</AfterRate></Rate>
          <div className="modal-navigation">
            <button onClick={()=>this.props.closeModal()} className="double left button-5">Cancel</button>
            <button className="double right button-1" style={{ fontSize: "13px" }}>Save</button>
          </div>
        </Form>
        
      </div>
    ) } else {
      return "";
    }
  }
}


const Rate = styled.div`
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    text-align: center;
`;

const AfterRate = styled.span`
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: #727C8F;
`;

const Span = styled.span`
  min-width: 50px;
  display: inline-block;
  outline: none;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;


const stateToProps = state => {
  const rr = (state.modals.args && state.modals.args.rr && state.modals.args.rr.toString()) || '';
  const orr = state.modals.args && state.modals.args.orr;
  return {
    formName: (state.modals.args && state.modals.args.form) || "",
    initialValues: {
      rr,
    },
    rr: ((state.form['roleRateOverride'] || {}).values || {}).rr,
    orr,
  };
};

function dispatchToProps(dispatch) {
  return bindActionCreators({
    changeForm,
    closeModal,
  }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(reduxForm({ 
  form: 'roleRateOverride',
})(OffsetTooltip))

 


import React from "react";

class Navigation extends React.Component {
  render() {
    const { hidden, name, optional, skip, greenButton } = this.props;
    if (!optional) return (
      <div className={`navigation-options ${hidden ? 'invisible' : ''}`}>
        <button type="submit" className={greenButton ? 'green' : ''}>{name}</button>
        <span className='press-enter'>press <strong>ENTER</strong></span>
      </div>
    );
    return (
      <div className={`navigation-options skippable`}>

        <button className='skip' type='button' onClick={skip}>Skip</button>

        <span className={`hidden-container ${hidden ? 'invisible' : ''}`}>
          <button id='submit' type="submit" className={greenButton ? 'green' : ''}>{name}</button>
          <span className='press-enter'>press <strong>ENTER</strong></span>
        </span>
         
      </div>
    )
  }
}

export default Navigation;

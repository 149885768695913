import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Container, Heading } from './Shared';
import { uploadUrl } from 'modules';

const TheTeam = ({ team }) => {
  return (
    <Container>
      <Heading>Meet Our Specialist Team</Heading>
      <TeamContainer>
        {(team || {}).map(({ id, title, name, blurb }) => (
          <MainContainer>

            <MobileTeam>
              <Individual>
                <Portrait src={`${uploadUrl}/brokers/small${id}.png`} alt={name} />
                <BlueSeperator />
                <div>
                  <Title>{title}</Title>
                  <Name>{name}</Name>
                </div>
              </Individual>
              <BlurbContainer>
                <Blurb>{blurb}</Blurb>
              </BlurbContainer>
            </MobileTeam>

            <DesktopTeam>
              <Portrait src={`${uploadUrl}/brokers/small${id}.png`} alt={name} />
              <TextContainer>
                <Title>{title}</Title>
                <BlueSeperator />
                <Name>{name}</Name>
                <BlurbContainer>
                  <Blurb>{blurb}</Blurb>
                </BlurbContainer>
              </TextContainer>
            </DesktopTeam>
          </MainContainer>
        ))}
      </TeamContainer>
    </Container>
  );
}

const stateToProps = state => {
  let { team, proposalBroker } = state.user;
  if (!proposalBroker) return { team };
  const broker = {
    id: proposalBroker.id,
    name: proposalBroker.name,
    title: 'Your Broker',
    blurb: proposalBroker.blurb,
  }
  
  team = team.filter(x => x.id !== broker.id && x.overviewPosition);
  team = [broker, ...team];

  return {
    team
  }
}

export default connect(stateToProps)(TheTeam);

const Individual = styled.div`
display: flex;
align-items: center;
`;

const DesktopTeam = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 645px) {
    display: none;
  }
`;

const MobileTeam = styled.div`
  display: none;

  @media (max-width: 645px) {
    display: block;
    margin-top: 30px;
  }
`;

const TeamContainer = styled.div`
  margin-top: 30px;
`;

const Portrait = styled.img`
  display: inline-block; 
  object-fit: cover;
  height: 120px;
  min-width: 120px;
  border-radius: 50%;
  background-color: #D2DCEB;
  margin-right: 60px;

  @media (max-width: 645px) {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin-right: 0;
  }
`;

const Blurb = styled.span`
  color: #576377;
  font-size: 16px;
  line-height: 150%;
`;

const Name = styled(Blurb)`
  font-weight: 700;

  @media (max-width: 645px) {
    display: block;
  }
`;

const Title = styled(Blurb)`
  font-weight: 800;
  font-style: italic;

  @media (max-width: 645px) {
    display: block;
  }
`;

const MainContainer = styled.div`
  display: block;
`;

const TextContainer = styled.div`
  display: inline-block;
  /* margin-top: 10px; */
  //width: 50%;
`;

const BlurbContainer = styled.div`
  margin-top: 10px;
`;

const BlueSeperator = styled.span`
  display: inline-block;
  width: 3px;
  height: 22px;
  margin: 0 20px 0 20px;
  background-color: #2291FF;
  vertical-align: top; 

  @media (max-width: 645px) {
    height: 43px;
  }
`;
import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import SearchLoans from "./SearchLoans"
import LoanShortlist from "./LoanShortlist"
import CheckEligibility from "./CheckEligibility"
import Help from "./Help/underConstruction"
import Contact from "./Contact"
import ProductDetails from "./ProductDetails"
import AccountSettings from "./Accounts/AccountSettings"
import ShowModal from "Components/Modals/showModal"
import ValidationResult from "./Accounts/ValidationResult"
import ValidationSent from "./Accounts/ValidationSent"
import EmailChangeResult from "./Accounts/EmailChangeResult"
import PreventEmails from "./Accounts/PreventEmails"
import ResetPassword from "./Accounts/ResetPassword"

import getQueryParams from "utils/getQueryParams";


// function getQueryVariable(query, variable) {
//     if (query) {
//       var vars = query.split('?')[1].split('&');
//       for (var i = 0; i < vars.length; i++) {
//           var pair = vars[i].split('=');
//           if (decodeURIComponent(pair[0])  variable) {
//             console.log("Next: " + pair[1]);
//             return decodeURIComponent(pair[1]);
//           }
//       }
//     }
//     return '';
// }

class Routes extends Component {
  render() {
    return (
    <Switch>

      {/* <Route exact path="/contact" component={Contact}/>
      <Redirect exact from="/" to="/search-loans" />
      <Route exact path="/search-loans" render={(props) => <SearchLoans type="unverified" {...props} /> } />
      <Route exact path="/search-loans/product-details" component={ProductDetails} />
      <Route exact path="/loan-shortlist" component={LoanShortlist}/>
      <Route exact path="/help" component={Help}/>
      <Route exact path="/help/:id/" component={Help} />
      <Route exact path="/check-eligibility/" render={(props) => <CheckEligibility type="initial" {...props} /> } />
      <Route exact path="/products/:id/" component={ProductDetails} />
      <Route exact path="/accounts" component={AccountSettings}/> */}

      <Redirect exact from="/signup" to="/search-loans" />
      <Redirect exact from="/" to="/search-loans" />
      <Redirect exact from="/refinance" to="/search-loans" />
      <Route exact path="/search-loans" render={(props) => <SearchLoans type={this.props.state} state="unverified" {...props} /> } />
      <Route exact path="/search-loans/product-details" render={(props) => <ProductDetails type={this.props.state} {...props} /> } />
      {/* <Route exact path="/loan-shortlist" render={(props) => <LoanShortlist type={this.props.state} {...props} /> } /> */}
      <Route exact path="/help" render={(props) => <Help type={this.props.state} {...props} /> } />
      <Route exact path="/help/:id/" render={(props) => <Help type={this.props.state} {...props} /> } />
      <Redirect exact from="/check-eligibility/" to="/check-eligibility/details/" />
      <Route exact path="/check-eligibility/:id/" render={(props) => <CheckEligibility type={this.props.state} {...props} /> } />
      <Route exact path="/products/:id/" render={(props) => <ProductDetails type={this.props.state} {...props} /> } />
      <Route exact path="/verify" render={(props) => <ValidationResult type={this.props.state} {...props} /> } />
      <Route exact path="/validation-email-sent" render={(props) => <ValidationSent type={this.props.state} {...props} /> } />
      <Route exact path="/replace-email" render={(props) => <EmailChangeResult type={this.props.state} {...props} /> } /> 
      <Route exact path="/prevent-emails" render={(props) => <PreventEmails type={this.props.state} {...props} /> } />
      <Route exact path="/reset-password" render={(props) => <ResetPassword type={this.props.state} {...props} /> } />
      
      <Route
          exact
          path="/login"
          render={props => (
            <Redirect to={`/` + getQueryParams().next} />
          )}
        />

      <Route
        path="/login-form"
        render={props => (
          <ShowModal modal='signin' redirect={''} />
        )}
      />

      <Route
        path="/contact"
        render={props => (
          <ShowModal modal='checkEligibilityHelp' redirect={''} />
        )}
      />

      <Route
        path="/:path"
        render={props => (
          <ShowModal modal='signup-1' redirect={props.match.params.path} />
        )}
      />

    <Route component={()=>(
    <main className="page-not-found">
      <div className="main-container">
        <div className="center">
          <div class="illustration"></div>
          <h4>404 Page not Found</h4>
          <p>Why not search to identify a suitable home loan?</p>
        </div>
      </div>
    </main>
    )} />
    </Switch>
    );
  }
};


export default withRouter(props => <Routes {...props} />);

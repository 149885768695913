import React from 'react';
import styled, { css } from 'styled-components';
import Tooltips from "Components/Tooltips";

import imgIllustration from 'img/page_illustrations/referrals.png';
import { useSelector } from 'react-redux';

export const Main = styled.main``;

export const MainContainer = styled.div`
width: 685px;
overflow: visible;
`;

export const PageIllustration = styled.img.attrs({
  src: imgIllustration,
})`
  width: 100%; 
  height: 300px;
  margin-bottom: 30px;
  object-fit: cover;
  object-position: 50% -15%;
`;

export const Card = styled.div`
  border: solid 1px #E9EBF1;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  overflow: visible;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
  line-height: 24px;

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
    }
  }

  ol {
    li {
      list-style: decimal inside !important;
      margin-left: 10px;
    }
  } 
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CenterContent = styled.div`
  display: flex;
  justify-content: space-around;
`

export const LoadMore = styled.div`
  color: #2689EB;
  font-size: 15px;
  font-weight: 700;
`;

export const InfoButton = styled.button`
  width: 143px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d8dce7;
  background: #ffffff;
  box-shadow: 0px 1px #e9ebf1;
  line-height: 40px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
`;

export const ReferButton = styled.button`
  width: 143px;
  height: 40px;
  border-radius: 4px;
  background: ${p => p.color ?? '#2291ff'};
  border: none;

  color: white;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;

  cursor: pointer;
`;

export const NextButton = styled(ReferButton)`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 109px;
  cursor: pointer;
`;

export const TotalsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const TotalCard = styled(Card)`
  width: calc(33.3% - 20px);
  min-height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 15px;
`;

export const TotalContainer = styled.div`
  margin-bottom: 20px;

  img {
    margin-right: 20px;
  }

  font-size: 24px;
  font-weight: 400;
`;

export const Total = styled.span`
  position: relative;
  top: -17px;
  font-size: 24px;
  font-weight: 400;
`;

export const ReferralTable = styled.div`
  margin: 20px -20px 0 -20px;
  width: calc(100% + 40px);
  background-color: #F9FAFC;
  border-top: solid 1px #E9EBF1;
  border-collapse: collapse;
`

export const TableHeader = styled.div`
  background-color: #F9FAFC;
  color: #A8ACB4;
  border-collapse: collapse;
  border-bottom: solid 1px #E9EBF1;
  height: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 40px;
`;

export const TableCell = styled.div`
  display: inline-block;
  width: ${p => p.width};
  &:first-of-type {
    padding-left: 25px;
  }
`;

export const TableRow = styled.div`
  background-color: #FFFFFF;
  color: #575E6C;
  border-collapse: collapse;
  border-bottom: solid 1px #E9EBF1;
  height: 65px;
  font-weight: 400;
  font-size: 14px;
  line-height: 65px;

  &:last-of-type {
    //border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    ${TableCell} {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;



export const Stage = styled.div`
  display: inline-block;
  color: white;
  background-color: ${p => p.color};
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;

  [data-tooltip]:before {
    line-height: 19px;
    margin-left: -230px;
    transform: translate(calc(-50% + 230px), 0);
    padding: 10px;
  }
`;

export const CommissionPaid = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #26BD27;
`;

export const Saved = styled.div`
  display: inline-block;
  margin-right: 20px;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
  background-color: #26BD27;
  color: white;
  font-weight: 700;
`;

export const FormStyle = styled.div`
  .form {
    max-width: 100% !important;
    label {
      span {
        float: right !important;
      }
    }

    textarea {
      resize: none;
      height: 120px;
    }
  }

  

  .w280, .w100p, .tiny {
    margin: 20px 30px 0 0 !important;
  }
  
  .w100p {
    margin-right: 0 !important;
  }

  .w280 {
    width: calc(50% - 15px) !important;
    &.rs {
      margin-right: 0 !important;
    }
  }

  .tiny {
    width: calc(40% - 15px) !important;

    &.rs {
      margin-right: 0 !important;
      width: calc(60% - 15px) !important;
    }
  }
`;

export const Row = styled.div``;
export const Column = styled.div`
  display: inline-block;
  width: ${p => p.width ?? '100%'};
`;

export const Placeholder = styled.div`
  display: inline-block;
  width: 92px;
  height: 17px;
  border-radius: 8px;
  background: #f4f4f4;
`;

export const StageLabel = ({ stage, noTooltip }) => {
  const globalSettings = useSelector(state => state.user.globalSettings)
  let color, definition;
  if (stage === 'Referral submitted') {
    color = '#B31CE0';
    definition = globalSettings.referrer_tooltip_submitted;
  } else if (stage === 'Contacted') {
    color = '#00CD98';
    definition = globalSettings.referrer_tooltip_contacted;
  } else if (stage === 'Deal in progress') {
    color = '#26BD27';
    definition = globalSettings.referrer_tooltip_in_progress;
  } else if (stage === 'Payment pending') {
    color = '#2291FF';
    definition = globalSettings.referrer_tooltip_payment_pending;
  } else if (stage === 'On hold') {
    color = '#F5A623';
    definition = globalSettings.referrer_tooltip_on_hold;
  } else if (stage === 'Not proceeding') {
    color = '#575E6C';
    definition = globalSettings.referrer_tooltip_not_proceeding;
  } else if (stage === 'Settled') {
    color = '#2291FF';
  } else {
    return 'ERROR';
  }
  return (
    <Stage color={color}>
      {noTooltip ? stage :
        <Tooltips type='wrapper' definition={definition}>
          {stage}
        </Tooltips>
      }
    </Stage>
  );
}


import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Container, Heading } from './Shared';

const AboutLender = ({ ids = [], banks = {} }) => {
  const lenders = useSelector(state => state.user.lenders);
  const selectedLenders = ids
    .filter(id => Object.keys(banks).some(key => key === id))
    .map(id => lenders.find(x => x.id === id))
    .filter(x => !!x && !!x.description);

  const lastIndex = selectedLenders.length - 1; 
  return selectedLenders.map((lender, index) => {
    const marginBottom = (index === lastIndex) ? '60px' : '40px';
    return (
      <Container style={{ marginBottom }}>
        <Heading>About {lender.name}</Heading>
        <P>{lender.description}</P>
      </Container>
  )});
};

export default  AboutLender;

const P = styled.p`
  white-space: pre-line;
  line-height: 24px !important;
`;
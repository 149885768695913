import html from './utils/html';
import listToString from './utils/listToString';

const generateAdditonalDetails = ({user = {}, loans = []}) => {
  
  const loanbaseCashback = user.meta?.miniproposal?.overview?.loanbaseCashback ?? {};
  const lenderCashback = user.meta?.miniproposal?.overview?.lenderCashback ?? {};
  
  const trailRebates = loans
    .filter(x => !!x.newLoan.trailRebate)
    .map(x => ({ 
      amount: x.newLoan.trailRebate, 
      name: x.name, 
      term: (x.newLoan.fixedTermMonths ?? 0) / 12,
      isIntroVariable: (x.newLoan.initial_ir_type === 'Intro Variable')
    }));

  // const cashbackPropertyNames = loans
  //   .filter(x => !!x.cashback)
  //   .map(x => html`the <b>${x.name}</b> loan`);
  
  
  let lenderCashbackText = '';

  if (lenderCashback.type === 'splits') {
    lenderCashbackText = 'As noted in each row, a number of cashbacks apply to the above lending structure. These loan applications must be lodged by {lendercashbackexpiry} and successfully settle, in order to be eligible for the cashback.';
  } else {
    if (lenderCashback.lender === 'other' && lenderCashback.lenderName.includes('&')) {
      lenderCashbackText = '{lendername} are providing a cashback of {lendercashbackvalue} upon successful settlement of the loans detailed above, so long as the loan applications are lodged by {lendercashbackexpiry}.';
    } else {
      lenderCashbackText = '{lendername} is providing a cashback of {lendercashbackvalue} upon successful settlement of the loans detailed above, so long as the loan applications are lodged by {lendercashbackexpiry}.';
    }
  }
  // if (cashbackPropertyNames.length === 1) {
  //   const name = cashbackPropertyNames[0];
  //   lenderCashbackText += html` This cashback offer is only applicable for the ${name}.`
  // } 
  // if (cashbackPropertyNames.length > 1) {
  //   lenderCashbackText += html` This cashback offer is only applicable for ${listToString(cashbackPropertyNames)}.`
  // }

  const trailRebateText = x => {
    let endText;
    if (x.isIntroVariable) {
      endText = x.term ? `the introductory variable term of ${x.term} years` : 'the life of the loan';
    } else {
      endText = x.term ? `the first ${x.term} years of the fixed term loan term` : 'the life of the loan';
    }
    return html`${x.name}: ${x.amount}% annual trail rebate, valid for ${endText}<br/><br/>`;
  };

  const loanbaseCashbackText = 'We are providing a cashback of {loanbasecashbackvalue} upon successful settlement of the loans detailed above, providing you send through required supporting documents by {loanbasecashbackexpiry}.';

  const generatedHtml = `
    ${!trailRebates.length ? '' : html`
      <b>Trail Rebate</b><br/>
      Some of the above loan products also have an applicable Secure Finance trail rebate. 
      Note that trail rebates are calculated based on utilised funds only, 
      and are applicable for the fixed rate term if fixed, or the loan term if variable. 
      The interest rates shown in the above table do not factor in these rebates. 
      The following rebates are applicable:
      <br/><br/>
      ${trailRebates.map(trailRebateText).join('')}

      <i>Please review the full terms & conditions of the Secure Finance trail rebates on the final page of this document.</i>
      <br/><br/>
    `}
    ${!loanbaseCashback.isActive ? '' : html`
      <b>Secure Finance Cashback</b><br/>
      ${loanbaseCashbackText}
      <br/><br/>
    `}
    ${!lenderCashback.isActive ? '' : html`
      <b>Lender Cashback</b><br/>
      ${lenderCashbackText}
      <br/><br/>
    `}
  `;

  return generatedHtml;
};

export default generateAdditonalDetails;
import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { createRequired } from 'Components/Forms/validation';
import { reduxForm, Field } from 'redux-form';

import Navigation from "Components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import { requestForgotPassword } from "modules/user/actions";

import ForgotPasswordComponent from "./Components/ForgotPasswordComponent";


// const emailRequired = createRequired('Please enter your email');

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators({
//     requestForgotPassword
//   }, dispatch);
// }

class ForgotPassword extends Component {

  // state = { submitted: false, email: '' }

  // submit = ({ email }) => {
  //   this.props.requestForgotPassword({
  //     email, callback: () =>
  //       this.setState({ submitted: true, email })
  //   });
  //   console.log('did the thing')
  // }

  render() {
    // const { handleSubmit } = this.props;
    // const { submitted } = this.state;
    return (
      <div>
        <Navigation state="unactive" />
        <main className="user-authentication">
          <div className="modal single">

            <ForgotPasswordComponent />

          <div className="navigation back">
              <NavLink exact to="/login">
              ← Back to Sign In
              </NavLink>
          </div>

          </div>
        </main>
      </div>
    );
  }
}

// const Input = ({meta: {touched, error}, input, formError, ...props}) => {
//   return ( 
//   <Fragment>
//     <input
//       className={((touched && error) || formError) && 'error'}
//       {...props} {...input} required={false}/>
//     {touched && error && <div className='field-error-message'>{error}</div>} 
//   </Fragment>
//   )
// };

// ForgotPassword = reduxForm({
//   form: 'login',
//   destroyOnUnmount: false,
// })(ForgotPassword)

export default ForgotPassword;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { addToShortlist, removeFromShortlist } from 'modules/user/actions';
import { openModal } from 'modules/modals/actions';
import { closeModal } from 'modules/modals/actions';


function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist,
    currentModal: (state.modals)?state.modals.currentModal:"",
    nsrValues: state.user.nsrValues,
    repaymentFrequency: state.user.meta.searchFilter_repaymentFrequency,
    loanPurpose: state.user.meta.loanPurpose,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    addToShortlist,
    removeFromShortlist
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

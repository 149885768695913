import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LetsStart from './Views/LetsStart/';
// import SeeHowItWorks from './Components/SeeHowItWorks/SeeHowItWorks';
import CalculateMyBudget from 'Views/Calculators/Views/Home';
import LoanShortlist from './Views/LoanShortlist/';
import LoanApplications from './Views/LoanApplications/';
import EligibilityAssessment from './Views/EligibilityAssessment';
import Disclaimer from 'Components/Global/DashboardDisclaimer';


function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }

  return {
    shortlist: state.user.shortlist,
    applications: state.user.applications,
    user: state.user.meta,
    applicationProducts,
    products,
    application,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class Dashboard extends Component {
  componentDidMount() {
    document.title = "Secure Finance - Dashboard";
    this.props.setTitle("Welcome to Secure Finance")
    this.props.setPageID("dashboard");
  }

  render() {
    return (
      <main className={"main dashboard " + this.props.type}>
        <div className="main-container">

          <LetsStart />
          <CalculateMyBudget type='dashboard' dashboard />
          
          {(this.props.shortlist.length > 0)?<LoanShortlist shortlist={this.props.shortlist} />:""}
          {(this.props.user.eligibilityStart && (this.props.products.length > 0))?<EligibilityAssessment />:""}
          
        </div>
        <Disclaimer/>
      </main>

    )
  }
}

Dashboard.defaultProps = {
  requestProducts: () => ({}),
  shortlist: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
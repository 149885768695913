import { createAction } from 'redux-actions';

export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_REFINANCE_MODAL = 'OPEN_REFINANCE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL_WITH_ARGS = 'OPEN_MODAL_WITH_ARGS';

export const openModal = createAction(OPEN_MODAL);
export const openRefinanceModal = createAction(OPEN_REFINANCE_MODAL);
export const closeModal = createAction(CLOSE_MODAL);
export const openModalWithArgs = createAction(OPEN_MODAL_WITH_ARGS);

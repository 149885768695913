import React from 'react';
import { reduxForm } from 'redux-form';

import { FieldGroup } from "Components/Forms";
import Navigation from "../Components/Navigation";
import QuestionWrapper from "../Components/QuestionWrapper";

import { createValidateEmail } from 'Components/Forms/validation';
const validateEmail = createValidateEmail();

let EmailAddress = function(props) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>
      <h1>Where can we send your tailored results?</h1>
      <h2>This will also be your username. No spam, we promise.</h2>
      <div>
        <FieldGroup validate={validateEmail} id='email' width='large' position='left' title='Your Email' required='yes' type='text' />
      </div>
      <Navigation hidden={props.pristine } name="Almost there →" />
      <img className='img-email' src={require('../../../img/page_illustrations/man_computer.png')}/>
    </QuestionWrapper>
  )
}

EmailAddress = reduxForm({
  form: 'onboarding-email',  // set to match login form field
  destroyOnUnmount: false,
})(EmailAddress);

export default EmailAddress;

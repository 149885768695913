import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';

import ModuleBox from 'Components/Global/ModuleBox/ModuleBox';
import { MediumProductBox } from "Components/Product"
import shortListFilterFunction from "utils/shortListFilterFunction"

import updateFormValues from "modules/updateFormValues";

function mapStateToProps(state) {
  return {
    user: state.user.meta,
  };
}

class LoanShortlist extends Component {
  updateFilter(result) {
    updateFormValues(result, ()=>{});
  }

  render() {
    const { shortlist, user, isLoading } = this.props;
    const filteredProducts = shortlist.filter(p => !shortListFilterFunction(p, user));

    const filteredCount = shortlist.length - filteredProducts.length;
    const test = ((filteredProducts.length + (!!filteredCount ? 0 : 0)) * (290) + 8);

    if (filteredProducts.length !== 0) {
      return (
        <ModuleBox id="loan-shortlist" link="loan-shortlist" linkText="Go to Loan Shortlist" class="shortlist nb" title="Loan Shortlist" toggle="Y" shortlist={filteredProducts.length}>      
          <div className="scroll-container" style={{width: ((filteredProducts.length + (!!filteredCount ? 0 : 0)) * (291))}}>
            {filteredProducts.map((box, index)=>{
              return <MediumProductBox key={box.id} product={box} dashboard />
            })}
          </div>
        </ModuleBox>
      )}
    else {
      return "";
    }
  }
}

export default connect(mapStateToProps, null)(LoanShortlist)
import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal } from 'modules/modals/actions';
import { requestAddProductToApplication } from 'modules/user/actions';
import { requestPatchUser } from 'modules/user/actions';
const $ = window.$;

function mapStateToProps(state) {
    return {
      user: state.user.meta,
      userId: state.user.id,
    };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      closeModal,
      openModal,
      requestAddProductToApplication,
      requestPatchUser
    }, dispatch);
  }

class RefinanceAnalysisButton extends Component {

    refinanceFocus(product) {
      this.props.requestPatchUser({userId: this.props.userId, data: {meta: {refinanceFocus: product}}, callback: ()=>{}});
    }

    render() {
      return (
          <button onClick={()=>refinanceToggle(this, this.props.product, this.props.user.initialLoanDetails)} className="refinance-analysis">Refinance Analysis</button>
      )
    }
}

function refinanceToggle(that, product, initial) {
  if (initial) {
    //console.log('focusing on product!');
    that.refinanceFocus(product);
    setTimeout(() => {
      $('main').animate({
          scrollTop: $('.refinance-content').offset().top - $('main').offset().top + $('main').scrollTop() - 90
      }, 400);
    }, 10)
  }
  else {
    that.props.openModal("refinanceAnalysisStart");
    that.refinanceFocus(product);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RefinanceAnalysisButton);

import React from "react"; import { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

import LoanShortlistResult from './Components/LoanShortlistResult';
import LoanShortlistFiltered from './Components/LoanShortlistFiltered';
import LoanShortlistLabel from './Components/LoanShortlistLabel';
import LoanShortlistContainer from './Components/LoanShortlistContainer';
import BorrowHeader from 'Components/Global/BorrowHeader';

import updateFormValues from "modules/updateFormValues";
import shortListFilterFunction from "utils/shortListFilterFunction"
import requestFilteredProducts from "modules/product/requestFilteredProducts";
import { PageLoader } from "Components/Global/Loader";
import Tooltips from "Components/Tooltips";

import Disclaimer from 'Components/Global/ProductDetailsDisclaimer';

function mapStateToProps(state) {
  return {
    user: state.user.meta,
  };
}

class LoanShortlist extends Component {
  componentDidMount() {
    document.title = "Secure Finance - Compare Shortlist";
    this.props.setTitle("Compare Shortlist");
    this.props.setPageID("shortlist");
  }

  updateFilter(result) {
    updateFormValues(result, ()=>{});
  }

  onSubmit = results => {
    updateFormValues(results, ()=>{
      requestFilteredProducts(null, 0, this.props.user.sortField, this.props.user.sortDirection, this.props.user.godmode);
    }, true);
  }

  render(){
    const { shortlist, user, isLoading } = this.props;
    const filteredProducts = shortlist.filter(p => !shortListFilterFunction(p, user));

    const filteredCount = shortlist.length - filteredProducts.length;

    console.log("THIS" + JSON.stringify(this.props.user))

    return(
      <main className={"loan-shortlist " + this.props.type}>
        <div className="main-container">
          <PageLoader show={isLoading}/>
            {(shortlist.length===0)?(
                <div className="empty">
                  <div className="illustration"></div>
                  <h4>You have no shortlisted loans! 😔</h4>
                  <p>Why not <NavLink to="/search-loans">search</NavLink> to identify a suitable home loan?</p>
                </div>
              ):(
                <div>
                  <BorrowHeader type='loan-shortlist' onSubmit={this.onSubmit}/>
                  {(this.props.user.loanPurpose === "refinance")?<Tooltips type="shortlist" />:""}
                  <div className="loan-shortlist">
                    <LoanShortlistContainer>
                      <div className="scroll-container" style={{width: ((filteredProducts.length + (!!filteredCount ? 1 : 0)) * (280+20))}}>
                        {filteredProducts.map((box, index)=> <LoanShortlistResult key={index} calculateBox={box} key={box.id} onSubmit={this.onSubmit} />)}
                        {filteredCount > 0 &&
                          <LoanShortlistFiltered count={filteredCount}/>
                        }
                      </div>
                    </LoanShortlistContainer>
                  </div>
                </div>

              )}

        </div>
        <Disclaimer/>
      </main>
    );
  }
}

export default connect(mapStateToProps, null)(LoanShortlist)
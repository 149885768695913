import React, { Fragment } from "react";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import RefinanceAnalysis from "Views/RefinanceAnalysis";

import calculateLoanValues from "utils/calculateLoanValues";
import CheckEligibilityButton from "Components/CheckEligibilityButton";
import RefinanceAnalysisButton from "Components/RefinanceAnalysisButton";
import BorrowingPowerValue from "Components/BorrowingPowerValue";
import StarLoansButton from "Components/StarLoansButton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openModal, closeModal } from "modules/modals/actions";
import { requestPatchUser } from "modules/user/actions";
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import Tooltips from "Components/Tooltips";
import BankLogo from "Components/Global/BankLogo";

import styled, { css } from "styled-components";

function mapStateToProps(state) {
  return {
    user: state.user,
    userId: state.user.id,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeModal,
      openModal,
      requestPatchUser,
    },
    dispatch
  );
}

class LoanSearchResult extends Component {
  constructor(props) {
    super(props);

    // let isStarred = false;

    // for (let i = 0; i < props.shortlist.length; i++) {
    //   if (props.shortlist[i].id === props.result.id) {
    //     isStarred = true;
    //     break;
    //   }
    // }

    this.state = { refinanceState: false, toggleRefinance: false };
  }

  toggleRefinance() {
    this.setState((prevState) => {
      return { ...prevState, toggleRefinance: !prevState.toggleRefinance };
    });
  }

  handleClick(isStarred) {
    if (isStarred) {
      this.props.removeFromShortlist(this.props.result.id);
    } else {
      this.props.addToShortlist(this.props.result);
    }
  }

  refinanceFocus(product) {
    this.props.requestPatchUser({
      userId: this.props.userId,
      data: { meta: { refinanceFocus: product } },
      callback: () => {},
    });
  }

  render() {
    const {
      shortlist,
      nsrValues,
      tags,
      result,
      state,
      repaymentFrequency,
      user,
    } = this.props;

    let product = calculateLoanValues(result);

    let newMonthlyRepayment;
    if (product.repaymentType === "Interest Only") {
      newMonthlyRepayment =
        (parseFloat(product.iir) / 100 / 12) * user.meta.refinanceLoanAmount;
    } else {
      newMonthlyRepayment = calculateMonthlyRepayments(
        parseFloat(product.iir) / 100 / 12,
        (user.meta.refinanceYears || 30) * 12,
        user.meta.refinanceLoanAmount
      ).toFixed(2);
    }

    let potentialSavings =
      user.meta.refinanceMonthlyRepayment - newMonthlyRepayment;
    if (repaymentFrequency === "weekly") potentialSavings *= 12 / 52.1428;
    if (repaymentFrequency === "fortnightly") potentialSavings *= 12 / 26.0714;
    potentialSavings = potentialSavings.toFixed(2);

    const hideDetails =
      ((user.meta.entryType === "main" || user.meta.entryType === "modal") &&
        !user.phoneNumber) ||
      (user.mobileVerificationRequired && !user.isMobileVerified);

    const maxNSR = Math.max(...Object.values(nsrValues));

    //console.log('max', maxNSR);

    return (
      <div>
        <LoanResults
          isPriority={product.is_priority_product}
          className={
            "loan-result col-m1" +
            MonthlySavingsStyle(
              this.props.loanPurpose,
              user.meta.initialLoanDetails
            )
          }
        >
          <LoanDetailsMobile>
            {!!product.is_priority_product && (
              <SelectProductTagContainer>
                <Tooltips
                  type="wrapper"
                  maxWidth="180px"
                  definition={
                    "Secure Finance Choice products are top selections made by the Secure Finance team"
                  }
                >
                  <SelectProductTag>Secure Finance Choice</SelectProductTag>
                </Tooltips>
              </SelectProductTagContainer>
            )}
            <div class="product">
              {hideDetails ? (
                <div
                  style={{
                    margin: "0",
                    height: "100%",
                    backgroundColor: "#eee",
                  }}
                />
              ) : (
                <Fragment>
                  <BankLogo product={product} />
                </Fragment>
              )}
              <span>{hideDetails ? "" : product.product_name}</span>
            </div>
            <Statistics>
              <li className={product.redraw_allowed}>Redraw</li>
              <li className={product.full_offset}>
                {product.full_offset === "additional" ? (
                  <Tooltips type="wrapper" definition={product.offsetTooltip}>
                    Offset
                  </Tooltips>
                ) : (
                  <span>Offset</span>
                )}
              </li>
              <li className={product.extra_repayments_allowed}>
                Early Repayments
              </li>
            </Statistics>
          </LoanDetailsMobile>

          <div className="logo">
            {!!product.is_priority_product && (
              <SelectProductTagContainer>
                <Tooltips
                  type="wrapper"
                  maxWidth="180px"
                  definition={
                    "LSecure Finance Choice products are top selections made by the Secure Finance team"
                  }
                >
                  <SelectProductTag>Secure Finance Choice</SelectProductTag>
                </Tooltips>
              </SelectProductTagContainer>
            )}
            {hideDetails ? (
              <div
                style={{ margin: "0", height: "100%", backgroundColor: "#eee" }}
              />
            ) : (
              <Fragment>
                <BankLogo product={product} />
                {!!product.rebateTooltip && (
                  <Tooltips type="rebate" definition={product.rebateTooltip} />
                )}
                {product.rebateTagContent && (
                  <p className="rebate-tag">{product.rebateTagContent}</p>
                )}
              </Fragment>
            )}
          </div>

          <div className="interest-rate">
            {/* {tags.interestRate + "<- This"} */}
            {/* {RateTags(tags.interestRate, product.iir, "Lowest Rate")} */}
            <div className="rate-container">
              <div className="value">
                {parseFloat(product.iir).toFixed(2)}
                <span>% p.a.</span>
              </div>
              <div className="value-description">
                {product.rateDescriptionYears}
              </div>
            </div>
          </div>

          <div className="comparison-rate">
            {/* {RateTags(tags.comparisonRate, product.comparisonRate, "Lowest Rate")} */}
            <div className="value">
              {parseFloat(product.comparisonRate).toFixed(2)}
              <span>% p.a.</span>
            </div>
          </div>

          <div className="repayments">
            <div className="value">
              $
              {Math.round(repaymentValue(product, repaymentFrequency))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              <span>{repaymentFrequencyLabel(repaymentFrequency)}</span>
            </div>
          </div>

          {MonthlySavings(
            this.props.refinance,
            Math.round(potentialSavings),
            user.meta.initialLoanDetails,
            repaymentFrequency
          )}

          <div className="borrowing-power">
            {/* {RateTags(nsrValues[product.bank], maxNSR, "Highest Power")} */}

            <div className="borrowing-power-container">
              <BorrowingPowerValue
                value={nsrValues[product.bank] || nsrValues["default"]}
                user={this.props.user}
                lender={user.lenders.find(
                  (lender) => lender.id === product.bank
                )}
                product={product}
              />
            </div>
          </div>

          <div className="compare-starred-loans">
            <div className="compare-container">
              <CheckEligibilityButton product={result} how="true" />
              <StarLoansButton product={result} />
              {/* <button onClick={()=>this.handleClick(isStarred)} className={"favourites " + (isStarred ? 'active' : '') } ></button> */}
            </div>
          </div>

          <LoanDetailsTablet>
            <div class="left">
              <span>{hideDetails ? "" : product.product_name}</span>
              <Statistics>
                <li className={product.redraw_allowed}>Redraw</li>
                <li className={product.full_offset}>
                  {product.full_offset === "additional" ? (
                    <Tooltips type="wrapper" definition={product.offsetTooltip}>
                      Offset
                    </Tooltips>
                  ) : (
                    <span>Offset</span>
                  )}
                </li>
                <li className={product.extra_repayments_allowed}>
                  Early Repayments
                </li>
              </Statistics>
            </div>
            <div class="right">
              <CheckEligibilityButton product={result} />
              <StarLoansButton product={result} />
            </div>
          </LoanDetailsTablet>

          <div className="loan-statistics">
            <div className="left">
              <NavLink exact to={"/products/" + product.id}>
                {" "}
                <button className="product-details">Product Details</button>
              </NavLink>
              {RefinanceToggle(
                this.props.loanPurpose === "refinance",
                this,
                product.id
              )}
              <span>{hideDetails ? "" : product.product_name}</span>

              <div class="details">
                <span>{hideDetails ? "" : product.product_name}</span>
                <Statistics>
                  <li className={product.redraw_allowed}>Redraw</li>
                  <li className={product.full_offset}>
                    {product.full_offset === "additional" ? (
                      <Tooltips
                        type="wrapper"
                        definition={product.offsetTooltip}
                      >
                        Offset
                      </Tooltips>
                    ) : (
                      <span>Offset</span>
                    )}
                  </li>
                  <li className={product.extra_repayments_allowed}>
                    Early Repayments
                  </li>
                </Statistics>
              </div>
            </div>

            <div className="right">
              <Statistics>
                <li className={product.redraw_allowed}>Redraw</li>
                <li className={product.full_offset}>
                  {product.full_offset === "additional" ? (
                    <Tooltips type="wrapper" definition={product.offsetTooltip}>
                      Offset
                    </Tooltips>
                  ) : (
                    <span>Offset</span>
                  )}
                </li>
                <li className={product.extra_repayments_allowed}>
                  Early Repayments
                </li>
              </Statistics>

              <div className="fees">
                <div className="fee-upfront">
                  <strong>${product.approxUpfrontFee}</strong>
                  <span>Approx Upfront</span>
                </div>
                <div className="fee-annual">
                  <strong>${product.ongoing_fees}</strong>
                  <span>Annual Fee</span>
                </div>
              </div>
            </div>
          </div>
        </LoanResults>
        {user.meta.refinanceFocus === product.id &&
        user.meta.initialLoanDetails == "1" &&
        this.props.refinance == "refinance" &&
        this.props.browser.greaterThan.w840 ? (
          <div className="refinance-analysis-box">
            <div className="refinance-title">
              <button onClick={() => this.refinanceFocus(0)}></button>
              <div className="refinance-title-container">
                <h2>
                  <span>Refinance Analysis</span>
                </h2>
              </div>
            </div>

            <RefinanceAnalysis product={product} result={result} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const Statistics = styled.ul`
  display: inline-block;
  vertical-align: 50%;
  margin-right: 10px;

  li {
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: 10px 10px;
    padding-left: 15px;
    margin-left: 10px;

    &.Y,
    &.T {
      background-image: url(${require("../../../../img/elements/check-green.png")});
      color: #26bd27;

      span {
        color: #26bd27;
      }
    }

    &.N,
    &.F {
      background-image: url(${require("../../../../img/elements/cross-red.png")});
      color: #ff4e4c;
      \ span {
        color: #ff4e4c;
      }
    }

    &.additional {
      padding-left: 0px;
      &::before {
        content: "$";
        margin-right: 5px;
      }
      color: #2291ff;

      span {
        color: #2291ff !important;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const LoanDetailsMobile = styled.div`
  position: relative;
  border-bottom: 1px solid #e9ebf1;
  padding: 15px 15px;
  /* overflow: auto; */
  display: none;

  @media (max-width: 645px) {
    display: block;
  }

  .product {
    display: block;

    h6 {
      vertical-align: top;
      display: inline-block;
      width: auto;
      width: 85px;
      font-size: 16px;
      line-height: 1.2;
      color: #727c8f;
      font-style: italic;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 85px;
      height: auto;
      max-height: 50px;
      min-height: 50px;
      object-fit: contain;
      width: 85px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
      width: calc(100% - 85px);
      padding-left: 20px;
      line-height: 1.4;
    }
  }

  ${Statistics} {
    display: block;
    margin-top: 5px;
  }
`;

const LoanDetailsTablet = styled.div`
  border-top: 1px solid #e9ebf1;
  padding: 15px 20px;
  overflow: auto;
  display: none;

  @media (max-width: 950px) {
    overflow: visible;
    display: block;
  }

  @media (max-width: 840px) {
    display: none;
  }

  @media (max-width: 645px) {
    display: none;
  }

  .left {
    display: inline-block;

    ${Statistics} {
      display: block;
      margin-top: 5px;
    }

    span {
      font-style: italic;
      font-size: 12px;
      color: #101922;
    }
  }

  .right {
    float: right;
    display: inline-block;
  }
`;

const SelectProductTagContainer = styled.div`
  position: absolute;
  top: -1px;
  left: -2px;
  width: 100%;
`;

const SelectProductTag = styled.div`
  margin: auto;
  width: 115px;
  height: 22px;
  line-height: 22px;
  color: white;
  background-color: #2291ff;
  font-size: 12px;
  font-weight: 700;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoanResults = styled.div`
  margin-top: 15px;
  border-radius: 7px;
  /* background-color: red !important; */
  ${(p) =>
    p.isPriority &&
    css`
      border: solid 2px #4a90e2 !important;
    `}
  /* For 800px width */
  @media (max-width: 950px) {
    /* background-color: green !important; */
  }

  /* For 615px width */
  @media (max-width: 645px) {
    border-radius: 0 !important;
    /* background-color: purple !important; */
  }

  .logo,
  .interest-rate,
  .comparison-rate,
  .repayments,
  .borrowing-power,
  .monthly-savings,
  .compare-starred-loans {
    width: calc((100% - 200px) / 5);
    display: inline-block;
    border-left: solid 1px #e9ebf1;
    height: 100px;
    vertical-align: top;
    text-align: center;
    padding: 20px 5px;

    @media (max-width: 1000px) {
      width: calc((100% - 200px) / 4);
    }

    @media (max-width: 950px) {
      width: calc(100% / 4);
    }

    @media (max-width: 645px) {
      width: calc(100% / 3);
      height: 75px;
    }
  }

  &.monthly-savings {
    .logo,
    .interest-rate,
    .comparison-rate,
    .repayments,
    .borrowing-power,
    .compare-starred-loans,
    .monthly-savings {
      width: calc((100% - 200px) / 6);

      @media (max-width: 1000px) {
        width: calc((100% - 200px) / 5);
      }

      @media (max-width: 950px) {
        width: calc(100% / 5);
      }

      @media (max-width: 645px) {
        width: calc(100% / 4);
      }
    }
  }

  .compare-starred-loans {
    width: 200px;
  }

  .logo {
    border-left: none;
    position: relative;

    @media (max-width: 645px) {
      display: none;
    }

    h6 {
      font-size: 12px;
      font-weight: 400;
      color: #727c8f;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 50%;
      position: relative;
      transform: translate3d(0, -50%, 0);
    }

    .rebate-tag {
      position: absolute;
      left: 10%;
      bottom: 0;
      width: 80%;
      background-color: #27c229;
      border-bottom: 1px solid #24ab26;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: #fff;
      padding: 5px 0;
      font-weight: 600;
      font-size: 12px;

      @media (max-width: 1100px) {
        font-size: 10px;
      }
    }
  }

  .value {
    font-size: 26px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1210px) {
      font-size: 20px;
    }

    @media (max-width: 645px) {
      font-size: 20px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #727c8f;

      @media (max-width: 1210px) {
        font-size: 10px;
        font-weight: 400;
      }

      @media (max-width: 645px) {
        font-size: 8px;
      }
    }
  }

  .value-description {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    color: #727c8f;

    @media (max-width: 1210px) {
      font-size: 10px;
    }
  }

  .logo {
    vertical-align: middle;

    img {
      top: 50%;
      position: relative;
      transform: translate3d(0, -50%, 0);
      padding: 0 2px;
      display: block;
      vertical-align: middle;
      margin: 0 auto;
      max-width: 120px;
      width: 100%;
      height: auto;
      max-height: 50px;
      object-fit: contain;
    }
  }

  .interest-rate {
    @media (max-width: 645px) {
      border-left: none;
    }

    .rate-container {
      padding-top: 17px;
      text-align: center;

      @media (max-width: 645px) {
        padding-top: 7px;
      }
    }
  }

  .comparison-rate {
    .value {
      padding-top: 17px;

      @media (max-width: 645px) {
        padding-top: 7px;
      }
    }
  }

  .repayments {
    overflow: hidden;

    .value {
      padding-top: 17px;

      @media (max-width: 645px) {
        padding-top: 7px;
      }
    }

    span {
      display: block;
      margin-top: 6px;
    }
  }

  .monthly-savings {
    .value {
      padding-top: 17px;

      @media (max-width: 645px) {
        padding-top: 7px;
      }
    }

    span {
      display: block;
      margin-top: 6px;
    }
  }

  .borrowing-power {
    @media (max-width: 1000px) {
      display: none;
    }

    .borrowing-power-container {
      padding-top: 19px;
      display: block;
      margin: 0 auto;
    }

    .borrowing-value {
      &.empty-value {
        padding-top: 1px;
      }

      @media (max-width: 1440px) {
        color: transparent;
        width: 0;
        padding-left: 30px;

        &.empty-value {
          width: inherit;
          padding-left: 0;
          color: #2291ff;
          cursor: pointer;

          &:hover {
            color: #46a3ff;
          }

          &:active,
          &:focus {
            color: #1c83ea;
          }
        }
      }
    }
  }

  .compare-starred-loans {
    @media (max-width: 950px) {
      display: none;
    }

    .compare-container {
      min-width: 160px;
      display: block;
      margin: 0 auto;
      margin-top: 11px;

      .favourites {
        vertical-align: top;
        display: inline-block;
        /* .favourites(); */
      }
    }
  }

  .best {
    font-weight: 600;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    height: 17px;
    background-color: #27c229;
    border-bottom: 1px solid #24ab26;
    width: 85px;
    color: #fff;
    padding-top: 4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    margin-top: -20px;
    display: block;
    margin: -20px auto 5px;
  }

  .loan-statistics {
    width: 100%;
    border-top: solid 1px #e9ebf1;
    padding: 10px 0 7px 20px;
    border-radius: 0 0 7px 7px;
    font-size: 12px;
    font-weight: 400;
    background-color: #fbfbfc;

    @media (max-width: 645px) {
      padding: 10px 20px 7px 20px;
      /* overflow: auto; */
    }

    .left,
    .right {
      display: inline-block;
      max-width: calc(100% - 488px);
    }

    .left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 470px);

      @media (max-width: 950px) {
        max-width: 100%;
        overflow: visible;
      }

      .details {
        display: none;

        span {
          display: inherit;
          margin-left: 0;
          margin-bottom: 5px;
        }

        @media (max-width: 840px) {
          display: block;
        }

        @media (max-width: 645px) {
          display: none;
        }
      }

      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        cursor: pointer;
        outline: inherit;
        font-size: 13px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        padding: 12px 25px 12px 25px;
        background-color: #fff;
        border: 1px solid #d8dce7;
        box-shadow: 0 1px 0 0 #e9ebf1;
        color: #223241;
        font-weight: 600;
        font-family: "Lato", sans-serif;
        font-size: 11px;
        padding: 6px 10px 6px 10px;
      }

      .product-details,
      .refinance-analysis {
        background-position: calc(100% - 10px) 50%;
        background-repeat: no-repeat;
        padding-right: 30px;

        @media (max-width: 840px) {
          display: none;
        }

        @media (max-width: 645px) {
          display: none;
        }
      }

      .product-details {
        background-image: url(${require("../../../../img/button/right-arrow-short-blue.png")});
        background-size: 7px 10px;
        padding-right: 28px;
      }

      .refinance-analysis {
        background-image: url(${require("../../../../img/button/stats-blue.png")});
        margin-left: 10px;
        background-size: 10px 10px;
      }

      span {
        font-weight: 400;
        font-family: "Lato", sans-serif;
        font-size: 12px;
        margin-left: 10px;
        font-style: italic;

        @media (max-width: 950px) {
          display: none;
        }
      }
    }

    .right {
      float: right;
      text-align: left;
      max-width: 488px;

      @media (max-width: 645px) {
        width: 100%;
        max-width: none;
        float: none;
      }

      ${Statistics} {
        @media (max-width: 950px) {
          display: none;
        }
      }

      .fees {
        display: inline-block;
        text-align: center;
        width: 203px;

        @media (max-width: 645px) {
          width: 100%;
        }

        .fee-upfront,
        .fee-annual {
          display: inline-block;
          border-left: solid 1px #e9ebf1;
          width: 98px;

          @media (max-width: 645px) {
            width: 50%;
            border-left: none;
          }
        }

        .fee-annual {
          @media (max-width: 645px) {
            border-left: solid 1px #e9ebf1;
          }
        }

        span {
          font-weight: 400;
          font-size: 10px;
          margin-top: 2px;
          display: block;
          color: #727c8f;
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }
`;

function repaymentValue(product, freq) {
  if (freq === "weekly") return product.weeklyRepayments;
  if (freq === "fortnightly") return product.fortnightlyRepayments;
  return product.monthlyRepayments;
}

function repaymentFrequencyLabel(freq) {
  if (freq === "weekly") return "/ week";
  if (freq === "fortnightly") return "/ fortnight";
  return "/ month";
}

function RefinanceToggle(value1, a, productId) {
  if (value1 === true) {
    // return <button onClick={()=>a.toggleRefinance()} className="refinance-analysis">Refinance Analysis</button>;
    return <RefinanceAnalysisButton product={productId} />;
  }
}

function MonthlySavings(value1, testValue, value3, frequency) {
  if (value1 === "refinance" && value3 == "1") {
    return (
      <div className="monthly-savings">
        <div className="value">
          ${testValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          <span>{repaymentFrequencyLabel(frequency)}</span>
        </div>
      </div>
    );
  }
}

function MonthlySavingsStyle(value1, value2) {
  if (value1 === "refinance" && value2 == "1") {
    return " monthly-savings";
  } else {
    return "";
  }
}

function RateTags(value1, value2, tagName) {
  if (value1 === value2) {
    return <div className="best">{tagName}</div>;
  }
}

// function BorrowingPowerValue(value1){
//   //console.log('NSR value:', value1);
//   if (value1 < 0.7){
//     return <div className="borrowing-value low">Low</div>
//   }
//   if (0.7 <= value1 && value1 < 1.3){
//     return <div className="borrowing-value medium">Medium</div>
//   }
//   else {
//     return <div className="borrowing-value high">High</div>
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(LoanSearchResult);

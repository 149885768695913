import formatNumber from 'utils/formatNumber';

// redux-form validate does not accept anonymous functions
// So don't evaluate these 2nd order functions in render()!

export const createRequired = (errorString = 'This is required') => 
  value => value || value === 0 ? undefined : errorString;

//for dates in the format ddmmyy
export const createValidateDate = (errorString = 'The date entered is invalid') => 
  value => {
    if (!value) return undefined;
    const [day, month, year] = value
      .match(/.{1,2}/g)
      .map(n => parseInt(n));
      
    if (!(day && month && year)) return errorString;

    const date = new Date(1900 + year, month - 1, day);
    if (isNaN(date)) return errorString;
    
    // Checks to see if any values overflowed. 99/99/99 would be valid otherwise
    if (day !== date.getDate() ||
        (month - 1) !== date.getMonth() ||
        1900 + year !== date.getFullYear()) 
        return errorString;
  };

  export const createValidateEmail = (errorString = 'Email address is invalid') => 
    value => value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? undefined : errorString;
    
  export const createNotZero = (errorString = 'The number entered must be above 0') =>
    value => Number(value) ? undefined : errorString;

  export const createPasswordValidation = (errorString = 'Password must contain at least 6 characters, 1 letter and 1 number') =>
    value => (!value || (/(?=.*\d)(?=.*[a-z])/.test(value) && (value.length >= 6) ))  ? undefined : errorString;
    
  export const createNumberBetween = (low, high, errorString = (low, high) => `Number must between ${low} & ${high}`) =>
    value => {
      const val = parseFloat(value);
      if (val < low || val > high) return errorString(formatNumber(low), formatNumber(high));
      return null; 
    }

  export const phoneNumberProps = {
    normalize: value => (value || '').replace(/[^0-9+]+/g, ''),
    validate: value => {
      const length = (value || '').length;
      if (length === 0) return null;
      const numeralCount = (value || '').replace(/[^0-9]+/g, '').length;
      if (value.startsWith('+61')) {
        return (numeralCount !== 11 || length - numeralCount > 1) ? 'Phone number is invalid' : null;
      }
      if (value.startsWith('04')) {
        return (numeralCount !== 10 || length - numeralCount > 1) ? 'Phone number is invalid' : null;
      }
      return (numeralCount < 8 || length - numeralCount > 1) ? 'Phone number is invalid' : null;
    }
  };

import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Important from '../Questions/important';
import LivingConditions from '../Questions/livingConditions';
import PayOffLoan from '../Questions/payOffLoan';
import CombinedIncome from '../Questions/combinedIncome';
import FullName from '../Questions/fullName';
import FullNameFinal from '../Questions/fullNameFinal';
import EmailAddress from '../Questions/emailAddress';
import MobileNumber from '../Questions/mobileNumber';
import TotalCost from '../Questions/totalCost';
import YourContribution from '../Questions/yourContribution';
import HowClose from '../Questions/howCloseToBuilding';

import Debouncer from 'utils/debouncer';
const debouncer = new Debouncer();

let OnboardingForm = props => {
  const questionProps = {
    isTransitioning: props.isTransitioning,
    onSubmit: props.onSubmit,
    maxQuestion: props.maxQuestion,
    setQuestion: props.setQuestion,
    currentQuestion: props.currentQuestion,
    gotoQuestion: props.gotoQuestion,
    skip: props.goToNextQuestion,
    debounce: debouncer,
    finishOnboarding: props.finishOnboarding,
    globalSettings: props.globalSettings,
  }

  const showMobile = props.location.search.indexOf('showMobile=true') !== -1;
  const showEmail = props.location.search.indexOf('showEmail=true') !== -1;

  let id = 1;

  return (

		<div id="fullpage" className="form" >

      <Important id={id++} {...questionProps}/>
      <TotalCost id={id++} {...questionProps}/>
      <YourContribution id={id++} {...questionProps}/>
      <HowClose id={id++} {...questionProps}/>
      <LivingConditions id={id++} {...questionProps}/>
      <PayOffLoan id={id++} {...questionProps}/>
      <CombinedIncome id={id++} {...questionProps}/>
      {showMobile &&
        <FullName id={id++} {...questionProps}/>
      }
      {showEmail &&
        <EmailAddress id={id++} {...questionProps}/>
      }
      {showMobile ? 
        <MobileNumber id={id++} {...questionProps} finalQuestion/>
        :        
        <FullNameFinal id={id++} {...questionProps} finalQuestion/>
      }
      <div className="divide bottom"></div>

		</div>
	);
};

OnboardingForm = reduxForm({
  form: 'onboardingformvalues'  // a unique identifier for this form
})(OnboardingForm);

const selector = formValueSelector('onboardingformvalues') // <-- same as form name
OnboardingForm = connect(
  state => {
    //console.log(state);
    // can select values individually
    const importantState = selector(state, 'important');
    const closeToBuyingState = selector(state, 'closeToBuying');
    const livingConditionsState = selector(state, 'livingConditions');

    return {
      importantState,
      closeToBuyingState,
      livingConditionsState,
      location: state.router.location
    }
  }
)(OnboardingForm)

export default OnboardingForm;

export default results  => {
  //console.log('submit results:', results);
  let userData = {meta:{}};
  let applicationData = {meta:{}};
  let partner = {meta:{}};

  for (let key in results) {
    let split = key.split("-");
    if (split.length < 2) {
      continue;
    }
    let service = split[0];
    let dbKey = split[1];

    //reject non-primitive values
    // if (typeof results[key] === 'object' && results[key] !== null) {
    //   continue;
    // }

    switch (service) {
      case "user":
        userData[dbKey] = results[key];
        break;
      case "user_meta":
        userData.meta[dbKey] = results[key];
        break;
      case "calculators":  
        if (!userData.meta.calculators) userData.meta.calculators = {};
        userData.meta.calculators[dbKey] = results[key];
        break;
      case "application":   
        applicationData[dbKey] = results[key];
      break;
      case "application_meta":
        applicationData.meta[dbKey] = results[key];
        break;
      case "user_partner":
        partner[dbKey] = results[key];
        break;
      case "user_partner_meta":
        partner.meta[dbKey] = results[key];
        
      break;
      default:
        break;
    }
  }

  userData.meta['searchFilter_isMonthly'] = '';
  userData.meta['searchFilter_isWeekly'] = '';
  userData.meta['searchFilter_isFortnightly'] = '';

  userData.application = applicationData;
  userData.partner = partner;

  return userData;
}
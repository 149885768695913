import React from "react"; import { Component } from "react";
import ProceedButton from 'Components/ProceedButton';

class CheckEligibilityInitial extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityInitial") {
    return (
      <div className="modal-content">
        <img src={require('../../../img/modal/initial-eligibility.png')} alt="Notifications"/>
        <h1>Eligibility Assessment</h1>
        <h4>No credit checks.</h4>
        <ul>
          <li>We will review your situation to confirm it meets lender credit policy. To do this, we will need to gather further details from you. </li>
        	<li>You may add up to 2 more loan products to your eligibility check by clicking the Check Eligibility button situated next to any loan product.</li>
        </ul>
        <div className="modal-navigation">
          {/* <button onClick={()=>this.props.openModal("checkEligibilityAdd")} className="left button-5">Skip Adding More</button> */}
          <ProceedButton className="left button-5" name="Skip Adding More" />
          <button onClick={()=>this.props.closeModal()} className="right button-1">Add Up to 2 More Product</button>
        </div>

        <div className="request-assistance">
          <div className="request-assistance-content">
            <h4>Want us to do the heavy lifting?</h4>
            <p>We're always here and happy to help <br/>you select a product, and apply for a loan.</p>
          </div>
          <button onClick={()=>window.Intercom('show')}>Request Assistance</button>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default CheckEligibilityInitial;

import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadioBox from '../Components/RadioBox';
import QuestionWrapper from "../Components/QuestionWrapper";

let PayOffLoan = function(props) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>
      <div className="content two-box button">
        <h1>How do you want to structure your repayments?</h1>
        <h2>Principal & Interest generally attracts a lower interest rate and helps you pay down the loan sooner.</h2>
        <RadioBox name="user_meta-isPandILoan" onChange={()=>{props.handleSubmit()}} text="Principal and Interest" value="Y" select={props.value} />
        <RadioBox name="user_meta-isPandILoan" onChange={()=>{props.handleSubmit()}} text="Interest Only" value="N" select={props.value} />
      </div>
    </QuestionWrapper>
  )
}

PayOffLoan = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-payOffLoan'  // a unique identifier for this form
})(PayOffLoan);

const selector = formValueSelector('onboarding-payOffLoan') // <-- same as form name
PayOffLoan = connect(
  state => {
    //console.log(state);
    // can select values individually
    const value = selector(state, 'user_meta-isPandILoan');
    return {
      value
    }
  }
)(PayOffLoan);

export default PayOffLoan;

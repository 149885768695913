import React from "react"; import { Component } from "react";
import { Loader } from "./Global/Loader";

class Loading extends Component {
  render() {
    return (
      <div id="loading">
         <Loader/>
      </div>
    )
  }
}

export default Loading;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { requestLogin } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import { requestPatchUser } from 'modules/user/actions';
import { formValueSelector } from "redux-form";

const selector = formValueSelector('searchFilter');
function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    applicationProducts,
    products,
    application,
    userMeta: state.user.meta,
    userId: state.user.id,
    isOOLoan: selector(state, 'user_meta-isOOLoan'),
    isPandILoan: selector(state, 'user_meta-isPandILoan'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    requestPatchUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import React from "react";
import { Component } from "react";
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FieldGroup, FormContainer } from "Components/Forms";
import { createNumberBetween } from 'Components/Forms/validation';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';
import { verificationService, emailService } from 'modules';
import { requestPatchUser } from 'modules/user/actions'
import TagManager from 'react-gtm-module'

const numberBetween = createNumberBetween(1, 999999999, (low, high) => `Combined income must be between $${low} and $${high}`);

function mapStateToProps(state) {
  const fields = (state.form.account || {}).fields || {};
  const errors = (state.form.account || {}).syncErrors || {};
  return {
    user: state.user,
    fields,
    errors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}


const validatePhoneNumber = (value) => {
  let valid = false;
  if (value.length === 9) {
    valid = value.startsWith('4') && getDigitCounts(value.replace('4', '')).size > 1
  } else if (value.length === 10) {
    valid = value.startsWith('04') && getDigitCounts(value.replace('04', '')).size > 1
  }
  return valid ? null : 'Not a valid mobile number';
  
}
  
const pinMask = {
  normalize: (value, oldValue) => {
    if (!value) return '';
    return value.length <= 6 ? value : oldValue;
  }
};

const phoneMask = {
  normalize: (value, oldValue) => {
    if (!value) return '';
    if (value.startsWith('0')) {
      return value.length <= 10 ? value : oldValue;
    }
    return value.length <= 9 ? value : oldValue;
  }
};

class SMSCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resend: numberIsFake(props.user.phoneNumber),
    }
  }

  toggleResend() {
    this.props.reset();
    // if (this.state.resend) {
    //   this.props.change('phoneNumber', '');
    //   this.props.untouch('phoneNumber')
    // } else {
    //   this.props.change('pin', '');
    //   this.props.untouch('pin');
    // }
    this.setState(state => ({ ...state, resend: !this.state.resend }))
  }

  onSubmit = values => {
    return new Promise( async (resolve, reject) => {
      let phoneNumber = values.phoneNumber;

      // console.log("#khu :" + phoneNumber+ " : Is Fake "+ this.props.user.id);
      if (phoneNumber) {
        if (phoneNumber.startsWith('4')) {
          phoneNumber = '0' + phoneNumber;
        }
        this.props.requestPatchUser({
          userId: this.props.user.id,
          data: {
            phoneNumber,
            phoneNumberType: 'mobile'
          },
          callback: async () => {
            await this.resendSms();
            this.toggleResend();
            resolve();
          },
        });
        return;
      }

      const result = await verificationService.create({
        type: 'sms-verification',
        phoneNumber: this.props.user.phoneNumber,
        userId: this.props.user.id,
        pin: values.pin,
      });
 
      
      if (result.error) {
        //console.log(result.error);
        reject(new SubmissionError({
          _error: 'Incorrect pin',
          pin: 'Incorrect pin',
        }));
      } else {
        let user = this.props.user;
        const conversionType = user.meta.isOOLoan === "Y" ?
          (user.meta.loanPurpose === "refinance" ? 'homeRefinance' : 'homePurchase')
          :
          (user.meta.loanPurpose === "refinance" ? 'investorRefinance' : 'investorPurchase');

        this.props.requestPatchUser({ // this will NOT SAVE to DB (no user id provided)
          data: {
            isMobileVerified: true,
          },
          callback: () => {

            this.props.requestPatchUser({
              userId: user.id,
              data: {
                meta: {
                  conversionType,
                }
              },
              callback: () => {
                emailService.create({ type: 'finish-onboarding' });
              }
            })
            

            TagManager.dataLayer({
              gtmId: 'GTM-5HT97R9',
              dataLayer: {
                event: conversionType,
              }
            });

            console.log(conversionType);

            window.Intercom("boot", {
              app_id: "t2b7w54a"
            });

            resolve();
          },
        });
      }

    })
  };
  resendSms = async () => {
    const result = await verificationService.create({
      type: 'send-sms-verification',
      phoneNumber: this.props.user.phoneNumber,
      userId: this.props.user.id,
      resendAttempt: true,
    });

    this.props.requestPatchUser({
      data: {
        smsAttempts: this.props.user.smsAttempts + 1,
      }
    });

    if (result.error) {
      //console.log(result.error)
      return { error: result.error };
    } else {
      //console.log('SMS resent');
      return { success: true };
    }
  }

  render() {
    const { user, invalid, fields, errors, submitFailed } = this.props;
    const validationError = (errors.pin === 'This is required' || errors.phoneNumber === 'This is required') ? submitFailed : invalid && (fields.pin || {}).touched || (fields.phoneNumber || {}).touched;
    const isFakeNumber = numberIsFake(user.phoneNumber)
    let phoneNumber = user.phoneNumber;
    if (phoneNumber.startsWith('04')) {
      phoneNumber = '+61' + phoneNumber.replace('0', '');
    }
    if (phoneNumber.startsWith('4')) {
      phoneNumber = '+61' + phoneNumber;
    }
   
    return (
      <div className="sms-code">
        <FormContainer className="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {(this.state.resend)
            ?
            <div className="enter-mobile">
              <h3>Please enter your Australian mobile number</h3>
              <div class="mobile-container">
                <div className="number-overlay">
                  +61
                </div>
                <FieldGroup required id='phoneNumber' type='text' width='large' position='left' validate={validatePhoneNumber} {...phoneMask}/>
              </div>
              {isFakeNumber || this.props.submitting ||
                <div className="wrong-number" style={{padding: validationError  ? '10px 0 0 0' : '0 0 10px 0'}}><span onClick={() => this.toggleResend()}>Cancel</span></div>
              }
              <div className="submit-container">
                <button className="submit"><ButtonSpinner loading={this.props.submitting}/>{isFakeNumber ? 'Send' : 'Resend'} Code</button>
                <div className="resend-verification"></div>
              </div>
            </div>
            :
            <div className="enter-code">
              <h3>Code has been {user.smsAttempts > 1 ? 'resent' : 'send'} to {phoneNumber}</h3>
              <FieldGroup required  id='pin' width='large' position='left' hideOptional type='number' placeholder="Enter 6 digit code" {...pinMask}/>
              <div className="wrong-number" style={{padding: validationError  ? '10px 0 0 0' : '0 0 10px 0'}}>Wrong phone number? <span onClick={() => this.toggleResend()}>Click here</span> to update it.</div>
              <div className="submit-container">
                <button className="submit"><ButtonSpinner loading={this.props.submitting}/>Verify Account</button>
                <div className="resend-verification" onClick={this.resendSms}>Resend verification code {user.smsAttempts > 1 && `(${4 - user.smsAttempts} attempt${user.smsAttempts !== 3 ?'s' : ''} left)`}</div>
              </div>
            </div>
          }
        </FormContainer>
      </div>
    )
  }
}

SMSCode = reduxForm({
  form: 'account',
})(SMSCode);

export default connect(mapStateToProps, mapDispatchToProps)(SMSCode);

const getDigitCounts = number => {
  // will return a map of digits and their count in a string
  // e.g. 0400000022 = Map({ '0': 7, '2': 2, '4': 1 })
  const digitCounts = new Map();

  for (const d of number) {
    const count = digitCounts.get(d) || 0;
    digitCounts.set(d, count + 1);
  }

  return digitCounts;
}

const numberIsFake = number => {
 // console.log("#khu " + number);
  // mobile numbers with only one repeated number after the prefix are fake
  // e.g. 04222222222 = fake, 04221221222 = real
  if (number.startsWith('04')) {
    const stripped = number.replace('04', '');
    return getDigitCounts(stripped).size === 1;
  }
  if (number.startsWith('04')) {
    const stripped = number.replace('04', '');
    return getDigitCounts(stripped).size === 1;
  }
  if (number.startsWith('+614')) {
    const stripped = number.replace('+614', '');
    return getDigitCounts(stripped).size === 1;
  }
  const digitCounts = getDigitCounts(number);
  
  // if number only has 1 repeated digit it's probably fake, if it has more than 2 it's OK
  // 4444444444444 = fake, 123123123123 = real
  if (digitCounts.size !== 2) return digitCounts.size < 2;

  // if it has 2 unique digits check if one digit is used only once, if so it's probably fake
  // 030000000000 = fake, 0303030303030 = real,


  return [...digitCounts.values()].some(count => count === 1);
}

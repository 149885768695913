import { createAction } from 'redux-actions';

export const REQUEST_NEW_USER = 'REQUEST_NEW_USER';
export const RECEIVE_NEW_USER = 'RECEIVE_NEW_USER';
export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const REQUEST_PATCH_USER = 'REQUEST_PATCH_USER';
export const RECEIVE_PATCH_USER = 'RECEIVE_PATCH_USER';
export const ADD_TO_SHORTLIST = 'ADD_TO_SHORTLIST';
export const REMOVE_FROM_SHORTLIST = 'REMOVE_FROM_SHORTLIST';
export const RECEIVE_ADD_TO_SHORTLIST = 'RECEIVE_ADD_TO_SHORTLIST';
export const RECEIVE_REMOVE_FROM_SHORTLIST = 'RECEIVE_REMOVE_FROM_SHORTLIST';
export const REQUEST_NEW_APPLICATION = 'REQUEST_NEW_APPLICATION';
export const RECEIVE_NEW_APPLICATION = 'RECEIVE_NEW_APPLICATION';
export const REQUEST_PATCH_APPLICATION = 'REQUEST_PATCH_APPLICATION';
export const RECEIVE_PATCH_APPLICATION = 'RECEIVE_PATCH_APPLICATION';
export const REQUEST_ADD_PRODUCT_TO_APPLICATION = 'REQUEST_ADD_PRODUCT_TO_APPLICATION';
export const RECEIVE_ADD_PRODUCT_TO_APPLICATION = 'RECEIVE_ADD_PRODUCT_TO_APPLICATION';
export const REQUEST_REMOVE_PRODUCT_FROM_APPLICATION = 'REQUEST_REMOVE_PRODUCT_FROM_APPLICATION';
export const RECEIVE_REMOVE_PRODUCT_FROM_APPLICATION = 'RECEIVE_REMOVE_PRODUCT_FROM_APPLICATION';
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_SET_ACCOUNT_ABUSED = 'REQUEST_SET_ACCOUNT_ABUSED';
export const REQUEST_TOKEN_LOGIN = 'REQUEST_TOKEN_LOGIN';


export const REQUEST_FILE_UPLOAD = 'REQUEST_FILE_UPLOAD';
export const REQUEST_PATCH_FILE = 'REQUEST_PATCH_FILE';
export const REQUEST_REMOVE_FILE = 'REQUEST_REMOVE_FILE';
export const RECEIVE_CREATE_FILE = 'RECEIVE_CREATE_FILE';
export const RECEIVE_PATCH_FILE = 'RECEIVE_PATCH_FILE';
export const RECEIVE_REMOVE_FILE = 'RECEIVE_REMOVE_FILE';

export const REQUEST_OAUTH_MERGE = 'REQUEST_OAUTH_MERGE';
export const REQUEST_SECURE_LINK = 'REQUEST_SECURE_LINK';
export const REQUEST_EMAIL_VERIFICATION = 'REQUEST_EMAIL_VERIFICATION';
export const REQUEST_VALIDATION_EMAIL = 'REQUEST_VALIDATION_EMAIL';

export const REQUEST_SELF = 'REQUEST_SELF';
export const RECEIVE_SELF = 'RECEIVE_SELF'; 

export const PATCH_ENDED = 'PATCH_ENDED';

export const REQUEST_CREATE_REFERRAL = 'REQUEST_CREATE_REFERRAL';
export const RECEIVE_CREATE_REFERRAL = 'RECEIVE_CREATE_REFERRAL';

export const ADD_NOTE_TO_REFERRAL = 'ADD_NOTE_TO_REFERRAL';

export const requestSignup = createAction(REQUEST_SIGNUP);
export const requestLogin = createAction(REQUEST_LOGIN);
export const requestAuth = createAction(REQUEST_AUTH);
export const requestLogout = createAction(REQUEST_LOGOUT);
export const receiveLogout = createAction(RECEIVE_LOGOUT);
export const receiveAuth = createAction(RECEIVE_AUTH);
export const requestPatchUser = createAction(REQUEST_PATCH_USER);
export const receivePatchUser = createAction(RECEIVE_PATCH_USER);
export const addToShortlist = createAction(ADD_TO_SHORTLIST);
export const removeFromShortlist = createAction(REMOVE_FROM_SHORTLIST);
export const receiveAddToShortlist = createAction(RECEIVE_ADD_TO_SHORTLIST);
export const receiveRemoveFromShortlist = createAction(RECEIVE_REMOVE_FROM_SHORTLIST);
export const requestNewApplication = createAction(REQUEST_NEW_APPLICATION);
export const receiveNewApplication = createAction(RECEIVE_NEW_APPLICATION);
export const requestPatchApplication = createAction(REQUEST_PATCH_APPLICATION);
export const receivePatchApplication = createAction(RECEIVE_PATCH_APPLICATION);
export const requestAddProductToApplication = createAction(REQUEST_ADD_PRODUCT_TO_APPLICATION);
export const receiveAddProductToApplication = createAction(RECEIVE_ADD_PRODUCT_TO_APPLICATION);
export const requestRemoveProductFromApplication = createAction(REQUEST_REMOVE_PRODUCT_FROM_APPLICATION);
export const receiveRemoveProductFromApplication = createAction(RECEIVE_REMOVE_PRODUCT_FROM_APPLICATION);
export const requestForgotPassword = createAction(REQUEST_FORGOT_PASSWORD);
export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET);
export const requestSetAccountAbused = createAction(REQUEST_SET_ACCOUNT_ABUSED);
export const requestTokenLogin = createAction(REQUEST_TOKEN_LOGIN);

export const requestFileUpload = createAction(REQUEST_FILE_UPLOAD);
export const requestPatchFile = createAction(REQUEST_PATCH_FILE);
export const requestRemoveFile = createAction(REQUEST_REMOVE_FILE);
export const receiveCreateFile = createAction(RECEIVE_CREATE_FILE);
export const receivePatchFile = createAction(RECEIVE_PATCH_FILE);
export const receiveRemoveFile = createAction(RECEIVE_REMOVE_FILE);

export const requestOauthMerge = createAction(REQUEST_OAUTH_MERGE);
export const requestSecureLink = createAction(REQUEST_SECURE_LINK);
export const requestEmailVerification = createAction(REQUEST_EMAIL_VERIFICATION);
export const requestValidationEmail = createAction(REQUEST_VALIDATION_EMAIL);

export const requestSelf = createAction(REQUEST_SELF);
export const receiveSelf = createAction(RECEIVE_SELF);

export const patchEnded = createAction(PATCH_ENDED);

export const requestCreateReferral = createAction(REQUEST_CREATE_REFERRAL);
export const receiveCreateReferral = createAction(RECEIVE_CREATE_REFERRAL);

export const addNoteToReferral = createAction(ADD_NOTE_TO_REFERRAL);
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import { requestPatchUser } from 'modules/user/actions';
import { FlexColumn, FlexRow, SmallHeading, Text, Footnote, Container as SectionContainerBase, Heading } from './Shared';
import formatNumber from 'utils/formatNumber';
import SectionButton from "../../../component/SectionButton";

export const Aggregates = ({ loans = [], active, editMode }) => {

  const { overview, user } = useSelector(state => ({
    overview: ((state.user.meta.miniproposal || {}).overview || {}).lendingOverview || {},
    user: state.user,
  }));

  const selectValue = (key, event) => {
    const { value } = event.target;
    patchProposal({ [key]: value });
  }

  const dispatch = useDispatch(); 

  const patchUser = payload => dispatch(requestPatchUser(payload));

  const patchProposal = (data, callback) => {

    const toSave = {
      overview: {
        lendingOverview: data
      }
    };
  
    patchUser({ 
      userId: user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  };

  const aggregateInterestFrequency = overview.aggregateInterestFrequency || 'year';
  const aggregateRepaymentsFrequency = overview.aggregateRepaymentsFrequency || 'month';

  const releventInterestTerm = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? Infinity : l.newLoan.releventInterestTerm)
    .reduce((a, b) => Math.min(a, b), Infinity);

    const totalXYearInterestSavings = loans
    .map(l => l.newLoan.yearXInterestSaving(releventInterestTerm) || 0)
    .reduce((a, b) => a + b, 0);

  const repaymentReduction = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : 
      (l.currentLoan.monthlyRepayment || 0) - (l.newLoan.monthlyRepayments || 0))
    .reduce((a, b) => a + b, 0);

  const totalYearOneInterestSavings = loans
    .map(l => l.newLoan.yearOneInterestSaving || 0)
    .reduce((a, b) => a + b, 0);

  const oldYearOneInterest = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.oldYearOneInterest || 0)
    .reduce((a, b) => a + b, 0);

  const yearOneInterest = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.yearOneInterest || 0)
    .reduce((a, b) => a + b, 0);
  
  const totalMonthlyRepayments = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.newLoan.monthlyRepayments || 0)
    .reduce((a, b) => a + b, 0);

  const oldTotalMonthlyRepayments = loans
    .map(l => l.currentLoan.newLoanPurpose !== 'refinance' ? 0 : l.currentLoan.monthlyRepayment || 0)
    .reduce((a, b) => a + b, 0);

  const trailRebates = loans
    .map(x => x.newLoan.trailRebate)
    .filter(x => !!x);

  let interestPeriodMultiplier;
  switch (aggregateInterestFrequency) {
    case 'year': interestPeriodMultiplier = 1;
    break;
    case 'month': interestPeriodMultiplier = 1/12;
    break;
    case 'fortnight': interestPeriodMultiplier = 1/26.0714;
    break;
    case 'week': interestPeriodMultiplier = 1/52.1428;
    break;
  }

  let repaymentPeriodMultiplier;
  switch (aggregateRepaymentsFrequency) {
    case 'year': repaymentPeriodMultiplier = 12;
    break;
    case 'month': repaymentPeriodMultiplier = 1;
    break;
    case 'fortnight': repaymentPeriodMultiplier = 12/26.0714;
    break;
    case 'week': repaymentPeriodMultiplier = 12/52.1428;
    break;
  }

  const oldAggregateInterest = oldYearOneInterest * interestPeriodMultiplier;
  const newAggregateInterest = yearOneInterest * interestPeriodMultiplier;

  const oldAggregateRepayments = oldTotalMonthlyRepayments * repaymentPeriodMultiplier;
  const newAggregateRepayments = totalMonthlyRepayments * repaymentPeriodMultiplier;
  const showInterestSavings = (oldAggregateInterest > newAggregateInterest && totalXYearInterestSavings > 0) || overview.forceRefinanceComparison;
  const showRepaymentSavings = (oldAggregateRepayments > newAggregateRepayments) || overview.forceRefinanceComparison;

  if (!showInterestSavings && !showRepaymentSavings) return null;

  return (
    <SectionContainer active={active}>
      <Seperator/>
      <Heading>Refinance Comparison</Heading>
      <Text>
        The table below outlines savings when refinancing your current loans to the recommended solution.
        These figures are based only on refinanced loans and exclude any new lending such as cashouts or purchases.
      </Text>
      <MainFlexRow>
        {showInterestSavings &&
          <FlexColumnAggregatesContainer style={{ order: 10}}>
            <ChartHeading>Aggregate <NewLine/>Interest Payable</ChartHeading>
            <FlexChart>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Current
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(oldAggregateInterest / newAggregateInterest) * 100}>
                        <VisibilityCheck>
                          {visible => <ColumnForground color='#727C8F' animate={!!visible}/> }
                        </VisibilityCheck>
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#727C8F'>
                    <Text size='22px' weight='bold' marginBottom='5px'>
                      {formatMoney(oldAggregateInterest)}
                    </Text>
                    <Text size='16px' weight='600'>
                      per {aggregateInterestFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Proposed*
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(newAggregateInterest / oldAggregateInterest) * 100}>
                        <VisibilityCheck>
                          {visible => <ColumnForground  color='#2291FF' animate={!!visible}/> }
                        </VisibilityCheck>
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#2291FF'>
                    <Text size='22px' weight='bold' marginBottom='5px'>
                      {formatMoney(newAggregateInterest)}
                    </Text>
                    <Text size='16px' weight='600'>
                      per {aggregateInterestFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
            </FlexChart>
            <RepaymentsContainer>
              <Text size='12px'>
                Show interest
                <SelectBox onChange={value => selectValue('aggregateInterestFrequency', value)} value={aggregateInterestFrequency}>
                  <option value='year'>Annually</option>
                  <option value='month'>Monthly</option>
                  <option value='fortnight'>Fortnightly</option>
                  <option value='week'>Weekly</option>
                </SelectBox>
              </Text>
            </RepaymentsContainer>
            
            <FlexRow margin='20px 0 0 0'>
              <Footnote>
                {!!trailRebates.length ? 
                  '*Based on the first year you refinance and factors in the annual trail rebate.' 
                  :
                  '*Based on the first year you refinance.'
                }
              </Footnote> 
            </FlexRow>
            
          </FlexColumnAggregatesContainer>
        }

        {showInterestSavings &&
          <FlexColumnAggregatesContainer type="mobile" style={{ order: 20}}>
            <SmallHeading>Interest Saved Year 1</SmallHeading>
            <BigBlueText>{formatMoney(totalYearOneInterestSavings)}</BigBlueText>
            {(releventInterestTerm > 1) &&
              <>
                <div style={{height: '25px'}}/>
                <SmallHeading>Interest Saved Over {releventInterestTerm} Years</SmallHeading>
                <BigBlueText>{formatMoney(totalXYearInterestSavings)}</BigBlueText>
              </>
            }
          </FlexColumnAggregatesContainer>
        }
        

        <FlexColumnAggregatesContainer type="info" style={{ order: 30}}>
          {showInterestSavings &&
          <>
            <FigureHeading>Interest Saved Year 1</FigureHeading>
              <BigBlueText>{formatMoney(totalYearOneInterestSavings)}</BigBlueText>
              
              {(releventInterestTerm > 1) &&
                <>
                  <div style={{height: '25px'}}/>
                  <FigureHeading>Interest Saved Over {releventInterestTerm} Years</FigureHeading>
                  <BigBlueText>{formatMoney(totalXYearInterestSavings)}</BigBlueText>
                </>
              }
              <div style={{height: '25px'}}/>
            </>
          }
          {showRepaymentSavings &&
            <>
              <FigureHeading>Repayment Reduction</FigureHeading>
              <BigBlueText>{formatMoney(repaymentReduction)} per month</BigBlueText>
              <div style={{height: '25px'}}/>
              <FigureHeading>Repayment Saved Year 1</FigureHeading>
              <BigBlueText>{formatMoney(repaymentReduction * 12)}</BigBlueText>
            </>
          }
        </FlexColumnAggregatesContainer>

        
        {showRepaymentSavings &&

          <FlexColumnAggregatesContainer style={{ order: 20 }}>

            <ChartHeading>Aggregate <NewLine/>Repayments</ChartHeading>
            <FlexChart>
              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Current
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(oldAggregateRepayments / newAggregateRepayments) * 100}>
                        <VisibilityCheck>
                          {visible => <ColumnForground color='#727C8F' animate={!!visible}/> }
                        </VisibilityCheck>
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#727C8F'>
                    <Text size='22px' weight='bold' marginBottom='5px'>
                      {formatMoney(oldAggregateRepayments)}
                    </Text>
                    <Text size='16px' weight='600'>
                      per {aggregateRepaymentsFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>

              <FlexChartColumns>
                <ChartColumn>
                  <RepaymentColumnTitle>
                    Proposed
                  </RepaymentColumnTitle>
                  <ColumnContainer>
                    <ColumnBackground>
                      <ForgroundContainer heightPercent={(newAggregateRepayments / oldAggregateRepayments) * 100}>
                        <VisibilityCheck>
                          {visible => <ColumnForground  color='#2291FF' animate={!!visible}/> }
                        </VisibilityCheck>
                      </ForgroundContainer>
                    </ColumnBackground>
                  </ColumnContainer>
                  <Container fontColor='#2291FF'>
                    <Text size='22px' weight='bold' marginBottom='5px'>
                      {formatMoney(newAggregateRepayments)}
                    </Text>
                    <Text size='16px' weight='600'>
                      per {aggregateRepaymentsFrequency}
                    </Text>
                  </Container>
                </ChartColumn>
              </FlexChartColumns>
            </FlexChart>
            <RepaymentsContainer>
              <Text size='12px' style={{textAlign: 'center'}}>
                Show repayments
                <SelectBox onChange={value => selectValue('aggregateRepaymentsFrequency', value)} value={aggregateRepaymentsFrequency}>
                  <option value='year'>Annually</option>
                  <option value='month'>Monthly</option>
                  <option value='fortnight'>Fortnightly</option>
                  <option value='week'>Weekly</option>
                </SelectBox>
              </Text>
            </RepaymentsContainer>
          </FlexColumnAggregatesContainer>
        }

        {showRepaymentSavings &&
          <FlexColumnAggregatesContainer type="mobile" style={{ order: 40}}>
            <SmallHeading>Repayment Reduction</SmallHeading>
            <BigBlueText>{formatMoney(repaymentReduction)} per month</BigBlueText>
            <div style={{height: '25px'}}/>
            <SmallHeading>Repayment Saved Year 1</SmallHeading>
            <BigBlueText>{formatMoney(repaymentReduction * 12)}</BigBlueText>
          </FlexColumnAggregatesContainer>
        }

        {showInterestSavings ? "" :
        <FlexColumnAggregatesContainer style={{ margin: '0', order: 90}}></FlexColumnAggregatesContainer>}

      </MainFlexRow>
    </SectionContainer>
  );
} 

const formatMoney = n => {
  return (n < 0) ? `-$${formatNumber(-n)}` : `$${formatNumber(n)}`
}

const VisibilityCheck = ({ children }) => {
  
  const [active, setActive] = useState(false);
  
  const callback = useCallback(isVisible => {
    if (isVisible && !active) setActive(true);
  }, [active]);
  
  return (
    <VisibilitySensor onChange={callback}>
      {children(active)}
    </VisibilitySensor>
  )
}

const NewLine = styled.div`
  display: inline;

  @media (max-width: 1120px) {
    display: block;
  }
  @media (max-width: 780px) {
    display: inline;
  }
  @media (max-width: 580px) {
    display: block;
  }
  @media (max-width: 480px) {
    display: inline;
  }
`;

const FigureHeading = styled(SmallHeading)`
  font-size: 16px !important;
`;

const ChartHeading = styled(SmallHeading)`
  
  @media (min-width: 779px) {
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content;
    text-align: center;
  }
`;

const SectionContainer = styled(SectionContainerBase)`
  @media (max-width: 780px) {
      margin-bottom: 0px;
  }
`;

const Seperator = styled.div`
    background-color: #B3BECC;
    height: 1px;
    width: 100%; 
    margin-bottom: 60px;

    @media (max-width: 645px) {
      display: none;
    }
`;

const FlexChart = styled(FlexRow)`
  justify-content: space-between;
  margin: 20px 0 20px 0;

  @media (max-width: 480px) {
    justify-content: start;
  }
`;

const FlexChartColumns = styled(FlexColumn)`
  width: calc(50% - 20px);

  @media (max-width: 480px) {
    width: 200px !important;
  }
`;


const ChartColumn = styled.div`
  @media (max-width: 480px) {
    width: 90px !important;
    margin-right: 90px;
  }
  
`;

const MainFlexRow = styled(FlexRow)`
  justify-content: space-between;

  @media (max-width: 780px) {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }

  @media (max-width: 480px) {
    margin: 30px 0 30px 0;
  }
`;

const RepaymentsContainer = styled(FlexRow)`
  
  justify-content: center;
  margin: 20px 0 0 0;

  @media (max-width: 480px) {
    width: 320px;
  }
`;

const FlexColumnAggregatesContainer = styled(FlexColumn)`
  width: calc(33.33% - 40px);

  @media (max-width: 780px) {
    width: calc(50% - 20px);
    margin-bottom: 50px;
  }

  @media (max-width: 480px) {
    width: 100%;
    /* text-align: center; */
    margin-bottom: 20px;
  }

  &::empty {
    margin-bottom: 0;
  }

  ${p => p.type == "info" ? css`
    @media (max-width: 780px) {
      display: none;
    }
  ` : ''}

  ${p => p.type == "mobile" ? css`
    display: none;

    @media (max-width: 780px) {
      display: block;
    }
  ` : ''}
`;

const Container = styled.div`
  text-align: center;
  ${p => p.fontColor && css`color: ${p.fontColor};`}
  padding: ${p => p.padding || '0'};
  margin: ${p => p.margin || '0'};
`;

const BigBlueText = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #308AE9;
  margin-bottom: 15px;
`;

const RepaymentColumnTitle = styled.div`
  color: #575E6C;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
`;

const ColumnContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const ColumnBackground = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 40px;
  height: 188px;
  background-color: #E9EBF1;
  border-radius: 15px;
  overflow: hidden;
`;

const ForgroundContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: ${p => p.heightPercent}%;
`;

const ColumnForground = styled.div`
  background-color: ${p => p.color};
  width: 100%;
  height: 1%;
  border-radius: 15px;
  ${p => p.animate && css`
    animation: growColumn 1s ease-in-out 0s 1;
    animation-fill-mode: forwards;
  `}
  
  @keyframes growColumn {
      from { height: 1%; }
      to { height: 100%; }
  }
`;

const SelectBox = styled.select`
    margin-left: 5px;
    
    appearance: none;
    border: none;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    width: 100px;
    background-image: url(${require('img/button/down-blue.png')});
    background-size: 10px 6px;
    background-position: Calc(100% - 15px);
    background-repeat: no-repeat;
    background-color: transparent;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    border-radius: 4px;
    border: 1px solid rgba(233, 235, 241, 0.0);
    
    &:hover {
      margin-left: 5px;
      width: 110px;
      appearance: none;
      height: 30px;
      border: 1px solid #D8DCE7;
      padding-left: 15px;
      font-size: 12px;
      font-weight: 400;
      background-image: url(${require('img/button/down-blue.png')});
      background-size: 10px 6px;
      background-position: Calc(100% - 15px);
      background-repeat: no-repeat;
      background-color: transparent;
      border-radius: 4px;

      &:disabled {
        color: #D8DCE7;
      }

      &:focus {
        box-shadow: 0 0 10px rgba(34, 145, 255, 0.15);
        border: 1px solid #2291FF;
        border-radius: 4px;
      }
    }

`;


import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, FieldArray } from 'redux-form';
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';

import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, Heading3, Heading4, Button, UnsavedChangesPrompt, timePeriod } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import FormNavigation from "../Components/FormNavigation";

import { createNotZero } from 'Components/Forms/validation';
import { FadeIn } from './Components/MultiStageForm';

const notZero = createNotZero();

function mapStateToProps(state) {
  return {
    user: state.user.meta,
    userId: state.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class SimpleForm extends Component {

  updateOtherLiabilities(type, person){
  }

  render() {
    return (
    <FormContainer onSubmit={this.props.handleSubmit}>
      <h1>Your Liabilities</h1>
      {/* {"#" + this.props.user.individual_otherLiabilities_Count} */}
      <p className='under-heading'>Please Provide aggregate totals for each type of liability if applicable.</p>
      
      <SelectGroup required id="user_meta-haveCredit" width="small" position="left" title="Do you have a credit card?" type="select" values={['Yes', 'No']} />
      <div/>
      {(this.props.haveCredit === "Yes") &&
        <FadeIn>
          <FieldGroup id='user_meta-creditCardLimit' width='small' position='left' title='What is your total credit card limit?' required='Yes' type='text' placeholder='0' mask='monetary'/>
        </FadeIn>
      }

      {!!(this.props.haveCredit === 'No' || this.props.creditCardLimit) &&
        <FadeIn>
          <SelectGroup required id="user_meta-haveHECS" width="small" position="left" title="Do you have a HECS-HELP debt?" type="select" values={['Yes', 'No']} fill="fill" />
        </FadeIn>
      }

      {!!this.props.haveHECS &&
        <FadeIn>
          <SelectGroup required id="user_meta-havePersonalLoan" width="small" position="left" title="Do you have a personal loan?" type="select" values={['Yes', 'No']} fill="fill" />
          <div/>
          {(this.props.havePersonalLoan === "Yes") &&
            <FadeIn>
              <FieldGroup required id='user_meta-personalLoanBalance' width='small' position='left' title='What is your total personal loan balance?' required='Yes' type='text' mask='monetary' placeholder='0' />
            </FadeIn>
          }
          <div/>
          {!!this.props.personalLoanBalance &&
            <FadeIn>
              <DoubleFieldGroup required id='user_meta-personalLoanBalanceRepayment' width='small' position='left' title='Personal Loan Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
            </FadeIn>
          }
        </FadeIn>
      }

      {!!(this.props.havePersonalLoan === "No" || this.props.personalLoanBalanceRepayment) && 
        <FadeIn>
          <SelectGroup required id="user_meta-haveCarLoan" width="small" position="left" type="select" title="Do you have a car loan?" values={['Yes', 'No']} fill="fill" />
          <div/>
          {(this.props.haveCarLoan == "Yes") &&
            <FadeIn>
              <FieldGroup required id='user_meta-carLoan' width='small' position='left' title='What is your total car loan balance?' required='Yes' type='text' mask='monetary' placeholder='0' />
            </FadeIn>
          }
          <div/>
          {!!this.props.carLoan &&
            <FadeIn>
              <DoubleFieldGroup required id='user_meta-carLoanRepayment' width='small' position='left' title='Car Loan Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
            </FadeIn>
          }
        </FadeIn>
      }


      {!!(this.props.haveCarLoan == "No" || this.props.carLoanRepayment) && 
        <FadeIn>
          <FieldArray name='user_meta-otherLiabilities' component={OtherLiabilities} />
          <div className='extra-margin'/>
          <div className='extra-margin'/>
          {togglePartner(this.props.applicantType, this)}
          {this.props.applicantType !== "youAndYourPartner" && 
            <FormNavigation currentPage="5" disabled={this.props.invalid} submitting={this.props.submitting}/>
          }
        </FadeIn>
      }

      <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting) }/>
      <div style={{width: '100%', height: '200px'}}/>
    </FormContainer>
  );
}
}

function togglePartner(applicantType, a){
  if (applicantType === "youAndYourPartner") {
    return (
      <Fragment>
        {/* <Heading3 width='large' position='left' title='Your Partners Liabilities' content='Please provide aggregate totals for each type of liability, if applicable. ' /> */}
        <h1>Your Partner's Liabilities</h1>
        {/* {"#" + this.props.user.individual_otherLiabilities_Count} */}
        <p className='under-heading'>Please Provide aggregate totals for each type of liability if applicable.</p>

        <SelectGroup required id="user_partner_meta-haveCredit" width="small" position="left" type="select" title="Does your partner have a credit card?" values={['Yes', 'No']} />
        <div/>
        {(a.props.partnerHaveCredit === "Yes") &&
          <FadeIn>
            <FieldGroup id='user_partner_meta-creditCardLimit' width='small' position='left' title="What is your partner's total credit card limit?" required='Yes' type='text' placeholder='0' mask='monetary'/>
          </FadeIn>
        }

        {!!(a.props.partnerHaveCredit === 'No' || a.props.partnerCreditCardLimit) &&
          <FadeIn>
            <SelectGroup required id="user_partner_meta-haveHECS" width="small" position="left" type="select" title="Does your partner have a HECS-HELP debt?" values={['Yes', 'No']} fill="fill" />
          </FadeIn>
        }

        {!!a.props.partnerHaveHECS &&
          <FadeIn>
            <SelectGroup required id="user_partner_meta-havePersonalLoan" width="small" position="left" type="select" title="Does your partner have a personal loan?" values={['Yes', 'No']} fill="fill" />
            <div/>
            {(a.props.partnerHavePersonalLoan === "Yes") &&
              <FadeIn>
                <FieldGroup required id='user_partner_meta-personalLoanBalance' width='small' position='left' title="What is your partner's total personal loan balance?" required='Yes' type='text' mask='monetary' placeholder='0' />
              </FadeIn>
            }
            <div/>
            {!!a.props.partnerPersonalLoanBalance &&
              <FadeIn>
                <DoubleFieldGroup required id='user_partner_meta-personalLoanBalanceRepayment' width='small' position='left' title='Personal Loan Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
              </FadeIn>
            }
          </FadeIn>
        }

        {!!(a.props.partnerHavePersonalLoan === "No" || a.props.partnerPersonalLoanBalanceRepayment) && 
          <FadeIn>
            <SelectGroup required id="user_partner_meta-haveCarLoan" width="small" position="left" type="select" title="Does your partner have a car loan?" values={['Yes', 'No']} fill="fill" />
            <div/>
            {(a.props.partnerHaveCarLoan == "Yes") &&
              <FadeIn>
                <FieldGroup required id='user_partner_meta-carLoan' width='small' position='left' title="What is your partner's total car loan balance?" required='Yes' type='text' mask='monetary' placeholder='0' />
              </FadeIn>
            }
            <div/>
            {!!a.props.partnerCarLoan &&
              <FadeIn>
                <DoubleFieldGroup required id='user_partner_meta-carLoanRepayment' width='small' position='left' title='Car Loan Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
              </FadeIn>
            }
          </FadeIn>
        }
        
        {!!(a.props.partnerHaveCarLoan == "No" || a.props.partnerCarLoanRepayment) && 
          <FadeIn>
            <FieldArray name='user_partner_meta-otherLiabilities' component={OtherLiabilities} props={{isPartner: true}}/>
            <div className='extra-margin'/>
            <div className='extra-margin'/>

            <FormNavigation currentPage="5" disabled={a.props.invalid} submitting={a.props.submitting}/>
            
          </FadeIn>
        }
      </Fragment>
    );
  } else {
    return "";
  }
}

const initialLiability = {
  repaymentType: timePeriod.MONTH,
}

const OtherLiabilities = ({ fields, isPartner }) => (
  <Fragment>
    {!fields.length &&
      <Heading4 width='large' position='left' title='Other Liabilities' content={`If ${isPartner ? 'your partner has' : 'you have' } any other liabilities not covered above, please add it by clicking the button below.`}/>
    }
    {fields.map((item, index) => (
      <div key={index} className='field-array'>
        <Heading4 width='large' position='left' title={'Other Liability ' + (index + 1)} />
        <button type='button' className="delete top-right" onClick={() => fields.remove(index)}></button>
        <FieldGroup required id={`${item}.liability`} width='large' position='left' title='Liability' type='text' placeholder='E.g. Other Asset Finance' />
        <FieldGroup required id={`${item}.amount`} width='small' position='left' title='Liability Amount' type='text' placeholder='0' mask='monetary' validate={notZero}/>
        <DoubleFieldGroup required id={`${item}.repayment`} width='small' position='right' title='Repayment' type='monetary-time' mask='monetary' placeholder='0' validate={notZero} />
      </div>
    ))}
    <Button onClick={() => fields.push(initialLiability)} width='small' position='left' title='Add Other Liability' />
  </Fragment>
)


SimpleForm = reduxForm({
  form: 'eligibility-liabilities',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-liabilities') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // external values
    const applicantType = state.user.applications[0].meta.applicantType;
    return {
      applicantType,
      initialValues: {
        'user_meta-personalLoanBalanceRepaymentType': timePeriod.MONTH,
        'user_meta-carLoanRepaymentType': timePeriod.MONTH,
        'user_partner_meta-personalLoanBalanceRepaymentType': timePeriod.MONTH,
        'user_partner_meta-carLoanRepaymentType': timePeriod.MONTH,
        ...getInitialValuesObject(state), 
        "user_meta-eligibility_complete_page5": "1", 
        "user_meta-eligibility_last_page": 5
      },

      haveCredit: selector(state, 'user_meta-haveCredit'),
      creditCardLimit: selector(state, 'user_meta-creditCardLimit'),
      haveHECS: selector(state, 'user_meta-haveHECS'),
      havePersonalLoan: selector(state, 'user_meta-havePersonalLoan'),
      personalLoanBalance: selector(state, 'user_meta-personalLoanBalance'),
      personalLoanBalanceRepayment: selector(state, 'user_meta-personalLoanBalanceRepayment'),
      haveCarLoan: selector(state, 'user_meta-haveCarLoan'),
      carLoan: selector(state, 'user_meta-carLoan'),
      carLoanRepayment: selector(state, 'user_meta-carLoanRepayment'),

      partnerHaveCredit: selector(state, 'user_partner_meta-haveCredit'),
      partnerCreditCardLimit: selector(state, 'user_partner_meta-creditCardLimit'),
      partnerHaveHECS: selector(state, 'user_partner_meta-haveHECS'),
      partnerHavePersonalLoan: selector(state, 'user_partner_meta-havePersonalLoan'),
      partnerPersonalLoanBalance: selector(state, 'user_partner_meta-personalLoanBalance'),
      partnerPersonalLoanBalanceRepayment: selector(state, 'user_partner_meta-personalLoanBalanceRepayment'),
      partnerHaveCarLoan: selector(state, 'user_partner_meta-haveCarLoan'),
      partnerCarLoan: selector(state, 'user_partner_meta-carLoan'),
      partnerCarLoanRepayment: selector(state, 'user_partner_meta-carLoanRepayment'),
    }
  }
)(SimpleForm);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleForm);

// verification is required for all mobile numbers and any very obvious fakes
 const isVerificationRequired = phoneNumber => {
  // verify all mobile numbers
  if (phoneNumber.startsWith('04') ||
    phoneNumber.startsWith('4') ||
    phoneNumber.startsWith('+614')||
    phoneNumber.startsWith('00614') 
    //||phoneNumber.startsWith('+92')
  )
    return true;
  
  const digitCounts = new Map();

  for (const d of phoneNumber) {
    const count = digitCounts.get(d) || 0;
    digitCounts.set(d, count + 1);
  }

  // if number only has 1 repeated digit it's probably fake, if it has more than 2 it's OK
  // 4444444444444 = true, 123123123123 = false
  if (digitCounts.size !== 2) return digitCounts.size < 2;

  // if it has 2 unique digits check if one digit is used only once, if so it's probably fake
  // 030000000000 = true, 0303030303030 = false
  return [...digitCounts.values()].some(count => count === 1);
}

export default isVerificationRequired;
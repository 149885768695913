import React, { Component } from 'react';

const $ = window.$;

class RateField extends Component {
  
  state = {inputValue: ""};

  onKeyPress = n =>
    e => {
      let blockString = '-/:;()$&@“".,?!’[]{}#%^*+=_\|~<>£€¥•\''; // long filter for iOS
      if (blockString.indexOf(e.key) !== -1) {
        e.preventDefault();
      }
    }

  handleChange = e => {
    let value = e.currentTarget.value.replace(/[^0-9]/g,'').slice(0, (this.props.digits || 3));
    this.setState({inputValue: value});

    if (this.props.digits > 2) 
      value = value.slice(0, 1) + "." + value.slice(1); // add decimal point
    else {
      value = '.' + value;
    }
    value = parseFloat(value);

    this.props.onChange(value);
  }

  onBlur = e => {
    let value = this.state.inputValue;
    if (!value) {
      return;
    }
    while (value.length < (this.props.digits || 3)) {
      value += '0';
    }
    this.setState({inputValue: value});
  }

  render() {

    const value = this.state.inputValue.replace(/[^0-9]/g,'') || (this.props.value ? (Math.round(this.props.value * 100) || 0)
      .toString()
      .padStart((this.props.digits || 3), '0') : '');

    const placeholder = new Array((this.props.digits || 3) + 1).join('0');
    const maxNumber = parseInt(new Array((this.props.digits || 3) + 1).join('9'));
    
    return (
      <div className={'interest-rate-field ' + (value ? '' : 'empty')}>
        <div className={'digits n' + (this.props.digits || 3)}>
          <input data-lpignore="true" type="text" value={value} placeholder={placeholder} max={maxNumber} maxLength={(this.props.digits || 3)} onKeyDown={this.onKeyPress(0)} onChange={e=>this.handleChange(e)} onBlur={e=>{this.onBlur(e)}} inputMode="numeric" />
          <div className='decimal-point'>.</div>
        </div>
        <span className='percentage-sign'>%</span>
      </div>
    )
  }
}

export default RateField;
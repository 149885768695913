import React, { Component, Fragment } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';

import { ErrorMessage } from '.';
import imgPin from 'img/icons/google-pin.png';

export default class AddressInput extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isGoogleApproved: false,
    };
  }

  componentDidMount() {
    const { placeId } = this.props.input.value;
    if (placeId) 
      geocodeByPlaceId(placeId)
        .then(() => this.setState({ isGoogleApproved: true }));
  }

  handleChange = address => {
    this.setState({ isGoogleApproved: false });
    this.props.input.onChange && this.props.input.onChange({ address, placeId: null });
  };

  handleSelect = (address, placeId) => {
    if (placeId)
      this.setState({isGoogleApproved: true});
    this.props.input.onChange && this.props.input.onChange({ address, placeId });
  };

  render() {
    const { width, meta: {touched, error}, hideVerified } = this.props;
    return (
      <PlacesAutocomplete
        value={this.props.input.value.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{position: 'relative', width}} className='address-input'>
            {this.state.isGoogleApproved && !hideVerified && <Fragment><VerifiedText>Google Verified</VerifiedText><ImgGooglePin/></Fragment>}
            <input
              className={touched && error && 'error'}
              type='address'
              style={suggestions.length > 0 ? {borderBottomRightRadius: '0', borderBottomLeftRadius: '0'} : {}} 
              value={this.props.input.value.address}
              {...getInputProps({
                ...this.props,
              })}/>
            {suggestions.length > 0 && 
              <OptionContainer style={{width, minWidth: width}}>
                {suggestions.map((suggestion, index) => (
                  <SelectOption key={index} {...getSuggestionItemProps(suggestion, {
                    mainText: suggestion.formattedSuggestion.mainText,
                    secondaryText: suggestion.formattedSuggestion.secondaryText,
                    isHighlighted: suggestion.active,
                    width,
                  })}/>
                ))}
              </OptionContainer>
            }
            {touched && error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

const SelectOption = ({ mainText, secondaryText, isHighlighted, ...props }) => (
  <Option 
    {...props}
    style={isHighlighted ? { background: '#2291FF', color: 'white' } : {}}>
    <MainText>{mainText}</MainText>
    <SecondaryText>{secondaryText}</SecondaryText>
  </Option>
);

const ImgGooglePin = props => <img className='google-pin' width={10} height={15} src={imgPin} {...props}/>
const VerifiedText = props => <div className='verified' {...props}/>;
const OptionContainer  = props => <div className='options-container' {...props}/>;
const Option = ({width, ...props}) => <div className='option' style={{width}} {...props}/>;
const MainText = props => <span className='text-main' {...props}/>;
const SecondaryText = props => <span className='text-secondary' {...props}/>;


import React from "react"; import { Component } from "react";
import { withRouter } from 'react-router-dom'

import getQueryParams from "utils/getQueryParams";

class SignUpFinish extends Component {

  goToForwardAddress = ()=>{
    this.props.closeModal();
    let values = getQueryParams();
    let redirect = values.redirect;
    this.props.history.push("/" + redirect);
  }

  render() {
    if (this.props.currentModal === "signUpFinish") {
    return (
      <div className="modal-content">
        <img src={require('../../../img/modal/finish.png')} alt="finish"/>
        <h1>It’s done.</h1>
        <p>Hooray! Let's go explore some loans.</p>
        <div className="modal-navigation">
          <button onClick={()=>this.goToForwardAddress()} className="single left button-1">Roger!</button>
        </div>

        <div className="request-assistance">
          <div className="request-assistance-content">
            <h4>Want us to do the heavy lifting?</h4>
            <p>We're always here and happy to help <br/>you select a product, and apply for a loan.</p>
          </div>
          <button onClick={()=>this.props.openModal("checkEligibilityHelp")}>Request Assistance</button>
        </div>

      </div>
    ) } else {
      return "";
    }
  }
}

export default withRouter(SignUpFinish);
import React from "react"; import { Component } from "react";

import { MediumProductBox } from "Components/Product"
// import shortListFilterFunction from "utils/shortListFilterFunction"

export default class AdditionalLoanProducts extends Component {
  
  render() {
    
    if (this.props.currentModal === "additionalLoanProducts") {
      const { product } = this.props;
      if (this.props.products.length == 1) this.props.closeModal();
      return (
        <div className="modal-content">
          <div className="heading">
            <h1>Additional Loan Products</h1>
          </div>

          <div className={"product-list" + ((this.props.products.length <= 3)?" no-scroll":"")}>
            <div className="scroll-container" style={{width: (this.props.products.length * 290)}}>
              {this.props.products.slice(1).map(item => {
                  return <MediumProductBox key={item.id} product={item} disableDelete />
              })}
            </div>
          </div>

        </div>
      ) } else {
        return "";
    }
  }
}

import React from "react";
import { Field } from 'redux-form';

class RadioBox extends React.Component {
  render() {
    return(
      <label className={"box w205 " + this.props.class + " " + toggleState(this.props.select, this.props.value) + " " + this.props.value}>
        <Field name={this.props.name} onClick={this.props.onChange} component="input" type="radio" value={this.props.value} />
        {' '}
        <span className="title">{this.props.text}</span>
      </label>
  );
}
}

export default RadioBox;

function toggleState(value1, value2) {
  if (value1 === value2){
    return "enabled";
  }
  else {
    return "";
  }
}

import React from "react"; import { Component } from "react";
import calculateLoanValues from "utils/calculateLoanValues";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { NavLink } from "react-router-dom";

import LinkButton from "Components/LinkButton";
import { closeModal } from 'modules/modals/actions';
import { openModal } from 'modules/modals/actions';
import RequestUpdate from "Components/Modals/RequestUpdate";
import { emailService } from 'modules';
import Tooltips from "Components/Tooltips";
import BankLogo from 'Components/Global/BankLogo';


function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  const desiredLoanAmount = (state.user.applications[0] || {}).loanAmount || 0;
  return {
    desiredLoanAmount,
    products,
    application,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}

class LoanApplicationsBox extends Component {

  requestUpdate(data) {
    this.props.openModal('requestUpdate');
    data.product_name = this.props.product.product_name;
    data.type = 'request-update';
    emailService.create(data);
  }

  render() {
    let product = calculateLoanValues(this.props.product);
    let user = this.props.user;

    return (
      <tr className="applications-row">
        <td className="loan-details">
          <BankLogo product={product} />
          <h6>{product.package}</h6>
          <h6 className="value">
            $
            {this.props.desiredLoanAmount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            Loan
          </h6>

          <div className="interest-rate">
            <div className="variable-rate">
              <div className="rate">
                {product.iir}
                <span> % p.a.</span>
              </div>
              <div className="description">{product.rateDescriptionYears}</div>
            </div>
            <div className="comparison-rate">
              <div className="rate">
                {product.comparisonRate}
                <span> % p.a.</span>
              </div>
              <div className="description">Comparison</div>
            </div>
          </div>
          <div className="principal-interest">
            <h5>
              {this.props.principle
                ? "Principal and interest"
                : "Interest only"}
            </h5>
            <div className="description">Repayment Type</div>
          </div>
          <div className="refinance-investment">
            <div className="refinance-value">
              <h5>{user.loanPurpose}</h5>
              <div className="description">Loan Purpose</div>
            </div>
            <div className="investment-value">
              <h5>{this.props.purpose ? "live-in" : "investment"}</h5>
              <div className="description">Loan Type</div>
            </div>
          </div>
          {this.props.products.length == 1 ? (
            ""
          ) : (
            <div className="more-products">
              <span
                onClick={() => this.props.openModal("additionalLoanProducts")}
              >
                +{this.props.products.length - 1} More Products
                <Tooltips
                  type="definition"
                  term={"selectedProduct" + this.props.products.length}
                />
              </span>
            </div>
          )}
        </td>

        {assessmentStage(this.props.assessment, this, product.product_name)}

        <td className="comments">
          <div className="comments-main">
            <div class="contact-box email-us">
              <h4>Email Us or Call Us</h4>
              <p>
                We love talking to our customers, give us a call! <br /> Or if
                email is your thing, send us a note at info@securefinance.com.au
              </p>
              <a class="contact-button" href="mailto:info@securefinance.com.au">
                Email Us Now
              </a>
              <a class="contact-button" href="tel:0404 262 056 ">
                0404 262 056
              </a>
            </div>

            {/* Do not delete, old code for messages */}
            {/* <ul>
              <li className="message">
                <div className="message-details">
                  <div className="user-image"></div>
                  <div className="user-details">You <span className="date">21st Jun</span></div>
                </div>
                <div className="message-content">
                  Please click the button to the left to resume & finish your eligibility check so that we can check whether or not this loan product is suitable for your situation. This will not affect your credit rating
                </div>
              </li>
              <li className="message">
                <div className="message-details">
                  <div className="user-image"></div>
                  <div className="user-details">You <span className="date">21st Jun</span></div>
                </div>
                <div className="message-content">
                  Please click the button to the left to resume & finish your eligibility check so that we can check whether or not this loan product is suitable for your situation. This will not affect your credit rating
                </div>
              </li>
              <li className="message">
                <div className="message-details">
                  <div className="user-image"></div>
                  <div className="user-details">You <span className="date">21st Jun</span></div>
                </div>
                <div className="message-content">
                  Please click the button to the left to resume & finish your eligibility check so that we can check whether or not this loan product is suitable for your situation. This will not affect your credit rating
                </div>
              </li>
              <li className="message latest">
                <div className="message-details">
                  <div className="user-image"></div>
                  <div className="user-details">Jeffrey Lau <span className="date">21st Jun</span></div>
                </div>
                <div className="message-content">
                  Please click the button to the left to resume & finish your eligibility check so that we can check whether or not this loan product is suitable for your situation. This will not affect your credit rating
                </div>
              </li>
            </ul> */}
          </div>

          {/* <div className="comments-nagivation">
            <button className="ask-a-question"><span>Contact Us</span></button>
          </div> */}
        </td>
      </tr>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanApplicationsBox);


function assessmentStage(value, that){
    switch (value?value + 1:1) {
      case 8: return (
        <td className="stage">
          <div className={"application-status unfinished"}><span>Unfinished Eligibility Assessment</span></div>
          <div className="application-status-description">We’re still waiting for you to finish the qualifier. Perhaps you'd like to give us a call?</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state1.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>Feel free to complete the assessment online, or if it’s easier to do over the phone, give us a call or advise of the best time for us to call you.</p>
            <p className="grey">Our skilled broking team can assess your situation, identify, apply for and get approved for a better home loan. Remember, our service is completely free.</p>
          </div>
          <LinkButton to="/check-eligibility/"className="request-update">Resume Qualifer</LinkButton>
        </td>
      );
      case 2: return (
        <td className="stage">
          <div className={"application-status unfinished"}><span>Qualifier Submitted</span></div>
          <div className="application-status-description">Our team is checking your scenario to see if you qualify.</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state2.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>We have received your completed qualification form. Our team is now working to qualify you for your selected loan product(s).</p> 
            <p className="grey">We will get in touch if we need to confirm any further details, or otherwise once we’ve finished.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Qualifier Submitted'})} className="request-update">Request Update</button>
        </td>
      );
      case 3: return (
        <td className="stage">
          <div className={"application-status lodged"}><span>Qualified</span></div>
          <div className="application-status-description">Congratulations, you qualify!</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state3.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>Congratulations, you qualify for one or more of your selected loans or a suitable alternative.</p> 
            <p className="grey">Please let us know if you would like to proceed to application.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Qualified'})} className="request-update">Request Update</button>
        </td>
      );
      case 4: return (
        <td className="stage">
          <div className={"application-status lodged"}><span>Proceeding to application</span></div>
          <div className="application-status-description">You are proceeding to application</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state4.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>Thanks for confirming that you would like to apply for one of your selected loan products.</p> 
            <p className="grey">We will now begin work on your application.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Proceeding to application'})} className="request-update">Request Update</button>
        </td>
      );
      case 5: return (
        <td className="stage">
          <div className={"application-status unfinished"}><span>Not Eligible</span></div>
          <div className="application-status-description">We could not qualify you for your selected loan products</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state4.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>Unfortunately, you do not appear to qualify for any of your selected loans.</p> 
            <p className="grey">We have also explored other options, and could not find an appropriate solution.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Not Eligible'})} className="request-update">Request Update</button>
        </td>
      );
      case 6: return (
        <td className="stage">
          <div className={"application-status unfinished"}><span>Cancelled Qualification Check</span></div>
          <div className="application-status-description">You have cancelled your qualification check</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state4.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>We understand that you have chosen to cancel your qualification check.</p> 
            <p className="grey">Please reach out if you wish to explore your lending options.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Cancelled Qualification Check'})} className="request-update">Request Update</button>
        </td>
      );
      default : return (
        <td className="stage">
          <div className={"application-status unfinished"}><span>Not Proceeding</span></div>
          <div className="application-status-description">You have chosen not to proceed to application</div>
          <img className="eligiblity-progress-bar" src={require(`img/elements/progress-bar-state4.png`)} alt="progress-bar-state"/>
          <NavLink to="help/" className="eligibility-progress-bar-definition">What do the stages mean?</NavLink>
          <div className="application-details">
            <p>We understand that you have chosen to not to proceed to application</p> 
            <p className="grey">Please reach out if you wish to explore your lending options.</p>
          </div>
          <button onClick={()=>that.requestUpdate({stage: 'Not Proceeding'})} className="request-update">Request Update</button>
        </td>
      );
    }
}
import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import SignIn from "./Accounts/SignIn"
import SignUp from "./Accounts/SignUp"
import ForgotPassword from "./Accounts/ForgotPassword"
import SecureLink from "./Accounts/SecureLink"
import ResetPassword from "./Accounts/ResetPassword"
import SetupPassword from "./Accounts/SetupPassword"
import InitialQuestionSet from "./InitialQuestionSet"
import InitialQuestionSetRefinance from "./InitialQuestionSet/Refinance"
import Contact from "./Contact"
import ValidationResult from "./Accounts/ValidationResult"
import ValidationSent from "./Accounts/ValidationSent"
import EmailChangeResult from "./Accounts/EmailChangeResult"
import PreventEmails from "./Accounts/PreventEmails"
import ShowModal from "Components/Modals/showModal"

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={InitialQuestionSet} />
      <Route exact path="/refinance" component={InitialQuestionSet} />
      <Route exact path="/construct" component={InitialQuestionSet} />
      
      {/* Optimize URLs */}
      <Route exact path="/mv/refinance" component={InitialQuestionSetRefinance} />
      <Route exact path="/mv/purchase" component={InitialQuestionSet} />

      <Route exact path="/login" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/secure-link" component={SecureLink} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/setup-password" component={SetupPassword} />
      <Route exact path="/verify" component={ValidationResult} /> } />
      <Route exact path="/validation-email-sent" component={ValidationSent} /> } />
      <Route exact path="/replace-email" component={EmailChangeResult} /> } />      
      <Route exact path="/prevent-emails" component={PreventEmails} /> } />     

      <Route
        path="/contact"
        render={props => (
          <ShowModal modal='checkEligibilityHelp' redirect={''} />
        )}
      />

      <Route
        path="/:id*"
        render={props => (
          <Redirect to={`/login?next=${props.match.params.id}`} />
        )}
      />

      <Route component={Error} />
    </Switch>
  );

};

export default withRouter(props => <Routes {...props} />);

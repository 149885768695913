import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { openModal } from 'modules/modals/actions';
import { setTitle, setPageID } from 'modules/page/actions';


function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    setTitle,
    setPageID,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

import calculateMonthlyRepayments from "./calculateMonthlyRepayments";

export default ({ user, lender, product }) => {
    
    const meta = user.meta;

    let loanAmount;

    if (meta.loanAmount) {
      loanAmount = meta.loanAmount;
    } else {
      loanAmount = meta.purchasePrice - meta.depositAmount;
    }

    let period = meta.loanPeriod;
    if (!period || period > 30) {
      period = 30;
    }

    if (meta.ioPeriod && !meta.isPandILoan) {
      period -= meta.ioPeriod;
      period = period < 0 ? 0 : period;
    }

    let assessmentRate = parseFloat(product.iir) / 100;
    if (meta.isOOLoan) {
        assessmentRate += lender.assessmentRateOO / 100;
    } else {
        assessmentRate += lender.assessmentRateInv / 100;
    }

    let newMorgageRepayments = 12 * calculateMonthlyRepayments(assessmentRate / 12, period * 12, loanAmount)
    

    if (meta.ioPeriod && !meta.isPandILoan) {
    newMorgageRepayments += meta.ioPeriod * loanAmount * assessmentRate;
    }

    let otherMorgageRepayments = 0;
    if (lender.otherDebtTreatement == "actual") {
    if (meta.otherMorgageRepaymentsActual) {
        otherMorgageRepayments = meta.otherMorgageRepaymentsActual / meta.otherMorgageRepaymentsActualType;
    }
    } else {
    if (meta.otherMorgageRepaymentsAssessed) {
        otherMorgageRepayments = meta.otherMorgageRepaymentsAssessed;
    }
    }

    if (otherMorgageRepayments && lender.otherDebtTreatement == "actual+%") {
    if (meta.isOOLoan) {
        if (lender.otherDebtAdditionOO) {
        otherMorgageRepayments += otherMorgageRepayments * lender.otherDebtAdditionOO;
        }
    } else { 
        if (lender.otherDebtAdditionInv) {
        otherMorgageRepayments += otherMorgageRepayments * lender.otherDebtAdditionInv;
        }
    }
    }

    let totalCommitments = user.paritalCommitments + newMorgageRepayments + otherMorgageRepayments;

    return Math.floor(10000 * user.netIncome / (user.servicablityLivingExpenses + totalCommitments)) / 10000;
    
}
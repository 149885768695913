import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form'
import { requestPatchUser } from 'modules/user/actions';

import { FieldGroup, FormContainer, AddressFieldGroup } from "Components/Forms";
import { createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';
import sanitizeHtml from 'sanitize-html';
import imgArrowRight from 'img/button/arrow-white-right.svg';

import {
   FormStyle, Card, Title, Paragraph, NextButton, Column, ButtonContainer, Saved
} from '../Components/Styled';
import { ButtonSpinner } from 'Components/Global/ButtonSpinner';


const emailValidation = createValidateEmail();
const emailRequired = createRequired();

const CompanyDetailsForm = ({ handleSubmit, initialValues, submitting }) => {
  const dispatch = useDispatch();

  const [ submitted, setSubmitted ] = useState(false);
  const userId = useSelector(state => state.user.id);


  const onSubmit = values => {
    return new Promise((resolve, reject) => {
      console.log(values);
      dispatch(requestPatchUser({
        userId,
        data: {
          referralProfile: { ...values, email: undefined },
        },
        callback: () => {
          resolve();
          setSubmitted(true);
      }}));
    })
    
  }

  return ( <FormContainer containerStyle={{ maxWidth: '2000px' }} onSubmit={handleSubmit(onSubmit)} className="your-details">
    <Card style={{ marginTop: '30px', paddingLeft: '60px', paddingTop: '40px', paddingBottom: '30px'}}>
      <div style={{ width: '565px' }}>
        <Title>Your Company Details</Title>
        <Paragraph>
          If you would like to update your accounts e-mail address or payment details for commission payments, please get in touch with us via phone on 0404 262 056.
        </Paragraph>
        <FormStyle>
          <div className='form'>
          <FieldGroup showRequired id='companyName' width='small' position="left" title='Company Name' required type='text' />
            <FieldGroup showRequired id='abn' width='small' position="right" title='ABN' required type='text' />
            <FieldGroup showRequired id="companyPhone" width="small" position="left" title="Company Landline" required type="text" {...phoneNumberProps}/>
            <FieldGroup hideOptional id="email" width="small" position="right" title="Accounts E-mail" type="text" disabled/>
            <AddressFieldGroup hideVerified showRequired id="address" width="100%" position="left" title="Company Address" type="text" required/>
            
            <FieldGroup showRequired id='primaryContactName' width='small' position="left" title='Primary Contact Name' required type='text' />
            <FieldGroup showRequired id='primaryContactPhone' width='small' position="right" title='Primary Contact Phone' required type='text' {...phoneNumberProps}/>
            <FieldGroup showRequired id='primaryContactEmail' width='small' position="left" title='Primary Contact E-mail' required type='text' validate={[emailRequired, emailValidation]} />

            <FieldGroup hideOptional id='secondaryContactName' width='small' position="left" title='Secondary Contact Name' type='text' />
            <FieldGroup hideOptional id='secondaryContactPhone' width='small' position="right" title='Secondary Contact Phone' type='text' {...phoneNumberProps}/>
            <FieldGroup hideOptional id='secondaryContactEmail' width='small' position="left" title='Secondary Contact E-mail' type='text' />
          </div>
        </FormStyle>
      </div>
    </Card>

    <ButtonContainer>
      <div></div>
      <div>
        {submitted && <Saved>Changes saved</Saved>}
        <NextButton style={{ width: '160px', marginRight: '10px'}} type='submit'><ButtonSpinner loading={submitting}/>Update Details</NextButton>
      </div>
    </ButtonContainer>
    </FormContainer>) 
}



export default reduxForm({ form: 'company-details' })(CompanyDetailsForm); 


import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

class CheckEligibilityHowDoesThisWork extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityHowDoesThisWork") {
      return (
        <div className="modal-content">
          <h1>How does this work?</h1>
          <div className="box-text">Our online platform is backed by a strong team of lending professionals who can help you get a great deal on your home loan - all at no cost to you!</div>

          <div className="row step-1">
            <div className="half-column">
              <p><strong>Step 1.</strong> We’re here to help with your home loan needs. Find one or more loans that you are interested in and click Qualify Me to select that loan product. You can add up to 3 into the system.</p>
            </div>
            <div className="half-column right">
              <img src={require('../../../img/modal/howdoesthiswork_1.png')} alt="howdoesthiswork" />
            </div>
          </div>
          <div className="row step-2">
            <div className="half-column">
              <img src={require('../../../img/modal/howdoesthiswork_2.png')} alt="howdoesthiswork" />
            </div>
            <div className="half-column right">
              <p><strong>Step 2.</strong> Click Qualify Me in the bottom right-hand corner. This will progress to the next step of the qualifier.</p><p>We ask for your contact details so we can get in touch to clarify and confirm your scenario.</p>
            </div>
          </div>
          <div className="row step-3">
            <div className="half-column">
              <p><strong>Step 3.</strong> The qualifier will then ask you a number of detailed questions about your financial situation.</p><p>Lenders have different credit policies, so we need to understand your scenario to help. Please complete this as best you can.</p>
            </div>
            <div className="half-column right">
              <img src={require('../../../img/modal/howdoesthiswork_3.png')} alt="howdoesthiswork" />
            </div>
          </div>
          <div className="row step-4">
            <div className="half-column">
              <img src={require('../../../img/modal/howdoesthiswork_4.png')} alt="howdÎoesthiswork" />
            </div>
            <div className="half-column right">
              <p><strong>Step 4.</strong> We will then confirm whether you qualify for your selected products and let you know your options.</p><p>We’ll also try and identify any superior alternatives, including “under the counter” deals.</p>
            </div>
          </div>

          <div className="row step-5">
            <div className="half-column">
              <p><strong>Step 5.</strong>  If you wish to proceed with one of these options, we will lodge an application on your behalf and follow this through with the lender until it settles.</p><p>After that, we conduct regular reviews and check-ins to ensure your lending needs are always being met. Simple as that! 😀</p>
            </div>
            <div className="half-column right">
              <img src={require('../../../img/modal/howdoesthiswork_5.png')} alt="howdoesthiswork" />
            </div>
          </div>

        <div className="modal-navigation">
          <button onClick={()=>this.props.openModal('checkEligibilityHelp')} className="left double resume">Get Advice</button>
          <button onClick={()=>this.props.closeModal()} className="right double delete">Got it!</button>
        </div>

        </div>
      )
    } else {
      return "";
    }
  }
}

export default CheckEligibilityHowDoesThisWork;

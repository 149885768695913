import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

function mapStateToProps(state) {
  return {
    user: state.user.meta,
  };
}

class Tooltips extends Component {
  render() {
    //console.log('tooltip:', this.props);
    if (this.props.user.user_meta_tooltips || this.props.user.user_meta_tooltips === undefined) {
      switch (this.props.type) {
        case "search": return <SearchTooltip {...this.props}/>;
        case "definition": return <DefinitionTooltip style={this.props.style} type={this.props.term} />;
        case "shortlist": return <LoanShortlist {...this.props}/>;
        case "rebate": return <RebateTooltip definition={this.props.definition} />;
        case "custom": return <CustomTooltip {...this.props} definition={this.props.definition} />;
        case "wrapper": return <CustomTooltipWrapper {...this.props} definition={this.props.definition}  />;
        default: return "NULL";
      }
    } else {
      switch (this.props.type) {
        case "definition": return <DefinitionTooltip type={this.props.term} {...this.props}/>;
        case "rebate": return <RebateTooltip definition={this.props.definition} {...this.props}/>;
        case "custom": return <CustomTooltip {...this.props} definition={this.props.definition} />;
        case "wrapper": return <CustomTooltipWrapper {...this.props} definition={this.props.definition}  />;
        default: return "";
      }
    }

  }
}

class SearchTooltip extends Component {
  render() {
    return (
      <div className="tooltip-container search">
        <div className="left">
          <span>📌 Interested in applying? Click Qualify Me to start the process!</span>
        </div>
        <div className="right">
          <span>Star loans to shortlist and compare them</span>
        </div>
        {/* <img className="left" src={require('img/tooltips/search_left.png')} alt='search'/>
        <img className="right" src={require('img/tooltips/search_right.png')} alt='search'/> */}
      </div>
    )
  }
}

class LoanShortlist extends Component {
  render() {
    return (
      <div className="tooltip-container shortlist">
        <span>
          Hint: Click this icon below for refinance analysis.
        </span>
        {/* <img src={require('img/tooltips/shortlist.png')} alt='shortlist'/> */}
      </div>
    )
  }
}

let definitions =
{
  borrowingPower: 'Rough indication of how likely you are to be able to borrow the loan amount you are searching for through this lender.',
  bridging: 'A temporary loan to enable you to purchase a new property before selling your current property.',
  companyTrust: 'This loan is available to Company borrowers',
  construction: 'This loan is available to fund the construction of a property on a block of land.',
  extraRepayment: 'Allows repayments beyond the minimum you are required to pay each month allowed without penalty.',
  firstHomeBuyer: 'Available to someone who has never owned their own home in Australia before and is now looking to buy their own home.',
  firstPropertyBuyer: 'Available to someone who has never owned a property in Australia before and is now looking to buy their first property.',
  fortnightlyRepayment: 'The approximate minimum repayment you will be required to make for this loan on a fortnightly basis.',
  interestInAdvance: 'Requires you to pre-pay each year\'s worth of interest upfront every year, rather than monthly in arrears.',
  interestOnly: 'Available with interest only repayments only',
  interestRate: 'The nominal interest rate that you will be charged for this loan for the initial period',
  loDocAltDoc: 'This loan permits alternative income verification options, such as business activity statements, an accountant\'s letter, or business bank statements for self employed borrowers.',
  loanIncrease: 'Allows you to apply for an increase to your initial loan amount.',
  lockedRate: 'Option to ensures that the fixed rate you settle on will not be higher than the rate you have locked in at application time, generally for a fee.',
  lVR: 'Loan to Value Ratio; calculated by dividing the loan amount by the value of the security property.',
  lVRHigh: 'High loan-to-value ratios may restrict the number of available loan products. Lenders Mortgage Insurance may also be applicable. Get in touch with us for assistance.',
  monthlyRepayment: 'The approximate minimum repayment you will be required to make for this loan on a monthly basis.',
  offset: 'Allows the full balance of your linked balance with a deposit account with the lender to count as though it were paid off your loan when interest is calculated.',
  ownerBuilder: 'This construction loan allows you to build your own property, rather than requiring you to pay an external building company to build it.',
  pi: 'Available with principal & interest repayments only',
  piOrInterest: 'Available with either principal and interest or interest only repayments',
  piOrInterestShortlist: 'This product is only available with principal & interest repayments',
  interestOnlyShortlist: 'This product is only available with interest only repayments',
  package: 'Bundles your loan up with additional products and features; generally includes an offset account and requires an ongoing fee. Other common features include rate discounts and credit cards.',
  partialOffset: 'Allows a percentage of your linked balance with a deposit account with the lender to count as though it were paid off your loan when interest is calculated.',
  portability: 'Allows you to keep your loan when moving to a new property, to avoid the need to refinance.',
  purchase: 'This loan is available for purchasing a property.',
  redraw: 'Allows you to withdraw extra repayments you have made beyond your scheduled balance.',
  refinance: 'This loan is available for the purpose of refinancing an existing loan across from another lender.',
  smsf: 'This loan is available to Self Managed Super Fund borrowers (SMSF\'s)',
  splitting: 'Allows you to split your total loan up into smaller / separate loan accounts, with the possibility of different rate and repayment types.',
  vacantLand: 'This loan is available for the purchase or refinance of vacant land as security.',
  weeklyRepayment: 'The approximate minimum repayment you will be required to make for this loan on a weekly basis.',
  stampDuty: 'Estimates the amount of stamp, mortgage & transfer duty payable, as well as any grants you may be eligible for',
  fundsRequired: 'Estimates the loan required for you to complete your purchase by subtracting your deposit from the total purchase cost, including stamp duty and other closing costs.',
  switchingCost: 'This may include: discharge fees, valuation fees, application fees, legals, fixed cost, break fees, and government fees.',
  selfEmployedIncome: 'Self-employed income generally means wages paid to yourself + net profit before tax for the business + depreciation added back.',
  selectedProduct1: 'You have selected 1 products for the qualifier. Please click the text to view the list of products you have selected.',
  selectedProduct2: 'You have selected 2 products for the qualifier. Please click the text to view the list of products you have selected.',
  selectedProduct3: 'You have selected 3 products for the qualifier. Please click the text to view the list of products you have selected.',
  WIR: 'This weighted calculation is an indicative average interest rate for your entire borrowing portfolio based on our recommended solution, excluding lender fees and charges. It is calculated by proportionally weighting interest rates by the corresponding loan size. For any solution including fixed rate loans, this is calculated based on the shortest fixed rate term.',
  WTR: 'This weighted calculation is an indicative average interest rate for your entire borrowing portfolio based on our recommended solution, including lender fees and charges. It is calculated by proportionally weighting interest rates by the corresponding loan size.',
  proposedOptionsRR: 'This is the variable rate that automatically applies after your fixed rate term. Before this occurs, we would generally work with to you to roll you into another competitive fixed rate, negotiate a discount to the variable rate, or help you refinance to a more competitive solution elsewhere. This means that you should not usually need to pay the revert rate.',
}

class DefinitionTooltip extends Component {
  render() {
    return (
      <span className="info" data-tooltip={definitions[this.props.type]} style={this.props.style}>?</span>
    )
  }
}

class RebateTooltip extends Component {
  render() {
    return (
      <WrapperSpan className="info rebate" data-tooltip={this.props.definition}></WrapperSpan>
    )
  }
}

class CustomTooltip extends Component {
  render() {
    return (
      <span className={"info " + this.props.className} data-tooltip={this.props.definition}></span>
    )
  }
}

class CustomTooltipWrapper extends Component {
  render() {
    return (
      <WrapperSpan width={this.props.maxWidth} className={'tooltip-wrapper ' + this.props.className} data-tooltip={this.props.definition}>{this.props.children}</WrapperSpan>
    )
  }
}

const WrapperSpan = styled.span`
  ${p => p.width && css`
    &::before {
      max-width: ${p.width};
    }
  `}
`;

export default connect(mapStateToProps, null)(Tooltips);

import React, { useState } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';
import Tooltips from "Components/Tooltips";

import imgReferralCount from 'img/elements/referrals-count.svg';
import imgCommissionPayed from 'img/elements/referrals-commission-paid.svg';
import imgSettledLoans from 'img/elements/referrals-settled-loans.svg';

import {
 Card, Title, TotalsContainer, TotalContainer, TotalCard, Total, ReferralTable, StageLabel,
 TableCell, TableHeader, TableRow, CommissionPaid, Placeholder, CenterContent, LoadMore
} from './Styled';
import formatNumber from 'utils/formatNumber';
import moment from 'moment';


const ReferralsInfo = ({ isIntro }) => {
  const dispatch = useDispatch();
  const [ maxLoaded, setMaxLoaded ] = useState(20);
  const referrals = useSelector(state => state.user.referredApplications ?? []);
  let visibleReferrals = referrals.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
  visibleReferrals = visibleReferrals.slice(0, maxLoaded);
  
  
  const referralCount = referrals.length;
  const settledCount = referrals.filter(x => !!x.commissionAmount || x.stage === 'Payment pending').length;
  const commissionPaid = referrals
    .reduce((total, x) => total + (x.commissionAmount ?? 0), 0);
  
  return (
    <>
      <TotalsContainer>
        <TotalCard>
          <TotalContainer>
            <img src={imgReferralCount} /> 
            <Total>{referralCount ? formatNumber(referralCount) : '—'}</Total>
          </TotalContainer>
          <Title>Referral Count</Title>
        </TotalCard>
        <TotalCard>
        <TotalContainer>
            <img src={imgSettledLoans} /> 
            <Total>{settledCount ? formatNumber(settledCount) : '—'}</Total>
          </TotalContainer>
          <Title>Settled Loans</Title>
        </TotalCard>
        <TotalCard>
        <TotalContainer>
            <img src={imgCommissionPayed} /> 
            <Total>{commissionPaid ? '$' + formatNumber(commissionPaid) : '—'}</Total>
          </TotalContainer>
          <Title>Commission Paid</Title>
        </TotalCard>

        
      </TotalsContainer>
      <Card style={{ marginTop: '30px', paddingBottom: 0, borderBottom: 'none' }}>
        <Title>Recent referrals</Title>
        <ReferralTable>
          <TableHeader>
            <TableCell width='34%'>CLIENT NAME</TableCell>
            <TableCell width='22%'>BROKER</TableCell>
            <TableCell width='22%'>LAST UPDATE</TableCell>
            <TableCell width='22%'>STATUS</TableCell>
          </TableHeader>
          {!!referrals.length && visibleReferrals
            .map(x => {
              return (
                <TableRow>
                  <TableCell width='34%'>{(x.referralDetails?.firstName ?? '') + ' ' + (x.referralDetails?.lastName ?? '')}</TableCell>
                  <TableCell width='22%'>{x.brokerName ?? '—'}</TableCell>
                  <TableCell width='22%'>{moment(x.updatedAt).format('DD MMMM YYYY')}</TableCell>
                  <TableCell width='22%'>{x.commissionAmount || x.referreeCommissonAmount ? <>
                      {/*<CommissionPaid>{'$' + formatNumber(x.commissionAmount)} Commission Paid</CommissionPaid>*/}
                      <CommissionPaid>Commission Paid</CommissionPaid> 
                    </>
                    : 
                    <StageLabel stage={x.stage}/>}
                  </TableCell>
                </TableRow>
            )})}
          {referrals.length > maxLoaded &&
            <TableRow style={{ cursor: 'pointer'}} onClick={() => setMaxLoaded(maxLoaded + 10)}>
              <CenterContent>
                <LoadMore>Load more...</LoadMore>
              </CenterContent>
            </TableRow>

          }
          {!referrals.length && <>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width='34%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
              <TableCell width='22%'><Placeholder /></TableCell>
            </TableRow>
          </>}
        </ReferralTable>
      </Card>
    </>
  ) 
}

export default ReferralsInfo; 



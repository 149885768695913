import React from "react";
import { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";
import { lenderService } from "modules";
import calculateProductNSR from 'utils/calculateProductNSR';

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}


class BorrowingPowerValue extends Component {
  render() {
    //debugger;
    const { user, lender, product } = this.props;
    
    const isOO = !!user.meta.isOOLoan;
    
    let isActualRate = false;

    if (lender) {
      isActualRate = isOO ? 
        lender.assessmentRateOOType === 'actual' : 
        lender.assessmentRateInvType === 'actual';
    }
    
    const value = isActualRate ? calculateProductNSR({ user, lender, product }) : this.props.value;
    
    if (this.props.user.borrowingPower && this.props.user.borrowingPower.high) {
      if (value < 0.7) {
        return <div className="borrowing-value low">Low</div>
      }
      if (0.7 <= value  && value < 1.3) {
        return <div className="borrowing-value medium">Medium</div>
      }
      else {
        return <div className="borrowing-value high">High</div>
      }
    }
    else {
      return <NavLink to="/calculators/borrowing-power" className="borrowing-value empty-value">Calculate this</NavLink>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BorrowingPowerValue);
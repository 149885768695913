import React from "react";
import { Form } from 'redux-form';

const $ = window.$;

let maxWindowHeight = 0;

class QuestionWrapper extends React.Component {


  static getDerivedStateFromProps(props, state) {
    const elements = $('.q' + props.id).find('input, button, select');
    if (props.id !== props.currentQuestion) {
      elements.each(function() { $(this).attr('tabindex', -1)});
    } else {
      elements.each(function() { $(this).removeAttr('tabindex')});
    }
    return null;
  }

  submit = args => {
    args.nativeEvent.preventDefault();
    this.props.onSubmit(args);
  }

  render() {
    
    const { onSubmit, id, gotoQuestion, invalid, isTransitioning, currentQuestion, ...props } = this.props;

    
      if (isTransitioning) {
        if(!this.props.alwaysRender) {
          if (Math.abs(id - currentQuestion) > 1) return null; 
        }
      } else {
        if (id !== currentQuestion) return null;
      }
    
    
    const className = (id < currentQuestion) ? 'before ' : (id > currentQuestion) ? 'after ' : '';
    console.log('Question:', id, this.props.finalQuestion);
    return(
      <form onSubmit={this.submit} className={className + id}  noValidate >
      <div
>
        <div className={"onboarding-question " + ('q' + id + ' ') + (invalid ? 'invalid-form' : '')} id={'n' + id}>
        
          <div className={"content form-container " + (this.props.finalQuestion ? "last-question" : "")}>
            <div className='centre-align'>
              <div className='centred-content'>
                {this.props.children}
              </div>
            </div>
          </div>
          <div className="divide"></div>
        </div>
      </div>
        
      </form>
  );
}
}

export default QuestionWrapper;

import React from "react"; import { Component } from "react";
import { openModal, closeModal } from 'modules/modals/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestLogin } from 'modules/user/actions';
import { MediumProductBox } from "Components/Product"
import { NavLink } from "react-router-dom";

function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
  }, dispatch);
}

class ShortListView extends Component {
  render() {
    if (this.props.currentModal === "shortListView") {
    return (
      <div className="modal-content">
        <div className="heading">
          <h1>Your Shortlist</h1>
        </div>

        {(this.props.shortlist.length !== 0)?
          <div className={"product-list" + ((this.props.shortlist.length <= 3)?" no-scroll":"")}>
            <div className="scroll-container" style={{width: (this.props.shortlist.length * 290)}}>
              {this.props.shortlist.map((items)=> <MediumProductBox product={items} />)}
            </div>
          </div>
        : 
          <div className="empty">
            <div className="illustration"></div>
            <h4>You have no shortlisted loans! 😔</h4>
            <p>Why not <NavLink to="/search-loans">search</NavLink> to identify a suitable home loan?</p>
          </div>
        }
        

      </div>
    ) } else {
      return "";
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortListView);
import React, { useEffect } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'modules/modals/actions';

import imgPlus from 'img/button/small_white_plus.svg';
import imgMoreInfo from 'img/button/more_info.svg';

import {
  Main, MainContainer, PageIllustration, Card, Title, Paragraph, ButtonContainer, InfoButton, ReferButton,
} from '../Components/Styled';

import ReferralsInfo from '../Components/ReferralsInfo';
import sanitizeHtml from 'sanitize-html';

const Referrals = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const globalSettings = user.globalSettings;
  const referrals = user.referredApplications;
  const isProfessional = user.isProfessionalReferrer;

  useEffect(() => {
    dispatch(setTitle('Referral Dashboard'));
    dispatch(setPageID('referrals'));
  }, [])

  const clickReferButton = () => {
    history.push('/referrals/new')
  }

  let introText = '';
  if (isProfessional) {
    if (referrals && referrals.length) {
      introText = globalSettings.professional_referrer_intro_existing;
    } else {
      introText = globalSettings.professional_referrer_intro_new;
    }
  } else {
    if (referrals && referrals.length) {
      introText = globalSettings.referrer_intro_existing;
    } else {
      introText = globalSettings.referrer_intro_new;
    }
  }

  return (
    <Main class='main' style={{ paddingBottom: '100px' }}>
      <MainContainer style={{ minWidth: '685px', maxWidth: '850px', width: 'auto' }}>
        <PageIllustration />
          <Card>
            <Title>{isProfessional ? 'Refer a Client' : 'Refer a Friend Program'}</Title>
            <Paragraph style={{ minHeight: '90px' }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(introText)}} />
            <ButtonContainer>
              <InfoButton onClick={() => dispatch(openModal('referral-more-info'))}><img src={imgMoreInfo} style={{ marginRight: '22px', position: 'relative', top: '2px' }}/>More Info</InfoButton>
              <ReferButton onClick={clickReferButton}><img src={imgPlus} style={{ marginRight: '10px' }} />{isProfessional ? 'Refer New Client' : 'Refer a Friend'}</ReferButton>
            </ButtonContainer>
          </Card>
          <ReferralsInfo />
      </MainContainer>
    </Main>
  ) 
}

export default Referrals; 



import React from "react"; import { Component } from "react";
import { requestPatchUser } from 'modules/user/actions';
import { addToShortlist, removeFromShortlist } from 'modules/user/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components'
import { SubMenu, MenuOptions } from "Views/Proposal/theme/Dropdown";
import { Icons } from "Views/Proposal/theme/ProposalIcons";
import _ from 'lodash';
import calculateLoanValues from "utils/calculateLoanValues";
import calculateTrueRate from "utils/calculateTrueRate";
import TagManager from 'react-gtm-module'

import alterArray from 'utils/alterArray';
import crypto from 'crypto';

const { $ } = window;

function mapStateToProps(state) {
  let applicationProducts = [];
  if (state.user.applications && state.user.applications.length > 0) {
    applicationProducts = state.user.applications[0].products;
  }
  return {
    application: state.user.applications[0],
    applicationProducts,
    userMeta: state.user.meta,
    userId: state.user.id,
    shortlist: state.user.shortlist,
    godmode: state.user.godmode,
    miniProposal: state.user.meta.miniproposal,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    addToShortlist,
    removeFromShortlist
  }, dispatch);
}

function hash(data) {
  return crypto.createHash('md5').update(data).digest("hex");
}

class StarLoansButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      isStarred: false,
      inProgress: false,
    };
  }

  toggleMenu() {
    this.setState(state => ({ ...state, menuOpen: !this.state.menuOpen }))
  }

  closeMenu() {
    this.setState(state => ({ ...state, menuOpen: false }))
  }

  componentDidMount() {
    const { product, shortlist } = this.props;
    const isStarred = shortlist.some(i => i.id === product.id);
    this.setState({ isStarred });
    $(window).on('click', this.checkIfLeaveMenu.bind(this));  
  }

  componentWillUnmount() {
    $(window).off();
  }

  handleClick(isStarred) {

    // GTM - Shortlisting Loan
    TagManager.dataLayer({
      gtmId: 'GTM-5HT97R9',
      dataLayer: {
        event: 'shortlistLoanProduct'
      }
    });

    if (this.state.inProgress) return;
    const { product, shortlist } = this.props;
    if (isStarred && shortlist.some(i => i.id === product.id)) {
      //console.log('removing...')
      this.setState({ isStarred: false, inProgress: true });
      this.props.removeFromShortlist({
        data: this.props.product.id, callback: () => {
          this.setState({ inProgress: false });
          //console.log('Finished!')
        }
      });
    }
    else if (!shortlist.some(i => i.id === product.id)) {
      //console.log('Adding...')
      this.animate();
      this.setState({ isStarred: true, inProgress: true });
      this.props.addToShortlist({
        ...this.props.product, callback: () => {
          this.setState({ inProgress: false });
          //console.log('Finshed!')
        }
      });
    }

  }

  animate = async () => {

    this.setState({ isAnimating: true });

    var mq = window.matchMedia('(max-width: 1440px)');
    //console.log(mq);

    const destination = $('.menu-item-4').offset();
    destination.left = 0;
    const startPos = $(this.refs.container).offset();
    var delta = {
      top: (destination.top - startPos.top) - 75,
      left: (destination.left - startPos.left) + 50,
    };

    if (mq.matches) {
      delta = {
        top: (destination.top - startPos.top) - 43,
        left: (destination.left - startPos.left) + 31,
      };
    }

    const middle = {
      top: delta.top / 2,
      left: delta.left / 2
    };


    const $star =
      $(`<div class="animated-star" style="top:${startPos.top + 5}px; left:${startPos.left + 5}px"/>`);

    $('body').append($star);

    $star
      .offset(startPos)
      .css('transform', `translate(${middle.left}px, ${middle.top}px) scale(2)`);
    setTimeout(() => {
      $star.css('transform', `translate(${delta.left}px, ${delta.top}px) scale(0.5)`);
    }, 300);
    setTimeout(() => {
      $star.css('opacity', '0');
    }, 600);
    setTimeout(() => {
      $star.remove();
    }, 900);

  }

  addLoanToProperty(id, product) {
    const loanValues = calculateLoanValues(product);
    const loanComparison = this.props.miniProposal.properties[id].loanComparison;
    const loans = loanComparison.loanOrder
      .map(id => loanComparison.loans[id]);
    if (loanComparison.loanOrder.length >= 3 ||
      loans.some(loan => loan.productId === product.id)) {
      return;
    }

    const recommendationActive = (loanComparison.loanOrder.length === 0) 
      ? true : loanComparison.recommendationActive;

    const loanId = hash(Date.now().toString());
    const newLoanOrder = [...loanComparison.loanOrder, loanId];
    const trueRate = calculateTrueRate({ 
      rate: parseFloat(product.iir) / 100, 
      monthlyFee: ((loanValues.approxUpfrontFee / 30) + (product.monthly_fees * 12 || (product.annual_fees || 0))) / 12,
      loanAmount: this.props.miniProposal.properties[id].currentLoan.loanAmount,
      loanPeriod: this.props.miniProposal.properties[id].currentLoan.newLoanPeriod || 30,
      fixedTerm: product.fixed_term / 12,
      rr: product.rr / 100,
    });

    const newLoan = { 
      ...product, 
      id: loanId, 
      productId: product.id,
      trueRate,
      originalTrueRate: trueRate,
      originalIIR: product.iir,
      originalRR: product.rr,
      approxUpfrontFee: loanValues.approxUpfrontFee,
      originalApproxUpfrontFee: loanValues.approxUpfrontFee,
      ongoing_fees: loanValues.ongoing_fees,
      original_ongoing_fees: loanValues.ongoing_fees,
      original_full_offset: loanValues.full_offset,
      original_redraw_allowed: loanValues.redraw_allowed,
      original_extra_repayments_allowed: loanValues.extra_repayments_allowed,
    };

    console.log({ newLoanOrder, newLoan });
    this.patchProposal(id, { loanOrder: alterArray.push(loanId), loans: { [loanId]: newLoan }, recommendationActive });
  }

  patchProposal = (id, data, callback) => this.props.requestPatchUser({ 
    userId: this.props.user.id,
    params: {
      mergeMeta: true,
    },
    data: { 
      meta: { 
        miniproposal: {
          properties: {
            [id]: {
              loanComparison: data
            }
          }
        }
      } 
    },
    callback, 
  });

  checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.proposal-button' + this.props.product.id).length) {
      this.closeMenu()
    }
  }

  

  render() {
    const { shortlist, product, miniProposal, user } = this.props;
    const { isStarred } = this.state;

    const hasVideoProposal = user.proposal && user.proposal.enabled;

        let tabs = [];
    if (miniProposal && Array.isArray(miniProposal.tabs)) {
      tabs = miniProposal.tabs.map((id, index) => {
        const property = miniProposal.properties ? miniProposal.properties[id] : null;
        
        if (!property) {
          // Handle missing property
          return {
            id: null,
            title: 'Error',
            color: tabColours[0],
            loanCount: 0,
            disabled: true,
          };
        }

        const loanComparison = property.loanComparison || {};
        let disabled = false;

        const loans = (loanComparison.loanOrder || [])
          .map((loanId) => loanComparison.loans ? loanComparison.loans[loanId] : null)
          .filter(loan => loan !== null); // Filter out null loans in case they don't exist
        
        if (loans.length >= 3) disabled = true;
        
        if (loans.length > 0 && loans.some(loan => loan && loan.productId === product.id)) {
          disabled = true;
        }

        return {
          id: property.id,
          title: property.name,
          color: tabColours[index + 1] || tabColours[0], // Ensure there's a fallback color
          loanCount: loans.length,
          disabled,
        };
      });
    }

    //console.log({tabs});

    if(this.props.godmode) {
      return (
        <StarContainer ref='container' className={'proposal-button' + product.id} >
          <button
            onClick={() => this.toggleMenu()}
            className={"favourites" + (isStarred ? ' active' : ' ')}>
          </button>
          <SubMenuAdjusted status={this.state.menuOpen}>
            {tabs.map((tab, index) => {
              return (<MenuOptions key={index} color={tab.disabled ? "disabled" :"default"} onClick={() => this.addLoanToProperty(tab.id, product)}><Icons color={tab.color} />Add to {tab.title} ({tab.loanCount})</MenuOptions>)
            })}
            <MenuOptions color="default" onClick={() => this.handleClick(isStarred)}>{!isStarred ? "Star Loan" : "Un-star Loan"}</MenuOptions>
          </SubMenuAdjusted>
        </StarContainer>
      );
    }

    return (
      <StarContainer ref='container'>
        <button 
          onClick={()=>this.handleClick(isStarred)} 
          className={"favourites" + (isStarred ? ' active' : ' ') }>
        </button>
      </StarContainer> 
    );
  }

}


const tabColours = [
  '#2291FF',
  '#26BD27',
  '#BD10E0',
  '#FF4E4C',
  '#F5A623',
  '#00CD98',
  '#FF00B2',
  '#00E5FF',
  '#FF0000',
  '#2291FF',
  '#26BD27',
  '#BD10E0',
  '#FF4E4C',
  '#F5A623',
  '#00CD98',
];

const StarContainer = styled.span`
  position: relative;
`;

const SubMenuAdjusted = styled(SubMenu)`
  top: 36px;
  display: none;
  width: 250px;
  
  ${props => props.status && css`
    display: block;
  `};

  &:after {
    width: 36px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(StarLoansButton);

import styled, { css } from 'styled-components'

export const MenuOptions = styled.li`
  font-size: 12px;
  border-bottom: 1px solid #D8DCE7;
  padding: 10px 0;
  cursor: pointer;
  text-align: left;

  ${props => props.confirm && css`
    position: relative;
  `};

  ${props => props.color === "default" && css`
    color: #818CA2;

    &:hover {
      color: #101922;
    }
  `};

  ${props => props.color === "disabled" && css`
    color: #dedede;
    cursor: not-allowed;
  `};

  ${props => props.color === "red" && css`
    color: #FF4E4C;

    &:hover {
      color: #EC2422;
    }
  `};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const SubMenu = styled.ul`
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D8DCE7;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
  border-radius: 4px 0 4px 4px;
  right: 0px;
  top: 23px;
  padding: 2px 10px;
  width: ${p => p.width || 150}px;
  display: none;
  text-align: left;
  z-index: 51;

  ${props => props.location && css`
    right: none;
    left: 0px;
    border-radius: 0 4px 4px 4px;

    &:after {
      right: none;
      left: -1px;
    }
  `}
  
  ${props => props.status && css`
    display: block;
  `};

  &:after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #fff;
    width: 40px;
    right: -1px;
    top: -3px;
    border-right: 1px solid #D8DCE7;
    border-left: 1px solid #D8DCE7;
  }
`;

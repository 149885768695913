import React, { useEffect, useState } from 'react';
import { setTitle, setPageID } from "modules/page/actions";
import { useDispatch, useSelector } from 'react-redux';

import imgPlus from 'img/button/small_white_plus.svg';
import imgMoreInfo from 'img/button/more_info.svg';
import imgBack from 'img/button/back_blue.svg';

import {
  Main, MainContainer, PageIllustration, Card, Title, Paragraph, ButtonContainer, InfoButton, ReferButton, CenterContent,
} from '../Components/Styled';

import ReferralsInfo from '../Components/ReferralsInfo';
import ConfirmDetailsForm from '../Components/ConfirmDetailsForm';
import ReferralForm from '../Components/ReferralForm';
import sanitizeHtml from 'sanitize-html';

const AddReferral = ({ history }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  
  const startingContent = (user.meta.hasConfirmedReferrerDetails || user.isProfessionalReferrer) 
    ? 'referral-form' : 'confirm-details';
  const [ content, setContent ] = useState(startingContent);

  useEffect(() => {
    dispatch(setTitle(user.isProfessionalReferrer ? 'New Referral' : 'Refer a Friend'));
    dispatch(setPageID('referrals'));
  }, [])

  return (
    <Main class='main'>
      <MainContainer>
        <PageIllustration />

        {content === 'confirm-details' &&
          <ConfirmDetailsForm setContent={setContent} initialValues={user}/>
        }

        {content === 'referral-form' && <>
          <ReferralForm setContent={setContent}/>
        </>}

        {(content === 'referral-form-success' || content === 'referral-form-success-duplicate') && <>
          <Card style={{ padding: '45px 0 45px 0' }}>
            <CenterContent style={{ marginTop: '5px'}}><span>Thank you for submitting the referral!</span></CenterContent>
            {content === 'referral-form-success-duplicate' &&
              <CenterContent style={{ marginTop: '5px'}}>
                <span style={{ fontStyle: 'italic' }}>Please note that this client already exists in our database.</span>
              </CenterContent>
            }
            <CenterContent style={{ marginTop: content === 'referral-form-success-duplicate' ? '12px' : '5px'}}><span>One of our team will reach out to them shortly 🙂</span></CenterContent>
            <CenterContent style={{ marginTop: '45px'}}>
              <InfoButton onClick={() => history.push('/referrals/dashboard')} style={{ width: '254px', height: '50px', lineHeight: '50px'}}>
                <img src={imgBack} style={{ marginRight: '22px', position: 'relative', top: '2px' }}/>
                Back to Referral Dashboard
              </InfoButton>
            </CenterContent>
          </Card>
        </>}

      </MainContainer>
    </Main>
  ) 
}

export default AddReferral; 



import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LoginForm from "Components/Accounts/LoginForm";
import Navigation from "Components/Navigation/Navigation";
import Divider from "./Components/Divider";
import { NavLink } from "react-router-dom";
import { googleSignin, facebookSignin } from "utils/oauth";
import { requestSecureLink } from "modules/user/actions";
import { openModal } from "modules/modals/actions";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      requestSecureLink,
    },
    dispatch
  );
}

class SignIn extends Component {
  state = {
    values: [],
  };

  requestSecureLink = () => {
    this.props.requestSecureLink({
      email: "josh@wax.com.au",
      callback: (result) => {
        //console.log(result);
      },
    });
  };

  render() {
    return (
      <div>
        <Navigation state="unactive" />
        <main className="user-authentication">
          <div className="modal">
            <div className="left-image">
              <div className="quote">
                <div className="quote-symbol">"</div>
                <div className="quote-content">
                  <strong>SecureFinance</strong> recently helped me with a loan
                  for a new home purchase. The entire process was efficient and
                  stress-free. I have no hesitation recommending their services
                  to others and will never be going direct to the banks again!{" "}
                  <span>- Jason, Raceview (QLD)</span>
                </div>
              </div>
            </div>

            <div className="right-content">
              <h2>Sign In to Your Account</h2>
              <h4>Please enter your details below to sign in!</h4>

              <LoginForm />
              <Divider />

              <div className="skip-password">
                <div className="skip-password-text">
                  <span>Skip password!</span>
                  Login with a magic link!
                </div>
                <NavLink className="email-secure-link" exact to="/secure-link">
                  <span>Email Me a Magic Link</span>
                </NavLink>
              </div>

              <Divider />

              <div className="alternative-signin">
                <button className="google" onClick={() => googleSignin()}>
                  Sign In with Google
                </button>
                <button className="facebook" onClick={() => facebookSignin()}>
                  Sign In with Facebook
                </button>
              </div>

              <div className="navigation">
                Don't have an account yet?{" "}
                <NavLink exact to="/signup/">
                  Click here to Sign Up.
                </NavLink>
              </div>
            </div>
          </div>
        </main>
        <div className="copyright-footer">
          Secure Finance Services Pty Ltd. Australian Credit Licence Number
          465059. 204/11 Solent Circuit, Norwest NSW 2153. By registering you
          are agreeing with our{" "}
          <a
            href="/privacy-policy"
            target="_blank"
            style={{ color: "#798da0", textDecoration: "underline" }}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

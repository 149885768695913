import React from "react"; import { Component } from "react";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import checkHost from "utils/checkHost"

class ConnectionError extends Component {

  state = {
    submitting: false,
  }


  submit = () => {
    //console.log('submit running')
    if (this.state.submitting === false) {
      checkHost();
      this.setState({ submitting: true });

      setTimeout(() => {
        this.setState({ submitting: false });
      }, 1500);
    }
  }

  render() {
    return (
      <div className="modal-content">
        <h1>
          <span class="orange-excl">!</span>Connection Error
        </h1>
        <p>
          Unable to connect to the Secure Finance platform. Please check your
          internet connection and try again.
        </p>
        <button onClick={this.submit}>
          <ButtonSpinner loading={this.state.submitting} />
          Try Again
        </button>
      </div>
    );
  }
}

export default ConnectionError;

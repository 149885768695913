import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { NavLink } from "react-router-dom";
import Navigation from "Components/Navigation/Navigation";
import QuestionSetTwo from "./Forms/QuestionSetTwo";
import {requestSignup} from "modules/user/actions";
import { openModal } from 'modules/modals/actions';
import { createUser, _userData } from "utils/createUser"

import _isEqual from 'lodash/isEqual';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import { parseFullName } from 'parse-full-name'; 
import { isChrome } from 'utils/getDevice';
import isVerificationRequired from 'utils/isVerificationRequired';
import { settingsService } from "modules";


const $ = window.$;

let maxWindowHeight = 0;
let isScrolling = false;
let isChanging = false;
let blockSetQuestion = false;

function getWindowHeight() {
  let windowHeight = $(window).innerHeight();
  if (windowHeight < maxWindowHeight) {
    windowHeight = maxWindowHeight;
  } else if (maxWindowHeight < windowHeight) {
    maxWindowHeight = windowHeight;
  }
  return windowHeight;
}

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(Number.parseFloat(interestRate) / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = Number.parseFloat(interestRate) * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}

function scrollToPosition(start, end, lastTime, updateProgress = true) {
  let scrollTime = 300;
 isScrolling = true;
 blockSetQuestion = true;

 setTimeout(() => {
   isScrolling = false;
 }, scrollTime);

 setTimeout(() => {
   blockSetQuestion = false;
 }, scrollTime + 500);

 $([document.documentElement, document.body]).animate({
   scrollTop: end
 }, scrollTime);
 return;
}

function mapStateToProps(state) {
  return {
    form: state.form,
    user: state.user,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSignup,
    openModal,
  }, dispatch);
}

class InitialQuestionSet extends React.Component {

  constructor() {
    super();
    this.state = {
      currentQuestion: 1,
      maxQuestion: 1,
      hideOptionals: false,
    }
  }
  
  getGlobalSettings = async () => {
    const globalSettings = await settingsService.get(1);
    this.setState({ globalSettings }, () => console.log(this.state.globalSettings));
  }

  componentDidMount() {
    $('body').css('overflow-y', 'scroll');
    $('body').css('overflow-x', 'hidden');
    $('body').addClass('onboarding');

    this.getGlobalSettings();

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  componentWillUnmount() {
    $('body').removeAttr('style');
    $('body').removeClass('onboarding');
  }

  setCurrentQuestion = n => {
    console.log('XXgoing to question... ' + n);
    if (n > this.state.maxQuestion) {
      return;
    }
    if (this.state.currentQuestion !== n && !blockSetQuestion) {
      $("input").blur();
      this.setState({currentQuestion: n});
    }
  }

  goToNextQuestion() {
    if (isChanging) {
      return;
    }
    isChanging = true;
    setTimeout(() => {
      isChanging = false;
    }, 300);

    $("input").blur();

    let currentQuestion = this.state.currentQuestion;

    setTimeout(() => {
      if (currentQuestion !== this.state.currentQuestion) {
        return;
      }
      if (this.state.currentQuestion === this.state.maxQuestion) {
        this.setState((prevState) => {
          return {...prevState, maxQuestion: prevState.maxQuestion + 1}
        });
      }
      this.goToQuestion(this.state.currentQuestion + 1);
    }, 10);
  }

  skipOptional() {
    this.setState({hideOptionals: true, maxQuestion: 10}, () => {
      this.goToQuestion(10);
    })
  }

  submitOptional() {
    //console.log('interest rate submitted')
    this.setState({hideOptionals: false}, () => {
      this.goToNextQuestion();
    })
  }

  goToQuestion(n) {
    console.log('going to question... ' + n)
    let inputField = $('#n' + n + " input");
    if (inputField.length > 0) {
      let y = window.pageYOffset;      
      window.scrollTo(0, y);
      setTimeout(() => {
        inputField.focus();        
      }, 10);
    } else {
      $("input").blur();
    }

    if (n > 0) {
      let element = document.getElementById('n' + n);

      if (element) {
        let windowHeight = $(window).innerHeight();
        if (windowHeight < maxWindowHeight) {
          windowHeight = maxWindowHeight;
        } else if (maxWindowHeight < windowHeight) {
          maxWindowHeight = windowHeight;
        }
        let elementHeight;

        if (isChrome && $(element).find('input').length === 1) {
          elementHeight = element.offsetHeight;          
        } else {
          elementHeight = $(element).find('.content')[0].offsetHeight;
        }

        let position = (element.offsetTop - windowHeight/2 + elementHeight/2);
        //if (n === this.state.maxQuestion) position = window.scrollHeight;
        scrollToPosition(window.pageYOffset, position);
        console.log("setting question to:" + n);
        this.setState({currentQuestion: n});
      } else {
        this.createUser();
      }
    }
  }

  scrollToQuestion(n) {
    let inputField = $('#n' + n + " input");
    if (inputField.length > 0) {
      let y = window.pageYOffset;
      //inputField[0].focus();
      window.scrollTo(0, y);
    } else {
      $("input").blur();
    }

    if (n > 0) {
      let element = document.getElementById('n' + n);

      if (element) {
        let windowHeight = getWindowHeight();

        let elementHeight;
        if ($(element).find('input').length === 1) {
          elementHeight = element.offsetHeight;          
        } else {
          elementHeight = $(element).find('.content')[0].offsetHeight;
        }

        let position = (element.offsetTop - windowHeight/2 + elementHeight/2);
        //if (n === this.state.maxQuestion) position = window.scrollHeight;
        scrollToPosition(window.pageYOffset, position, false, false);
      }
    }
  }

  handleSubmit(results) {
    //console.log('Submit Results', results);
    $(':focus').blur();
    if (!isScrolling) {
      this.goToNextQuestion();      
    }
  }

  createUser() {

    

    const invalidForms = $('.invalid-form');
    if (invalidForms.length) {
      let windowHeight = getWindowHeight();
      const elementHeight = invalidForms.outerHeight()
      const position = (invalidForms.offset().top - windowHeight/2 + elementHeight/2);
      //console.log('invalid!', position);
      scrollToPosition(window.pageYOffset, position, false, false);
      return;
    }

    let results = {
    };
    for (let key in this.props.form) {
      if (key.indexOf("onboarding-") !== -1) {
        results = {...results, ...this.props.form[key].values};
      }
    }    

    

    {
      let { first, last } = parseFullName(results['name']);
      delete results['name'];
      if (last && !first) {
        first = last;
        last = '';
      }
      results['user-firstName'] = first;
      results['user-lastName'] = last;
    }
    
    results["user_meta-householdIncome"] = 
      results["user_meta-householdIncome"] / results["user_meta-householdIncomeType"];
    if (!results["user_meta-loanAmount"]) {
      results["user_meta-loanAmount"] = 
        results["user_meta-purchasePrice"] - results["user_meta-depositAmount"];
    }

    if (!results['user_meta-refinanceRepayments'] 
      && results['user_meta-refinanceInterestRate']
      && results['user_meta-refinanceYears']) {
        
      let monthlyRepayments;
      if (results['user_meta-refinanceRepaymentType'] === 'PandI') {
        monthlyRepayments = calculateMonthlyRepayments(
          results['user_meta-refinanceInterestRate'] / 100 / 12,
          results['user_meta-refinanceYears'] * 12,
          results['user_meta-loanAmount']
        );
      } else {
        monthlyRepayments = 
          (results['user_meta-refinanceInterestRate'] / 100 / 12) * results['user_meta-loanAmount']
      }
      results['user_meta-refinanceMonthlyRepayment'] = Number.parseInt(monthlyRepayments);
    }

    if (results['user_meta-refinanceRepayments']) {
      results['user_meta-refinanceMonthlyRepayment'] = 
        Math.round((results['user_meta-refinanceRepayments'] / results['user_meta-refinanceRepaymentsType']) / 12);
    }

    if (results['user_meta-refinanceMonthlyRepayment']) {
      results['user_meta-initialLoanDetails'] = '1';
    }

    if (results["user_meta-isOOLoan"] === "N") {
      results["user_meta-isOOLoan"] = "";
    }

    if ((this.props.location.search.indexOf('verifyMobile=0') !== -1) 
      || (this.props.location.search.indexOf('verifyMobile=false') !== -1)
      || (this.state.globalSettings && !this.state.globalSettings.sms_verification)) {
      results.mobileVerificationRequired = false;
    } else {
      if (results.phoneNumber) {
        results.mobileVerificationRequired = 
          isVerificationRequired(results.phoneNumber);
      } else {
        results['user_meta-verifyMobile'] = 1;
      }
    }

    results['user_meta-loanPurpose'] = 'refinance';
    results['user_meta-skip_email'] = 1;
    results['user_meta-entryType'] = 'main';

    if (this.props.location.search.indexOf('showEmail=true') === -1) {
      results['user_meta-noEmail'] = 1;
    }
    if (this.props.location.search.indexOf('showMobile=true') === -1) {
      results['user_meta-phoneAfter'] = 1;
    }

    results.email = this.props.form['onboarding-email'] ? 
      this.props.form['onboarding-email'].values.email : "";

    createUser(results).catch(() => { throw new Error("Error creating user"); });;
  }

  render() {
    const { maxQuestion } = this.state;

    let element = $('#n' + maxQuestion);
    let scrollHeight;
    if (element && element.offset()) {
      //console.log('max element offset:', element.offset().top)
      let windowHeight = getWindowHeight();
      var elementBottom = element.offset().top + element.outerHeight() / 2  + windowHeight / 2;

      if (window.matchMedia('(max-width: 645px)').matches) {
        elementBottom = elementBottom - 25;
      } else {
        elementBottom = elementBottom - 50;
      }

      //console.log("windowHeight:" + windowHeight + " offsetTop:" + element.offset().top + " outerHeight:" + element.outerHeight() + " windowHeight:" + windowHeight);
      //console.log("elementBottom:" + elementBottom);
      //console.log("scrollHeight:" + Math.max(windowHeight, elementBottom));


      scrollHeight = Math.max(windowHeight, elementBottom);
    } else {
      scrollHeight = $('#fullpage').innerHeight();
    }

    return(
        <div>
          <Navigation state="sidebar-navigation" />
          {/* <progress value="0" id="progressBar">
            <div className="progress-container">
              <span className="progress-bar"></span>
            </div>
          </progress> */}

          <div className="progress-container">
            <span style={{height: 0}}id="test" className="progress-bar"></span>
          </div>

          <main className="initial-question-set" id="initial-questions">

            <NavLink className="logo" exact to="/">
              <img src={require('img/brand/secure_finance.png')} alt='Securefinance logo' />
            </NavLink>

            <NavLink className="log-in-button" exact to="/login">I already have an account</NavLink>

            {/* <div className="fade-top"></div> */}
            {/* <div className="fade-bottom"></div> */}
            <div className="main-container">
              <div className="spacing" style={{height: scrollHeight}} ></div>
              <div className="scroll-area" style={{height: scrollHeight, overflow: 'hidden'}}>

                <QuestionSetTwo 
                  hideOptionals={this.state.hideOptionals}
                  setQuestion={this.setCurrentQuestion} 
                  currentQuestion={this.state.currentQuestion}
                  maxQuestion={maxQuestion} 
                  skipOptionals={() => this.skipOptional()} 
                  submitOptionals={() => this.submitOptional()} 
                  goToNextQuestion={() => this.goToNextQuestion()} 
                  gotoQuestion={n => this.scrollToQuestion(n)} 
                  continue={() => {this.createUser().catch(() => { throw new Error("Error creating user"); })}} 
                  onSubmit={this.handleSubmit.bind(this)} />
              </div>
            </div>
          </main>

        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialQuestionSet);

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, Form } from "redux-form";
import Slider from 'rc-slider';
import { change } from "redux-form";

import getInitialValuesObject from "utils/getInitialValuesObject";
import { FieldGroup } from "Components/Forms";
import updateFormValues from "modules/updateFormValues"
import 'rc-slider/assets/index.css';
import initializeFormValues from "utils/initializeFormValues";

const $ = window.$;

const selector = formValueSelector('searchFilter');
function mapStateToProps(state) {
  return {
    loanPurpose: selector(state, 'user_meta-loanPurpose'),
    isOOLoan: selector(state, 'user_meta-isOOLoan'),
    isPandILoan: selector(state, 'user_meta-isPandILoan'),
    loanAmount: selector(state, 'user_meta-loanAmount'),
    purchasePrice: selector(state, 'user_meta-purchasePrice'),
    user: state.user.meta,
    browser: state.browser,
    //initialValues: getInitialValuesObject(state),
  };
}

function mapDispatchToProps(dispatch) {
return bindActionCreators({
    change,
  }, dispatch);
}

let currentAction = null;

class LoanShortlistHeader extends Component {
  constructor(props) {
    super(props);

    this.state= {
      year: 30,
      greaterThanW480: this.props.browser.greaterThan.w480,
    };
    this.changeYear = this.changeYear.bind(this);
  }

  componentDidMount() {
    $(".loan-search-header .header-text.hover").on('click', moveBox);

    function moveBox() {
      var tooltip = $(this).find('.hover-border');
      var requiredSpacing = 20;

      var adjustPadding = 40;

      setTimeout(function() {
        var tooltip_rect = tooltip[0].getBoundingClientRect();
        console.log("Pop-up")
        console.log(tooltip_rect);

        if (tooltip_rect.x < requiredSpacing - adjustPadding) {
          tooltip.css({ left: -tooltip_rect.x + requiredSpacing + parseInt(tooltip.css("left")) - adjustPadding }); 
          tooltip.find(".content")
        }

        if ((tooltip_rect.x + tooltip_rect.width - $(window).width()) > adjustPadding - requiredSpacing ) {
          tooltip.css({ left: -(tooltip_rect.x + tooltip_rect.width - $(window).width()) + parseInt(tooltip.css("left")) + adjustPadding - requiredSpacing + 1 });
        }
        
      }, 100);
    }

    $(document).on('click.openMenu', e => {
      const $target = $(e.target);
      if ($target.hasClass('hover')) {
        if ($target.hasClass('hovering')) {
          $('.hovering').removeClass('hovering');
        } else {
          $('.hovering').removeClass('hovering');
          $target.addClass('hovering');
          $(e.target).find('input').focus();
        }
      } else if (!$target.parents('.hover').length) {
        $('.hovering').removeClass('hovering');
      }
      if ($target.attr('type') === 'radio') {
        $('.hovering').removeClass('hovering');
      }
    });

    $(document).on('keydown.openMenu', e => {
      if (e.key === 'Enter') {
        $('.hovering').removeClass('hovering');
      }
    })

    initializeFormValues('searchFilter');
  }

  componentWillUnmount() {
    //$('.header-text.hover#focus-input').off();
    // $('#slider-click').off();
    $(document).off('.openMenu')
  }

  



  changeYear(value){
    this.setState((prevState) => {return {...prevState, year: value}});
    this.props.change("user_meta-loanPeriod", value);
    // this.submitOnChange();
    // console.log(value);
  }

  submitOnChange =
    value => {}

  

  render() {
    const { user, type }  = this.props;
    
    const sliderValue = this.state.sliderClicked ? {} : {value:user.loanPeriod};

    return (
      <div className={type + "-header"}>
      
      <Form className="form" onSubmit={this.props.handleSubmit}>      
        <h5>You want to borrow</h5>

          <div className="header-text hover want-to-borrow" id='focus-input'>$
            {this.props.loanAmount ?
              user.loanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
              "?"
            }
            <div className='hover-border leftmost'>
              <div className="modal">
                <div className="content">
                  <FieldGroup width='large' id='user_meta-loanAmount' title='How much do you want to borrow?' hideOptional type='text' mask='monetary' placeholder='0' maxNumber={9999999} />     
                  {/* <br /> */}
                  {/* <input type="submit" value="Update" /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="header-text">over</div>

          <div className={"header-text hover years" + (this.state.sliderClicked ? ' hovering' : '')}>
            {user.loanPeriod} years
            <div className='hover-border'>
              <div className="modal">
                <div className="content">
                    <FieldGroup maxNumber={99} width='large' id='user_meta-loanPeriod' title='For how many years?' hideOptional type='number' mask='int' />
                    <div id='slider-click'>
                      <Slider step={1} {...sliderValue} onChange={this.changeYear} min={1} max={30} />
                    </div>
                    <ul>
                      <li>1 Year </li>
                      <li>30 Years </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          <div className="header-text">to</div>

          <div className="header-text hover property-type">
            {user.loanPurpose || "???"}
            <div className='hover-border w126'>
              <div className="modal w126">
                <div className="content">
                <ul className="select">
                  <li>
                    <label>Purchase<Field name="user_meta-loanPurpose" component="input" type="radio" value="purchase" /></label>
                  </li>
                  <li>
                    <label>Refinance<Field name="user_meta-loanPurpose" component="input" type="radio" value="refinance" /></label>
                  </li>
                  <li>
                    <label>Cash-out<Field name="user_meta-loanPurpose" component="input" type="radio" value="cash-out" /></label>
                  </li>
                  <li>
                    <label>Construct<Field name="user_meta-loanPurpose" component="input" type="radio" value="construct" /></label>
                    </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
          
        
          <div className="header-text">{user.loanPurpose === 'cash-out' ? 'against ' : ''}{(user.isOOLoan)?"a":"an"}</div>

          <div className="header-text hover purchase-type">
          {(user.isOOLoan)?"live-in":"investment"}
          <div className='hover-border w126'>
            <div className="modal w126">
              <div className="content">
                <ul className="select">
                  <li><label>Live-in<Field name="user_meta-isOOLoan" onClick={this.props.onChange} component="input" type="radio" value="Y" /></label></li>
                  <li><label>Investment<Field name="user_meta-isOOLoan" onClick={this.props.onChange} component="input" type="radio" value="" /></label></li>
                </ul>
              </div>
            </div>
          </div>
          </div>

          <div className="header-text">property worth</div>

          <div className="header-text hover property-worth" id='focus-input'>$
            {user.purchasePrice?
              user.purchasePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
              "?"
            }
          <div className='hover-border'>
            <div className="modal">
              <div className="content">
                  <FieldGroup maxNumber={9999999} width='large' id='user_meta-purchasePrice' title='How much is the property worth?' hideOptional type='text' mask='monetary' placeholder='0' /> 
                  {/* <br /> */}
                  {/* <input type="submit" value="Update" /> */}
              </div>
            </div>
          </div>
          </div>

          <div className="header-text">paying</div>

          <div className="header-text hover pay-type">
            {(user.isPandILoan)?"principal and interest":"interest only"}
            <div className='hover-border w170'>
              <div className="modal w170">
                <div className="content">
                  <ul className="select">
                    <li><label>Principal &amp; Interest<Field name="user_meta-isPandILoan" onClick={this.props.onChange} component="input" type="radio" value="Y" /></label></li>
                    <li><label>Interest Only<Field name="user_meta-isPandILoan" onClick={this.props.onChange} component="input" type="radio" value="" /></label></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Form>
        </div>
    );
  }
}

LoanShortlistHeader = reduxForm({
  form: 'searchFilter',  // a unique identifier for this form
  destroyOnUnmount: false,
  onChange: (values, dispatch, props, prev) => {
    if (Object.getOwnPropertyNames(prev).length === 0) return;
    clearTimeout(currentAction);
    currentAction = setTimeout(() => {
      if (props) props.submit();
    }, 500);
  }
})(LoanShortlistHeader);

export default connect(mapStateToProps, mapDispatchToProps)(LoanShortlistHeader);

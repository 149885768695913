import React from "react";
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector, Form } from 'redux-form';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import SectionButton from "../../component/SectionButton";
import { createRequired } from 'Components/Forms/validation';
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from 'modules/user/actions';
import { EditableParagraph } from 'Components/Forms';
import _ from 'lodash';
const $ = window.$;

const required = createRequired("Please type a message");

function mapStateToProps(state, props) {
  return {
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    text: ((state.form[props.form] || {}).values || {}).text,
    formValues: (state.form[props.form] || {}).values || {},
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class AdditionalDetails extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef()
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  save = () => {
    let values = {...this.props.formValues};
    delete values.enabled;

    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;
    if (!this.props.isForSummary) {
      toSave = {
        properties: {
          [this.props.tabId]: {
            additionalDetails: data
          }
        }
      }
    } else {
      toSave = {
        summary: {
          additionalDetails: data
        }
      }
    }

    this.props.requestPatchUser({ 
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  } 

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });
    setTimeout(() => {
      if (this.props.text === 'Add Text Here') {
        document.execCommand('selectAll');
      }
    }, 5);
    this.props.toggleEdit();
  }

  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    console.log('key entered!')
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { user, miniProposal } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    return (
      <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
        <AdditionalDetailsContainer isView={this.props.isView} active={this.props.active}>
          <Container>
            {!noGodmode && !!user.godmode && !miniProposal.previewMode &&
              <SectionButton
              removeSection={this.props.removeSection}
              enableEdit={() => this.enableEdit()}
              isEditable={this.props.active}
              cancelEdit={() => this.cancel()}
              type="section" style={{ float: 'right', position: 'absolute', top: '0', right: '0' }} 
              cancelSaveContainer="0px"
              />
            }
            <h2><Span name='title' as={EditableContent} disabled={!this.props.active} onEnter={this.blurOnEnter}/></h2>
            <p><EditableParagraph richText={!!this.props.active} innerRef={this.inputRef} onKeyDown={e => this.onKeyDown(e)} allowLineBreaks className={this.props.active ? 'active' : ''} name='text' disabled={!this.props.active}>{this.props.details}</EditableParagraph></p>
          </Container>
        </AdditionalDetailsContainer>
      </Form>
    )
  }
}

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50px;

  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;

    &:focus {
      border-color: #3F70F2;
    }
  `}
`;

const Container = styled.div`
  margin-bottom: 30px;

  @media(max-width: 645px) {
    margin-bottom: 15px;
  }
`;

const AdditionalDetailsContainer = styled.div`
  position: relative;
  transition: max-height 0.2s;
  max-height: 1000px;

  ${props => props.active && css`
    z-index:  500;
  `}

  ${props => !props.isView && css`
    max-height: 0;
    overflow: hidden;
  `}
`;

export default reduxForm({
  //form: 'additional_details',
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(AdditionalDetails));

import {
  REQUEST_PATCH_USER,
  RECEIVE_PATCH_USER,
  RECEIVE_AUTH,
  RECEIVE_LOGOUT,
  RECEIVE_ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST,
  RECEIVE_NEW_APPLICATION,
  RECEIVE_PATCH_APPLICATION,
  RECEIVE_ADD_PRODUCT_TO_APPLICATION,
  RECEIVE_REMOVE_PRODUCT_FROM_APPLICATION,
  RECEIVE_CREATE_FILE,
  RECEIVE_PATCH_FILE,
  RECEIVE_REMOVE_FILE,
  RECEIVE_SELF,
  PATCH_ENDED,
  RECEIVE_CREATE_REFERRAL,
  ADD_NOTE_TO_REFERRAL,
} from './actions';

import _ from 'lodash';
import moment from 'moment';
import { PATCH_REFERRAL } from 'modules/application/actions';
// eslint-disable-next-line

function mergeObjects(o1,o2) {
  //console.log('Objects to be merged:', o1, o2);

  if (o2?.__editArray) {
    let out = [...o1];
    if (o2.push) {
      out = [...out, o2.push[0]];
    }
    if (o2.remove) {
      out = out.filter(x => x !== o2.remove[0]);
    }
    if (o2.swap) {
      if (o2.swap.length > out.length) return out;
      out = out.map((_, index) => out[o2.swap[index] ?? index]);
    }
    console.log(out);
    return out;
  }

  if (o1 === undefined) { 
    return o2;
  }
  if (o2 === undefined) { 
    return o1;
  }
  if (typeof o2 !== "object" || o2 === null) {
    return o2;
  }

  if (Array.isArray(o1)) {
    if (!o1.length) { 
      return o2;
    }
    // If array objects have ids merge objects with the same id 
    if (o1[0].id !== undefined) {
      let ids = new Set([
        ...o1.map(o => o.id), 
        ...o2.map(o => o.id)
      ]);
      ids = [...ids].filter(id => id !== undefined);
      return ids.map(id => 
        mergeObjects(
          o1.find(o => o.id === id), 
          o2.find(o => o.id === id)
        )
      );
    } else {
      return o2;
    }
  }

  if (!Object.keys(o1).length) { 
    return o2;
  }
  if (!Object.keys(o2).length) { 
    return o1;
  }

  const keys = Object.keys(o1).concat(Object.keys(o2).filter(function (item) {
      return Object.keys(o1).indexOf(item) < 0;
  }));

  let ret = {};
  for (let key of keys) {       
    ret[key] = mergeObjects(o1[key], o2[key]);
  }
  return ret;
}

function processUserMeta(_newData, _currentData) {
  //console.log('processing user meta...', _newData);
  let newData = _newData;
  let currentData = _currentData;

  // convert any json arrays back to arrays in user_meta and partner_meta
  // the string is identified by '**array' at its beginning eg. "**array[1, 2, 3]" 
  // for (const key in newData.meta) {
  //   if (typeof newData.meta[key] === 'string' && newData.meta[key].includes('**array')) {
  //     const result = JSON.parse(newData.meta[key].replace('**array', ''));
  //     if (result) newData.meta[key] = result;
  //   }
  // }


  if (newData.partner) {
    let id = newData.partner.id;
    if (!id) {
      if (currentData.partners && currentData.partners[0]) {
        id = currentData.partners[0].id;
        if (!id) {
          //setting ids to 'deadbeef' (or anything) will ensure they are merged if for some reason they don't have ids
          currentData.partners[0].id = id = 'deadbeef';
        }
      }
    }

    newData.partners = [];
    newData.partners[0] = { ...newData.partner, id };
    delete newData.partner;
  }

  // for (const partner of newData.partners) {
  //   for (const key in partner) {
  //     if (typeof partner[key] === 'string' && partner[key].includes('**array')) {
  //       const result = JSON.parse(partner[key].replace('**array', ''));
  //       if (result) partner[key] = result;
  //     }
  //   }
  // }

  if (newData.application) {
    newData.applications = [];
    newData.applications[0] = newData.application;
    delete newData.application;
  }

  if (newData.meta && (!currentData.meta || !currentData.meta.loanAmount)
        && !newData.meta.loanAmount 
        && (newData.meta.purchasePrice || newData.meta.depositAmount)) {
    let purchasePrice = (newData.meta.purchasePrice || !currentData.meta)?newData.meta.purchasePrice:currentData.meta.purchasePrice;
    let depositAmount = (newData.meta.depositAmount || !currentData.meta)?newData.meta.depositAmount:currentData.meta.depositAmount;
    newData.meta.loanAmount = purchasePrice - depositAmount;
  }

  const result = mergeObjects(currentData, newData);
  if (result.partners && result.partners[0] && result.partners[0].id === 'deadbeef') {
    result.partners[0].id = undefined;
  }
  //console.log(result);
  return result;
}

export const user = (state = {}, action) => {
  switch(action.type) {
    case RECEIVE_AUTH: {
      let user = processUserMeta(action.payload, state);
      const godmodeValid = parseInt(window.localStorage.getItem('godmodeId')) === action.payload.id;
      user.godmode = godmodeValid ? user.godmode : '';
      return {...user, verifyFinished: state.verifyFinished};
    }
    case RECEIVE_SELF: {
      let user = processUserMeta(action.payload, {});
      user.godmode = state.godmode;
      user.accessToken = state.accessToken;
      user.meta.proposalActiveTab = state.meta?.proposalActiveTab;
      return user;
    }
    case RECEIVE_LOGOUT: {
      return { verifyFinished: state.verifyFinished }
    }
    case REQUEST_PATCH_USER: {
      return { ...state, isSaving: true };
    }
    case PATCH_ENDED: {
      return { ...state, isSaving: false };
    }
    case RECEIVE_PATCH_USER: {
      let newData = processUserMeta(action.payload, state);
      newData = removeDeletedItems(newData);
      const godmodeValid = window.localStorage.getItem('godmodeEmail') === newData.email;
      //console.log(newData);
      delete newData.password;
      delete newData.currentPassword;
      return {...newData };
    }
    case RECEIVE_ADD_TO_SHORTLIST: {
      return {...state, shortlist: [...state.shortlist, action.payload]};
    }
    case REMOVE_FROM_SHORTLIST: {

      return {
        ...state, 
        meta: {
          ...state.meta,
          lastRemovedProduct: state.shortlist.find(item=>item.id === action.payload.data),
        },
        shortlist: state.shortlist.filter(item=>item.id !== action.payload.data)
      };
    }
    case RECEIVE_NEW_APPLICATION:
    case RECEIVE_PATCH_APPLICATION: {
      return {...state, applications: [...state.applications, action.payload]};
    }
    case ADD_NOTE_TO_REFERRAL: {
      const applications = state.referredApplications;
      const note = action.payload.note;
      const id = action.payload.id;

      const oldApp = applications.find(x => x.id === id);
      const newApp = { ...oldApp, notes: [ ...oldApp.notes, { ...note, date: moment() } ]};
      const newApplications = [ ...applications.filter(x => x.id !== id), newApp ];

      return { ...state, referredApplications: newApplications };
    }
    case RECEIVE_ADD_PRODUCT_TO_APPLICATION: {
      let applications = state.applications;
      applications[0] = {...applications[0], products: [...applications[0].products, action.payload.product]};      
      return {...state, applications};
    }
    case RECEIVE_REMOVE_PRODUCT_FROM_APPLICATION: {
      const { meta } = state;
      let applications = state.applications;
      const removedProduct = applications[0].products.find(item=>item.id === action.payload.product_id);
      applications[0] = {...applications[0], products: applications[0].products.filter(item=>item.id !== action.payload.product_id)};      
      return {
        ...state, 
        applications,
        meta: {
          ...meta,
          lastRemovedProduct: removedProduct,
        },
      };
    }
    case RECEIVE_CREATE_FILE: {
      return {...state, uploads: [...state.uploads, action.payload]};
    }
    case RECEIVE_PATCH_FILE: {
      let uploads = [...state.uploads];
      for (let i = 0; i < uploads.length; i++) {
        if (uploads[i].name === action.payload.name) {
          uploads[i] = mergeObjects(uploads[i], action.payload);
        }
      }      
      return {...state, uploads};
    }
    case RECEIVE_REMOVE_FILE: {
      let uploads = state.uploads.filter(item=>item.id !== action.payload.id && item.name !== action.payload.name);
      return {...state, uploads};
    }
    case RECEIVE_CREATE_REFERRAL: {
      if (!action.payload.application) return state;

      let newApplication = { ...action.payload.application };
      newApplication.stage = 'Referral submitted';
      newApplication.updatedAt = moment().format();

      let applications = [ ...(state.referredApplications ?? []), newApplication ];
        
      return { ...state, referredApplications: applications };
    }
    default : {
      return state;
    }
  }
};

const removeDeletedItems = _item => {
  let item = _.cloneDeep(_item);
  for (let key in item) {
    if (item[key] === 'DELETE_ITEM') delete item[key];
    if (_.isPlainObject(item[key])) item[key] = removeDeletedItems(item[key]);
  }
  return item;
};
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ForgotPasswordComponent from 'Views/Accounts/Components/ForgotPasswordComponent';


class ForgetPassword extends Component {
  render() {
    if (this.props.currentModal === "forgetPassword") {
    return (
      <div className="modal-content">
        <ForgotPasswordComponent />

        <div className="navigation back">
          <a onClick={()=>this.props.openModal("signin")}>
          ← Back to Sign In
          </a>
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default ForgetPassword;
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";

import {openModal} from "modules/modals/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal
  }, dispatch);
}

class ShowModal extends React.Component {

  render() {
    this.props.openModal(this.props.modal);
    let redirect = this.props.redirect;
    if (redirect) {
      redirect = "?redirect=" + redirect;
    }
    return(
      <Redirect to={"/search-loans" + redirect} />
    )
  }

}

export default connect(null, mapDispatchToProps)(ShowModal);

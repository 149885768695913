import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from "redux-form";
import styled, { css } from 'styled-components';

import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';

import { Input, FormContainer } from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import imgDanger from 'img/elements/exc-circle-red.png';

const FORM_NAME = 'objective-list-modal';

let Form = ({ submitting, handleSubmit, objectives }) => {

  const objectivesIntro = useSelector(state => state.user.globalSettings.overview_objectives_body);
  const userId = useSelector(state => state.user.id);
  const [ needsConfirmation, setNeedsConfirmation ] = useState(false);
  const dispatch = useDispatch(); 
    
  const onSubmit = values => {
      
    const listItems = objectives
      .filter((_, index) => values.objectives[index])
      .map(x => x.text);

    let htmlList = `<ul>`;
    for (const item of listItems) {
      htmlList += `<li>${item}</li>`;
    }
    htmlList += `</ul>`;

    const generatedHtml = objectivesIntro + htmlList;
    
    console.log(generatedHtml)
    const patch = {
        overview: {
          objectives: {
            body: generatedHtml,
            objectivesSet: true,
            objectiveSwitches: values.objectives,
          }
        }
    };

    dispatch(requestPatchUser({ 
        userId: userId,
        params: {
          mergeMeta: true,
        },
        data: { 
          meta: { 
            miniproposal: patch,
          } 
        },
    }));

    dispatch(closeModal());
  }

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} className="form your-details">
      <Section>
      {/* <Heading>Objectives</Heading> */}
      {objectives.map((objective , index) => (
        <Switch>
          <Field name={`objectives[${index}]`} type="checkbox" component={Input} />
          <SwitchLabel>{objective.name}</SwitchLabel>
        </Switch>
      ))}
    </Section>
    <ButtonContainer>
        {needsConfirmation ? <>
            <ConfirmationQuestion>This will wipe all edits. Are you sure?</ConfirmationQuestion>
            <button type='submit' className="analyse"><ButtonSpinner loading={submitting}/>Yes</button>
          </> : <button type='button' className="analyse" onClick={() => setNeedsConfirmation(true)}><ButtonSpinner loading={submitting}/>Ok</button>
        }
        <button type='button' className="analyse" onClick={() => dispatch(closeModal())} style={{backgroundColor: '#FC5256'}}>Cancel</button>
      </ButtonContainer>
    </FormContainer>
  )
}


export default reduxForm({
    form: FORM_NAME,
})(Form);

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 75px;

  button, button {
    position: relative;
    width: 46% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const ConfirmationQuestion = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #FC535A !important;
  font-weight: 600;
  border: solid 1px #FC535A;
  padding-top: 13px;
  padding-left: 35px;
  border-radius: 4px;
  background-color: #FFEAEB;
  position: absolute;
  top: -43px;
  left: 0px;
  color: black;
  background-image: url(${imgDanger});
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 14px 14px;
`;

const SwitchLabel = styled.span`
  position: relative;
  top: -3px;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 40px;  
`;

const Switch = styled.span`
    display: block;
    margin-bottom: 20px;

    input[type="checkbox"] {
    font-size: 30px; 
    appearance: none;
    width: 40px !important; 
    height: 20px !important; 
    background: #ddd;
    border-radius: 3em !important; 
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;

    &:checked {
      background: #26BD27;
    }

    &:after {
      position: absolute;
      content: "";
      width: 20px; 
      height: 20px; 
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0, 0, 0, .3); 
      transform: scale(.7); 
      left: 0;
      transition: all .2s ease-in-out;
    }

    &:checked:after {
      left: calc(100% - 20px);
    }
  }
`;
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

import { _userData } from "utils/createUser"

class LoginAlreadyHaveAccount extends Component {

  render() {
    if (this.props.currentModal === "loginAlreadyHaveAccount") {
    return (
      <div className="modal-content">
        <h1>An Account Already Exists</h1>
        <p>
          There is an existing Secure Finance account with the e-mail address
          <br />
          <strong>{_userData.email}</strong>
        </p>

        <br />
        <p>
          You can go to the sign in page using the button below.
          <br />
          Alternatively, you can sign up using a new e-mail address.
        </p>
        <div className="modal-navigation">
          <button
            onClick={() => this.props.openModal("newEmail")}
            className="double left button-5"
          >
            Create New Account
          </button>
          <LinkButton
            to="/login"
            onClick={() => this.props.closeModal()}
            className="double right button-1"
          >
            Sign In
          </LinkButton>
        </div>
      </div>
    ); } else {
      return "";
    }
  }
}

export default LoginAlreadyHaveAccount;
function intToOrdinal(n) {
  
    const a = n % 10;
    const b = n % 100;
  
    if (a === 1 && b !== 11)
      return `${n}st`;
    
    if (a === 2 && b !== 12)
      return `${n}nd`;
  
    if (a === 3 && b != 13)
      return `${n}rd`;
  
    return `${n}th`;
  }
  
  export default intToOrdinal;
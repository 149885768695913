import React from "react"; import { Component } from "react";

class CheckEligibilityFull extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityFull") {
    return (
      <div className="modal-content">
        <h1>You cannot select any more loans</h1>
        <p>You can only select a maximum of 3 loan products for the loan qualifier. Please get in touch if you want some assistance.</p>

        <div className="request-assistance">
          <div className="request-assistance-content">
            <h4>Want us to do the heavy lifting?</h4>
            <p>We're always here and happy to help <br/>you select a product, and apply for a loan.</p>
          </div>
          <button onClick={()=>this.props.openModal("checkEligibilityHelp")}>Request Assistance</button>
        </div>

      </div>
    ) } else {
      return "";
    }
  }
}

export default CheckEligibilityFull;
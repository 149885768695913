import React from "react"; import { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form';
import LinkButton from "Components/LinkButton";
import Tooltips from "Components/Tooltips";

import { FieldGroup, FormContainer, SelectGroup, RadioToggleGroup, CheckBoxGroup, MultiSelectGroup } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import initializeFormValues from "utils/initializeFormValues";

let currentAction = null;

class LoanSearchFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterShown: false,
      majorOnly: false,
      loanAmountOverlay: false,
      borrowingCapacityOverlay: false,
    };
  }

  toggleFilterPopup() {
    this.setState((prevState) => { return { ...prevState, isFilterShown: !prevState.isFilterShown } });
  }

  toggleLoanAmountOverlay() {
    this.setState((prevState) => { return { ...prevState, loanAmountOverlay: !prevState.loanAmountOverlay } });
  }

  removeLoanAmountOverlay() {
    if (this.state.loanAmountOverlay) 
      this.setState((prevState) => { return { ...prevState, loanAmountOverlay: false } });
  }

  toggleBorrowingCapacityOverlay() {
    this.setState((prevState) => { return { ...prevState, borrowingCapacityOverlay: !prevState.borrowingCapacityOverlay } });
  }

  removeBorrowingCapacityOverlay() {
    if (this.state.borrowingCapacityOverlay)
    this.setState((prevState) => { return { ...prevState, borrowingCapacityOverlay: false } });
  }

  // Select reset fields
  changeLoanPurposeValue() {
    this.props.change("xxxuser_meta-searchFilter_loanPurpose", "");
  }

  changeBankValue() {
    this.props.change("user_meta-searchFilter_bank", ['ALL_LENDERS']);
  }

  changeRepaymentValue() {
    this.props.change("user_meta-searchFilter_repaymentFrequency", "");
  }

  changeNicheLendingValue() {
    this.props.change("xxxuser_meta-searchFilter_nicheLending", "");
  }

  clearRequiredFeatures = () =>
    this.clearFields([
      'user_meta-searchFilter_bridging_loan',
      'user_meta-searchFilter_extra_repayments_allowed',
      'user_meta-searchFilter_interest_in_advance',
      'user_meta-searchFilter_lockedRate',
      'user_meta-searchFilter_full_offset',
      'user_meta-searchFilter_partial_offset',
      'user_meta-searchFilter_package',
      'user_meta-searchFilter_portable',
      'user_meta-searchFilter_redraw_allowed',
      'user_meta-searchFilter_rebate',
    ]);

  clearLoanTypes = () =>
    this.clearFields([
      'user_meta-searchFilter_company',
      'user_meta-searchFilter_loDoc',
      'user_meta-searchFilter_ownerBuilder',
      'user_meta-searchFilter_vacant',
      'user_meta-searchFilter_is_smsf'
    ]);


  clearFields = fields => {
    for (const f of fields)
      this.props.change(f, '');
  }

  lenderFilterChanged = (value, oldValue) => {
    let length;
    if ((value || []).every(v => v !== 'MAJOR_ONLY')) {
      this.setState({ majorOnly: false });
    }
    if (!value || !value.length) return ['ALL_LENDERS']
    if (length = (value || []).length) {

      if (value[length - 1] === 'MAJOR_ONLY') {
        if (length === 1) {
          this.setState({ majorOnly: false });
          return ['ALL_LENDERS'];
        }
        this.setState({ majorOnly: true });
        if (!this.state.majorOnly)
          return ['MAJOR_ONLY', 'ANZ', 'CBA', 'HSL', 'WES'];
      }
      if (value[length - 1] === 'ALL_LENDERS') {
        this.setState({ majorOnly: false });
        return ['ALL_LENDERS'];
      }
      if (value.some(v => v === 'ALL_LENDERS')) {
        return value.filter(v => v !== 'ALL_LENDERS');
      }
    }

    return value;
  }

  componentDidMount() {
    let $ = window.$;

    let loanAmountOverlayClicked = false;
    let borrowingCapacityClicked = false;

    $('.loan-amount-overlay').on('click', () => {
      loanAmountOverlayClicked = true;
      //console.log("inside")
    });

    $('.borrowing-capacity-overlay').on('click', () => {
      borrowingCapacityClicked = true;
    });

    $('.loan-amount').on('click', () => {
      loanAmountOverlayClicked = true;
    });

    $('.borrowing-capacity').on('click', () => {
      borrowingCapacityClicked = true;
    });

    $('body').on('click', () => {
      if (!loanAmountOverlayClicked) {
        this.removeLoanAmountOverlay();
      }
      if (!borrowingCapacityClicked) {
        this.removeBorrowingCapacityOverlay();
      }
      //console.log("any click")
      loanAmountOverlayClicked = false;
      borrowingCapacityClicked = false;
    });

    initializeFormValues('searchFilter', (values, state) => ({
      'user_meta-searchFilter_bank': ['ALL_LENDERS'],
      'user_meta-searchFilter_initial_ir_type': 'any',
      ...values,
    }));
  }

  render() {
    let lenderFilterOptions = [
      { name: 'Show All Lenders', value: 'ALL_LENDERS' },
      { name: 'Major Lenders Only', value: 'MAJOR_ONLY' },
      { name: 'ANZ', value: 'ANZ' },
      { name: 'CBA', value: 'CBA' },
      { name: 'NAB', value: 'HSL' },
      { name: 'Westpac', value: 'WES' },
    ]
    const otherLenders = (this.props.lenders || [])
      .filter(l => lenderFilterOptions.every(o => o.value !== l.id))
      .filter(l => l.accredited)
      .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
      .map(l => ({ name: l.name, value: l.id }));

    if (!this.state.majorOnly)
      lenderFilterOptions = [...lenderFilterOptions, ...otherLenders];

    let condensedLenders = [];
    if (this.props.lenderFilter) {
      condensedLenders = this.props.lenderFilter
        .filter(b => b !== 'ALL_LENDERS' && b !== 'MAJOR_ONLY');
    }
    condensedLenders = condensedLenders.length ? condensedLenders : null;

    let lvr = this.props.loanAmount / this.props.purchasePrice * 100;
    let lvrClass = "";
    if (lvr > 80) {
      lvrClass = "warning";
    }
    if (!lvr || !this.props.purchasePrice) {
      lvr = "-"
    } else {
      lvr = Math.min(100, lvr).toFixed(0);
    }

    return (

      <div className={"search-filter " + (this.state.isFilterShown ? "open" : "")}>
        <FormContainer onSubmit={this.props.handleSubmit}>

          <div className="w140 ls search-loanAmount">
            <label>Loan Details</label>

            <div className={"header-text hover loan-amount " + (this.state.loanAmountOverlay ? "selected" : "")} id='focus-input' onClick={() => this.toggleLoanAmountOverlay()}>$
                {this.props.loanAmount ?
                this.props.loanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                "?"
              }
            </div>

            <div className='loan-amount-overlay' style={{
              opacity: ((this.state.loanAmountOverlay) ? "1" : "0"),
              pointerEvents: ((this.state.loanAmountOverlay) ? "all" : "none"),
              visibility: ((this.state.loanAmountOverlay) ? "visible" : "hidden"),
            }}>
              <div className="modal">
                <div className="content">
                  <FieldGroup id='user_meta-loanAmount' title='Loan Amount' hideOptional type='text' mask='monetary' placeholder='0' maxNumber={9999999} />
                  <FieldGroup id='user_meta-purchasePrice' title='Purchase Price' hideOptional type='text' mask='monetary' placeholder='0' maxNumber={9999999} />
                  <div className={"lvr " + lvrClass}>
                    <label>LVR</label>
                    <div className="value">{lvr}%{(lvr > 80) ? <Tooltips type='definition' term='lVRHigh' /> : <Tooltips type='definition' term='lVR' />} {(lvr > 80) ? <span className="warning">Search results may be limited</span> : ""}</div>
                  </div>
                  <FieldGroup maxNumber={99} id='user_meta-loanPeriod' title='Loan Term' required={false} type='number' mask='int' />
                  <SelectGroup empty class="search-loanPurpose" empty="false" id='user_meta-loanPurpose' width='100%' position='left' title='Loan Purpose' required={false} type='select' values={[{ text: 'Purchase', value: 'purchase' }, { text: 'Refinance', value: 'refinance' }, { text: 'Cash-out', value: 'cash-out' }, { text: 'Construct', value: 'construct' }]} />
                </div>
              </div>
            </div>
          </div>
          {this.props.browser.greaterThan.w840 &&
            <RadioToggleGroup class="search-purpose" id='user_meta-isOOLoan' width='188' position='left' title='Property Purpose' required={false} placeholder='' checked='To live in' unchecked='Investment' />
          }
          {this.props.browser.greaterThan.w840 &&
            <RadioToggleGroup class="search-repaymentType" id='user_meta-isPandILoan' style={{width: '263px'}} width='small' position='left' title='Repayment Type' required={false} placeholder='' checked='Principal &amp; Interest' unchecked='Interest Only' />
          }
          <SelectGroup empty="false" class="search-rateType" id='user_meta-searchFilter_initial_ir_type' style={{ width: '130px'}} position='left' title='Rate Type' required={false} type='select' values={[{ value: 'any', text: 'Any' }, { value: 'V', text: 'Variable' }, { value: 'F', text: 'Fixed' }, { value: 'IV', text: 'Intro Variable' }]} />
          {this.props.browser.greaterThan.w840 &&
            <div className="w135 ls search-borrowingPower">
              <label>Borrowing Capacity</label>
              <div className={"header-text hover borrowing-capacity " + (this.state.borrowingCapacityOverlay ? "selected" : "")} onClick={() => this.toggleBorrowingCapacityOverlay()}>
                <span className="overflow-span">
                  {(this.props.borrowingPower && this.props.borrowingPower.high) ? (
                    ((this.props.borrowingPowerActive) ?
                      ("$" + this.props.borrowingPower.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                      :
                      (["$" + this.props.borrowingPower.high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      <span className="orange-excl">!</span>
                      ])
                    )
                  ) :
                    ([<span className="small-orange">Details needed</span>,
                    <span className="orange-excl">!</span>])
                  }
                </span>
              </div>

              <div className='borrowing-capacity-overlay' style={{
                opacity: ((this.state.borrowingCapacityOverlay) ? "1" : "0"),
                pointerEvents: ((this.state.borrowingCapacityOverlay) ? "all" : "none"),
                visibility: ((this.state.borrowingCapacityOverlay) ? "visible" : "hidden"),
              }}>
                <div className="modal">
                  {this.props.borrowingPower ? (
                    <div className="content">
                      <h2>Your Borrowing Capacity is</h2>
                      <div className="value"><span className="number">${(this.props.borrowingPower.low || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><span className="to"> to </span><span className="number">${(this.props.borrowingPower.high || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></div>
                      {this.props.borrowingPowerActive ? (
                        <div className="additional-info small">This is based on data you entered into<br />the borrowing power calculator.</div>
                      ) : (
                          <div className="additional-info orange"><span className="orange-excl">!</span>This is a rough approximation</div>
                        )}
                      <LinkButton to="/calculators/borrowing-power">{
                        this.props.borrowingPowerActive ? "Calculate borrowing power" : "Improve Accuracy"
                      }</LinkButton>
                      <div className="mandatories">
                        Results should only be used as a rough guide as we make a number of assumptions for these calculations. Lender credit policy may also change at any time. Please contact us for a personalised assessment.
                        </div>
                    </div>
                  ) : (
                      <div className="content">
                        <h2>We need details to calculate your borrowing capacity.</h2>
                        <div className="additional-info blue">The more information you enter, the more accurate the calculation will be.</div>
                        <LinkButton to="/calculators/borrowing-power">Go to Calculator</LinkButton>
                        <div className="mandatories">
                          Results should only be used as a rough guide as we make a number of assumptions for these calculations. Lender credit policy may also change at any time. Please contact us for a personalised assessment.
                        </div>
                      </div>
                    )}
                </div>
              </div>

            </div>
          }
          <a className={"filters " + (this.state.isFilterShown ? "shown" : "")} onClick={() => this.toggleFilterPopup()} ><span>{this.props.browser.greaterThan.w480?"More Filters":"Filters"}</span></a>

          {!this.props.browser.greaterThan.w840 && this.state.isFilterShown &&
            <>
              <RadioToggleGroup class="search-purpose" id='user_meta-isOOLoan' width='188' position='left' title='Purpose' required={false} placeholder='' checked='To live in' unchecked='Investment' />
              <RadioToggleGroup class="search-repaymentType" id='user_meta-isPandILoan' width='small' position='left' title='Repayment Type' required={false} placeholder='' checked='Principal &amp; Interest' unchecked='Interest Only' />
            </>
          }

          <div className={"additional-filters " + (this.state.isFilterShown ? " " : "condensed ")}>



            <div className={"required-features " + ((this.state.isFilterShown || this.props.bridgingLoan || this.props.extraRepayments || this.props.interestInAdvice || this.props.loanIncrease || this.props.lockedRate || this.props.fullOffset || this.props.partialOffset || this.props.package || this.props.portability || this.props.redrawAllowed || this.props.rebate || this.props.splitting) ? "" : "disable")}>

              {(this.state.isFilterShown || this.props.bridgingLoan || this.props.extraRepayments || this.props.interestInAdvice || this.props.loanIncrease || this.props.lockedRate || this.props.fullOffset || this.props.partialOffset || this.props.package || this.props.portability || this.props.redrawAllowed || this.props.rebate || this.props.splitting)
                ?
                <h5>Required features<span onClick={this.clearRequiredFeatures}>Unselect All</span></h5>
                :
                ""}

              <div className="checkbox-container">
                <CheckBoxGroup id='user_meta-searchFilter_bridging_loan' title='Bridging' />
                <CheckBoxGroup id='user_meta-searchFilter_extra_repayments_allowed' title='Extra Repayments' />
                <CheckBoxGroup id='user_meta-searchFilter_interest_in_advance' title='Interest In Advance' />
                <CheckBoxGroup id='user_meta-searchFilter_lockedRate' title='Locked Rate' />
                <CheckBoxGroup id='user_meta-searchFilter_full_offset' title='Offset' />
                <CheckBoxGroup id='user_meta-searchFilter_partial_offset' title='Partial Offset' />
                <CheckBoxGroup id='user_meta-searchFilter_package' title='Package' />
                <CheckBoxGroup id='user_meta-searchFilter_portable' title='Portability' />
                <CheckBoxGroup id='user_meta-searchFilter_redraw_allowed' title='Redraw' />
                <CheckBoxGroup id='user_meta-searchFilter_rebate' title='Rebate' />
              </div>
            </div>

            <div className={"required-loan-types " + ((this.state.isFilterShown || this.props.company || this.props.loDoc || this.props.ownerBuilder || this.props.vacant || this.props.smsf) ? "" : "disable")}>
              {(this.state.isFilterShown || this.props.company || this.props.loDoc || this.props.ownerBuilder || this.props.vacant || this.props.smsf)
                ?
                <h5>Required Loan Types<span onClick={this.clearLoanTypes}>Unselect All</span></h5>
                :
                ""
              }
              <div className="checkbox-container">
                <CheckBoxGroup id='user_meta-searchFilter_company' title='Company' />
                <CheckBoxGroup id='user_meta-searchFilter_loDoc' title='Lo Doc' />
                <CheckBoxGroup id='user_meta-searchFilter_ownerBuilder' title='Owner Builder' />
                <CheckBoxGroup id='user_meta-searchFilter_vacant' title='Vacant' />
                <CheckBoxGroup id='user_meta-searchFilter_is_smsf' title='SMSF' />
              </div>
            </div>

            <div className="misc-filters">
              {(this.props.loanPurpose || this.props.nicheLending || this.props.lenderFilter && !(this.props.lenderFilter.length === 1 && this.props.lenderFilter[0] === 'ALL_LENDERS')) ?
                <h5>Other Filters</h5>
                :
                ""
              }
              <div className="checkbox-container">
                {/* {toggleSelectCheckbox("loan-purpose", "Loan Purpose: ", this.props.loanPurpose, this)} */}
                {toggleSelectCheckbox("lender-filter", "Lender Filter: ", (condensedLenders || []).join(', '), this)}
                {/* {toggleSelectCheckbox("repayment-frequency", "Repayment Frequency: ", this.props.repaymentFrequency, this)} */}
                {/* {toggleSelectCheckbox("niche-lending", "Niche Lending: ", this.props.nicheLending, this)} */}
              </div>

              <SelectGroup empty class="search-loanPurpose" empty="false" id='user_meta-loanPurpose' width='small' position='left' title='Loan Purpose' required={false} type='select' 
                values={[
                  { text: 'Purchase', value: 'purchase' }, 
                  { text: 'Refinance', value: 'refinance' }, 
                  { text: 'Cash-out', value: 'cash-out' }, 
                  { text: 'Construct', value: 'construct' }
                ]} />

              <MultiSelectGroup width={250} normalize={this.lenderFilterChanged} id='user_meta-searchFilter_bank' position='left' title='Lender Filter' required={false} type='select' placeholder='Show All Lenders'
                // values={[{value:"", name:'Show All Lenders'}, {value:"CBA", name:"CBA"}, {value:"HSL", name:"NAB"}, {value:"WES", name:"Westpac"}, , {value:"ANZ", name:"ANZ"}]} 
                values={lenderFilterOptions}
              />
              <SelectGroup class="search-repaymentFrequency" empty="false" id='user_meta-searchFilter_repaymentFrequency' width='small' position='left' title='Repayment Frequency' required={false} type='select' placeholder='Fortnightly'
                values={[
                  // {value: "", text: 'All Frequencies'}, 
                  { value: 'monthly', text: 'Monthly' },
                  { value: 'fortnightly', text: 'Fortnightly' },
                  { value: 'weekly', text: 'Weekly' },
                ]} />
              {/* <SelectGroup class="search-nicheLending" empty="false" id='xxxuser_meta-searchFilter_nicheLending' width='small' position='left' title='Niche Lending' required={false} type='select' placeholder='' values={['All Lendings', 'Accountant, Engineer, Foreign Income']} /> */}

              <SelectGroup style={{ width: '150px', display: ((this.props.irType === 'any' || this.props.irType === 'F') && this.state.isFilterShown) ? 'inline-block' : 'none'}} class="search-fixedTerm" empty="false" id='user_meta-searchFilter_fixed_term' width='small' position='left' title='Fixed Term' required={false} type='select'
                values={[
                  // {value: "", text: 'All Frequencies'}, 
                  { value: 'any', text: 'Any' },
                  { value: '1', text: '1 year' },
                  { value: '2', text: '2 year' },
                  { value: '3', text: '3 year' },
                  { value: '4', text: '4 year' },
                  { value: '5', text: '5 year' },
                  { value: '5+', text: '> 5 year' },
                ]} />

              
              
              {/* <button className="update-filters">Update Filters</button> */}
            </div>
          </div>
        </FormContainer>
      </div>
    );
  }
}




function toggleSelectCheckbox(type, name, state, that) {
  if (state) {
    switch (type) {
      case "loan-purpose": return <button className="condensed-button active" onClick={() => that.changeLoanPurposeValue()}>{name + state}</button>;
      case "lender-filter": return <button className="condensed-button active" onClick={() => that.changeBankValue()}>{name + state}</button>;
      case "repayment-frequency": return <button className="condensed-button active" onClick={() => that.changeRepaymentValue()}>{name + state}</button>;
      case "niche-lending": return <button className="condensed-button active" onClick={() => that.changeNicheLendingValue()}>{name + state}</button>;
      default: return "DEFAULT"
    }
  }
  else {
    return <button className="disabled">Disabled</button>;
  }
}

LoanSearchFilter = reduxForm({
  form: 'searchFilter',  // a unique identifier for this form
  destroyOnUnmount: false,
  onChange: (values, dispatch, props, prev) => {
    if (Object.getOwnPropertyNames(prev).length === 0) return;
    clearTimeout(currentAction);
    currentAction = setTimeout(() => {
      props.submit();
    }, 300);
  }
})(LoanSearchFilter);

const selector = formValueSelector('searchFilter') // <-- same as form name
LoanSearchFilter = connect(
  state => {
    //console.log(state);
    // can select values individually
    const loanAmount = selector(state, 'user_meta-loanAmount');
    const purchasePrice = selector(state, 'user_meta-purchasePrice');
    const loanPeriod = selector(state, 'user_meta-loanPeriod');
    const loanMainPurpose = selector(state, 'user_meta-loanPurpose');

    const loanPurpose = selector(state, 'user_meta-searchFilter_loanPurpose');
    const loanPurposeToggle = selector(state, 'xxxuser_meta-searchFilter_loanPurpose_toggle');
    const lenderFilter = selector(state, 'user_meta-searchFilter_bank');
    const lenderFilterToggle = selector(state, 'user_meta-searchFilter_bank_toggle');
    const repaymentFrequency = selector(state, 'user_meta-searchFilter_repaymentFrequency');
    const repaymentFrequencyToggle = selector(state, 'xxxuser_meta-searchFilter_repaymentFrequency_toggle');
    const nicheLending = selector(state, 'xxxuser_meta-searchFilter_nicheLending');
    const nicheLendingToggle = selector(state, 'sxxxuser_meta-searchFilter_nicheLending_toggle');

    // redundant - remove later

    const bridgingLoan = selector(state, 'user_meta-searchFilter_bridging_loan');
    const extraRepayments = selector(state, 'user_meta-searchFilter_extra_repayments_allowed');
    const interestInAdvice = selector(state, 'user_meta-searchFilter_interest_in_advance');
    const loanIncrease = selector(state, 'xxxuser_meta-searchFilter_loanIncrease');
    const lockedRate = selector(state, 'user_meta-searchFilter_lockedRate');
    const fullOffset = selector(state, 'user_meta-searchFilter_full_offset');
    const partialOffset = selector(state, 'user_meta-searchFilter_partial_offset');
    const packageSearch = selector(state, 'user_meta-searchFilter_package');
    const portability = selector(state, 'user_meta-searchFilter_portable');
    const redrawAllowed = selector(state, 'user_meta-searchFilter_redraw_allowed');
    const rebate = selector(state, 'user_meta-searchFilter_rebate');
    const splitting = selector(state, 'user_meta-searchFilter_splitting');
    const company = selector(state, 'user_meta-searchFilter_company');
    const loDoc = selector(state, 'user_meta-searchFilter_loDoc');
    const ownerBuilder = selector(state, 'user_meta-searchFilter_ownerBuilder');
    const vacant = selector(state, 'user_meta-searchFilter_vacant');
    const smsf = selector(state, 'user_meta-searchFilter_is_smsf');
    const fixedTerm = selector(state, 'user_meta-searchFilter_fixed_term');
    const irType = selector(state, 'user_meta-searchFilter_initial_ir_type');

    return {
      borrowingPower: state.user.borrowingPower,
      borrowingPowerActive: state.user.meta.calculators && state.user.meta.calculators.borrowingPowerActive,

      loanAmount,
      purchasePrice,
      loanPeriod,
      loanMainPurpose,

      lenders: state.user.lenders,
      loanPurpose,
      loanPurposeToggle,
      lenderFilter,
      lenderFilterToggle,
      repaymentFrequency,
      repaymentFrequencyToggle,
      nicheLending,
      nicheLendingToggle,

      bridgingLoan,
      extraRepayments,
      interestInAdvice,
      loanIncrease,
      lockedRate,
      fullOffset,
      partialOffset,
      "package": packageSearch,
      portability,
      redrawAllowed,
      splitting,
      company,
      rebate,
      loDoc,
      ownerBuilder,
      vacant,
      smsf,
      fixedTerm,
      irType,
      // initialValues: {
      //   'user_meta-searchFilter_bank': ['ALL_LENDERS'],
      //   'user_meta-searchFilter_initial_ir_type': 'any',
      //   ...getInitialValuesObject(state)
      // }
    }
  }
)(LoanSearchFilter);

export default LoanSearchFilter;
//
// ((this.props.bridgingLoan || this.props.extraRepayments || this.props.interestInAdvice || this.props.loanIncrease || this.props.lockedRate || this.props.fullOffset
//   || this.props.partialOffset ||this.props.package || this.props.portabillity || this.props.redrawAllowed
//   || this.props.splitting || this.state.isFilterShown || this.props.company || this.props.construction
//   || this.props.loDoc || this.props.ownerBuilder || this.props.vacant || this.props.sms || this.props.loanPurpose
//   || this.props.lenderFilter || this.props.repaymentFrequency || this.props.nicheLending)
//   ?"EXISTS":"NOTHING")

import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadioBox from '../Components/RadioBox';

import QuestionWrapper from "../Components/QuestionWrapper";

let HowClose = function(props) {

  function onChange() {
    props.onSubmit();
  }

  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} invalid={props.invalid} debounce={props.debounce} id={props.id} gotoQuestion={props.gotoQuestion} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion} >
      <div className="content four-box">
        <h1>How close are you to buying?</h1>
        <RadioBox name="user_meta-howClose" onChange={()=>onChange()} text="I've signed the contract" value="signedContract" select={props.value} />
        <RadioBox name="user_meta-howClose" onChange={()=>onChange()} text="I’ve found a great property" value="foundProperty" select={props.value} />
        <RadioBox name="user_meta-howClose" onChange={()=>onChange()} text="I am still looking for the right place" value="stillLooking" select={props.value} />
        <RadioBox name="user_meta-howClose" onChange={()=>onChange()} text="I’m just starting to look around" value="lookingNow" select={props.value} />
      </div>
    </QuestionWrapper>
  )
}

HowClose = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-howClose'  // a unique identifier for this form
})(HowClose);

const selector = formValueSelector('onboarding-howClose') // <-- same as form name
HowClose = connect(
  state => {
    //console.log(state);
    // can select values individually
    const value = selector(state, 'user_meta-howClose');
    return {
      value
    }
  }
)(HowClose);

export default HowClose;

import React, { Component, Fragment } from "react";
import { Field, Form } from 'redux-form';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { Prompt } from "react-router-dom";
import styled, { css } from 'styled-components';

import Tooltips from "Components/Tooltips";
import AddressInput from './AddressInput'
import { createRequired } from "./validation";
import SearchSelect from './SearchSelect';
import RateField from "./InterestRateField";
import { MultiSelect } from "./MultiSelect";
import EditableContent from "Components/Forms/EditableContent";

const fieldRequired = createRequired();
const selectRequired = createRequired('Please select an option');

export class FormContainer extends Component {
  render() {
    const { name, children, extended, containerStyle, ...props } = this.props;
    const _class = this.props.class; // this is why you don't name a prop a keyword
    delete this.props.class; 
    return (
      <div className={"form" + (extended ? ' extended' : '')} style={containerStyle || {}}>
        <Form noValidate {...props} className={name + " " + typeClass(_class)}>

          <div className="form-container">
            {children}
          </div>

        </Form>
      </div>
    );
  }
}



export const Input = ({meta: {touched, error, submitFailed}, input, errorCallback, formError, disabled, className, ...props}) => {
  if (error && errorCallback) errorCallback(error);
  let showError = false;
  if ((error === 'This is required' || error === 'Please enter your email') && submitFailed) {
    showError = true;
  } else if (touched && error && error !== 'This is required' && error !== 'Please enter your email') {
    showError = true;
  }
  return ( 
  <Fragment>
    <input
      {...props} {...input} required={false}
      className={((showError || formError) ? 'error ' : '') + (disabled ? 'disabled ' : '') + (className || '')}/>
    {showError && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
  )
};

export const TextAreaField = ({meta: {touched, error}, input, errorCallback, formError, disabled, className, ...props}) => {
  return ( 
    
  <Fragment>
    <textarea
      {...props} {...input} required={false}
      className={(((touched && !!error) || formError) ? 'error ' : '') + (disabled ? 'disabled ' : '') + className}/>
    {touched && !!error && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
  )
};

export const ErrorMessage = props => (
  <div className={'field-error-message ' + (props.isBottomLabel ? 'is-bottom-label' : '')} {...props}/>
)

export class FieldGroup extends Component {
  render() {
    let { validate, required, formError, normalize, format, maxNumber } = this.props;
    validate = validate || [];
    validate = validate instanceof Array ? validate : [validate]
    validate = required ? [fieldRequired, ...validate] : validate;
    if (!maxNumber) maxNumber = 99999999;
    return (
      <div style={this.props.style} className={typeWidth(this.props.width) + typePosition(this.props.position, this.props.width) + ((this.props.type === 'password' ? 'text' : this.props.type) + " ") + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title) + ((this.props.mask === "monetary")?"monetary":"") + ((this.props.mask === "years")?"years-mask":"") + " " + this.props.className}>
        <label>{this.props.title}
        {!!this.props.tooltip &&
          <Tooltips type="definition" term={this.props.tooltip}/>
        }
          <span>{this.props.showRequired ? 'Required' : ((this.props.required || this.props.hideOptional)?"":" Optional")}</span>
        </label>
        <div>
          <div className={"field " + this.props.type + "-field"}>
            <Field
              data-lpignore="true"
              disabled={this.props.disabled}
              name={this.props.id}
              component={Input}
              type={typeValue(this.props.type)}
              placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
              required={this.props.required}
              validate={validate}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              formError={formError}
              maxLength={this.props.maxlength}
              format={format}
              normalize={normalize}
              pattern={(this.props.mask == "int")?"[0-9]*": this.props.pattern}
              inputMode={(this.props.mask == "int")?"numeric": this.props.inputMode}
              initialValues={this.props.initialValues}
              {...masks(this.props.mask, maxNumber)}
              
              
            />
            {this.props.autoCalculated && <div className='auto-calc'>Auto-calculated</div>}
          </div>
        </div>
        <label className="bottom">{this.props.bottomLabel}</label>
      </div>
    );
  }
}

class PasswordInput extends Component {
  state = {
    hidden: true,
  }
  
  toggleHidden = () => {
    this.setState({ hidden: !this.state.hidden }, () => 
      this.input.focus()
    );  
  }

  render() {
    const {meta: {touched, error, submitFailed}, input, errorCallback, formError, ...props} = this.props;
    const { hidden } = this.state;
    if (error && errorCallback) errorCallback(error);
    let showError = false;
    if ((error === 'This is required' || error === 'Please enter your password' || error === 'Please enter a password' || error === 'You must confirm your password') && submitFailed) {
      showError = true;
    } else if (touched && error && error !== 'This is required' && error !== 'Please enter a password'  && error !== 'Please enter your password'  && error !== 'You must confirm your password') {
      showError = true;
    }
    return ( 
    <Fragment>
      <input
        ref = {ref => this.input = ref} 
        type = {hidden ? 'password' : 'text'}
        className={(showError|| formError) ? 'error ' : ''}
        {...props} {...input} required={false}/>
      {showError && <ErrorMessage>{error}</ErrorMessage>} 
      <button tabIndex={-1} className={!input.value && hidden ? 'hide ' : '' + (!hidden ? 'show' : '')} type='button' onClick={this.toggleHidden} ></button>
    </Fragment>
    )
  }
} 


export class PasswordField extends Component {
  
  
  render() {
    let { validate, required, formError, normalize, format, maxNumber, value } = this.props;
    //console.log('props:', this.props);
    validate = validate || [];
    validate = validate instanceof Array ? validate : [validate]
    validate = required ? [fieldRequired, ...validate] : validate;
    return (
      <div className={typeWidth(this.props.width) + typePosition(this.props.position, this.props.width) + 'text ' + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title)}>
        <label>{this.props.title}
        {!!this.props.tooltip &&
          <Tooltips type="definition" term={this.props.tooltip}/>
        }
          <span>{(this.props.required || this.props.hideOptional)?"":" Optional"}</span>
        </label>
        <div>
          <div className={"field password-field" + (value ? ' dirty' : '')}>
            <Field
              name={this.props.id}
              component={PasswordInput}
              placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
              required={this.props.required}
              validate={validate}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              formError={formError}
              maxLength={this.props.maxlength}
              normalize={normalize}
              format={format}
              {...masks(this.props.mask, maxNumber)}
            />
            
            {this.props.autoCalculated && <div className='auto-calc'>Auto-calculated</div>}
          </div>
          
        </div>
        <label className="bottom">{this.props.bottomLabel}</label>
      </div>
    );
  }
}

export class AddressFieldGroup extends Component {
  render() {
    const { title, required, showRequired, width, position, type, fill, mask, bottomLabel, placeholder, id, ...props } = this.props;
    //console.log('width:', width);
    return(
      <div className={typeWidth(width) + typePosition(position, width) + (type + " ") + (id + " ") + typeClass(this.props.class) + typeFill(fill) + typeTitle(title)}>
        <label>{title}<span>{(required)? (showRequired) ? " Required":"":" Optional"}</span></label>
        <div>
          <div className={"field " + type + "-field"}>
            <Field 
              name={id}
              component={AddressInput}
              type={typeValue(type)}
              placeholder={typePlaceholder(type, placeholder)}
              width={width}
              validate={required ? fieldRequired : []}
              {...props}
            />
          </div>
        </div>
        <label className="bottom">{bottomLabel}</label>
      </div>
    );
  }
}


const RateFieldComponent = ({meta: {touched, error}, input, errorCallback, formError, forRebate, ...props}) => {
  if (error && errorCallback) errorCallback(error);
  return ( 
  <Fragment>
    <RateField 
      digits={forRebate ? 2 : 3}
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
  </Fragment>
  )
};

const interestRateValidation = value => value < 1 ? 'The entered rate must be above 1%' : null;
const rebateValidation = value => value > 0.5 ? 'The entered rate must be no more than 0.5%' : null;

export class RateFieldGroup extends Component {
  render() {
    const { title, required, width, position, type, fill, mask, bottomLabel, placeholder, id, forRebate, ...props } = this.props;
    //console.log('width:', width);
    let validion = forRebate ? rebateValidation : interestRateValidation;

    return(
      <div className={typeWidth(width) + typePosition(position, width) + (type + " ") + (id + " ") + typeClass(this.props.class) + typeFill(fill) + typeTitle(title)}>
        <label>{title}<span>{(required || props.hideOptional)?"":" Optional"}</span></label>
        <div>
          <div className={"field " + type + "-field"}>
            <Field 
              name={id}
              component={RateFieldComponent}
              validate={required ? [fieldRequired, validion] : [validion]}
              forRebate={forRebate}
              {...props}
            />
          </div>
        </div>
        <label className="bottom">{bottomLabel}</label>
      </div>
    );
  }
}

// Remove Subtitle
export class Subtitle extends Component {
  render() {
    return (
      <div className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " subtitle " + typeClass(this.props.class)}>
        <h3>{this.props.title}</h3>
      </div>
    );

  }
}

export class Heading3 extends Component {
  render() {
    return (
      <div className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " form-h3 " + typeClass(this.props.class)}>
        <h3>{this.props.title}</h3>
        <p>{this.props.content}</p>
      </div>
    );

  }
}

export class Heading4 extends Component {
  render() {
    return (
      <div className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " form-h4 "+ typeClass(this.props.class)}>
        <h4>{this.props.title}</h4>
        <p>{this.props.content}</p>
      </div>
    );

  }
}

const Select = ({meta: {touched, error}, input, ...props}) => (
  <Fragment>
    <select
      className={touched && error && 'error'}
      {...props} {...input}/>
    {!touched || !error ?
      <label className="bottom">{props.bottomLabel}</label> 
      : 
      [<label className="bottom"/>,
      <ErrorMessage style={{ marginTop: '-8px'}}>{error}</ErrorMessage>]
    } 
  </Fragment>
);

const SearchableSelect = ({meta: {touched, error}, input, ...props}) => (
  <Fragment>
    <SearchSelect
      className={touched && error && 'error'}
      isSearchable
      width={props.width || 380}
      {...props} {...input}/>
    {!touched || !error ?
      <label className="bottom">{props.bottomLabel}</label> 
      : 
      [<label className="bottom"/>,
      <ErrorMessage style={{ marginTop: '-8px'}}>{error}</ErrorMessage>]
    } 
  </Fragment>
);

const MultiSelectComponent = ({meta: {touched, error}, input, ...props}) => (
  <Fragment>
    <MultiSelect
      // className={touched && error && 'error'}
      width={props.width || 380}
      {...props} {...input}/>
    {!touched || !error ?
      <label className="bottom">{props.bottomLabel}</label> 
      : 
      [<label className="bottom"/>,
      <ErrorMessage style={{ marginTop: '-8px'}}>{error}</ErrorMessage>]
    } 
  </Fragment>
);

export class MultiSelectGroup extends Component {
  render() {
    return (
      <div style={{width: this.props.width}}className={'w280 ' + typePosition(this.props.position, this.props.width) + (this.props.type + " ") + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title)}>
        <label>{this.props.title}<span>{(this.props.required || this.props.hideOptional)?"":" Optional"}</span></label>
        <div className="field">
          <Field 
            onChange={this.props.onChange} 
            name={this.props.id}
            width={this.props.width}
            component={MultiSelectComponent} 
            validate={this.props.required ? selectRequired : []} 
            required={this.props.required} 
            placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
            bottomLabel={this.props.bottomLabel} 
            normalize={this.props.normalize}
            options={this.props.values}/>             
        </div>
      </div>
    );
  }
}

export class SelectGroup extends Component {
  render() {
    return (
      <div style={this.props.style} className={typeWidth(this.props.width) + typePosition(this.props.position, this.props.width) + (this.props.type + " ") + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title) + " " + this.props.className}>
        <label>{this.props.title}<span>{(this.props.required || this.props.hideOptional)?"":" Optional"}</span></label>
        <div className="field">
          <Field 
            style={this.props.style}
            onChange={this.props.onChange} 
            name={this.props.id}
            component={Select} 
            validate={this.props.required ? selectRequired : []} 
            required={this.props.required} 
            placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
            bottomLabel={this.props.bottomLabel} >
            {toggleEmptySelect(this.props.empty)}
            {this.props.values.map((item, index)=>{
              let value, text;
              if (item === undefined || item === null) {
                return;
              }
              if (typeof item !== "string") {
                value = item.value;
                text = item.text;
              } else {
                value = item;
                text = item;
              }
              const selected = this.props.selectFirst && !!index;
              return <option key={index} value={value} selected={selected}>{text}</option>})}              
          </Field>
        </div>
      </div>
    );
  }
}

export class SearchableSelectGroup extends Component {
  render() {
    return (
      <div style={this.props.style} className={typeWidth(this.props.width) + typePosition(this.props.position, this.props.width) + (this.props.type + " ") + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title)}>
        <label>{this.props.title}<span>{(this.props.required || this.props.hideOptional)?"":" Optional"}</span></label>
        <div className="field">
          <Field 
            onChange={this.props.onChange} 
            name={this.props.id}
            width={this.props.width}
            component={SearchableSelect} 
            validate={this.props.required ? selectRequired : []} 
            required={this.props.required} 
            placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
            bottomLabel={this.props.bottomLabel} 
            options={this.props.values}
            dontFilter={this.props.dontFilter}
            />    
            

        </div>
      </div>
    );
  }

}

export class DoubleFieldGroup extends Component {
  render() {
    let { validate, required, format, normalize } = this.props;
    validate = validate || [];
    validate = validate instanceof Array ? validate : [validate]
    validate = required ? [fieldRequired, ...validate] : validate;
    return(
      <Fragment>
        <div className={typeWidth(this.props.width) + typePosition(this.props.position, this.props.width) + ("double-field ") + (this.props.id + " ") + typeClass(this.props.class) + typeFill(this.props.fill) + typeTitle(this.props.title)  + ((this.props.mask === "monetary" || this.props.mask === "negativeMonetary")?"monetary":"") + ((this.props.mask === "years")?"years-mask":"")}>
          <label>
            {this.props.title}
            {!!this.props.tooltip &&
              <Tooltips type="definition" term={this.props.tooltip}/>
            }
            <span>{(this.props.required || this.props.hideOptional)?"":" Optional"}</span>
          </label>
          <div>
            <div className={"field double-field-value"}>
              <Field
                data-lpignore="true"
                name={this.props.id} 
                format={format}
                normalize={normalize}
                required={required}
                component={Input}
                validate={validate}
                type="text"
                placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
                pattern={(this.props.mask == "int")?"[0-9]*": this.props.pattern}
                inputMode={(this.props.mask == "int")?"numeric": this.props.inputMode}

                {...masks(this.props.mask, this.props.maxNumber)} />
            </div>
            {typeContactFields(this.props.type, this.props.id)}
          </div>
          <label className="bottom">{this.props.bottomLabel}</label>
        </div>

      </Fragment>
      
    );
  }
}

const formatToggle = valueChecked => 
  value => {
    if (!valueChecked) return value;
    return (value === valueChecked) ? 'Y' : '';
  };

const normalizeToggle = (valueChecked, valueUnchecked) =>
  value => value ? (valueChecked || 'Y') : (valueUnchecked || '');

export class RadioToggleGroup extends Component {
  render() {
    return(
    <div style={this.props.style} className={typeWidth(this.props.width) + " radio-toggle-group " + typePosition(this.props.position, this.props.width) + " " +  this.props.type + " " + this.props.id + " " + typeClass(this.props.class)}>
      <label>{this.props.title}</label>

      <div className="radio-toggle-content">
        <Field type="checkbox" component={Input} id={this.props.id} name={this.props.id}
              onChange={this.props.onChange}
              normalize={normalizeToggle(this.props.valueChecked, this.props.valueUnchecked)}
              format={formatToggle(this.props.valueChecked)}
              validate={this.props.required ? selectRequired : []}
              required={this.props.required}
              errorCallback={this.props.errorCallback}
              />
        <label htmlFor={this.props.id}>
          <div className="radio-toggle-group-switch" data-checked={this.props.checked} data-unchecked={this.props.unchecked}></div>
        </label>

      </div>

    </div>
  );
  }
}

export class Empty extends Component {
  render() {
    return(
      <div className={typeWidth(this.props.width) + " radio-toggle-group " + typePosition(this.props.position, this.props.width) + " " +  this.props.type + " " + typeClass(this.props.class)}>
      </div>
    );
  }
}

export class KeyLine extends Component {
  render() {
    return(
      <hr />
    );
  }
}

export class CheckBoxGroup extends Component{
  render () {
    return (
      <div className={"checkbox-option " + this.props.id + " " + typeClass(this.props.class)}>
        <Field onChange={this.props.onChange} name={this.props.id} id={this.props.id} component={Input} type="checkbox" normalize={v => v?"Y":""} />
        <label htmlFor={this.props.id}>{this.props.title}</label>
      </div>
    );
  }
}

export class Button extends Component {
  render () {
    return (
      <div 
        className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " form-button " + typeClass(this.props.class)} 
        onClick={this.props.onClick}>
        <a onClick={() => {}}>{this.props.title}</a>
      </div>
    );
  }
}

export class RadioBox extends Component {
  render() {
    return(
      <label className={"box box-form w280 " + toggleState(this.props.select, this.props.value) + (this.props.value + " ")+ typeBoxSize(this.props.subtext) + " " + typeClass(this.props.class)}>
        <Field name={this.props.name} onClick={this.props.onChange} required component='input' type="radio" value={this.props.value} />
        {' '}
        <span className="title">{this.props.text}</span>
        <span className='subtitle'>{this.props.subtext}</span>
      </label>
  );
  }
}

class RadioGroupComponent extends Component {
  
  renderChildren = () => {
    const { children, input: { name } } = this.props;
    return children.map( (child, key) => {
      return React.cloneElement(child, {
        key,
        name
      });
    });
  }

  render() {
    const {meta: {touched, error}, input, ...props} = this.props;
    return (
      <Fragment>
        {this.renderChildren()}
        {touched && error && <ErrorMessage>{error}</ErrorMessage>} 
      </Fragment>
    );
  }
}

export const RadioGroup = (props) => (
  <Field {...props} component={RadioGroupComponent} validate={props.required ? selectRequired : []}/>
);

export class TextArea extends Component {
  render() {
    return(
      <div className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " " +  this.props.type + " " + this.props.id + " " + typeClass(this.props.class)}>
        <label>{this.props.title}</label>
          <div className="field text-area">
            <Field
              className={this.props.className}
              name={this.props.id}
              component={TextAreaField}
              type={typeValue(this.props.type)}
              placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
              required={this.props.required}
              validate={this.props.validate}
              {...masks(this.props.mask)}
            />
          </div>
      </div>
    );
  }
}

export const UnsavedChangesPrompt = ({when}) => (
  <Prompt
    when={when}
    message='There are unsaved changes. Are you sure you want to leave this page?'
  />
)

export class Address extends Component {
  render() {
    return(
      <div className={typeWidth(this.props.width) + " " + typePosition(this.props.position, this.props.width) + " " +  this.props.type + " " + this.props.id + " " + typeClass(this.props.class)}>
        <label>{this.props.title}</label>
          <div className="field text-area">
            <Field
              name={this.props.id}
              onFocus="geolocate()"
              component="textarea"
              type={typeValue(this.props.type)}
              placeholder={typePlaceholder(this.props.type, this.props.placeholder)}
              required={this.props.required}
              meta
              {...masks(this.props.mask)}
            />
          </div>
      </div>
    );
  }
}

function optional(value) {
  if (value) {
    if ((value.toLowerCase()) === "yes") {
      return ""
    }
    else {
      return <span>Optional</span>;
    }
  }
}
function typeTitle(value){
  if (value) {
    if (value.length === 0) {
      return "no-title ";
    }
    else {
      return "";
    }
  }
  else {
    return "";
  }
}

function typeFill(value){
  if (value) {
    return value + " ";
  }
  else {
    return "";
  }
}

function typeClass(value){
  if (value) {
    return value + " ";
  }
  else {
    return "";
  }
}
function toggleEmptySelect(value) {
  if (value) {
    return "";
  } else {
    return <option value="" disabled selected>Please select an option</option>;
  }
}
function typeBoxSize(value) {
  if (!value) {
    return "small ";
  }
  else {
    return "large ";
  }
}
function toggleState(value1, value2) {
  if (value1 === value2){
    return "enabled ";
  }
  else {
    return "";
  }
}
function typePlaceholder(value, value2) {
  if (value2 !== "") {
    return value2;
  }
  else {
    switch (value) {
      case "email": return "E.g. jeff.lau@live.com";
      case "password": return "Enter your password";
      case "contact-number": return "";
      default: return value2;
    }
  }
}


// placeholder={typePlaceholder(this.props.type, this.props.placeholder)}

function typePosition(value, value1){
  if (value === "right") {
    return "rs ";
  }
  if (value === "right" && value1 === "580") {
    return "ls "
  }
  else {
    return "ls "
  }
}
function typeWidth(value){
  if (value === "small") {
    return "w280 ";
  }
  if (value === "large") {
    return "w580 ";
  }
  if (value === "100%") {
    return "w100p ";
  }
  if (value === "50%") {
    return "w50p ";
  }
  else {
    return " w" + value + " ";
  }
}
function typeValue(value){
  switch (value) {
    case "contact-number": return "number";
    case "email": return "email";
    case "select": return "select";
    case "password": return "password";
    default: return value;
  }
}

export const timePeriod = {
  YEAR: 1,
  MONTH: 1/12,
  WEEK: 1/365.25*7,
  FORTNIGHT: 1/365.25*14,
}

function typeContactFields(value, value1){
  if (value === "number-type") {
    return(
      <div className={"field double-field-type"}>
        <Field name={value1 + "Type"} component="select" defaultValue='mobile'>
          <option name={value1 + "Type"} value="mobile">Mobile</option>
          <option name={value1 + "Type"} value="home">Home</option>
          <option name={value1 + "Type"} value="work">Work</option>
        </Field>
      </div>
    );
  }
  if (value === "monetary-time") {
    return (
      <div className={"field double-field-type"}>
        <Field name={value1 + "Type"} component="select" defaultValue={1/12}>
          <option name={value1 + "Type"} value={timePeriod.MONTH}>Monthly</option>
          <option name={value1 + "Type"} value={timePeriod.FORTNIGHT}>Fortnightly</option>
          <option name={value1 + "Type"} value={timePeriod.WEEK}>Weekly</option>
          <option name={value1 + "Type"} value={timePeriod.YEAR}>Annually</option>
        </Field>
      </div>
    );
  }
  else {
    return "";
  }
}
function masks(value, max) {
  switch (value) {
    case "monetary": return (customCurrencyMask(max));
    case "birthday": return (birthday);
    case "percentage": return (percentage);
    case "int": return (customNumberMask(max));
    case "years": return (customYearMask(max));
    case "negativeMonetary": return negativeCurrencyMask;
    default: return ("");
  }
}

export const EditableParagraph = styled(EditableContent)`
  line-height: 150%;
  min-height: 50px;
  outline: none;
  
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    margin-top: 20px;

    li:before {
      content: '•';
      margin-left: -20px;
      margin-right: 10px;
    }

    li {
      list-style: none inside !important;
      margin-left: 30px;
      margin-bottom: 15px;
    }
  }

  ol {
    margin-top: 20px;

    li {
      list-style: decimal inside !important;
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }

  &.active {
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  }
`;

// Masks

const yearMask = createNumberMask({
  allowEmpty: 'false',
  // suffix: ' years',
  allowEmpty: false
})

const intMask = createNumberMask({
  allowEmpty: 'false',
})

const currencyMask = createNumberMask({
  // prefix: '$ ',
  // suffix: ' AUD',
  locale: 'en-US',
  // allowEmpty: 'false',
});

const negativeCurrencyMask = createNumberMask({
  // prefix: '$ ',
  // suffix: ' AUD',
  locale: 'en-US',
  allowEmpty: true,
  allowNegative: true,
});

const birthday = createTextMask({
  pattern: '99/99/99',
  guide: false,
});

const percentage = createNumberMask({
  suffix: ' %',
  allowEmpty: true,
  decimalPlaces: 2,
  // pattern: '99.99',
});




const customCurrencyMask = (max = Infinity) => ({
  format: value => {
    if (!value) return '';
    value = Math.min(max, value);
    value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return value;
  },

  normalize: (value, oldValue) => {
    value = value.replace(/\D/g,'');
    value = parseInt(value);
    if (!value) return 0;
    return value <= max ? value : oldValue;
  }
});

const customNumberMask = (max = Infinity) => ({
  format: value => {
    if (!value) return '';
    value = Math.min(max, value);
    value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    return value;
  },

  normalize: (value, oldValue) => {
    if (!value) return 0;
    value = value.replace(/\D/g,'');
    value = parseInt(value);
    return value <= max ? value : oldValue;
  }
});


const customYearMask = (max = Infinity) => ({
  format: value => {
    const num = value;
    if (!value) return '';
    value = Math.min(max, value);
    value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // value = value + (num === 1 ? ' year' : ' years');
    return value;
  },

  normalize: (value, oldValue) => {
    if (value === undefined) return 30;
    value = value.replace(/\D/g,'');
    value = parseInt(value);
    if (!value) return 0;
    return value <= max ? value : oldValue;
  }
});
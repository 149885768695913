import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { SelectGroup } from "Components/Forms";
import { requestPatchFile, requestRemoveFile, receivePatchFile } from 'modules/user/actions';

let currentAction = null;

const selector = formValueSelector('eligibility-payslips');
function mapStateToProps(state, props) {

  let initialValues = {};
  let uploads = state.user.uploads;
  
  let applicant = selector(state, 'applicant_' + props.file.id)

  uploads.forEach((upload)=>{
    initialValues["applicant_" + upload.id] = upload.applicant;
  });

  return {
    applicantName: state.user.firstName + (state.user.lastName ? (" " + state.user.lastName) : ''),
    partners: state.user.partners,
    applicant,
    initialValues,
    uploads
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchFile,
    requestRemoveFile,
    receivePatchFile
  }, dispatch);
}

class Payslips extends React.Component {

  constructor(props) {
    super(props);

    let comment = "";
    
    let file = this.props.uploads.filter(f=>f.id === this.props.file.id)[0];
    if (file) {
      comment = file.userComment;
    }
    comment = comment || "";
    this.state= {addComment: false, commentText: comment};
  }

  toggleAddComment(){
    this.setState((prevState) => {return {...prevState, addComment: !prevState.addComment}});
    return false;
  }

  submitOnChange() {
    clearTimeout(currentAction);
    currentAction = setTimeout(this.props.handleSubmit, 30);
  }

  deleteFile() {
    this.props.requestRemoveFile({id: this.props.file.id});
    return false;
  }

  cancelUpload() {
    this.props.receivePatchFile({name: this.props.file.name, cancel: true});
    return false;
  }

  onCommentChange(event) {
    let value = event.target.value;
    this.setState(prevState => ({...prevState, commentText: value}));
  }

  saveComment() {
    this.props.requestPatchFile({id: this.props.file.id, data: {
      name: this.props.file.name,
      userComment: this.state.commentText
    }});
    this.toggleAddComment();
    return false;
  }

  render() {
    let file = this.props.file;
    return(
      <form className="payslip" onSubmit={this.props.handleSubmit}>
        {(file.status > 0)?
          <div className="upload-edit">
            <div className="file-name">{file.name}</div>
            <div className="file-details">
              <div className="applicant">
              <SelectGroup class={this.props.applicant ? '' : 'applicant-not-selected'} onChange={()=>this.submitOnChange()} id={'applicant_' + file.id} width='small' position='left' title='' type='select' placeholder='' 
                  values={[this.props.applicantName, ...this.props.partners.map(partner=>partner.firstName && partner.firstName + " " + partner.lastName)]} />
              </div>
              <div className="properties">
                <button type="button"  onClick={()=>this.toggleAddComment()} className="comment"></button>
                <button type="button" className="delete" onClick={()=>this.deleteFile()}></button>
              </div>
            </div>
            {this.state.addComment ?
              <div className="add-comment">
                <p>Add Comment</p>
                <input type="text" name="comment" defaultValue={this.state.commentText} onChange={event=>this.onCommentChange(event)}></input>
                <button type="button" className="save-comment" onClick={()=>this.saveComment()}>Save Comment</button>
              </div>
            :
              ""
            }
          </div>
        :
          <div className="upload-status">
            <div className="upload-progress-bar">
              <p className="file-name">{file.name}</p>
              {/* <p className="upload-speed">5.3MB/s</p> */}

              <div className="progress-bar-container">
                <div className="progress-bar-indicator" style={{width: ("" + file.percentUploaded + "%")}}></div>
              </div>

            </div>
            <button className="delete" onClick={()=>this.cancelUpload()}></button>
          </div>
        }

      </form>
  );
}
}

Payslips = reduxForm({
  form: 'eligibility-payslips',  // a unique identifier for this form
  destroyOnUnmount: false
})(Payslips);

export default connect(mapStateToProps, mapDispatchToProps)(Payslips);
import store from 'store';
import {requestPatchUser} from "modules/user/actions";
import {closeModal} from "modules/modals/actions";

export default function updateFormValues(results, _callback, isEarlyCallback=false, willCloseModal = true, lateCallback = () => {}) {
  //console.log('submit results:', results);
  let userData = {meta:{calculators: {}}};
  let applicationData = {meta:{}};
  let userUpdated = false;
  let state = store.getState();
  let partner = {meta:{}};

  for (let key in results) {
    let split = key.split("-");
    if (split.length < 2) {
      continue;
    }
    let service = split[0];
    let dbKey = split[1];

    //reject non-primitive values
    // if (typeof results[key] === 'object' && results[key] !== null) {
    //   continue;
    // }

    switch (service) {
      case "user":
        if (results[key] !== state.user[dbKey] && typeof results[key] !== 'object') {
          userData[dbKey] = results[key];
          userUpdated = true;
        }
        break;
      case "user_meta":
        if (!state.user.meta || results[key] !== state.user.meta[dbKey]) {
          if (typeof results[key] === 'array' 
              && JSON.stringify(results[key]) === JSON.stringify(state.user.meta[dbKey])) {
            break;
          }
          userData.meta[dbKey] = results[key];
          userUpdated = true;
        }
        break;
      case "calculators":
      
      if (!state.user.meta || !state.user.meta.calculators || results[key] !== state.user.meta.calculators[dbKey]) {
        userData.meta.calculators[dbKey] = results[key];
        userUpdated = true;
      }
      if (state.user.meta && state.user.meta.calculators) {
        userData.meta.calculators = { ...state.user.meta.calculators, ...userData.meta.calculators}
      }
      break;
      case "application":
        if (results[key] !== state.user.applications[0][dbKey] && typeof results[key] !== 'object') {
          applicationData[dbKey] = results[key];
          userUpdated = true;
        }
        break;
      case "application_meta":
        if (!state.user.applications[0].meta || results[key] !== state.user.applications[0].meta[dbKey]) {
          if (typeof results[key] === 'array' 
              && JSON.stringify(results[key]) === JSON.stringify(state.user.applications[0].meta[dbKey])) {
            break;
          }
          applicationData.meta[dbKey] = results[key];
          userUpdated = true;
        }
        break;
      case "user_partner":
        if (!state.user.partners 
          || state.user.partners.length === 0 
          || (results[key] !== state.user.partners[0][dbKey] && typeof results[key] !== 'object')) {
          partner[dbKey] = results[key];
          userUpdated = true;
        }
        break;
      case "user_partner_meta":
        if (!state.user.partners || state.user.partners.length === 0
          || !state.user.partners[0].meta 
          || results[key] !== state.user.partners[0].meta[dbKey]) {
          if (typeof results[key] === 'array' 
              && JSON.stringify(results[key]) === JSON.stringify(state.user.partners[0].meta[dbKey])) {
            break;
          }
          partner.meta[dbKey] = results[key];
          userUpdated = true;
        }
      break;
      default:
        break;
    }
  }

  userData.meta['searchFilter_isMonthly'] = '';
  userData.meta['searchFilter_isWeekly'] = '';
  userData.meta['searchFilter_isFortnightly'] = '';

  userData.application = { ...applicationData, id: (state.user.applications[0] || {}).id };
  userData.partner = { ...partner, id: (state.user.partners[0] || {}).id };

  let callbackEarly = null;
  let callback = null;

  if (isEarlyCallback) {
    callbackEarly = _callback;
    callback = lateCallback;
  } else {
    callback = _callback;
}

  if (userUpdated) {
    //console.log(JSON.stringify(userData));

    store.dispatch(requestPatchUser({
      userId: store.getState().user.id,
      data: userData,
      callback,
      callbackEarly,
    }));
    
  } else if (_callback) {
    _callback();
    lateCallback();
  }
  if (willCloseModal) store.dispatch(closeModal());
}

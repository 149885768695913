import { app, usersService, shortlistService, applicationService, applicationProductsService, verificationService, filesService, hostUrl } from '../index';
import request from "superagent";
import store from 'store';
import {receivePatchFile, receiveRemoveFile} from "./actions";
import Debouncer from 'utils/debouncer';
import _ from 'lodash';
const debouncer = new Debouncer();



export async function signup(payload) {
  
  let authentication;
  try {
    authentication = await usersService.create(payload);
    console.log("AUTH" + JSON.stringify(authentication));
  } catch (err) {
    console.log(err);
    return err;
  }

  const tryAuth = async () => {
    try {
      let result = await app.authenticate({
        strategy: 'jwt',
        accessToken: authentication.accessToken,
      });
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  let attempts = 3;
  let result;
  while (attempts > 0) {
    result = await tryAuth();

    if (!result.accessToken) {
      attempts--;
    } else {
      return result;
    }
  }
  return result;
}



export async function login(payload) {
  try {
    return await app.authenticate({
      strategy: 'local',
      ...payload,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {};
  }
}

export function logout() {
  return app.logout();
};

export async function getSelf() {
  try {
    const user = await usersService.get(1, {query:{login:true}});
    return user;
  } catch (err) {
    console.log(err);
    return {error: err};
  }
}

export async function auth() {
  try {
    const token = await app.passport.getJWT();
    console.log("TOKEN: " + token);
    if (!token) {
      return {error: "No token"};
    }
    const payload = await app.passport.verifyJWT(token);
    console.log(JSON.stringify(payload));
    const user = await usersService.get(1, {query:{login:true}});
    return user;
  } catch (err) {
    console.log(err);
    app.logout();
    return {error: err};
  }
}

let valuesToMerge = {};

export async function userPatch(payload) {
  try {
    //console.log(JSON.stringify(payload));
    
    let { data, userId, params, noDebounce } = payload;
    if (!userId) return {};
    if (params && params.mergeMeta) {
      _.mergeWith(valuesToMerge, data, (a, b) => {
        if (_.isArray(a)) return b;
        if (!a && b && b.__editArray) return b;
        if (a && a.__editArray && b && b.__editArray) {
          let out = a;
          if (b.push) {
            if (!a.push) out = { ...out, __editArray: true, push: b.push };
            else out = { ...out, push: [...a.push, ...b.push] };
          }
          if (b.remove) {
            if (!a.remove) out = { ...out, __editArray: true, remove: b.remove };
            else out = { ...out, remove: [...a.remove, ...b.remove] };
          }
          if (b.swap) {

            if (!a.swap) out = { ...out, __editArray: true, swap: b.swap };
            let aSwap = [...a.swap]; let bSwap = [...b.swap];
            if (aSwap.length <= bSwap.length) {
              aSwap = bSwap.map((_, i) => aSwap[i] ?? i);
            } else {
              bSwap = aSwap.map((_, i) => bSwap[i] ?? i);
            }
            const swap = bSwap.map(i => aSwap[i]);
            out = { ...out, __editArray: true, swap };
          }
          return out;
        }
      });
      await new Promise(resolve => {
        debouncer.run( async () => {
          data = _.cloneDeep(valuesToMerge);
          valuesToMerge = {};
          await usersService.patch(userId, data, { query: params });
          resolve();
        }, 2000);
      });
      
      return {};
    } else {
      return await usersService.patch(userId, data, { query: params });
    }
  } catch (err) {
    console.log(err);
    return {
      error: true,
      message: err.message,
      validation: err.data?err.data.validation:{}
    };
  }
}

export async function userPatchDebounce(payload) {
  
  debouncer.run(async () => {
    try {
      console.log('Debounced patch firing')
      console.log(JSON.stringify(payload));
      let { data, userId, params } = payload;
      if (!userId) return;
      await usersService.patch(userId, data, { query: params });
    } catch (err) {
      console.log(err);
    }
  }, 1000);
  
}

export async function shortlistCreate(payload) {
  try {
    console.log("test");
    let spop = shortlistService.create(payload);
    return await spop;
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function shortlistRemove(payload) {
  try {
    console.log('removing from shortlist id:', payload)
    return await shortlistService.remove(payload);
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function applicationCreate(payload) {
  try {
    return await applicationService.create(payload);
  } catch (error) {
    console.log(error);
    return { error };
  }
}

export async function applicationPatch(payload) {
  try {
    return await applicationService.patch(payload.userId, payload.data);
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function addProductToApplication(payload) {
  try {
    console.log("test");
    return await applicationProductsService.create(payload);
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function removeProductFromApplication(payload) {
  try {
    console.log("test");
    return await applicationProductsService.remove(payload.applicationId, {query: {product_id: payload.product_id}});
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function forgotPassword(payload) {
  try {
    return await verificationService.create({
      email: payload.email,
      type: "forgot"
    });
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function resetPassword(payload) {
  try {
    return await verificationService.create({
      type: "password-reset",
      email: payload.email,
      password: payload.password,
      token: payload.token
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function setAccountAbused(payload) {
  try {
    return await verificationService.create({
      type: "set-account-abused",
      email: payload.email,
      token: payload.token
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function tokenLogin(payload) {
  try {
    let {accessToken} = await verificationService.create({
      type: payload.type || "login",
      email: payload.email,
      token: payload.token
    });
    app.logout();
    return await app.authenticate({
      strategy: 'jwt',
      accessToken: accessToken,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {error: err};
  }
}

export async function uploadFile({file, type}) {
  const token = await app.passport.getJWT();
  var req = request
    .post(hostUrl + "/file-upload")
    .set('Authorization', token)
    .field('type', type)
    .attach('uri', file)
    .on('progress', event => {
      console.log('uploaded: ' + event.percent + '%');
      let fileInStore = store.getState().user.uploads.filter(f=>f.name===file.name)[0];
      if (!fileInStore) {
        return;
      }
      if (store.getState().user.uploads.filter(f=>f.name===file.name)[0].cancel) {
        store.dispatch(receiveRemoveFile({
          name: file.name
        }))
        req.abort();
        return;
      }
      store.dispatch(receivePatchFile({
        name: file.name,
        percentUploaded: event.percent
      }))
    });
    req.end((err, res) => {
      console.log('COMPLETE: res.body');
      store.dispatch(receivePatchFile(res.body))
    });
    console.log('test');
    return;
}

// export async function uploadFile({file, type}) {
//   const token = await app.passport.getJWT();

//   const data = new FormData()
//   data.append('uri', file);

//   request
//     .post('http://localhost:3030/file-upload', data, {
//       onUploadProgress: ProgressEvent => {
//         let percent = (ProgressEvent.loaded / ProgressEvent.total*100);
//         console.log('uploaded: ' + percent + '%');
//         store.dispatch(receivePatchFile({
//           name: file.name,
//           percentUploaded: percent
//         })),
//         {
//           headers: {
//               'Content-Type': 'multipart/form-data',
//               'Authorization': token
//           }
//         }
//       },
//     })
//     .then(res => {
//       console.log('COMPLETE: res.body');
//       store.dispatch(receivePatchFile(res.body))
//     })
//     return;
// }

export async function patchFile(payload) {
  try {
    console.log("test");
    return await filesService.patch(payload.id, payload.data);
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function removeFile({id}) {
  try {
    return await filesService.remove(id);
  } catch (err) {
    console.log(err);
    return {err};
  }
}


export async function oauthMerge(payload) {
  try {
    return await verificationService.create({
      type: "oauth-merge",
      email: payload.email,
      token: payload.token
    }); 
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function secureLink(payload) {
  try {
    return await verificationService.create({
      type: payload.type || "create-login-link",
      email: payload.email,
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function emailVerification(payload) {
  try {
    return await verificationService.create({
      type: "validate",
      email: payload.email,
      token: payload.token
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function sendValidationEmail(payload) {
  try {
    return await verificationService.create({
      email: payload.email,
      type: "send-verification",
      continue: payload.continue
    });
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function createReferral(payload) {
  let result;
  try {
    result = await usersService.create(payload);
  } catch (error) {
    console.log(error);
    return { error };
  }
  return result;
}

export async function addNoteToReferral(payload) {
  let result;
  try {
    result = await applicationService.patch(payload.id, payload);
  } catch (error) {
    console.log(error);
    return { error };
  }
  return result;
}
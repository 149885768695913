import React from "react";
import { Link } from "react-router-dom";

import { unset } from "lodash";

export default () => {
  return (
    <div className="disclaimer">
      <p className="text-large">
        Secure Finance Services Pty Ltd · Australian Credit License Number
        465059
      </p>
      <p className="text-small">
        Head Office: 204/11 Solent Circuit, Norwest NSW 2153
      </p>
      <p className="text-small" style={{ marginTop: "10px" }}>
        <a href="CreditGuide.pdf" target="_blank">
          Credit Guide
        </a>
        <Link style={{ all: unset }} to="/privacy-policy">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};

import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, blur } from 'redux-form';

import Navigation from "Components/Navigation/Navigation";
import LinkButton from "Components/LinkButton";
import {requestPasswordReset} from "modules/user/actions";

import getQueryParams from "utils/getQueryParams";
import { PasswordField } from "Components/Forms";
import { createPasswordValidation, createRequired } from 'Components/Forms/validation';

const passwordValidation = createPasswordValidation();
const passwordRequired = createRequired('Please enter a password');
const confirmationRequired = createRequired('You must confirm your password');



const selector = formValueSelector('login')
function mapStateToProps(state) {
  return {
    userId: state.user.id,
    password: selector(state, 'password'),
    confirmPassword: selector(state, 'confirmPassword'),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPasswordReset,
    blur,
  }, dispatch);
}

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      submitted: false,
      password: ''  
    };
  }

  onSubmit = ({ password }) => {
    const { email, token } = getQueryParams(this.props.location.search);

    this.props.requestPasswordReset({
      data: {
        password,
        email,
        token,
      },
      callback:(result)=>{
        if (result.err) {
          alert(result.err.message);
        } else {
          this.setState(()=>{
            return {submitted: true}
          });
        }
    }});
  }
  


  passwordsMatch = (value, values) => 
    value === values.password ? null : 'Passwords do not match';

  onPasswordChanged = e => {
    this.setState({ password: e.target.value }, () => {
      this.props.blur('login', 'confirmPassword', this.props.confirmPassword);
    });
  }

  render() {
    let loginLink = (this.props.userId)?"/login-form":"/login";
    return (
    <div>
      <Navigation state="unactive" />
      <main className="user-authentication">
        {
          (this.state.submitted)?(
            <div className="modal single">
              <h2>Success!</h2>
              <h4>Your password has been successfully updated.</h4>
              <form>
                <LinkButton className="button-1 full-width-button" exact to={loginLink}>
                  Go to Sign In
                </LinkButton>
              </form>
            </div>
          ):(
            <div className="modal single">
              <h2>Create a password</h2>
              <h4>Please set a new password to continue</h4>
              <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <label>Password</label>
                <PasswordField onChange={this.onPasswordChanged} id='password' hideOptional name="password" validate={[passwordRequired, passwordValidation]}/>
                <label>Confirm Password</label>
                <PasswordField id='confirmPassword' name="confirmPassword" hideOptional validate={[confirmationRequired, this.passwordsMatch]} />
                <button type="submit">Setup my New Password & Continue</button>
              </form>
            </div>
        )};
      </main>
  </div>
  );
}
}

ResetPassword = reduxForm({
  form: "login"
})(ResetPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal, openRefinanceModal, openModalWithArgs } from 'modules/modals/actions';
import CheckEligibilityButton from "Components/CheckEligibilityButton";
import Tooltips from "Components/Tooltips";
import BorrowingPowerValue from "Components/BorrowingPowerValue"

import { removeFromShortlist, requestPatchUser } from 'modules/user/actions';
import calculateLoanValues from "utils/calculateLoanValues"

import { reduxForm } from "redux-form";
import { FormContainer, SelectGroup } from "Components/Forms";
import BankLogo from 'Components/Global/BankLogo';

const $ = window.$;

function mapStateToProps(state) {
  return {
    nsrValues: state.user.nsrValues,
    userNotMeta: state.user,
    user: state.user.meta,
    userId: state.user.id,
    products: state.products.list,
    repaymentFrequency: state.user.meta.searchFilter_repaymentFrequency,
    loanPurpose: state.user.meta.loanPurpose,
    nsrValues: state.user.nsrValues,
    initialValues: {payment_frequency: "monthly"}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    openRefinanceModal,
    removeFromShortlist,
    requestPatchUser,
    openModalWithArgs,
  }, dispatch);
}

class LoanShortlistResult extends Component {

  constructor(props) {
    super(props);

    this.state= {isUnstarShown: false, isShownMore: true};
  }

  toggleUnstarPopup(){
    const { isUnstarShown } = this.state;
    if (!isUnstarShown) {
      $(window).click(e => {
        if (!$(e.target).closest('.favourites').length 
          && $(e.target).attr('class') !== 'pick-avatar') {

          this.setState({isUnstarShown: false});
        }
      });  
    } else {
      $(window).off();
    }
    this.setState({isUnstarShown: !isUnstarShown});
  }

  toggleDashboardShowMore(){
    let newState = this.props.user.pageState_dashboard_loanshortlist_showmore?0:1;
    this.props.requestPatchUser({userId: this.props.userId, data: {meta: {pageState_dashboard_loanshortlist_showmore: newState}}, callback: ()=>{}});
  }

  toggleCheckEligibilityShowMore(){
    let newState = this.props.user.pageState_checkeligibility_loanshortlist_showmore?0:1;
    this.props.requestPatchUser({userId: this.props.userId, data: {meta: {pageState_checkeligibility_loanshortlist_showmore: newState}}, callback: ()=>{}});
  }

  openRefinanceModal = product => {
    const isInitialised = !!this.props.user.initialLoanDetails;
    if (isInitialised) {
      this.props.openRefinanceModal(product);
    }
    else {
      this.props.openModalWithArgs({
        modal: 'refinanceAnalysisStart',
        args: product
      });
    }
  }

  submitForm() {
    setTimeout(() => this.props.handleSubmit(), 100);
  }

  render() {

    let product = calculateLoanValues(this.props.calculateBox);
    
    const { repaymentFrequency, loanPurpose } = this.props;
    return (
      <div className={toggleTypeClass(this.props.type)}>
          {product.rebateTagContent && 
            Award(product.rebateTagContent)
          }
        <div className="lender">
          <div onClick={()=>this.toggleUnstarPopup()} className="favourites">
            {this.state.isUnstarShown ?
              <a className="confirmFavourites" onClick={()=>this.props.removeFromShortlist({ data: product.id })}>Click here to unstar</a>:
              ""
            }
          </div>
          {loanPurpose === 'refinance' &&
            <button onClick={()=>this.openRefinanceModal(product)} className="refinance"></button>
          }
          {(this.props.display == "loan-details")?<CheckEligibilityButton type="loan-details" product={this.props.calculateBox} />:""}
          <BankLogo product={product} />
          <h6>{product.product_name}</h6>
        </div>

        <div className="payments">
        ${Math.round(repaymentValue(product, repaymentFrequency)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          <span className="field-description">Payment
            <FormContainer onSubmit={this.handleSubmit}>
              <SelectGroup onChange={() => this.submitForm()} empty="false" id='user_meta-searchFilter_repaymentFrequency' width='small' position='left' hideOptional type='select' placeholder='0' 
                values={[
                {value: 'monthly', text: 'Monthly'},
                {value: 'fortnightly', text: 'Fortnightly'},
                {value: 'weekly', text: 'Weekly'},
                ]} />
            </FormContainer>
          </span> 
        </div>

        <div className="interest-rate">
          <div className="variable-rate">
            <div className="rate">{product.iir}<span>% p.a.</span></div>
            <div className="field-description">{product.rateDescriptionYears}</div>
          </div>
          <div className="comparison-rate">
            <div className="rate">{product.comparisonRate}<span>% p.a.</span></div>
            <div className="field-description">Comparison Rate</div>
          </div>
        </div>

        {(this.props.type === "dashboard" && !this.props.user.pageState_dashboard_loanshortlist_showmore)
        ?
          ""
        :
        <div>
          <div className="total-cost">
            ${Math.round(totalCost(product, repaymentFrequency)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            <span className="field-description">Lifetime Cost</span>
          </div>

          {(this.props.type === "check-eligibility")
            ?
            ""
            :
            <div className="borrowing-power">
              <BorrowingPowerValue 
                value={(this.props.nsrValues[product.bank] || this.props.nsrValues['default'])} 
                user={this.props.userNotMeta} 
                lender={this.props.userNotMeta.lenders.find(lender => lender.id === product.bank)}
                product={product}
              />
              <span className="field-description">Borrowing Power</span>
            </div>
          }

          <div className="repayment-type">
            {product.repaymentType}{(product.repaymentType == "Interest Only")?<Tooltips type="definition" term="interestOnlyShortlist"/>:(<Tooltips type="definition" term="piOrInterestShortlist"/>)}
            <span className="field-description">Repayment Type</span>
          </div>

          <div className="fees">
              <div className="upfront-fee">${product.approxUpfrontFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              <span className="field-description">Approx Upfront</span>
            </div>
              <div className="ongoing-fee">${product.ongoing_fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              <span className="field-description">Annual Fees</span>
            </div>
          </div>

          <div className="cost-reducers">
            <ul>
              <li className={product.redraw_allowed}>Redraw</li>
              <li className={product.full_offset}>
                {product.full_offset === 'additional' ? 
                  <Tooltips type="wrapper" definition={product.offsetTooltip}>Offset</Tooltips>
                  :
                  <span>Offset</span>
                }
              </li>
              <li className={product.extra_repayments_allowed}>Early Repayments</li>
            </ul>
          </div>

          {(this.props.type === "check-eligibility")
            ?
              <div className={!(this.props.user.pageState_checkeligibility_loanshortlist_showmore)?"show-more hide":"show-more show"}>
                <button onClick={()=>this.toggleCheckEligibilityShowMore()}><span>{!(this.props.user.pageState_checkeligibility_loanshortlist_showmore)?"Show More":"Show Less"}</span></button>
              </div>
            :
            ""
          }

          {!(this.props.user.pageState_checkeligibility_loanshortlist_showmore) && (this.props.type === "check-eligibility") ?
            <div className="more-information">
              <div className="additional-features">Locked Rate, Partial Offset, Splitting</div>
              <div className="loan-types">Bridging, First Home Buyer, Owner Builder</div>
            </div>
            :
            ""
          }

        </div>
        }

        {(this.props.type === "dashboard")?
        <div className={!(this.props.user.pageState_dashboard_loanshortlist_showmore)?"show-more hide":"show-more show"}>
          <button onClick={()=>this.toggleDashboardShowMore()}><span>{!(this.props.user.pageState_dashboard_loanshortlist_showmore)?"Show More":"Show Less"}</span></button>
        </div>: ""}

        {(this.props.type === "check-eligibility")?
          ""
          :
          <div>
            <div className="more-info">
              <LinkButton to={"/products/" + product.id} className="details">Details</LinkButton>
              <CheckEligibilityButton how product={this.props.calculateBox} />
            </div>
          </div>
        }

      </div>
    );
  }
}

function repaymentValue(product, freq) {
  if (freq === 'weekly') return product.weeklyRepayments;
  if (freq === 'fortnightly') return product.fortnightlyRepayments;
  return product.monthlyRepayments;
}

function totalCost(product, freq) {
  if (freq === 'weekly') return product.totalCostWeekly;
  if (freq === 'fortnightly') return product.totalCostFortnightly;
  return product.totalCost;
}

function toggleTypeClass(type){
  if (type === "dashboard") {
    return "col-c3 shortlist-result";
  }
  if (type === "check-eligibility") {
    return "check-eligibility-selection shortlist-result";
  }
  else {
    return "shortlist-result";
  }
}

function Award(value){
  if (value !== ''){
    return <div className="best">{value}</div>
  }
  else {
    return ''
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(LoanShortlistResult);

LoanShortlistResult = reduxForm({
  form: 'searchFilter',
  destroyOnUnmount: false
})(LoanShortlistResult);

export default connect(mapStateToProps, mapDispatchToProps)(LoanShortlistResult);
import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { FieldGroup } from "Components/Forms";
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { Header, Subtitle } from "../../theme/General";
import SectionButton from "../../component/SectionButton";
import RefinanceAnalysis from "../../../RefinanceAnalysis";
import calculateLoanValues from "utils/calculateLoanValues"

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal
  }, dispatch);
}

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  return {
    shortlist: state.user.shortlist,
    user: state.user.meta,
    products,
    application
  };
}

class RefinanceSavings extends React.Component {

  render() {
    let product = this.props.product;

    return (
      <RefinanceSavingsContainer>
        <Heading>Your Potential Refinance Savings</Heading>
        <SubHeading>Based on recommended solution</SubHeading>
        <RefinanceAnalysis isPDF={this.props.isPDF} currentLoan={this.props.currentLoan} product={product} result={this.props.result} type="proposal" />
      </RefinanceSavingsContainer>
    )
  }

}

const Heading = styled(Header)`
  margin-bottom: 2px !important;

  @media (min-width: 950px) {
    margin-bottom: 10px !important;
  }
`

const SubHeading = styled.h3`
    @media (min-width: 950px) {
      display: none;
    }
    width: 100%;
    font-weight: 400;
    display: inline-block;
    text-align: center;
    font-size: 12px !important;
    margin-bottom: 10px !important;
    color: #727C8F;
`;

const RefinanceSavingsContainer = styled.div`
  // background-color: red;

  .refinance-content {
    margin-top: 10px !important;
    /* @media (max-width: 480px) {
      margin: 0 -20px 10px -20px !important;
      width: 100vw !important;
    } */
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(RefinanceSavings);

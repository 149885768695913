import { productsService } from '../index';

function processProductData(_product) {
  let product = {..._product};
  switch (product.initial_ir_type) {
    case "V":
      product.initial_ir_type = "Variable";
      break;
    case "F":
      product.initial_ir_type = "Fixed";
      break;
    default: break;
  }

  return product;
}

export async function findProducts(payload) {
  try {
    let products = await productsService.find(payload);
    //console.log(products);
    let list = products.data.map((product)=>processProductData(product));
    //list.sort((a,b)=>parseFloat(a.truerate) - parseFloat(b.truerate));
    //console.log(list);
    const isFinished = products.total <= (products.limit + products.skip);
    return {list, isFinished, total: products.total};
  } catch (err) {
    return [];
  }
}

export async function getProduct(payload) {
  try {
    return await processProductData(productsService.get(payload).data);
  } catch (err) {
    return {};
  }
}

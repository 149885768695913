import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { reduxForm } from 'redux-form';

import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, Heading3, Heading4, KeyLine, UnsavedChangesPrompt, timePeriod } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import FormNavigation from "../Components/FormNavigation";
import { FadeIn } from './Components/MultiStageForm';
let SimpleForm = props => {

  // const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <FormContainer onSubmit={props.handleSubmit}>
      <h1>Living Expenses</h1>
      <p className='under-heading'>Part of the process of qualifying you for a loan requires us to understand your living expenses. Please provide details below to the best of your knowledge.</p>

      {toggleHouseExpenses(props.ownHome, props.loanPurpose, props.propertyPurpose, props.housingType, props)}
      {(props.ownHome === 'Yes' || 
        props.propertyPurpose === 'purposeToLiveIn' || 
        props.housingType === 'Living With Family (rent-free)' || 
        !!props.housingAmount ||
        !!props.housingOther) &&
        
        <FadeIn>
          <Heading3 width='large' position='left' title='Would you like to fill in a detailed budget now?' />
          <SelectGroup required id='user_meta-otherLivingExp' title='' width='small' position='left' type='select' placeholder='Yes' values={['Yes','No']} />
          {toggleOtherLivingExpenses(props.otherLivingExp, props.applicantType, props)}
          
        </FadeIn>
      }
      {(props.otherLivingExp === 'Yes' || !!props.basicTotalMonthlyExp) &&
        <FadeIn>
          <FormNavigation currentPage="6" disabled={props.invalid} submitting={props.submitting}/>
        </FadeIn>
      }
      
      
      <UnsavedChangesPrompt when={!(props.pristine || props.submitting) }/>
  </FormContainer>
  );
};

function toggleOtherLivingExpenses(otherLivingExp, applicantType, props){
  if (otherLivingExp === "Yes") {
    return (
      <FadeIn>
        <Heading4 width='large' position='left' title='Lifestyle' />
        <DoubleFieldGroup hideOptional id='user_meta-foodGroceriesExp' width='small' position='left' title='Food &amp; Groceries' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-phoneInternetPayExp' width='small' position='right' title='Phone, Internet, Pay TV' type='monetary-time' mask='monetary' placeholder='0' mask="monetary"/>
        <DoubleFieldGroup hideOptional id='user_meta-entertainmentExp' width='small' position='left' title='Entertainment' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <KeyLine />

        <Heading4 width='large' position='left' title='Transport' />
        <DoubleFieldGroup hideOptional id='user_meta-vehicleRegistrationExp' width='small' position='left' title='Vehicle Registration, Insurance, Servicing' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-motorVehiclePetrolExp' width='small' position='right' title='Motor Vehicle Petrol, Tolls, Parking' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-publicTransportExp' width='small' position='left' title='Public Transport, Uber, Taxi, etc.' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <KeyLine />

        <Heading4 width='large' position='left' title='Insurance' />
        <DoubleFieldGroup hideOptional id='user_meta-healthOtherExp' width='small' position='left' title='Health &amp; Other Personal' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-homeContentsLandlordExp' width='small' position='right' title='Home/Contents/Landlord' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <KeyLine />

        <Heading4 width='large' position='left' title='Property' />
        <DoubleFieldGroup hideOptional id='user_meta-councilRatesExp' width='small' position='left' title='Council &amp; Strata Rates' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-utilitiesExp' width='small' position='right' title='Utilities (Electricity, Gas, Water)' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <KeyLine />

        <Heading4 width='large' position='left' title='Miscellaneous' />
        <DoubleFieldGroup hideOptional id='user_meta-childcareMaintenanceExp' width='small' position='left' title='Childcare, Maintenance' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-educationExp' width='small' position='right' title='Education' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <DoubleFieldGroup hideOptional id='user_meta-otherExp' width='small' position='left' title='Other' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
        <KeyLine />
        <div className="total-monthly-expenses">
          <h4>Total Monthly Expenses </h4>
          <h4 className="totals">${Math.round(props.expenses).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
        </div>
      </FadeIn>
    );
  }
  if (otherLivingExp === "No") {
    if (applicantType === "youAndYourPartner") {

    return (
      <FadeIn>
        <Heading3 width='large' position='left' title='What are your approximate total monthly living expenses?' />
        <FieldGroup required id='user_meta-basicTotalMonthlyExp' title='' width='small' position='left' type='text' placeholder='0' mask="monetary" bottomLabel='If applicable, please include your investment property council rates and strata fees.'/>
      </FadeIn>
    );
    }
    else {
      return (
        <FadeIn>
          <Heading3 width='large' position='left' title='What are your approximate total monthly living expenses?' />
          <FieldGroup id='user_meta-basicTotalMonthlyExp' title='' width='small' position='left' required='Yes' type='text' placeholder='0' mask="monetary" bottomLabel='If applicable, please include your investment property council rates and strata fees.' />
        </FadeIn>
    );
    }
  }
  else {
    return "";
  }
}

function toggleHouseExpenses(ownHome, loanPurpose, propertyPurpose, housingType) {
  //console.log(ownHome, loanPurpose, propertyPurpose)
  if ((ownHome === "No") && (propertyPurpose !== "purposeToLiveIn")) {
    return (
      <div>
        <Heading3 width='large' position='left' title='Housing Expenses' />
        <SelectGroup id='user_meta-housingType' width='small' position='left' title='What is your housing situatation?' required='Yes' type='select' placeholder='Rent' values={['Renting', 'Boarding', 'Living With Family (rent-free)', 'Other']} />
        <div/>
        {toggleHousingSituation(housingType)}
      </div>
    );
  }
  else {
    return "";
  }

}

function toggleHousingSituation(housingType) {
  if (housingType === "Renting" || housingType === "Boarding") {
    return (
      <FadeIn>
         <DoubleFieldGroup id='user_meta-housingAmount' width='small' position='left' title={`${housingType === 'Renting' ? 'Rental' : 'Boarding'} Expenses`} required='Yes' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
      </FadeIn>
    );
  }
  if (housingType === "Other") {
    return (
      <FadeIn>
        <FieldGroup id='user_meta-housingOther' width='large' position='left' title='Other?' required='Yes' type='text' placeholder='Please explain.' />
      </FadeIn>
    );
  }
  else {
    return ""
  }
}

function totals(expenses){
    const peopleArray = Object.keys(expenses).map(i => expenses[i] || 0);
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    return peopleArray.reduce(reducer);
}

SimpleForm = reduxForm({
  form: 'eligibility-expenses',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-expenses') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // external values
    const loanPurpose = state.user.applications[0].meta.loanPurpose;
    const propertyPurpose = state.user.applications[0].meta.propertyPurpose;
    const ownHome = state.user.meta.ownHome;
    const housingType = selector(state, 'user_meta-housingType');
    const housingAmount = selector(state, 'user_meta-housingAmount') || 0;
    const housingAmountType = selector(state, 'user_meta-housingAmountType');
    const housingOther = selector(state, 'user_meta-housingOther');
    const applicantType = state.user.applications[0].meta.applicantType;
    const basicTotalMonthlyExp = selector(state, 'user_meta-basicTotalMonthlyExp');

    // internal values
    const MONTH = 1/12;

    const foodGroceriesExp = selector(state, 'user_meta-foodGroceriesExp') || 0;
    const foodGroceriesExpPeriod = selector(state, 'user_meta-foodGroceriesExpType') || MONTH;
    const phoneInternetPayExp = selector(state, 'user_meta-phoneInternetPayExp') || 0;
    const phoneInternetPayExpPeriod = selector(state, 'user_meta-phoneInternetPayExpType') || MONTH;
    const entertainmentExp = selector(state, 'user_meta-entertainmentExp') || 0;
    const entertainmentExpPeriod = selector(state, 'user_meta-entertainmentExpType') || MONTH;
    const vehicleRegistrationExp = selector(state, 'user_meta-vehicleRegistrationExp') || 0;
    const vehicleRegistrationExpPeriod = selector(state, 'user_meta-vehicleRegistrationExpType') || MONTH;
    const motorVehiclePetrolExp = selector(state, 'user_meta-motorVehiclePetrolExp') || 0;
    const motorVehiclePetrolExpPeriod = selector(state, 'user_meta-motorVehiclePetrolExpType') || MONTH;
    const publicTransportExp = selector(state, 'user_meta-publicTransportExp') || 0;
    const publicTransportExpPeriod = selector(state, 'user_meta-publicTransportExpType') || MONTH;
    const healthOtherExp = selector(state, 'user_meta-healthOtherExp') || 0;
    const healthOtherExpPeriod = selector(state, 'user_meta-healthOtherExpType') || MONTH;
    const homeContentsLandlordExp = selector(state, 'user_meta-homeContentsLandlordExp') || 0;
    const homeContentsLandlordExpPeriod = selector(state, 'user_meta-homeContentsLandlordExpType') || MONTH;
    const councilRatesExp = selector(state, 'user_meta-councilRatesExp') || 0;
    const councilRatesExpPeriod = selector(state, 'user_meta-councilRatesExpType') || MONTH;
    const utilitiesExp  = selector(state, 'user_meta-utilitiesExp') || 0;
    const utilitiesExpPeriod  = selector(state, 'user_meta-utilitiesExpType') || MONTH;
    const childcareMaintenanceExp = selector(state, 'user_meta-childcareMaintenanceExp') || 0;
    const childcareMaintenanceExpPeriod = selector(state, 'user_meta-childcareMaintenanceExpType') || MONTH;
    const educationExp = selector(state, 'user_meta-educationExp') || 0;
    const educationExpPeriod = selector(state, 'user_meta-educationExpType') || MONTH;
    const otherExp = selector(state, 'user_meta-otherExp') || 0;
    const otherExpPeriod = selector(state, 'user_meta-otherExpType') || MONTH;
    
    const yearlyExpenses = 
      housingAmount / housingAmountType +
      foodGroceriesExp / foodGroceriesExpPeriod +
      phoneInternetPayExp / phoneInternetPayExpPeriod + 
      entertainmentExp / entertainmentExpPeriod +
      vehicleRegistrationExp / vehicleRegistrationExpPeriod + 
      motorVehiclePetrolExp / motorVehiclePetrolExpPeriod + 
      publicTransportExp / publicTransportExpPeriod + 
      healthOtherExp / healthOtherExpPeriod + 
      homeContentsLandlordExp / homeContentsLandlordExpPeriod + 
      councilRatesExp / councilRatesExpPeriod + 
      utilitiesExp / utilitiesExpPeriod +
      childcareMaintenanceExp / childcareMaintenanceExpPeriod + 
      educationExp / educationExpPeriod + 
      otherExp / otherExpPeriod;

    const expenses = yearlyExpenses / 12;
    
  
    const otherLivingExp = selector(state, 'user_meta-otherLivingExp');

    return {
      otherLivingExp,
      ownHome,
      loanPurpose,
      propertyPurpose,
      housingType,
      applicantType,
      expenses,
      housingAmount,
      housingOther,
      basicTotalMonthlyExp,
      initialValues: {
        //'user_meta-otherLivingExp': 'No',
        'user_meta-housingAmountType': timePeriod.WEEK,
        'user_meta-foodGroceriesExpType': timePeriod.MONTH,
        'user_meta-phoneInternetPayExpType': timePeriod.MONTH,
        'user_meta-entertainmentExpType': timePeriod.MONTH,
        'user_meta-vehicleRegistrationExpType': timePeriod.MONTH,
        'user_meta-motorVehiclePetrolExpType': timePeriod.MONTH,
        'user_meta-publicTransportExpType': timePeriod.MONTH,
        'user_meta-healthOtherExpType': timePeriod.MONTH,
        'user_meta-homeContentsLandlordExpType': timePeriod.MONTH,
        'user_meta-councilRatesExpType': timePeriod.MONTH,
        'user_meta-utilitiesExpType': timePeriod.MONTH,
        'user_meta-childcareMaintenanceExpType': timePeriod.MONTH,
        'user_meta-educationExpType': timePeriod.MONTH,
        'user_meta-otherExpType': timePeriod.MONTH,
        'user_meta-housingAmountType': timePeriod.MONTH,
        'user_meta-housingOtherType': timePeriod.MONTH,
        ...getInitialValuesObject(state), 
        "user_meta-eligibility_complete_page6": "1", 
        "user_meta-eligibility_last_page": 6}
    }
  }
)(SimpleForm);

export default SimpleForm;
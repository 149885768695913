import React from "react"; import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { requestPatchUser } from 'modules/user/actions';

const $ = window.$;

function mapStateToProps(state) {
  return {
    user: state.user.meta,
    userId: state.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class PickAvatar extends Component {

  constructor(props) {
    super(props);
    this.state= {isAvatarShown: false};
  }

  toggleAvatarPopup(){
    const { isAvatarShown } = this.state;
    if (!isAvatarShown) {
      $(window).click(e => {
        if (!$(e.target).closest('.pop-up').length 
          && $(e.target).attr('class') !== 'pick-avatar') {
          
          this.setState({isAvatarShown: false});
        }
      });  
    } else {
      $(window).off();
    }
    this.setState({isAvatarShown: !isAvatarShown});
  }

  AvatarPictureType(val){
    let newState = val;
    this.props.requestPatchUser({userId: this.props.userId, data: {meta: {profile_picture: newState}}, callback: ()=>{}});
  }

  render() {
    return (
      <div className="avatar-selector">
        <button onClick={()=>this.toggleAvatarPopup()} className="pick-avatar">Pick an Avatar</button>

        {this.state.isAvatarShown ?
          <div className="pop-up">
            <div className="content">
              <div className="row n1">
                <button onClick={()=>this.AvatarPictureType(1)} className={"avatar-pic n1 " + ((this.props.user.profile_picture === "1")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(2)} className={"avatar-pic n2 " + ((this.props.user.profile_picture === "2")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(3)} className={"avatar-pic n3 " + ((this.props.user.profile_picture === "3")?"selected":"")}></button>
              </div>
              <div className="row n2">
                <button onClick={()=>this.AvatarPictureType(4)} className={"avatar-pic n4 " + ((this.props.user.profile_picture === "4")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(5)} className={"avatar-pic n5 " + ((this.props.user.profile_picture === "5")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(6)} className={"avatar-pic n6 " + ((this.props.user.profile_picture === "6")?"selected":"")}></button>
              </div>
              <div className="row n2">
                <button onClick={()=>this.AvatarPictureType(7)} className={"avatar-pic n7 " + ((this.props.user.profile_picture === "7")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(8)} className={"avatar-pic n8 " + ((this.props.user.profile_picture === "8")?"selected":"")}></button>
                <button onClick={()=>this.AvatarPictureType(9)} className={"avatar-pic n9 " + ((this.props.user.profile_picture === "9")?"selected":"")}></button>
              </div>
            </div>
          </div>
          :""
        }

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickAvatar);

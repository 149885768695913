import store from 'store';
import { pingHost } from 'modules';
import { setHostIsDead } from "modules/local/actions";

const isHostAlive = async () => {
  console.log("isHostAlive");
  try {
    const { success } = await pingHost();
    console.log("Succes: " + success);
    return success;
  } catch (err) {
    console.log("FAILED");
    return false;
  }
}

let interval;

const hostIsAlive = async function () {
  store.dispatch(setHostIsDead(false));
  clearInterval(interval);
}

const hostIsDead = async function () {
  store.dispatch(setHostIsDead(true));
  interval = setInterval(async () => {
    if (await isHostAlive()) {
      hostIsAlive();
    }
  }, 25000);
}

let hasJustChecked = false;

export default async function checkHost() {
  if (hasJustChecked) {
    return;
  }

  hasJustChecked =  true;
  setTimeout(()=>{
    hasJustChecked =  false;
  }, 1000);

  if (!(await isHostAlive())) {
    hostIsDead();
  } else {
    hostIsAlive();
  }
}
import React, { useEffect, useState, useRef } from 'react';
import styled, { css }from 'styled-components';


export const Img = ({ src, loader, fallback, className, width, height, maxFill, ...props }) => {
  const [ srcIndex, setSrcIndex ] = useState(0);
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  
  const container = useRef(null);

  useEffect(() => {
    const img = new Image();
    //img.className = className;
    
    
    img.onload = () => {

      // area = the ratio that the image would cover of the bounding box if you used "object-fit: contain"
      // The value will be between 0 (no area covered) and 1 (100% covered)
      const boundingRatio = width / height;
      const targetRatio = img.width / img.height;
      let area = boundingRatio / targetRatio;
      if (area > 1) {
        area = 1 / area;
        width *= area;
      } else {
        height *= area;
      }
      
      // maxFill is the maximum area allowed (again, from 0-1). 
      // If area exceeds this value the image will be scaled exactly so that its area = maxFill;
      maxFill = maxFill || 1;
      if (area > maxFill) {
        let reductionRatio = Math.sqrt(maxFill / area);
        height *= reductionRatio;
        width *= reductionRatio;
      }

      setLoading(false);
      img.style = css`
        object-fit: contain;
      `;
      img.width = width;
      img.height = height;
      img.crossOrigin = true;
      if (!container.current || !container.current.appendChild) {
        setError(true);
        return;
      }
      container.current.innerHTML = '';
      container.current.appendChild(img)
    };

    if (!Array.isArray(src)) {
      //src is not an array
      img.onerror = () => {
        setError(true);
      };
      img.src = src;
        
    } else {
      //src is an array
      img.onerror = () => {
        if (!error) {
          if (srcIndex < src.length - 1) {
            setSrcIndex(srcIndex + 1)
          } else {
            setError(true);
          }
        }
      };
      img.src = src[srcIndex];
    }
  }, [srcIndex, width, height]);

  let content = null;
  if (loading) content = loader;
  if (error) content = fallback;
  

  return (
    <Container ref={container} className={className} {...props}>
      {content}
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
`;
import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { createRequired, createValidateEmail } from 'Components/Forms/validation';
import { reduxForm, Field, SubmissionError } from 'redux-form';

import Navigation from "Components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import { requestForgotPassword } from "modules/user/actions";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";
import Debouncer from "utils/debouncer";

const debouncer = new Debouncer();
const emailRequired = createRequired('Please enter your email');
const emailValidation = createValidateEmail();

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestForgotPassword
  }, dispatch);
}

class ForgotPasswordComponent extends Component {

  state = { submitted: false, email: '' }

  submit = ({ email }) => {

    return new Promise((resolve, reject) => 
      debouncer.run(() => {
        this.props.requestForgotPassword({
          email, callback: response => {
            if (response.status !== 'success') {
              return reject( new SubmissionError({
                _error: 'An account with this email does not exist',
              }));
            }
            this.setState({ submitted: true, email });
            return resolve();
          }
        })
      }, 5000)
    );
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    const { submitted } = this.state;
    return (
      <div>
        {submitted ? (
            <div>
            <h2>Password reset request submitted</h2>
            <h4>An email with a link to reset your password has been sent to: {this.state.email}<br /><br /></h4>
            </div>
            ) : (
            <div style={{ position: 'relative' }}>
            <h2>Don't remember your password?</h2>
            <h4>Please enter your e-mail address below and we will send you a password reset link.</h4>
              <form onSubmit={handleSubmit(this.submit)}>
                  <Field component={Input} name="email" placeholder="Enter e-mail address" validate={[emailRequired, emailValidation]} />
                  {error && <div className="validation" style={{position: 'absolute'}}>{error}</div>}
                  <button className='forgot-password' style={{marginTop: 30}} type="submit"><ButtonSpinner loading={submitting}/>Reset My Password</button>
              </form>
            </div>
        )}
      </div>
    );
  }
}

const Input = ({meta: {touched, error}, input, formError, ...props}) => {
  return ( 
  <Fragment>
    <input
      className={((touched && error) || formError) && 'error'}
      {...props} {...input} required={false}/>
    {touched && error && <div className='field-error-message'>{error}</div>} 
  </Fragment>
  )
};

ForgotPasswordComponent = reduxForm({
  form: 'login-forgot_password',
})(ForgotPasswordComponent)

const stateToProps = state => {
  return {
    initialValues: {
      email: ((state.form.login || {}).values || {}).email,
    }
  };
}
  

export default connect(stateToProps, mapDispatchToProps)(ForgotPasswordComponent);

import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { requestPatchUser } from 'modules/user/actions';
import { openModal, closeModal } from 'modules/modals/actions';

function mapStateToProps(state) {
    return {
      user: state.user.meta,
      userId: state.user.id,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      requestPatchUser,
      closeModal,
      openModal,
    }, dispatch);
  }

class ProceedButton extends Component {
    proceedToEligibility(){
        let newState = this.props.user.proceedToEligibility?0:1;
        this.props.closeModal("checkEligibilityInitial");
        this.props.requestPatchUser({userId: this.props.userId, data: {meta: {proceedToEligibility: newState}}, callback: ()=>{}});
      }

    render() {
        return(
            <LinkButton to="/check-eligibility/" onClick={()=>this.proceedToEligibility()} className={this.props.className + " proceed"}>
            {(this.props.user.eligibilityStart !== 1)?"Qualify Me!":this.props.name}
            {/* {this.props.name} {this.props.user.eligibilityStart} */}
            </LinkButton>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProceedButton);
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Breakdown from '../../DetailedBreakdown';
import { Container, Heading } from './Shared';

import imgArrowRight from 'img/elements/blue-arrow-right.svg';
import imgArrowDown from 'img/elements/blue-arrow-down.svg';

import imgLeftArrow from 'img/tooltips/arrow_left.png'

const DetailedBreakdown = ({ tabs }) => {
  const [ collapsed, setCollapsed ] = useState(false);
  const [ showInfo, setShowInfo ] = useState(false);

  const toggleCollapsed = () => {
    setTimeout(() => setShowInfo(!collapsed), 500);
    setCollapsed(!collapsed);
  }


  return <>
    <Container>
        <Heading onClick={() => toggleCollapsed()} style={{cursor: 'pointer'}}>
          <ArrowButton collapsed={collapsed}/>
          Detailed Lending Breakdown
          {collapsed &&
            <InformationHelp show={showInfo}>Click to view detailed lending breakdown</InformationHelp>
          }
        </Heading>
        
        
        <BreakdownContainer collapsed={collapsed}>
          <Breakdown showInfo={!showInfo} tabs={tabs} overviewActivated={true}/>
        </BreakdownContainer>
        
    </Container>
  </>;
} 

export default DetailedBreakdown;

const Seperator = styled.div`
    background-color: #B3BECC;
    height: 1px;
    width: 100%; 

    margin-bottom: 60px;
    margin-top: 67px; 

    @media (max-width: 645px) {
      display: none;
    }

    ${props => props.last && css`

      @media (max-width: 780px) {
        margin-top: -50px;
      }

      @media (max-width: 645px) {
        display: block;
      }
    `}
`;

const InformationHelp = styled.div`
  opacity:${props => props.show ? 1 : 0};
  transition: 0.5s opacity ease-in-out;
  position: absolute;
  top: 40px;
  left: 0px;
  font-size: 13px;
  color: #727C8F;
  padding-left: 13px;
  font-weight: normal;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    height: 19px;
    width: 19px;
    top: -5px;
    left: -12px;
    transform: scaleX(-1);
    background-size: 19px 19px;
    background-repeat: no-repeat;
    background-image: url(${imgLeftArrow});
    transform: scaleY(-1);
  }
`;

const BreakdownContainer = styled.div`
  max-height: ${p => p.collapsed ? '0px' : '3000px'};
  transition: 0.5s max-height ease-in-out;
  overflow: hidden;
`;

const ArrowButton = styled.img.attrs(props => ({
  src: props.collapsed ? imgArrowRight : imgArrowDown,
}))`
  cursor: pointer;
  margin-right: ${props => props.collapsed ? '10px' : '7px'};
  object-fit: contain;
`;




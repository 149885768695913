import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { FlexColumn, FlexRow, SmallHeading } from '../Shared';
import BankLogoBase from 'Components/Global/BankLogo3';
import EditableContent from "Components/Forms/EditableContent";
import Tooltips from "Components/Tooltips";

export const RecommendedLenders = ({ banks = [], lenders = [], loans = [], active, editMode, defaults }) => {
const lendingOverview = useSelector(state => 
  state.user.meta.miniproposal?.overview?.lendingOverview ?? {});

const loanbaseCashback = useSelector(state =>
  state.user.meta.miniproposal?.overview?.loanbaseCashback ?? {});

const lenderCashback = useSelector(state =>
  state.user.meta.miniproposal?.overview?.lenderCashback ?? {});

const trailRebates = loans
  .map(x => x.newLoan.trailRebate)
  .filter(x => !!x);

const hasTrailRebate = !!trailRebates.length;

let rebateSectionCount = 0;
if (hasTrailRebate) rebateSectionCount++;
if (lenderCashback.isActive) rebateSectionCount++;
if (loanbaseCashback.isActive) rebateSectionCount++;

let sectionCount = 1;
if (lendingOverview.aggregateLending === true) sectionCount++;
if (lendingOverview.aggregateFees !== false) sectionCount++;

const showPiggy = sectionCount !== 3 && (rebateSectionCount === 0 || rebateSectionCount === 3)

let blankColumns = 3 - sectionCount;
if (showPiggy) blankColumns -= 1;

const blurOnEnter = () => {
    document.activeElement.blur();
}
return (
  <FlexRowLending>
      <FlexColumnBank>
        <SmallHeading>Recommended Lenders</SmallHeading>
        <Banks>
          {Object.keys(banks).map(id => (
            <BankLogo 
              width={135}
              height={60}
              maxFill={0.6}
              product={{ bank: id, bank_name: lenders.find(l => l.id === id)?.name }} 
              alt="loanbase" /> 
          ))}
        </Banks> 

      </FlexColumnBank>
      
      {lendingOverview.aggregateLending === true &&
        <FlexColumnLending>
          <SmallHeading>Aggregate Interest Rates</SmallHeading>

          <AggregateRate>
            <Rate>
              <Span 
                as={EditableContent} 
                name='weightedIIR' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              <Postfix>% p.a.</Postfix>
              {!!lendingOverview.weightedIIR && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from ${defaults.weightedIIR}%`}/>
              }
            </Rate>
            <Title>Weighted Interest Rate</Title>
          </AggregateRate>

          <AggregateRate>
            <Rate>
              <Span 
                as={EditableContent} 
                name='weightedTrueRate' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              <Postfix>% p.a.</Postfix>
              {!!lendingOverview.weightedTrueRate && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from ${defaults.weightedTrueRate}%`}/>
              }
            </Rate>
            <Title>Weighted True Rate</Title>
          </AggregateRate>

        </FlexColumnLending>
      }

      {lendingOverview.aggregateFees !== false &&
        <FlexColumnFees>
          <SmallHeading>Aggregate Lender <NoWrap>Fees <span className='info' data-tooltip='These figures are the total fees payable across all recommended lenders by proceeding with the proposed solution.'>?</span></NoWrap></SmallHeading>

          <AggregateRate>
            <Rate>
              $<Span 
                as={EditableContent} 
                name='upfrontFees' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              {!!lendingOverview.upfrontFees && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from $${defaults.upfrontFees}`}/>
              }
            </Rate>
            <Title>Approximate Upfront Fees<ReferenceMark>†</ReferenceMark></Title>
          </AggregateRate>

          <AggregateRate>
            <Rate>
              $<Span 
                as={EditableContent} 
                name='ongoingFees' 
                disabled={!active} 
                onEnter={blurOnEnter} 
              />
              {!!lendingOverview.ongoingFees && editMode &&
                <InlineBlueDot type='custom' definition={`Changed from $${defaults.ongoingFees}`}/>
              }
            </Rate>
            <Title>Annual Fees</Title>
          </AggregateRate>

        </FlexColumnFees>
      }  

      {Array(blankColumns).fill().map(() =>  <FlexColumnFees />)}

      {showPiggy &&
        <FlexColumnFees>
          <img src={require('img/page_illustrations/piggybank.png')} alt="checkeligibilityhelptimer"/>
        </FlexColumnFees>
      }

      
    </FlexRowLending>
        
  );


}

const NoWrap = styled.span`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
`

const InlineBlueDot = styled(Tooltips)`
  display: inline-block !important;
  height: 7px !important;
  width: 7px !important;
  background-color: #2291FF !important;
  border-radius: 50% !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
`;

const FlexRowLending = styled(FlexRow)`
  margin: 50px 0;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    margin: 30px 0 20px 0;
  }
`;


const FlexColumnBank = styled(FlexColumn)`
  width: calc(27% - 10px);
  border-right: 1px solid #D8DCE7;
  padding-right: 10px;

  @media (max-width: 654px) {
    display: none;
  }
`;

const FlexColumnLending = styled(FlexColumn)`
  width: calc(33% - 10px);

  @media (max-width: 654px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FlexColumnFees = styled(FlexColumn)`
  width: calc(33% - 10px);

  @media (max-width: 654px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: contain;
    max-height: 190px;
  }
`;

const Banks = styled.div`
`;

const ReferenceMark = styled.span`
  font-size: 66%;
  vertical-align: top;
`;

const BankLogo = styled(BankLogoBase)`
  margin-right: 40px;
  margin-top: 5px;
  margin-bottom: 35px;
  /* max-width: 180px;
  max-height: 30px; */
  object-fit: contain;
  display: block;
  
  @media (max-width: 480px) {
    max-width: 80px !important; 
    margin-right: 30px !important;
  }
`;

const Rate = styled.span``;
const Postfix = styled.span``;
const Title = styled.div``;

const AggregateRate = styled.div`
  margin-bottom: 20px;

  ${Rate} {
    color: #2291FF;
    font-weight: 700;
    font-size: 26px;

    ${Postfix} {
      font-weight: 400;
      font-size: 12px;
      margin-left: 3px; 
    }
  }

  ${Title} {
    font-weight: 400;
    font-size: 11px;
    margin-top: 5px;
    color: #727C8F;
    display: block;
    font-family: 'Lato', sans-serif;
  }

`;

const Span = styled.span`
  display: inline-block;
  ${p => p.disabled ? '' : css`
    border: solid 1px #A3A8B2;
    border-radius: 3px;
  `}
`;
import React from "react"; import { Component } from "react";

class RequestUpdate extends Component {
  render() {
    if (this.props.currentModal === "requestUpdate") {
    return (
      <div className="modal-content">
        <h1>Update Requested</h1>
        <p>We will be in touch shortly to provide you with an update.</p>
        <div className="modal-navigation">
          <button onClick={()=>this.props.closeModal()} className="single button-1">Great!</button>
        </div>
        <img src={require('../../../img/modal/requestupdate.png')} alt="Refinance"/>
      </div>
    ) } else {
      return "";
    }
  }
}

export default RequestUpdate;

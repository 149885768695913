import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { FieldGroup } from "Components/Forms";
import styled, { css } from 'styled-components'
import { openModal } from "modules/modals/actions";
import { SubMenu, MenuOptions } from "Views/Proposal/theme/Dropdown";
const $ = window.$;

class AddSectionProperty extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu() {
    this.setState(state => ({ ...state, menuOpen: !this.state.menuOpen }))
  }

  componentDidMount() {
    $(window).on('click', this.checkIfLeaveMenu.bind(this));  
  }

  componentWillUnmount() {
    $(window).off();
  }

  checkIfLeaveMenu = e => {
    if(!$(e.target).parents('.add-section-button').length) {
      this.leaveMenu()
    }
  }

  leaveMenu() {
    this.setState(state => ({ ...state, menuOpen: false }));
  }

  render() {
    if (this.props.isProperty && this.props.isCurrentLoan && this.props.isExecSummary && this.props.isAdditionalDetails) return null;
    return (
      <Section className='add-section-button'>
        <SectionContainer>
          <SectionIcon onClick={() => this.toggleMenu()} selected={this.state.menuOpen}>
            <div class="add-section-button"></div>
            <span>Add Section</span>
          </SectionIcon>
          <AdjustedSubMenu status={this.state.menuOpen}>
            <MenuOptions onClick={() => this.props.addSection("propertyDetail")} color={this.props.isProperty?"disabled":"default"}>Property Detail</MenuOptions>
            <MenuOptions onClick={() => this.props.addSection("currentLoan")} color={this.props.isCurrentLoan?"disabled":"default"}>Current Loan</MenuOptions>
            <MenuOptions onClick={() => this.props.addSection("execSummary")} color={this.props.isExecSummary?"disabled":"default"}>Exec Summary</MenuOptions>
            {!!this.props.broker?.id &&
              <MenuOptions onClick={() => this.props.addSection("additionalDetails")} color={this.props.isAdditionalDetails?"disabled":"default"}>Additional Details</MenuOptions>
            }
            <MenuOptions onClick={() => this.props.addSection("propertyDetail currentLoan execSummary")} color={(this.props.isProperty && this.props.isCurrentLoan && this.props.isExecSummary) ?"disabled":"default"}>Prop / Current / Exec</MenuOptions>
            <MenuOptions onClick={() => this.props.addSection("propertyDetail execSummary")} color={(this.props.isProperty && this.props.isExecSummary)?"disabled":"default"}>Prop / Exec</MenuOptions>
          </AdjustedSubMenu>
        </SectionContainer>
      </Section>
    )
  }
}

const AdjustedSubMenu = styled(SubMenu)`
  top: 44px;
  right: 26px;

  &:after {
    width: 45px;
    top: -8px;
    height: 8px;
  }
`;

const SectionContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Section = styled.div`
  margin: 20px 0 40px 0;
  text-align: center;
`;

const SectionIcon = styled.div`
  color: rgba(34,145,255,0.5);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  .add-section-button {
    display: block;
    margin: 0px 26px 15px 27px;
    width: 45px;
    height: 45px;
    background-color: rgba(145,200,255,1);
    border: 1px solid rgba(145,200,255,1);
    background-image: url(${require('../../../img/button/thin_white_add.png')});
    background-repeat: no-repeat;
    background-size: 22px 22px;
    background-position: 50% 50%;
    border-radius: 2px;
  }

  &:hover {
    color: rgba(34,145,255,1);

    .add-section-button {
      background-color: rgba(34,145,255,1);
      background-image: url(${require('../../../img/button/thin_white_add.png')});
      border: 1px solid rgba(34,145,255,1);
    }
  }

  ${props => props.selected && css`
    color: rgba(34,145,255,1);

    .add-section-button {
      background-color: #FFFFFF;
      background-image: url(${require('../../../img/button/thin_blue_add.png')});
      border: 1px solid #D8DCE7;
    }

    &:hover {
      .add-section-button {
        background-color: #FFFFFF;
        background-image: url(${require('../../../img/button/thin_blue_add.png')});
        border: 1px solid #D8DCE7;
      }
    }
  `};
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal
  }, dispatch);
}

const mapStateToProps = (state, ownProps) => ({
  miniProposal: state.user.meta.miniproposal,
  broker: state.user.proposalBrokerBroker,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSectionProperty);

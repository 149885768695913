import React from "react"; import { Component } from "react";
import Help from "../Components/Help";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import updateFormValues from "modules/updateFormValues"
import StampDutyForm from "../Forms/StampDutyForm";
import calculateStampDuty, { OWNER_OCCUPIED, INVESTMENT, NSW, ESTABLISHED_HOME } from "utils/calculateStampDuty";
import cleanFormSubmission from 'utils/cleanFormSubmission'
import Disclaimer from 'Components/Global/GlobalDisclaimer';
import Debouncer from 'utils/debouncer';
import formatNumber from 'utils/formatNumber';
const debouncer = new Debouncer();

class StampDuty extends Component {

  state = {}

  calcStampDuty = values => {   
    const { 
      propertyType, 
      propertyPurpose,
      propertyState,
      firstHomeBuyer,
      foreignPurchaser,
      propertyValue = 0,
    } = cleanFormSubmission(values).meta.calculators || {};

    const {
      totalLenderFees = 0,
      conveyancingFee = 0,
      propertyReport = 0,
      additionalCost = 0,
      availableSavings,
    } = this.props.values;

    const data = {
      value: propertyValue || 0,
      type: propertyType || ESTABLISHED_HOME,
      isFirstHomeBuyer: firstHomeBuyer === 'Y',
      state: propertyState || NSW,
      use: propertyPurpose === 'Y' ?  INVESTMENT : OWNER_OCCUPIED,
      isForeign: foreignPurchaser === 'Y',
      income: 0,
      dependents: 0,
      isPensioner: false,
    };

    const { stampDuty, registration, transfer, firstHomeGrant } = calculateStampDuty(data);

    const upfrontCost = (stampDuty + registration + transfer - firstHomeGrant) 
    + (totalLenderFees + conveyancingFee + propertyReport + additionalCost);

    values['calculators-result_UpfrontCost'] = upfrontCost;
    values['calculators-result_StampDuty'] = stampDuty;
    values['calculators-result_MorgageFee'] = registration;
    values['calculators-result_TransferFee'] = transfer;
    values['calculators-result_FirstHomeGrant'] = firstHomeGrant;


    this.setState({stampDuty, registration, transfer, firstHomeGrant});

    if (availableSavings != null) {
      const loanSize = Math.max(0, upfrontCost + propertyValue - availableSavings);
      values['calculators-result_LoanSize'] = loanSize;
    }
    
    debouncer.run(() => updateFormValues(values, () => {}), 3000);

  }

  render() {
    const { 
      result_UpfrontCost,
      result_TransferFee,
      result_StampDuty, 
      result_MorgageFee, 
      result_FirstHomeGrant,
    } = this.props.values;

    const {
      transfer,
      stampDuty,
      registration,
      firstHomeGrant,
    } = this.state;

    return(
      <div className="stamp-duty">
        <h1>Calculate Your Stamp Duty, Closing Costs & Grants</h1>
        <h2>Please enter your property purchase details below.</h2>
        <div className="content">

          <div className="left-content">
            <div className="module">
              <StampDutyForm onSubmit={this.calcStampDuty}/>
              <div className="image"></div>
            </div>
            <div className="references"><sup>1</sup>Results should only be used as a rough guide as we make a number of assumptions for this calculation. Lender credit policy may also change at any time, affecting these results. Please get in touch for a personalised assessment.</div>
          </div>

          <div className="right-content">
            <div className="module stamp-duty-result">
              <div className="result">${formatNumber((stampDuty + transfer + registration - firstHomeGrant) || (result_StampDuty + result_TransferFee + result_MorgageFee - result_FirstHomeGrant) || result_UpfrontCost, 0)}</div>
              <div className="result-subtitle">Total Payable<sup>1</sup></div>
              <div className="result-calculations">
                <h3>Fees</h3>
                <ul>
                  <li><span>${formatNumber(registration || result_MorgageFee)}</span>Mortgage</li>
                  <li><span>${formatNumber(transfer || result_TransferFee)}</span>Transfer</li>
                  <li><span>${formatNumber(stampDuty || result_StampDuty)}</span>Stamp Duty</li>
                  <li><span>${formatNumber((registration + transfer + stampDuty) || (result_StampDuty + result_TransferFee + result_MorgageFee))}</span>Total</li>
                </ul>
                <h3>Concessions</h3>
                <ul>
                  <li><span>${formatNumber(firstHomeGrant || result_FirstHomeGrant)}</span>First Home Grant</li>
                  <li><span>$0</span>Other</li>
                  <li><span>${formatNumber(firstHomeGrant || result_FirstHomeGrant)}</span>Total</li>
                </ul>
              </div>

            </div>
            <Help />
          </div>

        </div>
        <Disclaimer/>
      </div>
    );
  }
}

const stateToProps = state => ({
  values: {
    result_TransferFee: 0,
    result_StampDuty: 0 ,
    result_MorgageFee: 0,
    result_FirstHomeGrant: 0,
    ...state.user.meta.calculators
  } 
});

export default connect(stateToProps) (StampDuty);

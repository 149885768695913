import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { requestLogin, requestRemoveProductFromApplication } from 'modules/user/actions';
import { closeModal, openModal } from 'modules/modals/actions';
import { requestPatchUser } from 'modules/user/actions';

function mapStateToProps(state) {
  let products = [];
  let application = {};
  if (state.user && state.user.applications && state.user.applications.length > 0) {
    application = state.user.applications[0];
    products = application.products;
  }
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    products,
    application
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestLogin,
    removeProductFromApplication: requestRemoveProductFromApplication,
    requestPatchUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

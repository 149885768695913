import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { NavLink } from "react-router-dom";
import Navigation from "Components/Navigation/Navigation";
import QuestionSetOne from "./Forms/QuestionSetOne";
import QuestionSetTwo from "./Forms/QuestionSetTwo";
import QuestionSetThree from "./Forms/QuestionSetThree";
import {requestSignup} from "modules/user/actions";
import { openModal } from 'modules/modals/actions';
import { createUser, _userData } from "utils/createUser"

import _isEqual from 'lodash/isEqual';
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import { parseFullName } from 'parse-full-name'; 
import { isChrome } from 'utils/getDevice';
import isVerificationRequired from 'utils/isVerificationRequired';
import { settingsService } from "modules";


const $ = window.$;

let maxWindowHeight = 0;
let isScrolling = false;
let isChanging = false;
let blockSetQuestion = false;

function getWindowHeight() {
  let windowHeight = $(window).innerHeight();
  if (windowHeight < maxWindowHeight) {
    windowHeight = maxWindowHeight;
  } else if (maxWindowHeight < windowHeight) {
    maxWindowHeight = windowHeight;
  }
  return windowHeight;
}

const calculateRepayments = ({
  loanAmount,
  interestRate,
  repaymentType,
  loanPeriod,
}) => {
  let result = 0
  if (repaymentType === 'PandI') {
    result = calculateMonthlyRepayments(Number.parseFloat(interestRate) / 12, loanPeriod * 12, loanAmount) || 0;
  } else if (repaymentType === 'Interest') {
    result = Number.parseFloat(interestRate) * loanAmount / 12; 
  }
  if (result) {
    return Number.parseInt(result);
  }
}

// function scrollToPosition(start, end, lastTime, updateProgress = true) {
//   let scrollTime = 300;
//  isScrolling = true;
//  blockSetQuestion = true;

//  setTimeout(() => {
//    isScrolling = false;
//  }, scrollTime);

//  setTimeout(() => {
//    blockSetQuestion = false;
//  }, scrollTime + 500);

//  $([document.documentElement, document.body]).animate({
//    scrollTop: end
//  }, scrollTime);
//  return;
// }

function mapStateToProps(state) {
  return {
    form: state.form,
    user: state.user,
    location: state.router.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSignup,
    openModal,
  }, dispatch);
}

class InitialQuestionSet extends React.Component {

  constructor() {
    super();
    this.state = {
      currentQuestion: 1,
      maxQuestion: 1,
      globalSettings: null,
      hideOptionals: false,
    }
  }

  getGlobalSettings = async () => {
    const globalSettings = await settingsService.get(1);
    this.setState({ globalSettings }, () => console.log(this.state.globalSettings));
  }

  componentDidMount() {
    // $('body').css('overflow-y', 'scroll');
    // $('body').css('overflow-x', 'hidden');
    $('body').addClass('onboarding');

    this.getGlobalSettings();
  }

  componentWillUnmount() {
    $('body').removeAttr('style');
    $('body').removeClass('onboarding');
  }

  // setCurrentQuestion = n => {
  //   if (n > this.state.maxQuestion) {
  //     return;
  //   }
  //   if (this.state.currentQuestion !== n && !blockSetQuestion) {
  //     $("input").blur();
  //     this.setState({currentQuestion: n});
  //   }
  // }

  goToNextQuestion() {
    this.goToQuestion(this.state.currentQuestion + 1);
  }

  skipOptional() {
    this.setState({hideOptionals: true, maxQuestion: 10}, () => {
      this.goToQuestion(10);
    })
  }

  submitOptional() {
    this.setState({hideOptionals: false}, () => {
      this.goToNextQuestion();
    })
  }

  goToQuestion(n) {
    let inputField = $('#n' + n + " input");
    if (inputField.length) {
      setTimeout(() => inputField.focus(), 300);
    }
    this.setState({currentQuestion: n});
  }

  handleSubmit(results) {
    //console.log('Submit Results', results);
    const invalidForms = $('.invalid-form ' + '.q' + this.state.currentQuestion);
      if (invalidForms.length) return;
    $(':focus').blur();
    this.goToNextQuestion();   
  }

  createUser() {
    const invalidForms = $('.invalid-form.q' + this.state.currentQuestion);
      if (invalidForms.length) return;
    
    let results = {};

    for (let key in this.props.form) {
      if (key.indexOf("onboarding-") !== -1) {
        results = {...results, ...this.props.form[key].values};
      }
    }    
    
    {
      const trimmedName = results['name']
        .trim()
        .replace(/\s+/g, " ");
      
      let { first, last } = parseFullName(trimmedName);
      delete results['name'];
      
      if (last && !first) {
        first = last;
        last = '';
      }

      results['user-firstName'] = first;
      results['user-lastName'] = last;
    }

    results['user_meta-referrer'] = document.referrer || 'Direct';

    if (!results['user-additionalProperties']) results['user-additionalProperties'] = 'None';

    if (results["user_meta-purchasePrice"] && results["user_meta-depositAmount"]) {
      results["user_meta-loanAmount"] = results["user_meta-purchasePrice"] - results["user_meta-depositAmount"];
    }
    
    if (results["user_meta-isOOLoan"] === "N") {
      results["user_meta-isOOLoan"] = "";
    }
    if (results["user_meta-isPandILoan"] === "N") {
      results["user_meta-isPandILoan"] = "";
    }

    results["user-householdIncome"] = 
      Math.floor((results["user_meta-householdIncome"] ?? 0) / (results["user_meta-householdIncomeType"] ?? 1));

    if (results["user-householdIncome"] && this.state.globalSettings && this.state.globalSettings.income_brackets) {
      const brackets = this.state.globalSettings.income_brackets;
      for (const [index, bracket] of brackets.entries()) {
        if (results["user-householdIncome"] >= bracket.from && results["user-householdIncome"] < (bracket.to ?? Infinity)) {
          results["user-incomeBracket"] = index + 1;
        }
      }
    }
    
    if (this.props.location.pathname.indexOf('refinance') !== -1) {
      results['user_meta-loanPurpose'] = 'refinance';
    } else if (this.props.location.pathname.indexOf('construct') !== -1) {
      results['user_meta-loanPurpose'] = 'construct';
    } else {
      results['user_meta-loanPurpose'] = 'purchase';
    }

    results['user_meta-skip_email'] = 1;
    results['user_meta-entryType'] = 'main';

    if (this.props.location.search.indexOf('showEmail=true') === -1) {
      results['user_meta-noEmail'] = 1;
    }
    if (this.props.location.search.indexOf('showMobile=true') === -1) {
      results['user_meta-phoneAfter'] = 1;
    }

    if ((this.props.location.search.indexOf('verifyMobile=0') !== -1) 
      || (this.props.location.search.indexOf('verifyMobile=false') !== -1)
      || (this.state.globalSettings && !this.state.globalSettings.sms_verification)) {
      results.mobileVerificationRequired = false;
    } else {
      if (results.phoneNumber) {
        results.mobileVerificationRequired = 
          isVerificationRequired(results.phoneNumber);
      } else {
        results['user_meta-verifyMobile'] = 1;
      }
    }

    results.email = this.props.form['onboarding-email']?this.props.form['onboarding-email'].values.email:"";
    
    results['user_meta-introType'] = "introflowPage";

    createUser(results).catch(() => { throw new Error("Error creating user"); });;
  }

  render() {
    const { maxQuestion } = this.state;

    let QuestionSet;
    if (this.props.location.pathname.indexOf('refinance') !== -1) {
      QuestionSet = QuestionSetTwo;
    } else if (this.props.location.pathname.indexOf('construct') !== -1) {
      QuestionSet = QuestionSetThree;
    } else {
      QuestionSet = QuestionSetOne;
    }

    return(
        <div>
          <Navigation state="sidebar-navigation" />
          {/* <progress value="0" id="progressBar">
            <div className="progress-container">
              <span className="progress-bar"></span>
            </div>
          </progress> */}

          <div className="progress-container">
            <span style={{height: 0}}id="test" className="progress-bar"></span>
          </div>

          <main className="initial-question-set" id="initial-questions">

            <NavLink className="logo" exact to="/">
              <img src={require('img/brand/secure_finance.png')} alt='Securefinance logo' />
            </NavLink>

            <NavLink className="log-in-button" exact to="/login">I already have an account</NavLink>

            {/* <div className="fade-top"></div> */}
            {/* <div className="fade-bottom"></div> */}
            <div className="main-container">
              {/* <div className="spacing" style={{height: scrollHeight}} ></div> */}
              <div className="scroll-area">

                <QuestionSet 
                  globalSettings={this.state.globalSettings}
                  isTransitioning={true}
                  hideOptionals={this.state.hideOptionals}
                  currentQuestion={this.state.currentQuestion}
                  skipOptionals={() => this.skipOptional()} 
                  submitOptionals={() => this.submitOptional()} 
                  goToNextQuestion={() => this.goToNextQuestion()} 
                  onSubmit={this.handleSubmit.bind(this)} 
                  finishOnboarding={this.createUser.bind(this)}
                />
              </div>
            </div>
          </main>

        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialQuestionSet);

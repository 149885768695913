import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Heading, Body, Container as ContainerBase, Column } from './Shared';

import imgAward from '../img/brand/small-award-notext.png';
import imgMPA from '../img/brand/mpa-logo.png';
import sanitizeHtml from 'sanitize-html';
import { imageUrl } from 'modules';

export default ({ settings }) => {
    const text = settings.overview_pdf_about ?? '';
    const random = Math.random();
    return (
      <Container>
        <Column width="500px">
          <Heading>About Secure Finance</Heading>
          <Body dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
        </Column>

        <ColumnCentered width="256px">
          <img
            width={170}
            src={imageUrl + "/logos/awards_vertical.png?n=" + random}
          />
          <FeaturedIn>Featured in</FeaturedIn>
          <img width={170} src={imgMPA} />
        </ColumnCentered>
      </Container>
    );
  };


const Container = styled(ContainerBase)`
  margin-top: 18px;
`;

const FeaturedIn = styled.div`
  font-style: italic;
  font-size: 17px;
  margin-top: 37px;
  margin-bottom: 10px;
`

const ColumnCentered = styled(Column)`
  padding-top: 40px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
`;

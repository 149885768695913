import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";

class RefinanceAnalysisStart extends Component {
  render() {
    if (this.props.currentModal === "refinanceAnalysisStart") {
    return (
      <div className="modal-content">
        <img src={require('../../../img/modal/refinance.png')} alt="Refinance"/>
        <h1>Refinance Analysis</h1>
        <p>We will be able to tell you how much you could save in interest by refinancing to a different loan. We just need to gather key details about your current loan.</p> 
        <p>This step should take less than a minute to complete. You can then  compare you current loan to any loan product on our site.</p> 

        <div className="modal-navigation">
          <button onClick={()=>this.props.openModal("refinanceAnalysisDetails")}>Let's Start!</button>
        </div>
        <div className="references">
        Please note that calculated interest savings include a number of assumptions and might not be accurate for your particular situation.
        </div>
      </div>
    ) } else {
      return "";
    }
  }
}

export default RefinanceAnalysisStart;

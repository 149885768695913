import React from "react";
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Input } from "Components/Forms";
import { connect } from 'react-redux'

import styled from 'styled-components'
import { openModal } from "modules/modals/actions";
import { requestPatchUser } from 'modules/user/actions';
import { FormContainer, SelectGroup } from "Components/Forms";
import _ from 'lodash';

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class AggregateRepayments extends React.Component {

  render() {
    return (
      <FormContainer onSubmit={this.handleSubmit}>
        <StyledSelectGroup onChange={this.props.onChange} empty="false" id='repaymentPeriod' width='small' position='left' hideOptional type='select' placeholder='0'
          values={[
            { value: 'monthly', text: 'Monthly' },
            { value: 'fortnightly', text: 'Fortnightly' },
            { value: 'weekly', text: 'Weekly' },
          ]} />
      </FormContainer>
    )
  }
}

const StyledSelectGroup = styled(SelectGroup)`
  /* background-color: red; */
  margin: 0 !important;
  width: 90px !important;

  label {
    margin: 0 !important;
  }

  select {
    border: none !important;
    height: 20px !important;
    padding: 0 !important;
    color: #727C8F !important;
    font-size: 12px !important;

    &:focus {
      box-shadow: none !important;
    }
  }
`;

AggregateRepayments = reduxForm({
  form: 'test',
})(AggregateRepayments);

export default connect(mapStateToProps, mapDispatchToProps)(AggregateRepayments);

import React from "react"; import { Component } from "react";
import sanitizeHtml from 'sanitize-html';

class ReferralMoreInfo extends Component {

  render() {
    if (this.props.currentModal === "referral-more-info") {
      const { settings, user } = this.props;
      const modalHtml = user.isProfessionalReferrer ? settings.professional_referrer_info_modal : settings.referrer_info_modal;
      return (
        <div className="modal-content">
          <div dangerouslySetInnerHTML={{ __html: modalHtml ?? ''}} />
          <div className="modal-navigation">
            <button onClick={()=>this.props.closeModal()} className="single button-1">Done</button>
          </div>
        </div>
    ) } else {
      return "";
    }
  }
}

export default ReferralMoreInfo;

import React from "react"; import { Component } from "react";

class LoanSearch extends Component {
  render() {

    return (
      <div className="search-results">
        {this.props.children}
      </div>
    );
  }
}

export default LoanSearch;

import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadioBox from '../Components/RadioBox';
import QuestionWrapper from "../Components/QuestionWrapper";


let LivingConditions = function(props) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} invalid={props.invalid} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>
      <div className="content two-box">
        <h1>Is this property an investment or do you live there?</h1>
        <h2>Lenders often provide discounts for owner-occupied properties.</h2>
        <RadioBox name="user_meta-isOOLoan" onChange={()=>{props.handleSubmit()}} class="LiveIn" text="It's my home" value="Y" select={props.value} />
        <RadioBox name="user_meta-isOOLoan" onChange={()=>{props.handleSubmit()}} class="Investment" text="Investment Property" value="N" select={props.value} />
      </div>
    </QuestionWrapper>
  )
}

LivingConditions = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-livingConditions'  // a unique identifier for this form
})(LivingConditions);

const selector = formValueSelector('onboarding-livingConditions') // <-- same as form name
LivingConditions = connect(
  state => {
    //console.log(state);
    // can select values individually
    const value = selector(state, 'user_meta-isOOLoan');
    return {
      value
    }
  }
)(LivingConditions);

export default LivingConditions;

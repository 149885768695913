import {
  OPEN_MODAL, CLOSE_MODAL, OPEN_REFINANCE_MODAL, OPEN_MODAL_WITH_ARGS
} from './actions';

export const modalsReducer = (state = {}, action) => {
  switch(action.type) {
    case CLOSE_MODAL: {
      document.body.classList.remove('modal-open');
      state = {...state, currentModal: ""}
      break;
    }
    case OPEN_MODAL: {
      document.body.classList.add('modal-open');
      state = {...state, currentModal: action.payload}
      break;
    }
    case OPEN_MODAL_WITH_ARGS: {
      document.body.classList.add('modal-open');
      state = {...state, currentModal: action.payload.modal, args: action.payload.args}
      break;
    }
    case OPEN_REFINANCE_MODAL : {
      document.body.classList.add('modal-open');
      state = {...state, currentModal: "refinanceAnalysis", result: action.payload}
      break;
    }
    default : {
      break;
    }

  }
  return state;
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field } from "redux-form";
import styled, { css } from 'styled-components';
import DatePicker from "react-datepicker";
import formatNumber from 'utils/formatNumber';

import { requestPatchUser } from 'modules/user/actions';
import { closeModal } from 'modules/modals/actions';

import "react-datepicker/dist/react-datepicker.css";

import { 
    Input,
    FormContainer, 
} from "Components/Forms";
import { ButtonSpinner } from "Components/Global/ButtonSpinner";


const FORM_NAME = 'lender-cashback-modal';

let Form = ({ submitting, handleSubmit, lenders }) => {

  const { user, form } = useSelector(state => {
      const user = state.user;
      const form = (state.form[FORM_NAME] || {}).values || {};
      return { user, form };
  });
  
  const dispatch = useDispatch(); 
    
  const onSubmit = values => {

      const patch = {
          overview: values,
      };

      dispatch(requestPatchUser({ 
          userId: user.id,
          params: {
            mergeMeta: true,
          },
          data: { 
            meta: { 
              miniproposal: patch,
            } 
          },
      }));

      dispatch(closeModal());
    }

    return (
      <FormContainer
        onSubmit={handleSubmit(onSubmit)}
        className="form your-details"
      >
        <Section>
          <Heading>Show/Hide Sections</Heading>

          <Switch>
            <Field
              name="additionalDetails.isActive"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Additional Details</SwitchLabel>
          </Switch>

          <Switch>
            <Field
              name="lendingOverview.aggregateLending"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Aggregate Interest Rates</SwitchLabel>
          </Switch>

          <Switch>
            <Field
              name="lendingOverview.aggregateFees"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Aggregate Lender Fees</SwitchLabel>
          </Switch>

          <Switch>
            <Field
              name="beyondSettlement.isActive"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Beyond Settlement</SwitchLabel>
          </Switch>

          <Switch>
            <Field
              name="lenderCashback.isActive"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Lender Cashback</SwitchLabel>
          </Switch>

          <Switch>
            <Field
              name="loanbaseCashback.isActive"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Secure Finance Cashback</SwitchLabel>
          </Switch>

          <Switch style={{ display: "inline-block" }}>
            <Field
              name="lendingOverview.refinanceComparison"
              type="checkbox"
              component={Input}
            />
            <SwitchLabel>Proposed Refinance Comparison</SwitchLabel>
          </Switch>
          {form.lendingOverview.refinanceComparison && (
            <Switch style={{ display: "inline-block", marginLeft: "73px" }}>
              <Field
                name="lendingOverview.forceRefinanceComparison"
                type="checkbox"
                component={Input}
              />
              <SwitchLabel>Force ON</SwitchLabel>
            </Switch>
          )}
        </Section>
        {!!lenders && !!lenders.length && (
          <Section>
            <Heading>About Lender Options</Heading>
            {lenders.map((lender) => (
              <Switch>
                <Field
                  name={"aboutLender." + lender.id}
                  type="checkbox"
                  component={Input}
                />
                <SwitchLabel>{lender.name}</SwitchLabel>
              </Switch>
            ))}
          </Section>
        )}

        <button className="analyse">
          <ButtonSpinner loading={submitting} />
          Submit
        </button>
      </FormContainer>
    );
}


export default reduxForm({
    form: FORM_NAME,
})(Form);

const SwitchLabel = styled.span`
  position: relative;
  top: -3px;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

const Heading = styled.h3`
    font-size: 16px !important;
    font-weight: bold;
    margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 40px;  
`;

const Label = styled.label`
    margin-top: 20px !important;
`;

const Switch = styled.span`
    display: block;
    margin-bottom: 20px;

    input[type="checkbox"] {
    font-size: 30px; 
    appearance: none;
    width: 40px !important; 
    height: 20px !important; 
    background: #ddd;
    border-radius: 3em !important; 
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;

    &:checked {
      background: #26BD27;
    }

    &:after {
      position: absolute;
      content: "";
      width: 20px; 
      height: 20px; 
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0, 0, 0, .3); 
      transform: scale(.7); 
      left: 0;
      transition: all .2s ease-in-out;
    }

    &:checked:after {
      left: calc(100% - 20px);
    }
  }
`;
import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal, closeModal } from 'modules/modals/actions';
import ModuleBox from 'Components/Global/ModuleBox/ModuleBox';
import CalculateBox from '../Components/CalculateBox';
import LinkButton from "Components/LinkButton";
import Disclaimer from 'Components/Global/GlobalDisclaimer';
const stateToProps = state => ({
    calculators: state.user.meta.calculators || {},
    borrowingPower: state.user.borrowingPower && state.user.borrowingPower.high || 0,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}


class Home extends React.Component {

  render() {
    const { calculators } = this.props;
    const borrowingPowerActive = this.props.borrowingPower && calculators.borrowingPowerActive;
    const refinanceActive = calculators.result_RefinanceSavings && calculators.refinanceActive;
    const stampDutyActive = calculators.result_UpfrontCost && calculators.stampDutyActive;
    const fundsRequiredActive = calculators.result_LoanSize && calculators.fundsRequiredActive;
    
    return(
      <div className="calculator-home" >
        <ModuleBox id="calculators" link="calculators" title="Calculate My Budget" alwaysShow={typeToggle(this.props.type)}>
          <CalculateBox term="borrowingPower" link="borrowing-power" title="Borrowing Power" subtitle="What’s my maximum loan size?" result={borrowingPowerActive ? this.props.borrowingPower.toFixed(0) : ''} status={borrowingPowerActive}/>
          <CalculateBox term="fundsRequired" link="funds-required" title="Loan Amount Required" subtitle="What size loan do I need?" result={fundsRequiredActive ? calculators.result_LoanSize.toFixed(0) : ''} status={fundsRequiredActive}/>
          <CalculateBox term="refinance" link="refinancing" title="Refinancing" subtitle="How much will refinancing save me?" result={refinanceActive ? calculators.result_RefinanceSavings.toFixed(0) : ''} status={refinanceActive}/>
          <CalculateBox term="stampDuty" link="stamp-duty" title="Stamp Duty" subtitle="What are my closing costs & grants?" result={stampDutyActive ? calculators.result_UpfrontCost.toFixed(0) : ''} status={stampDutyActive}/>
        </ModuleBox>
        <div className="calculator-help dashboard-section">
          <img class="illustration" src={require('../../../img/page_illustrations/calculator_home.png')} alt="calculators"/>
          <h3>Want some help?</h3>
          <p>We’re here to offer you free personalised service and assistance with your mortgage needs. Don’t hesitate to get in touch!</p>
          <div className="navigation">
            <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="get-advice">Get Advice</button>
            <LinkButton to="/search-loans" className="search-loans">Search for a Loan</LinkButton>
          </div>
          
        </div>

        {(this.props.type == "calculators")
        ?
        [<div className="references">
          <ol>
            <li>
              Calculators: All calculators make various assumptions and we make no guarantee as to the
              accuracy of these figures. Furthermore, lender policy may change at any time. Please get in
              touch for a personalised assessment.
            </li>
          </ol>
        </div>,
        <Disclaimer />]
        :
        ""
        }
      </div>
      
    );
  }
}

export default connect(stateToProps, mapDispatchToProps)(Home);


function typeToggle(value){
  if (value === "dashboard") {
    return "false";
  }
  else {
    return "true";
  }
}




import React from 'react';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import RadioBox from '../Components/RadioBox';
import QuestionWrapper from "../Components/QuestionWrapper";

let PayOffLoan = function(props) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={props.debounce} id={props.id} setQuestion={props.setQuestion} currentQuestion={props.currentQuestion} onSubmit={props.handleSubmit} maxQuestion={props.maxQuestion}>
      <div className="content two-box button">
        <h1>What is your current repayment type?</h1>
        <h2>If you’re not sure, select Principal and Interest.</h2>
        <RadioBox name='user_meta-refinanceRepaymentType' onChange={()=>{props.handleSubmit()}} text="Principal and Interest" value="PandI" select={props.value} />
        <RadioBox name='user_meta-refinanceRepaymentType' onChange={()=>{props.handleSubmit()}} text="Interest Only" value="Interest" select={props.value} />
      </div>
    </QuestionWrapper>
  )
}

PayOffLoan = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-payOffLoan',
  onChange: (values, dispatch, props) => {
    dispatch(change('onboarding-payOffLoan', 'user_meta-repaymentType', values['user_meta-refinanceRepaymentType']));
  }
})(PayOffLoan);

const selector = formValueSelector('onboarding-payOffLoan') // <-- same as form name
PayOffLoan = connect(
  state => {
    //console.log(state);
    // can select values individually
    const value = selector(state, 'user_meta-repaymentType');
    return {
      value
    }
  }
)(PayOffLoan);

export default PayOffLoan;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form'
import { requestPatchUser } from 'modules/user/actions';

import { FieldGroup, FormContainer, DoubleFieldGroup, PasswordField } from "Components/Forms";
import { createPasswordValidation, createValidateEmail, createRequired, phoneNumberProps } from 'Components/Forms/validation';
import sanitizeHtml from 'sanitize-html';
import imgArrowRight from 'img/button/arrow-white-right.svg';

import {
   FormStyle, Card, Title, Paragraph, NextButton, Column, ButtonContainer,
} from '../Components/Styled';
import { emailService } from 'modules';
import html from 'utils/html';

const emailValidation = createValidateEmail();
const emailRequired = createRequired('Please enter your email');

const ConfirmDetailsForm = ({ handleSubmit, setContent, initialValues }) => {
  const dispatch = useDispatch();
  const globalSettings = useSelector(state => state.user.globalSettings);

  const onSubmit = values => {
    console.log(values);
    values.meta.hasConfirmedReferrerDetails = true;
    setContent('referral-form');
    dispatch(requestPatchUser({
      userId: initialValues.id,
      params: {
        mergeMeta: true,
      },
      data: values,
    }));
    emailService.create({
      type: 'system-email',
      subject: 'New referrer signed up',
      body: emailBody(values),
    });
  }

  return (
    <Card style={{ marginTop: '30px'}}>
      <div style={{ width: '565px' }}>
        <Title>Confirm Your Details</Title>
        <Paragraph dangerouslySetInnerHTML={{ __html: sanitizeHtml(globalSettings.referrer_confirm_details ?? '')}} />
        <FormStyle>
          <FormContainer onSubmit={handleSubmit(onSubmit)} className="your-details">
            <FieldGroup showRequired id='firstName' width='small' position="left" title='First Name' required type='text' />
            <FieldGroup showRequired id='lastName' width='small' position="right" title='Last Name' required type='text' />
            <FieldGroup showRequired id="email" width="small" position="left" title="Your e-mail address" hideOptional type="text" validate={[emailRequired, emailValidation]}/>
            <FieldGroup id="phoneNumber" width="small" position="right" title="Best contact number"   type="text"/>
            <FieldGroup hideOptional id="bankAccount.name" width="small" position="left" title="Bank Account Name" type="text"/>
            <Column width='calc(50% - 15px)'>
              <FieldGroup hideOptional className='tiny' id='bankAccount.bsb' width='small' position="left" title='BSB' type='text' />
              <FieldGroup hideOptional className='tiny' id='bankAccount.number' width='small' position="right" title='Account Number'type='text' />
            </Column>
            <ButtonContainer>
              <div></div>
              <NextButton type='submit'>Next <img src={imgArrowRight} style={{ height: '12px', marginLeft: '20px', position: 'relative', top: '1px' }}/></NextButton>
            </ButtonContainer>
          </FormContainer>
        </FormStyle>
        
      </div>
          
    </Card>
  ) 
}

export default reduxForm({ form: 'confirm-details' })(ConfirmDetailsForm); 


const emailBody = user => html`
  <div>Name: ${user.firstName} ${user.lastName ?? ''}</div>
  <div>Email: ${user.email}</div>
  <div>Phone: ${user.phoneNumber}</div>
  <div>Bank Account Name: ${user.bankAccount?.name}</div>
  <div>BSB: ${user.bankAccount?.bsb}</div>
  <div>Account Number: ${user.bankAccount?.number}</div>
  `;
import React from "react"; import { Component } from "react";
import Help from "../Components/Help";


import { change } from 'redux-form';

import { connect } from 'react-redux';

import updateFormValues from "modules/updateFormValues"

import calculateStampDuty, { OWNER_OCCUPIED, INVESTMENT, NSW, ESTABLISHED_HOME } from "utils/calculateStampDuty";
import { bindActionCreators } from "redux";
import initializeFormValues from "utils/initializeFormValues";
import cleanFormSubmission from 'utils/cleanFormSubmission'

import FundsRequiredForm from '../Forms/FundsRequiredForm';
import Disclaimer from 'Components/Global/GlobalDisclaimer';
import Debouncer from "utils/debouncer";
import formatNumber from 'utils/formatNumber';
const debouncer = new Debouncer();


class FundsRequired extends Component {

  state = {}

  componentDidMount = () => {
    initializeFormValues('calculator', 
    (values, state) => ({
      'calculators-propertyValue': state.user.meta.purchasePrice,
      'calculators-availableSavings': state.user.meta.depositAmount,
      'calculators-propertyState': 'NSW',
      'calculators-propertyType': 'ESTABLISHED_HOME',
      'calculators-firstHomeBuyer': '',
      'calculators-foreignPurchaser': '',
      'calculators-propertyPurpose': (state.user.isOOLoan === '' && 'Y') || '',
      'calculators-fundsRequiredActive': 'Y',
      ...values,
    }));
  }

  onSubmit = values => {

    const { 
      propertyType, 
      propertyPurpose,
      propertyState,
      firstHomeBuyer,
      foreignPurchaser,
      totalLenderFees = 0,
      conveyancingFee = 0,
      propertyReport = 0,
      additionalCost = 0,
      propertyValue = 0,
      availableSavings = 0,
    } = cleanFormSubmission(values).meta.calculators || {};

    const { stampDuty, registration, transfer, firstHomeGrant } = calculateStampDuty({
      value: propertyValue || 0,
      type: propertyType || ESTABLISHED_HOME,
      isFirstHomeBuyer: firstHomeBuyer === 'Y',
      state: propertyState || NSW,
      use: propertyPurpose === 'Y' ?  INVESTMENT : OWNER_OCCUPIED,
      isForeign: foreignPurchaser === 'Y',
      income: 0,
      dependents: 0,
      isPensioner: false,
    });

    const upfrontCost = (stampDuty + registration + transfer - firstHomeGrant) 
      + (totalLenderFees + conveyancingFee + propertyReport + additionalCost);

    const loanSize = Math.max(0, upfrontCost + propertyValue - availableSavings);

    values['calculators-result_StampDuty'] = stampDuty;
    values['calculators-result_MorgageFee'] = registration;
    values['calculators-result_TransferFee'] = transfer;
    values['calculators-result_FirstHomeGrant'] = firstHomeGrant;
    values['calculators-result_UpfrontCost'] = upfrontCost;
    values['calculators-result_LoanSize'] = loanSize;
    values['calculators-result_GovernmentConcessions'] = firstHomeGrant;

    debouncer.run(() => updateFormValues(values, () => {}), 3000);

    this.setState({upfrontCost, loanSize})

    this.props.change('calculators-result_TransferFee', transfer);
    this.props.change('calculators-result_MorgageFee', registration);
    this.props.change('calculators-result_StampDuty', stampDuty);
    this.props.change('calculators-result_GovernmentConcessions', firstHomeGrant);
  }

  render() {
    //console.log('props', this.props);
    const { result_LoanSize, result_UpfrontCost} = this.props;
    const { loanSize, upfrontCost } = this.state;
    return(
      <div className="funds-required">
        <h1>Loan Sizing Calculator</h1>
        <h2>Please enter your property purchase below.</h2>
        <div className="content">

          <div className="left-content">
            <div className="module funds-required-result">
              <div className="result-left">
                <div className="result">${formatNumber(loanSize || result_LoanSize || 0, 0)}</div>
                <div className="result-subtitle">Estimated Loan Required<sup>1</sup></div>
              </div>
              <div className="result-right">
                <div className="result">${formatNumber(upfrontCost || result_UpfrontCost || 0, 0)}</div>
                <div className="result-subtitle">Upfront Costs</div>
              </div>
            </div>

            <FundsRequiredForm onSubmit={this.onSubmit}/>
            
            <div className="references"><sup>1</sup>Results should only be used as a rough guide as we make a number of assumptions for this calculation. Lender credit policy may also change at any time, affecting these results. Please get in touch for a personalised assessment.</div>
          </div>

          <div className="right-content">
            <div className="image"></div>
            <Help />
          </div>

        </div>
        <Disclaimer/>
      </div>
    );
  }
}

const stateToProps = state => ({
  result_LoanSize: 0,
  result_UpfrontCost: 0,
  ...state.user.meta.calculators
});

const dispatchToProps = dispatch => bindActionCreators({change}, dispatch)

export default connect(stateToProps, dispatchToProps) (FundsRequired);

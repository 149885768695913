import React from "react";

const LoanTracker = () => {
  return(
    <main>
      LoanTracker
    </main>
  );
}

export default LoanTracker;

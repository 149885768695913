import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export class MultiSelect extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    this.setState({ 
      options: this.props.options.map(
        o => ({ 
          ...o, 
          isChecked: (this.props.value || []).some(v => v === o.value)
        })
      )});
    window.addEventListener('click', this.clickOffComponent);
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('keypress', this.onKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickOffComponent);
    window.removeEventListener('keypress', this.onKeyPress);
  }

  render() {
    const { isOpen } = this.state;
    let { placeholder, onChange, width, tabIndex, label, options, value, ...props } = this.props;
    value = value || [];
    return (
      
      <div 
        className={`multi-select ${isOpen && 'open'}`} 
        style={{width}} 
        ref={node => this.node = node}
        {...props}
      >
        {label && <label className='label'>{label}</label>}
        <div className='select-box' onClick={this.toggleOpen} tabIndex = {tabIndex}>
          {
            !value.length ? 
              placeholder 
              : options
                .filter(o => value.some(v => v === o.value))
                .map(o => o.name)
                .join(', ')
          }
        </div>
        {
          isOpen && (
            <div className='options-container' style={{width}}>
              {
                options.map((option, index) => {
                  const isChecked = value.some(v => v === option.value);
                  return (
                  <MultiSelectOption
                    tabIndex={tabIndex + index + 1}
                    key={index}
                    isChecked={isChecked}
                    {...option}
                    onClick={this.toggleSelect(option, index)}/>
                  )
                })
              }
            </div>
          )
        }
      </div>
    )
  }

  toggleOpen = e => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen })
  }

  toggleSelect = (option, index) => e => {
    let { value } = this.props;
    value = value || [];
    e.preventDefault();
    let newValue;
    if (value.some(v => v === option.value)) {
      newValue = value.filter(v => v !== option.value);
    } else {
      newValue = [...value, option.value];
    }
    newValue = newValue.length ? newValue : '';
    if (this.props.onChange)
      this.props.onChange(newValue);
  }

  clickOffComponent = e => {
    if (this.state.isOpen && !this.node.contains(e.target))
      this.setState({ isOpen: false});
  }

  onKeyPress = e => {
    //console.log(e.keyCode);
    if (e.keyCode === 13 || e.keyCode === 32) {
      if (document.activeElement === ReactDOM.findDOMNode(this.node))
        this.setState({ isOpen: !this.state.isOpen});
    }
  }
}

const CheckMark = ({checked, ...props}) => (
  <span className={'check-mark' + (checked ? ' checked' : '')} {...props} />
);

const CheckBoxContainer = props => (
  <label className='check-box-container' {...props} />
);
  
const CheckBox = ( {title, checked } ) => (
  <CheckBoxContainer>
    {title}
    <CheckMark checked={checked}/>
  </CheckBoxContainer>
);

const MultiSelectOption = ({ name, value, isChecked, onClick, ...props }) => (
  <div className='select-option' onClick={onClick} {...props}>
    <CheckBox title={name}
      type='checkbox' 
      checked={isChecked}/>
  </div>
);
import React, { Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Form } from "redux-form";
import { setTitle, setPageID } from "modules/page/actions";
import ModuleBox from "Components/Global/ModuleBox/ModuleBox";

import { createRequired } from 'Components/Forms/validation';
import styled, { css } from 'styled-components'
import SectionButton from "../component/SectionButton";
import Property from "./sections/Property"
import ExecutiveSummary from "./sections/ExecutiveSummary"

import { SectionContainer } from "../theme/General";
import CurrentLoan from "./sections/CurrentLoan";
import ProposedOptions from "./sections/ProposedOptions";
import RefinanceSavings from "./sections/RefinanceSavings";
import BrokerSection from "./sections/BrokerSection";
import AddSectionProperty from "../component/AddSectionProperty";

import { PDFExport } from '@progress/kendo-react-pdf';

import _ from 'lodash';

import { requestPatchUser } from 'modules/user/actions';

const required = createRequired("Please type a message");

function mapStateToProps(state, props) {
  const previewMode = (state.user.meta.miniproposal || {}).previewMode;
  const godmode = state.user.godmode;
  return {
    isPDF: !!props.isPDF,
    proposal: state.user.meta.proposal,
    user: state.user.meta,
    godmode,
    previewMode, 
    miniProposal: state.user.meta.miniproposal,
    userId: state.user.id,
    broker: state.user.proposalBroker,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTitle,
      setPageID,
      requestPatchUser,
    },
    dispatch
  );
}

class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      property: false,
      currentLoan: false,
      execSummary: false,
      additionalDetails: false,
    };
  }

  

  toggleEdit(val) {
    console.log("Edit mode")
    this.setState(state => ({ ...state, editMode: val }))
  }

  cancelEdit() {
    this.setState(state => ({ ...state, editMode: undefined }))
  }

  isActive(editMode, val) {
    if (editMode === val) {
      return true;
    } else {
      return false;
    }
  }

  removeSection(type) {
    console.log(type)
    const { userId, miniProposal } = this.props
    const patchProposal = data => this.props.requestPatchUser({ 
      userId,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: {
            properties: {
              [this.props.data.id]: {
                [type]: data
              }
            }
          }
        } 
      }});

    patchProposal({ enabled: false }); 
  }
  

  addSection(types) {
    const patch = types.split(' ')
      .reduce((obj, type) => ({ ...obj, [type]: { enabled: true } }), {});

    const { userId } = this.props
    const patchProposal = data => this.props.requestPatchUser({ 
      userId,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: {
            properties: {
              [this.props.data.id]: data
            }
          }
        } 
      }});

    patchProposal(patch);
  }

  toggleOverview(excluded) {
    const { userId } = this.props
    const patchProposal = data => this.props.requestPatchUser({ 
      userId,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: {
            properties: {
              [this.props.data.id]: data
            }
          }
        } 
      }});

      patchProposal({ excludedFromOverview: !excluded })
  }


  render() {
    const { data, user, miniProposal, godmode, isPDF, index, overviewActivated } = this.props;
    const noGodmode = this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    console.log({ data });
    return (
      <SectionContainer isPDF={isPDF}>
        <Overlay status={this.state.editMode}></Overlay>
        {!noGodmode && !!godmode && !miniProposal.previewMode && !isPDF &&
        <>
          <SectionButtonRight 
            removeTab={() => this.props.onTabClose(data.id)} 
            toggleOverview={this.toggleOverview.bind(this)}
            tabId={data.id} 
            right 
            excludedFromOverview={data.excludedFromOverview}
            type="tab" 
          />
          <AddSectionProperty 
            right
            addSection={this.addSection.bind(this)}
            isProperty={data.propertyDetail.enabled}
            isCurrentLoan={data.currentLoan.enabled}
            isExecSummary={data.execSummary.enabled}
            isAdditionalDetails={data.additionalDetails.enabled}
          />
        </>
        }
         {data.id === 2 && console.log('property details', data.propertyDetail)}
        <PropertyLoanContainer>
        <Property
          isPDF={isPDF}
          tabId={data.id}
          form={'property-' + data.id + '-' + index}
          loan={data.currentLoan}
          isView={data.propertyDetail.enabled}
          removeSection={() => this.removeSection("propertyDetail")} 
          cancelEdit={() => this.cancelEdit()}
          toggleEdit={() => this.toggleEdit(4)}
          active={this.isActive(this.state.editMode, 4)} />
        <CurrentLoan
          isTop={!this.isActive(this.state.editMode, 4)}
          isPDF={isPDF}
          form={'current_loan-' + data.id + '-' + index}
          tabId={data.id}
          data={data.currentLoan}
          initialValues={data.currentLoan}
          isView={data.currentLoan.enabled}
          removeSection={() => this.removeSection("currentLoan")}
          active={this.isActive(this.state.editMode, 1)}
          cancelEdit={() => this.cancelEdit()}
          toggleEdit={() => this.toggleEdit(1)} />
        </PropertyLoanContainer>
        <ExecutiveSummary
          isTop={!this.isActive(this.state.editMode, 4) && !this.isActive(this.state.editMode, 1)}
          isPDF={isPDF}
          form={'executive_summary-' + data.id + '-' + index}
          tabId={data.id}
          initialValues={data.execSummary}
          isView={data.execSummary.enabled}
          removeSection={() => this.removeSection("execSummary")}
          active={this.isActive(this.state.editMode, 2)}
          cancelEdit={() => this.cancelEdit()}
          toggleEdit={() => this.toggleEdit(2)} />
        <ProposedOptions
          isOverview={overviewActivated}
          isTop={!this.isActive(this.state.editMode, 4) && !this.isActive(this.state.editMode, 1) && !this.isActive(this.state.editMode, 2)}
          index={index}
          isPDF={isPDF}
          tabId={data.id}
          data={data.loanComparison}
          currentLoan={data.currentLoan}
          editMode={this.state.editMode}
          cancelEdit={() => this.cancelEdit()}
          toggleEdit={n => this.toggleEdit(n)} />
        
        {!!this.props.broker?.id && !isPDF && !overviewActivated &&
          <BrokerSection
            index={index}
            tabId={data.id}
            broker={this.props.broker ?? {}}
            details={data.additionalDetails}
            isView={data.additionalDetails.enabled}
            removeSection={() => this.removeSection("additionalDetails")}
            active={this.isActive(this.state.editMode, 3)}
            cancelEdit={() => this.cancelEdit()}
            toggleEdit={() => this.toggleEdit(3)} 
          />
        }
        
      </SectionContainer>
    );
  }
}

const PropertyLoanContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 560px) {
    display: block;
  }

  > form {
    width: 36%;

    @media (max-width: 1080px) {
      width: 40%;
    }

    @media (max-width: 900px) {
      width: 45%;
    }

    @media (max-width: 700px) {
      width: 50%;
    }

    @media (max-width: 560px) {
      width: 100%;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  ${props => props.status && css`
    opacity: 1;
    display: block;
    pointer-events: auto;
  `}
`;

const SectionButtonRight = styled(SectionButton)`
  position: absolute;
  top: 10px;
  left: 10px;
`;



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
  form: "proposal"
})(Properties));

import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal } from 'modules/modals/actions';

function mapStateToProps(state) {
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
  }, dispatch);
}


class Help extends Component {

  render() {
    return(
      <div className="help">
        <h3>Want some help?</h3>
        <p>We’re here to offer you free personalised service and assistance with your mortgage needs. Don’t hesitate to get in touch!</p>
        <div className="navigation">
          <button onClick={()=>this.props.openModal("checkEligibilityHelp")} className="get-advice">Get Advice</button>
          <button className="lets-chat" onClick={()=>window.Intercom('show')}><span>Let's Chat</span></button>
      </div>
      <LinkButton to="/search-loans" className="search-loans">Search Loans</LinkButton>
    </div>
  );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Help);
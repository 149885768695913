import React from "react"; import { Component } from "react";
import { Line } from 'react-chartjs-2';
//import "chartjs-plugin-lineheight-annotation";
import { connect } from 'react-redux';
import LinkButton from "Components/LinkButton";
import CheckEligibilityButton from "Components/CheckEligibilityButton";
import calculateLoanValues from "utils/calculateLoanValues"
import { bindActionCreators } from 'redux';
import StarLoansButton from "Components/StarLoansButton";
import calculateMonthlyRepayments from "utils/calculateMonthlyRepayments";
import { NavLink } from "react-router-dom";
import BorrowingPowerValue from "Components/BorrowingPowerValue"

import { addToShortlist, removeFromShortlist } from 'modules/user/actions';
import { openModal, openModalWithArgs } from 'modules/modals/actions';
import { requestProducts } from 'modules/product/actions';
import { closeModal } from 'modules/modals/actions';
import Tooltips from "Components/Tooltips";
import BankLogo from 'Components/Global/BankLogo';

function mapStateToProps(state) {
  return {
    shortlist: state.user.shortlist,
    currentModal: (state.modals) ? state.modals.currentModal : "",
    nsrValues: state.user.nsrValues,
    products: state.products.list,
    user: state.user.meta,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    openModalWithArgs,
    addToShortlist,
    removeFromShortlist,
    requestProducts,
  }, dispatch);
}


class RefinanceAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refinanceState: false,
      toggleRefinance: false,
      repaymentInterval: 1,
    };
  }

  switchRefinanceState() {
    this.setState((prevState) => { return { ...prevState, refinanceState: !prevState.refinanceState } });
  }

  calculatePeriods = ({ user, product, mode, currentLoan }) => {
    const loanPeriod = user.refinanceYears || 30;
    const newLoanPeriod = (mode === "proposal" && currentLoan) ? (currentLoan.newLoanPeriod || 30) : loanPeriod; 
    let chartPeriod = (product.initial_ir_type === 'Fixed' || product.initial_ir_type === 'Intro Variable') ?
      Math.floor(product.fixed_term / 12) : Math.max(loanPeriod, newLoanPeriod);

    if (user.refinanceRepaymentType === 'Interest' || product.repaymentType === 'Interest Only') {
      chartPeriod = Math.min(chartPeriod, 5);
    }

    return { chartPeriod, loanPeriod, newLoanPeriod };
  }

  calculateLoanSchedulePandI = ({ period, ir, principle, chartPeriod }) => {
    const repayment = calculateMonthlyRepayments(ir, period, principle);
    let totalInterestRepayment, totalPrincipleRepayment;
    totalInterestRepayment = totalPrincipleRepayment = 0;
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment,
      totalPrincipleRepayment,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    [...Array(chartPeriod)].forEach((_, i) => {
      
      const remainingPrinciple = principle - totalPrincipleRepayment;
      const interestRepayment = remainingPrinciple * ir;
      const principleRepayment = repayment - interestRepayment;
      if (i < period) {
        totalInterestRepayment += interestRepayment;
        totalPrincipleRepayment += principleRepayment;
      }
     
      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment,
        remainingPrinciple
      });
    });
    return schedule;
  }

  calculateLoanScheduleInterestOnly = ({ period, ir, principle, chartPeriod }) => {
    //console.log('IO schedule stuff:', period, ir, principle)
    let schedule = [{
      time: 0,
      remainingPrinciple: principle,
      totalInterestRepayment: 0,
      totalPrincipleRepayment: 0,
      interestRepayment: 0,
      principleRepayment: 0
    }];
    const interestRepayment = ir * principle;
    let totalInterestRepayment = 0;
    [...Array(chartPeriod)].forEach((_, i) => {
      if (i < period) {
        totalInterestRepayment += interestRepayment;
      }

      schedule.push({
        time: i + 1,
        interestRepayment,
        totalInterestRepayment,
        principleRepayment: 0,
        remainingPrinciple: principle
      });
      
    });
    return schedule;
  }

  render() {
    let { user, currentLoan, browser } = this.props;

    if (this.props.type === "proposal") {
      currentLoan.newLoanPeriod = parseInt(currentLoan.newLoanPeriod);
      user = {
        ...user,
        refinanceLoanAmount: currentLoan.loanAmount,
        refinanceInterestRate: currentLoan.interestRate,
        refinanceLender: currentLoan.lender,
        refinanceMonthlyRepayment: currentLoan.monthlyRepayment,
        refinanceOtherLenderName: currentLoan.otherLenderName,
        refinanceRepaymentType: currentLoan.repaymentType,
        refinanceYears: currentLoan.loanPeriod,
      }
    }

    const product = calculateLoanValues(this.props.product);
    const { chartPeriod, loanPeriod, newLoanPeriod } = this.calculatePeriods({ user, product, mode: this.props.type, currentLoan });
    const trailRebate = product.trailRebate || 0;
    
    let newMonthlyRepayment = calculateMonthlyRepayments(parseFloat(product.iir) / 100 / 12, newLoanPeriod * 12, (user.refinanceLoanAmount)).toFixed(2);
    if (product.repaymentType === 'Interest Only') {
      newMonthlyRepayment = product.iir * user.refinanceLoanAmount / 12 / 100;
    }
    const newMonthlyInterestRepayment = (product.repaymentType === 'Interest Only') ? newMonthlyRepayment
      : newMonthlyRepayment - user.refinanceLoanAmount / newLoanPeriod / 12;
    const widthNewMonthlyRepayment = ((((parseFloat(newMonthlyRepayment) / parseFloat(user.refinanceMonthlyRepayment)) * 100)));
    const widthCurrentMonthlyRepayment = ((((parseFloat(user.refinanceMonthlyRepayment) / parseFloat(newMonthlyRepayment)) * 100)));
    const potentialMonthlySavings = (user.refinanceMonthlyRepayment - newMonthlyRepayment).toFixed(2);
    const lvr = ((user.refinanceLoanAmount / user.purchasePrice) * 100).toFixed(2);

    product.irrBeforeRebate = product.iir;
    product.iir -= trailRebate;

    const loanSchedule = user.refinanceRepaymentType === 'Interest' ?
      this.calculateLoanScheduleInterestOnly({ period: loanPeriod * 12, ir: user.refinanceInterestRate / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 })
      :
      this.calculateLoanSchedulePandI({ period: loanPeriod * 12, ir: user.refinanceInterestRate / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 });

    const newLoanSchedule = product.repaymentType === 'Interest Only' ?
      this.calculateLoanScheduleInterestOnly({ period: newLoanPeriod * 12, ir: product.iir / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 })
      :
      this.calculateLoanSchedulePandI({ period: newLoanPeriod * 12, ir: product.iir / 1200, principle: user.refinanceLoanAmount, chartPeriod: chartPeriod * 12 });

    const newLoanScheduleWithoutRebate = product.repaymentType === 'Interest Only' ?
      this.calculateLoanScheduleInterestOnly({ period: newLoanPeriod * 12, ir: product.irrBeforeRebate / 1200, principle: user.refinanceLoanAmount, chartPeriod: 13 })
      :
      this.calculateLoanSchedulePandI({ period: newLoanPeriod * 12, ir: product.irrBeforeRebate / 1200, principle: user.refinanceLoanAmount, chartPeriod: 13 });

    const yearOneInterestSaving = loanSchedule[12].totalInterestRepayment - newLoanSchedule[12].totalInterestRepayment;
    const yearOneRebateSaving = newLoanScheduleWithoutRebate[12].totalInterestRepayment - newLoanSchedule[12].totalInterestRepayment;
    //console.log('loan schedules', loanSchedule, newLoanSchedule);
 
    let AltLogo;
    if (user.refinanceOtherLenderName) {
      AltLogo = () => <h6 className="company-text">{user.refinanceOtherLenderName}</h6>;
    } else {
      AltLogo = () => <h6 className="company-text">Your Current Lender</h6>;
    }

    let currentBankLogo;
    try {
      currentBankLogo = <img className="company-logo" src={require(`img/banks/${user.refinanceLender}.png`)} alt="logo" />;
    } catch (e) {
      if (user.refinanceOtherLenderName) {
        currentBankLogo = <h6 className="company-text">{user.refinanceOtherLenderName}</h6>;
      } else {
        currentBankLogo = <h6 className="company-text">Your Current Lender</h6>;
      }
    }

    const rebate = Number.parseInt(product.rebate_value) || 0;

    let repaymentIntervalString = 'monthly';
    if (this.state.repaymentInterval > 2) repaymentIntervalString = 'fortnightly';
    if (this.state.repaymentInterval > 4) repaymentIntervalString = 'weekly';

    //console.log('loan periods', chartPeriod, loanPeriod)

    const totalInterestSavings = (loanSchedule[chartPeriod * 12].totalInterestRepayment - newLoanSchedule[chartPeriod * 12].totalInterestRepayment) + rebate
    const chartLabels = (chartPeriod < 4) ? [...Array(chartPeriod * 12 + 1).keys()] : [...Array(chartPeriod * 12 + 1).keys()].map(n => '' + Math.floor(n / 12));
    const labelPeriod = (chartPeriod < 4) ? 'Month' : 'Year';
    //console.log(chartLabels)

    var options = {
      animation: false,
      lineHeightAnnotation: {
        always: false,
        hover: true,
        color: '#aaa',
        lineWeight: 1,
        noDash: true,
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0
          },
          scaleLabel: {
            display: true,
            labelString: labelPeriod + 's',
          }
        }],
        yAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            callback: function (label, index, labels) {
              if (label > 999999) {
                return '$' + label / 1000000 + 'M';
              } else {
                return '$' + label / 1000 + 'k';
              }
            },
            scaleLabel: {
              display: true,
              labelString: '1k = 1000'
            },
            autoSkip: true,
            maxTicksLimit: 6
          }
        }]
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return labelPeriod + ' ' + data['labels'][tooltipItem[0]['index']];
          },
          label: function (tooltipItem, data) {
            var label = Math.round(tooltipItem.yLabel)
            var comma = label.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            return '$' + comma + ' savings';
          },
        },
        backgroundColor: '#fff0',
        //borderColor: '#E9EBF1',
        //borderWidth: 1,
        intersect: false,
        titleFontFamily: "'Lato', sans-serif",
        titleFontSize: 11,
        titleFontStyle: 400,
        titleFontColor: '#727C8F',
        bodyFontColor: '#101922',
        bodyFontFamily: "'Lato', sans-serif",
        bodyFontSize: 12,
        bodyFontStyle: 600,
        displayColors: false,
        caretPadding: 30,
        caretSize: 15,
        cornerRadius: 4,
        xPadding: 10,
        yPadding: 10
      },
      elements: { point: { radius: 50 } },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
    }
    this.data = (canvas) => {
      const ctx = canvas.getContext("2d")
      const gradient = ctx.createLinearGradient(150.000, 0.000, 150.000, 300.000);
      gradient.addColorStop(0.2, 'rgba(234,245,255,1)');
      gradient.addColorStop(1, 'rgba(255,255,255,0)');
      return {
        labels: chartLabels,
        datasets: [
          {
            label: 'My First dataset',
            lineTension: 0.1,
            backgroundColor: gradient,
            borderColor: 'rgba(34,145,255,1)',
            borderWidth: 2,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'bevel',
            //pointBorderColor: 'rgba(34,145,255,1)',
            //pointBackgroundColor: 'rgba(34,145,255,1)',
            // pointBorderWidth: 1,
            pointBorderWidth: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: 'rgba(34,145,255,1)',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            fill: 'origin',
            data: [...Array(chartPeriod * 12 + 1).keys()]
              .map(index => ({
                x: index,
                y: (loanSchedule[index].totalInterestRepayment - newLoanSchedule[index].totalInterestRepayment) + rebate
              })),
          }
        ]
      }
    };

    if (this.props.type === "proposal") {
      return (
        <div className="refinance-content proposal-refinance">
          <table>
            <tbody>
              <tr>
                <td colSpan={(browser.lessThan.w780 || browser.is.w780) ? '100%' : ''}>
                  <div className="left-side">
                    <div class='top-items'>
                      <div className="loan">
                        <div className='logo-container' style={{height: '42px'}}>
                          <BankLogo product={product} />
                        </div>
                        <div className="loan-name">Offset Home Loan Package</div>
                      </div>

                      <div className="type">
                        <div className="loan-amount">
                          <h5 style={{ fontWeight: '700'}}>${(user.refinanceLoanAmount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                          <div className="description">New Loan Amount</div>
                        </div>
                      </div>
                    </div>
                    

                    <div className="repayments">
                      <div className="indicator">
                        <div className="indicator-value" style={{ width: Value(widthCurrentMonthlyRepayment) + "%" }} >Current Repayments</div>
                      </div>
                      <h2 style={{ fontWeight: '700'}}>${Math.round(user.refinanceMonthlyRepayment / this.state.repaymentInterval).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                    </div>

                    <div className="repayments new">
                      <div className="indicator">
                        <div className="indicator-value" style={{ width: Value(widthNewMonthlyRepayment) + "%" }} >New Repayments</div>
                      </div>
                      <h2 style={{ fontWeight: '700'}}>${Math.round(newMonthlyRepayment / this.state.repaymentInterval).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                    </div>
                    
                    {!this.props.isPDF &&
                      <div className="show-repayments">
                        <span>Show repayments</span>
                        <select onChange={e => this.setState({ repaymentInterval: Number.parseFloat(e.target.value) })}>
                          <option value="1">Monthly</option>
                          <option value="2.17">Fortnightly</option>
                          <option value="4.35">Weekly</option>
                        </select>
                      </div>
                    }
                    

                  </div>
                </td>
                {browser.greaterThan.w780 &&
                  <td>
                    <div className="right-side">
                      <div className="view-chart">
                        <div className="graph">
                          <div className={totalInterestSavings > 0 ? "potential-savings positive" : "potential-savings negative"}>
                            <h2 style={{ fontWeight: '700'}}>${(totalInterestSavings.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            {!!trailRebate ?
                              <p>Total Interest Savings Over {chartPeriod} Years <br/> Including Rebate</p>
                              :
                              <p>Total Interest Savings<br />Over {chartPeriod} Years</p>
                            }
                            
                            
                          </div>
                          <Line data={this.data} options={options} style={{ height: '307px'}}/>
                        </div>
                      </div>
                    </div>
                  </td>
                }
              </tr>
              {(browser.lessThan.w780 || browser.is.w780) &&
                <tr>
                  <td colSpan='100%'>
                    <div className="right-side">
                      <div className="view-chart">
                        <div className="graph">
                          <div className={totalInterestSavings > 0 ? "potential-savings positive" : "potential-savings negative"}>
                            <h2 style={{ fontWeight: '700'}}>${(totalInterestSavings.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            <p>Total Interest Savings<br />Over {chartPeriod} Years</p>
                          </div>
                          <Line data={this.data} options={options} style={{ height: '307px'}}/>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              }
              
              <tr>
                {(browser.greaterThan.w780) &&
                  <td>
                    <div className="refinance-details">
                      {!!trailRebate ?
                        <p>The new loan term is assumed to be equal in length to the current loan's remaining term. For any interest only period, the comparison chart only shows savings over 5 years. As noted earlier in this proposal, the rebate is paid annually and based on net utilised funds (i.e. not funds held in offset). All savings and rebates are approximations only, and are based on the stated loan balance.</p>
                        :
                        <p>The new loan term is assumed to be equal in length to the current loan's remaining term. For any interest only period, the comparison chart only shows savings over 5 years. All savings and rebates are approximations only, and are based on the stated loan balance.</p>
                      }
                    </div>
                  </td>
                }
                <td className="td-product-comparison">

                  {!trailRebate ?
                    <div className="product-comparison">
                      <div className="monthly-savings">
                        <div className="value" style={{ fontWeight: '700'}}>${((user.refinanceMonthlyRepayment - newMonthlyRepayment).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div className="value-description">Monthly Repayment Savings</div>
                      </div>
                      <div className="lvr">
                        <div className="value" style={{ fontWeight: '700'}}>${yearOneInterestSaving.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div className="value-description">Year 1 Interest Savings</div>
                      </div>
                    </div>
                    :
                    <div className="product-comparison three-items">
                      <div className="monthly-savings">
                        <div className="value" style={{ fontWeight: '700'}}>${((user.refinanceMonthlyRepayment - newMonthlyRepayment).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div className="value-description">Monthly Repayment Savings</div>
                      </div>
                      <div className="lvr">
                        <div className="value" style={{ fontWeight: '700'}}>${yearOneInterestSaving.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div className="value-description">Year 1 Interest Savings</div>
                      </div>
                      <div className="rebate-savings">
                        <div className="value" style={{ fontWeight: '700'}}>${yearOneRebateSaving.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div className="value-description">Year 1 Rebate</div>
                      </div>
                    </div>

                  }

                </td>
              </tr>
              {(browser.lessThan.w780 || browser.is.w780) &&
                <tr>
                  <td style={{paddingRight: '25px'}}>
                    <div className="refinance-details">
                      {!!trailRebate ?
                        <p>The new loan term is assumed to be equal in length to the current loan's remaining term. For any interest only period, the comparison chart only shows savings over 5 years. As noted earlier in this proposal, the rebate is paid annually and based on net utilised funds (i.e. not funds held in offset).</p>
                        :
                        <p>The new loan term is assumed to be equal in length to the current loan's remaining term. For any interest only period, the comparison chart only shows savings over 5 years.</p>
                      }
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>

        </div>
      )
    } else {
      return (
        <div className="refinance-content loan-search">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="left-side">

                    <div className="current-loan">
                      <div className="loan">
                        <h4>Your Current Loan</h4>
                        <div className='logo-container'>
                          <BankLogo product={{ bank: user.refinanceLender }} unloader={<AltLogo />} />
                        </div>
                      </div>

                      <div className="rates">
                        <div className="variable-rate">
                          <div className="rate">{this.props.user.refinanceInterestRate.toFixed(2)}<span>% p.a.</span></div>
                          <div className="rate-description">Variable</div>
                        </div>

                        <a onClick={() => this.props.openModalWithArgs({ modal: "refinanceAnalysisDetails", args: product })}>Not correct?</a>
                      </div>

                      <div className="type">

                        <div className="principal-interest">
                          <h5>{this.props.user.refinanceRepaymentType === 'Interest' ? 'Interest Only' : 'Principal & Interest'}</h5>
                          <div className="description">Repayment Type</div>
                        </div>

                        <div className="loan-amount">
                          <h5>${this.props.user.refinanceLoanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                          <div className="description">Current Loan Amount</div>
                        </div>

                      </div>

                      <div className="repayments">
                        <div className="indicator">
                          <div className="indicator-value" style={{ width: Value(widthCurrentMonthlyRepayment) + "%" }} >Current Repayments</div>
                        </div>
                        <h2>${Math.round(this.props.user.refinanceMonthlyRepayment / this.state.repaymentInterval).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                      </div>

                    </div>

                    <div className="new-loan">
                      <div className="loan">
                        <h4>Your New Loan</h4>
                        <div className='logo-container'>
                          <BankLogo product={product} />
                        </div>
                      </div>

                      <div className="rates">
                        <div className="variable-rate">
                          <div className="rate">{this.props.product.iir}<span>% p.a.</span></div>
                          <div className="rate-description">{product.rateDescriptionYears}</div>
                        </div>

                        <div className="comparison-rate">
                          <div className="rate">{this.props.product.comparisonRate}<span>% p.a.</span></div>
                          <div className="rate-description">Comparison Rate</div>
                        </div>

                        <p>{product.rebateTagContent}</p>
                      </div>

                      <div className="type">

                        <div className="principal-interest">
                          <h5>{product.repaymentType}</h5>
                          <div className="description">Repayment Type</div>
                        </div>

                        <div className="loan-amount">
                          <h5>${this.props.user.refinanceLoanAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                          <div className="description">New Loan Amount</div>
                        </div>

                      </div>

                      <div className="repayments">
                        <div className="indicator">
                          <div className="indicator-value" style={{ width: Value(widthNewMonthlyRepayment) + "%" }} >New Repayments</div>
                        </div>
                        <h2>${Math.round(newMonthlyRepayment / this.state.repaymentInterval).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*</h2>
                      </div>

                    </div>

                  </div>

                </td>
                <td className='right-cell'>

                  <div className="right-side">


                    <div className="top">

                      <button onClick={() => this.switchRefinanceState()} className={"toggle-details " + ((!this.state.refinanceState) ? "product-details-view" : "chart-view")}>{(!this.state.refinanceState) ? "Product Details" : "View Chart"}</button>

                      <div className="shortlist">
                        <p>Star this loan to add it <br />to your shortlist</p>
                        <StarLoansButton product={this.props.product} />
                      </div>
                    </div>

                    {(!this.state.refinanceState) ?
                      <div className="view-chart">
                        <div className="graph">
                          <div className={totalInterestSavings > 0 ? "potential-savings positive" : "potential-savings negative"}>
                            <h2 style={{ fontWeight: '700'}}>${(totalInterestSavings.toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2>
                            <p>Total Interest Savings<br />Over {chartPeriod} Years</p>
                          </div>
                          <Line data={this.data} options={options} />
                        </div>

                        <div className="product-comparison">

                          <div className="monthly-savings">
                            <div className="value">${((user.refinanceMonthlyRepayment - newMonthlyRepayment).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div className="value-description">Monthly Repayment Savings</div>
                          </div>

                          <div className="lvr">
                            <div className="value">${yearOneInterestSaving.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div className="value-description">Year 1 Interest Savings</div>
                          </div>

                          <div className="borrowing-power">
                            <BorrowingPowerValue value={(this.props.nsrValues[product.bank] || this.props.nsrValues['default'])} />
                            <div className="description">Borrowing Power</div>
                          </div>

                        </div>


                      </div>
                      :
                      <div className="product-details">
                        <div className="product-content">
                          <h4>Product Details & Features</h4>
                          <h4 className="product-name">{this.props.product.product_name}</h4>

                          <div className="details">
                            <h5>Features</h5>
                            <ul className="statistics">
                              <li className={product.redraw_allowed}>Redraw</li>
                              <li className={product.full_offset}>
                                {product.full_offset === 'additional' ? 
                                  <Tooltips type="wrapper" definition={product.offsetTooltip}>Offset</Tooltips>
                                  :
                                  <span>Offset</span>
                                }
                              </li>
                              <li className={product.extra_repayments_allowed}>Early Repayments</li>
                            </ul>
                          </div>

                          <div className="details">
                            <div className="ongoing">
                              <h5>Ongoing Fee</h5>
                              <p>${product.ongoing_fees} Annual</p>
                            </div>
                            <div className="upfront">
                              <h5>Approximate Upfront Fee</h5>
                              <p>${product.approxUpfrontFee}</p>
                            </div>
                          </div>

                          <div className="details">
                            <h5>Purpose</h5>
                            <p>{InvOrOcc(this.props.product.maximum_lvr_investment, this.props.product.maximum_lvr_own_occ)}</p>
                          </div>
                          {(product.initial_ir_type === "Fixed") ?
                            <div className="details">
                              <h5>Revert Rate</h5>
                              <p>{product.rr}% after {product.fixedTermMonths} Months</p>
                            </div> : ""}

                          <div className="details">
                            <h5>Repayment Type</h5>
                            <p>{product.repaymentType}</p>
                          </div>

                          {this.props.product.key_details &&
                            <div className="key-details">
                              <h4>Key Details</h4>
                              <p>
                                {this.props.product.key_details + " "}
                              </p>
                            </div>
                          }
                          <NavLink to={"/products/" + this.props.product.id} onClick={() => this.props.closeModal()}>More Details...</NavLink>
                        </div>
                      </div>
                    }

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="refinance-details">
                    <p>
                      *{product.initial_ir_type === 'Fixed' && 'New monthly repayments are based on the initial fixed term and may be higher once this term is complete. '}
                      This analysis is only designed to provide you with a rough indication of savings and does not constitute personal advice. We have assumed that any interest only period rolls over for the life of the loan. Furthermore the new loan term is assumed to be equal in length to the current loan's remaining term. <strong>Update your current loan</strong> to make this comparison more accurate.
            </p>
                    <div className="navigation">
                      <button className="update-loan" onClick={() => this.props.openModalWithArgs({ modal: "refinanceAnalysisDetails", args: product })} >Update Current Loan</button>
                      <div className="show-repayments">
                        <span>Show repayments</span>
                        <select onChange={e => this.setState({ repaymentInterval: Number.parseFloat(e.target.value) })}>
                          <option value="1">Monthly</option>
                          <option value="2.17">Fortnightly</option>
                          <option value="4.35">Weekly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="next-steps">
                    <p><strong>Next Steps: </strong>Comparing home loans can be hard work, so we’ve done the number crunching for you. We’re always available to help you get a better home loan.</p>
                    <div className="navigation">
                      <button onClick={() => this.props.openModal("checkEligibilityHelp")} className="get-advice"><span>Get Advice</span></button>
                      <CheckEligibilityButton product={this.props.result} />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      );
    }
  }
}

function LVR(value) {
  if (value > 100) {
    return <div className="value red">{"100"}%</div>;
  }
  else if (value > 90) {
    return <div className="value red">{value}%</div>;
  }
  else if (value > 80) {
    return <div className="value orange">{value}%</div>;
  }
  else {
    return <div className="value">{value}%</div>;
  }
}

function Value(value) {
  if (value < 100) {
    return value;
  } else {
    return 100;
  }
}

function InvOrOcc(inv, occ) {
  if ((inv > 0) && (occ > 0)) {
    return "Investment or Owner Occupied";
  }
  else {
    if (occ > 0) {
      return "Owner Occupied"
    }
    else {
      return "Investment"
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefinanceAnalysis);
import React, { useRef, useEffect, useCallback } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTitle, setPageID } from "modules/page/actions";
import styled, { css } from "styled-components";
import VideoProposal from "./VideoProposal";
import Properties from "./Properties";
import Tabs, { Tab } from "Components/Global/react-awesome-tabs";
import { Icons } from "../theme/ProposalIcons";
import _ from "lodash";
import { requestPatchUser } from "modules/user/actions";
import EditableContent from "Components/Forms/EditableContent";
import { reduxForm, Form } from "redux-form";
import Summary from "./Summary";

import crypto from "crypto";
import Select from "Components/Forms/SearchSelect";
import Overview from "./Overview";

import alterArray from "utils/alterArray";

const $ = window.$;

function mapStateToProps(state) {
  const previewMode = (state.user.meta.miniproposal || {}).previewMode;
  const godmode = state.user.godmode;
  return {
    proposal: state.user.meta.proposal,
    user: state.user.meta,
    godmode,
    previewMode,
    userId: state.user.id,
    miniProposal: state.user.meta.miniproposal,
    activeTab: state.user.meta.proposalActiveTab || 0,
    browser: state.browser,
  };
}

function hash(data) {
  return crypto.createHash("md5").update(data).digest("hex");
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTitle,
      setPageID,
      requestPatchUser,
    },
    dispatch
  );
}

class DetailedBreakdown extends Component {
  tabs = [];

  state = {
    tabFocused: false,
  };

  pdf = null;

  componentDidMount() {
    document.title = "Secure Finance - Proposal";
    this.props.setTitle("Proposal");
    this.props.setPageID("proposal");
  }

  handleTabSwitch(active) {
    this.props.requestPatchUser({
      data: {
        meta: {
          proposalActiveTab: active,
        },
      },
    });
  }

  toggleTabFocused = (tabFocused) => this.setState({ tabFocused });

  handleTabPositionChange(a, b) {
    const moveTab = (a, b, _arr) => {
      let arr = [..._arr];
      arr.splice(a, 0, arr.splice(b, 1)[0]);
      return arr;
    };

    const { user, activeTab } = this.props;
    const tabs = user.miniproposal.tabs || [];
    const tabCount = tabs.length;

    const extras = extraTabs(user);

    if (a <= extras - 1 || b <= extras - 1) return;

    if (a === activeTab) this.handleTabSwitch(b);
    else if (b === activeTab) this.handleTabSwitch(a);

    a -= extras;
    b -= extras;

    // this should never happen, but would be very bad if it did
    if (a >= tabCount || b >= tabCount) return;

    console.log("swap", a, b);
    this.patchProposal({ tabs: alterArray.swap(a, b) });

    //this.forceUpdate();
  }

  handleTabClose(tabId) {
    this.patchProposal({
      tabs: alterArray.remove(tabId),
      properties: {
        [tabId]: "DELETE_ITEM",
      },
    });

    this.handleTabSwitch(Math.max(this.props.activeTab - 1, 0));
  }

  handleTabAdd() {
    const { user } = this.props;
    const tabs = user.miniproposal.tabs || [];
    if (tabs.length > 14) {
      return;
    }

    const tabId = hash(Date.now().toString());

    const tabCount = tabs.length;
    const NewPropertyTitle = "Property " + (tabCount + 1);

    const newTab = {
      id: tabId,
      name: NewPropertyTitle,
      propertyDetail: {
        enabled: false,
        addressEnabled: true,
        loanAmountEnabled: 1,
        image: true,
        title: NewPropertyTitle,
        address: "Add Address Here",
        value: "",
        loanAmount: "",
      },
      currentLoan: {
        enabled: false,
        title: "Current Loan",
      },
      execSummary: {
        enabled: false,
        title: "Executive Summary",
        text: "Add Text Here",
      },
      additionalDetails: {
        enabled: false,
        title: "Additional Details",
        text: "Add Text Here",
      },
      loanComparison: {
        loanOrder: [],
        loans: {},
      },
    };

    this.patchProposal({
      tabs: alterArray.push(tabId),
      properties: {
        [tabId]: newTab,
      },
    });

    this.handleTabSwitch(tabCount + (userHasVideoProposal(user) ? 1 : 0));

    this.setState({
      tabCount: this.state.tabCount + 1,
    });
  }

  patchProposal = (data, callback) =>
    this.props.requestPatchUser({
      userId: this.props.userId,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: data,
        },
      },
      callback,
    });

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      tabCount: 1,
    };
  }

  switchContent(content, that, index, data) {
    switch (content) {
      case "video":
        return (
          <TabPanel>
            <VideoProposal />
          </TabPanel>
        );
      case "proposal":
        return (
          <TabPanel key={data.id}>
            <Properties
              index={index}
              data={data}
              onTabClose={that.handleTabClose.bind(that)}
              overviewActivated={that.props.overviewActivated}
            />
          </TabPanel>
        );
      case "summary":
        return (
          <TabPanel>
            <Summary overviewActivated={that.props.overviewActivated} />
          </TabPanel>
        );
      case "overview":
        return <Overview showBreakdown={false} editMode={true} />;
    }
  }

  switchIcons(icon, color, name, propertyId, active, toggleFocused) {
    switch (icon) {
      case "video":
        return (
          <>
            <Icons color={color}></Icons>
            <form style={{ display: "inline-block" }}>
              <Span
                className="tab-text"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                Video
              </Span>
            </form>
          </>
        );
      case "file":
        return (
          <>
            <Icons color={color} />
            <EditableTitle
              active={active}
              form={"tabTitle-" + propertyId}
              propertyId={propertyId}
              initialValues={{ name }}
              toggleFocused={toggleFocused}
            />
          </>
        );
      case "summary":
        return (
          <>
            <Icons color={color}></Icons>
            <form style={{ display: "inline-block" }}>
              <Span
                className="tab-text"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                {name}
              </Span>
            </form>
          </>
        );
    }
  }

  title(val) {
    switch (val) {
      case "video":
        return "Video";
      case "summary":
        return "Summary";
      default:
        return "";
    }
  }

  render() {
    let { user, godmode, previewMode, tabs, activeTab } = this.props;

    const tabCount = tabs.length;
    if (activeTab >= tabCount) activeTab = 0;

    const noTabs =
      this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    if (noTabs) godmode = false;
    return (
      <>
        {!noTabs && !this.props.removeShowInfo && (
          <TopOption>
            {/* {!!this.props.godmode && (user.miniproposal || {}).draftMode &&
                <RightNavigation savePDF={this.savePDF.bind(this)} savingPdf={this.state.savePDF}/>
              }
              {!godmode || previewMode &&
                <p>Add new proposal summary here</p>
              } */}

            {tabCount > 1 && (
              <InformationHelp hide={!this.props.showInfo}>
                Select a tab to view your other properties / options
              </InformationHelp>
            )}
          </TopOption>
        )}

        {noTabs && (
          <SelectBoxContainer className="form">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <InformationHelp2 style={{ marginBottom: "10px" }}>
                {this.props.browser.greaterThan.w480
                  ? "Click on the menu to view your other properties"
                  : "View your other properties"}
              </InformationHelp2>
            </div>
            <Select
              style={{ display: "block" }}
              onChange={(value) => this.handleTabSwitch(value)}
              value={activeTab}
              options={tabs.map(({ title, color }, index) => ({
                name: (
                  <>
                    <Icons color={color} />
                    {title}
                  </>
                ),
                value: index,
              }))}
            />

            {/* <select onClick={e => this.handleTabSwitch(e.target.value)} defaultValue={this.props.activeTab}>
                {tabs.map(({ title }, index) => <option value={index}>{title}</option>)}
              </select> */}
          </SelectBoxContainer>
        )}

        <TabsContainer numberOfTabs={tabCount}>
          <Tabs
            hideTabs={noTabs}
            active={activeTab}
            onTabSwitch={this.handleTabSwitch.bind(this)}
            onTabPositionChange={this.handleTabPositionChange.bind(this)}
            onTabClose={this.handleTabClose.bind(this)}
            onTabAdd={this.handleTabAdd.bind(this)}
            draggable={godmode && !previewMode && !this.state.tabFocused}
            showAdd={
              godmode && !previewMode && (user.miniproposal || {}).draftMode
            }
          >
            {tabs.map((value, index) => {
              return (
                <Tab
                  tabKey={value.id}
                  type={value.title}
                  //title={this.title(value.content)}
                  icon={this.switchIcons(
                    value.icon,
                    value.color,
                    value.title,
                    value.id,
                    activeTab === index,
                    this.toggleTabFocused.bind(this)
                  )}
                  showClose={true}
                >
                  {this.switchContent(
                    value.content,
                    this,
                    index,
                    ((user.miniproposal || {}).properties || [])[value.id]
                  )}
                </Tab>
              );
            })}
          </Tabs>
        </TabsContainer>
      </>
    );
  }
}

const Span = styled.span`
  display: inline-block;
  outline: none;
  min-width: 50%;
  cursor: pointer;

  ${(p) =>
    p.disabled
      ? ""
      : css`
          &:focus {
            border: solid 1px #a3a8b2;
            border-radius: 3px;
          }
        `}
`;

const EditableTitleBase = ({
  handleSubmit,
  submit,
  userId,
  propertyId,
  miniProposal,
  requestPatchUser,
  godmode,
  previewMode,
  active,
  toggleFocused,
  ...props
}) => {
  const titleRef = useRef(null);

  const onSubmit = ({ name }) => {
    if (titleRef.current) titleRef.current.scrollLeft = 0;
    requestPatchUser({
      userId,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: {
            properties: {
              [propertyId]: { name },
            },
          },
        },
      },
    });
  };

  const onEnter = (e) => {
    e.preventDefault();
    toggleFocused(false);
    titleRef.current.blur();
    submit();
  };

  const checkIfLeaveTab = useCallback((e) => {
    toggleFocused($(e.target).hasClass("tab-name"));
  }, []);

  useEffect(() => {
    $(window).on("mousedown.checkLeaveTab", checkIfLeaveTab);

    return () => {
      $(window).off(".checkLeaveTab");
    };
  }, [active]);

  if (!godmode || previewMode) {
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "inline-block" }}
      >
        <Span
          className="tab-text"
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "98%",
          }}
        >
          {miniProposal.properties[propertyId].name}
        </Span>
      </Form>
    );
  } else
    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "inline-block" }}
      >
        {!active ? (
          <Span
            className="tab-text"
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "98%",
            }}
          >
            {miniProposal.properties[propertyId].name}
          </Span>
        ) : (
          <Span
            className="tab-name tab-text"
            style={{ whiteSpace: "nowrap", overflow: "hidden", width: "98%" }}
            innerRef={titleRef}
            as={EditableContent}
            name="name"
            disabled={!godmode || previewMode}
            onBlur={submit}
            onEnter={onEnter}
          />
        )}
      </Form>
    );
};

const EditableTitle = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ destroyOnUnmount: false })(EditableTitleBase));

let newTabStyle = [
  "#2291FF",
  "#26BD27",
  "#BD10E0",
  "#FF4E4C",
  "#F5A623",
  "#00CD98",
  "#FF00B2",
  "#00E5FF",
  "#FF0000",
  "#2291FF",
  "#26BD27",
  "#BD10E0",
  "#FF4E4C",
  "#F5A623",
  "#00CD98",
  "#FF00B2",
  "#00E5FF",
  "#FF0000",
];

const userHasVideoProposal = (user) => {
  if (user.proposal && user.proposal.enabled) {
    const value = user.proposal.expiry;
    if (value) {
      const day = parseInt(value.slice(0, 2));
      const month = parseInt(value.slice(2, 4));
      const year = parseInt(value.slice(4, 8));
      const expiry = new Date(year, month - 1, day);
      if (expiry > new Date()) return true;
    }
  }
  return false;
};

const userHasOverviewOrSummary = (user) => {
  if (user.miniproposal) {
    return (
      !!user.miniproposal.showSummary || !!user.miniproposal.overviewActivated
    );
  }
  return false;
};

const extraTabs = (user) => {
  let extras = 0;
  if (userHasVideoProposal(user)) extras++;
  if (userHasOverviewOrSummary(user)) extras++;
  return extras;
};

const TabsContainer = styled.div`
  .r-a-t {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &,
    & * {
      box-sizing: border-box;
    }

    .add-wrapper {
      position: absolute;
      top: 0;
      left: calc(100% - 50px);
      width: 50px;
      height: 50px;

      transition: background ease 0.3s;
      background-image: url(${require("../../../img/button/file-add.png")});
      background-repeat: no-repeat;
      background-size: 28px 28px;
      background-position: 50% 50%;
      cursor: pointer;

      &:hover {
        background-image: url(${require("../../../img/button/file-add_fill.png")});
      }
    }

    .tab-wrapper {
      position: absolute;
      top: 0;
      left: ${(p) => css`-${Math.min(p.numberOfTabs * 0.25, 1)}%;`};
      width: ${(p) => Math.min(p.numberOfTabs * 25, 100) + "%;"};
      height: 50px;

      &.with-add {
        left: ${(p) => css`-${Math.min(p.numberOfTabs * 0.24, 0.96)}%;`};
        width: ${(p) => Math.min(p.numberOfTabs * 24, 96) + "%;"};
      }

      .tab-button {
        position: absolute;
        height: 50px;
        user-select: none;
        margin-right: -5px;

        &:hover {
          cursor: default;
        }

        &:not(.active):hover {
          .tab,
          .icon.loading,
          .icon.loading .mask {
            background: #f3f3f3;
          }

          .shadow {
            background: linear-gradient(to right, transparent, #f8f8f8);
          }
        }

        &.active {
          z-index: 999 !important;

          .tab {
            background: #fff;
            // border-width: 2px 0 0;
            // border-style: solid;
            // border-color: lighten(rgb(4,159,217), 5%);

            &:after {
              content: "";
              width: 100%;
              background-color: #fff;
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: -2px;
              height: 5px;
            }

            &.overview::after {
              bottom: 0 !important;
            }
          }

          .icon.loading,
          .icon.loading .mask {
            background: #fff;
          }

          .shadow {
            background: linear-gradient(to right, transparent, #fff);
          }
        }
      }

      .tab {
        position: absolute;
        left: 0;
        top: -4px;
        width: calc(100% - 5px);
        height: 50px;
        display: inline-block;
        border-radius: 4px 4px 0 0;
        vertical-align: top;
        margin-top: 4px;
        padding: 0 15px;
        background: #f9f9f9;
        z-index: 0;
        //transition: background ease .3s;
        user-select: none;
        border: 1px solid #d8dce7;
        border-bottom: none;
        background-color: #ececec;
        cursor: pointer;

        ${(props) =>
          props.numberOfTabs > 9 &&
          css`
            // padding: 0 10px;
          `}
      }

      .text {
        position: absolute;
        left: 13px;
        top: 17px;
        z-index: 999;
        font-size: 12px;
        width: calc(100% - 30px);
        font-family: LatoPDF;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        //overflow: hidden;
        white-space: nowrap;

        form {
          width: calc(100% - 24px);
        }

        &.with-close {
          width: calc(100% - 35px);

          ${(props) =>
            props.numberOfTabs > 9 &&
            css`
              // color: transparent;
              // width: 16px;
              // overflow: hidden;
              // display: inline-block;
              // position: relative;
              // left: calc(50% - 14px);
              // right: 0;
            `}
        }

        > * {
          position: relative;
          vertical-align: top;
        }

        .icon {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          margin-right: 8px;
          cursor: pointer;
        }

        .icon.loading {
          margin-top: 2px;
          width: 12px;
          height: 12px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(4, 159, 217);
          border-radius: 50%;
          background: #f9f9f9;
          transform: rotateZ(30deg);
          animation-name: IconLoading;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
          transition: background ease 0.3s;

          & .mask {
            position: absolute;
            left: -3px;
            top: -3px;
            width: 14px;
            height: 14px;
            background: #f9f9f9;
            clip: rect(0px, 14px, 14px, 7px);
            transition: background ease 0.3s;
          }
        }

        .icon.warning {
          margin-top: 2px;
          width: 12px;
          height: 12px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 5px;
            width: 2px;
            height: 8px;
            background: red;
          }

          &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: 5px;
            width: 2px;
            height: 2px;
            background: red;
          }
        }
      }

      .close {
        position: absolute;
        left: calc(100% - 30px);
        top: 12px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: rotateZ(45deg);
        display: none;

        &:hover {
          background: #eb5528;

          &::before,
          &::after {
            background: white;
          }
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 3px;
          top: 7px;
          width: 9px;
          height: 1px;
          background: #888;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 7px;
          top: 3px;
          width: 1px;
          height: 9px;
          background: #888;
        }
      }
    }

    .panel-wrapper {
      @media (min-width: 841px) {
        padding-top: 50px;
      }
      width: 100%;
      height: calc(100% - 50px);
      border-top: 1px solid lighten(rgb(4, 159, 217), 5%);
      z-index: 998;
      overflow: hidden;

      .panel {
        display: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: -1;

        &.active {
          display: block;
          z-index: 1;
        }
      }
    }

    .tooltip {
      display: none;
      position: absolute;
      left: 100px;
      top: 40px;
      z-index: 999;
      padding: 5px 15px;
      white-space: nowrap;
      background-color: #fff;
      color: #333;
      font-size: 12px;
      border: 1px solid #ddd;
      border-radius: 2px;
      box-shadow: 1px 1px 2px rgba(206, 206, 206, 0.8);
      opacity: 0.95;
    }
  }

  @keyframes IconLoading {
    from {
      transform: rotateZ(30deg);
    }

    to {
      transform: rotateZ(390deg);
    }
  }
`;

const InformationHelp = styled.div`
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: 0.5s opacity ease-in-out;
  font-size: 13px;
  color: #727c8f;
  background-size: 19px 19px;
  background-repeat: no-repeat;
  background-position: 10px 100%;
  background-image: url(${require("../../../img/tooltips/arrow_left.png")});
  padding: 14px 0 13px 40px;
`;

const InformationHelp2 = styled.div`
  font-size: 13px;
  color: #727c8f;
  padding: 0 35px 3px 40px;

  &:after {
    content: "";
    display: inline-block;
    position: relative;
    height: 19px;
    width: 19px;
    top: 14px;
    left: 15px;
    transform: scaleX(-1);
    background-size: 19px 19px;
    background-repeat: no-repeat;
    background-image: url(${require("../../../img/tooltips/arrow_left.png")});
  }
`;

const TopOption = styled.div`
  width: 100%;
  height: 36px;
  margin-bottom: 15px;
`;

const TabPanel = styled.div`
  background: #ffffff;
  border: 1px solid #d8dce7;
  border-radius: 0 4px 4px 4px;
  position: relative;
  width: 100%;

  @media (max-width: 645px) {
    /* border: none; */
    border-left: none;
    border-right: none;
    border-radius: 0 0 0 0;
  }

  .col-m1 {
    background-color: transparent !important;
  }
`;

const SelectBoxContainer = styled.div`
  padding: 15px 0 20px 0;

  @media (min-width: 645px) {
    padding: 15px 0 20px 0;
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(DetailedBreakdown);

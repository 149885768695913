import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { reduxForm } from "redux-form";
import LocationSearchInput from 'Components/LocationSearchInput';

import FormNavigation from "../Components/FormNavigation";
import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, Heading3, Heading4, RadioBox, RadioGroup, UnsavedChangesPrompt, timePeriod } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import { MultiStageForm, FormStage } from './Components/MultiStageForm';

import { phoneNumberProps } from 'Components/Forms/validation';

class SimpleForm extends Component {

  render() {
    let stage = 0;
    if (this.props.borrowType) stage += 1
    // if (this.props.borrowType === 'Trust' || this.props.borrowType === 'Self-Managed Super Fund') stage += 1;
    if (this.props.applicantType) stage += 1;
    //console.log('stage', stage);
    //console.log('props', this.props);
    return (
      <FormContainer onSubmit={this.props.handleSubmit}>
        <MultiStageForm main name='form' stage={stage}>
          
          <FormStage>
            <h1>Applicant Details</h1>
            {/* <LocationSearchInput /> */}
            {/* <p>The answers to these questions will allow us to ensure that you meet the eligibility criteria for the home loan you have selected before lodging an application.</p> */}
            <Heading3 width='large' position='left' title='What type of borrower is this loan for?' />
            <SelectGroup required id='application_meta-borrowType' width='small' position='left'  type='select' placeholder='' values={['Personal', 'Company', 'Trust', 'Self-Managed Super Fund']} />
          </FormStage>

          <FormStage>
            {togglePersonalApplications(this.props.borrowType, this.props.applicantType)}
          </FormStage>
          
          <FormStage>
            <Heading3 width='large' position='left' title='Personal Information' content='Please let us know these details so we can confirm your eligibility for this loan. Your date of birth is important as some lenders limit lending based on age.' />
            {typeContactInformation(this.props.applicantType)}
            <FieldGroup required id='user-firstName'  width='small' position='left' title='First name' type='text' />
            <FieldGroup required id='user-lastName' width='small' position='right' title='Last Name' type='text' />
            <DoubleFieldGroup {...phoneNumberProps} required id='user-phoneNumber' width='small' position='left' title='Contact Number' type='number-type' />
            <FieldGroup required id='user_meta-age' width='small' position='right' title='Age in Years' type='text' placeholder='' mask="int" />
            <SelectGroup required id='user_meta-dependents' width='small' position='left' title='Number of Dependents' type='select' placeholder='0' values={['0', '1', '2', '3', '4', '5', '6+']} />
            <DoubleFieldGroup {...phoneNumberProps} id='user-secondaryPhoneNumber' width='small' position='right' title='Alternate Phone Number' type='number-type' />
            <FieldGroup required id='user_meta-postcode' width='small' position='left' title='Postcode' type='text' placeholder='E.g. 2000' />
            {togglePartner(this.props.applicantType, this.props.borrowType)}
            <FormNavigation previous="disable" currentPage="1" disabled={this.props.invalid} submitting={this.props.submitting}/>
            <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting)} />
          </FormStage>

        </MultiStageForm>
      </FormContainer>
    );
  }
} 


function togglePartner(value, value1) {
  if (value==="youAndYourPartner" && value1 !== "Self-Managed Super Fund" && value1 !== "Trust") {
    return (
      <div>
        <Heading4 width='wide' position='left' title="Your Partner's Details" />
        <FieldGroup required id='user_partner-firstName' width='small' position='left' title='First Name' type='text' />
        <FieldGroup  required id='user_partner-lastName' width='small' position='right' title='Last Name' type='text'  />
        <DoubleFieldGroup {...phoneNumberProps} id='user_partner-phoneNumber' width='small' position='left' title='Contact Number' type='number-type' />
        <FieldGroup required id='user_partner_meta-age' width='small' position='right' title='Age' type='text' mask="int" />
      </div>
    );
  }
  else {
    return "";
  }
}

function togglePersonalApplications(value, value1) {

    return (
      <div>
        <Heading3 width='large' position='left' title='Who will be on the home loan?' />
        <div className="content four-box">
          <RadioGroup required name="application_meta-applicantType">
            <RadioBox text="Just You" subtext='Individual' value="justYou" select={value1} errorCallback={error => console.log('Error!!!:', error)} />
            <RadioBox text="You and your partner" subtext='Individual' value="youAndYourPartner" select={value1} />
            <RadioBox text="You and One or More Others" subtext='Tenants in Common' value="youAndOneOrMoreOthers" select={value1} />
            <RadioBox text="Other Situation" subtext="We'll be in touch to discuss" value="otherSituation" select={value1} />
          </RadioGroup>
        </div>
      </div>
    );
  
}

function typeContactInformation(value) {
  if (value === "youAndOneOrMoreOthers" || value === "otherSituation") {
    return (
      <Heading4 width='large' position='left' title="Primary Applicant's Details" />
    );
  }
  else {
    return (
      <Heading4 width='large' position='left' title='Your Details' />
    );
  }
}

SimpleForm = reduxForm({
  form: 'eligibility-applications',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-applications') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // can select values individually
    const applicantType = selector(state, 'application_meta-applicantType');
    const borrowType = selector(state, 'application_meta-borrowType');
    const firstName = selector(state, 'user-firstName');
    const lastName = selector(state, 'user-lastName');
    const partnerFirstName = selector(state, 'user_partner-firstName');
    const partnerLastName = selector(state, 'user_partner-lastName');

    return {
      applicantType,
      borrowType,
      firstName,
      lastName,
      partnerFirstName,
      partnerLastName,
      initialValues: {
        'user-phoneNumberType': 'mobile',
        'user-secondaryPhoneNumberType': 'mobile',
        'user_partner-phoneNumberType': 'mobile',
        ...getInitialValuesObject(state), 
        "user_meta-eligibility_complete_page1": "1", 
        "user_meta-eligibility_last_page": 1}
    }
  }
)(SimpleForm);

export default SimpleForm;

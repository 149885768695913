import { handleAction, handleActions } from 'redux-actions';
import {
  receiveProduct,
  requestProducts,
  receiveProducts,
} from './actions';

export const products = handleActions(
  new Map([
    [
      receiveProducts,
      (state={list: []}, { payload }) => {
        //console.log(payload);
        if (payload.isAddition) {
          return ( {list: [...state.list, ...payload.products], isFinished: payload.isFinished, total: payload.total });
        }
        return { list: payload.products, isFinished: payload.isFinished, loading: false, total: payload.total };
      }
    ],
    [
      receiveProduct,
      (state={list: []}, { payload }) => ({ ...state, current: payload })
    ],
    [
      requestProducts,
      (state={list: []}, { payload }) => ({ ...state, loading: true })
    ]
  ]), 
  { list: [] }
);

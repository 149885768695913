import React from 'react';
import { Img } from './Image';
import { logoFolder, uploadUrl } from 'modules';


const getAltLogo = (id) => {
  try {
    return require(`img/banks/${id}.png`);
  } catch(e) {
    return '';
  }
}

export default ({ product, width, height, maxFill, ...props }) => {
  if (!product.bank) {
    return (
      props.unloader || <h6 {...props}>{product.bank_name}</h6>
    );
  }

  const logoSrc = uploadUrl + '/' + logoFolder + '/' + product.bank + '.png';

  return (
    <Img 
      width={width}
      height={height}
      maxFill={maxFill || 1}
      src={[logoSrc, getAltLogo(product.bank)]} 
      alt={product.bank_name + " logo"} 
      unloader={<h6>{product.bank_name}</h6>}
      crossOrigin="anonymous"
      {...props}
    />
  )
    
}




  
import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import CurrentLoanDetails from "./Forms/CurrentLoanDetails"

class RefinanceAnalysisDetails extends Component {
  render() {
    if (this.props.currentModal === "refinanceAnalysisDetails") {
    return (
      <div className="modal-content">
        <h1>Your Current Loan Details</h1>
        <CurrentLoanDetails button="Continue"/>
      </div>
    ) } else {
      return "";
    }
  }
}

export default RefinanceAnalysisDetails;

import React from 'react';
import { reduxForm, change, blur } from 'redux-form';
import QuestionWrapper from "../Components/QuestionWrapper";
import Navigation from "../Components/Navigation";
import CurrencyField from '../Components/CurrencyField';
import { connect } from 'react-redux';


import { createNumberBetween } from 'Components/Forms/validation';

const numberBetween = createNumberBetween(20000, 20000000, (low, high) => `Purchase price must be between $${low} and $${high}`);

const notMoreThanDoublePurchasePrice =
(value, values, props) =>props.purchasePrice && (value > props.purchasePrice * 2) ? 'Loan amount must be less than 200% of the property value' : null;

let PurchasePrice = function({ id, handleSubmit, pristine, maxQuestion, setQuestion, debounce, ...props }) {
  return (
    <QuestionWrapper isTransitioning={props.isTransitioning} gotoQuestion={props.gotoQuestion} invalid={props.invalid} debounce={debounce} id={id} setQuestion={setQuestion} currentQuestion={props.currentQuestion} onSubmit={handleSubmit} maxQuestion={maxQuestion}>
      <h1>What is your current loan amount?</h1>
      <h2>If you’re not sure, an approximation is fine.</h2>
      <CurrencyField required validate={[numberBetween, notMoreThanDoublePurchasePrice]} id='user_meta-loanAmount' type='text' />
      <Navigation hidden={ pristine } name="Next Step →" />
      <img className='img-price' src={require('../../../img/page_illustrations/onboarding_documents.png')}/>
    </QuestionWrapper>
  )
}

PurchasePrice = reduxForm({
  destroyOnUnmount: false,
  form: 'onboarding-loan-amount',
  onChange: (values, dispatch, props) => {
    dispatch(change('onboarding-loan-amount', 'user_meta-refinanceLoanAmount', values['user_meta-loanAmount']));
    dispatch(change('onboarding-purchase-price', 'user_meta-loanAmount', values['user_meta-loanAmount']));
    dispatch(blur('onboarding-purchase-price', 'user_meta-purchasePrice', props.purchasePrice));
  }
})(PurchasePrice);

const stateToProps = state => {
  let purchasePrice = null;
  if (state.form['onboarding-purchase-price']
  && state.form['onboarding-purchase-price'].values) {
    purchasePrice = state.form['onboarding-purchase-price'].values['user_meta-purchasePrice'];
  }
  return {purchasePrice};
}; 

export default connect(stateToProps)(PurchasePrice);

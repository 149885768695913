import React from "react"; import { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";
import LinkButton from "Components/LinkButton";

function mapStateToProps(state) {
  return {
    email: state.user.email,
    newEmail: state.user.newEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class AccountSettings extends Component {

  render() {

    return (
    <main className={"my-profile-confirmation " + this.props.type}>
      <div className="main-container">        
          <div className="confirmation">
            {(!this.props.newEmail)?
              <div>
                <h2>Profile Updated</h2>
                <p>Your profile has been updated.<br/>All is now well in the world 😀</p>
              </div>
            :
              <div>
                <h2>Please Check Your E-Mail</h2>
                <p>Your new e-mail address needs to be verified.<br/>We have sent a verification link to your e-mail address.</p>
              </div>
            }
            <LinkButton to={"/dashboard"} className="navigation">Go back to Dashboard</LinkButton>
          </div>
      </div>
    </main>
  );
}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

import React from "react"; import { Component } from "react";
import { reduxForm } from "redux-form";
import LinkButton from "Components/LinkButton";

import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { TextArea, FormContainer, SelectGroup, DoubleFieldGroup } from "Components/Forms";

class CheckEligibilityHelpCallback extends Component {
  render() {
    if (this.props.currentModal === "checkEligibilityHelpCallback") {
    return (
      <div className="modal-content">
        <h1>Thanks for getting in contact</h1>
        <h2>We look forward to talking in person</h2>
        

      </div>
    ) } else {
      return "";
    }
  }
}

export default reduxForm({
  form: "simple", // a unique identifier for this form
})(CheckEligibilityHelpCallback);

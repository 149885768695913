import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { reduxForm, FieldArray } from 'redux-form';

import { FieldGroup, FormContainer, SelectGroup, DoubleFieldGroup, Heading3, Heading4, Button, UnsavedChangesPrompt, timePeriod } from "Components/Forms";
import getInitialValuesObject from "utils/getInitialValuesObject";
import FormNavigation from "../Components/FormNavigation";

import { MultiStageForm, FormStage, FadeIn } from './Components/MultiStageForm';


class SimpleForm extends Component {
  
  state = {
    numberOfProperties: 0,
  }
  
  render() {
    // const { handleSubmit, pristine, reset, submitting } = props;
    const { props } = this;
    let stage = 0;
    if (props.ownHome) stage += 1;
    if (props.ownHome === 'No') stage += 1;
    if (props.homeIsMorgaged) stage += 1;


    return (
      <FormContainer extended onSubmit={props.handleSubmit}>


        <MultiStageForm main name='form' stage={stage}>

          <FormStage>
            <h1>Other Properties</h1>
            <p className='under-heading'>In order to assess your ability to the service the selected loan, we need to understand more about other properties you may own.</p>
            <Heading3 width='large' position='left' title='Do you own your own home?' />
            
              <SelectGroup required id='user_meta-ownHome' width='small' position='left' type='select' placeholder='Yes' values={['Yes', 'No']} />
          </FormStage>

          <FormStage>
            {toggleHomeDetails(props.ownHome, props.homeIsMorgaged)}
            
            {/* <SelectGroup id='user_meta-numInvProperties' validate={selectRequired} width='small' position='left' title='How any investment properties do you own?' required='Yes' type='select' placeholder='0' values={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']} /> */}
            
            
            {/* {addProperty(props.numInvProperties, props)} */}
            
          </FormStage>
          <FormStage>
            <FieldArray component={InvestmentProperties} name='user_meta-investmentProperties' {...props}/>
            <FormNavigation currentPage="3" disabled={props.invalid} submitting={this.props.submitting}/>
          </FormStage>
        </MultiStageForm>
        <UnsavedChangesPrompt when={!(props.pristine || props.submitting)} />
      </FormContainer>
    );

  }
}

const initialInvestmentProperty = {
  isMorgaged: 'No',
  rentType: timePeriod.WEEK,
  approxRepaymentType: timePeriod.MONTH,
}

class InvestmentProperties extends Component {

  render() {
    const { fields } = this.props;
    let headingContent;
    if (this.props.loanPurpose === 'purposeRefinance') headingContent = 'Please add any investment properties you own. If applicable, please exclude the property you are refinancing from this list.';
    else headingContent = 'Please add any investment properties you own. If applicable, please exclude the property you are purchasing from this list.';
    return (
      <FadeIn>
        <Heading3 
          width='large' 
          position='left' 
          title='Investment Properties' 
          content={(fields && fields.length) ? '' : headingContent}
        />
        {
          fields.map((item, index) => (
            <div key={index} className='field-array'>
              <FadeIn >
                <button type='button' className="delete top-right" onClick={() => fields.remove(index)}></button>
                <Heading4 width='large' position='left' title={"Property " + (index + 1)} content='Please provide details of this property below.' />
                <DoubleFieldGroup required id={`${item}.rent`} width='small' position='left' title='Rent' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
                <SelectGroup required id={`${item}.ownership`} width='small' position='right' title='Ownership' type='select' placeholder='' values={ownerList(this.props)} />
                <div style={{width: '580px'}}>
                  <SelectGroup empty hideOptional id={`${item}.isMorgaged`} title='Is there a morgage on this property?' width='small' position='left' type='select' placeholder='No' values={['No', 'Yes']} />
                </div>
                {fields.get(index) && fields.get(index).isMorgaged === 'Yes' &&
                  <FadeIn>
                    <FieldGroup required id={`${item}.loanAmount`} width='small' position='left' title='Loan Amount' type='text' placeholder='0' mask="monetary" />
                    <FieldGroup required id={`${item}.currentInterestRate`} width='small' position='right' title='Current Interest Rate' type='text' placeholder='0.00 %' mask="percentage" />
                    <DoubleFieldGroup required id={`${item}.approxRepayment`} width='small' position='left' title='Approximate Repayment' type='monetary-time' mask='monetary' placeholder='0' mask="monetary" />
                    <SelectGroup required id={`${item}.repaymentType`} width='small' position='right' title='Repayment Type' type='select' placeholder='Principal & Interest' bottomLabel='Not sure which? Select Principal & Interest' values={['Principal & Interest', 'Interest Only']} />
                  </FadeIn>
                }
              </FadeIn>
            </div>
          ))
        }
        <Button onClick={() => fields.push(initialInvestmentProperty)} type='button' width='small' position='left' title='Add Investment Property' />
      </FadeIn>
    )
  }
}

const toggleHomeDetails = (ownHome, homeIsMorgaged) => 

    ownHome === 'Yes' ?
      <FadeIn>
        <div>
          <Heading3 width='large' position='left' title='Is there a mortgage on your home?' />
          <SelectGroup required id='user_meta-homeIsMorgaged' width='small' position='left' type='select' values={['No', 'Yes']} />
        </div>
        {homeIsMorgaged === 'Yes' &&
          <FadeIn>
            <FieldGroup required id='user_meta-homeLoanAmount' width='small' position='left' title='Loan Amount' required='Yes' type='text' placeholder='0' mask="monetary" />
            <FieldGroup required id='user_meta-homeCurrentInterestRate' width='small' position='right' title='Current Interest Rate' required='Yes' type='text' placeholder='0.00 %' mask="percentage" />
            <DoubleFieldGroup required id='user_meta-homeApproxRepayment' width='small' position='left' title='Approximate Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
            <SelectGroup required id='user_meta-homeRepaymentType' width='small' position='right' title='Repayment Type' required='Yes' type='select' placeholder='Principal & Interest' bottomLabel='Not sure which? Select Principal & Interest' values={['Principal & Interest', 'Interest Only']} />
          </FadeIn>
        }
      </FadeIn> : '';


// function toggleHomeDetails(ownHome) {
//   if (ownHome === "Yes") {
//     return (
//       <div>
//         <Heading3 width='large' position='left' title='Home Details' content='Please provide details of your home below. The more details you provide here, the more accurate your eligibility results will be.' />
//         <FieldGroup id='user_meta-homeLoanAmount' width='small' position='left' title='Loan Amount (if any)' required='Yes' type='text' placeholder='0' mask="monetary" />
//         <FieldGroup id='user_meta-homeCurrentInterestRate' width='small' position='right' title='Current Interest Rate' required='Yes' type='text' placeholder='0.00 %' mask="percentage" />
//         <DoubleFieldGroup id='user_meta-homeApproxRepayment' width='small' position='left' title='Approximate Repayment' required='Yes' type='monetary-time' mask='monetary' placeholder='0' />
//         <SelectGroup id='user_meta-homeRepaymentType' width='small' position='right' title='Repayment Type' required='Yes' type='select' placeholder='Principal & Interest' values={['Principal & Interest', 'Interest Only']} />
//       </div>
//     );
//   }
//   else {
//     return "";
//   }
// }

function ownerList(props) {
  let name = props.firstName + (props.lastName ? (" " + props.lastName) : '');
  let partner = props.partnerFirstName + " " + props.partnerLastName;
  let together = name + " & " + partner + " Jointly (50/50)";
  let other = 'Other (e.g. Tenants in Common)';

  if (props.applicantType == "youAndYourPartner") {
    return [{text: name, value: 'user'}, {text: partner, value: 'partner'},  {text: together, value: 'user&partner'}, {text: other, value:'other'}];
  } else {
    return [{text: name, value: 'user'}, {text: other, value:'other'}];
  }
}

SimpleForm = reduxForm({
  form: 'eligibility-otherProperties',  // a unique identifier for this form
})(SimpleForm);

const selector = formValueSelector('eligibility-otherProperties') // <-- same as form name
SimpleForm = connect(
  state => {
    //console.log(state);
    // external values
    const application = (state.user.applications || [])[0] || {};
    const loanPurpose = (application.meta || {}).loanPurpose;
    const products = application.products || [];
    const ooProductExists = !!products.find(p => p.maximum_lvr_own_occ > 0);
    const ownHome = selector(state, 'user_meta-ownHome');
    const applicantType = selector(state, 'application_meta-applicantType');
    const homeIsMorgaged = selector(state, 'user_meta-homeIsMorgaged');
    const firstName = state.user.firstName;
    const lastName = state.user.lastName;
    
    let partnerFirstName;
    let partnerLastName;
    if (state.user.partners && state.user.partners.length) {
      partnerFirstName = state.user.partners[0].firstName;
      partnerLastName = state.user.partners[0].lastName;
    }

    // internal values
    const numInvProperties = selector(state, 'user_meta-numInvProperties');

    return {
      ooProductExists,
      loanPurpose,
      homeIsMorgaged,
      applicantType,
      numInvProperties,
      ownHome,
      firstName,
      lastName,
      partnerFirstName,
      partnerLastName,
      initialValues: {
        'user_meta-homeApproxRepaymentType': timePeriod.MONTH,
        'user_meta-ownHome': loanPurpose === 'purposeRefinance' && ooProductExists ? 'Yes' : null,
        ...getInitialValuesObject(state), 
        "user_meta-eligibility_complete_page3": "1", 
        "user_meta-eligibility_last_page": 3 
      },
    }
  }
)(SimpleForm);

export default SimpleForm;

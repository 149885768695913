import React from "react";
import { Component } from "react";

import { NavLink } from "react-router-dom";
import LoanSearchResult from "./Components/LoanSearchResult";
import LoanSearchHeader from "./Components/LoanSearchHeader";
import LoanSearch from "./Components/LoanSearch";
import LoanSearchFilter from "./Components/LoanSearchFilter";
import BorrowHeader from "Components/Global/BorrowHeader";
import updateFormValues from "modules/updateFormValues";

import LinkButton from "Components/LinkButton";
import Tooltips from "Components/Tooltips";

import requestFilteredProducts from "modules/product/requestFilteredProducts";
import calculateLoanValues from "utils/calculateLoanValues";
import { PageLoader } from "Components/Global/Loader";
import Disclaimer from "Components/Global/GlobalDisclaimer";
import styled, { css } from "styled-components";
// import { threadId } from "worker_threads";

let comparisonRateHighest = 0;
const $ = window.$;

class SearchLoans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUnstarShown: false,
      isShownMore: true,
      sticky: false,
      disableSticky: this.props.browser.greaterThan.w645,
      greaterThanW645: this.props.browser.greaterThan.w645,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document.title = "Secure Finance - Search and Compare Loans";
    this.props.setTitle("Search and Compare Loans");
    this.props.setPageID("search");
    $("main").scroll(this.handleScroll);
  }

  handleScroll(e) {
    if ($("#table-heading-container").offset().top < 60) {
      this.setState((state) => ({ ...state, sticky: true }));
    } else {
      this.setState((state) => ({ ...state, sticky: false }));
    }
  }

  updateFilters = (result) => {
    //console.log('update filter result:', result);
    const { sortField, sortDirection } = this.props.user.meta;
    updateFormValues(
      result,
      () => {
        requestFilteredProducts(
          null,
          0,
          sortField,
          sortDirection,
          this.props.user.godmode
        );
      },
      true,
      false
    );
  };

  changeSorting = (field) => {
    const { sortField, sortDirection } = this.props.user.meta;
    if (sortField === field) {
      this.props.requestPatchUser({
        userId: this.props.user.id,
        data: { meta: { sortDirection: -sortDirection } },
        callbackEarly: () =>
          requestFilteredProducts(
            null,
            0,
            field,
            -sortDirection,
            this.props.user.godmode
          ),
      });
    } else {
      this.props.requestPatchUser({
        userId: this.props.user.id,
        data: { meta: { sortField: field, sortDirection: 1 } },
        callbackEarly: () =>
          requestFilteredProducts(null, 0, field, 1, this.props.user.godmode),
      });
    }
  };

  tags(result) {
    let product = calculateLoanValues(result);
    // console.log(product);
    // console.log(parseFloat(product.comparisonRate));

    if (parseFloat(product.comparisonRate) < comparisonRateHighest) {
      comparisonRateHighest = product.comparisonRate;
      //console.log("Highest: " + comparisonRateHighest);
    }
    return {
      comparisonRate: comparisonRateHighest,
    };
  }

  toggleUnstarPopup() {
    const { isUnstarShown } = this.state;
    if (!isUnstarShown) {
      $(window).click((e) => {
        if (!$(e.target).closest(".close-button").length) {
          this.setState({ isUnstarShown: false });
        }
      });
    } else {
      $(window).off();
    }
    this.setState({ isUnstarShown: !isUnstarShown });
  }

  tooManyOptionsClose(type) {
    this.props.requestPatchUser({
      userId: this.props.user.id,
      data: { meta: { user_meta_tooltips: type } },
      callback: () => {},
    });
  }

  stickyState(sticky, disableSticky) {
    if (sticky && !disableSticky) {
      return true;
    } else {
      return false;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps.browser.mediaType);
    // console.log(nextProps.browser.greaterThan.w645 + " & " + prevState.greaterThanW645);
    if (nextProps.browser.greaterThan.w645 !== prevState.greaterThanW645) {
      if (nextProps.browser.greaterThan.w645) {
        return {
          disableSticky: true,
          greaterThanW645: nextProps.browser.greaterThan.w645,
        };
      } else {
        return {
          disableSticky: false,
          greaterThanW645: nextProps.browser.greaterThan.w645,
        };
      }
    }
  }

  render() {
    const { sortField, sortDirection } = this.props.user.meta;
    const { isLoading } = this.props;
    //console.log('Loading:', isLoading);
    const hasPriorityProducts = !!(
      this.props.products &&
      this.props.products.length &&
      this.props.products[0].is_priority_product
    );

    return (
      <main
        className={
          "search-loans " + this.props.state || "" + " " + this.props.type || ""
        }
      >
        <div className="main-container">
          <BorrowHeader onSubmit={this.updateFilters} type="loan-search" />

          <LoanSearchFilter onSubmit={this.updateFilters} {...this.props} />
          <div className="note-results">
            {!this.props.browser.greaterThan.w480 ? (
              <>
                <strong>Note:</strong> These results may be limited by your
                search criteria. Please{" "}
                <span
                  onClick={() => this.props.openModal("checkEligibilityHelp")}
                >
                  Get In Touch
                </span>{" "}
                for for free assistance.
              </>
            ) : (
              <>
                <strong>Note:</strong> Due to rates recently changing at a rapid
                rate, there may be delays with rates reflecting our most updated
                offerings. These results are based on your search criteria and
                may not show all products available to you. Please{" "}
                <span
                  onClick={() => this.props.openModal("checkEligibilityHelp")}
                >
                  Get In Touch
                </span>{" "}
                for help with identifying and applying for the best loan product
                for your situation.
              </>
            )}
          </div>
          <Tooltips type="search" />
          <LoanSearch>
            <PageLoader show={isLoading} />

            <div id="table-heading-container" style={{ minHeight: "50px" }}>
              <LoanSearchHeader
                sticky={this.stickyState(
                  this.state.sticky,
                  this.state.disableSticky
                )}
                onChange={this.changeSorting}
                sortDirection={sortDirection}
                sortField={sortField}
                refinance={this.props.loanPurpose}
                state={this.props.state === "unverified" ? false : true}
              />
            </div>
            {hasPriorityProducts && (
              <PriorityProductText>
                Secure Finance Choice products are top selections made by the
                Secure Finance team.
              </PriorityProductText>
            )}
            {!this.props.products || this.props.products.length === 0 ? (
              <div className="empty">
                {/* <div className="illustration"></div> */}
                <img
                  src={require("../../img/page_illustrations/search_empty.png")}
                  alt="empty"
                />
                <h4>
                  Looks like there are no results <br /> for the current search.{" "}
                </h4>
                <p>Try changing your scenario above to expand your search.</p>
              </div>
            ) : (
              this.props.products.map((result, index) => {
                if (index === 2) {
                  return (
                    <div key={result.id}>
                      <LoanSearchResult
                        result={result}
                        tags={this.tags(result)}
                        state={this.props.state === "unverified" ? false : true}
                        refinance={this.props.loanPurpose}
                      />
                      {TooManyOptions(this)}
                    </div>
                  );
                }
                if (index < this.props.products.length) {
                  return (
                    <LoanSearchResult
                      result={result}
                      key={result.id}
                      tags={this.tags(result)}
                      state={this.props.state === "unverified" ? false : true}
                      refinance={this.props.loanPurpose}
                    />
                  );
                } else {
                  return "";
                }
              })
            )}

            {!this.props.loansFinished &&
            this.props.products &&
            this.props.products.length > 0 ? (
              <Button
                onClick={() => {
                  requestFilteredProducts(
                    () => {},
                    this.props.products.length,
                    sortField,
                    sortDirection,
                    this.props.user.godmode
                  );
                }}
                className="load-more"
              >
                Click to Load More...
              </Button>
            ) : (
              ""
            )}
          </LoanSearch>
          <References>
            <ol>
              <li>
                Comparison Rates: Any quoted comparison rate is only true for
                the example given and may not include all fees and charges.
                Different terms, loan amounts or fees may result in a different
                comparison rate. Comparison rates are based on a loan amount of
                $150,000 over a loan term of 25 years.
              </li>

              <li>
                Borrowing Power: Results should only be used as a rough guide as
                we make a number of assumptions for these calculations. Lender
                credit policy may also change at any time. Please contact us for
                a personalised assessment.
              </li>

              <li></li>
              <li>
                Secure Finance is privately owned and operated in Australia. Our
                goal is to help Australians with their finance needs. We are
                proud members of the Finance Brokers Association of Australia
                (FBAA) and the Australian Financial Complaints Authority (AFCA).
                Note that our lending panel is comprised of more than 50 lenders
                which provide thousands of products, however not all of these
                products are listed on this platform. Furthermore we do not
                cover the entire market and there may be other features or
                options available to you. We work to ensure all online lender
                product data is accurate, but this may not always be the case.
                That said, we will never lodge an application before confirming
                the specifics of a loan product with you.
              </li>

              <Disclaimer />
            </ol>
          </References>
        </div>
      </main>
    );
  }
}

function TooManyOptions(that) {
  return (
    <div
      className="too-many-options"
      style={{
        pointerEvents: that.props.user.meta.user_meta_tooltips ? "all" : "none",
        height: that.props.user.meta.user_meta_tooltips ? "" : "0",
        padding: that.props.user.meta.user_meta_tooltips ? "" : "20px 0 0 0",
        opacity: that.props.user.meta.user_meta_tooltips ? "1" : "0",
      }}
    >
      <div onClick={() => that.toggleUnstarPopup()} className="close-button">
        {that.state.isUnstarShown ? (
          <a className="hide-tips" onClick={() => that.tooManyOptionsClose(0)}>
            Hide tips and illustrations?
          </a>
        ) : (
          ""
        )}
      </div>
      <h2>Too many options?</h2>
      <div className="options-container">
        <button onClick={() => that.props.openModal("checkEligibilityHelp")}>
          Get Advice
        </button>
        <button className="chat" onClick={() => window.Intercom("show")}>
          <span>Let's chat</span>
        </button>
      </div>
    </div>
  );
}

const Button = styled.button`
  @media (max-width: 645px) {
    margin: 20px 10px 0px 10px;
    width: calc(100% - 20px) !important;
  }
`;

const References = styled.div`
  ol {
    border-top: solid 1px #e9ebf1;
    margin-top: 30px;
    padding-top: 10px;

    li {
      font-weight: 400;
      font-family: "Lato", sans-serif;
      font-size: 14px;
      color: #727c8f;
      margin-top: 8px;
      line-height: 1.2;
    }
  }

  strong {
    font-weight: bolder;
  }
`;

const PriorityProductText = styled.div`
  font-size: 12px;
  color: #727c8f;
  margin-top: 10px;
  margin-bottom: -10px;
`;

export default SearchLoans;

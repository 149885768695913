import React from "react";
import styled, { css } from "styled-components";
import ReactDOM from "react-dom";
import { bindActionCreators } from "redux";
import { reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from "modules/user/actions";
import {
  Container,
  Heading,
  EditableParagraph,
  Column,
  TwoColumnContainer,
} from "./Shared";

const FORM_NAME = "overview-why-loanbase";
const BODY_NAME = "overview_why_body";
class WhyLoanbase extends React.Component {
  constructor(props) {
    super();
    this.inputRef = React.createRef();
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  };

  resetSection = () => {
    const body = this.props.globalSettings[BODY_NAME];
    const formValues = { body };
    this.patchProposal(formValues);
    this.props.initialize(formValues);
    this.props.reset();
  };

  save = () => {
    let values = { ...this.props.formValues };
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    this.patchProposal(values);
    this.props.initialize(values);

    this.props.cancelEdit();
  };

  patchProposal = (data, callback) => {
    let toSave;

    toSave = {
      overview: {
        whyLoanbase: data,
      },
    };

    this.props.requestPatchUser({
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: toSave,
        },
      },
      callback,
    });
  };

  enableEdit = () => {
    setTimeout(() => {
      this.inputRef.current.focus();
    });

    this.props.toggleActive();
  };

  onKeyDown = (e) => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current))
      return;
    if (e.key == "8" && e.altKey) {
      setTimeout(() => {
        document.execCommand("insertUnorderedList");
      }, 5);
    }
    if (e.key === "u" && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand("underline");
      }, 5);
      e.preventDefault();
      return false;
    }
  };

  blurOnEnter() {
    document.activeElement.blur();
  }

  render() {
    const { active, editMode } = this.props;
    const noGodmode =
      this.props.browser.lessThan.w840 || this.props.browser.is.w840;
    return (
      <Container active={active}>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
          {editMode && (
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              type="overview-section"
              style={{
                float: "right",
                position: "absolute",
                top: "-10px",
                right: "0",
              }}
              cancelSaveContainer="20px"
            />
          )}

          <TwoColumnContainer>
            <ColumnImage>
              <img
                src={require("../../../../../img/page_illustrations/why_loanbase.png")}
                alt="why_loanbase"
              />
            </ColumnImage>
            <Column>
              <Heading>Why Secure Finance</Heading>
              <p>
                <EditableParagraph
                  richText={!!active}
                  innerRef={this.inputRef}
                  allowLineBreaks
                  onKeyDown={(e) => this.onKeyDown(e)}
                  className={active ? "active" : ""}
                  name="body"
                  disabled={!active}
                />
              </p>
            </Column>
          </TwoColumnContainer>
        </Form>
      </Container>
    );
  }
}

const ColumnImage = styled.div`
  width: 380px;

  @media (max-width: 780px) {
    width: 270px;
  }

  @media (max-width: 645px) {
    width: 250px;
    order: 2;
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    width: 180px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    object-fit: contain;
    max-height: 265px;
  }
`;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      requestPatchUser,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  const { whyLoanbase } = state.user.meta.miniproposal.overview || {};
  const { globalSettings } = state.user;
  const body = (whyLoanbase || {}).body || globalSettings[BODY_NAME];

  return {
    globalSettings,
    body: ((state.form[FORM_NAME] || {}).values || {}).body,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    initialValues: { body },
    formValues: (state.form[FORM_NAME] || {}).values || {},
    browser: state.browser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(WhyLoanbase)
);

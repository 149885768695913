import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Form } from "redux-form";
import { setTitle, setPageID } from "modules/page/actions";
import { SectionContainer } from "../theme/General";
import { emailService } from "modules";
import { createRequired } from "Components/Forms/validation";
import styled, { css } from "styled-components";
import Tooltips from "Components/Tooltips";
import BrokerSection from "./sections/BrokerSection";
import ExecutiveSummary from "./sections/ExecutiveSummary";
import AddSectionSummary from "../component/AddSectionSummary";
import SectionButton from "../component/SectionButton";
import Switch from "../component/Switch";
import AggregateRepayments from "../component/AggregateRepayments";
import BankLogoBase from "Components/Global/BankLogo";
import EditableContent from "Components/Forms/EditableContent";
import { requestPatchUser } from "modules/user/actions";
import formatNumber from "utils/formatNumber";
import parseNumber from "utils/parseNumber";

import { calculateProductValues } from "utils/CalculateProductValues";

const required = createRequired("Please type a message");

function mapStateToProps(state) {
  const summary = state.user.meta.miniproposal.summary || {};

  const summaryData = {
    execSummary: {},
    overview: {},
    additionalDetails: {},
    ...summary,
  };

  return {
    userId: state.user.id,
    proposal: state.user.meta.proposal,
    user: state.user,
    miniProposal: state.user.meta.miniproposal,
    previewMode: state.user.meta.miniproposal.previewMode,
    lenders: state.user.lenders,
    summary: summaryData,
    initialValues: summaryData.overview,
    formData: (state.form["proposal-summary"] || {}).values || {},
    broker: state.user.proposalBroker,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestPatchUser,
      setTitle,
      setPageID,
    },
    dispatch
  );
}

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      repaymentPeriod: "monthly",
    };
  }

  toggleEdit(val) {
    console.log("Edit mode");
    this.setState((state) => ({ ...state, editMode: val }));
  }

  cancelEdit() {
    this.setState((state) => ({ ...state, editMode: undefined }));
  }

  cancelOverViewEdit() {
    this.props.reset();
    this.cancelEdit();
  }

  isActive(editMode, val) {
    if (editMode === val) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    document.title = "Secure Finance - Proposal";
    this.props.setTitle("Proposal");
    this.props.setPageID("proposal");
  }

  sendQuestion = ({ question }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await emailService.create({
          type: "proposal-question",
          question,
        });
        this.props.dispatch(this.props.change("question", ""));
        this.props.dispatch(this.props.untouch("question"));
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  onRepaymentsChange = ({ repaymentPeriod }) => {
    console.log("repayment period changed:", repaymentPeriod);
    this.setState({ repaymentPeriod });
  };

  onListKeyDown = (e) => {
    if (e.key === "Enter" || !e.target.value) {
      document.execCommand("formatBlock", false, "div");
    }
    if (e.key === "u" && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand("underline");
      }, 5);
      e.preventDefault();
      return false;
    }
  };

  patchProposal = (data) => {
    const { userId } = this.props;
    if (data.overview) {
      if (data.overview.rebateAmount) {
        data.overview.rebateAmount =
          formatNumber(
            parseInt(data.overview.rebateAmount.replace(/\D/g, ""))
          ) || "";
      }
      if (data.overview.brokerRebateAmount) {
        data.overview.brokerRebateAmount =
          formatNumber(
            parseInt(data.overview.brokerRebateAmount.replace(/\D/g, ""))
          ) || "";
      }
    }
    //this.props.initialize(data);
    this.props.requestPatchUser({
      userId,
      params: {
        mergeMeta: true,
      },
      data: {
        meta: {
          miniproposal: {
            summary: data,
          },
        },
      },
    });
  };

  addSection(types) {
    const defaults = {
      execSummary: {
        title: "Executive Summary",
        text: "Add Text Here",
      },

      overview: {
        rebateAmount: "1,000",
        rebateText: "Add Description Here",
        rebateAdditional: "Add Additional Info Here",
        brokerRebateAmount: "1,000",
        brokerRebateText: "Add Description Here",
        brokerRebateAdditional: "Add Additional Info Here",
        benifitList: "",
      },

      additionalDetails: {
        title: "Additional Details",
        text: "Add Text Here",
      },
    };

    const patch = types
      .split(" ")
      .reduce(
        (obj, type) => ({
          ...obj,
          [type]: { enabled: true, ...defaults[type] },
        }),
        {}
      );

    this.patchProposal(patch);
  }

  removeSection(type) {
    this.patchProposal({ [type]: { enabled: false } });
  }

  saveOverview = (data) => {
    this.patchProposal({ overview: data });
    this.cancelEdit();
  };

  toggleOption = (type) => {
    this.patchProposal({
      overview: { [type]: !this.props.summary.overview[type] },
    });
  };

  isOptionActive = (type) => {
    return !!this.props.summary.overview[type];
  };

  render() {
    const { miniProposal, lenders, summary, formData } = this.props;
    const { repaymentPeriod } = this.state;

    console.log(this.props);

    let loans = [];
    if (Array.isArray(miniProposal.tabs)) {
      for (const id of miniProposal.tabs) {
        const tab = miniProposal.properties[id];
        if (!tab) continue;
        const loanComparison = tab.loanComparison;
        const currentLoan = tab.currentLoan;
        const loanId = loanComparison.loanOrder[0];
        if (!loanId) continue;
        const newLoan = calculateProductValues(loanComparison.loans[loanId], currentLoan);
    
        if (currentLoan) {
          loans.push({
            newLoan,
            currentLoan,
          });
        }
      }
    }
    

    const proposedBanks = loans.reduce((obj, loan) => {
      const bank = loan.newLoan.bank;
      return {
        ...obj,
        [bank]: [...(obj[bank] || []), loan.newLoan],
      };
    }, {});

    const loansTotal = loans
      .map((l) => l.currentLoan.loanAmount)
      .reduce((a, b) => a + b, 0);

    let weightedIIR =
      loans
        .map((l) => l.newLoan.iir * l.currentLoan.loanAmount)
        .reduce((a, b) => a + b, 0) / loansTotal;
    weightedIIR = (weightedIIR || 0).toFixed(2);

    let weightedTrueRate =
      loans
        .map((l) => parseFloat(l.newLoan.trueRate) * l.currentLoan.loanAmount)
        .reduce((a, b) => a + b, 0) / loansTotal;
    weightedTrueRate = (weightedTrueRate || 0).toFixed(2);

    const interestRateBreakdown = loans
      .map(
        (l) =>
          `${l.newLoan.iir}%p.a.($${formatNumber(l.currentLoan.loanAmount)})`
      )
      .join(", ");

    const createBreakdown = (key) => {
      return Object.entries(proposedBanks)
        .map(([id, loans]) => [
          lenders.find((l) => l.id === id)?.name,
          loans.reduce((a, b) => a + parseNumber(b[key]), 0),
        ])
        .map(([name, value]) => `${name}($${formatNumber(parseNumber(value))})`)
        .join(", ");
    };

    const aggregateRepayments = loans
      .map((l) => l.newLoan[(repaymentPeriod || "monthly") + "Repayments"])
      .reduce((a, b) => a + b, 0);
    const aggregateRepaymentsBreakdown = createBreakdown(
      (repaymentPeriod || "monthly") + "Repayments"
    );

    const upfrontFees = loans
      .map((l) =>
        Math.max(
          parseNumber(l.newLoan.approxUpfrontFee) -
            (l.newLoan.upfrontRebate || 0),
          0
        )
      )
      .reduce((a, b) => a + b, 0);
    const upfrontFeesBreakdown = Object.entries(proposedBanks)
      .map(([id, loans]) => [
        lenders.find((l) => l.id === id)?.name,
        loans.reduce(
          (a, b) =>
            a +
            Math.max(
              parseNumber(b.approxUpfrontFee) - (b.upfrontRebate || 0),
              0
            ),
          0
        ),
      ])
      .map(([name, value]) => `${name}($${formatNumber(parseNumber(value))})`)
      .join(", ");

    const ongoingFees = loans
      .map((l) => l.newLoan.ongoing_fees)
      .reduce((a, b) => parseNumber(a) + parseNumber(b), 0);
    const ongoingFeeBreakdown = createBreakdown("ongoing_fees");

    const rebateSavings = loans
      .map((l) => l.newLoan.yearOneRebateSaving)
      .reduce((a, b) => parseNumber(a) + parseNumber(b), 0);

    const allEqual = (arr) => arr.every((i) => i === arr[0]);

    const firstLoan = loans[0] || { currentLoan: {}, newLoan: {} };

    const loanTerm = allEqual(loans.map((l) => l.currentLoan.newLoanPeriod))
      ? firstLoan.currentLoan.newLoanPeriod
      : null;
    const interestType = allEqual(loans.map((l) => l.newLoan.initial_ir_type))
      ? firstLoan.newLoan.initial_ir_type
      : null;
    const repaymentType = allEqual(loans.map((l) => l.newLoan.repaymentType))
      ? firstLoan.newLoan.repaymentType
      : null;
    const noFixedInterestLoans = !loans.some(
      (loan) => loan.newLoan.initial_ir_type === "Fixed"
    );
    const offset = loans.some((l) => l.newLoan.full_offset === "Y");
    const redrawAllowed = loans.some((l) => l.newLoan.redraw_allowed === "Y");
    const extraRepayments = loans.some(
      (l) => l.newLoan.extra_repayments_allowed === "Y"
    );

    const currentMonthlyRepayments = loans
      .map((l) =>
        l.currentLoan.newLoanPurpose === "purchase"
          ? l.newLoan.monthlyRepayments || 0
          : l.currentLoan.monthlyRepayment
      )
      .reduce((a, b) => a + (b || 0), 0);
    const newMonthlyRepayments = loans.reduce(
      (t, l) =>
        l.currentLoan.monthlyRepayment
          ? t + (l.newLoan.monthlyRepayments || 0)
          : t,
      0
    );
    const monthlySavings = currentMonthlyRepayments - newMonthlyRepayments;
    const interestSavings = loans
      .map((l) => l.newLoan.yearOneInterestSaving)
      .reduce((a, b) => a + (b || 0), 0);
    // if (!proposalActive) return null;
    console.log("data stuff", loans, proposedBanks, lenders);

    const tabId = "summary";

    const displayGodmode =
      this.props.user.godmode &&
      !this.props.miniProposal.previewMode &&
      !this.props.isPDF &&
      !this.props.browser.lessThan.w840 &&
      !this.props.browser.is.w840;

    return (
      <SectionContainer isPDF={this.props.isPDF}>
        {displayGodmode && (
          <AddSectionSummary
            addSection={this.addSection.bind(this)}
            isExecSummary={summary.execSummary.enabled}
            isAdditionalDetails={summary.additionalDetails.enabled}
            isOverview={summary.overview.enabled}
          />
        )}

        <ExecutiveSummary
          isPDF={this.props.isPDF}
          isForSummary={true}
          form={"executive_summary-" + tabId}
          tabId={tabId}
          initialValues={summary.execSummary}
          isView={summary.execSummary.enabled}
          removeSection={() => this.removeSection("execSummary")}
          active={this.isActive(this.state.editMode, 2)}
          cancelEdit={() => this.cancelEdit()}
          toggleEdit={() => this.toggleEdit(2)}
        />

        <Form onSubmit={this.props.handleSubmit(this.saveOverview.bind(this))}>
          <OverviewContainer
            active={this.isActive(this.state.editMode, 1)}
            isView={summary.overview.enabled}
          >
            <Heading
              isPDF={this.props.isPDF && !this.isActive(this.state.editMode, 2)}
              style={{ fontWeight: "700", marginTop: "15px" }}
            >
              Aggregate Lending Overview
            </Heading>
            <Table active={this.isActive(this.state.editMode, 1)}>
              <tbody>
                <TableRow>
                  <TableElement type="heading">
                    {Object.keys(proposedBanks).length > 1
                      ? "Lenders"
                      : "Lender"}
                  </TableElement>
                  <TableElement style={{ paddingBottom: "0" }}>
                    <Banks>
                      {Object.keys(proposedBanks).map((id) => (
                        <BankLogo
                          product={{
                            bank: id,
                            bank_name: lenders.find((l) => l.id === id)?.name,
                          }}
                          alt="Securefinance"
                        />
                      ))}
                    </Banks>
                    {displayGodmode && (
                      <SectionButton
                        isOptionActive={this.isOptionActive.bind(this)}
                        isEditable={this.isActive(this.state.editMode, 1)}
                        cancelEdit={() => this.cancelOverViewEdit()}
                        enableEdit={() => this.toggleEdit(1)}
                        toggleEdit={() => this.toggleEdit(1)}
                        toggleOption={this.toggleOption.bind(this)}
                        removeSection={() => this.removeSection("overview")}
                        active={this.isActive(this.state.editMode, 1)}
                        type="overview"
                        style={{
                          float: "right",
                          position: "absolute",
                          top: "0",
                          right: "0",
                        }}
                      />
                    )}
                  </TableElement>
                </TableRow>
                <TableRow>
                  <TableElement type="heading">Total Loan</TableElement>
                  <TableElement type="total_loan">
                    ${formatNumber(loansTotal)}
                  </TableElement>
                </TableRow>
                <TableRow>
                  <TableElement type="heading">Interest Rate</TableElement>
                  <TableElement style={{ paddingBottom: "5px" }}>
                    <ValueDescription style={{ margin: "0 60px 15px 0" }}>
                      {weightedIIR}
                      <Percentage>% p.a.</Percentage>
                      <ValueDescriptionSubtitle>
                        Weighted Interest Rate
                        {!this.props.isPDF && (
                          <Tooltips
                            type="definition"
                            term="WIR"
                            style={{ marginLeft: "5px" }}
                          />
                        )}{" "}
                      </ValueDescriptionSubtitle>
                    </ValueDescription>

                    {noFixedInterestLoans && (
                      <ValueDescription style={{ margin: "0 0 15px 0" }}>
                        {weightedTrueRate}
                        <Percentage>% p.a.</Percentage>
                        <ValueDescriptionSubtitle>
                          Weighted True Rate
                          {!this.props.isPDF && (
                            <Tooltips
                              type="definition"
                              term="WTR"
                              style={{ marginLeft: "5px" }}
                            />
                          )}
                        </ValueDescriptionSubtitle>
                      </ValueDescription>
                    )}

                    <Switch
                      disabled={!this.isActive(this.state.editMode, 1)}
                      style={{ margin: "0 0 0 5px" }}
                      name="interestRateBreakdown"
                    />

                    {formData.interestRateBreakdown && (
                      <Breakdown style={{ display: "block" }}>
                        Breakdown<span>{interestRateBreakdown}</span>
                      </Breakdown>
                    )}
                  </TableElement>
                </TableRow>
                <TableRow>
                  <TableElement type="heading">
                    Aggregate Repayments
                  </TableElement>
                  <TableElement style={{ paddingBottom: "5px" }}>
                    <ValueDescription style={{ marginRight: "27px" }}>
                      ${formatNumber(aggregateRepayments)}
                      <AggregateRepayments onChange={this.onRepaymentsChange} />
                    </ValueDescription>
                    {formData.repaymentsBreakdown && (
                      <Breakdown>
                        Breakdown<span>{aggregateRepaymentsBreakdown}</span>
                      </Breakdown>
                    )}

                    <Switch
                      disabled={!this.isActive(this.state.editMode, 1)}
                      style={{ margin: "0 0 0 20px" }}
                      name="repaymentsBreakdown"
                    />
                  </TableElement>
                </TableRow>
                <TableRow disabled={!summary.overview.termAndType}>
                  <TableElement type="heading">Term & Type</TableElement>

                  <TableElement
                    type="term&type"
                    style={{ paddingBottom: "10px" }}
                  >
                    {!!loanTerm && (
                      <ValueDescription className="loanType">
                        {loanTerm} Years
                        <ValueDescriptionSubtitle>
                          Loan Term
                        </ValueDescriptionSubtitle>
                      </ValueDescription>
                    )}
                    {!!interestType && (
                      <ValueDescription className="loanType">
                        {interestType}
                        <ValueDescriptionSubtitle>
                          Interest Type
                        </ValueDescriptionSubtitle>
                      </ValueDescription>
                    )}
                    {!!repaymentType && (
                      <ValueDescription className="loanType">
                        {repaymentType}
                        <ValueDescriptionSubtitle>
                          Repayment Type
                        </ValueDescriptionSubtitle>
                      </ValueDescription>
                    )}
                  </TableElement>
                </TableRow>
                <TableRow disabled={!summary.overview.upfrontFees}>
                  <TableElement type="heading">Upfront Fees</TableElement>
                  <TableElement
                    type="upfront_fees"
                    style={{ paddingBottom: "5px" }}
                  >
                    <ValueDescription>
                      ${formatNumber(upfrontFees)}
                      <ValueDescriptionSubtitle style={{ width: "75px" }}>
                        Approximate*
                      </ValueDescriptionSubtitle>
                    </ValueDescription>
                    {formData.upfrontFeesBreakdown && (
                      <Breakdown>
                        Breakdown<span>{upfrontFeesBreakdown}</span>
                      </Breakdown>
                    )}

                    <Switch
                      disabled={!this.isActive(this.state.editMode, 1)}
                      style={{ margin: "0 0 0 20px" }}
                      name="upfrontFeesBreakdown"
                    />
                  </TableElement>
                </TableRow>

                <TableRow disabled={!summary.overview.ongoingFees}>
                  <TableElement type="heading">Ongoing Fees</TableElement>
                  <TableElement
                    type="ongoing_fees"
                    style={{ paddingBottom: "5px" }}
                  >
                    <ValueDescription>
                      ${formatNumber(ongoingFees)}
                      <ValueDescriptionSubtitle style={{ width: "75px" }}>
                        Per Year
                      </ValueDescriptionSubtitle>
                    </ValueDescription>
                    {formData.ongoingFeesBreakdown && (
                      <Breakdown>
                        Breakdown<span>{ongoingFeeBreakdown}</span>
                      </Breakdown>
                    )}

                    <Switch
                      disabled={!this.isActive(this.state.editMode, 1)}
                      style={{ margin: "0 0 0 20px" }}
                      name="ongoingFeesBreakdown"
                    />
                  </TableElement>
                </TableRow>

                <TableRow>
                  <TableElement type="heading">Loan Features</TableElement>
                  <TableElement style={{ paddingBottom: "5px" }}>
                    <Features>
                      <Feature type={redrawAllowed}>Redraw</Feature>
                      <Feature type={offset}>Offset</Feature>
                      <Feature type={extraRepayments}>Early Repayments</Feature>
                    </Features>
                  </TableElement>
                </TableRow>
                {interestSavings > 1 && (
                  <TableRow>
                    <TableElement type="heading">Interest Saving</TableElement>
                    <TableElement
                      type="initial_interest_savings"
                      style={{ fontWeight: "700", lineHeight: "120%" }}
                    >
                      Approximately{" "}
                      <span>${formatNumber(interestSavings)} per year</span>{" "}
                      initial saving.
                    </TableElement>
                  </TableRow>
                )}
                {rebateSavings > 1 && (
                  <TableRow>
                    <TableElement type="heading">Annual Rebate</TableElement>
                    <TableElement
                      type="initial_interest_savings"
                      style={{ fontWeight: "700", lineHeight: "120%" }}
                    >
                      Approximately <span>${formatNumber(rebateSavings)}</span>{" "}
                      initial rebate.
                    </TableElement>
                  </TableRow>
                )}
                {monthlySavings > 1 && (
                  <TableRow>
                    <TableElement type="heading">
                      Repayment Reduction
                    </TableElement>
                    <TableElement
                      type="repayment_reduction"
                      style={{ fontWeight: "700", lineHeight: "120%" }}
                    >
                      Approximately{" "}
                      <span>${formatNumber(monthlySavings)} per month</span>{" "}
                      repayment reduction.
                    </TableElement>
                  </TableRow>
                )}
                <TableRow disabled={!summary.overview.lenderRebate}>
                  <TableElement type="heading">Lender Rebates</TableElement>
                  <TableElement type="lender_rebates">
                    <span style={{ fontWeight: "700" }}>
                      $
                      <Span
                        style={{ minWidth: "60px" }}
                        as={EditableContent}
                        name="rebateAmount"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                    </span>
                    <Offer>
                      <Div
                        style={{ fontWeight: "700" }}
                        as={EditableContent}
                        name="rebateText"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                      <Span
                        as={EditableContent}
                        name="rebateAdditional"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                    </Offer>
                  </TableElement>
                </TableRow>
                <TableRow disabled={!summary.overview.brokerRebate}>
                  <TableElement type="heading">
                    <img
                      src={require("../../../img/brand/secure_finance.png")}
                      alt="Securefinance"
                    />
                    Broker Rebate
                  </TableElement>
                  <TableElement type="broker_rebate">
                    <span style={{ fontWeight: "700" }}>
                      $
                      <Span
                        style={{ minWidth: "60px" }}
                        as={EditableContent}
                        name="brokerRebateAmount"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                    </span>
                    <Offer>
                      <Div
                        style={{ fontWeight: "700" }}
                        as={EditableContent}
                        name="brokerRebateText"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                      <Span
                        as={EditableContent}
                        name="brokerRebateAdditional"
                        disabled={!this.isActive(this.state.editMode, 1)}
                      />
                    </Offer>
                  </TableElement>
                </TableRow>
                <TableRow disabled={!summary.overview.benefits}>
                  <TableElement type="heading">
                    Additional Benefits
                  </TableElement>
                  <TableElement type="additional_benefits">
                    <Content>
                      <BenifitList
                        disabled={!this.isActive(this.state.editMode, 1)}
                        as={EditableContent}
                        allowLineBreaks={true}
                        name="benifitsList"
                        onKeyDown={(e) => this.onListKeyDown(e)}
                      ></BenifitList>
                    </Content>
                  </TableElement>
                </TableRow>
              </tbody>
            </Table>
            <FinePrint>
              <p>
                The initial interest savings{" "}
                {rebateSavings > 1 && "and initial annual rebate"} are
                calculated based on the initial loan size being fully utilised
                (i.e. does not factor amounts held in offset).
              </p>
            </FinePrint>
            {this.props.isPDF && (
              <FinePrint>
                <p>
                  <b>Weighted Interest Rate:</b>
                  This weighted calculation is an indicative average interest
                  rate for your entire borrowing portfolio based on our
                  recommended solution, excluding lender fees and charges. It is
                  calculated by proportionally weighting interest rates by the
                  corresponding loan size.
                </p>
                <p>
                  <b>Weighted True Rate:</b>
                  This weighted calculation is an indicative average interest
                  rate for your entire borrowing portfolio based on our
                  recommended solution, including lender fees and charges. It is
                  calculated by proportionally weighting interest rates by the
                  corresponding loan size.
                </p>
                <p>
                  <b>Upfront fees:</b>
                  This estimate includes application fees, valuation costs, and
                  standard legals (excluding PEXA). Note that these fees are
                  indicative only, do not include government charges, and may
                  vary depending on your particular circumstances
                </p>
              </FinePrint>
            )}
          </OverviewContainer>
        </Form>

        {!!this.props.broker?.id &&
          !this.props.isPDF &&
          !this.props.overviewActivated && (
            <BrokerSection
              isForSummary={true}
              tabId={tabId}
              broker={this.props.broker ?? {}}
              details={summary.additionalDetails}
              isView={summary.additionalDetails.enabled}
              removeSection={() => this.removeSection("additionalDetails")}
              active={this.isActive(this.state.editMode, 3)}
              cancelEdit={() => this.cancelEdit()}
              toggleEdit={() => this.toggleEdit(3)}
            />
          )}

        <Overlay status={this.state.editMode}></Overlay>
      </SectionContainer>
    );
  }
}

const FinePrint = styled.div`
  p {
    color: #727c8f !important;
    font-size: 13px !important;
    margin-top: 15px;
  }

  b {
    font-weight: 700;
  }
`;

const Div = styled.div`
  ${(p) =>
    p.disabled
      ? ""
      : css`
          border: solid 1px #a3a8b2;
          border-radius: 3px;

          &:focus {
            border-color: #3f70f2;
          }
        `}
`;
const Span = styled.span`
  display: inline-block;

  ${(p) =>
    p.disabled
      ? ""
      : css`
          border: solid 1px #a3a8b2;
          border-radius: 3px;

          &:focus {
            border-color: #3f70f2;
          }
        `}
`;

const Content = styled.div`
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
`;

const BenifitList = styled.ul`
  div:before {
    content: "•";
    margin-left: -20px;
    margin-right: 10px;
  }

  div {
    display: list-item;
    list-style: none;
    margin-bottom: 10px;
    margin-left: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${(p) =>
    p.disabled
      ? ""
      : css`
          border: solid 1px #a3a8b2;
          border-radius: 3px;

          &:focus {
            border-color: #3f70f2;
          }
        `}
`;

const BankLogo = styled(BankLogoBase)`
  @media (max-width: 480px) {
    max-width: 80px !important;
    margin-right: 30px !important;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  ${(props) =>
    props.status &&
    css`
      opacity: 1;
      display: block;
      pointer-events: auto;
    `}
`;

const Banks = styled.div`
  img {
    margin-right: 40px;
    margin-bottom: 20px;
    max-width: 100px;
    max-height: 60px;
    object-fit: contain;
    vertical-align: middle;
  }
`;

const Heading = styled.h2`
  display: block;
  font-size: 18px !important;
  margin-bottom: 20px !important;

  ${(p) =>
    !p.isPDF &&
    css`
      margin-top: 40px;
    `}
`;

const Features = styled.ul`
  font-size: 14px;
`;

const Feature = styled.li`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #26bd27;
  padding-left: 20px;
  background-image: url(${require("../../../img/elements/check-fancy-green.png")});
  background-repeat: no-repeat;
  background-size: 14px 10px;
  background-position: 0 50%;

  ${(props) =>
    props.type &&
    css`
      background-image: url(${require("../../../img/elements/cross-red.png")});
      background-size: 13px 13px;
      color: #ff4e4c;
    `}
`;

const ValueDescriptionSubtitle = styled.span`
  color: #727c8f;
  font-size: 11px;
  display: block;
  margin: 5px 0 0 0;
`;

const Percentage = styled.span`
  display: inline !important;
  font-size: 11px;
  color: #727c8f;
  margin-left: 3px;
`;

const Breakdown = styled.span`
  font-size: 10px;
  color: #727c8f;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;

  span {
    font-size: 11px;
    display: block;
    margin: 5px 0 0 0;
  }
`;

const ValueDescription = styled.span`
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
  margin-bottom: 15px;

  .loanType {
    margin: 0 60px 10px 0 !important;
  }

  @media (max-width: 440px) {
    &:not(.loanType) {
      margin-right: 0 !important;
    }
  }
`;

const Offer = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
  width: calc(100% - 115px);

  @media (max-width: 645px) {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }

  ${Div} {
    color: #26bd27;
    font-size: 14px;
    font-weight: 600;
  }

  ${Span} {
    font-size: 12px;
    color: #727c8f;
    font-weight: 400;
    display: block;
    margin: 5px 0 0 0;
  }
`;

const OverviewContainer = styled.div`
  position: relative;
  transition: max-height 0.2s;

  ${(props) =>
    props.active &&
    css`
      z-index: 500;
    `}

  ${(props) =>
    !props.isView &&
    css`
      max-height: 0;
      overflow: hidden;
    `}
`;

const Table = styled.div`
  display: table;
  width: 100%;
  border-top: 1px solid #d8dce7;
  border-left: 1px solid #d8dce7;
  table-layout: fixed;

  ${(props) =>
    props.active &&
    css`
      z-index: 500;
    `}
`;

const TableRow = styled.div`
  display: table-row;
  transition: max-height 0.2s;

  ${(props) =>
    props.disabled &&
    css`
      max-height: 0;
      overflow: hidden;
      display: none;
    `}
`;

const TableElement = styled.div`
  display: table-cell;
  border-bottom: 1px solid #d8dce7;
  border-right: 1px solid #d8dce7;
  padding: 20px 20px 20px 40px;
  vertical-align: middle;
  position: relative;
  width: 70%;

  @media (max-width: 840px) {
    padding: 20px 20px 20px 30px;
  }
  @media (max-width: 645px) {
    padding: 15px 20px 20px 20px;
  }
  @media (max-width: 480px) {
    padding: 15px 15px 15px 15px;
  }

  ${(props) =>
    props.type === "heading" &&
    css`
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #727c8f;
      text-align: right;
      padding: 20px;
      vertical-align: top;

      width: 240px;
      @media (max-width: 840px) {
        width: 220px;
      }
      @media (max-width: 645px) {
      }
      @media (max-width: 570px) {
        width: 150px;
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
      }
      @media (max-width: 420px) {
        width: 110px;
      }

      img {
        max-width: 140px;
        object-fit: contain;
        margin: 0 0 10px auto;
        display: block;

        @media (max-width: 645px) {
          max-width: 110px;
        }

        @media (max-width: 480px) {
          max-width: 100px;
        }

        @media (max-width: 420px) {
          max-width: 80px;
        }
      }
    `}

  ${(props) =>
    props.type === "total_loan" &&
    css`
      font-size: 20px;
      font-weight: 400;
    `}

  ${(props) =>
    props.type === "lender_rebates" &&
    css`
      font-size: 19px;
      font-weight: 600;
      color: #2689eb;

      sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -0.5em;
      }
    `}

  ${(props) =>
    props.type === "broker_rebate" &&
    css`
      font-size: 19px;
      font-weight: 600;
      color: #2689eb;

      sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -0.5em;
      }
    `}

  ${(props) =>
    props.type === "initial_interest_savings" &&
    css`
      font-size: 19px;
      font-weight: 600;

      span {
        color: #2689eb;
      }
    `}

  ${(props) =>
    props.type === "repayment_reduction" &&
    css`
      font-size: 19px;
      font-weight: 600;

      span {
        color: #2689eb;
      }
    `}

  ${(props) =>
    props.type === "additional_benefits" &&
    css`
      font-size: 14px;
    `}

  ${(props) =>
    props.type === "term&type" &&
    css`
      padding-right: 0px !important;
    `}
`;

Summary = reduxForm({
  form: "proposal-summary",
  destroyOnUnmount: false,
})(Summary);

export default connect(mapStateToProps, mapDispatchToProps)(Summary);

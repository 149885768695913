import React from 'react';
import styled from 'styled-components';
import GlobalDisclamer from './GlobalDisclaimer';

export default props => {
  return (
    <Container {...props}>
      <div className="references">
        <ol>
          <li>
            Comparison Rates: Any quoted comparison rate is only true for the example given and may
            not include all fees and charges. Different terms, loan amounts or fees may result in a different
            comparison rate. Comparison rates are based on a loan amount of $150,000 over a loan term of
            25 years.
          </li>
          <li>
            Borrowing Power: Results should only be used as a rough guide as we make a number of
            assumptions for these calculations. Lender credit policy may also change at any time. Please
            contact us for a personalised assessment.
          </li>
        </ol>
        
      </div>
      <GlobalDisclamer/>
    </Container>
  );
}

const Container = styled.div`
  @media (max-width: 480px) {
    padding-left: 10px;
    padding-right: 10px;
  }

`
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import View from './View';
import { closeModal, openModal } from 'modules/modals/actions';
import { requestPatchUser } from 'modules/user/actions';
 
function mapStateToProps(state) {
  return {
    currentModal: (state.modals)?state.modals.currentModal:"",
    miniProposal: state.user.meta.miniproposal,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    requestPatchUser,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

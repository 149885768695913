import React from "react";
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';


import { openModal } from "modules/modals/actions";
import SectionButton from "../../../component/SectionButton";
import { requestPatchUser } from 'modules/user/actions';
import { Container as SectionContainer, Heading, EditableParagraph } from './Shared';
import { RecommendedLenders } from './LendingOverviewSections/RecommendedLenders'; 
import { LendingStructure } from './LendingOverviewSections/LendingStructure';   

// import { Aggregates } from "./LendingOverviewSections/Aggregates";
import parseNumber from 'utils/parseNumber';
import formatNumber from "utils/formatNumber";

const FORM_NAME = "overview-lending-loanbase";
const BODY_NAME = "overview_lending_body"

class LendingOverview extends React.Component {

  constructor(props) {
    super();
    this.inputRef = React.createRef();
  }

  cancel = () => {
    this.props.reset();
    this.props.cancelEdit();
  }

  

  resetSection = () => {
    
    const formValues = { 
      body: '', 
      aggregateLending: false, 
      aggregateFees: true, 
      upfrontFees: '', 
      ongoingFees: '', 
      weightedIIR: '', 
      weightedTrueRate: ''
    };
    this.patchProposal(formValues);

    const { globalSettings, weightedTrueRate, weightedIIR, upfrontFees, ongoingFees } = this.props;

    const initialFormValues = {
      ...formValues,
      body: globalSettings[BODY_NAME],
      weightedTrueRate,
      weightedIIR,
      upfrontFees, 
      ongoingFees,
    };

    this.props.initialize(initialFormValues);

    this.props.reset();
  }

  save = () => {
    let values = {...this.props.formValues};
    delete values.enabled;
    if (!values.body) values.body = this.props.globalSettings[BODY_NAME];
    
    const { weightedTrueRate, weightedIIR, upfrontFees, ongoingFees } = this.props;
    if (values.weightedTrueRate) {
      values.weightedTrueRate = formatNumber(values.weightedTrueRate, 2);
      if (values.weightedTrueRate === weightedTrueRate) values.weightedTrueRate = 'DELETE_ITEM';
    }
    if (values.weightedIIR) {
      values.weightedIIR = formatNumber(values.weightedIIR, 2);
      if (values.weightedIIR === weightedIIR) values.weightedIIR = 'DELETE_ITEM';
    }
    if (values.upfrontFees) {
      values.upfrontFees = formatNumber(values.upfrontFees);
      if (values.upfrontFees === upfrontFees) values.upfrontFees = 'DELETE_ITEM';
    }
    if (values.ongoingFees) {
      values.ongoingFees = formatNumber(values.ongoingFees);
      if (values.ongoingFees === ongoingFees) values.ongoingFees = 'DELETE_ITEM';
    }

    this.patchProposal(values);

    for (let value of Object.values(values)) {
      if (value === 'DELETE_ITEM') value = undefined;
    }

    this.props.initialize(values);

    this.props.cancelEdit();
  }

  patchProposal = (data, callback) => {
    let toSave;
    
    toSave = {
      overview: {
        lendingOverview: data
      }
    }
    
    this.props.requestPatchUser({ 
      userId: this.props.user.id,
      params: {
        mergeMeta: true,
      },
      data: { 
        meta: { 
          miniproposal: toSave,
        } 
      },
      callback, 
    });
  } 

  enableEdit = () => {
    this.props.toggleActive();
  }



  onKeyDown = e => {
    if (document.activeElement !== ReactDOM.findDOMNode(this.inputRef.current)) return;
    if (e.key == '8' && e.altKey) {
      setTimeout(() => {
        document.execCommand('insertUnorderedList');
      }, 5);
    }
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      setTimeout(() => {
        document.execCommand('underline');
      }, 5);
      e.preventDefault();
      return false;
    }
  }

  blurOnEnter() {
    document.activeElement.blur();
  }

  openOptions = () => {
    this.props.openModal('lenderOverviewOptions')
  }

  render() {
    const { 
      active, 
      editMode, 
      lenders, 
      banks,
      loans,
      weightedIIR,
      weightedTrueRate, 
      upfrontFees, 
      ongoingFees
    } = this.props;

    return (
      <SectionContainer active={active} id='lending-overview'>
        <Form onSubmit={this.props.handleSubmit(this.save.bind(this))}>
          {editMode &&
            <SectionButton
              resetSection={() => this.resetSection()}
              enableEdit={() => this.enableEdit()}
              isEditable={active}
              cancelEdit={() => this.cancel()}
              openOptions={() => this.openOptions()}
              type="lending-overview" 
              style={{ float: 'right', position: 'absolute', top: '-10px', right: '0' }}
              cancelSaveContainer="20px"
            />
          }
          
          <Heading>Proposed Lending Overview</Heading>
          <p><EditableParagraph richText={!!active} innerRef={this.inputRef} allowLineBreaks onKeyDown={e => this.onKeyDown(e)} className={active ? 'active' : ''} name='body' disabled={!active}/></p>

          <RecommendedLenders editMode={editMode} loans={loans} lenders={lenders} banks={banks} active={active} defaults={{ weightedIIR, weightedTrueRate, upfrontFees, ongoingFees }}/>

          <LendingStructure loans={loans} editMode={editMode} />

        </Form>
      </SectionContainer>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal,
    requestPatchUser,
  }, dispatch);
}

const mapStateToProps = (state, props) => { 
  const miniProposal = state.user.meta.miniproposal;
  const lendingOverview = miniProposal.overview?.lendingOverview ?? {};
  
  const { globalSettings } = state.user;
  
  const body = lendingOverview.body || globalSettings[BODY_NAME];
  
  const { loans, banks } = props;

  const loansTotal = loans
    .map(l => l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0);

  let weightedIIR = loans
    .map(l => l.newLoan.iir * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedIIR = formatNumber(weightedIIR, 2);

  let weightedTrueRate = loans
    .map(l => parseFloat(l.newLoan.trueRate) * l.currentLoan.loanAmount)
    .reduce((a, b) => a + b, 0) / loansTotal;
  weightedTrueRate = formatNumber(weightedTrueRate, 2);
  
  let upfrontFees = loans
    .map(l => Math.max( parseNumber(l.newLoan.approxUpfrontFee) - (l.newLoan.upfrontRebate ?? 0), 0))
    .reduce((a, b) => a + b, 0);
  upfrontFees = formatNumber(upfrontFees);
  
  let ongoingFees = loans
    .map(l => l.newLoan.ongoing_fees)
    .reduce((a, b) => parseNumber(a) + parseNumber(b), 0);
  ongoingFees = formatNumber(ongoingFees);

  return {
    body: state.form[FORM_NAME]?.values?.body, 
    user: state.user,
    lenders: state.user.lenders,
    lendingOverview,
    globalSettings,
    weightedIIR,
    weightedTrueRate,
    upfrontFees,
    ongoingFees,
    initialValues: { 
      body, 
      weightedTrueRate: lendingOverview.weightedTrueRate || weightedTrueRate, 
      weightedIIR: lendingOverview.weightedIIR || weightedIIR, 
      upfrontFees: lendingOverview.upfrontFees || upfrontFees, 
      ongoingFees: lendingOverview.ongoingFees || ongoingFees
    },

    formValues: state.form[FORM_NAME]?.values ?? {},
    browser: state.browser,
    loans,
    banks,
    miniProposal,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }) (LendingOverview));
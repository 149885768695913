// import TagManager from 'react-gtm-module'

const trackPage = (page) => {

  // GTM - Track pages 

  // TagManager.dataLayer({
  //   gtmId: 'GTM-5HT97R9',
  //   dataLayer: {
  //     event: 'newIntroUnverified'
  //   }
  // });

}

let currentPage = ''

export default store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`

    if (currentPage !== nextPage) {
      currentPage = nextPage
      trackPage(nextPage)
    }
  }

  return next(action)
}
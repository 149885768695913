// This object is interpreted by both the client (mergeObjects in 
// user/reducers) and in the back end (patchHook in user.hooks) as 
// instructions on how to alter the user.meta array it's attached to.

// This increases robustness compared to just overwriting the array. 
// This way it's harder for array data to be accidentally overriden.
// (say if stale data is submitted)


const alterArray = {
  push: x => ({ __editArray: true, push: [x] }),
  remove: x => ({ __editArray: true, remove: [x] }),
  swap: (a, b) => {
    const length = Math.max(a, b) + 1;
    let swapArray = Array.from(Array(length).keys());
    swapArray.splice(a, 0, swapArray.splice(b, 1)[0]);
    return { __editArray: true, swap: swapArray };
  },
}

export default alterArray;
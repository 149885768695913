import React from "react"; import { Component } from "react";
import LinkButton from "Components/LinkButton";
import SelectTermIOForm from "./SelectTermIOForm"
import { connect } from 'react-redux';

export default class ProposalRebate extends Component {
  render() {
    if (this.props.currentModal === "io-term") {
    return (
      <div className="modal-content">
        <h1>IO Term</h1>
        <SelectTermIOForm tabId={this.props.tabId} productId={this.props.productId}/>
      </div>
    ) } else {
      return "";
    }
  }
}

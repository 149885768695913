import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form'

import Applicants from "./Forms/Applicants";
import Expenses from "./Forms/Expenses";
import Finish from "./Forms/Finish";
import Income from "./Forms/Income";
import Liabilities from "./Forms/Liabilities";
import LoanDetails from "./Forms/LoanDetails";
import OtherProperties from "./Forms/OtherProperties";
import SecurityProperty from "./Forms/SecurityProperty";
import Success from "./Forms/Success";
import { push } from 'connected-react-router'

import FormIntroduction from "./Components/FormIntroduction";
import YourDetails from "./Components/YourDetails";

import {mapToInitialValues} from "forms.js";

import updateFormValues from "modules/updateFormValues"
import { openModal, closeModal } from 'modules/modals/actions';
import { setTitle, setPageID } from 'modules/page/actions';
import { requestPatchUser, requestValidationEmail } from 'modules/user/actions'
import { emailService } from 'modules';
import TagManager from 'react-gtm-module'

function mapStateToProps(state) {
  let completionStates = new Array(10).fill(false);
  for (let key in state.user.meta) {
    if (key.indexOf("eligibility_complete") === 0 && state.user.meta[key]) {
      let pageNumber = key.split("page")[1];
      completionStates[pageNumber] = true;
    }
  }
  let completedPages = 0;
  if (state.user.applications && state.user.applications[0] && state.user.applications[0].list && state.user.applications[0].list !== 7) {
    completedPages = 8;
  } else {
    for (const [i, state] of completionStates.entries()) {
      if (!state && i) {
        completedPages = i;
        break;
      }
    }
  }
  
  let application;
  if (state.user.applications && state.user.applications[0] && state.user.applications[0].meta)
    application = state.user.applications[0].meta;
  

  return {
    completedPages,
    completionStates,
    initialValues: mapToInitialValues(state.user),
    user: state.user.meta,
    application,
    userId: state.user.id,
    isVerified: state.user.isVerified
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
    openModal,
    setTitle,
    setPageID,
    requestPatchUser,
    requestValidationEmail,
    push
  }, dispatch);
}

class CheckEligibility extends React.Component {
  helpTimer = null;

  state = {
    redirectUrl: '',
  };

  redirectRef = null;

  redirectTo(to) {
    this.setState({ redirectUrl: to }, () => {
      if (this.redirectRef) {
        this.redirectRef.click();
      }
    });
  }

  redirect() {
    //console.log("User is at: " + this.props.user.eligibility_last_page);

    if (!this.props.user.confirmed_details) {
      this.props.history.push('/check-eligibility/details');
    } else if (this.props.user.eligibilityStart) {
      switch (this.props.user.eligibility_last_page + 1) {
        case 1: this.props.history.push('/check-eligibility/1'); break;
        case 2: this.props.history.push('/check-eligibility/2'); break;
        case 3: this.props.history.push('/check-eligibility/3'); break;
        case 4: this.props.history.push('/check-eligibility/4'); break;
        case 5: this.props.history.push('/check-eligibility/5'); break;
        case 6: this.props.history.push('/check-eligibility/6'); break;
        case 7: this.props.history.push('/check-eligibility/7'); break;
        case 8: this.props.history.push('/check-eligibility/8'); break;
        // case 9: this.props.history.push('/check-eligibility/9'); break;
        default: this.props.history.push('/check-eligibility/1');
      }    
    } else {
      this.props.history.push('/check-eligibility/form-introduction');
    }
  }

  componentDidMount() {
    this.helpTimer = setTimeout(() => {
      this.props.openModal('checkEligibilityHelpTimer');
    }, 10 * 60000); // 10 minutes


    document.title = "Secure Finance - Loan Qualifier";
    this.props.setTitle("Loan Qualifier");
    this.props.setPageID("qualifier");
    
    this.redirect();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.match.params.id) {
      this.redirect();
    }
  }

  componentWillUnmount() {
    if (this.helpTimer) clearTimeout(this.helpTimer);
  }


  processResults(results, _pageId) {
    let pageId = _pageId && _pageId.slice();
    const { user, application, openModal } = this.props;
    if (results["user-password"]) {
      if (results["user-password"] === results["confirmPassword"] ) {
      } else {
        alert("Passwords do not match");
        return false;
      }
    }

    if (application) {
      if (results['application_meta-applicantType']
        && application.applicantType
        && results['application_meta-applicantType'] !== application.applicantType
        && user['eligibility_complete_page2']) 
        {
          results = {
            ...results, 
            'user_meta-eligibility_complete_page2': 0,
            'user_meta-eligibility_complete_page3': 0,
            'user_meta-eligibility_complete_page4': 0,
            'user_meta-eligibility_complete_page5': 0,
            'user_meta-eligibility_complete_page6': 0,
            'user_meta-eligibility_complete_page7': 0,
            'user_meta-eligibility_complete_page8': 0,
        }
        openModal('CheckEligibilityFormResetNotification');
      }

      if (results['application_meta-loanPurpose']
        && application.loanPurpose
        && results['application_meta-loanPurpose'] !== application.loanPurpose
        && user['eligibility_complete_page6']) 
        {
          results = {
            ...results, 
            'user_meta-eligibility_complete_page6': 0,
            'user_meta-eligibility_complete_page7': 0,
            'user_meta-eligibility_complete_page8': 0,
        }
        openModal('CheckEligibilityFormResetNotification');
      }

      if (results['application_meta-propertyPurpose']
        && application.propertyPurpose
        && results['application_meta-propertyPurpose'] !== application.propertyPurpose
        && user['eligibility_complete_page6']) 
        {
          results = {
            ...results, 
            'user_meta-eligibility_complete_page6': 0,
            'user_meta-eligibility_complete_page7': 0,
            'user_meta-eligibility_complete_page8': 0,
        }
        openModal('CheckEligibilityFormResetNotification');
      }
    }

    if (results['user_meta-ownHome']
      && user.ownHome
      && results['user_meta-ownHome'] !== user.ownHome
      && user['eligibility_complete_page6']) 
      {
        results = {
          ...results, 
          'user_meta-eligibility_complete_page6': 0,
          'user_meta-eligibility_complete_page7': 0,
          'user_meta-eligibility_complete_page8': 0,
      }
      openModal('CheckEligibilityFormResetNotification');
    }

    if (pageId === "7") {
      // GTM - Qualifier Finish
      TagManager.dataLayer({
        gtmId: 'GTM-5HT97R9',
        dataLayer: {
          event: 'qualifierSubmission'
        }
      });
    }

    return new Promise((resolve, reject) => {
      updateFormValues(results, (result) => {
        if (result && result.error) {
          reject(new SubmissionError({
              'user-email': 'Email address already taken.',
              _error: 'Update details failed!'
            }));
          return;
        }
        if (results['application-submitEligibility'] === 1) {
          emailService.create({type: 'check-eligibility'});
        }
        if (pageId === 'details') {
          this.redirectTo('/check-eligibility/form-introduction/');
          emailService.create({ type: 'start-qualify-me'});
        } else {
          this.redirectTo('/check-eligibility/' + (parseInt(pageId, 10) + 1));
        }
        resolve();
      }, false, false);
    });
    
  }

  render() {
    return(
      <main className={toggleBackgroundColor(this.props.match.params.id) + " " + this.props.state + " " + this.props.type}
      // style={this.props.match.params.id === "form-introduction" ? {} : {overflow: 'hidden'}}
      >
        {toggleSecondarySidebar(this.props.match.params.id, this.props, this.props.completedPages)}
        <div className="main-container">

          {FormContent(this.props.match.params.id, this.props, (results)=>this.processResults(results, this.props.match.params.id))}

        </div>
        <button type='button' style={{ display: 'none'}} ref={ref => this.redirectRef = ref} onClick={() => this.props.history.push(this.state.redirectUrl)} />
      </main>
    );
  }
}

CheckEligibility = connect(mapStateToProps,  mapDispatchToProps)(CheckEligibility);

export default CheckEligibility;

function FormContent(value, props, processResults){
  switch(value){
    case "form-introduction": return <FormIntroduction onSubmit={(results)=>processResults(results())} initialValues={props.initialValues} />;
    case "details": return <YourDetails onSubmit={processResults} initialValues={props.initialValues} />;
    case "1": return <div><Applicants onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "2": return <div><SecurityProperty onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "3": return <div><OtherProperties onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "4": return <div><Income onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "5": return <div><Liabilities onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "6": return <div><Expenses onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "7": return <div><LoanDetails onSubmit={processResults} initialValues={props.initialValues} /></div>;
    case "8": return <div><Success onSubmit={processResults} initialValues={props.initialValues} /></div>;
    // case "9": return <div><Success onSubmit={processResults} initialValues={props.initialValues} /></div>;
    default: return "";
  }
}



const applicantsDefaults = (values) => {
  values['user-phoneNumberType'] = values['user-phoneNumberType'] || 'mobile';
}

function toggleSecondarySidebar(value, props, currentStage){
  //console.log(value, props, currentStage)
  if (parseInt(value, 10) < 10) {
    return(
    <div className={"secondary-sidebar n" + value}>
      <ul>
        {SidebarStates(value, 1, "Applicants", props.completionStates, currentStage)}
        {SidebarStates(value, 2, "Security Property", props.completionStates, currentStage)}
        {SidebarStates(value, 3, "Other Properties", props.completionStates, currentStage)}
        {SidebarStates(value, 4, "Income", props.completionStates, currentStage)}
        {SidebarStates(value, 5, "Liabilities", props.completionStates, currentStage)}
        {SidebarStates(value, 6, "Living Expenses", props.completionStates, currentStage)}
        {SidebarStates(value, 7, "Loan Details", props.completionStates, currentStage)}
        {SidebarStates(value, 8, "Success", props.completionStates, currentStage)}
        {/* {SidebarStates(value, 9, "Success", props.completionStates)} */}
      </ul>
      <div className="bottom-help">
        <h3>🔍 Want some help?</h3>
        <p>Our team is always happy to help you select and apply for a great loan.</p>
        <button onClick={()=>props.openModal("checkEligibilityHelp")}>Get Assistance</button>
      </div>
    </div>);
  }
  else {
    return "";
  }
}

function SidebarStates(value, number, name, completionStates, stage){
  if (stage === number) {
    return <li><NavLink active className="current next" exact to={"/check-eligibility/" + number} ><span>{number}</span>{name}</NavLink></li>
  } else
  if (completionStates[number] || stage === 8) {
    return (
      <li><NavLink active className={`current done ${stage === 8 ? 'disabled' : ''}`} exact to={"/check-eligibility/" + number} ><span>{number}</span>{name}</NavLink></li>
    );
  }
  else {
    return (
      <li><NavLink active className="current disabled" exact to={"/check-eligibility/" + number} ><span>{number}</span>{name}</NavLink></li>
    );
  }
}

function toggleBackgroundColor(value){
  if (value === "1" || value === "2" || value === "3" || value === "4" || value === "5" || value === "6" || value === "7" || value === "8" || value === "9" ) {
    return "check-eligiblity double"
  }
  else {
      return "check-eligiblity"
  }
}
